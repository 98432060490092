import { useLoginUrl } from '@client/context/hooks';
import { simulateLinkClick } from '@client/routes/utils/url';


export const useLoginAction = (): () => void => {
  const getLoginUrl = useLoginUrl();

  return () => {
    simulateLinkClick(getLoginUrl());
  };
};
