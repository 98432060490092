import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { HOT_JAR_TAG } from '@client/analytics/types/types';
import { tagHotJarRecording } from '@client/analytics/utils/analytics';
import { FeatureOnboarding } from '@client/app/components/App/FeatureOnboarding/FeatureOnboarding';
import { getDate, getDaysDifference } from '@client/common/utils/date/date';
import { getCookie, setCookie } from '@client/common/utils/storage/cookies';
import { useEntriesMap } from '@client/content/hooks';
import { fetchFeatureOnboarding, selectFeatureOnboarding } from '@client/content/redux/featureOnboarding/redux';
import { useActiveLanguage, useTranslate } from '@client/i18n/hooks';
import { useAccessCallbackData } from '@client/routes/hooks';
import { getCurrentRoute } from '@client/routes/redux/selectors';

const COOKIE_FEATURE_ONBOARDING = 'feature_onboarding';
const DEFAULT_DAYS_TO_EXPIRE = 365;

export const FeatureOnboardingContainer = (): ReactElement | null => {
  const t = useTranslate();
  const { pathname } = useLocation();
  const lang = useActiveLanguage();
  const { data: entries } = useEntriesMap();
  const accessCallbackData = useAccessCallbackData();
  const dispatch = useDispatch();
  const content = useSelector(selectFeatureOnboarding);
  const { data } = content;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const currentRoute = getCurrentRoute(entries, pathname, lang);
  const isAllowed = !!currentRoute?.allow?.(accessCallbackData);

  const getCookieKey = (entryId: string) => `${COOKIE_FEATURE_ONBOARDING}_${entryId}_${lang}`;

  const getCookieDaysToExpire = (showUntil?: string) => {
    if (showUntil) {
      return (
        getDaysDifference(
          getDate(new Date().toISOString()),
          getDate(showUntil),
        ) + 1
      );
    }

    return DEFAULT_DAYS_TO_EXPIRE;
  };

  const filteredData = data?.filter((entry) => (
    matchPath(pathname, { path: `/${lang}${entry.data.url}`, strict: false, sensitive: true }) !== null
  ));
  const entry = filteredData?.sort(
    (entry1, entry2) => Date.parse(entry2.updatedAt) - Date.parse(entry1.updatedAt),
  )[0];
  const cookie = (entry ? getCookie(getCookieKey(entry.entryId)) : null) as Record<string, any> | null;
  const currentEntry = filteredData?.length > 0 && (!cookie || cookie.isVisible) && entry;

  const onDismiss = () => {
    setIsModalOpen(false);

    if (currentEntry) {
      setCookie(
        getCookieKey(currentEntry.entryId),
        { isVisible: false },
        getCookieDaysToExpire(currentEntry.data.show_until),
      );
    }
  };

  useEffect(() => {
    if (currentEntry) {
      setIsModalOpen(true);
      tagHotJarRecording(HOT_JAR_TAG.featureOnboardingOpen);
    }
  }, [currentEntry]);

  useEffect(
    () => {
      if (!content.wasStarted) {
        dispatch(fetchFeatureOnboarding());
      }
    },
    [],
  );

  return (
    <FeatureOnboarding
      entry={currentEntry}
      onDismiss={onDismiss}
      isOpen={isAllowed && isModalOpen}
      modalButtonLabel={t('Close')}
    />
  );
};
