import { slice as addressSlice } from '@client/myAccount/addresses/redux/address/redux';
import { slice as addressesSlice } from '@client/myAccount/addresses/redux/addresses/redux';
import { slice as addressesSearchSlice } from '@client/myAccount/addresses/redux/addressesSearch/redux';
import { combineSlices } from '@reduxjs/toolkit';

export const addressesReducer = combineSlices(
  addressSlice,
  addressesSlice,
  addressesSearchSlice,
);
