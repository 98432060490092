import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HomepageType } from 'b2b-common/core/content/Content.types';

const NAME = 'homepage';

export const slice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: RemoteRecordData.getDefault(),
  reducers: {
    fetchHomepage: (state) => {
      state = RemoteRecordData.init();
      return state;
    },
    fetchHomepageFulfilled: (state, action: PayloadAction<HomepageType>) => {
      state = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchHomepageRejected: (state, action: PayloadAction<ErrorType>) => {
      state = RemoteRecordData.reject(action.payload);
      return state;
    },
  },
});

export const {
  fetchHomepage,
  fetchHomepageFulfilled,
  fetchHomepageRejected,
} = slice.actions;

const getState = (state: AppState) => state.content.homepage;

export const selectHomepage = createSelector(
  getState,
  (state) => new RemoteRecord<HomepageType>(state),
);
