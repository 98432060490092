import { ReactElement } from 'react';
import { Props } from './ModalContent.types';
import './ModalContent.scss';

export const ModalContent = ({ data }: Props): ReactElement => (
  <>
    {data.subtitle && (
      <h5 className='ModalContent-Subtitle'>{data.subtitle}</h5>
    )}
    <div className='ModalContent-Content'>
      <div
        className='ModalContent-TextWrapper'
        dangerouslySetInnerHTML={{
          __html: data.text,
        }}
      />
      {data.image && (
        <div className="ModalContent-ImageWrapper">
          <img
            className="ModalContent-Image"
            src={data.image.url}
            alt={data.image.title}
          />
        </div>
      )}
    </div>
  </>
);
