import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';

export class PDF {
  public static generate(
    htmlContent: string,
    fileName = 'product',
  ): RequestConfigType {
    return RequestConfig.post(
      `/pdf/generate?name=${fileName}`,
      htmlContent,
      {
        headers: {
          'Content-Type': 'text/plain',
          Accept: 'application/pdf',
        },
        responseType: 'arraybuffer',
        timeout: 30000,
      },
    );
  }
}
