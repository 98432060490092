import { Cart } from '@client/cart/api/Cart';
import { addToCart, addToCartFulfilled, addToCartRejected } from '@client/cart/redux/addToCart/redux';
import { replaceCartStatus } from '@client/cart/redux/cartStatus/redux';
import { CART_MESSAGES, showCartToast } from '@client/cart/utils/cart';
import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import emitter, { EVENT } from '@client/common/utils/eventEmitter';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { CartStatusWithAddedItemType } from 'b2b-common/core/cart/api/CartTypes';
import { removeCartFulfilled } from '../cart/redux';

function* addItemSaga(action: ReturnType<typeof addToCart>): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<CartStatusWithAddedItemType>
> {
  const { sku, quantity, productName, reference } = action.payload;

  const toastId = showCartToast({
    messageId: CART_MESSAGES.addingToCart,
    addedQuantity: quantity,
    productName: productName || `SKU: ${sku}`,
  });

  try {
    const response = yield call(
      requestApiSaga,
      Cart.addItem({ sku, quantity, reference }),
    );
    const { cart, item } = response.data;
    const {
      addedQuantity,
      currentQuantity,
      errors,
      maxAllowedQuantity,
      productName,
    } = item;

    yield put(removeCartFulfilled());
    yield put(replaceCartStatus({ ...cart, isLastInsertedNewItem: true }));

    switch (true) {
      case Array.isArray(errors) && errors.length > 0:
        errors.forEach((messageId, messageIndex) => showCartToast({
          messageId,
          productName: productName || sku,
          addedQuantity,
          currentQuantity,
          maxAllowedQuantity,
          ...(messageIndex === 0 && { toastId }),
        }));

        yield put(addToCartRejected());
        break;
      case addedQuantity < 1:
        showCartToast({
          messageId: CART_MESSAGES.unknownError,
          productName: `SKU: ${sku}`,
          addedQuantity: 0,
          currentQuantity: 0,
          maxAllowedQuantity: 0,
          toastId,
        });
        yield put(addToCartRejected());
        break;
      default:
        showCartToast({
          messageId: CART_MESSAGES.success,
          productName: productName || sku,
          addedQuantity,
          currentQuantity,
          maxAllowedQuantity,
          toastId,
        });

        emitter.dispatch(EVENT.addToCartFulfilled);

        yield put(addToCartFulfilled({ sku, quantity: addedQuantity }));
        break;
    }
  } catch (error) {
    showCartToast({
      messageId: CART_MESSAGES.unknownError,
      productName: `SKU: ${sku}`,
      addedQuantity: 0,
      currentQuantity: 0,
      maxAllowedQuantity: 0,
      toastId,
    });

    yield put(addToCartRejected());
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* addToCartSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeEvery(addToCart.type, addItemSaga),
  ]);
}
