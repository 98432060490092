import { combineSlices } from '@reduxjs/toolkit';
import { cartSlice as coreCartSlice } from './cart/redux';
import { cartStatusSlice } from './cartStatus/redux';
import { cartCostCenterSlice } from './costCenter/redux';

export const cartReducer = combineSlices(
  coreCartSlice,
  cartStatusSlice,
  cartCostCenterSlice,
);
