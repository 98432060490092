import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { createKey } from '@client/common/utils/key/key';
import { ProductGroupType } from '@client/content/common';
import { getAvailableLanguages } from '@client/i18n/utils/language';
import classNames from 'classnames';
import { Props } from '../';
import { ProductBoxes } from './ProductBoxes';
import './ProductBoxListV2.scss';

export const ProductBoxListV2 = ({ className, componentData }: Props): ReactElement => {
  const { pathname } = useLocation();
  const { products: productGroups } = componentData;

  if (!Array.isArray(productGroups)) {
    return null;
  }

  const isHomePage = [...getAvailableLanguages(), '']
    .some(lang => pathname.replaceAll('/', '') === lang);

  return (
    <>
      {productGroups.map(
        (productGroup: ProductGroupType, index: number): ReactElement | null => (
          <div
            className={classNames('cds-cms-GridRow', className)}
            key={createKey('products-group', index)}
          >
            <div className={ classNames('cds-cms-ModuleContainer', {
              'ProductListModuleV2--HomePage': isHomePage,
            })}>
              <ProductBoxes productGroup={productGroup} />
            </div>
          </div>
        ),
      )}
    </>
  );
};
