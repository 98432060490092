import { FormEvent, ReactElement } from 'react';
import { CertificationInfo } from '@client/cart/components/AddToCart/CertificationInfo';
import { useTranslate } from '@client/i18n/hooks';
import { Alert, ALERT_DISPLAY_TYPE, ALERT_STYLE_TYPE } from '@lib/components/Alert';
import { Button as ButtonComponent, BUTTON_ICON_POSITION, BUTTON_TYPE } from '@lib/components/Button';
import { Icon, ICON_NAME } from '@lib/components/Icon';
import { Props } from './';

const QUANTITY_ADDING = 1;

export const Button = ({
  className,
  dataAttributes,
  isBlocked,
  hasCertificationInfo,
  hasAccessCartPermissions,
  isLoggedIn,
  onAddToCart,
  settings,
  text,
}: Props): ReactElement => {
  const t = useTranslate();
  const {
    isBuyable,
    isSellout,
    quantity,
    isDisabledByCoreAssortment,
  } = settings;

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    onAddToCart(QUANTITY_ADDING);
  };

  const renderAlert = (message: string): ReactElement => (
    <Alert
      displayType={ALERT_DISPLAY_TYPE.text}
      styleType={ALERT_STYLE_TYPE.info}
    >
      {message}
    </Alert>
  );

  if (isDisabledByCoreAssortment) {
    return renderAlert(text.notInCoreAssortment);
  }

  if (isBlocked) {
    return renderAlert(text.blockedUser);
  }

  if (isSellout && quantity <= 0) {
    return renderAlert(text.isSoldOut);
  }

  if (!isBuyable) {
    return renderAlert(text.notBuyable);
  }

  const isDisabled = (isLoggedIn && !hasAccessCartPermissions)
    || isDisabledByCoreAssortment
    || hasCertificationInfo;

  return (
    <form className={className} onSubmit={handleSubmit}>
      <ButtonComponent
        iconPosition={BUTTON_ICON_POSITION.left}
        isDisabled={isDisabled}
        isFullWidth
        type={BUTTON_TYPE.submit}
        dataAttributes={dataAttributes}
      >
        <Icon name={ICON_NAME.cart} />
        {t('Add to cart', {}, 'Buy button label')}
      </ButtonComponent>

      {hasCertificationInfo && <CertificationInfo className={className} />}

      {isLoggedIn && !hasAccessCartPermissions && (
        <Alert
          displayType={ALERT_DISPLAY_TYPE.text}
          styleType={ALERT_STYLE_TYPE.error}
        >
          {text.notPermitted}
        </Alert>
      )}
    </form>
  );
};
