import { account } from './account';
import { DEFAULT_ROUTE, DEFAULT_ROUTE_PARAMS } from './constants';
import { kanban } from './kanban';
import { main } from './main';
import { order } from './order';
import { productConfigurator } from './productConfigurator';
import { punchout } from './punchout';
import { CombinedRouteType, ROUTES_NAMES, RouteType } from './types';

export * from './constants';
export * from './types';

const combinedRoutes: CombinedRouteType = {
  ...productConfigurator,
  ...main,
  ...order,
  ...account,
  ...punchout,
  ...kanban,
};

export const ROUTES: Readonly<Record<keyof typeof ROUTES_NAMES, RouteType>> = Object.freeze(combinedRoutes);

export { DEFAULT_ROUTE, DEFAULT_ROUTE_PARAMS };
