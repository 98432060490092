import { MouseEvent, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectCrumbs } from '@client/breadcrumbs/redux/reducer';
import { useDeviceType } from '@client/common/hooks';
import { useTranslate } from '@client/i18n/hooks';
import { ROUTES } from '@client/routes/components/Router/routes';
import { useRouterPush, useUrlCreator } from '@client/routes/hooks';
import { openLink } from '@client/routes/utils/url';
import { PrimaryLayout } from '@lib/components/PrimaryLayout';
import classNames from 'classnames';
import { Breadcrumbs as BreadcrumbsComponent } from 'b2b-components/theme/components/Breadcrumbs';
import { isMobile } from 'b2b-components/utils/device';
import { Props } from './';
import './Breadcrumbs.scss';

export const Breadcrumbs = ({ className }: Props): ReactElement => {
  const t = useTranslate();
  const push = useRouterPush();
  const urlCreator = useUrlCreator();
  const deviceType = useDeviceType();
  const crumbData = useSelector(selectCrumbs);
  const homeUrl = urlCreator(ROUTES.homepage);
  const { pathname } = useLocation();
  const onCrumbClick = (url?: string): OnAnchorClickType => (e: MouseEvent<HTMLAnchorElement>): void => {
    if (url !== homeUrl) {
      e.preventDefault();
      url && openLink(url, push, e);
    }
  };

  if (pathname.includes('my-account') && isMobile(deviceType)) {
    return null;
  }

  if (crumbData.length < 1) {
    return null;
  }

  return (
    <div className={classNames('Breadcrumbs', className)}>
      <PrimaryLayout>
        <BreadcrumbsComponent
          crumbs={[{ title: t('Home'), url: homeUrl }, ...crumbData]}
          deviceType={deviceType}
          onCrumbClick={onCrumbClick}
        />
      </PrimaryLayout>
    </div>
  );
};
