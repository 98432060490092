import { useEffect, useRef } from 'react';

export const usePrevious = <T>(value: T): T | typeof undefined => {
  const ref = useRef<T | typeof undefined>(undefined);

  useEffect(
    (): void => {
      ref.current = value;
    },
  );

  return ref.current;
};
