import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { RequestAddAddressType, RequestEditAddressType } from '@client/myAccount/addresses/api/Address.types';
import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddressType } from 'b2b-common/core/account/api/Address.types';

const NAME = 'address';

export const slice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {},
  reducers: {
    fetchAddress: (state, action: PayloadAction<{ code: string }>) => {
      state[action.payload.code] = RemoteRecordData.init();
      return state;
    },
    fetchAddressFulfilled: (state, action: PayloadAction<{ code: string, data: AddressType }>) => {
      state[action.payload.code] = RemoteRecordData.fulfill(action.payload.data);
      return state;
    },
    fetchAddressRejected: (state, action: PayloadAction<{ code: string; error: ErrorType }>) => {
      state[action.payload.code] = RemoteRecordData.reject(action.payload.error);
      return state;
    },
  },
});

export const editAddress = createAction<{ code: string; addressData: RequestEditAddressType; }>('editAddress');
export const addAddress = createAction<RequestAddAddressType>('addAddress');
export const removeAddress = createAction<string>('removeAddress');
export const {
  fetchAddress,
  fetchAddressFulfilled,
  fetchAddressRejected,
} = slice.actions;

const getState = (state: AppState) => state.myAccount.addresses.address;

export const selectAddress = createSelector(
  getState,
  (state) => (code: string) => new RemoteRecord<AddressType>(state[code]),
);
