import { SEARCH_ACTION } from '@client/analytics/redux';
import {
  AnalyticsComponentType,
  AnalyticsProductType,
  BaseAnalyticsType,
  TEALIUM_PROD_ACTION,
} from '@client/analytics/types/types';

export const BARCODE_VALUE = 'barcode_scan';
export const CATEGORY_SET_VALUE = 'nav';

export interface BarcodeAnalyticsComponentType extends
  Omit<AnalyticsComponentType, 'attribute2' | 'attribute5' | 'attribute6' | 'innerlocation'> {
  category: BaseAnalyticsType,
  subcategory: string[] | [''],
  location: string[] | [''],
  attribute1: string[] | [''],
  attribute3: TEALIUM_PROD_ACTION[] | SEARCH_ACTION[],
  attribute4: string[] | [''],
}

export interface BarcodeAnalyticsProductType extends Omit<AnalyticsProductType, 'hasService' | 'purchasable'> {
  quan?: string,
}

/* eslint-disable camelcase */
export enum BARCODE_ACTIONS_VALUES {
  'open' = 'open',
  'camera_activation_success' = 'camera_activation_success',
  'camera_activation_error' = 'camera_activation_error',
  'add_list' = 'add_list',
  'product_found' = 'list',
  'product_not_found' = 'product_not_found',
  'list_click' = 'list_click',
  'watchlist_add' = 'wishlist_add',
}
/* eslint-enable camelcase */
