import { useEffect, useId, useRef } from 'react';
import { useHotJarTracking } from '@client/analytics/hooks';
import { debounce } from '@client/common/utils/debounce';
import { useSyncContext } from '@client/context/hooks';
import * as Sentry from '@sentry/react';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { StickyFooterProvider } from 'b2b-components/context/StickyFooterContext';
import { StickyHeaderProvider } from 'b2b-components/context/StickyHeaderContext';
import { ScrollRestoreProvider } from 'b2b-apps/core/app/context/ScrollRestoreContext';
import { useClearSuggestionsAction } from 'b2b-apps/core/catalog/hooks';
import { RESOLUTION_TYPE, useDeviceType, useScrollLock, useToggle } from 'b2b-apps/core/common/hooks';
import { isMobile } from 'b2b-apps/core/common/utils/env';
import { getShouldInitContextSync } from 'b2b-apps/core/common/utils/syncContext';
import { userCookie } from 'b2b-apps/core/common/utils/userCookie/userCookie';
import { App as AppComponent } from './App';

export const AppContainer = Sentry.withProfiler(
  ({ isProductPageRoute }: { isProductPageRoute?: boolean}) => {
    useHotJarTracking();
    const id = useId();
    const deviceType = useDeviceType();
    const clearSuggestions = useClearSuggestionsAction();
    const [isAutosuggestOpened, toggleIsAutosuggestOpened] = useToggle();
    const autosuggestRef = useRef<HTMLDivElement | null>(null);
    const scrollLock = useScrollLock();

    const handleAutosuggestRefCallback = (autosuggestBox: HTMLDivElement | null): void => {
      scrollLock(autosuggestBox, RESOLUTION_TYPE.mobile);
      autosuggestRef.current = autosuggestBox;
    };

    const handleOpenAutosuggest = (): void => {
      if (!isAutosuggestOpened) {
        toggleIsAutosuggestOpened(true);
      }
    };

    const handleCloseAutosuggest = (): void => {
      if (isAutosuggestOpened) {
        toggleIsAutosuggestOpened(false);
        clearSuggestions();
      }
    };

    const handleScroll = debounce(() => {
      // FIX for iPhones - despite hidden vertical scrolling when the autosuggest box is open
      // sometimes is possible to scrolling, therefore closing box is forced
      if (autosuggestRef.current) {
        clearAllBodyScrollLocks();
        toggleIsAutosuggestOpened(false);
      }
    });

    useEffect(
      (): () => void => {
        if (isMobile(deviceType)) {
          window.addEventListener('scroll', handleScroll);
        }

        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      },
      [deviceType],
    );

    const triggerSyncContext = useSyncContext();

    useEffect(
      () => {
        if (getShouldInitContextSync()) {
          triggerSyncContext();
        }
        userCookie.persist(id);
      },
      [],
    );

    return (
      <ScrollRestoreProvider>
        <StickyFooterProvider>
          <StickyHeaderProvider>
            <AppComponent
              autosuggestRefCallback={handleAutosuggestRefCallback}
              isAutosuggestOpened={isAutosuggestOpened}
              onOpenAutosuggest={handleOpenAutosuggest}
              onCloseAutosuggest={handleCloseAutosuggest}
              isProductPageRoute={isProductPageRoute}
            />
          </StickyHeaderProvider>
        </StickyFooterProvider>
      </ScrollRestoreProvider>
    );
  },
);
