import { createContext, PropsWithChildren, ReactElement, useState } from 'react';

interface IContext {
  skus: string[];
  setSkus: (skus: string[]) => void;
}

export const ModularPageProductsGroupContext = createContext<IContext>({
  skus: [],
  setSkus: (skus: string[]) => skus,
});

export const ModularPageProductsGroupContextProvider = ({ children }: PropsWithChildren): ReactElement => {
  const [skus, setSkus] = useState<string[]>([]);

  return (
    <ModularPageProductsGroupContext.Provider value={{ skus, setSkus }}>
      {children}
    </ModularPageProductsGroupContext.Provider>
  );
};
