import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeliveredOrdersType } from 'b2b-common/core/account/api/DeliveredOrders.types';

export const deliveredOrdersSlice = createSlice({
  name: 'deliveredOrders',
  initialState: {},
  reducers: {
    fetchDeliveredOrders: (state, action: PayloadAction<number>) => {
      state = RemoteRecordData.init();
      return state;
    },
    fetchDeliveredOrdersFulfilled: (state, action: PayloadAction<{ data: DeliveredOrdersType }>) => {
      state = RemoteRecordData.fulfill(action.payload.data);
      return state;
    },
    fetchDeliveredOrdersRejected: (state, action: PayloadAction<{ error: ErrorType }>) => {
      state = RemoteRecordData.reject(action.payload.error);
      return state;
    },
    clearAccountDeliveredOrders: (state) => {
      state = RemoteRecordData.getDefault();
      return state;
    },
  },
});

export const {
  fetchDeliveredOrders,
  fetchDeliveredOrdersFulfilled,
  fetchDeliveredOrdersRejected,
  clearAccountDeliveredOrders,
} = deliveredOrdersSlice.actions;

const selectState = (state: AppState) => state.myAccount.deliveredOrders.deliveredOrders;

export const selectDeliveredOrders = createSelector(
  selectState,
  (state) => new RemoteRecord<DeliveredOrdersType>(state),
);
