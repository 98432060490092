import { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { debounce } from '@client/common/utils/debounce';
import { usePrevious } from 'b2b-apps/core/common/hooks';
import { getPathnameWithSearch } from 'b2b-apps/core/routes/utils/url';

type ScrollPosition = Record<string, number>;
interface ScrollRestoreContextValue {
  getScrollPosition: (url: string) => number;
}
interface ScrollRestoreProviderProps {
  children: ReactNode;
}

const SCROLL_DEBOUNCE = 250;
const DONT_RESTORE_ON = ['brand', 'category', 'search'];
const ScrollRestoreContext = createContext<ScrollRestoreContextValue | undefined>(undefined);
const MAX_ENTRIES = 10;

export const ScrollRestoreProvider = ({ children }: ScrollRestoreProviderProps): ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const prevPathName = usePrevious(location.pathname);
  const prevScrollPosition = useRef<ScrollPosition>({});
  const handleScroll = useMemo(() => () => {
    prevScrollPosition.current = {
      ...prevScrollPosition.current,
      [getPathnameWithSearch(location)]: window.pageYOffset,
    };
  }, [location]);
  const debouncedHandleScroll = useMemo(
    () => debounce(handleScroll, SCROLL_DEBOUNCE),
    [handleScroll],
  );

  useEffect(() => {
    if (!location.hash) {
      if (history.action === 'POP') {
        window.scrollTo(0, prevScrollPosition.current[getPathnameWithSearch(location)] || 0);
      } else {
        const currentPathname = location.pathname;
        const shouldRestore = prevPathName !== currentPathname
          || !DONT_RESTORE_ON.some((element) => currentPathname.includes(element));

        if (shouldRestore) {
          window.scrollTo(0, 0);
        }
      }
    }
  }, [location, history.action, prevPathName]);

  useEffect(() => {
    window.addEventListener('scroll', debouncedHandleScroll);

    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [debouncedHandleScroll]);

  // fit object to MAX_ENTRIES for preventing memory leak
  useEffect(() => {
    const keys = Object.keys(prevScrollPosition.current);
    if (keys.length > MAX_ENTRIES) {
      const newEntries = Object.fromEntries(
        keys.slice(1).map((key) => [key, prevScrollPosition.current[key]]),
      );
      prevScrollPosition.current = newEntries;
    }
  }, [location.pathname]);

  const getScrollPosition = useCallback((url: string): number => prevScrollPosition.current[url] || 0, []);
  const contextValue = useMemo(() => ({ getScrollPosition }), [getScrollPosition]);

  return (
    <ScrollRestoreContext.Provider value={contextValue}>
      {children}
    </ScrollRestoreContext.Provider>
  );
};

export const useScrollRestore = (): ScrollRestoreContextValue => {
  const context = useContext(ScrollRestoreContext);

  if (context === undefined) {
    throw new Error('useScrollRestore must be used within a ScrollRestoreProvider');
  }

  return context;
};
