import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData, RemoteRecordDataType } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductAccessoriesType } from './types';

const initialState: { [sku: string]: RemoteRecordDataType<ProductAccessoriesType> } = {};

const NAME = 'productAccessories';

export const productAccessoriesSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState,
  reducers: {
    fetchProductAccessories: (state, action: PayloadAction<{ sku: string }>) => {
      state[action.payload.sku] = RemoteRecordData.init();
      return state;
    },
    fetchProductAccessoriesFulfilled: (state, action: PayloadAction<{ sku: string, data: ProductAccessoriesType }>) => {
      state[action.payload.sku] = RemoteRecordData.fulfill(action.payload.data);
      return state;
    },
    fetchProductAccessoriesRejected: (state, action: PayloadAction<{ sku: string, error: ErrorType }>) => {
      state[action.payload.sku] = RemoteRecordData.reject(action.payload.error);
      return state;
    },
  },
});

export const {
  fetchProductAccessories,
  fetchProductAccessoriesFulfilled,
  fetchProductAccessoriesRejected,
} = productAccessoriesSlice.actions;

const getState = (state: AppState) => state.product.productAccessories;

export const selectProductAccessoriesBySku = createSelector(
  [getState, (state, sku: string) => sku],
  (state, sku) => new RemoteRecord<ProductAccessoriesType>(state[sku]),
);
