import { SOURCE_TYPE } from '@client/content/common';
import { ContentEntryType } from 'b2b-common/core/content/Content.types';

const prepareData = (previousValue: any, componentData: ContentEntryType, currentIndex: number): any => ({
  queries: [...previousValue.queries,
    componentData?.source_type === SOURCE_TYPE.query && {
      ...componentData,
      position: currentIndex,
    },
  ].filter(Boolean),
  skus: [...previousValue.skus,
    componentData?.source_type !== SOURCE_TYPE.query && {
      ...componentData,
      position: currentIndex,
    },
  ].filter(Boolean),
});

export const categorizeProductGroupV2 = (data: ContentEntryType, isModule: boolean): any => {
  if (isModule) {
    return data?.container__main_content?.filter((item: any) => Object.hasOwn(item, 'container__module_products_group_v2'))
      ?.reduce((
        previousValue: any,
        currentValue: any,
        currentIndex: number,
      ) => {
        const componentData = currentValue?.container__module_products_group_v2
          ?.container__module_products_group_v2
          ?.module__products_group_v2
          ?.products[0];

        return prepareData(previousValue, componentData, currentIndex);
      }, {
        queries: [],
        skus: [],
      });
  }

  return data?.container__main_content?.filter((item: any) => Object.hasOwn(item, 'products_group_v2'))
    ?.reduce((
      previousValue: any,
      currentValue: any,
      currentIndex: number,
    ) => {
      const componentData = currentValue?.products_group_v2?.products[0];

      return prepareData(previousValue, componentData, currentIndex);
    }, {
      queries: [],
      skus: [],
    });
};
