import { lazy } from 'react';
import { ModularPageProductsGroupContextProvider } from '@client/content/components/ModularPage/Context/ModularPageProductsGroupContext';
import { HomePage } from '@client/home/components/HomePage';
import { getLocalisedPaths } from '@client/routes/utils/paths';
import { DEFAULT_ROUTE, UNTRANSLATED_ROUTE } from './constants';
import { MAIN_ROUTES_ENUM, MainRouteType } from './types';


export const main: MainRouteType = {
  [MAIN_ROUTES_ENUM.homepage]: {
    ...DEFAULT_ROUTE,
    paths: getLocalisedPaths('', true),
    exact: true,
    render: function renderHomePage() {
      return (
        <ModularPageProductsGroupContextProvider>
          <HomePage />
        </ModularPageProductsGroupContextProvider>
      );
    },
  },
  [MAIN_ROUTES_ENUM.category]: {
    ...UNTRANSLATED_ROUTE,
    paths: getLocalisedPaths('category'),
    component: lazy(() => import(
      /* webpackChunkName: "category" */
      '@client/catalog/components/CategoryPage'
    )),
    allowTranslation: false,
  },
  [MAIN_ROUTES_ENUM.search]: {
    ...UNTRANSLATED_ROUTE,
    paths: getLocalisedPaths('search'),
    component: lazy(() => import(
      /* webpackChunkName: "search" */
      '@client/catalog/components/SearchPage'
    )),
    allowTranslation: false,
  },
  [MAIN_ROUTES_ENUM.brand]: {
    ...UNTRANSLATED_ROUTE,
    paths: getLocalisedPaths('brand'),
    component: lazy(() => import(
      /* webpackChunkName: "brand" */
      '@client/catalog/components/BrandPage'
    )),
    allowTranslation: false,
  },
  [MAIN_ROUTES_ENUM.manufacturer]: {
    ...DEFAULT_ROUTE,
    paths: getLocalisedPaths('manufacturer/:manufacturer'),
    component: lazy(() => import(
      /* webpackChunkName: "manufacturer" */
      '@client/manufacturer/components/ViewPage'
    )),
  },
  [MAIN_ROUTES_ENUM.manufacturers]: {
    ...DEFAULT_ROUTE,
    paths: getLocalisedPaths('manufacturers'),
    component: lazy(() => import(
      /* webpackChunkName: "manufacturers" */
      '@client/manufacturer/components/ListPage'
    )),
  },
  [MAIN_ROUTES_ENUM.product]: {
    ...DEFAULT_ROUTE,
    paths: getLocalisedPaths('product/:sku', true),
    component: lazy(() => import(
      /* webpackChunkName: "product" */
      '@client/product/components/ProductPage'
    )),
  },
  [MAIN_ROUTES_ENUM.page]: {
    ...DEFAULT_ROUTE,
    paths: getLocalisedPaths('pages/:entryId(.+)', true),
    component: lazy(() => import(
      /* webpackChunkName: "pages" */
      '@client/content/components/ContentPage'
    )),
  },
  [MAIN_ROUTES_ENUM.productsCompare]: {
    ...DEFAULT_ROUTE,
    paths: getLocalisedPaths('products/compare/:skus?'),
    component: lazy(() => import(
      /* webpackChunkName: "products/compare" */
      '@client/product/components/ProductsComparePage'
    )),
  },
  [MAIN_ROUTES_ENUM.unlocalizedContent]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('unlocalized-content'),
    component: lazy(() => import(
      /* webpackChunkName: "unlocalized-content" */
      '@client/content/components/UnlocalizedContentPage'
    )),
  },
  [MAIN_ROUTES_ENUM.trackingOptOut]: {
    ...DEFAULT_ROUTE,
    paths: getLocalisedPaths('tracking-opt-out'),
    component: lazy(() => import(
      /* webpackChunkName: "tracking-opt-out" */
      '@client/settings/components/TrackingOptOutPage'
    )),
  },
};
