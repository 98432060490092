import { ReactElement, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useCmsAnalytics } from '@client/analytics/hooks';
import { useScrollRestore } from '@client/app/context/ScrollRestoreContext';
import { useBreadcrumbs } from '@client/breadcrumbs/hooks';
import { RemoteRecordHandler } from '@client/common/components/RemoteRecordHandler';
import { ModularPage } from '@client/content/components/ModularPage/ModularPage';
import { useHomepage } from '@client/content/hooks';
import { useTranslate } from '@client/i18n/hooks';
import { getPathnameWithSearch } from '@client/routes/utils/url';

export const HomePage = (): ReactElement => {
  const t = useTranslate();
  const scrollContext = useScrollRestore();
  const content = useHomepage();
  const history = useHistory();
  const location = useLocation();

  useBreadcrumbs([]);
  useCmsAnalytics(content);

  useEffect((): void => {
    if (content.isLoaded && history.action === 'POP') {
      const scrollPosition = scrollContext.getScrollPosition(getPathnameWithSearch(location));
      window.scrollTo(0, scrollPosition);
    }
  }, [content.isLoaded, history.action, location, scrollContext]);

  return (
    <RemoteRecordHandler
      remoteRecord={content}
      shouldCheckEmptyRecord
    >
      <ModularPage
        content={content}
        title={t('Shop Homepage\'s title')}
      />
    </RemoteRecordHandler>
  );
};
