import { ReactElement, ReactNode, useEffect } from 'react';
import { FormFieldCheckbox } from '@client/common/components/Form/FormFieldCheckbox';
import { FormFieldSelect, SELECT_STYLE_TYPE } from '@client/common/components/Form/FormFieldSelect';
import { FormFieldSelectable } from '@client/common/components/Form/FormFieldSelectable';
import { FORM_FIELD_TEXT_TYPE, FormFieldText, TEXT_STYLE_TYPE } from '@client/common/components/Form/FormFieldText';
import { FormFieldTextArea } from '@client/common/components/Form/FormFieldTextArea';
import { TOAST_TYPE } from '@client/common/components/Toast';
import { usePrevious } from '@client/common/hooks';
import { createKey } from '@client/common/utils/key/key';
import { showToast } from '@client/common/utils/showToast';
import { isEmailField } from '@client/content/utils/forms';
import { useTranslate } from '@client/i18n/hooks';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '@lib/components/Button';
import { Checkbox } from '@lib/components/Form/Checkbox/Checkbox';
import { MARKING_TYPE } from '@lib/components/Form/common';
import { File } from '@lib/components/Form/File';
import { Radio } from '@lib/components/Form/Radio';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import md5 from 'md5';
import { FormElementType } from 'b2b-common/core/content/Content.types';
import { GLOBAL_ERRORS_KEY } from '../FormMapper';
import { FORM_ELEMENT_TYPE } from '../FormMapperContainer';
import { TermsModal } from '../TermsModal';
import { Props } from './Body.types';
import { FormTable } from './FormTable';
import './Body.scss';

export const TERMS_REGEXP = /\[\[(.*?)\]\]/g;

export enum TEXT_INPUT_GRID_LENGTH {
  address = 6,
  addressAddition = 6,
  city = 6,
  companyName = 6,
  contactName = 6,
  dayOfBirth = 4,
  email = 4,
  freeText = 9,
  firstName = 4,
  lastName = 4,
  message = 9,
  name = 6,
  phone = 4,
  remark = 9,
  zipCode = 3,
}

export const MAX_FILES_AMOUNT = 10;
export const MAX_FILES_SIZE = 10000000;

type TextInputType = TEXT_INPUT_GRID_LENGTH;

export const Body = ({
  className,
  convertFileToBase64,
  displayFormError,
  elements,
  getAllowedFileTypes,
  getAllowedFileExtensions,
  isFileNameDisplayed,
  isTermsModalOpened,
  onTermsModalToggle,
  submitData,
}: Props): ReactElement => {
  const {
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    resetForm,
    touched,
    values,
    setFieldValue,
  } = useFormikContext();
  const t = useTranslate();
  const rootClassName = classNames('cds-cms-Form', className);
  const text = {
    added: t('Added'),
    add: t('Choose File'),
    addMultiple: t('Choose Files'),
    dragActive: t('Drop file here'),
    dragActiveMultiple: t('Drop files here'),
    dragInactive: t('No file chosen'),
    dragInactiveMultiple: t('No files chosen'),
    submitMultiple: t('Upload Files'),
    addNextItem: t('Add next item'),
    allowedFileTypes: t('Allowed file extensions: '),
    error: t('Error'),
    failedToOpenFile: t('Failed to open file'),
    maxFilesSize: t(
      'Total size of uploaded files must be under {maxSize}MB',
      {
        maxSize: Math.floor(MAX_FILES_SIZE / 1000000),
      },
    ),
    remove: t('Remove file'),
    submit: t('submit'),
    submitting: t('Submitting...'),
  };

  const isDataSubmitting: boolean = submitData && submitData.isLoading;
  const wasDataSubmitting = usePrevious(isDataSubmitting);
  const fieldText = {
    required: '*',
    optional: '(optional)',
  };

  useEffect(
    () => {
      if (wasDataSubmitting && submitData.isLoaded) {
        resetForm();
      }
    },
    [submitData.isLoaded, wasDataSubmitting],
  );

  useEffect(
    () => {
      if (isSubmitting && !isDataSubmitting && wasDataSubmitting) {
        setSubmitting(false);
      }
    },
    [isDataSubmitting, isSubmitting, wasDataSubmitting],
  );

  const renderCheckboxes = (element: Record<string, any>) => ({
    isOptionChecked,
    onOptionChange,
  }: {
      isOptionChecked: (value: any) => boolean,
      onOptionChange: (value: any) => any;
    }): ReactElement => (
    element.options.map((option: any, idx: number) => (
      <Checkbox
        isChecked={isOptionChecked(option)}
        key={option}
        label={option}
        onChange={() => onOptionChange(option)}
        name={element.fieldId}
      />
    ))
  );

  const renderRadioButtons = (element: Record<string, any>) => ({
    isOptionChecked,
    onOptionChange,
  }: {
      isOptionChecked: (value: any) => boolean,
      onOptionChange: (value: any) => any;
    }): ReactElement => element.options.map((option: any, idx: number) => (
      <Radio
        isChecked={isOptionChecked(option)}
        key={option}
        label={option}
        onChange={() => onOptionChange(option)}
        name={element.fieldId}
      />
  ));

  const termsElement = elements.find(
    (el) => el.fieldId === FORM_ELEMENT_TYPE.terms,
  );

  const submitElementText = elements.find((el) => el.type === FORM_ELEMENT_TYPE.submit)?.value
    || text.submit;
  const termsRegexResults = termsElement && termsElement.label && TERMS_REGEXP.exec(termsElement.label);

  const onFileSuccess = (fieldId: string) => (acceptedFiles: Record<string, any>[]): void => {
    setFieldValue(fieldId, []);
    const arrOfFiles: any[] = [];
    acceptedFiles.forEach((file) => {
      convertFileToBase64(file).then(
        (fileBinary) => {
          arrOfFiles.push({
            data: fileBinary,
            fileName: file.name,
            size: file.size,
          });
          setFieldValue(fieldId, arrOfFiles);
        },
        () => {
          showToast(TOAST_TYPE.error, text.error, text.failedToOpenFile);
        },
      );
    });
  };

  return (
    <form className={rootClassName} onSubmit={handleSubmit}>
      {elements.map((element: Record<string, any>) => {
        const {
          fieldId,
          label,
          multiline,
          options,
          required,
          type,
        } = element;

        switch (type) {
          case FORM_ELEMENT_TYPE.label:
            return (
              <div
                className="cds-cms-Form-Field cds-cms-Form-Label"
                dangerouslySetInnerHTML={{
                  __html: label,
                }}
                key={createKey(type, md5(label))}
              />
            );

          case FORM_ELEMENT_TYPE.checkbox:
            return (
              <div
                className="cds-cms-Form-Field"
                key={createKey(type, fieldId)}
              >
                <FormFieldSelectable
                  name={fieldId}
                  key={createKey(type, fieldId)}
                  innerComponentProps={{
                    isMultiple: true,
                    render: renderCheckboxes(element),
                    markingType: required
                      ? MARKING_TYPE.required
                      : MARKING_TYPE.none,
                    label: (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: label,
                        }}
                      />
                    ),
                    text: fieldText,
                    error: displayFormError(fieldId, [
                      errors[fieldId],
                      touched[fieldId],
                    ]),
                  }}
                />
              </div>
            );

          case FORM_ELEMENT_TYPE.fileUpload: {
            const maxFilesAmount = element.maxFiles || MAX_FILES_AMOUNT;
            return (
              <div
                className="cds-cms-Form-FileUpload"
                key={createKey(type, fieldId)}
              >
                <label className="cds-Label">
                  <span>{label}</span>
                  {required && (
                    <span className="cds-Label-Required">
                      {fieldText.required}
                    </span>
                  )}
                </label>
                <File
                  isFileNameDisplayed={isFileNameDisplayed}
                  isMultipleAllowed={true}
                  acceptedFileTypes={getAllowedFileTypes(element as any)}
                  isUploadButtonDisplayed={false}
                  onSuccess={onFileSuccess(fieldId)}
                  text={text}
                />
                <ul className="cds-cms-Form-FileInfo cds-List cds-List--Bulleted">
                  <li>
                    {text.allowedFileTypes}
                    {getAllowedFileExtensions(element as any, ', ')}
                  </li>
                  <li>
                    {t(
                      [
                        'Only one file is allowed',
                        'Up to {maxFilesAmount} files are allowed',
                        'maxFilesAmount',
                      ],
                      {
                        maxFilesAmount,
                      },
                    )}
                  </li>
                  <li>{text.maxFilesSize}</li>
                </ul>
                <span className="cds-cms-Form-FileError">
                  {displayFormError(fieldId, [
                    errors[fieldId],
                    touched[fieldId],
                  ])}
                </span>
                {errors?.[GLOBAL_ERRORS_KEY]?.[fieldId] && touched && (
                  <span className="cds-cms-Form-FileError">
                    {
                      displayFormError(fieldId, [errors?.[GLOBAL_ERRORS_KEY]?.[fieldId], touched])
                    }
                  </span>
                )}
              </div>
            );
          }
          case FORM_ELEMENT_TYPE.radio:
            return (
              <div
                className="cds-cms-Form-Field"
                key={createKey(type, fieldId)}
              >
                <FormFieldSelectable
                  name={fieldId}
                  innerComponentProps={{
                    isMultiple: false,
                    render: renderRadioButtons(element),
                    markingType: required
                      ? MARKING_TYPE.required
                      : MARKING_TYPE.none,
                    label: (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: label,
                        }}
                      />
                    ),
                    text: fieldText,
                    error: displayFormError(fieldId, [
                      errors[fieldId],
                      touched[fieldId],
                    ]),
                  }}
                />
              </div>
            );

          case FORM_ELEMENT_TYPE.select:
            return (
              <div
                className="cds-cms-Form-Field cds-cms-Form-Select"
                key={createKey(type, fieldId)}
              >
                <div
                  className="cds-cms-Form-SelectLabel"
                  dangerouslySetInnerHTML={{
                    __html: label,
                  }}
                />
                <FormFieldSelect
                  name={fieldId}
                  innerComponentProps={{
                    error: displayFormError(fieldId, [
                      errors[fieldId],
                      touched[fieldId],
                    ]),
                    markingType: required
                      ? MARKING_TYPE.required
                      : MARKING_TYPE.none,
                    text: fieldText,
                    options: options.map((option: any) => ({
                      label: option,
                      value: option,
                    })),
                    styleType: SELECT_STYLE_TYPE.underlined,
                    name: fieldId,
                  }}
                />
              </div>
            );

          case FORM_ELEMENT_TYPE.table:
            return (
              <FormTable
                element={element as FormElementType}
                fieldName={fieldId}
                fieldText={fieldText}
                displayFormError={displayFormError}
                globalError={errors?.[GLOBAL_ERRORS_KEY]?.[fieldId]}
                key={createKey(type, fieldId)}
              />
            );

          case FORM_ELEMENT_TYPE.terms: {
            let modalTrigger: ReactNode;
            let modalTriggerText = '';

            if (typeof label === 'string') {
              if (!termsRegexResults || !termsRegexResults[0]) {
                modalTrigger = (
                  <button
                    type="button"
                    className="cds-ButtonLink"
                    onClick={onTermsModalToggle}
                  >
                    {label}
                  </button>
                );
              } else {
                modalTriggerText = termsRegexResults[1] as string;

                const replaceTrigger = (label: string, value: string) => {
                  if (!value) {
                    return label;
                  }

                  return (
                    <span>
                      {label.split(value).reduce(
                        (
                          prev: ReactNode[],
                          current: ReactNode,
                          i: number,
                        ) => {
                          if (!i) {
                            return [current];
                          }

                          return prev.concat(
                            <button
                              key="triggerButton"
                              type="button"
                              className="cds-ButtonLink"
                              onClick={onTermsModalToggle}
                            >
                              {modalTriggerText}
                            </button>,
                            current,
                          );
                        },
                        [],
                      )}
                    </span>
                  );
                };

                modalTrigger = replaceTrigger(label, termsRegexResults[0]);
              }
            }

            return (
              <div
                className="cds-cms-Form-Field cds-cms-Form-Terms"
                key={createKey(type, fieldId)}
              >
                <FormFieldSelectable
                  name={fieldId}
                  key={createKey(type, fieldId)}
                  innerComponentProps={{
                    isMultiple: false,
                    render: function render() {
                      return (
                        <FormFieldCheckbox
                          name={fieldId}
                          innerComponentProps={{
                            label: modalTrigger,
                            isChecked: values[fieldId],
                          }}
                          onChange={() => {
                            setFieldValue(fieldId, !values[fieldId]);
                          }}
                        />
                      );
                    },
                    text: fieldText,
                    error: displayFormError(fieldId, [
                      errors[fieldId],
                      touched[fieldId],
                    ]),
                  }}
                />
              </div>
            );
          }
          case FORM_ELEMENT_TYPE.text: {
            if (multiline) {
              return (
                <div
                  className="cds-cms-Form-Field"
                  key={createKey(type, fieldId)}
                >
                  <FormFieldTextArea
                    name={fieldId}
                    innerComponentProps={{
                      className: 'cds-cms-Form-Text',
                      markingType: required
                        ? MARKING_TYPE.required
                        : MARKING_TYPE.none,
                      text: { ...fieldText, label: label },
                      error: displayFormError(fieldId, [
                        errors[fieldId],
                        touched[fieldId],
                      ]),
                      styleType: TEXT_STYLE_TYPE.underlinedFloatingLabel,
                    }}
                  />
                </div>
              );
            }

            let colClassName: string = TEXT_INPUT_GRID_LENGTH[fieldId as TextInputType]
              ? `col-md-${TEXT_INPUT_GRID_LENGTH[fieldId as TextInputType]}`
              : 'col-md-9';

            if (isEmailField(fieldId)) {
              colClassName = `col-md-${TEXT_INPUT_GRID_LENGTH.email}`;
            }

            return (
              <div className="row" key={createKey(type, fieldId)}>
                <div className={classNames('cds-cms-Form-Field', colClassName)}>
                  <FormFieldText
                    name={fieldId}
                    innerComponentProps={{
                      className: 'cds-cms-Form-Text',
                      markingType: required
                        ? MARKING_TYPE.required
                        : MARKING_TYPE.none,
                      error: displayFormError(fieldId, [
                        errors[fieldId],
                        touched[fieldId],
                      ]),
                      styleType: TEXT_STYLE_TYPE.underlinedFloatingLabel,
                      text: { ...fieldText, label: label },
                      type: isEmailField(fieldId)
                        ? FORM_FIELD_TEXT_TYPE.email
                        : FORM_FIELD_TEXT_TYPE.text,
                    }}
                  />
                </div>
              </div>
            );
          }
          default:
            return null;
        }
      })}
      <Button
        className="cds-cms-Form-Submit"
        isDisabled={isSubmitting}
        key={createKey(FORM_ELEMENT_TYPE.submit)}
        size={BUTTON_SIZE.small}
        type={BUTTON_TYPE.submit}
      >
        {isSubmitting ? text.submitting : submitElementText}
      </Button>
      {termsElement && (
        <TermsModal
          isOpen={isTermsModalOpened}
          toggleModal={onTermsModalToggle}
          modalContent={termsElement.text || ''}
        />
      )}
    </form>
  );
};
