import { TEALIUM_PROD_ACTION, TEALIUM_PROD_LIST } from '@client/analytics/types/types';
import { preparePrice, prepareQuantity, prepareStock } from '@client/analytics/utils/analytics';
import { ANALYTICS_ATTRIBUTES, BaseBuilder } from '@client/analytics/utils/builders/BaseBuilder';
import { ProductAccessoryType } from '@client/product/redux/productAccessories/types';
import { ProductFullType } from '@client/product/redux/products/full/types';
import { ProductType, WithPosition } from 'b2b-common/core/catalog/Suggestions.types';
import { ProductTileType } from 'b2b-common/core/product/api/ProductTile.types';

interface SearchAnalyticsSearchProductProperty {
  action: TEALIUM_PROD_ACTION;
  categories: string[];
  position: number;
  price: string;
  quantity: string;
  list: TEALIUM_PROD_LIST;
  id?: string;
  stock?: string;
}

type CommonProduct = ProductTileType | ProductFullType | ProductAccessoryType | ProductType;
export type AnalyticsSearchProducts= WithPosition<ProductType> | CommonProduct | CommonProduct[];

export class SearchAnalyticsSearchProductPropertyBuilder extends BaseBuilder<SearchAnalyticsSearchProductProperty> {
  private action: TEALIUM_PROD_ACTION;
  private quantity: string | number;
  private list: TEALIUM_PROD_LIST;
  private products: WithPosition<ProductType> | (ProductTileType |
    ProductFullType |
    ProductAccessoryType)[] | any;

  private productList: any;
  private readonly isArray: boolean;
  private readonly isQuantityDisabled: boolean;

  public constructor({ convertToArray = false, disableQuantity = false }: {
    convertToArray?: boolean,
    disableQuantity?: boolean
  }) {
    super();
    this.isArray = convertToArray;
    this.isQuantityDisabled = disableQuantity;
  }

  protected attributes: ANALYTICS_ATTRIBUTES[] = [
    ANALYTICS_ATTRIBUTES.action,
    ANALYTICS_ATTRIBUTES.categories,
    ANALYTICS_ATTRIBUTES.pos,
    ANALYTICS_ATTRIBUTES.price,
    ANALYTICS_ATTRIBUTES.list,
    ANALYTICS_ATTRIBUTES.id,
    ANALYTICS_ATTRIBUTES.stock,
  ];

  protected analyticsResultObject: Record<string, any> = {
    [ANALYTICS_ATTRIBUTES.action]: '',
    [ANALYTICS_ATTRIBUTES.categories]: [],
    [ANALYTICS_ATTRIBUTES.pos]: '',
    [ANALYTICS_ATTRIBUTES.price]: '',
    [ANALYTICS_ATTRIBUTES.quan]: '',
    [ANALYTICS_ATTRIBUTES.list]: '',
    [ANALYTICS_ATTRIBUTES.stock]: '',
  };

  public setAction(action: TEALIUM_PROD_ACTION): SearchAnalyticsSearchProductPropertyBuilder {
    this.action = action;
    return this;
  }

  public setQuantity(quantity: string | number): SearchAnalyticsSearchProductPropertyBuilder {
    this.quantity = quantity;
    return this;
  }

  public setList(list: TEALIUM_PROD_LIST): SearchAnalyticsSearchProductPropertyBuilder {
    this.list = list;
    return this;
  }

  public setProductsList(products: any): SearchAnalyticsSearchProductPropertyBuilder {
    this.productList = products;
    return this;
  }

  public setProducts(products: AnalyticsSearchProducts): SearchAnalyticsSearchProductPropertyBuilder {
    this.products = products;
    return this;
  }

  private prepareCategories(product: any): string[] {
    if (product.analytics.categories.every((category: string) => typeof category === 'object')) {
      return product.analytics.categories.map((category: any) => category.label);
    }
    return product.analytics.categories;
  }

  public build(): any {
    if (Array.isArray(this.products)) {
      this.analyticsResultObjectArray = this.products.map((product, index) => ({
        attributes: [
          ...this.attributes,
        ],
        analyticsResultObject: {
          [ANALYTICS_ATTRIBUTES.action]: this.action,
          [ANALYTICS_ATTRIBUTES.categories]: this.prepareCategories(product),
          [ANALYTICS_ATTRIBUTES.pos]: `${index + 1}`,
          [ANALYTICS_ATTRIBUTES.price]: preparePrice(product.effectivePricing.mainPrice),
          [ANALYTICS_ATTRIBUTES.list]: this.list,
          [ANALYTICS_ATTRIBUTES.id]: product.sku,
          [ANALYTICS_ATTRIBUTES.stock]: prepareStock(product.settings.quantity),
        },
      }));
    } else {
      this.attributes = [
        ...this.attributes,
        ...(this.isQuantityDisabled ? [] : [ANALYTICS_ATTRIBUTES.quan]),
      ].filter(Boolean);
      this.analyticsResultObject.action = this.action;
      this.analyticsResultObject.categories = this.prepareCategories(this.products);
      this.analyticsResultObject.pos = this.products.position || (this.productList
        .findIndex((product: any) => product.sku === this.products.sku) + 1).toString();
      this.analyticsResultObject.price = preparePrice(this.products.effectivePricing.mainPrice);
      if (!this.isQuantityDisabled) {
        this.analyticsResultObject.quan = prepareQuantity(this.quantity);
      }
      this.analyticsResultObject.list = this.list;
      this.analyticsResultObject.id = this.products.sku;
      this.analyticsResultObject.stock = prepareStock(this.products.settings.quantity);
    }

    return {
      [ANALYTICS_ATTRIBUTES.prod]: this.isArray ? [super.build()] : super.build(),
    };
  }
}
