import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContactInformationType } from 'b2b-common/core/account/api/Details.types';
import { DetailsType } from './types';

const NAME = 'details';

export const slice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: RemoteRecordData.getDefault(),
  reducers: {
    fetchDetails: (state) => {
      state = RemoteRecordData.init();
      return state;
    },
    fetchDetailsFulfilled: (state, action: PayloadAction<DetailsType>) => {
      state = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchDetailsRejected: (state, action: PayloadAction<ErrorType>) => {
      state = RemoteRecordData.reject(action.payload);
      return state;
    },
    updateContactInformation: (state, action: PayloadAction<ContactInformationType>) => state,
  },
});

export const {
  fetchDetails,
  fetchDetailsFulfilled,
  fetchDetailsRejected,
  updateContactInformation,
} = slice.actions;

const getState = (state: AppState) => state.myAccount.accountDetails.details;

export const selectDetails = createSelector(
  getState,
  state => new RemoteRecord<DetailsType>(state),
);
