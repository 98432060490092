import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { CheckoutDetails } from 'b2b-common/core/checkout/CheckoutDetails.types';

export class Order {
  public static place(checkoutId: string, checkoutDetails: CheckoutDetails): RequestConfigType {
    return RequestConfig.post(`/checkout/${checkoutId}/order`,
      { ...checkoutDetails },
      { withLocalization: true });
  }
}
