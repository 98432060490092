import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { stringify } from '@client/common/utils/queryParams';
import { ManufacturersType } from '@client/manufacturer/redux/manufacturers/types';
import { AxiosResponse } from 'axios';

export const MAX_RESULTS_NUMBER = 100;

export class Manufacturers {
  public static fetch(filter = ''): RequestConfigType {
    return RequestConfig.get(
      `/manufacturers${
        filter ? `?filter=${encodeURIComponent(filter)}` : ''
      }`,
      {
        withLocalization: false,
      },
    );
  }

  public static mapResponseToState(response: AxiosResponse): ManufacturersType {
    return response.data;
  }

  public static search(
    query = '',
    resultsNumber: number = MAX_RESULTS_NUMBER,
  ): RequestConfigType {
    const params = {
      pageSize: resultsNumber,
      query,
      startItem: 0,
    };

    return RequestConfig.get(
      `/manufacturers/search?${stringify(
        params,
      )}`,
      {
        withLocalization: false,
      },
    );
  }

  public static mapSearchResponseToState(response: AxiosResponse): ManufacturersType {
    return response.data;
  }
}
