import { ReactElement, useEffect, useState } from 'react';
import { useProductsAnalytics } from '@client/analytics/context/products/ProductsAnalyticsContext';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { SOURCE_TYPE, VIEW_TYPE } from '@client/content/common';
import {
  ProductBoxes,
} from '@client/content/components/ModularPage/Modules/ProductBoxListV2/ProductBoxes/ProductBoxes';
import { useProductsFromQuery } from '@client/content/hooks';
import { extractMptIdFromString, extractSkuNumbersFromString } from '@client/content/utils/productsGroup';
import { useUserData } from '@client/context/hooks';
import { useProductTilesAction, useProductTilesData } from '@client/product/hooks';
import { isProductGroupLoaded } from '@client/product/utils/product';
import { PRODUCT_BOX_STYLE_TYPE, ProductBoxTypeType } from '@lib/components/ProductList';
import { ProductTileType } from 'b2b-common/core/product/api/ProductTile.types';
import { Props } from './';

const MIN_COUNT_PRODUCTS_FOR_SLIDER = 4;
const HARD_PRODUCTS_LIMIT = 100;

export const ProductBoxesContainer = ({ productGroup }: Props): ReactElement => {
  const { isLoggedIn } = useUserData();
  const productsFromQueryRecord = useProductsFromQuery(productGroup);
  const productTilesAction = useProductTilesAction();
  const {
    source_type: sourceType,
    source_of_products: sourceOfProducts,
    limit: limitStr,
    view,
    mpt_id: mptId,
    label,
  } = productGroup;
  const cmsLimit = parseInt(limitStr, 10);
  const limit = Math.min(cmsLimit, HARD_PRODUCTS_LIMIT);
  const getSkus = (productsFromQuery: string[]): string[] => sourceType === SOURCE_TYPE.query
    ? productsFromQuery.slice(0, limit)
    : extractSkuNumbersFromString(sourceOfProducts);
  const originalSkus = getSkus(productsFromQueryRecord.data || []);
  const [skus, setSkus] = useState(originalSkus.slice(0, limit));
  const productTilesData = useProductTilesData(skus);
  const productsMptIds = extractMptIdFromString(sourceOfProducts);

  const areProductTilesLoaded = Object
    .values(productTilesData)
    .every(productTileRecord => productTileRecord.isLoaded);
  const numberOfLoadedProducts = Object
    .values(productTilesData)
    .filter(productTileRecord => productTileRecord.data !== null)
    .length;

  useEffect(
    () => {
      if (areProductTilesLoaded && numberOfLoadedProducts < limit) {
        const difference = limit - numberOfLoadedProducts;

        setSkus(prevState => [
          ...prevState.filter(sku => productTilesData[sku].data !== null),
          ...originalSkus.slice(prevState.length, prevState.length + difference),
        ]);
      }
    },
    [areProductTilesLoaded, numberOfLoadedProducts, limit, originalSkus.length],
  );

  const getCountSliderLoadedProductTiles = (
    productTilesData: Record<string, RemoteRecord<ProductTileType>>,
    skus: string[],
  ): number => {
    if (view !== VIEW_TYPE.slider) {
      return 0;
    }

    return skus.filter(
      (sku) => !productTilesData[sku]?.hasFailed
        && productTilesData[sku]?.isLoaded
        && productTilesData[sku]?.data,
    ).length;
  };

  const getCountSliderLoadingProductTiles = (
    productTilesData: Record<string, RemoteRecord<ProductTileType>>,
    skus: string[],
  ): number => {
    if (view !== VIEW_TYPE.slider) {
      return 0;
    }

    return skus.filter((sku) => productTilesData[sku]?.isLoading).length;
  };

  const countSliderLoadedProductTiles = getCountSliderLoadedProductTiles(productTilesData, skus);
  const countSliderLoadingProductTiles = getCountSliderLoadingProductTiles(productTilesData, skus);
  const canCreateSlider = countSliderLoadedProductTiles >= MIN_COUNT_PRODUCTS_FOR_SLIDER;

  const getStyleType = (): ProductBoxTypeType => {
    switch (view) {
      case VIEW_TYPE.list:
        return PRODUCT_BOX_STYLE_TYPE.list;

      case VIEW_TYPE.table:
        return PRODUCT_BOX_STYLE_TYPE.table;

      default:
        return PRODUCT_BOX_STYLE_TYPE.tile;
    }
  };

  useEffect((): void => {
    if (view === VIEW_TYPE.slider && !isProductGroupLoaded(skus, productTilesData)) {
      productTilesAction({ skus, groupNumber: 0 });
    }
  }, [view, isLoggedIn, skus.length]);

  const { analyzeProductClick, analyzeProductAddToCart } = useProductsAnalytics();

  return (
    <ProductBoxes
      isLoggedIn={isLoggedIn}
      isSlider={view === VIEW_TYPE.slider && (canCreateSlider || countSliderLoadingProductTiles > 0)}
      label={label}
      mptId={mptId}
      productsMptIds={productsMptIds}
      skus={skus}
      styleType={getStyleType()}
      view={view}
      onProductClick={analyzeProductClick}
      onProductAddToCart={analyzeProductAddToCart}
      countSliderLoadedProductTiles={countSliderLoadedProductTiles}
    />
  );
};
