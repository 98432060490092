import {
  TEALIUM_INTERACTION_ID,
  TEALIUM_PAGE_TYPE, TEALIUM_PROD_ACTION,
} from '@client/analytics/types/types';
import { preparePrice, prepareQuantity, prepareStock } from '@client/analytics/utils/analytics';
import { ANALYTICS_ATTRIBUTES, BaseBuilder } from '@client/analytics/utils/builders/BaseBuilder';
import { CartProductType } from 'b2b-common/core/cart/api/CartTypes';

export class CartAnalyticsBuilder
  extends BaseBuilder<any> {
  private products: CartProductType[]
  protected cartViewAttributes: ANALYTICS_ATTRIBUTES[] = [
    ANALYTICS_ATTRIBUTES.action,
    ANALYTICS_ATTRIBUTES.categories,
    ANALYTICS_ATTRIBUTES.id,
    ANALYTICS_ATTRIBUTES.price,
    ANALYTICS_ATTRIBUTES.quan,
    ANALYTICS_ATTRIBUTES.stock,
  ];

  public setProducts(products: CartProductType[]): CartAnalyticsBuilder {
    this.products = products;
    return this;
  }

  public build(): any {
    this.analyticsResultObjectArray = this.products.map(product => ({
      attributes: this.cartViewAttributes,
      analyticsResultObject: {
        [ANALYTICS_ATTRIBUTES.action]: TEALIUM_PROD_ACTION.checkoutCart,
        [ANALYTICS_ATTRIBUTES.categories]: product.analytics.categories,
        [ANALYTICS_ATTRIBUTES.id]: product.sku,
        [ANALYTICS_ATTRIBUTES.price]: preparePrice(product.single.price),
        [ANALYTICS_ATTRIBUTES.quan]: prepareQuantity(product.cart.quantity),
        [ANALYTICS_ATTRIBUTES.stock]: prepareStock(product.settings.quantity),
      },
    }));
    return {
      interaction: TEALIUM_INTERACTION_ID.cartView,
      page: {
        type: TEALIUM_PAGE_TYPE.cart,
      },
      prod: super.build() || [],
    };
  }
}
