import { ReactElement } from 'react';
import { useActiveLanguage, useTranslate } from '@client/i18n/hooks';
import { useNewsletterSubscriptionAction } from '@client/newsletter/hooks';
import { NewsletterForm } from './NewsletterForm';
import { FormValuesType } from './NewsletterForm.types';
import { Props } from './NewsletterFormContainer.types';

export enum FIELD_NAME {
  emailNewsletter = 'emailNewsletter',
}

export const NewsletterFormContainer = (props: Props): ReactElement => {
  const subscribe = useNewsletterSubscriptionAction();
  const language = useActiveLanguage();

  const handleSubmit = (values: FormValuesType): void => {
    subscribe({ email: values[FIELD_NAME.emailNewsletter], language });
  };

  const t = useTranslate();
  const text = {
    email: t('E-mail address'),
    resend: t('Resend'),
    subscribe: t('Subscribe'),
    emailIsNotValid: t('Email is not valid'),
    emailIsRequired: t('Email is required'),
  };

  const injectedProps = {
    onSubmit: handleSubmit,
    text,
  };

  return <NewsletterForm {...props} {...injectedProps} />;
};
