import { fetchCart } from '@client/cart/redux/cart/redux';
import { fetchCartStatus } from '@client/cart/redux/cartStatus/redux';
import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { parseError } from '@client/common/utils/api/error';
import { Kanban } from '@client/kanban/api';
import {
  addKanbanItemsToCart,
  addKanbanItemsToCartFulfilled,
  addKanbanItemsToCartRejected,
  clearAddKanbanItemsToCart,
  clearPlaceKanbanOrder,
  clearVerifyKanbanItems,
  placeKanbanOrder,
  placeKanbanOrderFulfilled,
  placeKanbanOrderRejected,
  selectVerifyKanbanItems,
  verifyKanbanItems,
  verifyKanbanItemsFulfilled,
  verifyKanbanItemsRejected,
} from '@client/kanban/redux/redux';
import { ROUTES } from '@client/routes/components/Router/routes';
import { pushToRoute } from '@client/routes/redux/utils';
import { AxiosResponse } from 'axios';
import {
  all,
  AllEffect,
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeLatest,
} from 'redux-saga/effects';
import { AddItemsToCartType } from 'b2b-common/core/cart/api/CartTypes';
import { VerifyKanbanItemsType } from 'b2b-common/core/kanban';

function* verifyKanbanItemsSaga(
  action: ReturnType<typeof verifyKanbanItems>,
): Generator<CallEffect | PutEffect, void, AxiosResponse> {
  const data = action.payload;

  try {
    const response = yield call(requestApiSaga, Kanban.verifyKanbanItems(data));

    yield put(verifyKanbanItemsFulfilled(response.data));
    yield put(fetchCartStatus());
  } catch (error) {
    yield put(verifyKanbanItemsRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* addKanbanItemsToCartSaga(
  action: ReturnType<typeof addKanbanItemsToCart>,
): Generator<
  SelectEffect | CallEffect | PutEffect,
  void,
  RemoteRecord<VerifyKanbanItemsType> & AxiosResponse<AddItemsToCartType>
> {
  const { lockerId, costCenterManuallyTypedId } = action.payload;
  const { data } = yield select(selectVerifyKanbanItems);

  const items = data?.filter(item => !!item.resolved).map(({ resolved }) => ({
    sku: resolved.sku,
    reference: resolved.reference,
    quantity: resolved.quantity,
  })) || [];

  try {
    yield put(clearPlaceKanbanOrder());
    const response = yield call(requestApiSaga, Kanban.addKanbanItemsToCart({
      lockerId,
      costCenterManuallyTypedId,
      items,
    }));

    yield put(addKanbanItemsToCartFulfilled(response.data));
    yield put(fetchCartStatus());
    yield put(fetchCart());
    yield put(clearVerifyKanbanItems());
  } catch (error) {
    yield put(addKanbanItemsToCartRejected(parseError(error)));
    yield put(reportError({ message: action.type, error }));
  }
}

function* placeKanbanOrderSaga(action: ReturnType<typeof placeKanbanOrder>): Generator<
  CallEffect | PutEffect | Generator,
  void,
  AxiosResponse
> {
  const data = action.payload;

  try {
    const response = yield call(requestApiSaga, Kanban.placeKanbanOrder(data));

    yield put(placeKanbanOrderFulfilled(response.data));
    yield put(fetchCartStatus());
    yield put(clearAddKanbanItemsToCart());
    yield pushToRoute(ROUTES.kanbanOrderConfirmation);
  } catch (error) {
    yield put(placeKanbanOrderRejected(parseError(error)));
    yield put(reportError({ message: action.type, error }));
    yield pushToRoute(ROUTES.kanbanOrderFail);
  }
}

export function* kanbanSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(verifyKanbanItems.type, verifyKanbanItemsSaga),
    takeLatest(addKanbanItemsToCart.type, addKanbanItemsToCartSaga),
    takeLatest(placeKanbanOrder.type, placeKanbanOrderSaga),
  ]);
}
