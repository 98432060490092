import { ANALYTICS_BOOLEAN, HOT_JAR_TAG } from '@client/analytics/types/types';
import { isServer } from '@client/common/utils/env';
import { SHIPPING_METHOD } from 'b2b-common/core/checkout/Checkout.types';

export const triggerHotJarRecording = (trigger: string): void => {
  if (!isServer && window.hj) {
    window.hj('trigger', trigger);
  }
};

export const tagHotJarRecording = (
  ...tags: HOT_JAR_TAG[]
): void => {
  if (!isServer && window.hj) {
    window.hj('tagRecording', tags);
  }
};

export const preparePrice = (price: number): string => {
  const prepared = price && !isNaN(price) ? price : 0;

  return prepared.toFixed(2).toString();
};

export const prepareQuantity = (quantity: any): string => quantity ? `${quantity}` : '';

export const prepareDeliveryDestination = (method: SHIPPING_METHOD): string => [
  SHIPPING_METHOD.pickup,
  SHIPPING_METHOD.pickupRenens,
  SHIPPING_METHOD.pickupWillisau,
  SHIPPING_METHOD.pickupStaff,
].includes(method) ? `pickup:${method}` : `home:${method}`;

export const prepareStock = (stock: number): string => {
  if (stock > 0) {
    return `${stock}`;
  }

  return 'n';
};

export const calculateProductPosition = (index: number, pageSize: number, page: number): string => {
  if (page === 1) {
    return (index + 1).toString();
  }
  return ((page - 1) * pageSize + (index + 1)).toString();
};

export const prepareAnalyticsBool = (value: boolean):
  ANALYTICS_BOOLEAN => value ? ANALYTICS_BOOLEAN.true : ANALYTICS_BOOLEAN.false;
