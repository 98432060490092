import { SkuList } from '@client/catalog/api/SkuList';
import {
  fetchSkuListSearch,
  fetchSkuListSearchFulfilled,
  fetchSkuListSearchRejected,
} from '@client/catalog/redux/skuList/redux';
import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { getSid } from '@client/context/redux/redux';
import { AxiosResponse } from 'axios';
import Chance from 'chance';
import { all, call, CallEffect, put, PutEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects';
import { SkuListSearchType } from 'b2b-common/core/catalog/Catalog.types';

function* fetchSkuListSearchSaga(
  action: ReturnType<typeof fetchSkuListSearch>,
): Generator<CallEffect | SelectEffect | PutEffect, void, string & AxiosResponse<SkuListSearchType>> {
  const { query: inputQuery, queryId, randomOrder } = action.payload;

  try {
    const response = yield call(requestApiSaga, SkuList.fetch(inputQuery));
    const { skuList } = SkuList.mapResponseToState(response);
    const sid = yield select(getSid);
    const chance = new Chance(sid);

    yield put(
      fetchSkuListSearchFulfilled({
        data: randomOrder ? chance.shuffle(skuList) : skuList,
        queryId,
      }),
    );
  } catch (error) {
    yield put(fetchSkuListSearchRejected({ error: parseError(error), queryId }));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* skuListSearchSaga(): Generator<any, any, any> {
  yield all([
    takeEvery(fetchSkuListSearch.type, fetchSkuListSearchSaga),
  ]);
}
