import { retryRequestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Invoices } from '@client/myAccount/invoices/api';
import {
  fetchInvoicesSearch,
  fetchInvoicesSearchFulfilled,
  fetchInvoicesSearchRejected,
} from '@client/myAccount/invoices/redux/invoicesSearch/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { InvoicesSearchType } from 'b2b-common/core/account/api/invoices';

function* invoicesSearchSaga(action: ReturnType<typeof fetchInvoicesSearch>):
  Generator<CallEffect | PutEffect, void, AxiosResponse<InvoicesSearchType>> {
  const { searchQuery, isPendingOnly } = action.payload;

  try {
    const response = yield call(
      retryRequestApiSaga,
      Invoices.search({
        pageSize: 50,
        startItem: 0,
        searchQuery,
        isPendingOnly,
      }),
    );

    yield put(fetchInvoicesSearchFulfilled(response.data));
  } catch (error) {
    yield put(fetchInvoicesSearchRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: { searchQuery } }));
  }
}

export function* invoicesSearchSagas(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(fetchInvoicesSearch.type, invoicesSearchSaga),
  ]);
}
