import { config } from '@client/config';
import cookie from 'js-cookie';
import compare from 'semver-compare';

const cookieCompatibility = config.storage.cookie.compatibility || {};

export const getCookie = (key: string, cookies?: Record<string, string>): Record<string, any> | string | null => {
  const stringifiedValue = cookies?.[key] || cookie.get(key) || 'null';
  let value = null;

  try {
    value = JSON.parse(stringifiedValue);
  } catch (error) {
    return null;
  }

  const minAppVersion = cookieCompatibility[key] || '0.0.0';

  if (value) {
    if (value.appVersion && compare(minAppVersion, value.appVersion) <= 0) {
      return value.data;
    }

    removeCookie(key);
  }

  return null;
};

export const createValidCookieValue = (data: any): { appVersion: string, data: any } => ({
  appVersion: config.version,
  data,
});

export const setCookie = (
  key: string,
  data: any,
  daysToExpire: Date | number = 365,
): void => {
  cookie.set(
    key,
    createValidCookieValue(data),
    {
      expires: daysToExpire,
    },
  );
};

export const removeCookie = (key: string): void => {
  cookie.remove(key);
};
