import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { stringify } from 'qs';

class Configurators {
  public static getStampConfigurator(
    sku: string,
    partAuxiliaryId?: string,
    quantity?: number,
    skipHookUrlTarget?: boolean,
  ): RequestConfigType {
    const queryString = stringify({
      quantity,
      partAuxiliaryId,
      skipHookUrlTarget,
    });

    return RequestConfig.get(`/products/${sku}/configurator?${queryString}`);
  }
}

export { Configurators };
