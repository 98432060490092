import { TEALIUM_INTERACTION_ID, TEALIUM_PROD_ACTION } from '@client/analytics/types/types';
import { calculateProductPosition, preparePrice, prepareStock } from '@client/analytics/utils/analytics';
import { ANALYTICS_ATTRIBUTES, BaseBuilder } from '@client/analytics/utils/builders/BaseBuilder';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { ProductAccessoryType } from '@client/product/redux/productAccessories/types';
import { ProductType } from 'b2b-common/core/catalog/Catalog.types';
import { ProductTileType } from 'b2b-common/core/product/api/ProductTile.types';

export class ProductListAnalyticsProductPropertyBuilder extends
  BaseBuilder<Record<string, RemoteRecord<ProductTileType>> | ProductAccessoryType[]> {
  private productList: Record<string, RemoteRecord<ProductTileType>> | ProductAccessoryType[];
  private skuList: ProductType[];
  private page: number;
  private pageSize: number;
  private pageType?: string;

  protected attributes: ANALYTICS_ATTRIBUTES[] = [
    ANALYTICS_ATTRIBUTES.action,
    ANALYTICS_ATTRIBUTES.categories,
    ANALYTICS_ATTRIBUTES.id,
    ANALYTICS_ATTRIBUTES.pos,
    ANALYTICS_ATTRIBUTES.price,
    ANALYTICS_ATTRIBUTES.stock,
    ANALYTICS_ATTRIBUTES.list,
  ];

  public setProductList(productList: Record<string, RemoteRecord<ProductTileType>> | ProductAccessoryType[]):
    ProductListAnalyticsProductPropertyBuilder {
    this.productList = productList;
    return this;
  }

  public setSkuList(skuList: ProductType[]): ProductListAnalyticsProductPropertyBuilder {
    this.skuList = skuList;
    return this;
  }

  public setPage(page: number): ProductListAnalyticsProductPropertyBuilder {
    this.page = page;
    return this;
  }

  public setPageSize(pageSize: number): ProductListAnalyticsProductPropertyBuilder {
    this.pageSize = pageSize;
    return this;
  }

  public setPageType(pageType: string): ProductListAnalyticsProductPropertyBuilder {
    this.pageType = pageType;
    return this;
  }

  public build(): any {
    const productCollection = [
      ...Object.values(this.productList).map(product => ({
        ...(product?.data ? product?.data : product),
        order: this.skuList?.findIndex(
          productIndex => productIndex?.sku === product?.data?.sku || productIndex?.sku === product?.sku,
        ),
      })),
    ].filter(product => product?.order !== -1);

    this.analyticsResultObjectArray = productCollection.map((product) => ({
      attributes: this.attributes,
      analyticsResultObject: {
        action: TEALIUM_PROD_ACTION.productsList,
        categories: product.analytics ? product.analytics.categories : [product.category?.label],
        id: product.sku.toString(),
        pos: calculateProductPosition(product.order, this.pageSize, this.page),
        price: preparePrice(product.effectivePricing.userPriceWithoutVat),
        stock: prepareStock(product.settings.quantity),
        list: this.pageType,
      },
    }));

    return {
      [ANALYTICS_ATTRIBUTES.interaction]: TEALIUM_INTERACTION_ID.productList,
      [ANALYTICS_ATTRIBUTES.prod]: super.build() || [],
    };
  }
}
