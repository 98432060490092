import { EnergyLabelType } from 'b2b-common/core/product/api/ProductTile.types';

export type ProductQuantityOrderedType = {
  deliveryDate: string | null;
  quantityOrdered: number;
}[];

export interface IQuntityOrdered {
  quantity: number;
  quantityOrdered: ProductQuantityOrderedType;
}

export type ProductResponseSettingsType = {
  allowOverstockBuy: 0 | 1;
  isBuyable: 0 | 1;
  isNew: 0 | 1;
  isRefurbished: 0 | 1;
  isSellout: 0 | 1;
  isSpecialOffer: 0 | 1;
  isSpecialOrder: 0 | 1;
  isSustainableAlternative: 0 | 1;
  maximumOrderQuantity: number | null;
  quantity: number;
  quantityOrdered: ProductQuantityOrderedType;
  requiresLicense: 0 | 1;
  showPrice: 0 | 1;
  isCoreAssortment: 0 | 1;
  isOwnAssortment: 0 | 1;
  configurableType?: string | null;
  directDelivery?: boolean;
  imageDisclaimer?: boolean;
};

export type ProductEffectivePricingType = {
  isLoggedIn: boolean;
  isCertified: boolean;
  isBlocked: boolean;
  hasPermissions: boolean;
  hasB2BUserPrices: boolean;
  isBuyable: boolean;
  userPrice: number;
  userPriceWithoutVat: number;
  listPrice: number;
  listPriceWithoutVat: number;
  basePrice: BasePriceType;
  priceTaxRate: string;
  mainPrice: number;
  isHiddenPrice: boolean;
  isHiddenPriceAndNotCertified: boolean;
};

export enum CONFIGURABLE_TYPE {
  stamp = 'stamp',
  card = 'card'
}

export type ProductSettingsType = {
  allowOverstockBuy: boolean;
  isBuyable: boolean;
  isNew: boolean;
  isOccasion: boolean;
  isSellout: boolean;
  isSpecialOffer: boolean;
  isSpecialOrder: boolean;
  isConsciousAlternative: boolean;
  maximumOrderQuantity: number;
  quantity: number;
  quantityOrdered: ProductQuantityOrderedType;
  requiresLicense?: boolean;
  shouldShowPrice: boolean;
  isCoreAssortment: boolean;
  isOwnAssortment: boolean;
  isDisabledByCoreAssortment?: boolean;
  configurableType: CONFIGURABLE_TYPE | null;
  directDelivery?: boolean;
  imageDisclaimer?: boolean;
}

export type AttributesType = {
  name: string;
  values: string[];
}

export type BreadcrumbsType = {
  depth: number;
  label: string;
  url: string;
}

export type SizesType = {
  link: string;
  size: string;
}

export type CoverType = {
  name: string;
  sizes: SizesType[];
} | null;

export type SpecificationType = {
  attributes: AttributesType[];
  name: string;
  ordinalNumber: number;
}

export type DocumentRegularType = {
  name: string;
  size: number;
  source: string;
}

export type DocumentDangerousType = {
  name: string;
  size: number;
  source: string;
}

export type DocumentsType = {
  regular: DocumentRegularType[];
  dangerousGoods: DocumentDangerousType[];
}

export type DescriptionType = {
  banners: {
    href: string;
    image: string;
  }[];
  bulletPoints: string[];
  dangers: {
    details: {
      id: string;
      name: string;
      values: string[];
    }[];
    icons: {
      link: string;
      name: string;
    }[];
  };
  documents: DocumentsType;
  energyLabel: EnergyLabelType;
  isTranslatedByDeepl: 0 | 1;
  lead: string;
  paragraphs: {
    ordinalNumber: number;
    text: string;
    title: string;
  }[];
  remarks: {
    text: string;
    type: string;
  }[];
  specification: SpecificationType[];
  subtitle: string;
  title: string;
}

export type LinkType = {
  match: 0 | 1;
  sku: string;
}

export type LogoType = {
  href: string;
  retina: boolean;
}

export type ManufacturerType = {
  internalPage: string;
  logo: LogoType[];
  name: string;
  productId: string;
  url: string;
  warranty: number;
}

export type OptionsType = {
  connections: null | Record<string, string[]>;
  isSelected: number;
  link: LinkType;
  presentationValue: string;
  thumbnail: string;
  value: string;
}

export type PriceType = {
  price: string;
  priceWithoutVat: string;
} | Record<string, any>;

export type BasePriceType = {
  price: number;
  unit: string;
} | null;

export type PricingType = {
  base: BasePriceType;
  list: PriceType;
  taxRate: string;
  user: PriceType;
}

export type ScaledDiscountType = {
  discount: number;
  fromQuantity: number;
}

export type VariantsType = {
  id: string;
  label: string;
  options: OptionsType[];
  selected: string;
}

export enum QUANTITY_PER_SALES_UNIT {
  PCK = 'PCK',
  STK = 'STK',
}

export type PackagingType = {
  quantityPerSalesUnit: number;
  quantityPerSalesUnitUnit: QUANTITY_PER_SALES_UNIT;
  containerQuantitySuggestion: number;
}

export type ProductBoughtType = {
  date: string,
  quantity: string,
  invoiceId: string,
}

export type ProductResponseType = {
  analytics: {
    title: string;
    categories: BreadcrumbsType[];
  };
  bought: ProductBoughtType,
  breadcrumbs: BreadcrumbsType[];
  certificates: string[];
  cover: CoverType;
  description: DescriptionType;
  ean: string;
  effectivePricing: ProductEffectivePricingType,
  manufacturer: ManufacturerType;
  pricing: null | PricingType;
  scaledDiscounts: ScaledDiscountType[];
  settings: ProductResponseSettingsType;
  sku: string;
  variants: VariantsType[];
  webDateStart: string;
  packaging?: PackagingType | null;
}


export enum REPORT_PRODUCT_ERROR_SUBJECT_TYPE {
  productDescription = 'product_description',
  productDocuments = 'product_documents',
  scopeOfDelivery = 'scope_of_delivery',
  productImages = 'product_images',
  productVideos = 'product_videos',
  productSpecification = 'product_specification',
  productAccessories = 'product_accessories',
  other = 'other'
}

export type ReportProductErrorDetails = {
  subject: REPORT_PRODUCT_ERROR_SUBJECT_TYPE,
  text?: string,
  email?: string,
  sku: string,
  path: string,
}
