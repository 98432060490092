import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DetailedOrderType, RequestCancelOrderItemsType } from 'b2b-common/core/account/api/orders';

const NAME = 'order';

export const orderSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {
    cancelOrder: RemoteRecordData.getDefault(),
    cancelOrderItems: RemoteRecordData.getDefault(),
    splitOrder: RemoteRecordData.getDefault(),
    orders: {},
  },
  reducers: {
    fetchOrder: (state, action: PayloadAction<string>) => {
      state.orders[action.payload] = RemoteRecordData.init();
      return state;
    },
    fetchOrderFulfilled: (state, action: PayloadAction<{
      orderId: string;
      data: DetailedOrderType;
    }>) => {
      state.orders[action.payload.orderId] = RemoteRecordData.fulfill(action.payload.data);
      return;
    },
    fetchOrderRejected: (state, action: PayloadAction<{
      orderId: string;
      error: ErrorType;
    }>) => {
      state.orders[action.payload.orderId] = RemoteRecordData.reject(
        action.payload.error,
      );
      return state;
    },
    cancelOrder: (state, action: PayloadAction<string>) => {
      state.cancelOrder = RemoteRecordData.init();
      return state;
    },
    cancelOrderFulfilled: (state) => {
      state.cancelOrder = RemoteRecordData.fulfill({});
      return state;
    },
    cancelOrderRejected: (state, action: PayloadAction<ErrorType>) => {
      state.cancelOrder = RemoteRecordData.reject(action.payload);
      return state;
    },
    cancelOrderItems: (state, action: PayloadAction<{
      data: RequestCancelOrderItemsType;
      orderId: string;
    }>) => {
      state.cancelOrderItems = RemoteRecordData.init();
      return state;
    },
    cancelOrderItemsFulfilled: (state) => {
      state.cancelOrderItems = RemoteRecordData.fulfill({});
      return state;
    },
    cancelOrderItemsRejected: (state, action: PayloadAction<ErrorType>) => {
      state.cancelOrderItems = RemoteRecordData.reject(action.payload);
      return state;
    },
    splitOrder: (state, action: PayloadAction<string>) => {
      state.splitOrder = RemoteRecordData.init();
      return state;
    },
    splitOrderFulfilled: (state) => {
      state.splitOrder = RemoteRecordData.fulfill({});
      return state;
    },
    splitOrderRejected: (state, action: PayloadAction<ErrorType>) => {
      state.splitOrder = RemoteRecordData.reject(action.payload);
      return state;
    },
    clearOrderModifications: (state) => {
      state.cancelOrder = RemoteRecordData.getDefault();
      state.cancelOrderItems = RemoteRecordData.getDefault();
      state.splitOrder = RemoteRecordData.getDefault();
      return state;
    },
  },
});

export const {
  fetchOrder,
  fetchOrderFulfilled,
  fetchOrderRejected,
  cancelOrder,
  cancelOrderFulfilled,
  cancelOrderRejected,
  cancelOrderItems,
  cancelOrderItemsFulfilled,
  cancelOrderItemsRejected,
  splitOrder,
  splitOrderFulfilled,
  splitOrderRejected,
  clearOrderModifications,
} = orderSlice.actions;

const getState = (state: AppState) => state.myAccount.orders.order;

export const selectOrder = createSelector(
  [getState, (state, orderId: string) => orderId],
  (state, orderId) => new RemoteRecord<DetailedOrderType>(state.orders[orderId]),
);

export const selectCancelOrderItems = createSelector(
  getState,
  state => new RemoteRecord(state.cancelOrderItems),
);

export const selectCancelOrder = createSelector(
  getState,
  state => new RemoteRecord(state.cancelOrder),
);

export const selectSplitOrder = createSelector(
  getState,
  state => new RemoteRecord(state.splitOrder),
);
