import { ReactElement } from 'react';
import { ProductsComparisonBar } from '@client/app/components/App/ProductsComparisonBar/ProductsComparisonBar';
import { useIsActive } from '@client/app/components/App/ProductsComparisonBar/useIsActive';

export interface Props {
  className?: string;
}

export const ProductsComparisonBarContainer = ({ className }: Props): ReactElement => {
  const isActive = useIsActive();

  if (isActive) {
    return <ProductsComparisonBar className={className} />;
  }

  return null;
};
