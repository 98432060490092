import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { RequestAddAddressType } from '@client/myAccount/addresses/api/Address.types';
import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CheckoutDataType,
  UpdateCheckoutRequestBodyType,
} from 'b2b-common/core/checkout/Checkout.types';
import { hasCustomerReference } from 'b2b-common/core/checkout/utils/checkout';

const NAME = 'checkout';

export const checkoutSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: RemoteRecordData.getDefault(),
  reducers: {
    checkoutFulfilled: (state, action: PayloadAction<CheckoutDataType>) => RemoteRecordData.fulfill(action.payload),
    checkoutRejected: (state, action: PayloadAction<ErrorType>) => RemoteRecordData.reject(action.payload),
    clearCheckout: () => RemoteRecordData.getDefault(),
    initCheckout: () => RemoteRecordData.init(),
    updateCheckout: (state, action: PayloadAction<{
      checkoutId: string;
      data: UpdateCheckoutRequestBodyType,
    }>) => (
      Object.assign(RemoteRecordData.init(), { data: state.data })
    ),
  },
});

export const {
  checkoutFulfilled,
  checkoutRejected,
  clearCheckout,
  initCheckout,
  updateCheckout,
} = checkoutSlice.actions;

export const saveShippingAddress = createAction<RequestAddAddressType>('saveShippingAddress');

const getState = (state: AppState) => state.checkout.checkout;

export const selectCheckout = createSelector(
  getState,
  state => new RemoteRecord<CheckoutDataType>(state),
);

export const selectCheckoutToken = createSelector(
  selectCheckout,
  (remoteRecord): string | null => {
    if (remoteRecord.isLoaded && remoteRecord.data?.token) {
      return remoteRecord.data.token;
    }

    return null;
  },
);

export const selectRequestData = createSelector(
  selectCheckout,
  (remoteRecord): UpdateCheckoutRequestBodyType | null => {
    const { data, isLoaded } = remoteRecord;

    if (!isLoaded || !data) {
      return null;
    }

    return {
      confirmationEmail: data.addresses.addresses.delivery.email || '',
      licenceHolderData: data.licenseHolder.licenseHolder || '',
      mobileNumber: data.addresses.addresses.delivery.phoneNumber || '',
      payment: data.payment.selected || '',
      reference: data.references.order || '',
      remarks: data.references.sales || '',
      shipping: {
        deliveryDate: data.shipping.deliveryDate,
        dropShipmentTypes: {
          type: data.addresses.options.selected || '',
        },
        options: {
          type: data.shipping.options.selected,
        },
        type: data.shipping.selected,
      },
      shippingAddress: {
        displayName: data.addresses.addresses.delivery.name,
        contactPerson: data.addresses.addresses.delivery.contactPerson,
        street: data.addresses.addresses.delivery.street,
        streetaddition: data.addresses.addresses.delivery.streetAddition,
        zip: data.addresses.addresses.delivery.postCode,
        city: data.addresses.addresses.delivery.city,
        reference: hasCustomerReference(data.addresses.options.selected)
          ? data.references.customer || ''
          : '',
      },
      driverInfo: data.driverInfo,
    };
  },
);
