import { OptionType } from 'b2b-components/theme/components/Form/SearchableSelect';

export enum FIELD_NAME {
  selectedWishlist = 'selectedWishlist',
}

export type FormValuesType = {
  [FIELD_NAME.selectedWishlist]: string,
}

export interface Props {
  currentWishlist: string | null;
  onChange: (fieldName: string, option: OptionType) => void;
  onModalClose: () => void;
  options: OptionType[];
  onLoadOptions: (searchPhrase: string) => Promise<OptionType[]>;
  onSubmit: () => void;
  onCreate: (value?: string) => void;
}
