import { ReactElement } from 'react';
import { useDeviceType } from '@client/common/hooks';
import { useTranslate } from '@client/i18n/hooks';
import { StockCount as StockCountComponent } from 'b2b-components/theme/components/StockCount';
import { Props } from './';

export const StockCount = (props: Props): ReactElement => {
  const t = useTranslate();
  const deviceType = useDeviceType();

  return (
    <StockCountComponent
      {...props}
      deviceType={deviceType}
      text={{
        date: t('Date'),
        deliveryDates: t('Expected delivery dates'),
        notReadyForShipment: t('Not ready for shipment'),
        orderedQuantity: t('Ordered Quantity'),
        readyForDirectDelivery: t(
          [
            '<strong>1</strong> pc. ready for direct delivery',
            '<strong>{count}</strong> pcs. ready for direct delivery',
            'count',
          ],
          {
            count: props.stockCount,
          },
        ),
        readyForShipment: t(
          [
            '<strong>1</strong> pc. ready for shipment',
            '<strong>{count}</strong> pcs. ready for shipment',
            'count',
          ],
          {
            count: props.stockCount,
          },
        ),
        unknownDeliveryDate: t('Delivery date unknown'),
        specialOrder: {
          full: t('Special Order'),
          short: t('S.O.'),
          explanation: t('We do not stock this item, it will be ordered for you after your order. You will receive an email with details about delivery time.'),
        },
        seeMore: t('see more'),
        directDeliveryStockInfo: t('stock in supplier’s warehouse'),
        deliveryInfoPackage: t('This item ships in a separate package, directly from our supplier.'),
        deliveryInfoTime: t('Delivery time is 3 to 5 business days.'),
        ...(props.text || {}),
      }}
    />
  );
};
