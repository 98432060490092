import { useDeviceType } from '@client/common/hooks';
import { isMobile } from '@client/common/utils/env';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

export enum RESOLUTION_TYPE {
  all = 'all',
  desktop ='desktop',
  mobile ='mobile',
}

export type UseScrollLockReturnType = (
  ref: HTMLElement | null,
  resolutionType?: RESOLUTION_TYPE,
) => void;

export const useScrollLock = (): UseScrollLockReturnType => {
  const deviceType = useDeviceType();

  return (ref: HTMLElement | null, resolutionType = RESOLUTION_TYPE.all) => {
    if (
      (isMobile(deviceType) && resolutionType === RESOLUTION_TYPE.mobile)
        || (!isMobile(deviceType) && resolutionType === RESOLUTION_TYPE.desktop)
        || resolutionType === RESOLUTION_TYPE.all
    ) {
      ref !== null ? disableBodyScroll(ref) : clearAllBodyScrollLocks();
    }
  };
};
