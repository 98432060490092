import { retryRequestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Invoice } from '@client/myAccount/invoices/api';
import {
  fetchInvoice,
  fetchInvoiceFulfilled,
  fetchInvoiceRejected,
} from '@client/myAccount/invoices/redux/invoice/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { DetailedInvoiceType } from 'b2b-common/core/account/api/invoices';

function* fetchInvoiceSaga(action: ReturnType<typeof fetchInvoice>):
  Generator<CallEffect | PutEffect, void, AxiosResponse<DetailedInvoiceType>> {
  const invoiceId = action.payload;

  try {
    const response = yield call(retryRequestApiSaga, Invoice.fetch(invoiceId));

    yield put(fetchInvoiceFulfilled({ invoiceId, data: response.data }));
  } catch (error) {
    yield put(fetchInvoiceRejected({ invoiceId, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: { invoiceId } }));
  }
}

export function* invoiceSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(fetchInvoice.type, fetchInvoiceSaga),
  ]);
}
