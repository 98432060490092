import { Id, toast } from 'react-toastify';
import { CartToast } from '@client/cart/components/CartToast';
import { TranslateFunction } from 'b2b-server/core/utils/language';
import { CartProductType, CartStatusType, CartType } from 'b2b-common/core/cart/api/CartTypes';
import { ProductSettingsType } from 'b2b-common/core/product/api/Product.types';

export enum CART_MESSAGES {
  addingToCart = 'adding_to_cart',
  maxOrderQuantityExceeded = 'item_max_order_quantity_exceeded',
  maxOrderQuantityReached = 'maximum_order_quantity_reached',
  maxQuantityReached = 'maximum_quantity_reached',
  missingCertificate = 'missing_certificate',
  missingPermissions = 'customer_article_permissions',
  notAvailable = 'item_no_longer_available',
  notBuyable = 'item_not_buyable',
  quantityReduced = 'quantity_reduced',
  soldOut = 'item_sold_out',
  success = 'success',
  unknownError = 'unknown_cart_sync_error',
  unknownSku = 'unknown_product_id',
  configurationRequired = 'configuration_required',
}

type CartToastConfig = {
  messageId: string,
  productName: string,
  addedQuantity?: number,
  currentQuantity?: number,
  maxAllowedQuantity?: number,
  toastId?: Id,
}

export const showCartToast = ({
  messageId,
  productName,
  addedQuantity,
  currentQuantity,
  maxAllowedQuantity,
  toastId,
}: CartToastConfig): Id => {
  const Content = <CartToast
    messageId={messageId}
    productName={productName}
    addedQuantity={addedQuantity}
    currentQuantity={currentQuantity}
    maxAllowedQuantity={maxAllowedQuantity}
  />;

  if (toastId) {
    toast.update(toastId, { render: Content });
    return;
  }

  return toast(Content);
};

export const cartProductsHaveErrors = (cartProducts: CartProductType[]): boolean => (
  !!cartProducts
  && Array.isArray(cartProducts)
  && !!cartProducts.find(
    (product) => Array.isArray(product.errors) && product.errors.length > 0,
  )
);

export const getMaxQuantityLimit = (settings: ProductSettingsType): number => {
  const { allowOverstockBuy, quantity, maximumOrderQuantity } = settings;
  let quantityAvailable = 999;

  if (!allowOverstockBuy) {
    quantityAvailable = quantity;
  }

  if (maximumOrderQuantity > 0) {
    quantityAvailable = Math.min(quantityAvailable, maximumOrderQuantity);
  }

  return quantityAvailable;
};

export const getStatusFromCart = (cart: CartType): CartStatusType => {
  const { products, summary } = cart;

  return {
    count: products.reduce(
      (count, product) => count + product.cart.quantity,
      0,
    ),
    lastInsertedQuantity: null,
    messages: [],
    value: summary.price,
    isLastInsertedNewItem: false,
  };
};

export enum CART_VALIDATE_ERROR {
  referenceTooLong = 'reference_too_long',
  quantityTooLow = 'quantity_too_low',
  requireSkuOrManufacturer = 'require_sku_or_manufacturer',
  requireManufacturerName = 'require_manufacturerName',
  requireManufacturerNumber = 'require_manufacturerNumber',
  notFound = 'not_found',
  multipleMatch = 'multiple_match',
  missingCertificate = 'missing_certificate',
  duplicatedItem = 'duplicated_item',
  configurationRequired = 'configuration_required',
}

export const getCartValidationErrorMessage = (t: TranslateFunction, error: string): string | undefined => {
  if (!error) {
    return undefined;
  }

  switch (error) {
    case CART_VALIDATE_ERROR.referenceTooLong:
      return t('Article reference is too long - max 50 characters.');

    case CART_VALIDATE_ERROR.quantityTooLow:
      return t('Incorrect quantity');

    case CART_VALIDATE_ERROR.requireSkuOrManufacturer:
      return t('SKU is required when manufacturer name and manufacturer number are empty.');

    case CART_VALIDATE_ERROR.requireManufacturerName:
      return t('Manufacturer name is required when manufacturer number is given.');

    case CART_VALIDATE_ERROR.requireManufacturerNumber:
      return t('Manufacturer number is required when manufacturer name is given.');

    case CART_VALIDATE_ERROR.notFound:
      return t('No match found.');

    case CART_VALIDATE_ERROR.multipleMatch:
      return t('Multiple products found.');

    case CART_VALIDATE_ERROR.missingCertificate:
      return t('Certification is required to purchase this product.');

    case CART_VALIDATE_ERROR.duplicatedItem:
      return t('Duplicated products have been found.');

    case CART_VALIDATE_ERROR.configurationRequired:
      return t('Cannot add configurable product to cart without configuration.');

    default:
      return t('This product causes unrecognized error.');
  }
};
