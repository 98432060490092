import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { PDF_CATEGORY } from 'b2b-common/core/account/api/Pdf.types';

export class Pdf {
  public static fetch(id: string, category: PDF_CATEGORY): RequestConfigType {
    return RequestConfig.get(
      `/account/pdf/${category}/${id}`,
      { withLocalization: true },
    );
  }
}
