import { preparePrice } from '@client/analytics/utils/analytics';
import { ANALYTICS_ATTRIBUTES, BaseBuilder } from '@client/analytics/utils/builders/BaseBuilder';
import { OrderType } from '@client/analytics/utils/builders/CheckoutBaseAnalyticsBuilder';

export class CheckoutOrderAnalyticsBuilder extends BaseBuilder<OrderType> {
  protected attributes = [
    ANALYTICS_ATTRIBUTES.id,
    ANALYTICS_ATTRIBUTES.revenue,
    ANALYTICS_ATTRIBUTES.shipping,
    ANALYTICS_ATTRIBUTES.smallQuantitySurcharge,
  ];

  protected order: OrderType;

  public setOrder(order: OrderType): CheckoutOrderAnalyticsBuilder {
    this.order = order;
    return this;
  }

  public build(): any {
    this.analyticsResultObject = {
      [ANALYTICS_ATTRIBUTES.id]: `${this.order.id}`,
      [ANALYTICS_ATTRIBUTES.revenue]: preparePrice(this.order.revenue),
      [ANALYTICS_ATTRIBUTES.shipping]: preparePrice(this.order.shipping),
      [ANALYTICS_ATTRIBUTES.smallQuantitySurcharge]: preparePrice(this.order.smallQuantitySurcharge),
    };

    return {
      ...super.build(),
      [ANALYTICS_ATTRIBUTES.payment]: {
        cost: preparePrice(this.order.payment.cost),
        type: `${this.order.payment.type || ''}`,
      },
    };
  }
}
