import { ReactElement } from 'react';
import { useProductsCompareSettings } from '@client/settings/hooks';
import classNames from 'classnames';
import { useStickyFooter } from 'b2b-components/context/StickyFooterContext';
import { HydratedContent } from 'b2b-components/core/components/HydratedContent';
import { BackToTop as BackToTopComponent } from 'b2b-components/theme/components/BackToTop';
import { Props } from './';
import './BackToTop.scss';

export const BackToTop = ({ className }: Props): ReactElement => {
  const { isStickyFooterEnabled } = useStickyFooter();
  const { products, isClosed } = useProductsCompareSettings();

  const comparisonVisible = products.length && !isClosed;

  const rootClassName = classNames(className, {
    'BackToTop-StickyFooter': isStickyFooterEnabled,
    'BackToTop-Comparison': comparisonVisible,
  });

  return (
    <HydratedContent>
      <BackToTopComponent className={rootClassName} dataAttributes={{
        'data-testid': 'backToTopButton',
      }} />
    </HydratedContent>
  );
};
