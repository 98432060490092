import { ANALYTICS_BOOLEAN, TEALIUM_INTERACTION_ID } from '@client/analytics/types/types';
import { prepareAnalyticsBool } from '@client/analytics/utils/analytics';
import { selectCartStatusData } from '@client/cart/redux/cartStatus/redux';
import { AppState } from '@client/common/redux/store';
import { config } from '@client/config';
import { getProfile } from '@client/context/redux/redux';
import { getActiveLanguage } from '@client/i18n/redux/redux';

export const getAnalyticsCommonData = (state: AppState): Record<string, any> => {
  const url = window?.location.href || null;
  const title = document ? document.title : null;
  const profile = getProfile(state);

  return {
    interaction: TEALIUM_INTERACTION_ID.generic,
    cart: {
      value: selectCartStatusData(state).value,
    },
    page: {
      id: null,
      language: getActiveLanguage(state),
      name: null,
      type: null,
    },
    page_title: title,
    platform: process.env.REACT_APP_ANALYTICS_PLATFORM,
    request: {
      id: window?.requestId || null,
    },
    server: {
      code: 200,
    },
    tech_applicationVersion: config.version,
    url_full: url,
    user: {
      accid: profile?.userId || null,
      debid: profile?.debitorIdMd5Hash,
      debid_unhashed: profile?.debitorId,
      email: profile?.emailMd5Hash,
      email2: profile?.emailSha256Hash,
      isStaff: ANALYTICS_BOOLEAN.false,
      loggedin: prepareAnalyticsBool(Boolean(profile)),
      custy: profile?.customerTypeGroup?.toLowerCase(),
      cussty: profile?.customerType?.toLowerCase(),
    },
    virtualPageView: ANALYTICS_BOOLEAN.true,
  };
};
