import { sanitizeOssTerm } from '@client/analytics/redux';
import { TEALIUM_INTERACTION_ID, ZERO } from '@client/analytics/types/types';
import { ANALYTICS_ATTRIBUTES, BaseBuilder } from '@client/analytics/utils/builders/BaseBuilder';
import { PRODUCT_BOX_STYLE_TYPE } from '@lib/components/ProductList';

enum OSS_INTERACTION {
  start = 'start',
  view = 'view',
  click = 'click',
  other = 'other',
  filter = 'filter',
  add = 'add_list'
}

interface OSS_INTERACTION_PARAMS {
  interaction: string;
  interactionLabel: string;
  term: string;
  type: string;
  resultsCount?: string | number;
}

enum OSS_INTERACTION_LABEL {
  click = 'std_srch:start:via_button_or_enter',
  viewResultPage = 'std_srch:view:results_page',
  productClick = 'std_srch:click:prod',
  productAdd = 'std_srch:add_list',
  suggestionStart = 'sug_srch:start',
  suggestionClick = 'sug_srch:click:prod',
  suggestionAddToCart = 'sug_srch:add_list:prod',
  suggestionStartFromSimmilars = 'std_srch:start:from_similar_query',
  suggestionCategory = 'std_srch:start:from_suggested_category',
  filter = 'std_srch:filter' // remember to add :filterName after
}

export class SearchAnalyticsSearchOssPropertyBuilder extends BaseBuilder<OSS_INTERACTION_PARAMS> {
  public static ossInteractionParams: Partial<Record<TEALIUM_INTERACTION_ID,
    { interaction: OSS_INTERACTION, interactionLabel: OSS_INTERACTION_LABEL }
  >> = {
    [TEALIUM_INTERACTION_ID.searchStart]: {
      interaction: OSS_INTERACTION.start,
      interactionLabel: OSS_INTERACTION_LABEL.click,
    },
    [TEALIUM_INTERACTION_ID.searchPageView]: {
      interaction: OSS_INTERACTION.view,
      interactionLabel: OSS_INTERACTION_LABEL.viewResultPage,
    },
    [TEALIUM_INTERACTION_ID.searchPageEmptyView]: {
      interaction: OSS_INTERACTION.view,
      interactionLabel: OSS_INTERACTION_LABEL.viewResultPage,
    },
    [TEALIUM_INTERACTION_ID.productSearchListClick]: {
      interaction: OSS_INTERACTION.click,
      interactionLabel: OSS_INTERACTION_LABEL.productClick,
    },
    [TEALIUM_INTERACTION_ID.productSearchAddToCart]: {
      interaction: OSS_INTERACTION.click,
      interactionLabel: OSS_INTERACTION_LABEL.productClick,
    },
    [TEALIUM_INTERACTION_ID.suggestedProductClick]: {
      interaction: OSS_INTERACTION.click,
      interactionLabel: OSS_INTERACTION_LABEL.suggestionClick,
    },
    [TEALIUM_INTERACTION_ID.suggestedProductAddToCart]: {
      interaction: OSS_INTERACTION.add,
      interactionLabel: OSS_INTERACTION_LABEL.suggestionAddToCart,
    },
    [TEALIUM_INTERACTION_ID.similarQueryClick]: {
      interaction: OSS_INTERACTION.start,
      interactionLabel: OSS_INTERACTION_LABEL.suggestionStartFromSimmilars,
    },
    [TEALIUM_INTERACTION_ID.suggestedCategoriesSearchStart]: {
      interaction: OSS_INTERACTION.start,
      interactionLabel: OSS_INTERACTION_LABEL.suggestionCategory,
    },
    [TEALIUM_INTERACTION_ID.searchFilterClick]: {
      interaction: OSS_INTERACTION.filter,
      interactionLabel: OSS_INTERACTION_LABEL.filter,
    },
  };

  private interaction: TEALIUM_INTERACTION_ID;
  private interactionLabel: string;
  private resultsCount: string | number;
  private term: string;
  private type: string;
  private originalQuery: string;
  private sortType: string;
  private resultsPerPage: number;
  private page: number;
  private productsCount: number;
  private listType: string;

  protected attributes: ANALYTICS_ATTRIBUTES[] = [
    ANALYTICS_ATTRIBUTES.interaction,
    ANALYTICS_ATTRIBUTES.interactionLabel,
    ANALYTICS_ATTRIBUTES.term,
    ANALYTICS_ATTRIBUTES.type,
    ANALYTICS_ATTRIBUTES.resultsCount,
  ];

  protected analyticsResultObject: Record<string, any> = {
    [ANALYTICS_ATTRIBUTES.interaction]: '',
    [ANALYTICS_ATTRIBUTES.interactionLabel]: '',
    [ANALYTICS_ATTRIBUTES.term]: '',
    [ANALYTICS_ATTRIBUTES.type]: '',
    [ANALYTICS_ATTRIBUTES.resultsCount]: ZERO,
  };

  public setInteraction(interaction: TEALIUM_INTERACTION_ID): SearchAnalyticsSearchOssPropertyBuilder {
    this.interaction = interaction;
    return this;
  }

  public setInteractionLabel(interactionLabel: string): SearchAnalyticsSearchOssPropertyBuilder {
    this.interactionLabel = interactionLabel;
    return this;
  }

  public setResultsCount(resultsCount: string | number): SearchAnalyticsSearchOssPropertyBuilder {
    this.resultsCount = resultsCount;
    return this;
  }

  public setTerm(term: string): SearchAnalyticsSearchOssPropertyBuilder {
    this.term = term;
    return this;
  }

  public setType(type: string): SearchAnalyticsSearchOssPropertyBuilder {
    this.type = type;
    return this;
  }

  public static GET_OSS_INTERACTION_PARAMS(): Partial<Record<TEALIUM_INTERACTION_ID,
    { interaction: OSS_INTERACTION, interactionLabel: OSS_INTERACTION_LABEL }
  >> {
    return this.ossInteractionParams;
  }

  public setOriginalQuery(originalQuery: string): SearchAnalyticsSearchOssPropertyBuilder {
    this.originalQuery = originalQuery;
    return this;
  }

  public setSortType(sortType: string): SearchAnalyticsSearchOssPropertyBuilder {
    this.sortType = sortType;
    return this;
  }

  public setResultsPerPage(resultsPerPage: number): SearchAnalyticsSearchOssPropertyBuilder {
    this.resultsPerPage = resultsPerPage;
    return this;
  }

  public setPage(page: number): SearchAnalyticsSearchOssPropertyBuilder {
    this.page = page;
    return this;
  }

  public setProductsCount(productsCount: number): SearchAnalyticsSearchOssPropertyBuilder {
    this.productsCount = productsCount;
    return this;
  }

  public setListType(listType: PRODUCT_BOX_STYLE_TYPE): SearchAnalyticsSearchOssPropertyBuilder {
    this.listType = listType;
    return this;
  }

  public build(): any {
    this.attributes = [
      ...this.attributes,
      (this.originalQuery && ANALYTICS_ATTRIBUTES.originalQuery),
      (this.sortType && ANALYTICS_ATTRIBUTES.sortType),
      (this.resultsPerPage && ANALYTICS_ATTRIBUTES.resultsPerPage),
      (this.page && ANALYTICS_ATTRIBUTES.pageIndex),
      (this.productsCount && ANALYTICS_ATTRIBUTES.resultsCount),
      (this.listType && ANALYTICS_ATTRIBUTES.listType),
    ].filter(Boolean);

    this.analyticsResultObject = {
      ...this.analyticsResultObject,
      ...(this.originalQuery && { [ANALYTICS_ATTRIBUTES.originalQuery]: '' }),
      ...(this.sortType && { [ANALYTICS_ATTRIBUTES.sortType]: '' }),
      ...(this.resultsPerPage != null && { [ANALYTICS_ATTRIBUTES.resultsPerPage]: '' }),
      ...(this.page != null && { [ANALYTICS_ATTRIBUTES.pageIndex]: '' }),
      ...(this.productsCount != null && { [ANALYTICS_ATTRIBUTES.resultsCount]: '' }),
      ...(this.listType && { [ANALYTICS_ATTRIBUTES.listType]: '' }),
    };
    this.analyticsResultObject.interaction = SearchAnalyticsSearchOssPropertyBuilder
      .GET_OSS_INTERACTION_PARAMS()[this.interaction].interaction;
    if (this.interactionLabel) {
      this.analyticsResultObject.interactionLabel = this.interactionLabel;
    } else {
      this.analyticsResultObject.interactionLabel = SearchAnalyticsSearchOssPropertyBuilder
        .GET_OSS_INTERACTION_PARAMS()[this.interaction].interactionLabel;
    }
    if (this.resultsCount !== undefined) {
      this.analyticsResultObject.resultsCount = this.resultsCount === 0 ? ZERO : `${this.resultsCount}`;
    }
    if (this.originalQuery) {
      this.analyticsResultObject.originalQuery = this.originalQuery.toLowerCase?.();
    }
    if (this.sortType) {
      this.analyticsResultObject.sortType = this.sortType;
    }
    if (this.resultsPerPage != null) {
      this.analyticsResultObject.resultsPerPage = `${this.resultsPerPage}`;
    }
    if (this.page != null) {
      this.analyticsResultObject.pageIndex = `${this.page}`;
    }
    if (this.productsCount != null) {
      this.analyticsResultObject.resultsCount = this.productsCount ? `${this.productsCount}` : ZERO;
    }
    if (this.listType) {
      this.analyticsResultObject.listType = this.listType;
    }
    if (this.term != null) {
      this.analyticsResultObject.term = sanitizeOssTerm(this.term.toLowerCase());
    }
    this.analyticsResultObject.type = this.type;

    return {
      [ANALYTICS_ATTRIBUTES.oss]: super.build(),
    };
  }
}
