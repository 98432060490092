import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { RMA_TYPE } from '@client/myAccount/rma/utils/rma';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateRmaBodyType,
  RmaDetailedType,
  RmaInvoiceAddressType,
  RmaInvoiceType, UpdateRmaBodyType,
} from 'b2b-common/core/account/api/rma';

const initialState = {
  cancel: RemoteRecordData.getDefault(),
  create: RemoteRecordData.getDefault(),
  rmaInvoices: {},
  rmaInvoicesAddresses: {},
  rmas: {},
  send: RemoteRecordData.getDefault(),
  update: RemoteRecordData.getDefault(),
};

export const rmaSlice = createSlice({
  name: 'rma',
  initialState,
  reducers: {
    createRma: (state, action: PayloadAction<{
      data: CreateRmaBodyType;
      type: RMA_TYPE;
      isStandalone: boolean;
    }>) => {
      state.create = RemoteRecordData.init();
      return state;
    },
    createRmaFulfilled: (state) => {
      state.create = RemoteRecordData.fulfill({});
      return state;
    },
    createRmaRejected: (state, action: PayloadAction<ErrorType>) => {
      state.create = RemoteRecordData.reject(action.payload);
      return state;
    },
    clearRmaCreate: (state) => {
      state.create = initialState.create;
      return state;
    },
    fetchRma: (state, action: PayloadAction<{
      rmaId: string;
      debitorId?: string;
    }>) => {
      state.rmas[action.payload.rmaId] = RemoteRecordData.init();
      return state;
    },
    fetchRmaFulfilled: (state, action: PayloadAction<{
      rmaId: string;
      data: RmaDetailedType;
    }>) => {
      state.rmas[action.payload.rmaId] = RemoteRecordData.fulfill(action.payload.data);
      return state;
    },
    fetchRmaRejected: (state, action: PayloadAction<{
      rmaId: string;
      error: ErrorType;
    }>) => {
      state.rmas[action.payload.rmaId] = RemoteRecordData.reject(action.payload.error);
      return state;
    },
    fetchRmaInvoiceAddress: (state, action: PayloadAction<string>) => {
      state.rmaInvoicesAddresses[action.payload] = RemoteRecordData.init();
      return state;
    },
    fetchRmaInvoiceAddressFulfilled: (state, action: PayloadAction<{
      rmaInvoiceId: string;
      data: RmaInvoiceAddressType;
    }>) => {
      state.rmaInvoicesAddresses[action.payload.rmaInvoiceId] = RemoteRecordData.fulfill(action.payload.data);
      return state;
    },
    fetchRmaInvoiceAddressRejected: (state, action: PayloadAction<{
      rmaInvoiceId: string;
      error: ErrorType;
    }>) => {
      state.rmaInvoicesAddresses[action.payload.rmaInvoiceId] = RemoteRecordData.reject(action.payload.error);
      return state;
    },
    fetchRmaInvoice: (state, action: PayloadAction<{
      rmaInvoiceId: string,
      debitorId?: string;
    }>) => {
      state.rmaInvoices[action.payload.rmaInvoiceId] = RemoteRecordData.init();
      return state;
    },
    fetchRmaInvoiceFulfilled: (state, action: PayloadAction<{
      rmaInvoiceId: string;
      data: RmaInvoiceType;
    }>) => {
      state.rmaInvoices[action.payload.rmaInvoiceId] = RemoteRecordData.fulfill(action.payload.data);
      return state;
    },
    fetchRmaInvoiceRejected: (state, action: PayloadAction<{
      rmaInvoiceId: string;
      error: ErrorType;
    }>) => {
      state.rmaInvoices[action.payload.rmaInvoiceId] = RemoteRecordData.reject(action.payload.error);
      return state;
    },
    clearRmaInvoices: (state) => {
      state.rmaInvoices = initialState.rmaInvoices;
      return state;
    },
    cancelRma: (state, action: PayloadAction<string>) => {
      state.cancel = RemoteRecordData.init();
      return state;
    },
    cancelRmaFulfilled: (state) => {
      state.cancel = RemoteRecordData.fulfill({});
      return state;
    },
    cancelRmaRejected: (state, action: PayloadAction<ErrorType>) => {
      state.cancel = RemoteRecordData.reject(action.payload);
      return state;
    },
    clearRmaCancel: (state) => {
      state.cancel = initialState.cancel;
      return state;
    },
    updateRma: (state, action: PayloadAction<{
      rmaId: string;
      data: UpdateRmaBodyType;
      debitorId?: string;
    }>) => {
      state.update = RemoteRecordData.init();
      return state;
    },
    updateRmaFulfilled: (state) => {
      state.update = RemoteRecordData.fulfill({});
      return state;
    },
    updateRmaRejected: (state, action: PayloadAction<ErrorType>) => {
      state.update = RemoteRecordData.reject(action.payload);
      return state;
    },
    clearRmaUpdate: (state) => {
      state.update = initialState.update;
      return state;
    },
    sendRmaEmail: (state, action: PayloadAction<{
      debitorId: string;
      rmaId: string;
      email: string;
    }>) => {
      state.send = RemoteRecordData.init();
      return state;
    },
    sendRmaEmailFulfilled: (state) => {
      state.send = RemoteRecordData.fulfill({});
      return state;
    },
    sendRmaEmailRejected: (state, action: PayloadAction<ErrorType>) => {
      state.send = RemoteRecordData.reject(action.payload);
      return state;
    },
    clearRmaSendEmail: (state) => {
      state.send = initialState.send;
      return state;
    },
    clearAccountRma: () => initialState,
  },
});

export const {
  createRma,
  createRmaFulfilled,
  createRmaRejected,
  clearRmaCreate,
  fetchRma,
  fetchRmaFulfilled,
  fetchRmaRejected,
  fetchRmaInvoiceAddress,
  fetchRmaInvoiceAddressFulfilled,
  fetchRmaInvoiceAddressRejected,
  fetchRmaInvoice,
  fetchRmaInvoiceFulfilled,
  fetchRmaInvoiceRejected,
  clearRmaInvoices,
  cancelRma,
  cancelRmaFulfilled,
  cancelRmaRejected,
  clearRmaCancel,
  updateRma,
  updateRmaFulfilled,
  updateRmaRejected,
  clearRmaUpdate,
  sendRmaEmail,
  sendRmaEmailFulfilled,
  sendRmaEmailRejected,
  clearRmaSendEmail,
  clearAccountRma,
} = rmaSlice.actions;

const getState = (state: AppState) => state.myAccount.rma.rma;

export const selectRma = createSelector(
  [getState, (state, rmaId: string) => rmaId],
  (state, rmaId) => new RemoteRecord<RmaDetailedType>(state.rmas[rmaId]),
);

export const selectRmaInvoice = createSelector(
  [getState, (state, rmaInvoiceId: string) => rmaInvoiceId],
  (state, rmaInvoiceId) => new RemoteRecord<RmaInvoiceType>(state.rmaInvoices[rmaInvoiceId]),
);

export const selectRmaInvoiceAddress = createSelector(
  [getState, (state, rmaInvoiceId: string) => rmaInvoiceId],
  (state, rmaInvoiceId) => new RemoteRecord<RmaInvoiceAddressType>(state.rmaInvoicesAddresses[rmaInvoiceId]),
);

export const selectCancelRma = createSelector(
  getState,
  state => new RemoteRecord(state.cancel),
);

export const selectCreateRma = createSelector(
  getState,
  state => new RemoteRecord(state.create),
);

export const selectUpdateRma = createSelector(
  getState,
  state => new RemoteRecord(state.update),
);

export const selectSendRmaEmail = createSelector(
  getState,
  state => new RemoteRecord(state.send),
);
