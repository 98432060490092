import {
  analyzeCartPageView,
  analyzeClearCart,
  analyzeItemClick,
  analyzeItemQuantityChange,
} from '@client/analytics/redux';
import { analyzeView } from '@client/analytics/redux/tealium/actions';
import {
  CartAnalyticsBuilder,
} from '@client/analytics/utils/builders/CartAnalyticsBuilder';
import {
  fetchCartFulfilled,
  fetchCartRejected,
  removeCartFulfilled,
  selectCart,
  selectCartProductBySku,
  updateItemQuantityFulfilled,
  updateItemQuantityRejected,
} from '@client/cart/redux/cart/redux';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import {
  all, AllEffect,
  call, CallEffect,
  ForkEffect,
  put, PutEffect,
  race, RaceEffect,
  select, SelectEffect,
  take, TakeEffect,
  takeLatest,
} from 'redux-saga/effects';
import { CartProductType, CartType } from 'b2b-common/core/cart/api/CartTypes';
import { getInteractionProductFromCartItem } from '../products/utils';
import { analyzeCartProductAddInteraction, analyzeCartProductClickInteraction, analyzeCartProductRemoveInteraction } from './interactions';

function* analyzeCartPageViewSaga(): Generator<
    SelectEffect | PutEffect| RaceEffect<TakeEffect>,
    void,
    RemoteRecord<CartType>
    & { fulfilled: ReturnType<typeof fetchCartFulfilled>, rejected: ReturnType<typeof fetchCartRejected> }
    > {
  const { fulfilled, rejected } = yield race({
    fulfilled: take(fetchCartFulfilled.type),
    rejected: take(fetchCartRejected.type),
  });

  if (rejected) {
    return;
  }

  const products = fulfilled.payload?.products || [];
  const cartAnalyticsBuilder = new CartAnalyticsBuilder();
  cartAnalyticsBuilder.setProducts(products);

  yield put(analyzeView(cartAnalyticsBuilder.build()));
}

function* analyzeItemClickSaga(action: ReturnType<typeof analyzeItemClick>): Generator<
    CallEffect | SelectEffect,
    void,
    CartProductType
  > {
  const { sku, listPosition } = action.payload;
  const cartProduct = yield select(selectCartProductBySku, sku);

  if (!cartProduct) {
    return;
  }

  yield call(
    analyzeCartProductClickInteraction,
    { ...getInteractionProductFromCartItem(cartProduct), listPosition },
  );
}

function* analyzeItemQuantityChangeSaga(action: ReturnType<typeof analyzeItemQuantityChange>): Generator<
  CallEffect | SelectEffect | RaceEffect<TakeEffect>,
  void,
  & CartProductType
  & {
    fulfilled: ReturnType<typeof updateItemQuantityFulfilled>,
    rejected: ReturnType<typeof updateItemQuantityRejected>
  }
  > {
  const { sku, quantity, listPosition } = action.payload;
  const cartProduct = yield select(selectCartProductBySku, sku);

  if (!cartProduct) {
    return;
  }

  const { rejected } = yield race({
    fulfilled: take(updateItemQuantityFulfilled.type),
    rejected: take(updateItemQuantityRejected.type),
  });

  if (rejected) {
    return;
  }

  const product = {
    ...getInteractionProductFromCartItem(cartProduct),
    listPosition,
    quantity: Math.abs(quantity),
  };

  if (quantity < 0) {
    yield call(analyzeCartProductRemoveInteraction, [product]);
    return;
  }

  yield call(analyzeCartProductAddInteraction, [product]);
}

function* analyzeClearCartSaga(): Generator<
  CallEffect | SelectEffect | RaceEffect<TakeEffect>,
  void,
  & RemoteRecord<CartType>
  & { fulfilled: ReturnType<typeof removeCartFulfilled>, rejected: ReturnType<typeof fetchCartRejected> }
  > {
  const cartRecord = yield select(selectCart);
  const cart = cartRecord.data;

  if (!cart) {
    return;
  }

  const { rejected } = yield race({
    fulfilled: take(removeCartFulfilled.type),
    rejected: take(fetchCartRejected.type),
  });

  if (rejected) {
    return;
  }

  const products = cart.products.map((product, index) => ({
    ...getInteractionProductFromCartItem(product),
    quantity: product.cart.quantity,
    listPosition: index + 1,
  }));

  yield call(
    analyzeCartProductRemoveInteraction,
    products,
  );
}

export function* cartAnalyticsSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(analyzeCartPageView.type, analyzeCartPageViewSaga),
    takeLatest(analyzeItemClick.type, analyzeItemClickSaga),
    takeLatest(analyzeItemQuantityChange.type, analyzeItemQuantityChangeSaga),
    takeLatest(analyzeClearCart.type, analyzeClearCartSaga),
  ]);
}

