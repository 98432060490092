import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { CollectionEntries } from '@client/content/api/CollectionEntries';
import {
  fetchContentCollectionEntity,
  fetchContentCollectionEntityFulfilled,
  fetchContentCollectionEntityRejected,
} from '@client/content/redux/contentCollectionsEntries/redux';
import { all, call, put, takeEvery } from 'redux-saga/effects';

function* fetchContentCollectionEntrySaga(
  action: ReturnType<typeof fetchContentCollectionEntity>,
): Generator<any, any, any> {
  const { collectionId, entryId } = action.payload;

  try {
    const response = yield call(
      requestApiSaga,
      CollectionEntries.fetch(collectionId, {
        slug: entryId,
      }),
    );
    yield put(
      fetchContentCollectionEntityFulfilled({
        collectionId,
        entryId,
        data: CollectionEntries.mapResponseToState(response),
      }),
    );
  } catch (error) {
    yield put(
      fetchContentCollectionEntityRejected({
        collectionId,
        entryId,
        error: parseError(error),
      }),
    );
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* contentCollectionsEntriesSaga(): Generator<any, any, any> {
  yield all([
    takeEvery(fetchContentCollectionEntity.type, fetchContentCollectionEntrySaga),
  ]);
}
