import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { EntriesMap } from '@client/content/api/EntriesMap';
import {
  fetchEntriesMap,
  fetchEntriesMapFulfilled,
  fetchEntriesMapRejected,
} from '@client/content/redux/entriesMap/redux';
import { all, call, put, takeEvery } from 'redux-saga/effects';

function* fetchEntriesMapSaga(
  action: ReturnType<typeof fetchEntriesMap>,
): Generator<any, any, any> {
  try {
    const response = yield call(requestApiSaga, EntriesMap.fetch());
    yield put(
      fetchEntriesMapFulfilled(EntriesMap.mapResponseToState(response)),
    );
  } catch (error) {
    yield put(fetchEntriesMapRejected(parseError(error)));
    yield put(reportError({ message: action.type, error }));
  }
}

function* entriesMapSaga(): Generator<any, any, any> {
  yield all([takeEvery(fetchEntriesMap.type, fetchEntriesMapSaga)]);
}

export { entriesMapSaga };
