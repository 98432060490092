export enum BUILD_ENV {
  dev = 'development',
  production = 'production',
}

export const isDevelopmentBuild = (): boolean => process.env.NODE_ENV === BUILD_ENV.dev;
export const isProductionBuild = (): boolean => process.env.NODE_ENV === BUILD_ENV.production;

export enum APP_ENV {
  dev = 'dev',
  stage = 'stage',
  releaseCandidate = 'rc',
  production = 'prod',
}

export const isAppEnvDev = (): boolean => process.env.REACT_APP_ENV === APP_ENV.dev;
export const isAppEnvProduction = (): boolean => process.env.REACT_APP_ENV === APP_ENV.production;
export const isAppEnvRc = (): boolean => process.env.REACT_APP_ENV === APP_ENV.releaseCandidate;
export const isAppEnvStage = (): boolean => process.env.REACT_APP_ENV === APP_ENV.stage;
/**
 * is env dev or stage?
 */
export const isAppEnvSafe = (): boolean => isAppEnvDev() || isAppEnvStage();
export const isShopBrackBusiness = (): boolean => process.env.SHOP?.toLowerCase() === 'brackbusiness';
export const isShopAlltron = (): boolean => process.env.SHOP?.toLowerCase() === 'alltron';

