import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { fetchHomepage, selectHomepage } from '@client/content/redux/homepage/redux';
import { HomepageType } from 'b2b-common/core/content/Content.types';

export const useHomepage = (): RemoteRecord<HomepageType> => {
  const dispatch = useDispatch();
  const homepage = useSelector(selectHomepage);

  useEffect(() => {
    if (!homepage.isLoaded) {
      dispatch(fetchHomepage());
    }
  }, [homepage.isLoaded]);

  return homepage;
};
