import { retryRequestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { CoreAssortment } from '@client/product/api/CoreAssortment';
import {
  fetchCoreAssortmentCount,
  fetchCoreAssortmentCountFulfilled,
  fetchCoreAssortmentCountRejected,
} from '@client/product/redux/coreAssortment/redux';
import { CoreAssortmentResponseType } from '@client/product/redux/coreAssortment/types';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

function* fetchCoreAssortmentCountSaga(
  action: ReturnType<typeof fetchCoreAssortmentCount>,
): Generator<CallEffect | PutEffect, any, AxiosResponse<CoreAssortmentResponseType>> {
  try {
    const response = yield call(retryRequestApiSaga, CoreAssortment.fetch());
    yield put(fetchCoreAssortmentCountFulfilled(response.data));
  } catch (error) {
    yield put(fetchCoreAssortmentCountRejected(parseError(error)));
    yield put(reportError({ message: action.type, error }));
  }
}


export function* coreAssortmentSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(fetchCoreAssortmentCount.type, fetchCoreAssortmentCountSaga),
  ]);
}
