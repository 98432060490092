import { ReactNode } from 'react';
import { toast } from 'react-toastify';
import { Toast, TOAST_TYPE } from '@client/common/components/Toast';

export const showToast = (
  type: TOAST_TYPE,
  header: string | null,
  content: ReactNode | null | undefined,
): void => {
  toast(<Toast type={type} header={header} content={content} />);
};
