import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import {
  AddKanbanItemsToCartRequestBodyType,
  PlaceKanbanOrderRequestBodyType,
  VerifyKanbanItemsRequestBodyType,
} from 'b2b-common/core/kanban/Kanban.types';

export class Kanban {
  public static verifyKanbanItems(data: VerifyKanbanItemsRequestBodyType): RequestConfigType {
    return RequestConfig.post('/kanban/verify', data);
  }

  public static addKanbanItemsToCart(data: AddKanbanItemsToCartRequestBodyType): RequestConfigType {
    return RequestConfig.post('/kanban/cart', data);
  }

  public static placeKanbanOrder(data: PlaceKanbanOrderRequestBodyType): RequestConfigType {
    return RequestConfig.post('/kanban/order', data);
  }
}
