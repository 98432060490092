import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddItemsToCartType } from 'b2b-common/core/cart/api/CartTypes';
import { CheckoutDataType } from 'b2b-common/core/checkout/Checkout.types';
import { VerifyRequestBodyType } from 'b2b-common/core/expressOrder/api/ExpressOrder.types';
import {
  AddKanbanItemsToCartBodyType,
  PlaceKanbanOrderDataType,
  PlaceKanbanOrderRequestBodyType,
  VerifyKanbanItemType,
} from 'b2b-common/core/kanban';

const initialState = {
  verifyKanbanItems: RemoteRecordData.getDefault(),
  addItemsToCart: RemoteRecordData.getDefault(),
  placeKanbanOrder: RemoteRecordData.getDefault(),
};

const NAME = 'kanban';

export const kanbanSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState,
  reducers: {
    verifyKanbanItems: (state, action: PayloadAction<VerifyRequestBodyType>) => {
      state.verifyKanbanItems = RemoteRecordData.init();
      return state;
    },
    verifyKanbanItemsFulfilled: (state, action: PayloadAction<VerifyKanbanItemType[]>) => {
      state.verifyKanbanItems = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    verifyKanbanItemsRejected: (state, action: PayloadAction<ErrorType>) => {
      state.verifyKanbanItems = RemoteRecordData.reject(action.payload);
      return state;
    },
    clearVerifyKanbanItems: (state) => {
      state.verifyKanbanItems = initialState.verifyKanbanItems;
      return state;
    },
    addKanbanItemsToCart: (state, action: PayloadAction<AddKanbanItemsToCartBodyType>) => {
      state.addItemsToCart = RemoteRecordData.init();
      return state;
    },
    addKanbanItemsToCartFulfilled: (state, action: PayloadAction<AddItemsToCartType>) => {
      state.addItemsToCart = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    addKanbanItemsToCartRejected: (state, action: PayloadAction<ErrorType>) => {
      state.addItemsToCart = RemoteRecordData.reject(action.payload);
      return state;
    },
    clearAddKanbanItemsToCart: (state) => {
      state.addItemsToCart = initialState.addItemsToCart;
      return state;
    },
    placeKanbanOrder: (state, action: PayloadAction<PlaceKanbanOrderRequestBodyType>) => {
      state.placeKanbanOrder = RemoteRecordData.init();
      return state;
    },
    placeKanbanOrderFulfilled: (state, action: PayloadAction<CheckoutDataType>) => {
      state.placeKanbanOrder = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    placeKanbanOrderRejected: (state, action: PayloadAction<ErrorType>) => {
      state.placeKanbanOrder = RemoteRecordData.reject(action.payload);
      return state;
    },
    clearPlaceKanbanOrder: (state) => {
      state.placeKanbanOrder = initialState.placeKanbanOrder;
      return state;
    },
  },
});

export const {
  verifyKanbanItems,
  verifyKanbanItemsFulfilled,
  verifyKanbanItemsRejected,
  clearVerifyKanbanItems,
  addKanbanItemsToCart,
  addKanbanItemsToCartFulfilled,
  addKanbanItemsToCartRejected,
  clearAddKanbanItemsToCart,
  placeKanbanOrder,
  placeKanbanOrderFulfilled,
  placeKanbanOrderRejected,
  clearPlaceKanbanOrder,
} = kanbanSlice.actions;

const getState = (state: AppState) => state.kanban;

export const selectVerifyKanbanItems = createSelector(
  getState, state => new RemoteRecord<VerifyKanbanItemType[]>(state.verifyKanbanItems),
);

export const selectAddKanbanItemsToCart = createSelector(
  getState, state => new RemoteRecord<AddItemsToCartType>(state.addItemsToCart),
);

export const selectPlaceKanbanOrder = createSelector(
  getState, state => new RemoteRecord<PlaceKanbanOrderDataType>(state.placeKanbanOrder),
);
