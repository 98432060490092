import { useLocation } from 'react-router-dom';
import { FILTERS } from '@client/catalog/utils/facets';
import { parse, stringify } from '@client/common/utils/queryParams';
import { useRouterPush } from '@client/routes/hooks';
import { FiltersValuesType } from 'b2b-common/core/catalog/Catalog.types';

type UseUpdateSearchUrlParamsReturnType = {
  updateSearchUrlParams: (filters: FiltersValuesType) => void;
};

export function useUpdateSearchUrlParams(): UseUpdateSearchUrlParamsReturnType {
  const push = useRouterPush();
  const { search } = useLocation();

  const updateSearchUrlParams = (filters: FiltersValuesType): void => {
    const currentFilters = parse(search);
    const newFilters = {
      ...currentFilters,
      [FILTERS.pagination]: {
        ...currentFilters[FILTERS.pagination],
        page: 1,
      },
      ...filters,
    };
    push({ search: stringify(newFilters) });
  };

  return { updateSearchUrlParams };
}
