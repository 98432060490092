import { Assortment } from '@client/assortment/api/Assortment';
import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { fetchOwnAssortment, fetchOwnAssortmentFulfilled, fetchOwnAssortmentRejected } from './redux';

function* fetchOwnAssortmentSaga(
  action: typeof fetchOwnAssortment,
): Generator<CallEffect | PutEffect, void, AxiosResponse> {
  try {
    const response = yield call(requestApiSaga, Assortment.fetchOwnAssortment());

    yield put(
      fetchOwnAssortmentFulfilled(response.data),
    );
  } catch (error) {
    yield put(fetchOwnAssortmentRejected(parseError(error)));
    yield put(reportError({ message: action.type, error }));
  }
}

export function* ownAssortmentSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeEvery(fetchOwnAssortment.type, fetchOwnAssortmentSaga),
  ]);
}
