import { MouseEvent, ReactElement, useEffect } from 'react';
import { HOT_JAR_TAG } from '@client/analytics/types/types';
import { tagHotJarRecording } from '@client/analytics/utils/analytics';
import { useDeviceType } from '@client/common/hooks';
import { ProductCategoryType } from '@client/product/redux/products/types';
import { ROUTES } from '@client/routes/components/Router/routes';
import { useOpenLink, useRouterPush, useUrlCreator } from '@client/routes/hooks';
import { getCategoryUrlParams, getCdnImageUrl, getProductFallbackImgUrl } from '@client/routes/utils/url';
import { VariantType } from '@lib/components/ProductList';
import { ProductTileVariantsOptionType } from 'b2b-common/core/product/api/ProductTile.types';
import { ProductBox } from './ProductBox';
import { Props } from './ProductBoxContainer.types';

export const ProductBoxContainer = ({
  onClick,
  onAddToCart,
  isAddToCartHidden,
  areVariantsHidden,
  isForSlider,
  isFromContentList,
  styleType,
  mptId,
  product,
  sku: productSku,
  className,
  showDirectDelivery,
}: Props): ReactElement => {
  const push = useRouterPush();
  const urlCreator = useUrlCreator();
  const deviceType = useDeviceType();
  const openLink = useOpenLink();
  const productData = product?.data;
  const productCategoryLabel = productData?.category?.label;
  const productCategoryUrl = productData?.category?.url;
  const mainImageLink = productData?.cover?.sizes?.filter(
    (size) => size.size === 'L',
  )?.[0]?.link;
  const options = productData?.variants?.options || [];
  const hasThumbnails = !!options.find(
    (variant: ProductTileVariantsOptionType) => variant.thumbnail,
  );

  useEffect(
    (): void => {
      if (product?.hasFailed) {
        tagHotJarRecording(HOT_JAR_TAG.productNotLoaded);
      }
    },
    [product?.hasFailed],
  );

  const category = (): ProductCategoryType => ({
    label: productCategoryLabel || '',
    url: productCategoryUrl
      ? `${urlCreator(ROUTES.category)}${getCategoryUrlParams(
        productCategoryUrl,
      )}`
      : '#',
  });

  const imageUrl = (): string => {
    if (mainImageLink) {
      return getCdnImageUrl(mainImageLink) || getProductFallbackImgUrl();
    }

    return getProductFallbackImgUrl();
  };

  const getVariant = (variant: ProductTileVariantsOptionType): VariantType => {
    const fallbackUrl = hasThumbnails ? getProductFallbackImgUrl() : null;
    return {
      sku: variant.link,
      label: variant.label,
      url: urlCreator(ROUTES.product, {
        sku: variant.link,
      }),
      thumbnail: getCdnImageUrl(variant.thumbnail) || fallbackUrl,
    };
  };

  const handleProductClick = (event: MouseEvent<HTMLElement>, clickedSku?: string) => {
    const correctSku = clickedSku || productSku;
    onClick?.(correctSku);

    return openLink(event, urlCreator(ROUTES.product, {
      sku: correctSku,
    }));
  };

  const handleAddToCart = (sku: string) => {
    onAddToCart?.(sku);
  };

  const variants = options.map(getVariant);

  return (
    <ProductBox
      product={product}
      isAddToCartHidden={isAddToCartHidden}
      areVariantsHidden={areVariantsHidden}
      isForSlider={isForSlider}
      isFromContentList={isFromContentList}
      styleType={styleType}
      mptId={mptId}
      sku={productSku}
      deviceType={deviceType}
      category={category()}
      imageUrl={imageUrl()}
      variants={variants}
      push={push}
      urlCreator={urlCreator}
      onClick={handleProductClick}
      onAddToCart={handleAddToCart}
      className={className}
      showDirectDelivery={showDirectDelivery}
    />
  );
};
