import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { AppState } from '@client/common/redux/store';
import { ContentEntryType } from 'b2b-common/core/content/Content.types';
import { fetchContentEntry, selectEntryRecord } from '../redux/contentEntries/redux';

export const useEntry = (entryId: string): RemoteRecord<ContentEntryType> => {
  const dispatch = useDispatch();
  const content = useSelector((state: AppState) => selectEntryRecord(state, entryId));

  useEffect(
    (): void => {
      if (!content.isLoaded) {
        dispatch(fetchContentEntry(entryId));
      }
    },
    [content.isLoaded, entryId],
  );

  return content;
};
