import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { ManufacturersType } from '@client/manufacturer/redux/manufacturers/types';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

const NAME = 'manufacturers';

export const manufacturersSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: RemoteRecordData.getDefault(),
  reducers: {
    fetchManufacturers: (state, action: PayloadAction<{
      filter: string | null | undefined;
      query: string | null | undefined;
    }>) => (
      Object.assign(RemoteRecordData.init(), {
        data: state.data,
      })
    ),
    fetchManufacturersFulfilled: (state, action: PayloadAction<ManufacturersType>) => (
      RemoteRecordData.fulfill(
        Object.assign({}, state.data, action.payload),
      )
    ),
    fetchManufacturersRejected: (state, action: PayloadAction<ErrorType>) => (
      RemoteRecordData.reject(action.payload)
    ),
  },
});

export const {
  fetchManufacturers,
  fetchManufacturersFulfilled,
  fetchManufacturersRejected,
} = manufacturersSlice.actions;


const getState = (state: AppState) => state.manufacturer.manufacturers;

export const selectManufacturers = createSelector(
  getState,
  state => new RemoteRecord<ManufacturersType>(state),
);
