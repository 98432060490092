import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CostCenterAccounts } from 'b2b-common/core/account/api/costCenters/CostCenterAccounts.types';

const NAME = 'accounts';

export const accountsSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: RemoteRecordData.getDefault(),
  reducers: {
    fetchCostCenterAccounts: (state, action: PayloadAction<string>) => RemoteRecordData.init(),
    fetchCostCenterAccountsFulfilled: (state, action: PayloadAction<CostCenterAccounts>) => (
      RemoteRecordData.fulfill(action.payload)
    ),
    fetchCostCenterAccountsRejected: (state, action: PayloadAction<ErrorType>) => (
      RemoteRecordData.reject(action.payload)
    ),
  },
});

export const {
  fetchCostCenterAccounts,
  fetchCostCenterAccountsFulfilled,
  fetchCostCenterAccountsRejected,
} = accountsSlice.actions;

const getState = (state: AppState) => state.myAccount.costCenter.accounts;

export const selectCostCenterAccounts = createSelector(
  getState,
  state => new RemoteRecord<CostCenterAccounts>(state),
);
