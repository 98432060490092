import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { ChangeCostCenterBudgetingDataType } from '@client/myAccount/costCenters/components/Budgets/ListPage';
import { FormValuesType } from '@client/myAccount/costCenters/components/FormPage';
import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CostCenterType, NewCostCenterResponseType } from 'b2b-common/core/account/api/costCenters/CostCenter.types';

const NAME = 'details';

export const costCenterSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {
    submitAction: RemoteRecordData.init(),
  },
  reducers: {
    fetchCostCenter: (state, action: PayloadAction<string>) => {
      state[action.payload] = RemoteRecordData.init();
      return state;
    },
    fetchCostCenterFulfilled: (state, action: PayloadAction<{
      id: string,
      data: CostCenterType,
    }>) => {
      state[action.payload.id] = RemoteRecordData.fulfill(action.payload.data);
      return state;
    },
    fetchCostCenterRejected: (state, action: PayloadAction<{
      id: string,
      error: ErrorType;
    }>) => {
      state[action.payload.id] = RemoteRecordData.reject(action.payload.error);
      return state;
    },
    submitCostCenterFulfilled: (state, action: PayloadAction<NewCostCenterResponseType>) => {
      state[action.payload.uuid] = {
        ...state[action.payload.uuid],
        ...RemoteRecordData.fulfill(action.payload),
      };
      state.submitAction = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    submitCostCenterRejected: (state, action: PayloadAction<ErrorType>) => {
      state.submitAction = RemoteRecordData.reject(action.payload);
      return state;
    },
    clearSubmitCostCenter: (state) => {
      state.submitAction = RemoteRecordData.init();
      return state;
    },
  },
});

export const {
  fetchCostCenter,
  fetchCostCenterFulfilled,
  fetchCostCenterRejected,
  submitCostCenterFulfilled,
  submitCostCenterRejected,
  clearSubmitCostCenter,
} = costCenterSlice.actions;

export const submitCostCenter = createAction<{
  data: FormValuesType | ChangeCostCenterBudgetingDataType,
  id?: string,
  onSubmitEndCallback:(isSubmitting: boolean) => void
    }>('submitCostCenter');

export const removeCostCenter = createAction<{
  id: string,
  onRemoveEndCallback:(isSuccessful: boolean) => void,
    }>('removeCostCenter');

const getState = (state: AppState) => state.myAccount.costCenter.details;

export const selectCostCenterById = createSelector(
  [getState, (state, id) => id],
  (state, id) => new RemoteRecord<CostCenterType>(state[id]),
);

export const selectCostCenterSubmitAction = createSelector(
  getState,
  state => new RemoteRecord<CostCenterType>(state.submitAction),
);
