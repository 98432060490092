import { useDispatch } from 'react-redux';
import { analyzeFlyout } from '@client/analytics/redux';
import { bindActionCreators } from '@reduxjs/toolkit';

export type UseFlyoutAnalyticsType = typeof analyzeFlyout;

export const useFlyoutAnalytics = (): UseFlyoutAnalyticsType => {
  const dispatch = useDispatch();

  return bindActionCreators(analyzeFlyout, dispatch);
};
