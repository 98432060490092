import { retryRequestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Wishlists } from '@client/myAccount/wishlists/api/Wishlists';
import {
  fetchWishlists,
  fetchWishlistsFulfilled,
  fetchWishlistsRejected,
  fetchWishlistsSearch,
  fetchWishlistsSearchFulfilled,
  fetchWishlistsSearchRejected,
} from '@client/myAccount/wishlists/redux/wishlists/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

function* fetchWishlistsSaga(action: ReturnType<typeof fetchWishlists>):
  Generator<CallEffect | PutEffect, any, AxiosResponse> {
  const { pageSize, startItem } = action.payload;

  try {
    const response = yield call(
      retryRequestApiSaga,
      Wishlists.fetch({ pageSize, startItem }),
    );
    yield put(fetchWishlistsFulfilled(response.data));
  } catch (error) {
    yield put(fetchWishlistsRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* fetchWishlistsSearchSaga(action: ReturnType<typeof fetchWishlistsSearch>):
  Generator<CallEffect | PutEffect, any, AxiosResponse> {
  const { query: searchQuery, sort, order } = action.payload;

  try {
    const response = yield call(
      retryRequestApiSaga,
      Wishlists.fetch({
        query: searchQuery,
        ...(sort && { sort }),
        ...(order && { order }),
      }),
    );
    yield put(fetchWishlistsSearchFulfilled({ ...response.data, searchQuery }));
  } catch (error) {
    yield put(fetchWishlistsSearchRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: { error: action.payload } }));
  }
}

export function* wishlistsSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(fetchWishlists.type, fetchWishlistsSaga),
    takeLatest(fetchWishlistsSearch.type, fetchWishlistsSearchSaga),
  ]);
}
