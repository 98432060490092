import { EVENT_SOURCE } from '@client/analytics/types/types';
import { createAction } from '@reduxjs/toolkit';

type PayloadWithSource<P> = P & { eventSource: EVENT_SOURCE; };

export const analyzeProductDetailsPageView = createAction<string>('analyzeProductDetailsPageView');

export type AnalyzeProductsListViewPayload = {
  skus?: string[];
};
export const analyzeProductsListView = createAction<
  PayloadWithSource<AnalyzeProductsListViewPayload>
>('analyzeProductsListView');

export type AnalyzeProductClickPayload = {
  sku: string;
  listPosition?: number;
  additionalId?: string | number;
};
export const analyzeProductClick = createAction<
  PayloadWithSource<AnalyzeProductClickPayload>
>('analyzeProductClick');

export type AnalyzeProductsAddToWishlistPayload = {
  sku: string;
};
export const analyzeProductAddToWishlist = createAction<
  PayloadWithSource<AnalyzeProductsAddToWishlistPayload>
>('analyzeProductAddToWishlist');

export type AnalyzeProductAddToCartPayload = {
  sku: string;
  listPosition?: number;
  additionalId?: string | number;
};
export const analyzeProductAddToCart = createAction<
  PayloadWithSource<AnalyzeProductAddToCartPayload>
>('analyzeProductAddToCart');

export type AnalyzeProductsAddToCartPayload = {
  products: { sku: string, listPosition: number }[];
  additionalId?: string | number;
}
export const analyzeProductsAddToCart = createAction<
  PayloadWithSource<AnalyzeProductsAddToCartPayload>
>('analyzeProductsAddToCart');
