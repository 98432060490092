import { RemoteRecordData, RemoteRecordDataType } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CoreAssortmentResponseType } from './types';

const initialState = RemoteRecordData.getDefault();

const NAME = 'coreAssortment';

export const coreAssortmentSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState,
  reducers: {
    fetchCoreAssortmentCount: (state) => RemoteRecordData.init(),
    fetchCoreAssortmentCountFulfilled: (state, action: PayloadAction<CoreAssortmentResponseType>) => (
      RemoteRecordData.fulfill(action.payload)
    ),
    fetchCoreAssortmentCountRejected: (state, action: PayloadAction<ErrorType>) => (
      RemoteRecordData.reject(action.payload)
    ),
  },
});

export const {
  fetchCoreAssortmentCount,
  fetchCoreAssortmentCountFulfilled,
  fetchCoreAssortmentCountRejected,
} = coreAssortmentSlice.actions;

export const getCoreAssortment = (state: AppState): RemoteRecordDataType<CoreAssortmentResponseType> => (
  state.product.coreAssortment
);
