/* eslint-disable simple-import-sort/imports */
import 'react-toastify/dist/ReactToastify.css';
import 'b2b-components/theme/styles/index.scss';

/* eslint-enable simple-import-sort/imports */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { hydrateRoot } from 'react-dom/client';
import { Root } from '@client/app/components/Root';
import { polyfills } from '@client/common/polyfills';
import { consoleLogger } from '@client/common/utils/logger/consoleLogger';
import { logger, setLogger } from '@client/common/utils/logger/logger';
import { sentryLogger } from '@client/common/utils/logger/sentryLogger';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === 'production') {
  setLogger(sentryLogger);
} else if (process.env.NODE_ENV === 'development') {
  setLogger(consoleLogger);
}

Promise.all(polyfills).then(() => {
  const rootElement = document.getElementById('root');

  if (!rootElement) {
    return;
  }

  hydrateRoot(rootElement, <Root />);
});
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
try {
  serviceWorker.unregister();
} catch (error: any) {
  logger.error(error.message, { error });
}
