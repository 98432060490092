import { ReactElement } from 'react';
import { SCREENSHOT_TOOL_DATA_HIDE } from '@client/common/screenshotTool';
import { useUserData } from '@client/context/hooks';
import { useTranslate } from '@client/i18n/hooks';
import { PrimaryLayout } from '@lib/components/PrimaryLayout';
import { Alert, ALERT_STYLE_TYPE } from 'b2b-components/theme/components/Alert';
import './GlobalAlert.scss';

export const GlobalAlert = (): ReactElement | null => {
  const t = useTranslate();
  const { isLoggedIn, isBlocked } = useUserData();

  const alertMessage = t('This account has been blocked and purchasing has been disabled. Please contact Customer Service for more information.');

  if (isLoggedIn && isBlocked) {
    return (
      <div className='GlobalAlert' {...SCREENSHOT_TOOL_DATA_HIDE}>
        <PrimaryLayout>
          <Alert styleType={ALERT_STYLE_TYPE.warning}>
            {alertMessage}
          </Alert>
        </PrimaryLayout>
      </div>
    );
  }

  return null;
};
