import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';

class CoreAssortment {
  public static fetch(): RequestConfigType {
    return RequestConfig.get(
      '/products/core-assortment/count',
      {
        withLocalization: false,
      },
    );
  }
}

export {
  CoreAssortment,
};
