import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { fetchUserStats, fetchUserStatsFulfilled, fetchUserStatsRejected, UserStats } from '@client/user-stats';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, delay, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { UserStatsType } from 'b2b-common/core/user-stats';

const REFRESH_USER_STATS_DELAY = 5 * 60 * 1000;

function* fetchUserStatsSaga(): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<UserStatsType>
  > {
  try {
    const response = yield call(requestApiSaga, UserStats.getUserStats());

    yield put(fetchUserStatsFulfilled(response.data));
  } catch (error) {
    yield put(fetchUserStatsRejected(parseError(error)));
    yield put(reportError({ message: 'fetchUserStatsSaga error', error }));
  }
}

function* refreshUserStatsSaga(): Generator<
  CallEffect | PutEffect,
  void,
  { isTimeout: boolean, isLogout: boolean }
  > {
  yield delay(REFRESH_USER_STATS_DELAY);
  yield put(fetchUserStats());
}

export function* userStatsSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(fetchUserStats.type, fetchUserStatsSaga),
    takeLatest(fetchUserStatsFulfilled.type, refreshUserStatsSaga),
  ]);
}
