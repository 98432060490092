import { useSelector } from 'react-redux';
import {
  getDebitorId,
  getIsBlocked,
  getIsEndCustomer,
  getIsLoggedIn,
  getProfile,
  getRole,
} from '@client/context/redux/redux';
import { ProfileType } from 'b2b-common/core/context/Context.types';

export type UseUserDataReturnType = {
  isLoggedIn: boolean;
  profile: ProfileType;
  debitorId: string | null;
  role: string | null;
  isBlocked: boolean;
  isPrivate: boolean;
};

export function useUserData(): UseUserDataReturnType {
  return {
    isLoggedIn: useSelector(getIsLoggedIn),
    profile: useSelector(getProfile),
    debitorId: useSelector(getDebitorId),
    role: useSelector(getRole),
    isBlocked: useSelector(getIsBlocked),
    isPrivate: useSelector(getIsEndCustomer),
  };
}
