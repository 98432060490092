import path from 'path';

export const APP_NAME: string = process.env.SHOP || '';

export const ASSETS_PATH = path.resolve(
  process.cwd(),
  `./build/${APP_NAME}`,
);

export const SESSION_COOKIE_NAME = 'sid';
export const COMPARE_PRODUCTS_COOKIE_NAME = 'compare_products';
export const DISABLED_TRACKING_COOKIE_NAME = 'disabled-tracking';
