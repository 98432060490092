import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import {
  AddItemsToCartRequestBodyType,
  AddItemsToWishlistRequestBodyType,
  VerifyRequestBodyType,
} from 'b2b-common/core/expressOrder/api/ExpressOrder.types';

class ExpressOrder {
  public static uploadCsv(file: any): RequestConfigType {
    const formData = new FormData();
    formData.append('file', file, 'import-file.csv');

    return RequestConfig.post('/express-order', formData);
  }

  public static verify(data: VerifyRequestBodyType): RequestConfigType {
    return RequestConfig.post('/express-order/verify', data);
  }

  public static addItemsToCart(data: AddItemsToCartRequestBodyType): RequestConfigType {
    return RequestConfig.post('/express-order/cart', data);
  }

  public static addItemsToWishlist(data: AddItemsToWishlistRequestBodyType): RequestConfigType {
    return RequestConfig.post('/express-order/wishlist', data);
  }
}

export { ExpressOrder };
