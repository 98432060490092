import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData, RemoteRecordDataType } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CollectedOrderDataType,
  PlaceCollectedOrderDataType,
} from 'b2b-common/core/collectedOrder/CollectedOrder.types';

const initialData: CollectedOrderDataType = {
  licenseHolder: {
    hasError: true,
    licenseHolder: '',
  },
  references: {
    order: '',
    sales: '',
  },
};

const initialState: {
  data: CollectedOrderDataType;
  isEnabled: boolean | null;
  place: RemoteRecordDataType<PlaceCollectedOrderDataType>;
} = {
  data: initialData,
  isEnabled: null,
  place: RemoteRecordData.getDefault(),
};

const NAME = 'collectedOrder';

export const collectedOrderSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState,
  reducers: {
    clearCollectedOrder: (state) => {
      state.data = initialData;
      state.isEnabled = null;
      return state;
    },
    placeCollectedOrder: (state, action: PayloadAction<CollectedOrderDataType>) => {
      state.place = RemoteRecordData.init();
      return state;
    },
    placeCollectedOrderFulfilled: (state, action: PayloadAction<PlaceCollectedOrderDataType>) => {
      state.place = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    placeCollectedOrderRejected: (state, action: PayloadAction<ErrorType>) => {
      state.place = RemoteRecordData.reject(action.payload);
      return state;
    },
    toggleCollectedOrder: (state, action: PayloadAction<boolean>) => {
      state.isEnabled = action.payload !== undefined
        ? action.payload
        : !state.isEnabled;
      return state;
    },
    updateCollectedOrder: (state, action: PayloadAction<CollectedOrderDataType>) => {
      state.data = action.payload;
      return state;
    },
  },
});

export const {
  clearCollectedOrder,
  placeCollectedOrder,
  placeCollectedOrderFulfilled,
  placeCollectedOrderRejected,
  toggleCollectedOrder,
  updateCollectedOrder,
} = collectedOrderSlice.actions;

const getState = (state: AppState) => state.collectedOrder;

export const getCollectedOrderIsEnabled = (state: AppState): boolean => getState(state).isEnabled;

export const getCollectedOrderData = (state: AppState): CollectedOrderDataType => getState(state).data;

export const selectPlaceCollectedOrder = createSelector(
  getState,
  (state) => new RemoteRecord<PlaceCollectedOrderDataType>(state.place),
);

