import { ReactElement } from 'react';
import { config } from '@client/config';
import { useTranslate } from '@client/i18n/hooks';
import { StatusCodes } from 'http-status-codes';
import { Alert, ALERT_DISPLAY_TYPE, ALERT_STYLE_TYPE } from 'b2b-components/theme/components/Alert';
import { Fail as FailComponent } from 'b2b-components/theme/components/Fail';
import { Props } from './';

export const Fail = ({ error, ...props }: Props): ReactElement => {
  const t = useTranslate();

  const isReportingErrors = config.showsErrors;

  if (error.statusCode === StatusCodes.FORBIDDEN) {
    return (
      <Alert displayType={ALERT_DISPLAY_TYPE.text} styleType={ALERT_STYLE_TYPE.warning}>
        {t('This content cannot be displayed due to restrictions on your account')}
      </Alert>
    );
  }

  return (
    <FailComponent
      message={
        (isReportingErrors && error && error.message)
        || t('Connection error occurred')
      }
      {...props}
    />
  );
};
