import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { useProductsAnalytics } from '@client/analytics/context/products/ProductsAnalyticsContext';
import { useFetchCartWishlistSearchAction } from '@client/cart/hooks/cartWishlistSearch';
import { RemoteRecordLoading } from '@client/common/components/RemoteRecordHandler';
import { MY_ACCOUNT_MESSAGE, showMyAccountToast } from '@client/myAccount/common/utils/messages';
import { OptionType } from 'b2b-components/theme/components/Form/SearchableSelect';
import emitter, { EVENT } from 'b2b-apps/core/common/utils/eventEmitter';
import { getOnSearchableSelectSearch } from 'b2b-apps/core/common/utils/form/getOnSearchableSelectSearch';
import {
  useAddToWishlistAction,
  useFetchWishlistsAction,
  useWishlistsRecord,
} from 'b2b-apps/core/myAccount/wishlists/hooks';
import { ROUTES } from 'b2b-apps/core/routes/components/Router';
import { useUrlCreator } from 'b2b-apps/core/routes/hooks';
import { CartWishlistType } from 'b2b-common/core/cart/api/CartTypes';
import { Form } from './Form';

interface Props {
  skus: string[];
  onModalClose: () => void;
  onCreate: (value?: string) => void;
}

export const SelectWishlistContent = ({
  skus,
  onModalClose,
  onCreate,
}: Props): ReactElement => {
  const urlCreator = useUrlCreator();
  const fetchWishlistsAction = useFetchWishlistsAction();
  const wishlistsRecord = useWishlistsRecord();
  const fetchWishlistsSearchAction = useFetchCartWishlistSearchAction();
  const addToWishlistAction = useAddToWishlistAction();
  const { analyzeProductAddToWishlist } = useProductsAnalytics();

  useEffect(() => {
    fetchWishlistsAction({ startItem: 0, pageSize: 0 });
  }, []);

  const loadedOptions = wishlistsRecord.isLoaded
    ? wishlistsRecord.data?.wishlists?.map((wishlist) => ({
      value: wishlist.id,
      label: wishlist.name,
    }))
    : null;

  const getOptions = (wishlists: CartWishlistType[] = []) => wishlists.map((wishlist) => ({
    value: wishlist.id,
    label: wishlist.name,
  }));

  const onSearch = getOnSearchableSelectSearch<CartWishlistType, OptionType>(
    fetchWishlistsSearchAction,
    EVENT.searchWishlistsFulfilled,
    EVENT.searchWishlistsRejected,
    getOptions,
  );

  const options = loadedOptions || [];
  const defaultWishlist = options?.[0] || null;
  const [currentWishlist, setCurrentWishlist] = useState<OptionType>();

  const handleAddToWishlistComplete = (): void => {
    if (skus.length === 1) {
      analyzeProductAddToWishlist({ sku: skus[0] });
    }
  };

  const handleChange = (fieldName: string, option: OptionType): void => {
    setCurrentWishlist(option);
  };

  const handleSubmit = (): void => {
    const wishlist = currentWishlist || defaultWishlist;
    if (wishlist && skus?.length) {
      addToWishlistAction({
        name: wishlist.label as string,
        wishlistId: wishlist.value,
        skus,
      });
      handleAddToWishlistComplete();
    }
  };

  const toastContent = (wishlistId: string, wishlistName: string): ReactNode => (
    <a href={urlCreator(ROUTES.myAccountWishlist, { wishlistId })}>
      {wishlistName}
    </a>
  );

  useEffect(() => {
    emitter.on(EVENT.addToWishlistFulfilled, ({ wishlistId, wishlistName }) => {
      showMyAccountToast(MY_ACCOUNT_MESSAGE.successAddToWishlist, toastContent(wishlistId, wishlistName));
      onModalClose();
    });

    return () => {
      emitter.off(EVENT.addToWishlistFulfilled);
    };
  }, []);


  return (
    <RemoteRecordLoading remoteRecords={[wishlistsRecord]}>
      <Form
        currentWishlist={currentWishlist?.value || defaultWishlist?.value || ''}
        onChange={handleChange}
        onModalClose={onModalClose}
        options={options}
        onLoadOptions={onSearch}
        onSubmit={handleSubmit}
        onCreate={onCreate}
      />
    </RemoteRecordLoading>
  );
};
