import { AppState } from '@client/common/redux/store';
import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContextTypes, ProfileType } from 'b2b-common/core/context/Context.types';

const initialState: ContextTypes = {
  isLoggedIn: false,
  profile: null,
  sid: '',
};

const NAME = 'context';

export const contextSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState,
  reducers: {
    setContext: (state, action: PayloadAction<ContextTypes>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { setContext } = contextSlice.actions;

export const syncContext = createAction('syncContext');

const getState = (state: AppState) => state.context;

export const getIsLoggedIn = (state: AppState): boolean => !!getState(state).isLoggedIn;

export const getProfile = (state: AppState): ProfileType => getState(state).profile;

export const selectHasOnlyCoreAssortment = (state: AppState): boolean => (
  getIsLoggedIn(state) && !!getProfile(state)?.hasOnlyCoreAssortment
);

export const getDebitorId = (state: AppState): string | null => getProfile(state)?.debitorId || null;

export const getRole = (state: AppState): string | null => getProfile(state)?.role || null;

export const getIsEndCustomer = (state: AppState): boolean => getProfile(state)?.isEndCustomer === true;

export const getIsBlocked = (state: AppState): boolean => getProfile(state)?.isBlocked || false;

export const getIsFirstOrder = (state: AppState): boolean => getProfile(state)?.isFirstOrder === true;

export const getSid = (state: AppState): string => getProfile(state)?.sid;

export const getProfilePermissions = createSelector(
  getProfile,
  (state) => state?.permissions || [],
);

export const getProfileCertificates = createSelector(
  getProfile,
  (state) => state?.certificates || [],
);
