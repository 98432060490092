import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { Collection } from '@client/content/api/Collection';
import { AxiosResponse } from 'axios';
import { ContentCollectionType } from 'b2b-common/core/content/Content.types';

export class Infobars extends Collection {
  public static fetch(): RequestConfigType {
    return RequestConfig.get(
      '/content/collection/infobars',
      { withLocalization: true },
    );
  }

  public static mapResponseToState<T extends ContentCollectionType[]>(response: AxiosResponse<T>): T {
    return super.mapResponseToState<T>(response);
  }
}
