import { reportError } from '@client/common/redux/errors/actions';
import { handleError } from '@client/common/utils/api/error';
import { getDebitorId } from '@client/context/redux/redux';
import { getActiveLanguage } from '@client/i18n/redux/redux';
import { all, AllEffect, ForkEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects';

function* reportErrorSaga(
  action: ReturnType<typeof reportError>,
): Generator<SelectEffect, void> {
  const { additionalData, error, ignoreNetwork = true, message } = action.payload;
  const debitorId = yield select(getDebitorId);
  const language = yield select(getActiveLanguage);

  handleError(
    message,
    {
      additionalData,
      debitorId,
      error,
      language,
      message,
    },
    ignoreNetwork,
  );
}

export function* errorsSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeEvery(reportError.type, reportErrorSaga),
  ]);
}
