import { reportError } from '@client/common/redux/errors/actions';
import { downloadPDFSaga, printPDFSaga } from '@client/common/redux/pdf/saga';
import { parseError } from '@client/common/utils/api/error';
import {
  downloadProductPdf,
  downloadProductPdfFulfilled,
  downloadProductPdfRejected,
  printProductPdf,
  printProductPdfFulfilled,
  printProductPdfRejected,
} from '@client/product/redux/productPdf/redux';
import { PRODUCT_MESSAGES, showProductToast } from '@client/product/utils/product';
import { all, AllEffect, call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

function* downloadProductPdfSaga(action: ReturnType<typeof downloadProductPdf>) {
  const { content, sku } = action.payload;

  try {
    yield call(downloadPDFSaga, content, `Product ${sku}`);
    yield put(downloadProductPdfFulfilled());
  } catch (error) {
    showProductToast(PRODUCT_MESSAGES.pdfExportError);
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
    yield put(downloadProductPdfRejected({ error: parseError(error) }));
  }
}

function* printProductPdfSaga(action: ReturnType<typeof printProductPdf>) {
  const { content, sku } = action.payload;

  try {
    yield call(printPDFSaga, content, `Product ${sku}`);
    yield put(printProductPdfFulfilled());
  } catch (error) {
    showProductToast(PRODUCT_MESSAGES.pdfExportError);
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
    yield put(printProductPdfRejected({ error: parseError(error) }));
  }
}

export function* productPdfSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeEvery(downloadProductPdf.type, downloadProductPdfSaga),
    takeEvery(printProductPdf.type, printProductPdfSaga),
  ]);
}
