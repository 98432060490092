import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DetailedInvoiceType } from 'b2b-common/core/account/api/invoices';

const NAME = 'invoice';

export const invoiceSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {},
  reducers: {
    fetchInvoice: (state, action: PayloadAction<string>) => {
      state[action.payload] = RemoteRecordData.init();
      return state;
    },
    fetchInvoiceFulfilled: (state, action: PayloadAction<{
      invoiceId: string;
      data: DetailedInvoiceType;
    }>) => {
      state[action.payload.invoiceId] = RemoteRecordData.fulfill(action.payload.data);
      return state;
    },
    fetchInvoiceRejected: (state, action: PayloadAction<{
      invoiceId: string;
      error: ErrorType;
    }>) => {
      state[action.payload.invoiceId] = RemoteRecordData.reject(action.payload.error);
      return state;
    },
  },
});

export const {
  fetchInvoice,
  fetchInvoiceFulfilled,
  fetchInvoiceRejected,
} = invoiceSlice.actions;

const getState = (state: AppState) => state.myAccount.invoices.invoice;

export const selectInvoice = createSelector(
  [getState, (state, invoiceId: string) => invoiceId],
  (state, invoiceId) => new RemoteRecord<DetailedInvoiceType>(state[invoiceId]),
);
