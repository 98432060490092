import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { stringify } from '@client/common/utils/queryParams';

export class Rmas {
  public static fetch(pageSize: number, startItem: number): RequestConfigType {
    const searchParams = { pageSize, startItem };
    return RequestConfig.get(`/account/rma?${stringify(searchParams)}`,
      { withLocalization: false });
  }

  public static search(query: string): RequestConfigType {
    return RequestConfig.get(`/account/rma/search-products?query=${query}`,
      { withLocalization: false });
  }
}
