import { MouseEvent } from 'react';
import { FACET_FILTERS, FILTERS } from '@client/catalog/utils/facets';
import { stringify } from '@client/common/utils/queryParams';
import { config } from '@client/config';
import { Location } from 'history';

const rootUrl = config.url.root || '';
const cdnImagesUrl = config.url.cdn.images || '';
const cdnDocumentsUrl = config.url.cdn.documents || '';
const anchorPattern = /#(([a-z])+)/;

export const openLink = (
  url: string,
  push: (...args: any[]) => any,
  event?: MouseEvent<any>,
): void => {
  const pattern = new RegExp(anchorPattern);
  const result = pattern.exec(url);
  const hash = result?.[0];

  if (hash && (url.startsWith('#') || (url.startsWith('/') || url.startsWith(rootUrl)))) {
    const element = document.querySelector(hash);

    if (element) {
      return element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  if (!isExternalUrl(url) && !(event?.metaKey) && !(event?.ctrlKey)) {
    push(getRouteUrl(url));

    return;
  }

  simulateLinkClick(url, '_blank');
};

export const simulateLinkClick = (
  url: string,
  target = '_self',
): void => {
  const link = Object.assign(document.createElement('a'), {
    href: url,
    target,
  });

  document.body && document.body.appendChild(link);
  link.click();
  document.body && document.body.removeChild(link);
};

export const isExternalUrl = (url: string): boolean => {
  if (!url || !url.startsWith) {
    return true;
  }

  return (
    url.startsWith('//') || (!url.startsWith('/') && !url.startsWith(rootUrl))
  );
};

export const getRouteUrl = (url: string): string => {
  if (url.startsWith) {
    if (url.startsWith('/') && !url.startsWith('//')) {
      return url;
    }

    if (url.startsWith(rootUrl)) {
      return url.replace(rootUrl, '');
    }
  }

  return '/';
};

export const getCdnImageUrl = (
  uri?: string | null,
): string | undefined => {
  if (!uri) {
    return undefined;
  }

  if (uri.startsWith(cdnImagesUrl)) {
    return uri;
  }

  return `${cdnImagesUrl}/${uri.replace(/^\//, '')}`;
};

export const getCdnDocumentUrl = (
  uri: string | null | undefined,
): string | null | undefined => {
  if (!uri) {
    return null;
  }

  return `${cdnDocumentsUrl}/${uri}`;
};

export const getCategoryUrlParams = (category: string): string => {
  const queryParams = stringify({
    [FILTERS.category]: category,
  });

  return `?${queryParams}`;
};

export const getSearchUrlParams = (query: string): string => {
  const queryParams = stringify({
    [FILTERS.query]: query,
  });

  return `?${queryParams}`;
};

export const getBrandUrlParams = (name: string): string => {
  const queryParams = stringify({
    [FILTERS.facetFilters]: {
      [FACET_FILTERS.manufacturer]: [name],
    },
  });

  return `?${queryParams}`;
};

export const getProductFallbackImgUrl = (): string => '/assets/product-fallback.svg';

export const getProductFallbackThumbnailUrl = (): string => '/assets/product-fallback-thumbnail.svg';

export const getPathnameWithSearch = (location: Location): string => location.pathname + location.search;

export const getFullLocation = (location: Location): string => {
  const {
    pathname,
    search,
    hash,
  } = location;

  return `${pathname}${search}${hash}`;
};

export const dropStartingSlash = (url: string): string => url.replace(/^\//, '');

export const getAbsoluteUrl = (url: string): string => {
  const rootUrl = (config.url.root || '/').replace(/\/$/, '');
  const sanitizedUrl = dropStartingSlash(url);

  return `${rootUrl}/${sanitizedUrl}`;
};
