import { ReactElement } from 'react';
import { Button } from '@client/cart/components/AddToCart/Button';
import { Full } from '@client/cart/components/AddToCart/Full';
import { Icon } from '@client/cart/components/AddToCart/Icon';
import { ADD_TO_CART_DISPLAY_TYPE, Props } from './AddToCart.types';

export const AddToCart = ({
  settings,
  type,
  onAddToCart,
  hasAccessCartPermissions,
  hasCertificationInfo,
  quantityAdding = 1,
  isBlocked,
  isLoggedIn,
  isVisible,
  showUnavailable,
  text,
  dataAttributes,
  className,
  shouldHideOnContentMouseOver,
}: Props): ReactElement | null => {
  if (!settings) {
    return null;
  }

  switch (type) {
    case ADD_TO_CART_DISPLAY_TYPE.button:
      return (
        <Button
          className={className}
          dataAttributes={dataAttributes}
          isBlocked={isBlocked}
          hasAccessCartPermissions={hasAccessCartPermissions}
          hasCertificationInfo={hasCertificationInfo}
          isLoggedIn={isLoggedIn}
          onAddToCart={onAddToCart}
          settings={settings}
          text={text}
        />
      );

    case ADD_TO_CART_DISPLAY_TYPE.full:
      return (
        <Full
          className={className}
          dataAttributes={dataAttributes}
          isBlocked={isBlocked}
          hasAccessCartPermissions={hasAccessCartPermissions}
          hasCertificationInfo={hasCertificationInfo}
          isLoggedIn={isLoggedIn}
          onAddToCart={onAddToCart}
          settings={settings}
          text={text}
        />
      );

    case ADD_TO_CART_DISPLAY_TYPE.icon:
    default:
      return (
        <Icon
          className={className}
          dataAttributes={dataAttributes}
          isBlocked={isBlocked}
          hasAccessCartPermissions={hasAccessCartPermissions}
          hasCertificationInfo={hasCertificationInfo}
          isLoggedIn={isLoggedIn}
          onAddToCart={onAddToCart}
          settings={settings}
          text={text}
          quantityAdding={quantityAdding}
          showUnavailable={showUnavailable}
          isVisible={isVisible}
          shouldHideOnContentMouseOver={shouldHideOnContentMouseOver}
        />
      );
  }
};
