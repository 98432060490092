import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import {
  fetchCostCenterOrders,
  fetchCostCenterOrdersFulfilled,
  fetchCostCenterOrdersRejected,
  searchCostCenterOrders,
  searchCostCenterOrdersFulfilled,
  searchCostCenterOrdersRejected,
} from '@client/myAccount/costCenters/redux/orders';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { CostCenterOrders } from '../../api/CostCenterOrders';

function* fetchCostCenterOrdersSaga(
  action: ReturnType<typeof fetchCostCenterOrders>,
): Generator<CallEffect | PutEffect, any, AxiosResponse> {
  const { startItem, pageSize } = action.payload;

  try {
    const response = yield call(requestApiSaga, CostCenterOrders.getOrders(null, null, startItem, pageSize));
    yield put(fetchCostCenterOrdersFulfilled(response.data));
  } catch (error) {
    yield put(fetchCostCenterOrdersRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* searchCostCenterOrdersSaga(
  action: ReturnType<typeof searchCostCenterOrders>,
): Generator<CallEffect | PutEffect, any, AxiosResponse> {
  const { startItem, pageSize, costCenterId, status } = action.payload;

  try {
    const response = yield call(requestApiSaga, CostCenterOrders.getOrders(costCenterId, status, startItem, pageSize));
    yield put(searchCostCenterOrdersFulfilled(response.data));
  } catch (error) {
    yield put(searchCostCenterOrdersRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* costCenterOrdersSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(fetchCostCenterOrders.type, fetchCostCenterOrdersSaga),
    takeLatest(searchCostCenterOrders.type, searchCostCenterOrdersSaga),
  ]);
}
