import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormDataType, FormElementType } from 'b2b-common/core/content/Content.types';

const initialState = {
  elements: {},
  submit: {},
};

const NAME = 'forms';

export const slice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState,
  reducers: {
    fetchForm: (state, action: PayloadAction<{ formId: string }>) => {
      state.elements[action.payload.formId] = RemoteRecordData.init();
      return state;
    },
    fetchFormFulfilled: (state, action: PayloadAction<{ formId: string, data: FormElementType[] }>) => {
      state.elements[action.payload.formId] = RemoteRecordData.fulfill(
        action.payload.data,
      );
      return state;
    },
    fetchFormRejected: (state, action: PayloadAction<{ formId: string, error: ErrorType }>) => {
      state.elements[action.payload.formId] = RemoteRecordData.reject(
        action.payload.error,
      );
      return state;
    },
    submitForm: (state, action: PayloadAction<{ formId: string, data: FormDataType }>) => {
      state.submit[action.payload.formId] = RemoteRecordData.init();
      return state;
    },
    submitFormFulfilled: (state, action: PayloadAction<{
      formId: string,
    }>) => {
      state.submit[action.payload.formId] = RemoteRecordData.fulfill({});
      return state;
    },
    submitFormRejected: (state, action: PayloadAction<{ formId: string, error: ErrorType }>) => {
      state.submit[action.payload.formId] = RemoteRecordData.reject(
        action.payload.error,
      );
      return state;
    },
  },
});

export const {
  fetchForm,
  fetchFormFulfilled,
  fetchFormRejected,
  submitForm,
  submitFormFulfilled,
  submitFormRejected,
} = slice.actions;

const getState = (state: AppState) => state.content.forms;

export const selectForms = createSelector(
  getState,
  (state) => new RemoteRecord<Record<string, any>>(state),
);

export const selectFormElementsById = createSelector(
  [getState, (state, formId: string) => formId],
  (state, formId) => new RemoteRecord<FormElementType[]>(state.elements[formId]),
);

export const selectFormSubmissionById = createSelector(
  [getState, (state, formId: string) => formId],
  (state, formId) => new RemoteRecord<FormElementType[]>(state.submit[formId]),
);
