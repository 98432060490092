import { AssortmentCategoryType } from '@client/assortment/redux/types';
import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { AxiosResponse } from 'axios';

class Assortment {
  public static fetch(): RequestConfigType {
    return RequestConfig.get('/catalog');
  }

  public static fetchOwnAssortment(): RequestConfigType {
    return RequestConfig.get('/catalog/own-assortment');
  }

  public static mapResponseToState({ data }: AxiosResponse<AssortmentCategoryType[]>): AssortmentCategoryType[] {
    return data;
  }
}

export {
  Assortment,
};
