import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

const NAME = 'skuList';

export const skuListSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {},
  reducers: {
    fetchSkuListSearch: (state, action: PayloadAction<{ query: string; queryId: string, randomOrder?: boolean }>) => {
      state[action.payload.queryId] = RemoteRecordData.init();
    },
    fetchSkuListSearchFulfilled: (state, action: PayloadAction<{ data: string[]; queryId: string }>) => {
      state[action.payload.queryId] = RemoteRecordData.fulfill(action.payload.data);
    },
    fetchSkuListSearchRejected: (state, action: PayloadAction<{ error: ErrorType, queryId: string }>) => {
      const record = state[action.payload.queryId];

      state[action.payload.queryId] = Object.assign(
        RemoteRecordData.reject(action.payload.error),
        record?.data
          ? {
            data: record?.data,
          }
          : {},
      );
    },
  },
});

export const {
  fetchSkuListSearch,
  fetchSkuListSearchFulfilled,
  fetchSkuListSearchRejected,
} = skuListSlice.actions;

const getState = (state: AppState) => state.catalog.skuList;

export const selectSkusFromQuery = createSelector(
  [getState, (state, queryId: string) => queryId],
  (state, queryId) => new RemoteRecord<string[]>(state[queryId] || []),
);
