import { lazy } from 'react';
import { config } from '@client/config';
import { getLocalisedPaths } from '@client/routes/utils/paths';
import { PERMISSION } from 'b2b-common/core/context/Context.types';
import { AUTHENTICATED_UNBLOCKED_ROUTE, DEFAULT_ROUTE } from './constants';
import { ORDER_ROUTES_ENUM, OrderRouteType } from './types';

export const order: OrderRouteType = {
  [ORDER_ROUTES_ENUM.cart]: {
    ...AUTHENTICATED_UNBLOCKED_ROUTE,
    paths: getLocalisedPaths('cart', true),
    component: lazy(() => import(
      /* webpackChunkName: "cart" */
      '@client/cart/components/CartPage'
    )),
    allow: ({
      isLoggedIn,
      isBlocked,
      permissionsValidator,
    }) => isLoggedIn && !isBlocked && permissionsValidator([PERMISSION.accessCart]),
  },
  [ORDER_ROUTES_ENUM.checkout]: {
    ...AUTHENTICATED_UNBLOCKED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('checkout/:step?'),
    component: lazy(() => import(
      /* webpackChunkName: "checkout" */
      '@client/checkout/components/CheckoutPage'
    )),
    allow: ({
      isLoggedIn,
      isBlocked,
      permissionsValidator,
    }) => isLoggedIn && !isBlocked && permissionsValidator([PERMISSION.accessCheckout]),
  },
  [ORDER_ROUTES_ENUM.checkoutConfirmation]: {
    ...AUTHENTICATED_UNBLOCKED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('checkout-confirmation'),
    component: lazy(() => import(
      /* webpackChunkName: "checkout-confirmation" */
      '@client/checkout/components/ConfirmationPage'
    )),
    allow: ({
      isLoggedIn,
      isBlocked,
      permissionsValidator,
    }) => isLoggedIn && !isBlocked && permissionsValidator([PERMISSION.accessCheckout]),
  },
  [ORDER_ROUTES_ENUM.checkoutFail]: {
    ...AUTHENTICATED_UNBLOCKED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('checkout-confirmation/fail'),
    component: lazy(() => import(
      /* webpackChunkName: "checkout-confirmation/fail" */
      '@client/checkout/components/FailPage'
    )),
    allow: ({
      isLoggedIn,
      isBlocked,
      permissionsValidator,
    }) => isLoggedIn && !isBlocked && permissionsValidator([PERMISSION.accessCheckout]),
  },
  [ORDER_ROUTES_ENUM.expressOrder]: {
    ...DEFAULT_ROUTE,
    paths: getLocalisedPaths('express-order'),
    component: lazy(() => import(
      /* webpackChunkName: "express-order" */
      '@client/expressOrder/components/ExpressOrderPage'
    )),
    allow: ({
      isLoggedIn,
      permissionsValidator,
    }) => !!config.features.expressOrder
      && isLoggedIn
      && (permissionsValidator([PERMISSION.accessCart])
        || permissionsValidator([PERMISSION.accessWishlist])),
  },
  [ORDER_ROUTES_ENUM.collectedOrderConfirmation]: {
    ...AUTHENTICATED_UNBLOCKED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('collected-order/confirmation'),
    component: lazy(() => import(
      /* webpackChunkName: "collected-order/confirmation" */
      '@client/collectedOrder/components/ConfirmationPage'
    )),
    allow: ({
      isLoggedIn,
      isBlocked,
      permissionsValidator,
    }) => isLoggedIn && !isBlocked && permissionsValidator([PERMISSION.accessCheckoutCollectOrder]),
  },
  [ORDER_ROUTES_ENUM.collectedOrderFail]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('collected-order/fail'),
    component: lazy(() => import(
      /* webpackChunkName: "collected-order/fail" */
      '@client/collectedOrder/components/FailPage'
    )),
  },
};
