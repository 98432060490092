import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { AxiosResponse } from 'axios';
import { ContentEntryType } from 'b2b-common/core/content/Content.types';

class Homepage {
  public static fetch(): RequestConfigType {
    return RequestConfig.get('/content/homepage', { withLocalization: true });
  }

  public static mapResponseToState(response: AxiosResponse<ContentEntryType>): ContentEntryType | null {
    return response.data;
  }
}

export { Homepage };
