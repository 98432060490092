const isMergeableObject = (value: any): boolean => {
  const nonNullObject = value && typeof value === 'object';
  return nonNullObject
    && Object.prototype.toString.call(value) !== '[object RegExp]'
    && Object.prototype.toString.call(value) !== '[object Date]';
};

const emptyTarget = (value: any): any[] | Record<any, any> => Array.isArray(value) ? [] : {};

const clone = (value: any) => isMergeableObject(value) ? merge(emptyTarget(value), value) : value;

const arrayMerge = (target: any, source: any): any => {
  const destination = target.slice();
  source.forEach((e: any, i: number) => {
    if (typeof destination[i] === 'undefined') {
      destination[i] = clone(e);
    } else if (isMergeableObject(e)) {
      destination[i] = merge(target[i], e);
    } else if (target.indexOf(e) === -1) {
      destination.push(clone(e));
    }
  });
  return destination;
};


const mergeObject = (target: any, source: any): any => {
  const destination = {};
  if (isMergeableObject(target)) {
    Object.keys(target).forEach(function (key) {
      destination[key] = clone(target[key]);
    });
  }
  Object.keys(source).forEach(function (key) {
    if (!isMergeableObject(source[key]) || !target[key]) {
      destination[key] = clone(source[key]);
    } else {
      destination[key] = merge(target[key], source[key]);
    }
  });
  return destination;
};

export const merge = (target: any, source: any): any => {
  const array = Array.isArray(source);

  if (array) {
    return Array.isArray(target) ? arrayMerge(target, source) : clone(source);
  }

  return mergeObject(target, source);
};
