import { ReactElement, useEffect } from 'react';
import { useProductsAnalytics } from '@client/analytics/context/products/ProductsAnalyticsContext';
import { useUserData } from '@client/context/hooks';
import { useProductTilesAction, useProductTilesData } from '@client/product/hooks';
import { isProductGroupLoaded } from '@client/product/utils/product';
import { ProductGroup } from '../ProductGroup';
import { Props } from './';

const ProductGroupContainer = ({
  skus,
  wereProductsFetched,
  groupNumber,
  styleType,
  isForSlider,
  isFromContentList,
  absolutePosition,
  className,
  productsMptIds,
  showDirectDelivery,
}: Props): ReactElement => {
  const { isLoggedIn } = useUserData();
  const productTilesAction = useProductTilesAction();
  const productTilesData = useProductTilesData(skus);
  const { analyzeProductAddToCart, analyzeProductClick } = useProductsAnalytics();

  useEffect((): void => {
    if (!wereProductsFetched && !isProductGroupLoaded(skus, productTilesData)) {
      productTilesAction({ skus, groupNumber });
    }
  }, [isLoggedIn, skus.toString()]);

  return (
    <ProductGroup
      skus={skus}
      productsMptIds={productsMptIds}
      productTiles={productTilesData}
      onProductClick={analyzeProductClick}
      onProductAddToCart={analyzeProductAddToCart}
      absolutePosition={absolutePosition}
      styleType={styleType}
      isFromContentList={isFromContentList}
      isForSlider={isForSlider}
      className={className}
      showDirectDelivery={showDirectDelivery}
    />
  );
};

export { ProductGroupContainer as ProductGroup };
