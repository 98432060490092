import { matchPath } from 'react-router-dom';
import { ROUTES, RouteType } from '@client/routes/components/Router/routes';
import { mapEntriesToRoutes } from '@client/routes/utils/mapEntriesToRoutes';
import { EntryMapType } from 'b2b-common/core/content/Content.types';
import { LANGUAGE_CODE } from 'b2b-common/core/i18n/consts';

export const getCurrentRoute = (
  entries: EntryMapType[] | null | undefined,
  pathname: string,
  activeLanguage: LANGUAGE_CODE,
):
  | null
  | (RouteType & {
      params: Record<string, any>;
    }
) => {
  const applicationRoutes = Object.values(ROUTES) as RouteType[];
  const cmsRoutes = Object.values(mapEntriesToRoutes(entries));
  const routes = applicationRoutes.concat(cmsRoutes);

  for (let index = 0; index < routes.length; index++) {
    const route = routes[index];
    const routeConfig = { ...route, path: route.paths?.[activeLanguage] || route.path };

    if (!routeConfig.path) {
      continue;
    }

    const match = matchPath(pathname, routeConfig);

    if (match) {
      return { ...route, params: match.params } as RouteType & { params: Record<string, any>; };
    }
  }

  return null;
};
