import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { SCREENSHOT_TOOL_DATA_ATTR_MPT_ID } from '@client/common/screenshotTool';
import { createKey } from '@client/common/utils/key/key';
import { config } from '@client/config';
import { MetaTagType } from '@client/content/components/ModularPage';
import { useTranslate } from '@client/i18n/hooks';
import { Props } from './Page.types';

export const Page = ({ title, children, className, metaTags, mptId }: Props): ReactElement => {
  const t = useTranslate();
  const dataAttributes: Record<string, any> = {};

  const defaultTitle = t(
    '{shopName}',
    { shopName: config.shopName },
    'Used as default page title',
  );

  const titleTemplate = t(
    '%s | {shopName}',
    { shopName: config.shopName },
    'Page title template, %s is a placeholder for the dynamic page name',
  );

  if (mptId) {
    dataAttributes[SCREENSHOT_TOOL_DATA_ATTR_MPT_ID] = mptId;
  }

  return (
    <div {...dataAttributes} className={className}>
      <Helmet titleTemplate={titleTemplate} defaultTitle={defaultTitle}>
        {title && <title>{title}</title>}
        {metaTags
          && metaTags.map((el: MetaTagType, index: number) => el.name && el.content ? (
            <meta
              key={createKey('meta', 'tag', index)}
              name={el.name}
              content={el.content}
              {...(el.property && { property: el.property })}
            />
          ) : null)}
      </Helmet>

      {children}
    </div>
  );
};
