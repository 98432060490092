import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InvoicesType } from 'b2b-common/core/account/api/invoices';

const NAME = 'invoices';

export const invoicesSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: RemoteRecordData.getDefault(),
  reducers: {
    fetchInvoices: (state, action: PayloadAction<{
      startItem: number;
      pageSize: number;
    }>) => RemoteRecordData.init(),
    fetchInvoicesFulfilled: (state, action: PayloadAction<InvoicesType>) => RemoteRecordData.fulfill(action.payload),
    fetchInvoicesRejected: (state, action: PayloadAction<ErrorType>) => RemoteRecordData.reject(action.payload),
  },
});

export const {
  fetchInvoices,
  fetchInvoicesFulfilled,
  fetchInvoicesRejected,
} = invoicesSlice.actions;

const getState = (state: AppState) => state.myAccount.invoices.invoices;

export const selectInvoices = createSelector(
  getState,
  (state) => new RemoteRecord<InvoicesType>(state),
);
