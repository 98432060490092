import { lazy } from 'react';
import { getLocalisedPaths } from '@client/routes/utils/paths';
import { PERMISSION } from 'b2b-common/core/context/Context.types';
import { AUTHENTICATED_ROUTE, DEFAULT_ROUTE } from '../constants';
import { NEWSLETTER_ROUTES_NAMES, NewsletterRouteType } from './types';

export const newsletter: NewsletterRouteType = {
  [NEWSLETTER_ROUTES_NAMES.myAccountNewsletters]: {
    ...AUTHENTICATED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/newsletters'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/newsletters" */
      '@client/myAccount/newsletters/components/ViewPage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }) => isLoggedIn
      && permissionsValidator([PERMISSION.accessMyAccountBasic]),
  },
  [NEWSLETTER_ROUTES_NAMES.newsletterConfirmation]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('newsletter/confirmation'),
    component: lazy(() => import(
      /* webpackChunkName: "newsletter/confirmation" */
      '@client/newsletter/components/NewsletterConfirmationPage'
    )),
  },
  [NEWSLETTER_ROUTES_NAMES.newsletterSignUpFail]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('newsletter/sign-up/fail'),
    component: lazy(() => import(
      /* webpackChunkName: "newsletter/sign-up/fail" */
      '@client/newsletter/components/NewsletterFailedPage'
    )),
  },
  [NEWSLETTER_ROUTES_NAMES.newsletterSignUpSuccess]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('newsletter/sign-up/success'),
    component: lazy(() => import(
      /* webpackChunkName: "newsletter/sign-up/success" */
      '@client/newsletter/components/NewsletterSuccessfulPage'
    )),
  },
};
