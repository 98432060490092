import { lazy } from 'react';
import { getLocalisedPaths } from '@client/routes/utils/paths';
import { PERMISSION } from 'b2b-common/core/context/Context.types';
import { AUTHENTICATED_ROUTE } from '../constants';
import { DASHBOARD_ROUTES_NAMES, DashboardRouteType } from './types';

export const dashboard: DashboardRouteType = {
  [DASHBOARD_ROUTES_NAMES.myAccount]: {
    ...AUTHENTICATED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/dashboard'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/dashboard" */
      '@client/myAccount/dashboard/components/DashboardPage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }) => isLoggedIn
      && permissionsValidator([PERMISSION.accessMyAccountDashboard]),
  },
};
