import { call, CallEffect } from 'redux-saga/effects';
import { TEALIUM_INTERACTION_ID, TEALIUM_PROD_ACTION } from '../../../types/types';
import { analyzeProductsInteraction } from '../interactions';
import { InteractionProduct } from '../types';

export function* analyzeProductsAddToCartInteraction(
  action: TEALIUM_PROD_ACTION,
  products: InteractionProduct[],
): Generator<CallEffect, void> {
  yield call(
    analyzeProductsInteraction,
    TEALIUM_INTERACTION_ID.addToCart,
    action,
    null,
    { products },
  );
}
