import {
  analyzeCheckoutStepView,
  analyzeOrderConfirmationPage,
  CHECKOUT_TYPE,
} from '@client/analytics/redux';
import { analyzeView } from '@client/analytics/redux/tealium/actions';
import {
  TEALIUM_INTERACTION_ID,
  TEALIUM_PAGE_TYPE,
  TEALIUM_PROD_ACTION,
} from '@client/analytics/types/types';
import { CheckoutBaseAnalyticsBuilder } from '@client/analytics/utils/builders/CheckoutBaseAnalyticsBuilder';
import {
  CheckoutConfirmationPageAnalyticsBuilder,
} from '@client/analytics/utils/builders/CheckoutConfirmationPageAnalyticsBuilder';
import { selectCheckout } from '@client/checkout/redux/checkout/redux';
import { selectPlaceOrder } from '@client/checkout/redux/order/redux';
import { selectPlaceCollectedOrder } from '@client/collectedOrder/redux/reducer';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { all, AllEffect, ForkEffect, put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects';
import { CheckoutDataType } from 'b2b-common/core/checkout/Checkout.types';


function* analyzeCheckoutStepViewSaga(action: ReturnType<typeof analyzeCheckoutStepView>): Generator<
  PutEffect | SelectEffect, void, RemoteRecord<CheckoutDataType>
> {
  const checkoutState: RemoteRecord<CheckoutDataType> = yield select(selectCheckout);
  const checkoutBuilder = new CheckoutBaseAnalyticsBuilder();
  const products = checkoutState?.data?.products || [];
  const shipping = checkoutState?.data?.shipping;
  const payment = checkoutState?.data?.payment;

  checkoutBuilder
    .setShipping({
      type: CHECKOUT_TYPE.login,
      selected: shipping.selected,
    })
    .setProducts(products)
    .setStep(action.payload)
    .setPaymentType(payment.selected)
    .setInteraction(TEALIUM_INTERACTION_ID.checkout)
    .setPageType(TEALIUM_PAGE_TYPE.checkout);

  yield put(analyzeView(
    checkoutBuilder.build(),
  ));
}

function* analyzeOrderConfirmationPageSaga(action: ReturnType<typeof analyzeOrderConfirmationPage>):
  Generator<PutEffect | SelectEffect, void, RemoteRecord<CheckoutDataType>> {
  const placeOrderState: RemoteRecord<CheckoutDataType> = action.payload
    ? yield select(selectPlaceCollectedOrder)
    : yield select(selectPlaceOrder);

  if (!placeOrderState) {
    return;
  }

  const { data } = placeOrderState;

  const checkoutBuilder = new CheckoutConfirmationPageAnalyticsBuilder(true);

  checkoutBuilder
    .setOrder({
      id: data?.token,
      revenue: data?.prices?.finalWithoutDiscounts,
      shipping: data?.prices?.shippingCosts,
      smallQuantitySurcharge: data?.prices?.smallQuantitiesCost,
      payment: {
        cost: data?.prices?.paymentCosts,
        type: data?.payment?.selected || '',
      },
    })
    .setPageType(TEALIUM_PAGE_TYPE.orderConfirmation)
    .setInteraction(TEALIUM_INTERACTION_ID.orderConfirmation)
    .setStep(TEALIUM_PROD_ACTION.purchase)
    .setShipping({
      type: CHECKOUT_TYPE.login,
      selected: data?.shipping?.selected,
    })
    .setProducts(data?.products)
    .setPaymentType(data?.payment?.selected);

  yield put(analyzeView(checkoutBuilder.build()));
}

export function* checkoutAnalyticsSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(analyzeCheckoutStepView.type, analyzeCheckoutStepViewSaga),
    takeLatest(analyzeOrderConfirmationPage.type, analyzeOrderConfirmationPageSaga),
  ]);
}
