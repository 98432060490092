import { requestApiSaga, retryRequestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Order } from '@client/myAccount/orders/api';
import {
  cancelOrder,
  cancelOrderFulfilled,
  cancelOrderItems,
  cancelOrderItemsFulfilled,
  cancelOrderItemsRejected,
  cancelOrderRejected,
  fetchOrder,
  fetchOrderFulfilled,
  fetchOrderRejected,
  splitOrder,
  splitOrderFulfilled,
  splitOrderRejected,
} from '@client/myAccount/orders/redux/order/redux';
import { fetchOrders } from '@client/myAccount/orders/redux/orders/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { DetailedOrderType } from 'b2b-common/core/account/api/orders';

const PAGE_SIZE = 25;
const START_ITEM = 1;

function* fetchOrderSaga(action: ReturnType<typeof fetchOrder>):
  Generator<CallEffect | PutEffect, void, AxiosResponse<DetailedOrderType>> {
  const orderId = action.payload;

  try {
    const response = yield call(retryRequestApiSaga, Order.fetch(orderId));

    yield put(fetchOrderFulfilled({ orderId, data: response.data }));
  } catch (error) {
    yield put(fetchOrderRejected({ orderId, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: { orderId: action.payload } }));
  }
}

function* cancelOrderSaga(action: ReturnType<typeof cancelOrder>):
  Generator<CallEffect | PutEffect | AllEffect<PutEffect>, void> {
  const orderId = action.payload;

  try {
    yield call(requestApiSaga, Order.cancel(orderId));
    yield all([
      put(cancelOrderFulfilled()),
      put(fetchOrders({ pageSize: PAGE_SIZE, startItem: START_ITEM })),
    ]);
  } catch (error) {
    yield put(cancelOrderRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: { orderId: action.payload } }));
  }
}

function* cancelOrderItemsSaga(action: ReturnType<typeof cancelOrderItems>): Generator<CallEffect | PutEffect, void> {
  const { data, orderId } = action.payload;

  try {
    yield call(requestApiSaga, Order.cancelItems(orderId, data));
    yield put(cancelOrderItemsFulfilled());
  } catch (error) {
    yield put(cancelOrderItemsRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* splitOrderSaga(action: ReturnType<typeof splitOrder>): Generator<CallEffect | PutEffect, void> {
  const orderId = action.payload;

  try {
    yield call(requestApiSaga, Order.split(orderId));
    yield put(splitOrderFulfilled());
  } catch (error) {
    yield put(splitOrderRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: { orderId: action.payload } }));
  }
}

export function* orderSaga(): Generator<AllEffect<ForkEffect>, void > {
  yield all([
    takeLatest(fetchOrder.type, fetchOrderSaga),
    takeLatest(cancelOrderItems.type, cancelOrderItemsSaga),
    takeLatest(cancelOrder.type, cancelOrderSaga),
    takeLatest(splitOrder.type, splitOrderSaga),
  ]);
}
