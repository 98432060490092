import { KeyboardEvent, MouseEvent, ReactElement, useEffect, useState } from 'react';
import { useProductsAnalytics } from '@client/analytics/context/products/ProductsAnalyticsContext';
import { useSearchAnalytics } from '@client/analytics/context/search/SearchAnalyticsContext';
import { Autosuggest } from '@client/app/components/App/Header/Autosuggest/Autosuggest';
import { useSuggestionsData } from '@client/catalog/hooks';
import { FILTERS } from '@client/catalog/utils/facets';
import { useDeviceType } from '@client/common/hooks';
import { isMobile } from '@client/common/utils/env';
import { ROUTES } from '@client/routes/components/Router/routes';
import { useRouterPush, useUrlCreator } from '@client/routes/hooks';
import { openLink } from '@client/routes/utils/url';
import { FacetType, ProductType } from 'b2b-common/core/catalog/Suggestions.types';
import { Props } from './AutoSuggestContainer.types';

export enum SUGGESTION_TYPE {
  searchField = 'searchField',
  category = 'category',
  search = 'search',
  product = 'product',
}

type SectionsLengthType = {
  [SUGGESTION_TYPE.searchField]: number,
  [SUGGESTION_TYPE.category]: number,
  [SUGGESTION_TYPE.search]: number,
  [SUGGESTION_TYPE.product]: number,
};

export const AutoSuggestContainer = ({ onCloseAutosuggest, setOnKeyDown, ...originalProps }: Props): ReactElement => {
  const push = useRouterPush();
  const deviceType = useDeviceType();
  const urlCreator = useUrlCreator();
  const suggestions = useSuggestionsData();
  const { analyzeSuggestedSimilarTermClick, analyzeSuggestedCategoryClick } = useSearchAnalytics();
  const { analyzeProductClick, analyzeProductAddToCart } = useProductsAnalytics();

  const [activeItem, setActiveItem] = useState({
    type: SUGGESTION_TYPE.searchField,
    index: 0,
  });

  const { data } = suggestions;
  const term = data?.term || '';

  const sectionsLength: SectionsLengthType = {
    [SUGGESTION_TYPE.searchField]: 1,
    [SUGGESTION_TYPE.category]: data && Array.isArray(data.facets.facet) ? data.facets.facet.length : 0,
    [SUGGESTION_TYPE.search]: data && Array.isArray(data.suggestions) ? data.suggestions.length : 0,
    [SUGGESTION_TYPE.product]: data && Array.isArray(data.products) ? data.products.length : 0,
  };

  const sectionsOrder: SUGGESTION_TYPE[] = [
    SUGGESTION_TYPE.searchField,
    SUGGESTION_TYPE.category,
    SUGGESTION_TYPE.search,
    SUGGESTION_TYPE.product,
  ];

  const notEmptySections = sectionsOrder.filter(
    (section: SUGGESTION_TYPE) => sectionsLength[section] > 0,
  );

  const getNextSection = (section: SUGGESTION_TYPE, sections: SUGGESTION_TYPE[]): SUGGESTION_TYPE => {
    const index = sections.indexOf(section);

    return sections[(index + 1) % sections.length] || SUGGESTION_TYPE.product;
  };

  const getPrevSection = (section: SUGGESTION_TYPE, sections: SUGGESTION_TYPE[]): SUGGESTION_TYPE => {
    const index = sections.indexOf(section);

    return sections[(index - 1 + sections.length) % sections.length] || SUGGESTION_TYPE.searchField;
  };

  useEffect(() => {
    //reset active item when search term changes
    setActiveItem({
      type: SUGGESTION_TYPE.searchField,
      index: 0,
    });
  }, [term]);

  const onArrowUp = () => {
    if (activeItem.index > 0) {
      setActiveItem({
        ...activeItem,
        index: activeItem.index - 1,
      });
    } else {
      const nextType = getPrevSection(activeItem.type, notEmptySections);

      setActiveItem({
        type: nextType,
        index: sectionsLength[nextType] - 1,
      });
    }
  };

  const onArrowDown = () => {
    if (activeItem.index + 1 < sectionsLength[activeItem.type]) {
      setActiveItem({
        ...activeItem,
        index: activeItem.index + 1,
      });
    } else {
      const nextType = getNextSection(activeItem.type, notEmptySections);

      setActiveItem({
        type: nextType,
        index: 0,
      });
    }
  };

  const onArrowLeft = (event: KeyboardEvent<any>) => {
    if (activeItem.type !== SUGGESTION_TYPE.searchField) {
      event.preventDefault();
    }

    if (
      activeItem.type === SUGGESTION_TYPE.product
      && (sectionsLength[SUGGESTION_TYPE.search] > 0
        || sectionsLength[SUGGESTION_TYPE.category] > 0)
    ) {
      setActiveItem({
        type: getNextSection(SUGGESTION_TYPE.searchField, notEmptySections),
        index: 0,
      });
    }
  };

  const onArrowRight = (event: KeyboardEvent<any>) => {
    if (activeItem.type !== SUGGESTION_TYPE.searchField) {
      event.preventDefault();
    }

    if (
      [SUGGESTION_TYPE.category, SUGGESTION_TYPE.search].includes(
        activeItem.type,
      )
      && sectionsLength[SUGGESTION_TYPE.product] > 0
    ) {
      setActiveItem({
        type: SUGGESTION_TYPE.product,
        index: 0,
      });
    }
  };

  const getCategoryUrl = (category: string, query: string): string => {
    const encodedCategory = encodeURIComponent(category);
    const encodedQuery = encodeURIComponent(query);

    return `${urlCreator(
      ROUTES.search,
    )}?${FILTERS.category}=${encodedCategory}&${FILTERS.query}=${encodedQuery}`;
  };

  const getSearchSuggestionUrl = (term: string, restTerm: string): string => {
    const encodedQuery = encodeURIComponent(term + restTerm);

    return `${urlCreator(ROUTES.search)}?${FILTERS.query}=${encodedQuery}`;
  };

  const onEnterClick = (event: KeyboardEvent<any>) => {
    if (activeItem.type !== SUGGESTION_TYPE.searchField) {
      if (!data) {
        return;
      }

      event.preventDefault();
      let url = '';
      const facet = data.facets.facet[activeItem.index];
      const suggestion = data.suggestions[activeItem.index];
      const product = data.products[activeItem.index];

      switch (activeItem.type) {
        case SUGGESTION_TYPE.category:
          if (facet) {
            url = getCategoryUrl(facet.value, facet.term);
          }

          break;

        case SUGGESTION_TYPE.search:
          if (suggestion) {
            url = getSearchSuggestionUrl(
              suggestion.term,
              suggestion.restTerm,
            );
          }

          break;

        case SUGGESTION_TYPE.product:
          if (product) {
            url = urlCreator(ROUTES.product, {
              sku: product.sku,
            });
          }

          break;

        default:
          break;
      }

      if (url) {
        openLink(url, push);
      }

      onCloseAutosuggest();
    }
  };

  const onKeyDown = (event: KeyboardEvent<any>) => {
    if (event.key === 'Enter') {
      onEnterClick(event);
    } else if (event.key === 'ArrowUp' || event.key === 'Up') {
      event.preventDefault();
      onArrowUp();
    } else if (event.key === 'ArrowDown' || event.key === 'Down') {
      event.preventDefault();
      onArrowDown();
    } else if (event.key === 'ArrowLeft' || event.key === 'Left') {
      !isMobile(deviceType) && onArrowLeft(event);
    } else if (event.key === 'ArrowRight' || event.key === 'Right') {
      !isMobile(deviceType) && onArrowRight(event);
    }
  };

  useEffect(() => {
    setOnKeyDown(() => onKeyDown);
  }, [setOnKeyDown]);

  const handleMouseEnter = (type: SUGGESTION_TYPE, index: number): OnMouseEnterType<any> => () => {
    setActiveItem({
      type,
      index,
    });
  };

  const handleAutosuggestContentClick = (e: MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
  };

  const handleSearchProductClick = (product: ProductType, index: number): OnAnchorClickType => (
    e: MouseEvent<HTMLAnchorElement | HTMLTableRowElement>,
  ): void => {
    const url = getProductUrl(product.sku);
    analyzeProductClick({ sku: product.sku, listPosition: index + 1 });
    handleLinkClick(url, e);
  };

  const handleProductAddToCart = (sku: string, index: number): void => {
    analyzeProductAddToCart({ sku, listPosition: index + 1 });
  };

  const handleSearchCategoryClick = (facet: FacetType): OnAnchorClickType => (
    e: MouseEvent<HTMLAnchorElement | HTMLTableRowElement>,
  ): void => {
    const url = getCategoryUrl(facet.value, facet.term);
    analyzeSuggestedCategoryClick(facet.name);
    handleLinkClick(url, e);
  };

  const handleSearchSuggestionClick = (url: string): OnAnchorClickType => (
    e: MouseEvent<HTMLAnchorElement | HTMLTableRowElement>,
  ): void => {
    analyzeSuggestedSimilarTermClick();
    handleLinkClick(url, e);
  };

  const handleLinkClick = (url: string, e: MouseEvent<HTMLAnchorElement | HTMLTableRowElement>) => {
    e.preventDefault();
    openLink(url, push, e);
    onCloseAutosuggest();
  };

  const getProductUrl = (sku: string) => urlCreator(ROUTES.product, { sku });

  return (
    <Autosuggest
      {...originalProps}
      suggestions={suggestions}
      onAutosuggestContentClick={handleAutosuggestContentClick}
      onCategoryClick={handleSearchCategoryClick}
      getCategoryUrl={getCategoryUrl}
      onSearchSuggestionClick={handleSearchSuggestionClick}
      getSearchSuggestionUrl={getSearchSuggestionUrl}
      onProductClick={handleSearchProductClick}
      onProductAddToCart={handleProductAddToCart}
      getProductUrl={getProductUrl}
      onMouseEnter={handleMouseEnter}
      activeItem={activeItem}
      onCloseAutosuggest={onCloseAutosuggest}
    />
  );
};
