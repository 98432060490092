import { removeCart } from '@client/cart/redux/cart/redux';
import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { PUNCHOUT_TYPE } from '@client/punchout/common/types';
import { Oci } from '@client/punchout/oci/api/Oci';
import { PLACE_OCI_ORDER } from '@client/punchout/oci/redux/actionTypes';
import { PlaceOciOrderAction } from '@client/punchout/oci/redux/types';
import { ROUTES } from '@client/routes/components/Router/routes';
import { pushToRoute } from '@client/routes/redux/utils';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { OciCartResponseType } from 'b2b-common/core/punchout/oci/oci.types';

function* placeOciOrderSaga(
  action: PlaceOciOrderAction,
): Generator<CallEffect | PutEffect | ReturnType<typeof pushToRoute>,
  void,
  AxiosResponse<OciCartResponseType>> {
  try {
    const response = yield call(
      requestApiSaga,
      Oci.fetchCart(),
    );
    const {
      cart,
      hook,
      target,
    } = Oci.mapCartResponse(response);
    const form = Object.assign(document.createElement('form'), {
      action: hook,
      method: 'post',
      target: target || '_top',
    });

    cart.forEach((item, itemIndex)=> {
      Object.entries(item).forEach(([key, value]) => {
        form.appendChild(
          Object.assign(document.createElement('input'), {
            type: 'hidden',
            name: `${key}[${itemIndex + 1}]`,
            value,
          }),
        );
      });
    });

    document.body && document.body.appendChild(form);
    form.submit();
    document.body && document.body.removeChild(form);

    yield put(removeCart());

    yield pushToRoute(ROUTES.cart, {
      punchoutType: PUNCHOUT_TYPE.oci,
    });
  } catch (error) {
    yield put(reportError({ message: action.type, error }));
    yield pushToRoute(ROUTES.homepage);
  }
}

export function* ociSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(PLACE_OCI_ORDER, placeOciOrderSaga),
  ]);
}
