import { analyzeBarcodeScan } from '@client/analytics/redux';
import { analyzeEvent } from '@client/analytics/redux/tealium/actions';
import { TEALIUM_INTERACTION_ID, TEALIUM_PROD_ACTION } from '@client/analytics/types/types';
import {
  BarcodeAnalyticsComponentPropertyBuilder,
} from '@client/analytics/utils/builders/BarcodeAnalyticsComponentPropertyBuilder';
import {
  BarcodeAnalyticsProductPropertyBuilder,
} from '@client/analytics/utils/builders/BarcodeAnalyticsProductPropertyBuilder';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { fetchWishlistFulfilled, fetchWishlistRejected } from '@client/myAccount/wishlists/redux/wishlist/redux';
import { selectProductTileBySku } from '@client/product/redux/products/tile/redux';
import {
  all,
  AllEffect,
  call,
  CallEffect,
  ForkEffect,
  put,
  race,
  RaceEffect,
  select,
  take,
  TakeEffect,
  takeEvery,
} from 'redux-saga/effects';
import { WishlistType } from 'b2b-common/core/account/api/wishlists';

interface BarcodeAnalyticsEventData {
  barcodeAction: TEALIUM_PROD_ACTION,
  barcode?: string,
  sku?: string | string[],
}

function* analyzeBarcodeEvent(
  {
    barcodeAction,
    barcode,
    sku,
  }: BarcodeAnalyticsEventData,
): Generator<any, any, any> {
  let productTile = null;
  if (sku) {
    productTile = yield select(selectProductTileBySku, sku as string);
  }
  const componentBarcodeMapper = new BarcodeAnalyticsComponentPropertyBuilder()
    .setBarcode(barcode)
    .setAction(barcodeAction);
  const prodBarcodeMapper = new BarcodeAnalyticsProductPropertyBuilder()
    .setBarcodeAction(barcodeAction)
    .setProductTile(productTile);

  yield put(analyzeEvent(
    {
      interaction: TEALIUM_INTERACTION_ID.barcodeScan,
      ...componentBarcodeMapper.build(),
      ...(productTile)
      && {
        ...prodBarcodeMapper.build(),
      },
    },
  ));
}

function* analyzeBarcodeScanUsageSaga(action: ReturnType<typeof analyzeBarcodeScan>): Generator<any, any, any> {
  const {
    barcodeAction,
    barcode,
    sku,
  } = action.payload;

  yield call(analyzeBarcodeEvent, {
    barcodeAction: barcodeAction,
    barcode: barcode,
    sku: sku,
  });
}

function* analyzeScannedProductInteraction(
  action: TEALIUM_PROD_ACTION,
  sku: string,
  barcode?: string,
): Generator<CallEffect, void> {
  yield call(
    analyzeBarcodeEvent,
    {
      barcodeAction: action,
      sku,
      barcode,
    },
  );
}

export function* analyzeScannedProductClick(sku: string, barcode: string): Generator<CallEffect | void> {
  yield call(
    analyzeScannedProductInteraction,
    TEALIUM_PROD_ACTION.productListClick,
    sku,
    barcode,
  );
}

export function* analyzeScannedProductAddToCart(sku: string): Generator<CallEffect, void> {
  yield call(
    analyzeScannedProductInteraction,
    TEALIUM_PROD_ACTION.addToCartFromList,
    sku,
  );
}

export function* analyzeScannedProductAddToWishlist(sku: string):
  Generator<CallEffect | RaceEffect<TakeEffect>,
  void,
  RemoteRecord<WishlistType> & {
    fulfilled: ReturnType<typeof fetchWishlistFulfilled>,
    rejected: ReturnType<typeof fetchWishlistRejected>
  }
  > {
  const { fulfilled, rejected } = yield race({
    fulfilled: take(fetchWishlistFulfilled.type),
    rejected: take(fetchWishlistRejected.type),
  });

  if (fulfilled) {
    yield call(
      analyzeScannedProductInteraction,
      TEALIUM_PROD_ACTION.addToWishlist,
      sku,
    );
  }

  if (rejected) {
    return;
  }
}

export function* barcodeAnalyticsSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeEvery(analyzeBarcodeScan.type, analyzeBarcodeScanUsageSaga),
  ]);
}
