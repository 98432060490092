import { ReactElement, useEffect, useRef, useState } from 'react';
import { Autosuggest } from '@client/app/components/App/Header/Autosuggest';
import { FlyoutBrands } from '@client/app/components/App/Header/FlyoutBrands';
import { FlyoutCatalog } from '@client/app/components/App/Header/FlyoutCatalog';
import { SearchField } from '@client/app/components/App/Header/SearchField';
import { TopBar } from '@client/app/components/App/Header/TopBar';
import { UserArea } from '@client/app/components/App/Header/UserArea';
import { Logo } from '@client/common/components/Logo';
import { PrimaryLayout } from '@lib/components/PrimaryLayout';
import classNames from 'classnames';
import { Props } from './';
import '@client/app/components/App/Header/Desktop/Desktop.scss';

export const Desktop = ({
  autosuggestRefCallback,
  className,
  isAutosuggestOpened,
  onCloseAutosuggest,
  onKeyDown,
  onOpenAutosuggest,
  setOnKeyDown,
  withBrands,
  withFullBrands,
}: Props): ReactElement => {
  const rootClassName = classNames('Desktop', className);
  const searchRef = useRef<HTMLInputElement>(null);
  const [styles, setStyles] = useState<React.CSSProperties>({});

  const calculateStyles = () => {
    const width = searchRef.current?.getBoundingClientRect().width;
    setStyles({ marginLeft: `calc(100% - ${width}px)` });
  };

  useEffect(() => {
    if (searchRef.current) {
      calculateStyles();

      window.onresize = () => {
        calculateStyles();
      };

      return () => {
        window.onresize = null;
      };
    }
  }, []);

  return (
    <>
      <TopBar />

      <div className={rootClassName}>
        <PrimaryLayout>
          <div className="cds-Row cds-Row--CenterVertical">
            <Logo />
            <UserArea />
          </div>

          <div
            className='cds-Row cds-Row--Stretch'
            data-testid='flyoutAndSearchRow'
          >
            <FlyoutCatalog className="Desktop-Flyout" />

            {(withBrands || withFullBrands) && (
              <FlyoutBrands
                withFullBrands={withFullBrands}
                className="Desktop-Flyout"
              />
            )}

            <SearchField
              searchRef={searchRef}
              onCloseAutosuggest={onCloseAutosuggest}
              onOpenAutosuggest={onOpenAutosuggest}
              onInputKeyDown={onKeyDown}
              closeButtonDataAttributes={{
                'data-testid': 'headerSearchFieldCloseButton',
              }}
              submitButtonDataAttributes={{
                'data-testid': 'headerSearchFieldSubmitButton',
              }}
            />
          </div>
        </PrimaryLayout>

        <Autosuggest
          autosuggestRefCallback={autosuggestRefCallback}
          isAutosuggestOpened={isAutosuggestOpened}
          onCloseAutosuggest={onCloseAutosuggest}
          setOnKeyDown={setOnKeyDown}
          style={styles}
        />
      </div>
    </>
  );
};
