import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { stringify } from '@client/common/utils/queryParams';
import { DEFAULT_LIST_PAGE_SIZE } from '@client/myAccount/costCenters/consts';

export class CostCenterOrders {
  public static get(
    orderId: string,
  ): RequestConfigType {
    return RequestConfig.get(
      `/account/cost-centers/orders/${orderId}`,
      {
        withLocalization: false,
      },
    );
  }

  public static rejectOrder(
    orderId: string,
    remarks?: string,
  ): RequestConfigType {
    return RequestConfig.put(
      `/account/cost-centers/orders/${orderId}/reject`,
      {
        remarks,
      },
    );
  }

  public static approveOrder(
    orderId: string,
    remarks?: string,
  ): RequestConfigType {
    return RequestConfig.put(
      `/account/cost-centers/orders/${orderId}/approve`,
      {
        remarks,
      },
    );
  }

  public static sendToSales(
    orderId: string,
    remarks?: string,
  ): RequestConfigType {
    return RequestConfig.put(
      `/account/cost-centers/orders/${orderId}/send-to-sales`,
      {
        remarks,
      },
    );
  }

  public static addToCart(orderId: string, copyArticleReferences: boolean): RequestConfigType {
    return RequestConfig.post(`/account/cost-centers/orders/${orderId}/cart`, { copyArticleReferences });
  }

  public static getOrderVerification(
    orderId: string,
  ): RequestConfigType {
    return RequestConfig.get(
      `/account/cost-centers/orders/${orderId}/verify`,
      {
        withLocalization: false,
      },
    );
  }

  public static getOrders(
    uuid: string,
    status: string,
    startItem = 0,
    pageSize = DEFAULT_LIST_PAGE_SIZE,
  ): RequestConfigType {
    return RequestConfig.get(
      `/account/cost-centers/orders?${stringify(
        { pageSize, startItem, uuid, status },
        { skipNulls: true },
      )}`,
      {
        withLocalization: false,
      },
    );
  }
}
