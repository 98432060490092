import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { RequestCancelOrderItemsType } from 'b2b-common/core/account/api/orders/Order.types';

export class Order {
  public static fetch(orderId: string): RequestConfigType {
    return RequestConfig.get(`/account/orders/${orderId}`, { withLocalization: false });
  }

  public static cancel(orderId: string): RequestConfigType {
    return RequestConfig.post(`/account/orders/${orderId}/cancel`, {}, { withLocalization: false });
  }

  public static cancelItems(orderId: string, data: RequestCancelOrderItemsType): RequestConfigType {
    return RequestConfig.post(`/account/orders/${orderId}/cancel-items`, data, { withLocalization: false });
  }

  public static split(orderId: string): RequestConfigType {
    return RequestConfig.post(`/account/orders/${orderId}/partial-shipment`, {}, { withLocalization: false });
  }
}
