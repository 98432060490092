import { retryRequestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Credits } from '@client/myAccount/credits/api/Credits';
import { fetchCredits, fetchCreditsFulfilled, fetchCreditsRejected } from '@client/myAccount/credits/redux/redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';

function* fetchCreditsSaga(action: ReturnType<typeof fetchCredits>): Generator<any, any, any> {
  const { pageSize, startItem } = action.payload;

  try {
    const response = yield call(retryRequestApiSaga, Credits.fetch(pageSize, startItem));
    yield put(fetchCreditsFulfilled(response.data));
  } catch (error) {
    yield put(fetchCreditsRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* creditsSaga(): Generator<any, any, any> {
  yield all([takeLatest(fetchCredits.type, fetchCreditsSaga)]);
}
