import { ReactElement, ReactNode } from 'react';
import { SCREENSHOT_TOOL_DATA_ATTR_MPT_ID } from '@client/common/screenshotTool';
import { BREAKPOINTS } from '@client/common/utils/breakpointsLayout';
import { createKey } from '@client/common/utils/key/key';
import { Slider, SLIDER_STYLE_TYPE } from '@lib/components/Slider';
import classNames from 'classnames';
import { useLinkHandler } from '../useLinkHandler';
import { Props } from '../';
import './LogoSlider.scss';

const SLIDER_PARAMS = {
  xs: {
    slidesPerGroup: 4,
    slidesPerView: 4,
    spaceBetween: 32,
  },
  sm: {
    slidesPerGroup: 6,
    slidesPerView: 6,
    spaceBetween: 32,
  },
  md: {
    slidesPerGroup: 8,
    slidesPerView: 8,
    spaceBetween: 32,
  },
  lg: {
    slidesPerGroup: 10,
    slidesPerView: 10,
    spaceBetween: 32,
  },
  default: {
    slidesPerGroup: 4,
    slidesPerView: 4,
    spaceBetween: 16,
  },
};

export const LogoSlider = ({ className, componentData }: Props): ReactElement | null => {
  const handleClick = useLinkHandler();

  if (!componentData.group || !Array.isArray(componentData.group)) {
    return null;
  }

  const logos = componentData.group.reduce(
    (
      result: any[],
      logo: Record<string, any>,
      index: number,
    ): ReactNode[] => {
      const dataAttributes = { ...logo.mpt_id && { [SCREENSHOT_TOOL_DATA_ATTR_MPT_ID]: logo.mpt_id } };

      if (logo.logo_image) {
        result.push(
          <a
            {...dataAttributes}
            className='LogoSliderModule-Logo'
            href={logo.url}
            key={createKey(index, componentData.entryId)}
            onClick={(e) => handleClick(logo.url, e)}
          >
            <img
              alt={logo.brand_name}
              className='LogoSliderModule-LogoImage'
              src={logo.logo_image.url}
            />
          </a>,
        );
      }

      return result;
    },
    [],
  );

  if (logos.length < 1) {
    return null;
  }

  return (
    <div className={classNames(className)}>
      {componentData.title && (
        <h2 className='cds-cms-SectionTitle'>{componentData.title}</h2>
      )}
      <div className='cds-cms-GridRow'>
        <div className='cds-cms-ModuleContainer'>
          <div className='LogoSliderModule'>

            {logos.length === 1 && logos}

            {logos.length > 1 && (
              <Slider
                {...SLIDER_PARAMS.default}
                breakpoints={{
                  [BREAKPOINTS.xs]: SLIDER_PARAMS.xs,
                  [BREAKPOINTS.sm]: SLIDER_PARAMS.sm,
                  [BREAKPOINTS.md]: SLIDER_PARAMS.md,
                  [BREAKPOINTS.lg]: SLIDER_PARAMS.lg,
                }}
                isAutoplay={true}
                isLoop={false}
                styleType={SLIDER_STYLE_TYPE.withMargin}
              >
                {logos}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
