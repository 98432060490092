import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CostCenterSearchListItemType } from 'b2b-common/core/account/api/costCenters/CostCenters.types';
import { CartCostCenterDetails } from 'b2b-common/core/cart/api/CartTypes';

const NAME = 'costCenter';

export const cartCostCenterSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {
    list: RemoteRecordData.getDefault(),
    details: RemoteRecordData.getDefault(),
  },
  reducers: {
    fetchCartCostCenters: (state) => {
      state.list = RemoteRecordData.init();
      return state;
    },
    fetchCartCostCentersFulfilled: (state, action: PayloadAction<CostCenterSearchListItemType[]>) => {
      state.list = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchCartCostCentersRejected: (state, action: PayloadAction<ErrorType>) => {
      state.list = RemoteRecordData.reject(action.payload);
      return state;
    },
    fetchCartCostCenterDetails: (state, action: PayloadAction<{ costCenterId: string, withAddresses?: boolean }>) => {
      state.details = RemoteRecordData.init();
      return state;
    },
    fetchCartCostCenterDetailsFulfilled: (state, action: PayloadAction<CartCostCenterDetails>) => {
      state.details = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchCartCostCenterDetailsRejected: (state, action: PayloadAction<ErrorType>) => {
      state.details = RemoteRecordData.reject(action.payload);
      return state;
    },
  },
});

export const {
  fetchCartCostCenters,
  fetchCartCostCentersFulfilled,
  fetchCartCostCentersRejected,
  fetchCartCostCenterDetails,
  fetchCartCostCenterDetailsFulfilled,
  fetchCartCostCenterDetailsRejected,
} = cartCostCenterSlice.actions;

export const selectCartCostCenter = createAction<string>('selectCartCostCenter');

const getState = (state: AppState) => state.cart.costCenter;

export const selectCartCostCenters = createSelector(
  getState,
  state => new RemoteRecord<CostCenterSearchListItemType[]>(state.list),
);

export const selectCartCostCenterDetails = createSelector(
  getState,
  state => new RemoteRecord<CartCostCenterDetails>(state.details),
);
