import { RouteType } from './types';

export const DEFAULT_ROUTE: RouteType = {
  paths: {},
  allow: () => true,
  allowTranslation: true,
};

export const DEFAULT_ROUTE_PARAMS: Record<string, any> = {
  step: 1,
  page: 1,
};

export const AUTHENTICATED_ROUTE: Partial<RouteType> = {
  ...DEFAULT_ROUTE,
  allow: ({ isLoggedIn }) => isLoggedIn,
};

export const AUTHENTICATED_UNBLOCKED_ROUTE: Partial<RouteType> = {
  ...DEFAULT_ROUTE,
  allow: ({ isLoggedIn, isBlocked }) => isLoggedIn && !isBlocked,
};

export const UNTRANSLATED_ROUTE: Partial<RouteType> = {
  ...DEFAULT_ROUTE,
  allowTranslation: false,
};
