import { ReactElement } from 'react';
import { withField } from '@client/common/components/Form/withField';
import { Selectable } from 'b2b-components/theme/components/Form/Selectable';
import { Props } from './FormFieldSelectable.types';

export const FormFieldSelectable = withField((props: Props): ReactElement => {
  const { innerComponentProps, field, form, ...otherProps } = props;

  return (
    <Selectable {...innerComponentProps} {...field} {...otherProps} />
  );
});
