import { ReactElement } from 'react';
import { createKey } from '@client/common/utils/key/key';
import { GUURU_CONTAINER, GuuruSnippet } from '@client/content/components/GuuruSnippet';
import { TextMedia as TextMediaComponent } from '@lib/components/CMS/TextMedia';
import classNames from 'classnames';
import { Props } from '../';

export const RichText = ({ className, componentData }: Props): ReactElement | null => {
  const data = componentData.rich_text_editor.componentData || [componentData];
  const hasGuuruContainer = data.some((content: string) => content.includes(GUURU_CONTAINER));
  const components = data.map((config: string, index: number) => (
    <TextMediaComponent
      key={createKey('rich-text', index)}
      htmlText={config}
    />
  ));

  return (
    <div className={classNames('cds-cms-GridRow', className)}>
      {hasGuuruContainer && <GuuruSnippet />}
      {components}
    </div>
  );
};
