import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddressesType } from 'b2b-common/core/account/api/Address.types';

const NAME = 'addressesSearch';

export const slice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {},
  reducers: {
    fetchAddressesSearch: (state, action: PayloadAction<{ searchQuery: string }>) => {
      state = RemoteRecordData.init();
      return state;
    },
    fetchAddressesSearchFulfilled: (state, action: PayloadAction<AddressesType>) => {
      state = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchAddressesSearchRejected: (state, action: PayloadAction<ErrorType>) => {
      state = RemoteRecordData.reject(action.payload);
      return state;
    },
    clearAddressesSearch: (state) => {
      state = RemoteRecordData.getDefault();
      return state;
    },
  },
});

export const {
  fetchAddressesSearch,
  fetchAddressesSearchFulfilled,
  fetchAddressesSearchRejected,
  clearAddressesSearch,
} = slice.actions;

const getState = (state: AppState) => state.myAccount.addresses.addressesSearch;

export const selectAddressesSearch = createSelector(
  getState,
  (state) => new RemoteRecord<AddressesType>(state),
);
