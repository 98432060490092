import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData, RemoteRecordDataType } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserStatsType } from 'b2b-common/core/user-stats';

const initialData: UserStatsType = {
  totalOrdersToReview: 0,
};

const initialState: RemoteRecordDataType<UserStatsType> = Object.assign(
  RemoteRecordData.getDefault(),
  {
    data: initialData,
  },
);

const NAME = 'userStats';

export const userStatsSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState,
  reducers: {
    fetchUserStats: (state) => {
      state = RemoteRecordData.init();
      return state;
    },
    fetchUserStatsFulfilled: (state, action: PayloadAction<UserStatsType>) => {
      state = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchUserStatsRejected: (state, action: PayloadAction<ErrorType>) => {
      state = RemoteRecordData.reject(action.payload);
      return state;
    },
    clearUserStats: (state) => {
      state = RemoteRecordData.fulfill(initialData);
      return state;
    },
  },
});

export const {
  fetchUserStats,
  fetchUserStatsFulfilled,
  fetchUserStatsRejected,
  clearUserStats,
} = userStatsSlice.actions;

const getState = (state: AppState) => state.userStats;

export const selectUserStats = createSelector(
  getState,
  state => new RemoteRecord<UserStatsType>(state),
);

export const selectUserStatsData = createSelector(
  selectUserStats,
  record => {
    if (record.data) {
      return record.data;
    }

    return {
      totalOrdersToReview: 0,
    };
  },
);
