import { analyzeContentForm } from '@client/analytics/redux/content/actions';
import { analyzeEvent } from '@client/analytics/redux/tealium/actions';
import { ContentAnalyticsBuilder } from '@client/analytics/utils/builders/ContentAnalyticsBuilder';
import { submitFormFulfilled, submitFormRejected } from '@client/content/redux/forms/redux';
import {
  all,
  AllEffect,
  ForkEffect,
  put,
  PutEffect,
  race,
  RaceEffect,
  take,
  TakeEffect,
  takeLatest,
} from 'redux-saga/effects';

function* analyzeContentFormSaga(action: ReturnType<typeof analyzeContentForm>): Generator<
  PutEffect | RaceEffect<TakeEffect>,
  void,
  any
> {
  const { formId, formName } = action.payload;

  const builder = new ContentAnalyticsBuilder();
  builder
    .setAttribute1(formId)
    .setAttribute2(formName);

  const { fulfilled, rejected } = yield race({
    fulfilled: take(submitFormFulfilled.type),
    rejected: take(submitFormRejected.type),
  });

  if (rejected) {
    return;
  }

  if (fulfilled) {
    yield put(analyzeEvent({
      ...builder.build(),
    }));
  }
}


export const contentAnalyticsSaga = function* (): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(analyzeContentForm.type, analyzeContentFormSaga),
  ]);
};
