

import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CostCenterAddress,
  CostCenterAddresses,
  CreateCostCenterAddressRequestBodyType,
  UpdateCostCenterAddressRequestBodyType,
} from 'b2b-common/core/account/api/costCenters/CostCenterAddresses.types';

const NAME = 'address';

export const addressSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {
    address: {},
    addresses: RemoteRecordData.getDefault(),
  },
  reducers: {
    fetchCostCenterAddress: (state, action: PayloadAction<{
      costCenterId: string;
      addressId: string;
    }>) => {
      state.address[action.payload.addressId] = RemoteRecordData.init();
      return state;
    },
    fetchCostCenterAddressFulfilled: (state, action: PayloadAction<{
      addressId: string;
      data: CostCenterAddress;
    }>) => {
      state.address[action.payload.addressId] = RemoteRecordData.fulfill(
        action.payload.data,
      );
      return state;
    },
    fetchCostCenterAddressRejected: (state, action: PayloadAction<{
      addressId: string;
      error: ErrorType;
    }>) => {
      state.address[action.payload.addressId] = RemoteRecordData.reject(
        action.payload.error,
      );
      return state;
    },
    fetchCostCenterAddresses: (state, action: PayloadAction<{
      costCenterId: string,
      pageSize: number;
      startItem: number;
    }>) => {
      state.addresses = RemoteRecordData.init();
      return state;
    },
    fetchCostCenterAddressesFulfilled: (state, action: PayloadAction<CostCenterAddresses>) => {
      state.addresses = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchCostCenterAddressesRejected: (state, action: PayloadAction<ErrorType>) => {
      state.addresses = RemoteRecordData.reject(action.payload);
      return state;
    },
  },
});

export const {
  fetchCostCenterAddress,
  fetchCostCenterAddressFulfilled,
  fetchCostCenterAddressRejected,
  fetchCostCenterAddresses,
  fetchCostCenterAddressesFulfilled,
  fetchCostCenterAddressesRejected,
} = addressSlice.actions;

export const editCostCenterAddress = createAction<{
  costCenterId: string;
  addressData: UpdateCostCenterAddressRequestBodyType;
}>('editCostCenterAddress');

export const addCostCenterAddress = createAction<{
  costCenterId: string;
  addressData: CreateCostCenterAddressRequestBodyType;
}>('addCostCenterAddress');

export const removeCostCenterAddress = createAction<{
  costCenterId: string;
  addressId: string;
}>('removeCostCenterAddress');

export const setDefaultCostCenterAddress = createAction<{
  costCenterId: string;
  addressId: string;
}>('setDefaultCostCenterAddress');

const getState = (state: AppState) => state.myAccount.costCenter.address;

export const selectCostCenterAddress = createSelector(
  [getState, (state, id) => id],
  (state, id) => new RemoteRecord<CostCenterAddress>(state.address[id]),
);

export const selectCostCenterAddresses = createSelector(
  getState,
  state => new RemoteRecord<CostCenterAddresses>(state.addresses),
);

