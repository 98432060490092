import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { stringify } from '@client/common/utils/queryParams';
import { SearchConfigType } from './Addresses.types';

export class Addresses {
  public static fetch({ pageSize, startItem, searchQuery }: SearchConfigType): RequestConfigType {
    const searchParams = {
      pageSize,
      startItem,
      query: searchQuery || undefined,
    };
    return RequestConfig.get(`/account/shipping-addresses?${stringify(searchParams)}`,
      { withLocalization: false });
  }
}
