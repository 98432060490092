import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { AxiosResponse } from 'axios';
import { ProductTileResponseType, ProductTilesResponseType } from 'b2b-common/shop/product/api/ProductTile.types';

class ProductTile {
  public static fetch(sku: string): RequestConfigType {
    return RequestConfig.get(`products/${sku}/tile`);
  }

  public static fetchMultiple(skus: string[]): RequestConfigType {
    return RequestConfig.get(`products/multiple-tiles/${skus.join(',')}`);
  }

  public static mapResponseToState(
    response: AxiosResponse<ProductTileResponseType>,
  ): any {
    return response.data;
  }

  public static mapMultipleTilesResponseToState(
    response: AxiosResponse<ProductTilesResponseType>,
  ): any {
    return response.data;
  }
}

export { ProductTile };
