import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreditsType } from 'b2b-common/core/account/api/Credits.types';

const NAME = 'credits';

export const creditsSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: RemoteRecordData.getDefault(),
  reducers: {
    fetchCredits: (state, action: PayloadAction<{ startItem: number, pageSize: number }>) => {
      state = RemoteRecordData.init();
      return state;
    },
    fetchCreditsFulfilled: (state, action: PayloadAction<CreditsType>) => {
      state = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchCreditsRejected: (state, action: PayloadAction<ErrorType>) => {
      state = RemoteRecordData.reject(action.payload);
      return state;
    },
  },
});

export const {
  fetchCredits,
  fetchCreditsFulfilled,
  fetchCreditsRejected,
} = creditsSlice.actions;

const getState = (state: AppState) => state.myAccount.credits;

export const selectCredits = createSelector(
  getState,
  (state) => new RemoteRecord<CreditsType>(state),
);
