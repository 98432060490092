import { slice as contentCollectionsSlice } from '@client/content/redux/contentCollections/redux';
import { slice as contentCollectionsEntriesSlice } from '@client/content/redux/contentCollectionsEntries/redux';
import { slice as contentEntriesSlice } from '@client/content/redux/contentEntries/redux';
import { slice as entriesMapSlice } from '@client/content/redux/entriesMap/redux';
import { slice as featureOnboardingSlice } from '@client/content/redux/featureOnboarding/redux';
import { slice as formsSlice } from '@client/content/redux/forms/redux';
import { slice as homepageSlice } from '@client/content/redux/homepage/redux';
import { slice as infobarsSlice } from '@client/content/redux/infobars/redux';
import { slice as newsroomSlice } from '@client/content/redux/newsroom/redux';
import { combineSlices } from '@reduxjs/toolkit';

export const contentReducer = combineSlices(
  contentCollectionsSlice,
  contentCollectionsEntriesSlice,
  contentEntriesSlice,
  entriesMapSlice,
  formsSlice,
  newsroomSlice,
  homepageSlice,
  infobarsSlice,
  featureOnboardingSlice,
);
