export const removeSubstring = (string: string, cutString: string): string => (
  string.replace(new RegExp(cutString, 'g'), '')
);
export const isString = (element: any):boolean => typeof element === 'string';
export const capitalize = (text:string):string => `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;
export const upperCaseFirst = (text?: string): string => text && `${text.charAt(0).toUpperCase()}${text.substring(1)}`;
export const paramCase = (input: string): string => {
  const splitRegexps = [/([a-z0-9])([A-Z])/g, /([A-Z])([A-Z][a-z])/g];
  const stripRegexp = /[^A-Z0-9]+/gi;
  const delimiter = '-';

  let result = input;
  splitRegexps.forEach(re => {
    result = result.replace(re, '$1\0$2');
  });

  result = result.replace(stripRegexp, '\0');

  return result
    .split('\0')
    .filter(Boolean)
    .map(str => str.toLowerCase())
    .join(delimiter);
};

