import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData, RemoteRecordDataType } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  AddItemsToCartType,
  CartItemType,
  CartSummaryCostCenter,
  CartType,
  UpdateItemReference,
  WishlistWithAddedCartType,
} from 'b2b-common/core/cart/api/CartTypes';

export type AddCartToWishlistAction = PayloadAction<string>;

const initialState = {
  addItems: RemoteRecordData.getDefault(),
  addCartToWishlist: RemoteRecordData.getDefault(),
  cartData: RemoteRecordData.getDefault(),
  updateItemReference: {} as Record<string, RemoteRecordDataType<any>>,
};

const NAME = 'cart';

export const cartSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState,
  reducers: {
    addCartToWishlist: (state, action: PayloadAction<string>) => {
      state.addCartToWishlist = RemoteRecordData.init();
      return state;
    },
    addCartToWishlistFulfilled: (state, action: PayloadAction<{ name: string, data: WishlistWithAddedCartType }>) => {
      state.addCartToWishlist = RemoteRecordData.fulfill(action.payload.data);
      return state;
    },
    addCartToWishlistRejected: (state, action: PayloadAction<{ name: string, error: ErrorType }>) => {
      state.addCartToWishlist = RemoteRecordData.reject(action.payload.error);
      return state;
    },
    clearAddCartToWishlist: (state) => {
      state.addCartToWishlist = RemoteRecordData.getDefault();
      return state;
    },
    clearCart: () => initialState,
    removeCartFulfilled: () => initialState,
    fetchCart: (state) => {
      state.cartData = Object.assign(
        RemoteRecordData.init(),
        { data: state.cartData.data },
      );
      return state;
    },
    fetchCartFulfilled: (state, action: PayloadAction<CartType>) => {
      state.cartData = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    replaceCart: (state, action: PayloadAction<CartType>) => {
      state.cartData = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchCartRejected: (state, action: PayloadAction<ErrorType>) => {
      state.cartData = RemoteRecordData.reject(action.payload);
      return state;
    },
    removeVoucher: (state) => {
      state.cartData = Object.assign(
        RemoteRecordData.init(),
        { data: state.cartData.data },
      );
      return state;
    },
    updateItemQuantity: (state, action: PayloadAction<{quantity: number, sku: string, partAuxiliaryId: string }>) => {
      state.cartData = Object.assign(
        RemoteRecordData.init(),
        { data: state.cartData.data },
      );
      return state;
    },
    delayedUpdateItemReference: (state, action: PayloadAction<{sku: string, reference: string}>) => {
      state.updateItemReference[action.payload.sku] = RemoteRecordData.init();
      return state;
    },
    updateItemReference: (state, action: PayloadAction<{sku: string, reference: string}>) => {
      state.updateItemReference[action.payload.sku] = RemoteRecordData.getDefault();
      return state;
    },
    updateItemReferenceFulfilled: (state, action: PayloadAction<{sku: string}>) => {
      state.updateItemReference[action.payload.sku] = RemoteRecordData.fulfill(null);
      return state;
    },
    updateItemReferenceRejected: (state, action: PayloadAction<{sku: string, error: ErrorType}>) => {
      state.updateItemReference[action.payload.sku] = RemoteRecordData.reject(action.payload.error);
      return state;
    },
    updateVoucher: (state, action: PayloadAction<{ code: string }>) => {
      state.cartData = Object.assign(
        RemoteRecordData.init(),
        { data: state.cartData.data },
      );
      return state;
    },
    addItemsToCart: (state, action: PayloadAction<CartItemType[]>) => {
      state.addItems = RemoteRecordData.init();
      return state;
    },
    addItemsToCartFulfilled: (state, action: PayloadAction<AddItemsToCartType>) => {
      state.addItems = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    addItemsToCartRejected: (state, action: PayloadAction<ErrorType>) => {
      state.addItems = RemoteRecordData.reject(action.payload);
      return state;
    },
    clearAddItemsToCart: (state)=> {
      state.addItems = RemoteRecordData.getDefault();
      return state;
    },
    selectCostCenterFulfilled: (state, action: PayloadAction<CartSummaryCostCenter>) => {
      state.cartData.data.summary.costCenter = action.payload;
      return state;
    },
  },
});

export const {
  addCartToWishlist,
  addCartToWishlistFulfilled,
  addCartToWishlistRejected,
  clearAddCartToWishlist,
  clearCart,
  delayedUpdateItemReference,
  removeCartFulfilled,
  removeVoucher,
  updateItemQuantity,
  updateItemReference,
  updateItemReferenceFulfilled,
  updateItemReferenceRejected,
  updateVoucher,
  fetchCart,
  fetchCartFulfilled,
  replaceCart,
  fetchCartRejected,
  addItemsToCart,
  addItemsToCartFulfilled,
  addItemsToCartRejected,
  clearAddItemsToCart,
  selectCostCenterFulfilled,
} = cartSlice.actions;

export const removeCart = createAction('removeCart');
export const removeCardProduct = createAction<{
  sku: string,
  quantity: number,
  partAuxiliaryId: string
}>('removeCardProduct');
export const updateItemQuantityFulfilled = createAction<{ sku: string, quantity: number }>('updateItemQuantityFulfilled');
export const updateItemQuantityRejected = createAction<{ sku: string, quantity: number }>('updateItemQuantityRejected');

export const ACTIONS_TO_SYNC = [
  clearCart.type,
  fetchCartFulfilled.type,
  fetchCartRejected.type,
  replaceCart.type,
];

const getState = (state: AppState) => state.cart.cart;

export const selectAddCartToWishlistData = createSelector(
  [getState],
  state => new RemoteRecord<WishlistWithAddedCartType>(state.addCartToWishlist),
);

export const selectCart = createSelector(
  [getState],
  state => new RemoteRecord<CartType>(state.cartData),
);

export const selectUpdateItemReferenceBySku = createSelector(
  [getState, (state, sku: string) => sku],
  (state, sku) => new RemoteRecord<UpdateItemReference>(state.updateItemReference[sku]),
);

export const selectCartProductCount = createSelector(
  [selectCart],
  cart => {
    let count = 0;

    if (cart.data) {
      const { products } = cart.data;

      if (Array.isArray(products)) {
        products.forEach((product) => {
          count += product.cart.quantity;
        });
      }
    }

    return count;
  },
);

export const selectCartProductBySku = createSelector(
  [selectCart, (state, sku) => sku],
  (cart, sku) => {
    if (!cart.data) {
      return;
    }

    return cart.data.products.find((product) => product.sku === sku);
  },
);

export const selectCartLicensedProducts = createSelector(
  [selectCart],
  (cart) => {
    if (cart.isLoaded && cart.data && cart.data.products) {
      return cart.data.products.filter(
        (product) => product.settings.requiresLicense,
      );
    }

    return [];
  },
);

export const selectAddItemsToCartData = createSelector(
  [getState],
  (state) => new RemoteRecord<AddItemsToCartType>(state.addItems),
);
