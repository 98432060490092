import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntryMapType } from 'b2b-common/core/content/Content.types';

const NAME = 'entriesMap';

export const slice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: RemoteRecordData.getDefault(),
  reducers: {
    fetchEntriesMap: (state) => {
      state = RemoteRecordData.init();
      return state;
    },
    fetchEntriesMapFulfilled: (state, action: PayloadAction<EntryMapType[]>) => {
      state = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchEntriesMapRejected: (state, action: PayloadAction<ErrorType>) => {
      state = RemoteRecordData.reject(action.payload);
      return state;
    },
  },
});

export const {
  fetchEntriesMap,
  fetchEntriesMapFulfilled,
  fetchEntriesMapRejected,
} = slice.actions;

const getState = (state: AppState) => state.content.entriesMap;

export const selectEntriesMap = createSelector(
  getState,
  (state) => new RemoteRecord<EntryMapType[]>(state),
);
