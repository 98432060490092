import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { ActionCreator } from '@reduxjs/toolkit';

export const useRemoteRecordsAnalytics = (
  remoteRecords: RemoteRecord<any>[],
  actionCreator: ActionCreator<any>,
  isTriggerBlocked?: boolean,
): (
) => void => {
  const dispatch = useDispatch();
  const [isTriggerReleased, releaseTrigger] = useState(false);
  const areRecordsLoading = remoteRecords.some(remoteRecord => remoteRecord?.isLoading);
  const hasAnyRecordFailed = remoteRecords.some(remoteRecord => remoteRecord?.hasFailed);
  const areRecordsLoaded = remoteRecords.every(remoteRecord => remoteRecord?.isLoaded);

  useEffect(
    () => {
      if (areRecordsLoading) {
        releaseTrigger(true);
      }
    },
    [areRecordsLoading],
  );

  useEffect(
    () => {
      if (areRecordsLoaded || hasAnyRecordFailed) {
        releaseTrigger(true);
      }
    },
    [areRecordsLoaded, hasAnyRecordFailed],
  );

  useEffect(
    () => {
      if (isTriggerReleased && (areRecordsLoaded || hasAnyRecordFailed) && !isTriggerBlocked) {
        dispatch(actionCreator());
        releaseTrigger(false);
      }
    },
    [isTriggerReleased, hasAnyRecordFailed, areRecordsLoaded, isTriggerBlocked],
  );

  return () => releaseTrigger(true);
};
