import { ProductListType } from '@client/analytics/hooks';
import { extractSkuNumbersFromString } from '@client/content/utils/productsGroup';
import { ContentEntryType } from 'b2b-common/core/content/Content.types';

export const getProductsSkusFromContentDataProductsGroupV2 = (
  isModule: boolean, data: ContentEntryType,
): Array<ProductListType> => {
  if (isModule) {
    return data?.map((item: any) => ({
      position: item.position,
      skus: extractSkuNumbersFromString(
        item?.source_of_products,
      ),
    }));
  }

  return data?.map((item: any) => ({
    position: item.position,
    skus: extractSkuNumbersFromString(item?.source_of_products),
  }));
};
