import { ReactElement, useEffect } from 'react';
import { useDeviceType } from '@client/common/hooks';
import { SCREENSHOT_TOOL_DATA_HIDE } from '@client/common/screenshotTool';
import { getCookie, setCookie } from '@client/common/utils/storage/cookies';
import { useTranslate } from '@client/i18n/hooks';
import {
  useClearProductsCompareAction,
  useRemoveProductFromCompareAction,
  useSetIsProductsComparisonBarClosed,
} from '@client/product/hooks';
import { COMPARE_PRODUCTS_COOKIE_NAME } from '@client/product/redux/productsCompare/types';
import { ROUTES } from '@client/routes/components/Router/routes';
import { useRouterPush, useUrlCreator } from '@client/routes/hooks';
import { getCdnImageUrl, getProductFallbackImgUrl, openLink } from '@client/routes/utils/url';
import { useProductsCompareSettings } from '@client/settings/hooks/useProductsCompareSettings';
import { ComparisonBar } from 'b2b-components/theme/components/ComparisonBar';
import { ICON_NAME } from 'b2b-components/theme/components/Icon';

export interface Props {
  className?: string;
}

export const ProductsComparisonBar = ({ className }: Props): ReactElement | null => {
  const t = useTranslate();
  const deviceType = useDeviceType();
  const urlCreator = useUrlCreator();
  const push = useRouterPush();
  const onClearProductsCompare = useClearProductsCompareAction();
  const onProductRemove = useRemoveProductFromCompareAction();
  const onSetIsComparisonBarClosed = useSetIsProductsComparisonBarClosed();
  const productsCompareSettings = useProductsCompareSettings();
  const { isClosed, products } = productsCompareSettings;
  const isVisible = products.length && !isClosed;

  //TODO
  // Shape of the cookie with comparison settings has changed and this is backward support for outdated cookies
  // Remove this useEffect when app reach version 4.0.0
  useEffect(
    () => {
      const cookie = getCookie(COMPARE_PRODUCTS_COOKIE_NAME) as { skus: string[] };
      if (cookie?.skus?.length) {
        setCookie(COMPARE_PRODUCTS_COOKIE_NAME, productsCompareSettings);
      }
    },
    [],
  );

  const handleClearComparisonClick = () => {
    onClearProductsCompare();
  };

  const handleHideComparisonClick = () => {
    onSetIsComparisonBarClosed({ isClosed: true });
  };

  const handleCompareClick = () => {
    openLink(urlCreator(ROUTES.productsCompare, { skus: products.map(product => product.sku).join() }), push);
  };

  const handleProductClick = (sku: string) => {
    openLink(
      urlCreator(ROUTES.product, { sku }),
      push,
    );
  };

  const handleProductRemove = (sku: string) => {
    onProductRemove({ sku });
  };

  const text = {
    label: t('Product comparison'),
    compareButtonLabel: t('Compare'),
    clearComparisonLabel: t('Clear comparison'),
    clearComparisonDescription: t('Remove all products from comparison'),
    hideComparisonBarLabel: t('Hide comparison bar'),
    hideComparisonBarDescription: t('Click on any "add to compare" button to make visible again'),
    unknownProduct: t('Unknown product'),
  };

  const actions = [
    {
      icon: ICON_NAME.remove,
      onClick: handleClearComparisonClick,
      label: text.clearComparisonLabel,
      description: text.clearComparisonDescription,
    },
    {
      icon: ICON_NAME.eyeHide,
      onClick: handleHideComparisonClick,
      label: text.hideComparisonBarLabel,
      description: text.hideComparisonBarDescription,
    },
  ];

  return (
    <ComparisonBar
      onCompareClick={handleCompareClick}
      onItemClick={handleProductClick}
      onItemRemove={handleProductRemove}
      items={products.map(product => ({
        id: product.sku,
        thumbnail: getCdnImageUrl(product.cover) || getProductFallbackImgUrl(),
        name: product.name,
      }))}
      label={text.label}
      isVisible={isVisible}
      compareButtonLabel={text.compareButtonLabel}
      actions={actions}
      className={className}
      deviceType={deviceType}
      dataAttributes={SCREENSHOT_TOOL_DATA_HIDE}
    />
  );
};
