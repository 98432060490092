import { AxiosRequestConfig } from 'axios';

export type RequestConfigExtrasType = AxiosRequestConfig & {
  url?: string;
  withLocalization?: boolean;
};

export type RequestConfigType = AxiosRequestConfig & {
  method: string;
  url: string;
  withLocalization?: boolean;
};

export class RequestConfig {
  public static get(url: string, config?: RequestConfigExtrasType): RequestConfigType {
    return { ...config, method: 'get', url };
  }

  public static post(url: string, data: any, config?: RequestConfigExtrasType): RequestConfigType {
    return { ...config, method: 'post', url, data };
  }

  public static put(url: string, data: any, config?: RequestConfigExtrasType): RequestConfigType {
    return { ...config, method: 'put', url, data };
  }

  public static patch(url: string, data: any, config?: RequestConfigExtrasType): RequestConfigType {
    return { ...config, method: 'patch', url, data };
  }

  public static delete(url: string, config?: RequestConfigExtrasType): RequestConfigType {
    return { ...config, method: 'delete', url };
  }
}
