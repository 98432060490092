import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { CostCenters } from '@client/myAccount/costCenters/api';
import { COST_CENTERS_LIST_DEFAULT_PAGE_SIZE } from '@client/myAccount/costCenters/consts';
import {
  fetchCostCenters,
  fetchCostCentersFulfilled,
  fetchCostCentersRejected,
  fetchCostCentersSearch,
  fetchCostCentersSearchFulfilled,
  fetchCostCentersSearchRejected,
  fetchCostCentersSimpleSearch,
} from '@client/myAccount/costCenters/redux/costCenters';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, debounce, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import emitter, { EVENT } from 'b2b-apps/core/common/utils/eventEmitter';
import {
  CostCenterSearchListItemType,
  CostCentersType,
} from 'b2b-common/core/account/api/costCenters/CostCenters.types';

const COST_CENTERS_SEARCH_DEBOUNCE = 500;

function* fetchCostCentersSaga(action: ReturnType<typeof fetchCostCenters>):
  Generator<CallEffect | PutEffect, void, AxiosResponse<CostCentersType>> {
  const page = action.payload || 1;
  const startItem = (page - 1) * COST_CENTERS_LIST_DEFAULT_PAGE_SIZE;

  try {
    const response = yield call(
      requestApiSaga,
      CostCenters.list(startItem),
    );

    yield put(fetchCostCentersFulfilled(response.data));
  } catch (error) {
    yield put(fetchCostCentersRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: { page: action.payload } }));
  }
}

function* fetchCostCentersSearchSaga(action: ReturnType<typeof fetchCostCentersSearch>):
  Generator<CallEffect | PutEffect, void, AxiosResponse<CostCentersType>> {
  const { page = 1, query } = action.payload;
  const startItem = (page - 1) * COST_CENTERS_LIST_DEFAULT_PAGE_SIZE;

  try {
    const response = yield call(
      requestApiSaga,
      CostCenters.search(startItem, COST_CENTERS_LIST_DEFAULT_PAGE_SIZE, query),
    );

    yield put(fetchCostCentersSearchFulfilled(response.data));
  } catch (error) {
    yield put(fetchCostCentersSearchRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* fetchCostCentersSimpleSearchSaga(action: ReturnType<typeof fetchCostCentersSimpleSearch>):
  Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<CostCenterSearchListItemType[]>
> {
  const { query, includeInactive } = action.payload;

  try {
    const response = yield call(
      requestApiSaga,
      CostCenters.simpleSearch({ query, includeInactive }),
    );

    emitter.dispatch(EVENT.searchCostCentersFulfilled, response.data);
  } catch (error) {
    emitter.dispatch(EVENT.searchCostCentersRejected, []);
  }
}

export function* costCentersSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(fetchCostCenters.type, fetchCostCentersSaga),
    takeLatest(fetchCostCentersSearch.type, fetchCostCentersSearchSaga),
    debounce(COST_CENTERS_SEARCH_DEBOUNCE, fetchCostCentersSimpleSearch.type, fetchCostCentersSimpleSearchSaga),
  ]);
}
