import { isServer } from '@client/common/utils/env';
import qs, { IStringifyOptions } from 'qs';

export const stringify = (
  object: Record<string, any>,
  options: IStringifyOptions = {},
): string => qs.stringify(object, {
  encodeValuesOnly: true,
  ...options,
});

export const parse = (url?: string): Record<string, any> => {
  const stringDefaultValue = !isServer ? window.location.search : '';
  const newUrl = url || stringDefaultValue;

  return qs.parse(newUrl, {
    ignoreQueryPrefix: true,
  });
};
