import { requestApiSaga, retryRequestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Rma } from '@client/myAccount/rma/api/Rma';
import {
  cancelRma,
  cancelRmaFulfilled,
  cancelRmaRejected,
  clearRmaCreate,
  clearRmaUpdate,
  createRma,
  createRmaFulfilled,
  createRmaRejected,
  fetchRma,
  fetchRmaFulfilled,
  fetchRmaInvoice,
  fetchRmaInvoiceAddress,
  fetchRmaInvoiceAddressFulfilled,
  fetchRmaInvoiceAddressRejected,
  fetchRmaInvoiceFulfilled,
  fetchRmaInvoiceRejected,
  fetchRmaRejected,
  sendRmaEmail,
  sendRmaEmailFulfilled,
  sendRmaEmailRejected,
  updateRma,
  updateRmaFulfilled,
  updateRmaRejected,
} from '@client/myAccount/rma/redux/rma/redux';
import { clearAccountRmas as clearRmas } from '@client/myAccount/rma/redux/rmas/redux';
import { RMA_TYPE } from '@client/myAccount/rma/utils/rma';
import { ROUTES } from '@client/routes/components/Router/routes';
import { pushToRoute } from '@client/routes/redux/utils';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

function* createRmaSaga(action: ReturnType<typeof createRma>):
  Generator<CallEffect | PutEffect | ReturnType<typeof pushToRoute>, any, AxiosResponse> {
  const { type, isStandalone, data } = action.payload;

  try {
    if (type === RMA_TYPE.repair) {
      if (data?.returnItems[0]?.quantity) {
        data.returnItems[0].quantity = '1';
      }
    }

    const { data: response } = yield call(requestApiSaga, Rma.create(type, data));
    yield put(createRmaFulfilled());
    yield put(clearRmaCreate());
    yield put(clearRmas());

    if (isStandalone) {
      yield pushToRoute(ROUTES.rmaConfirmation, {
        debitorId: data.customerNumber,
        rmaId: response.id,
      });
    } else {
      yield pushToRoute(ROUTES.myAccountRmaConfirmation, {
        rmaId: response.id,
      });
    }
  } catch (error) {
    yield put(createRmaRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* fetchRmaSaga(action: ReturnType<typeof fetchRma>): Generator<CallEffect | PutEffect, any, AxiosResponse> {
  const { rmaId, debitorId } = action.payload;

  try {
    const response = yield call(retryRequestApiSaga, Rma.fetch(rmaId, debitorId));
    yield put(fetchRmaFulfilled({ rmaId, data: response.data }));
  } catch (error) {
    yield put(fetchRmaRejected({ rmaId, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* fetchRmaInvoiceAddressSaga(action: ReturnType<typeof fetchRmaInvoiceAddress>):
  Generator<CallEffect | PutEffect, any, AxiosResponse> {
  const rmaInvoiceId = action.payload;

  try {
    const response = yield call(
      retryRequestApiSaga,
      Rma.fetchInvoiceAddress(rmaInvoiceId),
    );
    yield put(fetchRmaInvoiceAddressFulfilled({ rmaInvoiceId, data: response.data }));
  } catch (error) {
    yield put(fetchRmaInvoiceAddressRejected({ rmaInvoiceId, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: { rmaInvoiceId } }));
  }
}

function* fetchRmaInvoiceSaga(action: ReturnType<typeof fetchRmaInvoice>):
  Generator<CallEffect | PutEffect, any, AxiosResponse> {
  const { rmaInvoiceId, debitorId } = action.payload;

  try {
    const response = yield call(
      retryRequestApiSaga,
      Rma.fetchInvoice(rmaInvoiceId, debitorId),
    );
    yield put(fetchRmaInvoiceFulfilled({ rmaInvoiceId, data: response.data }));
  } catch (error) {
    yield put(fetchRmaInvoiceRejected({ rmaInvoiceId, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* updateRmaSaga(action: ReturnType<typeof updateRma>):
  Generator<CallEffect | PutEffect | ReturnType<typeof pushToRoute>, any, any> {
  const { rmaId, data } = action.payload;

  try {
    yield call(requestApiSaga, Rma.update(rmaId, data));
    yield put(updateRmaFulfilled());
    yield put(fetchRma({ rmaId }));
    yield put(clearRmaUpdate());
    yield put(clearRmas());
    yield pushToRoute(ROUTES.myAccountRma, {
      rmaId,
    });
  } catch (error) {
    yield put(updateRmaRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* cancelRmaSaga(action: ReturnType<typeof cancelRma>): Generator<CallEffect | PutEffect, any, any> {
  const rmaId = action.payload;

  try {
    yield call(requestApiSaga, Rma.cancel(rmaId));
    yield put(cancelRmaFulfilled());
  } catch (error) {
    yield put(cancelRmaRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: { rmaId } }));
  }
}

function* sendRmaEmailSaga(action: ReturnType<typeof sendRmaEmail>): Generator<CallEffect | PutEffect, any, any> {
  const { debitorId, rmaId, email } = action.payload;

  try {
    yield call(requestApiSaga, Rma.sendMail(debitorId, rmaId, email));
    yield put(sendRmaEmailFulfilled());
  } catch (error) {
    yield put(sendRmaEmailRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* rmaSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(cancelRma.type, cancelRmaSaga),
    takeLatest(createRma.type, createRmaSaga),
    takeLatest(fetchRma.type, fetchRmaSaga),
    takeLatest(fetchRmaInvoice.type, fetchRmaInvoiceSaga),
    takeLatest(sendRmaEmail.type, sendRmaEmailSaga),
    takeLatest(updateRma.type, updateRmaSaga),
    takeLatest(fetchRmaInvoiceAddress.type, fetchRmaInvoiceAddressSaga),
  ]);
}
