import { ReactElement } from 'react';
import { ADD_TO_CART_DISPLAY_TYPE, AddToCart } from '@client/cart/components/AddToCart';
import { isPhone } from '@client/common/utils/env';
import { AddToCompareAction } from '@client/product/components/ProductActions/AddToCompareAction';
import {
  ADD_TO_WISHLIST_STYLE_TYPE,
  AddToWishlistAction,
} from '@client/product/components/ProductActions/AddToWishlistAction';
import { Props } from './Actions.types';

export const Actions = ({
  isLoaded,
  product,
  deviceType,
  isAddToCartHidden,
  onAddToCart,
}: Props): ReactElement => {
  if (!isLoaded || !product) {
    return null;
  }

  const {
    description: { title },
    effectivePricing: { isCertified },
    settings,
    sku: productSku,
  } = product;

  return (
    <>
      <li className='cds-ProductBox-Action cds-ProductBox-Action-AddToCompare'>
        {!isPhone(deviceType) && (
          <AddToCompareAction
            product={product}
            isVisible
          />
        )}
      </li>
      <li className='cds-ProductBox-Action cds-ProductBox-Action-AddToWishlist'>
        <AddToWishlistAction
          skus={[productSku]}
          styleType={ADD_TO_WISHLIST_STYLE_TYPE.icon}
        />
      </li>

      {!isAddToCartHidden && (
        <li className='cds-ProductBox-Action cds-ProductBox-Action-AddToCart'>
          <AddToCart
            isUserCertified={isCertified}
            productName={title}
            settings={settings}
            sku={productSku}
            type={ADD_TO_CART_DISPLAY_TYPE.icon}
            isVisible
            onClick={onAddToCart}
          />
        </li>
      )}
    </>
  );
};
