import { ReactElement } from 'react';
import { BarcodeAnalyticsProvider } from '@client/analytics/context/barcode/BarcodeAnalyticsContext';
import { ProductsAnalyticsProvider } from '@client/analytics/context/products/ProductsAnalyticsContext';
import { SearchAnalyticsProvider } from '@client/analytics/context/search/SearchAnalyticsContext';
import { EVENT_SOURCE } from '@client/analytics/types/types';
import { Desktop } from '@client/app/components/App/Header/Desktop';
import { Mobile } from '@client/app/components/App/Header/Mobile';
import { isMobile } from '@client/common/utils/env';
import { Props } from './Header.types';

export const Header = (props: Props): ReactElement => {
  const { className, deviceType, ...restProps } = props;

  return (
    <BarcodeAnalyticsProvider>
      <SearchAnalyticsProvider>
        <ProductsAnalyticsProvider eventSource={EVENT_SOURCE.searchAutosuggest}>
          <div className={className}>
            {isMobile(deviceType) ? (
              <Mobile {...restProps} />
            ) : (
              <Desktop {...restProps} />
            )}
          </div>
        </ProductsAnalyticsProvider>
      </SearchAnalyticsProvider>
    </BarcodeAnalyticsProvider>
  );
};
