import { ReactElement } from 'react';
import { Autosuggest } from '@client/app/components/App/Header/Autosuggest';
import { ModalMenu } from '@client/app/components/App/Header/ModalMenu';
import { SearchField } from '@client/app/components/App/Header/SearchField';
import { UserArea } from '@client/app/components/App/Header/UserArea';
import { Logo } from '@client/common/components/Logo';
import { PrimaryLayout } from '@lib/components/PrimaryLayout';
import classNames from 'classnames';
import { Props } from './';
import './Mobile.scss';

export const Mobile = ({
  autosuggestRefCallback,
  className,
  isAutosuggestOpened,
  onCloseAutosuggest,
  onKeyDown,
  onOpenAutosuggest,
  setOnKeyDown,
}: Props): ReactElement => {
  const rootClassName = classNames('Mobile', className);

  return (
    <>
      <div className={rootClassName}>
        <PrimaryLayout>
          <div className="cds-Row cds-Row--CenterVertical">
            <ModalMenu />
            <Logo />
            <UserArea />
          </div>

          <div className="cds-Row">
            <SearchField
              onCloseAutosuggest={onCloseAutosuggest}
              onOpenAutosuggest={onOpenAutosuggest}
              onInputKeyDown={onKeyDown}
            />
          </div>
        </PrimaryLayout>

        <Autosuggest
          autosuggestRefCallback={autosuggestRefCallback}
          isAutosuggestOpened={isAutosuggestOpened}
          onCloseAutosuggest={onCloseAutosuggest}
          setOnKeyDown={setOnKeyDown}
        />
      </div>
    </>
  );
};
