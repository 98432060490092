import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrdersType } from 'b2b-common/core/account/api/orders';

export const ordersSearchSlice = createSlice({
  name: 'ordersSearch',
  initialState: RemoteRecordData.getDefault(),
  reducers: {
    ordersSearch: (state, action: PayloadAction<{
      pageSize: number;
      startItem: number;
      searchQuery: string;
    }>) => {
      state = RemoteRecordData.init();
      return state;
    },
    ordersSearchFulfilled: (state, action: PayloadAction<{ data: OrdersType }>) => {
      state = RemoteRecordData.fulfill(action.payload.data);
      return state;
    },
    ordersSearchRejected: (state, action: PayloadAction<{ error: ErrorType }>) => {
      state = RemoteRecordData.reject(action.payload.error);
      return state;
    },
    clearOrdersSearch: (state) => {
      state = RemoteRecordData.getDefault();
      return state;
    },
  },
});

export const {
  ordersSearch,
  ordersSearchFulfilled,
  ordersSearchRejected,
  clearOrdersSearch,
} = ordersSearchSlice.actions;

const selectState = (state: AppState) => state.myAccount.orders.ordersSearch;

export const selectOrdersSearch = createSelector(
  selectState,
  (state) => new RemoteRecord<OrdersType>(state),
);
