import { ReactNode } from 'react';
import { toast } from 'react-toastify';
import { MyAccountToast } from '@client/myAccount/common/components/MyAccountToast';

enum MY_ACCOUNT_MESSAGE {
  duplicationErrorAddToWishlist = 'duplicationErrorAddToWishlist',
  errorAddAddress = 'errorAddAddress',
  errorAddToWishlist = 'errorAddToWishlist',
  errorChangePassword = 'errorChangePassword',
  errorCreateAddress = 'errorCreateAddress',
  errorCreateAndAddToWishlist = 'errorCreateAndAddToWishlist',
  errorCreateUser = 'errorCreateUser',
  errorCreateWishlist = 'errorCreateWishlist',
  errorEditAddress = 'errorEditAddress',
  errorEditContactInformation = 'errorEditContactInformation',
  errorRemoveAddress = 'errorRemoveAddress',
  errorRemoveUser = 'errorRemoveUser',
  errorRemoveWishlist = 'errorRemoveWishlist',
  errorRemoveWishlistItem = 'errorRemoveWishlistItem',
  errorRemoveWishlistItems = 'errorRemoveWishlistItems',
  errorUpdateUser = 'errorUpdateUser',
  errorUpdateWishlist = 'errorUpdateWishlist',
  errorUpdateWishlistItem = 'errorUpdateWishlistItem',
  errorUpdateWishlistReference = 'errorUpdateWishlistReference',
  successAddAddress = 'successAddAddress',
  successAddToWishlist = 'successAddToWishlist',
  successChangePassword = 'successChangePassword',
  successCreateAddress = 'successCreateAddress',
  successCreateAndAddToWishlist = 'successCreateAndAddToWishlist',
  successCreateUser = 'successCreateUser',
  successCreateWishlist = 'successCreateWishlist',
  successEditAddress = 'successEditAddress',
  successEditContactInformation = 'successEditContactInformation',
  successRemoveAddress = 'successRemoveAddress',
  successSetAsDefaultAddress = 'successSetAsDefaultAddress',
  errorSetAsDefaultAddress = 'errorSetAsDefaultAddress',
  successRemoveItem = 'successRemoveItem',
  successRemoveItems = 'successRemoveItems',
  successRemoveUser = 'successRemoveUser',
  successRemoveWishlist = 'successRemoveWishlist',
  successUpdateUser = 'successUpdateUser',
  successUpdateWishlist = 'successUpdateWishlist',
  successCreateCostCenter = 'successCreateCostCenter',
  successUpdateCostCenter = 'successUpdateCostCenter',
  successRemoveCostCenter = 'successRemoveCostCenter',
  errorRemoveCostCenter = 'errorRemoveCostCenter',
  submitCostCenterError = 'submitCostCenterError',
  submitBudgetError = 'submitBudgetError',
  removeBudgetError = 'submitBudgetError',
  errorGetOrderDetail = 'errorGetOrderDetail',
  errorGetOrderDetailItems = 'errorGetOrderDetailItems',
  errorRejectOrder = 'errorRejectOrder',
  errorSendOrderToSales = 'errorSendOrderToSales',
}

const showMyAccountToast = (messageId: MY_ACCOUNT_MESSAGE, content?: ReactNode): void => {
  toast(<MyAccountToast messageId={messageId} content={content} />);
};

export { MY_ACCOUNT_MESSAGE, showMyAccountToast };
