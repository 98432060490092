import { parseError } from '@client//common/utils/api/error';
import { Assortment } from '@client/assortment/api/Assortment';
import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { fetchAssortment, fetchAssortmentFulfilled, fetchAssortmentRejected } from './redux';

function* fetchAssortmentSaga(
  action: typeof fetchAssortment,
): Generator<CallEffect | PutEffect, void, AxiosResponse> {
  try {
    const response = yield call(requestApiSaga, Assortment.fetch());

    yield put(
      fetchAssortmentFulfilled(Assortment.mapResponseToState(response)),
    );
  } catch (error) {
    yield put(fetchAssortmentRejected(parseError(error)));
    yield put(reportError({ message: action.type, error }));
  }
}

export function* assortmentSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeEvery(fetchAssortment.type, fetchAssortmentSaga),
  ]);
}
