import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeliveredOrdersType } from 'b2b-common/core/account/api/DeliveredOrders.types';

export const deliveredOrdersSearchSlice = createSlice({
  name: 'deliveredOrdersSearch',
  initialState: {},
  reducers: {
    deliveredOrdersSearch: (state, action: PayloadAction<string>) => {
      state = RemoteRecordData.init();
      return state;
    },
    deliveredOrdersSearchFulfilled: (state, action: PayloadAction<{ data: DeliveredOrdersType }>) => {
      state = RemoteRecordData.fulfill(action.payload.data);
      return state;
    },
    deliveredOrdersSearchRejected: (state, action: PayloadAction<{ error: ErrorType }>) => {
      state = RemoteRecordData.reject(action.payload.error);
      return state;
    },
    clearDeliveredOrdersSearch: (state) => {
      state = RemoteRecordData.getDefault();
      return state;
    },
  },
});

export const {
  deliveredOrdersSearch,
  deliveredOrdersSearchFulfilled,
  deliveredOrdersSearchRejected,
  clearDeliveredOrdersSearch,
} = deliveredOrdersSearchSlice.actions;

const selectState = (state: AppState) => state.myAccount.deliveredOrders.deliveredOrdersSearch;

export const selectDeliveredOrdersSearch = createSelector(
  selectState,
  (state) => new RemoteRecord<DeliveredOrdersType>(state),
);
