export const extractSkuNumbersFromString = (skuString?: string): string[] => {
  if (skuString) {
    const skuArray = skuString
      .split(/[;]/)
      .map(el => el.split(':')[0])
      .filter((v) => !!v);

    return skuArray;
  }

  return [];
};

export const extractMptIdFromString = (skuString?: string): Record<string, string> => (skuString?.split(';') || []).reduce((result, product) => {
  if (product.includes(':')) {
    const [sku, mptId] = product.split(':');
    if (sku && mptId) {
      result[sku] = mptId;
    }
  }
  return result;
}, {} as Record<string, string>);
