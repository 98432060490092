import { invoiceSlice } from '@client/myAccount/invoices/redux/invoice/redux';
import { invoicesSlice as coreInvoicesSlice } from '@client/myAccount/invoices/redux/invoices/redux';
import { invoicesSearchSlice } from '@client/myAccount/invoices/redux/invoicesSearch/redux';
import { sendEmailSlice } from '@client/myAccount/invoices/redux/sendEmail/redux';
import { combineSlices } from '@reduxjs/toolkit';

export const invoicesReducer = combineSlices(
  invoiceSlice,
  coreInvoicesSlice,
  invoicesSearchSlice,
  sendEmailSlice,
);
