import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CatalogType } from '@client/app/hooks';
import { useMapAssortment } from '@client/app/hooks/useMapAssortment';
import { config } from '@client/config';
import { fetchAssortment, selectAssortment } from '../redux/assortment/redux';

export const useAssortment = (): CatalogType => {
  const dispatch = useDispatch();
  const assortment = useSelector(selectAssortment);
  const content = useMapAssortment(assortment);

  useEffect(
    (): void => {
      if (!assortment.isLoaded && config.features.ownAssortment) {
        dispatch(fetchAssortment());
      }
    },
    [assortment.isLoaded],
  );

  return content;
};
