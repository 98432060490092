import { createContext, ReactElement, ReactNode, useContext } from 'react';
import { useDispatch } from 'react-redux';
import {
  analyzeProductAddToCart,
  AnalyzeProductAddToCartPayload,
  analyzeProductAddToWishlist,
  analyzeProductClick,
  AnalyzeProductClickPayload,
  analyzeProductsAddToCart,
  AnalyzeProductsAddToCartPayload,
  AnalyzeProductsAddToWishlistPayload,
  analyzeProductsListView,
  AnalyzeProductsListViewPayload,
} from '@client/analytics/redux';
import { EVENT_SOURCE } from '@client/analytics/types/types';
import { bindActionCreators } from '@reduxjs/toolkit';

interface ProductsAnalyticsContextValue {
  analyzeProductsListView:
    (data?: AnalyzeProductsListViewPayload) => ReturnType<typeof analyzeProductsListView>,
  analyzeProductAddToWishlist:
    (data: AnalyzeProductsAddToWishlistPayload) => ReturnType<typeof analyzeProductAddToWishlist>;
  analyzeProductClick:
    (data: AnalyzeProductClickPayload) => ReturnType<typeof analyzeProductClick>;
  analyzeProductAddToCart:
    (data: AnalyzeProductAddToCartPayload) => ReturnType<typeof analyzeProductAddToCart>;
  analyzeProductsAddToCart:
    (data: AnalyzeProductsAddToCartPayload) => ReturnType<typeof analyzeProductsAddToCart>;
}

interface ProductsAnalyticsProviderProps {
  children: ReactNode;
  eventSource: EVENT_SOURCE;
}

const ProductsAnalyticsContext = createContext<ProductsAnalyticsContextValue | undefined>(undefined);
export const ProductsAnalyticsProvider = ({ children, eventSource }: ProductsAnalyticsProviderProps): ReactElement => {
  const dispatch = useDispatch();

  return (
    <ProductsAnalyticsContext.Provider value={{
      analyzeProductsListView:
        bindActionCreators(data => analyzeProductsListView({ ...(data || {}), eventSource }), dispatch),
      analyzeProductAddToWishlist:
        bindActionCreators(data => analyzeProductAddToWishlist({ ...data, eventSource }), dispatch),
      analyzeProductClick:
        bindActionCreators(data => analyzeProductClick({ ...data, eventSource }), dispatch),
      analyzeProductAddToCart:
        bindActionCreators(data => analyzeProductAddToCart({ ...data, eventSource }), dispatch),
      analyzeProductsAddToCart:
        bindActionCreators(data => analyzeProductsAddToCart({ ...data, eventSource }), dispatch),
    }}>
      {children}
    </ProductsAnalyticsContext.Provider>
  );
};

export const useProductsAnalytics = (): ProductsAnalyticsContextValue => {
  const context = useContext(ProductsAnalyticsContext);

  if (context === undefined) {
    throw new Error('useProductsAnalytics must be used within a ProductsAnalyticsProvider');
  }

  return context;
};
