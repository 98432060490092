import { DEFAULT_EMPTY_QUERY } from '@client/catalog/redux/search/redux';
import { CatalogSearchType } from 'b2b-common/core/catalog/Catalog.types';

export const getResultsPerPageFromSearchData = (data: CatalogSearchType): number => (
  data.site?.listView
    .allowedProductsPerPage
    .find(config => config.current)
    ?.numberOfProducts || 48
);

export const sanitizeOssTerm = (query: string): string => query !== DEFAULT_EMPTY_QUERY ? query : '*';

