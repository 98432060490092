import { ReactElement } from 'react';
import classNames from 'classnames';
import { Props } from './index';

export const Sidebar = ({ children, className, header, sidebar }: Props): ReactElement => (
  <div className={classNames('cds-cms-Layout--Sidebar', className)}>
    <div className="cds-cms-LayoutBlock--Sidebar">{sidebar}</div>

    {header.length > 0 && (
      <div className="cds-cms-LayoutBlock--Banner">{header}</div>
    )}

    <div className="cds-cms-LayoutBlock--Main">{children}</div>
  </div>
);
