import { OSS_INTERACTION_LABEL } from '@client/analytics/redux';
import { analyzeEvent } from '@client/analytics/redux/tealium/actions';
import { TEALIUM_INTERACTION_ID, TEALIUM_PROD_ACTION, TEALIUM_PROD_LIST } from '@client/analytics/types/types';
import {
  SearchAnalyticsSearchOssPropertyBuilder,
} from '@client/analytics/utils/builders/SearchAnalyticsSearchOssPropertyBuilder';
import {
  AnalyticsSearchProducts,
  SearchAnalyticsSearchProductPropertyBuilder,
} from '@client/analytics/utils/builders/SearchAnalyticsSearchProductPropertyBuilder';
import { selectSearch } from '@client/catalog/redux/search/redux';
import { selectSuggestions } from '@client/catalog/redux/suggestions/redux';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { selectProductTileBySku } from '@client/product/redux/products/tile/redux';
import { call, CallEffect, put, PutEffect, select, SelectEffect } from 'redux-saga/effects';
import { CatalogSearchType } from 'b2b-common/core/catalog/Catalog.types';
import { SuggestionsType } from 'b2b-common/core/catalog/Suggestions.types';
import { ProductTileType } from 'b2b-common/core/product/api/ProductTile.types';

type SearchInteractionData = {
  interaction: TEALIUM_INTERACTION_ID;
  interactionLabel?: OSS_INTERACTION_LABEL;
  action: TEALIUM_PROD_ACTION;
  type: TEALIUM_PROD_LIST;
  product: AnalyticsSearchProducts;
  productsList: { sku: string }[];
  quantity?: number;
  resultsCount: number;
  query: string;
  originalQuery?: string;
};

function* analyzeSearchInteraction(data: SearchInteractionData): Generator<PutEffect, void> {
  const {
    interaction,
    interactionLabel,
    action,
    product,
    type,
    productsList,
    quantity,
    resultsCount,
    query,
    originalQuery,
  } = data;
  const productBuilder = new SearchAnalyticsSearchProductPropertyBuilder({ convertToArray: true });
  const ossBuilder = new SearchAnalyticsSearchOssPropertyBuilder();

  productBuilder
    .setAction(action)
    .setProducts(product)
    .setProductsList(productsList)
    .setList(type)
    .setQuantity(quantity);

  ossBuilder
    .setInteraction(interaction)
    .setInteractionLabel(interactionLabel)
    .setResultsCount(resultsCount)
    .setTerm(query)
    .setOriginalQuery(originalQuery)
    .setType(type);

  yield put(analyzeEvent({
    interaction: interaction,
    ...(productBuilder.build()),
    ...(ossBuilder.build()),
  }));
}

function* analyzeSearchProductInteraction(
  interaction: TEALIUM_INTERACTION_ID,
  interactionLabel: OSS_INTERACTION_LABEL | null,
  action: TEALIUM_PROD_ACTION,
  sku: string,
  quantity?: number,
): Generator<
    CallEffect | SelectEffect,
    void,
    RemoteRecord<CatalogSearchType> & RemoteRecord<ProductTileType>
> {
  const searchRecord = yield select(selectSearch);
  const search = searchRecord.data;
  const productTileRecord = yield select(selectProductTileBySku, sku);
  const productTile = productTileRecord.data;

  if (!productTile || !search) {
    return null;
  }

  const { query, requestedQuery: originalQuery } = search;

  yield call(
    analyzeSearchInteraction,
    {
      interaction,
      interactionLabel,
      action,
      product: productTile,
      productsList: search.products,
      query,
      originalQuery,
      quantity,
      resultsCount: search.productsCount,
      type: TEALIUM_PROD_LIST.search,
    },
  );
}

function* analyzeSearchAutosuggestProductInteraction(
  interaction: TEALIUM_INTERACTION_ID,
  interactionLabel: OSS_INTERACTION_LABEL | null,
  action: TEALIUM_PROD_ACTION,
  sku: string,
  quantity?: number,
): Generator<
  CallEffect | SelectEffect,
  void,
  RemoteRecord<SuggestionsType>
> {
  const autosuggestRecord = yield select(selectSuggestions);
  const { products, term } = autosuggestRecord.data;
  const product = products.find(product => product.sku === sku);

  if (!product) {
    return null;
  }

  yield call(
    analyzeSearchInteraction,
    {
      interaction,
      interactionLabel,
      action,
      type: TEALIUM_PROD_LIST.suggestion,
      product,
      productsList: products,
      query: term,
      quantity,
      resultsCount: products.length,
    },
  );
}

export function* analyzeSearchProductClick(sku: string): Generator<CallEffect, void> {
  yield call(
    analyzeSearchProductInteraction,
    TEALIUM_INTERACTION_ID.productSearchListClick,
    OSS_INTERACTION_LABEL.productClick,
    TEALIUM_PROD_ACTION.productListClick,
    sku,
  );
}

export function* analyzeSearchAutosuggestProductClick(sku: string): Generator<CallEffect, void> {
  yield call(
    analyzeSearchAutosuggestProductInteraction,
    TEALIUM_INTERACTION_ID.productSearchListClick,
    OSS_INTERACTION_LABEL.suggestionClick,
    TEALIUM_PROD_ACTION.productListClick,
    sku,
  );
}

export function* analyzeSearchProductAddToCart(sku: string): Generator<CallEffect, void> {
  yield call(
    analyzeSearchProductInteraction,
    TEALIUM_INTERACTION_ID.productSearchAddToCart,
    null,
    TEALIUM_PROD_ACTION.addToCartFromList,
    sku,
    1,
  );
}

export function* analyzeSearchAutosuggestProductAddToCart(sku: string): Generator<CallEffect, void> {
  yield call(
    analyzeSearchAutosuggestProductInteraction,
    TEALIUM_INTERACTION_ID.suggestedProductAddToCart,
    null,
    TEALIUM_PROD_ACTION.addToCartFromList,
    sku,
    1,
  );
}
