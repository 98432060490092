import { FC, lazy } from 'react';
import { config } from '@client/config';
import { getLocalisedPaths } from '@client/routes/utils/paths';
import { PERMISSION } from 'b2b-common/core/context/Context.types';
import { DEFAULT_ROUTE } from '../constants';
import { COST_CENTER_ROUTES_NAMES, CostCenterRouteType } from './types';

export const costCenter: CostCenterRouteType = {
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenterCreate]: {
    ...DEFAULT_ROUTE,
    paths: getLocalisedPaths('my-account/cost-center/create'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/cost-center/create" */
      '@client/myAccount/costCenters/components/FormPage'
    )),
    allow: ({
      isLoggedIn,
      permissionsValidator,
    }): boolean => !!config.features.procurement
      && isLoggedIn && permissionsValidator([PERMISSION.costCenterCreate]),
  },
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenters]: {
    ...DEFAULT_ROUTE,
    paths: getLocalisedPaths('my-account/cost-centers/:page?'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/cost-centers" */
      '@client/myAccount/costCenters/components/ListPage'
    )),
    allow: ({ isLoggedIn }): boolean => !!config.features.procurement && isLoggedIn,
  },
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenter]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/cost-center/:costCenterId'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/cost-center" */
      '@client/myAccount/costCenters/components/ViewPage'
    )),
    allow: ({ isLoggedIn }): boolean => !!config.features.procurement && isLoggedIn,
  },
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenterUpdate]: {
    ...DEFAULT_ROUTE,
    paths: getLocalisedPaths('my-account/cost-center/:costCenterId/update'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/cost-center/update" */
      '@client/myAccount/costCenters/components/FormPage'
    )),
    allow: ({ isLoggedIn }): boolean => !!config.features.procurement && isLoggedIn,
  },
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenterAddresses]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/cost-center/:costCenterId/addresses/:page?'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/cost-center/addresses" */
      '@client/myAccount/costCenters/components/Addresses/ListPage'
    )),
    allow: ({ isLoggedIn }): boolean => !!config.features.procurement && isLoggedIn,
  },
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenterAddressCreate]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/cost-center/:costCenterId/address/create'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/cost-center/address/create" */
      '@client/myAccount/costCenters/components/Addresses/CreatePage'
    )),
    allow: ({ isLoggedIn }): boolean => !!config.features.procurement && isLoggedIn,
  },
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenterAddressEdit]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/cost-center/:costCenterId/address/:addressId/update'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/cost-center/address/update" */
      '@client/myAccount/costCenters/components/Addresses/UpdatePage'
    )),
    allow: ({ isLoggedIn }): boolean => !!config.features.procurement && isLoggedIn,
  },
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenterUsers]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/cost-center/:costCenterId/users/:page?'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/cost-center/users" */
      '@client/myAccount/costCenters/components/Users/ListPage'
    )),
    allow: ({ isLoggedIn }): boolean => !!config.features.procurement && isLoggedIn,
  },
  [COST_CENTER_ROUTES_NAMES.myAccountCostCentersUsersCreate]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/cost-center/:costCenterId/user/create'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/cost-center/user/create" */
      '@client/myAccount/costCenters/components/Users/FormPage'
    )),
    allow: ({ isLoggedIn }): boolean => !!config.features.procurement && isLoggedIn,
  },
  [COST_CENTER_ROUTES_NAMES.myAccountCostCentersUsersUpdate]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/cost-center/:costCenterId/user/:userId/update'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/cost-center/user/update" */
      '@client/myAccount/costCenters/components/Users/FormPage'
    )),
    allow: ({ isLoggedIn }): boolean => !!config.features.procurement && isLoggedIn,
  },
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenterBudgets]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/cost-center/:costCenterId/budgets/:page?'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/cost-center/budgets" */
      '@client/myAccount/costCenters/components/Budgets/ListPage'
    )),
    allow: ({ isLoggedIn }): boolean => !!config.features.procurement && isLoggedIn,
  },
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenterBudgetForm]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/cost-center/:costCenterId/budget/:budgetId?'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/cost-center/budget" */
      '@client/myAccount/costCenters/components/Budgets/FormPage'
    )),
    allow: ({ isLoggedIn }): boolean => !!config.features.procurement && isLoggedIn,
  },
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenterOrderRequestList]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/cost-center/order-requests/:page'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/cost-center/order-requests" */
      '@client/myAccount/costCenters/components/OrderRequests/ListPage'
    )) as FC,
    allow: ({ isLoggedIn }): boolean => isLoggedIn && !!config.features.procurement,
  },
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenterOrderRequest]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/cost-center/order-request/details/:id'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/cost-center/order-request/details" */
      '@client/myAccount/costCenters/components/OrderRequests/DetailsPage'
    )) as FC,
    allow: ({ isLoggedIn }): boolean => !!config.features.procurement && isLoggedIn,
  },
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenterOrderRequestConfirmation]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/cost-center/order-request/confirmation/:id/:status'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/cost-center/order-request/confirmation" */
      '@client/myAccount/costCenters/components/OrderRequests/ConfirmationPage'
    )),
    allow: ({ isLoggedIn }): boolean => !!config.features.procurement && isLoggedIn,
  },
};
