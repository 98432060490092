import { FormEvent, ReactElement, useEffect, useState } from 'react';
import { CertificationInfo } from '@client/cart/components/AddToCart/CertificationInfo';
import emitter, { EVENT } from '@client/common/utils/eventEmitter';
import { useTranslate } from '@client/i18n/hooks';
import { Alert, ALERT_DISPLAY_TYPE, ALERT_STYLE_TYPE } from '@lib/components/Alert';
import { Button, BUTTON_ICON_POSITION, BUTTON_TYPE } from '@lib/components/Button';
import { ButtonGroup } from '@lib/components/ButtonGroup';
import { ValueType } from '@lib/components/Form/common';
import { CounterInput } from '@lib/components/Form/CounterInput';
import { Icon, ICON_NAME } from '@lib/components/Icon';
import classNames from 'classnames';
import { Hint } from './Hint';
import { Props } from './';
import './Full.scss';

export const Full = ({
  className,
  dataAttributes,
  isBlocked,
  hasAccessCartPermissions,
  hasCertificationInfo,
  isLoggedIn,
  onAddToCart,
  settings,
  text,
}: Props): ReactElement => {
  const t = useTranslate();
  const {
    isBuyable,
    isSellout,
    quantity,
    isDisabledByCoreAssortment,
  } = settings;
  const defaultState = { currentQuantity: 1, attemptedQuantity: 1 };
  const [state, setState] = useState(defaultState);
  const { currentQuantity, attemptedQuantity } = state;
  const counterId = 'cart/addToCart/full/counterId';
  const handleNumberChange = (value: ValueType, attemptedQuantity: ValueType) => {
    setState(prevState => ({
      ...prevState,
      currentQuantity: +value || 1,
      attemptedQuantity: +attemptedQuantity || 1,
    }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    onAddToCart(state.currentQuantity);
  };

  const getAlertMessage = () => {
    switch (true) {
      case isDisabledByCoreAssortment:
        return text.notInCoreAssortment;
      case isBlocked:
        return text.blockedUser;
      case (isSellout && quantity <= 0):
        return text.isSoldOut;
      case (!isBuyable):
        return text.notBuyable;
      case (isLoggedIn && !hasAccessCartPermissions):
        return text.notPermitted;
      default:
        return undefined;
    }
  };

  const isDisabled = isBlocked
    || (isSellout && quantity <= 0)
    || !isBuyable
    || (isLoggedIn && !hasAccessCartPermissions)
    || hasCertificationInfo
    || isDisabledByCoreAssortment;

  const isAlertVisible = isBlocked
    || (isSellout && quantity <= 0)
    || !isBuyable
    || (isLoggedIn && !hasAccessCartPermissions)
    || isDisabledByCoreAssortment;

  useEffect(() => {
    emitter.on(EVENT.addToCartFulfilled, () => {
      setState(defaultState);
    });

    return () => {
      emitter.off(EVENT.addToCartFulfilled);
    };
  }, []);

  return (
    <form className={className} onSubmit={handleSubmit}>
      <ButtonGroup className={classNames({ 'FullAddToCart-ButtonGroup--WithCertification': hasCertificationInfo })}>
        <CounterInput
          counterId={counterId}
          value={currentQuantity}
          onChange={handleNumberChange}
          isDisabled={isDisabled}
          maxValue={999}
        />
        <Button
          isDisabled={isDisabled}
          iconPosition={BUTTON_ICON_POSITION.left}
          type={BUTTON_TYPE.submit}
          dataAttributes={dataAttributes}
        >
          <Icon name={ICON_NAME.cart} />
          {t('Add to cart', {}, 'Buy button label')}
        </Button>
      </ButtonGroup>

      {hasCertificationInfo && <CertificationInfo className={className} />}

      {isAlertVisible && (
        <Alert displayType={ALERT_DISPLAY_TYPE.text} styleType={ALERT_STYLE_TYPE.info}>
          {getAlertMessage()}
        </Alert>
      )}

      <Hint
        attemptedQuantity={attemptedQuantity}
        currentQuantity={currentQuantity}
        settings={settings}
        text={text}
      />
    </form>
  );
};
