export enum VIEW_TYPE {
  list = 'List',
  table = 'Table',
  tile = 'Tile',
  slider = 'Slider',
}

export enum SOURCE_TYPE {
  skus = 'skus',
  query = 'query',
}

export type ProductGroupType = {
  label: string;
  limit: string;
  'mpt_id': string;
  'source_of_products': string;
  'random_order'?: boolean,
  'source_type': SOURCE_TYPE;
  view: VIEW_TYPE;
  _metadata: {
    uid: string;
  };
};
