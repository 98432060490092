import { ReactElement } from 'react';
import { useDeviceType } from '@client/common/hooks';
import { useTranslate } from '@client/i18n/hooks';
import { useAddProductToCompareAction, useRemoveProductFromCompareAction } from '@client/product/hooks';
import { ROUTES } from '@client/routes/components/Router/routes';
import { useRouterPush, useUrlCreator } from '@client/routes/hooks';
import { openLink } from '@client/routes/utils/url';
import { useProductsCompareSettings } from '@client/settings/hooks';
import classNames from 'classnames';
import { ActionLink } from 'b2b-components/theme/components/ActionLink';
import {
  CHECKBOX_BUTTON_SIZE,
  CHECKBOX_BUTTON_STYLE_TYPE,
  CheckboxButton,
} from 'b2b-components/theme/components/Form/Checkbox/CheckboxButton';
import { Tooltip } from 'b2b-components/theme/components/Tooltip';
import { DEVICE_TYPE } from 'b2b-components/utils/device';
import { MAX_NUMBER_OF_PRODUCTS_TO_COMPARE } from 'b2b-common/core/product/api/ProductsCompare.types';
import { Props } from './';
import './AddToCompareAction.scss';

export const AddToCompareAction = ({
  product,
  isVisible = true,
  buttonSize = CHECKBOX_BUTTON_SIZE.small,
  className,
}: Props): ReactElement => {
  const t = useTranslate();
  const urlCreator = useUrlCreator();
  const push = useRouterPush();
  const deviceType = useDeviceType();
  const { products } = useProductsCompareSettings();
  const numberOfProductsAddedToCompare = products.length;

  const isChecked = !!products.find(p => p.sku === product.sku);
  const isDisabled = !isChecked && numberOfProductsAddedToCompare === MAX_NUMBER_OF_PRODUCTS_TO_COMPARE;

  const addProductToCompare = useAddProductToCompareAction();
  const removeProductFromCompare = useRemoveProductFromCompareAction();

  const isHidden = deviceType !== DEVICE_TYPE.tablet && !(isChecked || isVisible);

  const { sku, name, title, description, cover } = product;


  const comparisonBarProduct = {
    sku: sku,
    name: name || title || description.title,
    cover: (typeof cover === 'string' ? cover : cover?.sizes.find(({ size }) => size === 'L')?.link) || null,
  };

  const handleChange = (): void => {
    if (isChecked) {
      removeProductFromCompare({ sku });
      return;
    }

    addProductToCompare({ product: comparisonBarProduct });
  };

  const handleTooltipLinkClick = () => {
    openLink(
      urlCreator(ROUTES.productsCompare, { skus: products.map(p => p.sku).join(',') }),
      push,
    );
  };

  const tooltipContent = isChecked
    ? t('Remove from comparison')
    : isDisabled
      ? t('You reached a maximum number of products in comparison')
      : t('Add to comparison');

  return (
    <div
      data-testid='addToComparisonActionButton'
      className={classNames(
        'AddToCompareAction',
        { 'AddToCompareAction--Ready': isChecked },
        { 'AddToCompareAction--Hidden': isHidden },
        className,
      )}
    >
      <Tooltip
        content={(
          <>
            <span className='AddToCompareAction-TooltipText'>
              {tooltipContent}
            </span>

            {(isChecked || isDisabled) && (
              <div className='AddToCompareAction-TooltipLink'>
                <ActionLink onClick={handleTooltipLinkClick}>
                  {`${t('Compare')} (${numberOfProductsAddedToCompare})`}
                </ActionLink>
              </div>
            )}
          </>
        )}
        triggerClassName="AddToCompareAction-TooltipTrigger"
        contentClassName="AddToCompareAction-TooltipContent"
      >
        <CheckboxButton
          onChange={handleChange}
          isChecked={isChecked}
          isDisabled={isDisabled}
          size={buttonSize}
          styleType={CHECKBOX_BUTTON_STYLE_TYPE.secondary}
        />
      </Tooltip>
    </div>
  );
};
