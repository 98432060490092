import { ReactElement } from 'react';
import { createKey } from '@client/common/utils/key/key';
import { TEXT_MEDIA_IMAGE_POSITION, TextMedia as TextMediaComponent } from '@lib/components/CMS/TextMedia';
import classNames from 'classnames';
import { Props } from '../';

enum IMAGE_POSITION {
  left = 'Left',
  right = 'Right',
}

export const TextMedia = ({ className, componentData }: Props): ReactElement | null => {
  let imagePosition;

  switch (componentData.image_position) {
    case IMAGE_POSITION.left:
      imagePosition = TEXT_MEDIA_IMAGE_POSITION.left;
      break;

    case IMAGE_POSITION.right:
      imagePosition = TEXT_MEDIA_IMAGE_POSITION.right;
      break;

    default:
      imagePosition = TEXT_MEDIA_IMAGE_POSITION.default;
      break;
  }

  return (
    <div className={classNames('cds-cms-GridRow', className)}>
      <div className="cds-cms-TextMediaContainer">
        <div className="cds-cms-GridRow">
          <TextMediaComponent
            key={createKey('textMedia')}
            htmlText={componentData.text}
            imageCaption={componentData.image_caption}
            imagePosition={imagePosition}
            imageUrl={componentData.image ? componentData.image.url : undefined}
            videoUrl={componentData.video_url || undefined}
          />
        </div>
      </div>
    </div>
  );
};
