import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PDF_CATEGORY, PdfType } from 'b2b-common/core/account/api/Pdf.types';

const NAME = 'pdf';

export const pdfSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {},
  reducers: {
    fetchPdf: (state, action: PayloadAction<{
      category: PDF_CATEGORY;
      id: string;
    }>) => {
      state[action.payload.id] = RemoteRecordData.init();
      return state;
    },
    fetchPdfFulfilled: (state, action: PayloadAction<{
      category: string;
      id: string;
      data: PdfType;
    }>) => {
      state[action.payload.id] = RemoteRecordData.fulfill(
        action.payload.data,
      );
    },
    fetchPdfRejected: (state, action: PayloadAction<{
      id: string;
      error: ErrorType;
    }>) => {
      state[action.payload.id] = RemoteRecordData.reject(
        action.payload.error,
      );
    },
    removePdf: (state, action: PayloadAction<string>) => {
      delete state[action.payload];
      return state;
    },
  },
});

export const {
  fetchPdf,
  fetchPdfFulfilled,
  fetchPdfRejected,
  removePdf,
} = pdfSlice.actions;

const getState = (state: AppState, id: string) => state.myAccount.pdf[id];

export const selectPdf = createSelector(
  getState,
  (state) => new RemoteRecord<PdfType>(state),
);
