import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { stringify } from '@client/common/utils/queryParams';

export class Users {
  public static fetch(pageSize = 25, startItem = 0): RequestConfigType {
    const searchParams = {
      pageSize,
      startItem,
    };

    return RequestConfig.get(
      `/account/users?${stringify(searchParams)}`,
    );
  }

  public static search(pageSize = 25, startItem = 0, searchQuery: string): RequestConfigType {
    const searchParams = {
      pageSize,
      startItem,
    };

    return RequestConfig.get(
      `/account/users?${stringify(searchParams)}&q=${searchQuery}`,
    );
  }
}
