import { ReactElement, ReactNode } from 'react';
import { RemoteRecordError } from 'b2b-apps/core/common/components/RemoteRecordHandler/RemoteRecordError';
import { RemoteRecordLoading } from 'b2b-apps/core/common/components/RemoteRecordHandler/RemoteRecordLoading';
import { RemoteRecord } from 'b2b-apps/core/common/redux/RemoteRecord';

interface Props {
  remoteRecord: RemoteRecord<any> | RemoteRecord<any>[];
  showOnRefresh?: boolean;
  customLoaderComponent?: ReactNode;
  customErrorComponent?: ReactNode;
  children: ReactNode;
  shouldCheckEmptyRecord?: boolean;
  shouldOpenNotFoundPage?: boolean;
  disableSpecificErrorHandler?: number | null;
}

export const RemoteRecordHandler = ({
  remoteRecord,
  showOnRefresh = true,
  children,
  customLoaderComponent,
  customErrorComponent,
  shouldCheckEmptyRecord,
  shouldOpenNotFoundPage = true,
  disableSpecificErrorHandler = null,
}: Props): ReactElement => {
  const disabledElement = (Array.isArray(remoteRecord) && disableSpecificErrorHandler !== null)
    ? remoteRecord.filter((_, index) => index !== disableSpecificErrorHandler) : remoteRecord;
  const remoteRecordsArray = Array.isArray(remoteRecord) ? remoteRecord : [remoteRecord];
  const disabledElementArray = Array.isArray(disabledElement) ? disabledElement : remoteRecordsArray;
  return (
    <RemoteRecordLoading
      remoteRecords={remoteRecordsArray}
      showOnRefresh={showOnRefresh}
      loadingComponent={customLoaderComponent}
    >
      <RemoteRecordError
        remoteRecords={disabledElementArray}
        errorComponent={customErrorComponent}
        shouldCheckEmptyRecord={shouldCheckEmptyRecord}
        shouldOpenNotFoundPage={shouldOpenNotFoundPage}
      >
        {children}
      </RemoteRecordError>
    </RemoteRecordLoading>
  );
};
