import { requestApiSaga, retryRequestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Address } from '@client/myAccount/addresses/api/Address';
import {
  addAddress,
  editAddress,
  fetchAddress,
  fetchAddressFulfilled,
  fetchAddressRejected,
  removeAddress,
} from '@client/myAccount/addresses/redux/address/redux';
import { MY_ACCOUNT_MESSAGE, showMyAccountToast } from '@client/myAccount/common/utils/messages';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import emitter, { EVENT } from 'b2b-apps/core/common/utils/eventEmitter';
import { AddressType } from 'b2b-common/core/account/api/Address.types';

function* fetchAddressSaga(
  action: ReturnType<typeof fetchAddress>,
): Generator<CallEffect | PutEffect, any, AxiosResponse<AddressType>> {
  const { code } = action.payload;

  try {
    const { data } = yield call(retryRequestApiSaga, Address.fetch(code));
    yield put(
      fetchAddressFulfilled({ code, data }),
    );
  } catch (error) {
    yield put(fetchAddressRejected({ code, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* addAddressSaga(action: ReturnType<typeof addAddress>): Generator<CallEffect | PutEffect, any, AxiosResponse> {
  const addressData = action.payload;

  try {
    yield call(requestApiSaga, Address.addAddress(addressData));

    emitter.dispatch(EVENT.submitAddressFulfilled);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.successCreateAddress);
  } catch (error) {
    yield put(reportError({ message: action.type, error }));

    emitter.dispatch(EVENT.submitAddressRejected);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.errorCreateAddress);
  }
}

function* editAddressSaga(
  action: ReturnType<typeof editAddress>,
): Generator<CallEffect | PutEffect, any, AxiosResponse> {
  const { code, addressData } = action.payload;

  try {
    yield call(requestApiSaga, Address.editAddress(code, addressData));

    emitter.dispatch(EVENT.submitAddressFulfilled);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.successEditAddress);
  } catch (error) {
    yield put(reportError({ message: action.type, error, additionalData: { code } }));

    emitter.dispatch(EVENT.submitAddressRejected);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.errorEditAddress);
  }
}

function* removeAddressSaga(
  action: ReturnType<typeof removeAddress>,
): Generator<CallEffect | PutEffect, any, AxiosResponse> {
  const code = action.payload;

  try {
    yield call(requestApiSaga, Address.removeAddress(code));

    emitter.dispatch(EVENT.removeAddressFulfilled);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.successRemoveAddress);
  } catch (error) {
    yield put(reportError({ message: action.type, error, additionalData: { code } }));

    emitter.dispatch(EVENT.removeAddressRejected);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.errorRemoveAddress);
  }
}

export function* addressSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(fetchAddress.type, fetchAddressSaga),
    takeLatest(addAddress.type, addAddressSaga),
    takeLatest(editAddress.type, editAddressSaga),
    takeLatest(removeAddress.type, removeAddressSaga),
  ]);
}
