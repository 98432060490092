import { ReactElement, useEffect } from 'react';
import { getErrorMessage } from '@client/common/utils/form/form';
import { useTranslate } from '@client/i18n/hooks';
import { useFormikContext } from 'formik';
import { ModalButtons } from 'b2b-components/core/components/Modal';
import { BUTTON_TYPE } from 'b2b-components/theme/components/Button';
import { FormFieldText } from 'b2b-apps/core/common/components/Form/FormFieldText';
import emitter, { EVENT } from 'b2b-apps/core/common/utils/eventEmitter';
import { FIELD_NAME, FormValuesType } from '../Form.types';
import { Props } from './index';

export const Body = ({ onBack }: Props): ReactElement => {
  const {
    errors,
    setFormikState,
    handleSubmit,
    isSubmitting,
    touched,
    setSubmitting,
  } = useFormikContext<FormValuesType>();
  const t = useTranslate();

  useEffect(() => {
    emitter.on(EVENT.addToWishlistRejected, () => {
      setSubmitting(false);
    });

    emitter.on(EVENT.searchWishlistsRejected, () => {
      setFormikState((state) => ({
        ...state,
        errors: {
          ...state.errors,
          [FIELD_NAME.newWishlistName]: t('Could not search wishlists. Please try again later.'),
        },
        touched: { ...state.touched, [FIELD_NAME.newWishlistName]: true },
      }));
    });

    return () => {
      emitter.off(EVENT.addToWishlistRejected);
      emitter.off(EVENT.searchWishlistsRejected);
    };
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <FormFieldText
        name={FIELD_NAME.newWishlistName}
        innerComponentProps={{
          autoFocus: true,
          text: {
            label: t('New wishlist name'),
          },
          error: getErrorMessage<FormValuesType>(
            errors[FIELD_NAME.newWishlistName],
            touched[FIELD_NAME.newWishlistName],
          ),
        }}
      />

      <ModalButtons
        primary={{
          label: isSubmitting ? t('Submitting...') : t('Create and add'),
          type: BUTTON_TYPE.submit,
          isLoading: isSubmitting,
        }}
        cancel={{
          label: t('Back'),
          onClick: onBack,
        }}
      />
    </form>
  );
};
