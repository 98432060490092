import { ReactElement, useId } from 'react';
import { withField } from '@client/common/components/Form/withField';
import { NEW_OPTION_VALUE, SearchableSelect, STYLE_TYPE } from 'b2b-components/theme/components/Form/SearchableSelect';
import { Props } from './FormFieldSearchableSelect.types';

export const FormFieldSearchableSelect = withField((props: Props): ReactElement => {
  const { innerComponentProps, field, form, ...otherProps } = props;
  const searchableSelectId = useId();

  return (
    <SearchableSelect searchableSelectId={searchableSelectId} {...innerComponentProps} {...field} {...otherProps} />
  );
});

export {
  NEW_OPTION_VALUE as SEARCHABLE_NEW_OPTION_VALUE,
  STYLE_TYPE as SEARCHABLE_SELECT_STYLE_TYPE,
};
