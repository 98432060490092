import { AnalyticsComponentType, TEALIUM_PROD_ACTION } from '@client/analytics/types/types';
import { ANALYTICS_ATTRIBUTES, BaseBuilder } from '@client/analytics/utils/builders/BaseBuilder';

export class FlyoutAnalyticsBuilder
  extends BaseBuilder<AnalyticsComponentType> {
  private path: string[];
  private url: string;
  private position: number;

  protected attributes: Array<ANALYTICS_ATTRIBUTES> = [
    ANALYTICS_ATTRIBUTES.attribute1,
    ANALYTICS_ATTRIBUTES.attribute2,
    ANALYTICS_ATTRIBUTES.attribute3,
    ANALYTICS_ATTRIBUTES.attribute4,
    ANALYTICS_ATTRIBUTES.attribute5,
    ANALYTICS_ATTRIBUTES.attribute6,
    ANALYTICS_ATTRIBUTES.category,
    ANALYTICS_ATTRIBUTES.subcategory,
    ANALYTICS_ATTRIBUTES.location,
    ANALYTICS_ATTRIBUTES.innerlocation,
  ];

  protected analyticsResultObject: Record<string, any> = {
    [ANALYTICS_ATTRIBUTES.attribute1]: '',
    [ANALYTICS_ATTRIBUTES.attribute2]: '',
    [ANALYTICS_ATTRIBUTES.attribute3]: '',
    [ANALYTICS_ATTRIBUTES.attribute4]: '',
    [ANALYTICS_ATTRIBUTES.attribute5]: '',
    [ANALYTICS_ATTRIBUTES.attribute6]: '',
    [ANALYTICS_ATTRIBUTES.category]: '',
    [ANALYTICS_ATTRIBUTES.subcategory]: '',
    [ANALYTICS_ATTRIBUTES.location]: '',
    [ANALYTICS_ATTRIBUTES.innerlocation]: '',
  };

  public setUrl(url: string): FlyoutAnalyticsBuilder {
    this.url = url;
    return this;
  }

  public setPosition(position: number): FlyoutAnalyticsBuilder {
    this.position = position;
    return this;
  }

  public setPath = (path: string[]): FlyoutAnalyticsBuilder => {
    this.path = path.filter((v) => !!v);
    return this;
  }

  public build(): any {
    this.analyticsResultObject.category = ['nav'];
    this.analyticsResultObject.subcategory = ['main_navigation'];
    this.analyticsResultObject.location = [`Level ${this.path.length}`];
    this.analyticsResultObject.attribute1 = [this.url];
    this.analyticsResultObject.attribute2 = [this.path.join('/')];
    this.analyticsResultObject.attribute3 = [TEALIUM_PROD_ACTION.click];
    this.analyticsResultObject.attribute4 = [this.path[0]];
    this.analyticsResultObject.attribute5 = [this.path[1]];
    this.analyticsResultObject.attribute6 = [this.path[2]];
    this.analyticsResultObject.innerlocation = [`${this.position}`];

    return {
      [ANALYTICS_ATTRIBUTES.component]: super.build() || {},
    };
  }
}
