import { retryRequestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Pdf } from '@client/myAccount/pdf/api/Pdf';
import { fetchPdf, fetchPdfFulfilled, fetchPdfRejected, removePdf } from '@client/myAccount/pdf/redux/redux';
import { AxiosResponse } from 'axios';
import download from 'downloadjs';
import { all, AllEffect, call, CallEffect, delay, ForkEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';

const FETCH_PDF_DELAY = 1000;

function* fetchPdfSaga(action: ReturnType<typeof fetchPdf>): Generator<CallEffect | PutEffect, any, AxiosResponse> {
  const { category, id } = action.payload;

  try {
    const response = yield call(retryRequestApiSaga, Pdf.fetch(id, category));
    const fileName = `${category.slice(0, -1)}-${id}.pdf`;
    const data = response.data;
    download(
      `data:application/pdf;base64,${data.pdf}`,
      fileName,
      'application/pdf',
    );
    yield delay(FETCH_PDF_DELAY);
    yield put(fetchPdfFulfilled({ id, category, data }));
    yield put(removePdf(id));
  } catch (error) {
    yield put(fetchPdfRejected({ id, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* pdfSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeEvery(fetchPdf.type, fetchPdfSaga),
  ]);
}
