import { getAvailableLanguages, LANGUAGE_REG_EXP } from '@client/i18n/utils/language';
import { SERVER_ROUTES } from '@client/routes/components/Router/routes';
import { Request } from 'express';
import { sanitizePath } from 'b2b-server/core/utils/path';
import { translations } from 'b2b-server/shop/assets/translations/translations';
import { LANGUAGE_CODE } from 'b2b-common/core/i18n/consts';
import { AB_TESTS } from 'b2b-common/core/order';

type TranslateFunction = (
  key: string | string[],
  params?: Record<string, string | number>,
  comment?: string,
) => string;


const detectLanguage = (request: Request, sessionLanguage: LANGUAGE_CODE = null): LANGUAGE_CODE => {
  let finalLanguage = LANGUAGE_CODE.de; // default
  const availableLanguages = getAvailableLanguages();
  const isInternalRequest = Object
    .values(SERVER_ROUTES)
    .some(path => request.originalUrl?.includes(path));

  //NOTE: keep order of checks intact!
  const isSessionLanguageSupported = availableLanguages.includes(sessionLanguage);

  if (isSessionLanguageSupported) {
    if (isInternalRequest) {
      return sessionLanguage;
    }

    finalLanguage = sessionLanguage;
  }

  const headerLanguage = request.acceptsLanguages?.(availableLanguages);
  if (headerLanguage) {
    finalLanguage = headerLanguage as LANGUAGE_CODE;
  }

  const url = sanitizePath(request.path);
  const match = url.match(LANGUAGE_REG_EXP);
  if (match && match[1]) {
    finalLanguage = match[1] as LANGUAGE_CODE;
  }

  return finalLanguage;
};

const getTranslatedText = (
  translations: Record<string, string> | undefined,
  key: string | string[],
  params?: Record<string, string | number>,
): string => {
  if (Array.isArray(key)) {
    const [pluralSingleKey, pluralMultipleKey, quantityKey] = key;

    return getTranslatedText(
      translations,
      params[quantityKey] > 1 ? pluralMultipleKey : pluralSingleKey,
      params,
    );
  }

  let text = translations?.[key] || key;

  if (params) {
    Object.entries(params).forEach(([param, value]) => {
      text = text.replaceAll(`{${param}}`, `${value}`);
    });
  }

  return text;
};

const getTranslationFromRequest = (request: Request): TranslateFunction => {
  const language = request.context.getSessionData().getSettings().language;

  return (
    key: string | string[],
    params: Record<string, string | number>,
  ): string => getTranslatedText(
    request.abTests[AB_TESTS.untranslated] ? {} : translations[language],
    key,
    params,
  );
};

export {
  detectLanguage,
  getTranslatedText,
  getTranslationFromRequest,
};
export type { TranslateFunction };
