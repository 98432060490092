export enum CMS_NEWSROOM_PAGE_ID {
  newsroomCenter = 'page__newsroom_center',
  downloadCenter = 'page__newsroom_download_center',
  singleNews = 'page__single_press_release',
  downloadPage = 'page__newsroom_download_pages',
}

export enum LOADED_CONTENT_TYPE_ID {
  assets = 'container__module_assets',
}

export const getIsContentTypeNewsroom = (contentTypeId: CMS_NEWSROOM_PAGE_ID): boolean => (
  Object.values(CMS_NEWSROOM_PAGE_ID).includes(contentTypeId)
);

export const getIsContentTypeNewsroomCenter = (contentTypeId: CMS_NEWSROOM_PAGE_ID): boolean => (
  contentTypeId === CMS_NEWSROOM_PAGE_ID.newsroomCenter
);

export const getIsContentTypeDownloadCenter = (contentTypeId: CMS_NEWSROOM_PAGE_ID): boolean => (
  contentTypeId === CMS_NEWSROOM_PAGE_ID.downloadCenter
);

export const getIsContentTypeNewsroomPage = (contentTypeId: CMS_NEWSROOM_PAGE_ID): null | any => (
  [CMS_NEWSROOM_PAGE_ID.singleNews, CMS_NEWSROOM_PAGE_ID.downloadPage].includes(contentTypeId)
);

export const getEntriesIdsFromNewsroomAssets = (newsroomAssets: Record<string, string[]>): string[] => (
  Object
    .keys(newsroomAssets)
    .filter((entryId) => newsroomAssets[entryId].some((el: string) => el.includes(LOADED_CONTENT_TYPE_ID.assets)))
);
