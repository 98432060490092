import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useCartStatusEffect } from '@client/cart/hooks';
import { useDeviceType } from '@client/common/hooks';
import { config } from '@client/config';
import { useCheckPermissions, useLoginAction, useLogoutAction, useUserData } from '@client/context/hooks';
import { selectHasOnlyCoreAssortment } from '@client/context/redux/redux';
import { useActiveLanguage, useTranslate } from '@client/i18n/hooks';
import { useMyAccountDashboardLinks } from '@client/myAccount/common/hooks';
import { ROUTES } from '@client/routes/components/Router/routes';
import { useRouterPush, useUrlCreator } from '@client/routes/hooks';
import { openLink, simulateLinkClick } from '@client/routes/utils/url';
import { useUserStatsEffect } from '@client/user-stats';
import classNames from 'classnames';
import { Tooltip } from 'b2b-components/core/components/Tooltip';
import { AccountMenu } from 'b2b-components/theme/components/AccountMenu';
import { Button, BUTTON_SIZE, BUTTON_STYLE_TYPE } from 'b2b-components/theme/components/Button';
import { BUTTON_ICON_ICON_NAME, BUTTON_ICON_SIZE, ButtonIcon } from 'b2b-components/theme/components/ButtonIcon';
import { CART_BUTTON_STYLE_TYPE, CartButton } from 'b2b-components/theme/components/CartButton';
import { Dropdown } from 'b2b-components/theme/components/Dropdown';
import { Toggler } from 'b2b-components/theme/components/Dropdown/Toggler';
import { Icon, ICON_NAME, ICON_SIZE } from 'b2b-components/theme/components/Icon';
import { isDesktop, isMobile, isTablet } from 'b2b-components/utils/device';
import { PERMISSION } from 'b2b-common/core/context/Context.types';
import './UserArea.scss';

export const UserArea = (): ReactElement => {
  const t = useTranslate();
  const { debitorId, isLoggedIn } = useUserData();
  const { count: cartProductCount, value: cartValue } = useCartStatusEffect();
  const { totalOrdersToReview } = useUserStatsEffect();
  const deviceType = useDeviceType();
  const push = useRouterPush();
  const urlCreator = useUrlCreator();
  const activeLanguage = useActiveLanguage();
  const hasOnlyCoreAssortment = useSelector(selectHasOnlyCoreAssortment);

  const hasAccessKanbanPermissions = useCheckPermissions([PERMISSION.accessKanban]);
  const hasAccessCartPermissions = useCheckPermissions([PERMISSION.accessCart]);
  const hasAccessWishlistPermissions = useCheckPermissions([PERMISSION.accessWishlist]);
  const hasMyAccountPermissions = useCheckPermissions([PERMISSION.accessMyAccountDashboard]);

  const hasExpressOrder = config.features.expressOrder && (hasAccessCartPermissions || hasAccessWishlistPermissions);
  const isSelfRegistrationEnabled = config.features.selfRegistration;
  const selfRegistrationUrl = isSelfRegistrationEnabled ? config.selfRegistrationUrl : '';
  const myAccountDashboardLinks = useMyAccountDashboardLinks();

  const handleLogin = useLoginAction();
  const handleLogout = useLogoutAction();
  const handleMyAccountLinkClick = (url: string) => openLink(url, push);
  const handleMyAccountClick = () => push(urlCreator(ROUTES.myAccount));
  const handleWishlistsClick = () => push(urlCreator(ROUTES.myAccountWishlists));
  const handleExpressOrderClick = () => push(urlCreator(ROUTES.expressOrder));
  const handleCartClick = () => push(urlCreator(ROUTES.cart));
  const handleKanbanClick = () => push(urlCreator(ROUTES.kanban));
  const handleRegisterClick = () => simulateLinkClick(
    selfRegistrationUrl.replace('{language}', activeLanguage),
  );

  const rootClassName = classNames(
    'UserArea',
    { 'UserArea--Mobile': !isTablet(deviceType) && isMobile(deviceType) },
  );

  const RegisterButton = (): ReactElement => (
    <Button
      size={BUTTON_SIZE.small}
      styleType={BUTTON_STYLE_TYPE.ghost}
      onClick={() => handleRegisterClick?.()}
    >
      {t('Register')}
    </Button>
  );

  const LoginButton = (): ReactElement => (
    <Button
      size={BUTTON_SIZE.small}
      onClick={() => handleLogin()}
      dataAttributes={{ 'data-testid': 'userAreaLoginButton' }}
    >
      {t('Login')}
    </Button>
  );

  const UnloggedButtons = (): ReactElement => (
    <>
      {isSelfRegistrationEnabled && (
        <li><RegisterButton /></li>
      )}

      <li><LoginButton /></li>
    </>
  );

  switch (true) {
    case isDesktop(deviceType):
      return (
        <ul className={rootClassName}>
          {isLoggedIn
            ? (
              <>
                {hasExpressOrder && (
                  <li>
                    <Toggler
                      className='UserArea-MyAccountButton UserArea-MyAccountButton--ExpressOrder'
                      icon={ICON_NAME.expressOrder}
                      label={t('Express Order')}
                      onClick={handleExpressOrderClick}
                      withArrow={false}
                      dataAttributes={{ 'data-testid': 'headerExpressOrderButton' }}
                    />
                  </li>
                )}

                {hasAccessWishlistPermissions && (
                  <li>
                    <Toggler
                      className='UserArea-MyAccountButton'
                      icon={ICON_NAME.wishlist}
                      label={t('Wishlists')}
                      onClick={handleWishlistsClick}
                      withArrow={false}
                      dataAttributes={{ 'data-testid': 'headerWishlistsButton' }}
                    />
                  </li>
                )}

                {hasMyAccountPermissions && (
                  <li className='UserArea-Li-MyAccount' data-hj-suppress>
                    <Dropdown
                      content={(
                        <AccountMenu
                          groups={myAccountDashboardLinks}
                          onLinkClick={handleMyAccountLinkClick}
                        />
                      )}
                      isHoverable={true}
                      togglerProps={{
                        icon: ICON_NAME.account,
                        label: t('My Account {debitorId}', { debitorId: debitorId ? `(${debitorId})` : '' }),
                        onClick: handleMyAccountClick,
                        withArrow: false,
                        className: classNames('UserArea-MyAccountButton', { 'UserArea-Badge': !!totalOrdersToReview }),
                        dataAttributes: { 'data-testid': 'headerMyAccountButton' },
                      }}
                    />
                    {hasOnlyCoreAssortment && (
                      <Tooltip triggerClassName='UserArea-Icon-TooltipTrigger'
                               content={t('This account is restricted to core assortment only')}>
                        <Icon name={ICON_NAME.coreAssortment} size={ICON_SIZE.small} />
                      </Tooltip>
                    )}
                  </li>
                )}

                <li>
                  <Button
                    size={BUTTON_SIZE.small}
                    styleType={BUTTON_STYLE_TYPE.light}
                    className='UserArea-Button--Logout'
                    onClick={handleLogout}
                    dataAttributes={{ 'data-testid': 'userAreaLogoutButton' }}
                  >
                    {t('Logout')}
                  </Button>
                </li>

                <li>
                  <CartButton
                    onClick={handleCartClick}
                    value={cartValue}
                    productCount={cartProductCount}
                    dataAttributes={{ 'data-testid': 'userAreaCartButton' }}
                  />
                </li>
              </>
            )
            : <UnloggedButtons />
          }
        </ul>
      );

    case isTablet(deviceType):
      return (
        <ul className={rootClassName}>
          {isLoggedIn
            ? (
              <>
                {hasAccessKanbanPermissions && (
                  <li>
                    <Toggler
                      className='UserArea-MyAccountButton'
                      icon={ICON_NAME.expressOrder}
                      label={t('Kanban Order')}
                      onClick={handleKanbanClick}
                      withArrow={false}
                    />
                  </li>
                )}

                <li>
                  <Toggler
                    className='UserArea-MyAccountButton'
                    icon={ICON_NAME.account}
                    label={t('My Account {debitorId}', { debitorId: debitorId ? `(${debitorId})` : '' })}
                    onClick={handleMyAccountClick}
                    withArrow={false}
                    dataAttributes={{ 'data-testid': 'headerMyAccountButton' }}
                  />
                </li>

                <li>
                  <CartButton
                    onClick={handleCartClick}
                    value={cartValue}
                    productCount={cartProductCount}
                    dataAttributes={{ 'data-testid': 'userAreaCartButton' }}
                  />
                </li>
              </>
            )
            : <UnloggedButtons />
          }
        </ul>
      );

    case isMobile(deviceType):
    default:
      return (
        <ul className={rootClassName}>
          {isLoggedIn
            ? (
              <>
                {hasAccessKanbanPermissions && (
                  <li>
                    <ButtonIcon
                      className='UserArea-MyAccountButton'
                      icon={ICON_NAME.expressOrder}
                      onClick={handleKanbanClick}
                      size={BUTTON_ICON_SIZE.small}
                      styleType={BUTTON_STYLE_TYPE.secondary}
                      dataAttributes={{ 'data-testid': 'headerExpressOrderButton' }}
                    />
                  </li>
                )}
                <li>
                  <ButtonIcon
                    className='UserArea-MyAccountButton'
                    icon={BUTTON_ICON_ICON_NAME.account}
                    onClick={handleMyAccountClick}
                    size={BUTTON_ICON_SIZE.small}
                    styleType={BUTTON_STYLE_TYPE.secondary}
                    dataAttributes={{ 'data-testid': 'headerMyAccountButton' }}
                  />
                </li>

                <li>
                  <CartButton
                    onClick={handleCartClick}
                    productCount={cartProductCount}
                    styleType={CART_BUTTON_STYLE_TYPE.minified}
                    dataAttributes={{ 'data-testid': 'userAreaCartButton' }}
                  />
                </li>
              </>
            )
            : <li><LoginButton /></li>
          }
        </ul>
      );
  }
};
