import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';

class Newsroom {
  public static fetchNews(page: number, pageSize: number): RequestConfigType {
    return RequestConfig.get(`/content/newsroom/news?page=${page}&pageSize=${pageSize}`);
  }

  public static fetchAssets(page: number, pageSize: number): RequestConfigType {
    return RequestConfig.get(`/content/newsroom/assets?page=${page}&pageSize=${pageSize}`);
  }
}

export {
  Newsroom,
};
