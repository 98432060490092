import { ReactElement } from 'react';
import { Toast, TOAST_TYPE } from '@client/common/components/Toast';
import { useTranslate } from '@client/i18n/hooks';
import { MY_ACCOUNT_MESSAGE } from '@client/myAccount/common/utils/messages';
import { Props } from './';

export const MyAccountToast = ({ messageId, content }: Props): ReactElement => {
  const t = useTranslate();

  switch (messageId) {
    case MY_ACCOUNT_MESSAGE.duplicationErrorAddToWishlist:
      return (
        <Toast
          header={t('Product is already on this wishlist')}
          type={TOAST_TYPE.warning}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.errorAddToWishlist:
      return (
        <Toast
          header={t('Could not add the product, try again later')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.errorChangePassword:
      return (
        <Toast
          header={t('Could not change password, try again')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.errorCreateAndAddToWishlist:
      return (
        <Toast
          header={t(
            'Could not add the product to a new wishlist, try again later',
          )}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.errorAddAddress:
    case MY_ACCOUNT_MESSAGE.errorCreateAddress:
      return (
        <Toast
          header={t('Could not add the address, try again later')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.errorCreateUser:
      return (
        <Toast
          header={t('Could not add the user, try again later')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.errorCreateWishlist:
      return (
        <Toast
          header={t('Could not add the wishlist, try again later')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.errorEditAddress:
      return (
        <Toast
          header={t('Could not edit the address, try again later')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.errorEditContactInformation:
      return (
        <Toast
          header={t('Could not edit contact information, try again later')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.errorRemoveAddress:
      return (
        <Toast
          header={t('Could not delete the address, try again later')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.errorRemoveUser:
      return (
        <Toast
          header={t('Your changes could not be saved, please try again later')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.errorRemoveWishlist:
      return (
        <Toast
          header={t('Could not delete the wishlist, try again later')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.errorUpdateUser:
      return (
        <Toast
          header={t('Could not edit the user, try again later')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.errorRemoveWishlistItem:
      return (
        <Toast
          header={t('Could not delete the wishlist item, try again later')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.errorRemoveWishlistItems:
      return (
        <Toast
          header={t('Could not delete wishlist items, try again later')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.errorUpdateWishlist:
      return (
        <Toast
          header={t('Could not edit the wishlist, try again later')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.errorUpdateWishlistItem:
      return (
        <Toast
          header={t('Could not edit the wishlist item, try again later')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.errorUpdateWishlistReference:
      return (
        <Toast
          header={t('Could not update wishlist reference, try again later')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.successAddToWishlist:
      return (
        <Toast
          header={t('Item added to wishlist')}
          type={TOAST_TYPE.success}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.successChangePassword:
      return (
        <Toast
          header={t('Your password has been successfully changed')}
          type={TOAST_TYPE.success}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.successCreateAndAddToWishlist:
      return (
        <Toast
        header={t('Item added to wishlist')}
        type={TOAST_TYPE.success}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.successAddAddress:
    case MY_ACCOUNT_MESSAGE.successCreateAddress:
      return (
        <Toast
          header={t('New address has been added')}
          type={TOAST_TYPE.success}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.successCreateUser:
      return (
        <Toast
          header={t('New user has been added')}
          type={TOAST_TYPE.success}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.successCreateWishlist:
      return (
        <Toast
          header={t('New wishlist has been added')}
          type={TOAST_TYPE.success}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.successEditAddress:
      return (
        <Toast
          header={t('Address edited successfully')}
          type={TOAST_TYPE.success}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.successEditContactInformation:
      return (
        <Toast
          header={t('Contact information edited successfully')}
          type={TOAST_TYPE.success}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.successRemoveAddress:
      return (
        <Toast
          header={t('Address removed successfully')}
          type={TOAST_TYPE.success}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.successSetAsDefaultAddress:
      return (
        <Toast
          header={t('New default address is set')}
          type={TOAST_TYPE.success}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.successRemoveUser:
      return (
        <Toast
          header={t('User has been successfully removed')}
          type={TOAST_TYPE.success}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.successRemoveWishlist:
      return (
        <Toast
          header={t('Wishlist removed successfully')}
          type={TOAST_TYPE.success}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.successRemoveItem:
      return (
        <Toast
          header={t('Item removed successfully')}
          type={TOAST_TYPE.success}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.successRemoveItems:
      return (
        <Toast
          header={t('Items removed successfully')}
          type={TOAST_TYPE.success}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.successUpdateUser:
      return (
        <Toast
          header={t('User edited successfully')}
          type={TOAST_TYPE.success}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.successUpdateWishlist:
      return (
        <Toast
          header={t('Wishlist edited successfully')}
          type={TOAST_TYPE.success}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.successCreateCostCenter:
      return (
        <Toast
          header={t('Cost center added successfully')}
          type={TOAST_TYPE.success}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.successUpdateCostCenter:
      return (
        <Toast
          header={t('Cost center updated successfully')}
          type={TOAST_TYPE.success}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.successRemoveCostCenter:
      return (
        <Toast
          header={t('Cost center successfully deleted')}
          type={TOAST_TYPE.success}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.errorRemoveCostCenter:
      return (
        <Toast
          header={t('Cost center cannot be remove while there are pending order requets')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.submitCostCenterError:
      return (
        <Toast
          header={t('Could not save cost center, try again later')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.submitBudgetError:
      return (
        <Toast
          header={t('Could not save budget, try again later')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.removeBudgetError:
      return (
        <Toast
          header={t('Could not remove budget, try again later')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.errorSetAsDefaultAddress:
      return (
        <Toast
          header={t('Could not set new default address, try again later')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.errorGetOrderDetail:
      return (
        <Toast
          header={t('Could not get order detail, try again later')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.errorGetOrderDetailItems:
      return (
        <Toast
          header={t('Could not get order items, try again later')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.errorRejectOrder:
      return (
        <Toast
          header={t('Order rejection was unsuccessful, try again later')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    case MY_ACCOUNT_MESSAGE.errorSendOrderToSales:
      return (
        <Toast
          header={t('Could not send the order to sales, try again later')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );

    default:
      return (
        <Toast
          header={t('Could not connect to the my account.')}
          type={TOAST_TYPE.error}
          content={content}
        />
      );
  }
};
