import { Component, ReactNode } from 'react';
import { logger } from '@client/common/utils/logger/logger';
import { AppCrash } from './AppCrash';
import { Props, State } from './';

export class ErrorBoundary extends Component<Props, State> {
  public state: { error: Error | null } = {
    error: null,
  };

  public componentDidCatch(error: Error, errorInfo: Record<string, any> = {}): void {
    logger.error(
      error.message,
      {
        error,
        ...errorInfo,
        source: 'ErrorBoundary',
      },
    );

    this.setState({
      error,
    });
  }

  public render(): ReactNode {
    if (this.state.error) {
      return <AppCrash />;
    }

    return this.props.children;
  }
}
