import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WishlistsSearchType, WishlistsType } from 'b2b-common/core/account/api/wishlists';

export const wishlistsSlice = createSlice({
  name: 'wishlists',
  initialState: {
    list: RemoteRecordData.getDefault(),
    search: RemoteRecordData.getDefault(),
  },
  reducers: {
    fetchWishlists: (state, action: PayloadAction<{ startItem: number, pageSize: number }>) => {
      state.list = RemoteRecordData.init();
      return state;
    },
    fetchWishlistsFulfilled: (state, action: PayloadAction<WishlistsType>) => {
      state.list = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchWishlistsRejected: (state, action: PayloadAction<ErrorType>) => {
      state.list = RemoteRecordData.reject(action.payload);
      return state;
    },
    fetchWishlistsSearch: (state, action: PayloadAction<WishlistsSearchType>) => {
      state.search = RemoteRecordData.init();
      return state;
    },
    fetchWishlistsSearchFulfilled: (state, action: PayloadAction<WishlistsType>) => {
      state.search = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchWishlistsSearchRejected: (state, action: PayloadAction<ErrorType>) => {
      state.search = RemoteRecordData.reject(action.payload);
      return state;
    },
    clearWishlistsSearch: (state) => {
      state.search = RemoteRecordData.getDefault();
      return state;
    },
  },
});

export const {
  fetchWishlists,
  fetchWishlistsFulfilled,
  fetchWishlistsRejected,
  fetchWishlistsSearch,
  fetchWishlistsSearchFulfilled,
  fetchWishlistsSearchRejected,
  clearWishlistsSearch,
} = wishlistsSlice.actions;

const selectState = (state: AppState) => state.myAccount.wishlists.wishlists;

export const selectWishlists = createSelector(
  selectState,
  (state) => new RemoteRecord<WishlistsType>(state.list),
);

export const selectWishlistsSearch = createSelector(
  selectState,
  state => new RemoteRecord<WishlistsType>(state.search),
);
