import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { Loader } from 'b2b-components/theme/components/Loader';

interface Props {
  remoteRecords: RemoteRecord<any>[];
  showOnRefresh?: boolean;
  children: ReactNode;
  loadingComponent?: ReactNode;
}

export const RemoteRecordLoading = ({
  remoteRecords,
  showOnRefresh = true,
  children,
  loadingComponent,
}: Props): ReactElement => {
  const [firstLoadFinished, setFirstLoadFinished] = useState(false);

  useEffect(() => {
    if (!firstLoadFinished && !remoteRecords.some(record => !record.isLoaded)) {
      setFirstLoadFinished(true);
    }
  }, [firstLoadFinished, remoteRecords]);


  if (remoteRecords.some((record) => record.isLoading)) {
    if (showOnRefresh || !firstLoadFinished) {
      return loadingComponent ? <>{loadingComponent}</> : <Loader />;
    }
  }

  return (
    <>{children}</>
  );
};
