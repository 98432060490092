import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { MY_ACCOUNT_MESSAGE, showMyAccountToast } from '@client/myAccount/common/utils/messages';
import {
  addCostCenterAddress,
  editCostCenterAddress,
  fetchCostCenterAddress,
  fetchCostCenterAddresses,
  fetchCostCenterAddressesFulfilled,
  fetchCostCenterAddressesRejected,
  fetchCostCenterAddressFulfilled,
  fetchCostCenterAddressRejected,
  removeCostCenterAddress,
  setDefaultCostCenterAddress,
} from '@client/myAccount/costCenters/redux/address';
import { pushToRoute } from '@client/routes/redux/utils';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import emitter, { EVENT } from 'b2b-apps/core/common/utils/eventEmitter';
import {
  CostCenterAddresses as CostCenterAddressesType,
} from 'b2b-common/core/account/api/costCenters/CostCenterAddresses.types';
import { CostCenterAddresses } from '../../api/CostCenterAddresses';
import { COST_CENTERS_LIST_DEFAULT_PAGE_SIZE } from '../../consts/costCentersList';

function* fetchCostCenterAddressSaga(action: ReturnType<typeof fetchCostCenterAddress>):
  Generator<CallEffect | PutEffect, any, AxiosResponse> {
  const { costCenterId, addressId } = action.payload;

  try {
    const response = yield call(requestApiSaga, CostCenterAddresses.get(costCenterId, addressId));
    yield put(fetchCostCenterAddressFulfilled({ addressId, data: response.data }));
  } catch (error) {
    yield put(fetchCostCenterAddressRejected({ addressId, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* addCostCenterAddressSaga(action: ReturnType<typeof addCostCenterAddress>):
  Generator<CallEffect | PutEffect | ReturnType<typeof pushToRoute>, any, AxiosResponse> {
  const { costCenterId, addressData } = action.payload;

  try {
    yield call(requestApiSaga, CostCenterAddresses.create(costCenterId, addressData));

    emitter.dispatch(EVENT.submitCostCenterAddressFulfilled);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.successCreateAddress);
  } catch (error) {
    yield put(reportError({ message: action.type, error }));

    emitter.dispatch(EVENT.submitCostCenterAddressRejected);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.errorCreateAddress);
  }
}

function* editCostCenterAddressSaga(action: ReturnType<typeof editCostCenterAddress>):
  Generator<CallEffect | PutEffect, any, AxiosResponse> {
  const { costCenterId, addressData } = action.payload;

  try {
    yield call(requestApiSaga, CostCenterAddresses.update(costCenterId, addressData));

    emitter.dispatch(EVENT.submitCostCenterAddressFulfilled);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.successEditAddress);
  } catch (error) {
    emitter.dispatch(EVENT.submitCostCenterAddressRejected);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.errorEditAddress);
  }
}

function* removeCostCenterAddressSaga(action: ReturnType<typeof removeCostCenterAddress>):
  Generator<CallEffect | PutEffect, any, AxiosResponse> {
  const { costCenterId, addressId } = action.payload;

  try {
    yield call(requestApiSaga, CostCenterAddresses.delete(costCenterId, addressId));

    emitter.dispatch(EVENT.removeCostCenterAddressFulfilled);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.successRemoveAddress);
  } catch (error) {
    emitter.dispatch(EVENT.removeCostCenterAddressRejected);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.errorRemoveAddress);
  }
}

function* setDefaultCostCenterAddressSaga(action: ReturnType<typeof setDefaultCostCenterAddress>):
  Generator<CallEffect | PutEffect, any, AxiosResponse> {
  const { costCenterId, addressId } = action.payload;

  try {
    yield call(requestApiSaga, CostCenterAddresses.setDefault(costCenterId, addressId));
    showMyAccountToast(MY_ACCOUNT_MESSAGE.successSetAsDefaultAddress);

    yield put(fetchCostCenterAddresses({
      costCenterId,
      startItem: 0,
      pageSize: COST_CENTERS_LIST_DEFAULT_PAGE_SIZE,
    }));
  } catch (error) {
    showMyAccountToast(MY_ACCOUNT_MESSAGE.errorSetAsDefaultAddress);
  }
}

function* fetchCostCenterAddressesSaga(action: ReturnType<typeof fetchCostCenterAddresses>):
  Generator<CallEffect | PutEffect, void, AxiosResponse<CostCenterAddressesType>> {
  const { costCenterId, pageSize, startItem } = action.payload;

  try {
    const response = yield call(
      requestApiSaga,
      CostCenterAddresses.list(costCenterId, startItem, pageSize),
    );
    yield put(fetchCostCenterAddressesFulfilled(response.data));
  } catch (error) {
    yield put(fetchCostCenterAddressesRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* costCenterAddressSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(fetchCostCenterAddress.type, fetchCostCenterAddressSaga),
    takeLatest(addCostCenterAddress.type, addCostCenterAddressSaga),
    takeLatest(editCostCenterAddress.type, editCostCenterAddressSaga),
    takeLatest(removeCostCenterAddress.type, removeCostCenterAddressSaga),
    takeLatest(setDefaultCostCenterAddress.type, setDefaultCostCenterAddressSaga),
    takeLatest(fetchCostCenterAddresses.type, fetchCostCenterAddressesSaga),
  ]);
}
