import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContentEntryType } from 'b2b-common/core/content/Content.types';

const NAME = 'contentEntries';

export const slice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {
    kam: RemoteRecordData.getDefault(),
  },
  reducers: {
    fetchContentEntry: (state, action: PayloadAction<string>) => {
      state[action.payload] = RemoteRecordData.init();
      return state;
    },
    fetchContentEntryFulfilled: (state, action: PayloadAction<{ entryId: string, data: ContentEntryType}>) => {
      state[action.payload.entryId] = RemoteRecordData.fulfill(action.payload.data);
      return state;
    },
    fetchContentEntryRejected: (state, action: PayloadAction<{ entryId: string, error: ErrorType }>) => {
      state[action.payload.entryId] = RemoteRecordData.reject(action.payload.error);
      return state;
    },
    fetchKAM: (state) => {
      state.kam = RemoteRecordData.init();
      return state;
    },
    fetchKAMFulfilled: (state, action: PayloadAction<ContentEntryType>) => {
      state.kam = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchKAMRejected: (state, action: PayloadAction<ErrorType>) => {
      state.kam = RemoteRecordData.reject(action.payload);
      return state;
    },
  },
});

export const {
  fetchContentEntry,
  fetchContentEntryFulfilled,
  fetchContentEntryRejected,
  fetchKAM,
  fetchKAMFulfilled,
  fetchKAMRejected,
} = slice.actions;

const getState = (state: AppState) => state.content.contentEntries;

export const selectEntryRecord = createSelector(
  [getState, (state, entryId: string) => entryId],
  (state, entryId) => new RemoteRecord<ContentEntryType>(state[entryId]),
);

export const selectEntriesRecordsByIds = createSelector(
  [getState, (state, entriesIds: string[]) => entriesIds],
  (state, entriesIds) => entriesIds.map(entryId => new RemoteRecord<ContentEntryType>(state[entryId])),
);

export const selectKAMRecord = createSelector(
  getState,
  (state) => new RemoteRecord<ContentEntryType>(state.kam),
);
