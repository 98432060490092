import { fetchCartCostCenters } from '@client/cart/redux/costCenter/redux';
import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { MY_ACCOUNT_MESSAGE, showMyAccountToast } from '@client/myAccount/common/utils/messages';
import { CostCenters } from '@client/myAccount/costCenters/api';
import {
  fetchCostCenter,
  fetchCostCenterFulfilled,
  fetchCostCenterRejected,
  removeCostCenter,
  submitCostCenter,
  submitCostCenterFulfilled,
  submitCostCenterRejected,
} from '@client/myAccount/costCenters/redux/costCenter';
import { ROUTES } from '@client/routes/components/Router/routes';
import { pushToRoute } from '@client/routes/redux/utils';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { CostCenterType, NewCostCenterResponseType } from 'b2b-common/core/account/api/costCenters/CostCenter.types';

function* fetchCostCenterSaga(action: ReturnType<typeof fetchCostCenter>):
  Generator<CallEffect | PutEffect, void, AxiosResponse<CostCenterType>> {
  const id = action.payload;

  try {
    const response = yield call(
      requestApiSaga,
      CostCenters.get(id),
    );

    yield put(fetchCostCenterFulfilled({ id, data: response.data }));
  } catch (error) {
    yield put(fetchCostCenterRejected({ id, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: { data: action.payload } }));
  }
}

function* submitCostCenterSaga(action: ReturnType<typeof submitCostCenter>):
  Generator<
  CallEffect | PutEffect | Generator,
  void,
  AxiosResponse<NewCostCenterResponseType>
> {
  const { data, id, onSubmitEndCallback } = action.payload;

  try {
    let response;
    if (id) {
      response = yield call(requestApiSaga, CostCenters.update(id, data));
    } else {
      response = yield call(requestApiSaga, CostCenters.create(data));
    }

    yield put(submitCostCenterFulfilled(response.data));
    yield put(fetchCartCostCenters());
    yield pushToRoute(ROUTES.myAccountCostCenter, { costCenterId: response.data?.uuid || id });
    showMyAccountToast(id ? MY_ACCOUNT_MESSAGE.successUpdateCostCenter : MY_ACCOUNT_MESSAGE.successCreateCostCenter);
  } catch (error) {
    yield put(submitCostCenterRejected(parseError(error)));
    showMyAccountToast(MY_ACCOUNT_MESSAGE.submitCostCenterError);
    onSubmitEndCallback(false);
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* removeCostCenterSaga(action: ReturnType<typeof removeCostCenter>):
  Generator<
    CallEffect | PutEffect | Generator,
    void,
    AxiosResponse<CostCenterType>
  > {
  const { id, onRemoveEndCallback } = action.payload;

  try {
    yield call(requestApiSaga, CostCenters.delete(id));
    yield put(fetchCartCostCenters());
    showMyAccountToast(MY_ACCOUNT_MESSAGE.successRemoveCostCenter);
    onRemoveEndCallback(true);
  } catch (error) {
    onRemoveEndCallback(false);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.errorRemoveCostCenter);
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* costCenterSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(removeCostCenter.type, removeCostCenterSaga),
    takeLatest(fetchCostCenter.type, fetchCostCenterSaga),
    takeLatest(submitCostCenter.type, submitCostCenterSaga),
  ]);
}
