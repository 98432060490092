import { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Pagination } from '@client/common/components/Pagination';
import { parse, stringify } from '@client/common/utils/queryParams';
import { useFetchNewsroomAssetsAction, useNewsroomAssetsData } from '@client/content/hooks';
import { NEWSROOM_ASSETS_PAGE_SIZE } from '@client/content/redux/newsroom/sagas';
import { useRouterPush } from '@client/routes/hooks';
import classNames from 'classnames';
import { Asset } from '../NewsroomAssets/Asset';
import { Props } from '../';

export const NewsroomAssets = ({ className }: Props): ReactElement => {
  const fetchAssets = useFetchNewsroomAssetsAction();
  const location = useLocation();
  const push = useRouterPush();
  const { data: newsData } = useNewsroomAssetsData();
  const page = +parse(location.search).page;

  useEffect(
    () => {
      fetchAssets({ page });
    },
    [page],
  );

  if (!newsData || !newsData.assets || !Array.isArray(newsData.assets) || !newsData.assets.length) {
    return null;
  }

  const handlePaginationPageChange = (nextPage: number): void => {
    push({ search: stringify({ page: nextPage }) });
  };

  return (
    <div className={classNames('cds-cms-NewsroomAssets', className)}>
      <div className="row">
        {
          newsData.assets.map(({ fileName, url, thumbnail, uid }) => (
            <Asset
              url={url}
              key={uid}
              className="col-lg-3 col-md-4 col-sm-6 col-xs-12"
              fileName={fileName}
            />
          ))
        }
      </div>

      {newsData && (
        <Pagination
          total={Math.ceil(newsData.total / NEWSROOM_ASSETS_PAGE_SIZE)}
          page={page || 1}
          onPageChange={handlePaginationPageChange}
        />
      )}
    </div>
  );
};
