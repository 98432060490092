import { ReactElement, useState } from 'react';
import { useBarcodeAnalytics } from '@client/analytics/context/barcode/BarcodeAnalyticsContext';
import { TEALIUM_PROD_ACTION } from '@client/analytics/types/types';
import { isServer } from '@client/common/utils/env';
import { useFetchProductTileByBarcodeEffect } from '@client/product/hooks';
import classNames from 'classnames';
import { Modal, MODAL_TYPE } from 'b2b-components/theme/components/Modal';
import { Props } from './CodeScannerModal.types';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const CodeScannerModalComponent = !isServer ? require('./Component').CodeScannerModal : ((): null => null);

export const CodeScannerModal = ({ isOpen, onClose, className }: Props): ReactElement => {
  const [isCodeScannerReady, setIsCodeScannerReady] = useState(false);
  const {
    barcode,
    onScanBarcode,
    onScanBarcodeClear,
    productTile,
    barcodeSearch,
  } = useFetchProductTileByBarcodeEffect();
  const isProductNotFoundVisible = barcodeSearch.isLoaded && !barcodeSearch?.data?.length;
  const isLoadedProductVisible = barcodeSearch.isLoaded && productTile.isLoaded;
  const { analyzeBarcodeScan } = useBarcodeAnalytics();

  const codeScannerModalClassName = classNames('CodeScannerModal', className);

  const handleCodeScannerReady = (): void => {
    setIsCodeScannerReady(true);
    !isCodeScannerReady && analyzeBarcodeScan({ barcodeAction: TEALIUM_PROD_ACTION.cameraSuccess });
  };

  const handleModalClose = () => {
    !isCodeScannerReady && analyzeBarcodeScan({ barcodeAction: TEALIUM_PROD_ACTION.cameraError });

    onClose();
  };

  return (
    <Modal
      className={codeScannerModalClassName}
      content={() => isOpen && CodeScannerModal && (
        <CodeScannerModalComponent
          onClose={handleModalClose}
          onReady={handleCodeScannerReady}
          barcode={barcode}
          productTile={productTile}
          isProductNotFoundVisible={isProductNotFoundVisible}
          isLoadedProductVisible={isLoadedProductVisible}
          onScan={onScanBarcode}
          onScanClear={onScanBarcodeClear}
        />
      )}
      isOpen={isOpen}
      onCloseModal={handleModalClose}
      type={MODAL_TYPE.fullscreen}
    />
  );
};
