import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData, RemoteRecordDataType } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductTilesType, ProductTileType } from 'b2b-common/core/product/api/ProductTile.types';

const initialState = {} as Record<string, RemoteRecordDataType<ProductTileType>>;

const NAME = 'tile';

export const tileSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState,
  reducers: {
    fetchProductTile: (state, action: PayloadAction<{ sku: string }>) => {
      state[action.payload.sku] = RemoteRecordData.init();
      return state;
    },
    fetchProductTileFulfilled: (state, action: PayloadAction<{ sku: string, data: ProductTileType }>) => {
      state[action.payload.sku] = RemoteRecordData.fulfill(action.payload.data);
      return state;
    },
    fetchProductTileRejected: (state, action: PayloadAction<{ sku: string, error: ErrorType }>) => {
      state[action.payload.sku] = RemoteRecordData.reject(action.payload.error);
      return state;
    },
    fetchProductTiles: (state, action: PayloadAction<{ skus: string[], groupNumber: number }>) => {
      action.payload.skus.map(
        (sku) => (state[sku] = RemoteRecordData.init()),
      );
      return state;
    },
    fetchProductTilesFulfilled: (state, action: PayloadAction<{ skus: string[], data: ProductTilesType }>) => {
      action.payload.skus.map(
        (sku) => (state[sku] = RemoteRecordData.fulfill(action.payload.data[sku])),
      );
      return state;
    },
    fetchProductTilesRejected: (state, action: PayloadAction<{ skus: string[], error: ErrorType }>) => {
      action.payload.skus.map(
        (sku) => (state[sku] = RemoteRecordData.reject(action.payload.error)),
      );
      return state;
    },
    fetchProductTileByBarcode: (state, action: PayloadAction<{ barcode: string }>) => state,
  },
});

export const {
  fetchProductTile,
  fetchProductTileFulfilled,
  fetchProductTileRejected,
  fetchProductTiles,
  fetchProductTilesFulfilled,
  fetchProductTilesRejected,
  fetchProductTileByBarcode,
} = tileSlice.actions;

const getState = (state: AppState) => state.product.products.tile;

export const selectProductsTiles = createSelector(
  [getState],
  products => Object
    .keys(products)
    .reduce(
      (productRecords, sku) => ({
        ...productRecords,
        [sku]: new RemoteRecord<ProductTileType>(products[sku]),
      }),
      {},
    ),
);

export const selectProductTileBySku = createSelector(
  [getState, (state, sku: string) => sku],
  (products, sku) => new RemoteRecord<ProductTileType>(products[sku]),
);

export const selectProductsBySkus = createSelector(
  [getState, (state, skus: string[]) => skus],
  (products, skus) => skus
    .reduce(
      (productRecords, sku) => ({
        ...productRecords,
        [sku]: new RemoteRecord<ProductTileType>(products[sku]),
      }),
      {},
    ),
);
