import { ReactElement, ReactNode } from 'react';
import { Fail } from '@client/common/components/Fail';
import { NotFoundPage } from '@client/common/components/NotFoundPage';
import { ServerFail } from '@client/common/components/ServerFail';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { isEmpty } from '@client/common/utils/empty/empty';
import { StatusCodes } from 'http-status-codes';

interface Props {
  remoteRecords: RemoteRecord<any>[];
  children: ReactNode;
  errorComponent?: ReactNode;
  shouldCheckEmptyRecord?: boolean;
  shouldOpenNotFoundPage?: boolean;
}

export const RemoteRecordError = ({
  remoteRecords,
  children,
  errorComponent,
  shouldCheckEmptyRecord,
  shouldOpenNotFoundPage,
}: Props): ReactElement => {
  if (remoteRecords.some(rr => rr.hasFailed && errorComponent)) {
    return <>{errorComponent}</>;
  }

  const notFoundError = remoteRecords.some(rr => rr.hasFailed && rr.error?.statusCode === StatusCodes.NOT_FOUND);
  if (shouldOpenNotFoundPage && notFoundError) {
    return <NotFoundPage />;
  }

  const serverError = remoteRecords.find(rr => rr.hasFailed && rr.hasServerError);
  if (serverError) {
    return <ServerFail error={serverError.error} isMessage={false} />;
  }

  const commonError = remoteRecords.find(rr => rr.hasFailed);
  if (commonError) {
    return <Fail error={commonError.error} />;
  }

  const emptyData = remoteRecords.find(rr => (rr.isLoaded || rr.hasFailed) && isEmpty(rr.data));
  if (emptyData && shouldCheckEmptyRecord) {
    return <Fail error={emptyData.error} />;
  }

  return (
    <>{children}</>
  );
};
