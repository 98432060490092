import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { RequestCreateUserType, RequestUpdateUserType } from 'b2b-common/core/account/api/User.types';

export class User {
  public static fetch(accountId: string): RequestConfigType {
    return RequestConfig.get(
      `/account/users/${accountId}`,
      {
        withLocalization: false,
      },
    );
  }

  public static create(data: RequestCreateUserType): RequestConfigType {
    return RequestConfig.post(
      '/account/users',
      data,
      {
        withLocalization: false,
      },
    );
  }

  public static update(
    accountId: string,
    data: RequestUpdateUserType,
  ): RequestConfigType {
    return RequestConfig.put(
      `/account/users/${accountId}`,
      data,
      {
        withLocalization: false,
      },
    );
  }

  public static remove(accountId: string): RequestConfigType {
    return RequestConfig.delete(
      `/account/users/${accountId}`,
      {
        withLocalization: false,
      },
    );
  }
}
