import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { SendEmail } from '@client/myAccount/invoices/api';
import {
  sendInvoiceToEmail,
  sendInvoiceToEmailFulfilled,
  sendInvoiceToEmailRejected,
} from '@client/myAccount/invoices/redux/sendEmail/redux';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';

function* sendSaga(action: ReturnType<typeof sendInvoiceToEmail>): Generator<CallEffect | PutEffect, void> {
  const { email, id } = action.payload;

  try {
    yield call(requestApiSaga, SendEmail.send(id, email));
    yield put(sendInvoiceToEmailFulfilled());
  } catch (error) {
    yield put(sendInvoiceToEmailRejected(error as ErrorType));
    yield put(reportError({ message: action.type, error }));
  }
}

export function* sendEmailSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeEvery(sendInvoiceToEmail.type, sendSaga),
  ]);
}
