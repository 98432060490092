import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Page } from '@client/app/components/App/Page';
import { InfoView } from '@client/common/components/InfoView';
import { useTranslate } from '@client/i18n/hooks';
import { useOrderRequestActions } from '@client/myAccount/costCenters/hooks';
import { ROUTES } from '@client/routes/components/Router/routes';
import { useRouterPush, useUrlCreator } from '@client/routes/hooks';
import { useBreadcrumbs } from 'b2b-apps/core/breadcrumbs/hooks';
import ConfirmationImg from './assets/confirmation.svg';
import FailImg from './assets/fail.svg';

export enum ConfirmationStatus {
  approved = 'approved',
  rejected = 'rejected',
  sentToSales = 'sent_to_sales'
}

export const ConfirmationPage = (): ReactElement | null => {
  const { id, status } = useParams<{ id: string, status: ConfirmationStatus }>();
  const t = useTranslate();
  const push = useRouterPush();
  const urlCreator = useUrlCreator();
  useBreadcrumbs([]);

  const { approvedRecord } = useOrderRequestActions(id);
  const { rejectedRecord } = useOrderRequestActions(id);
  const { sendToSalesRecord } = useOrderRequestActions(id);

  const buttons = {
    primary: {
      content: t('Back to order requests'),
      onClick: () => push(urlCreator(ROUTES.myAccountCostCenterOrderRequestList)),
    },
  };

  if (status === ConfirmationStatus.approved) {
    const email = approvedRecord.data.requesterEmail;

    return (
      <Page title={t('Confirmation')}>
        <InfoView
          buttons={buttons}
          image={ConfirmationImg}
          title={t('Thank you for placing order')}
          message={(
            <>
              <div>{t('Order has been successfully placed.')}</div>
              {!!email && <div>{t('We’ll shortly send a confirmation email to {email}', { email })}</div>}
            </>
          )}
        />
      </Page>
    );
  }

  if (status === ConfirmationStatus.rejected) {
    const email = rejectedRecord.data.requesterEmail;

    return (
      <Page title={t('Confirmation')}>
        <InfoView
          buttons={buttons}
          image={FailImg}
          title={t('Order request has been rejected.')}
          message={(
            <>
              <div>{t('Order hasn’t been placed.')}</div>
              {!!email && <div>{t('We’ll shortly send a status update email to {email}', { email })}</div>}
            </>
          )}
        />
      </Page>
    );
  }

  if (status === ConfirmationStatus.sentToSales) {
    const email = sendToSalesRecord.data.requesterEmail;

    return (
      <Page title={t('Confirmation')}>
        <InfoView
          buttons={buttons}
          image={ConfirmationImg}
          title={t('Order request sent for manual processing.')}
          message={(
            <>
              <div>{t('Order request has been sent to Sales Department for further processing.')}</div>
              {!!email && <div>{t('After it’s processed we’ll send a confirmation email to {email}', { email })}</div>}
            </>
          )}
        />
      </Page>
    );
  }

  return null;
};
