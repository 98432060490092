import { ReactElement } from 'react';
import { ProductsAnalyticsProvider } from '@client/analytics/context/products/ProductsAnalyticsContext';
import { usePageViewAnalytics } from '@client/analytics/hooks';
import { EVENT_SOURCE, TEALIUM_PAGE_TYPE } from '@client/analytics/types/types';
import { Page } from '@client/app/components/App/Page';
import { useBreadcrumbs } from '@client/breadcrumbs/hooks';
import { ContentBlock } from '@client/content/components/ContentBlock';
import { Full } from '@client/content/components/ModularPage/Layouts/Full';
import { Sidebar } from '@client/content/components/ModularPage/Layouts/Sidebar';
import { getComponentsFromModules, getNewsroomComponents } from '@client/content/hooks';
import { getContentPageType } from '@client/content/utils/contentSelectors';
import { getHeadData } from '@client/content/utils/headData';
import { resolveModules } from '@client/content/utils/modules';
import { generateNewsroomBreadcrumbs, NEWSROOM_MODULES_NAMES } from '@client/content/utils/newsroom';
import { useActiveLanguage, useTranslate } from '@client/i18n/hooks';
import classNames from 'classnames';
import {
  CMS_NEWSROOM_PAGE_ID,
  getIsContentTypeDownloadCenter,
  getIsContentTypeNewsroom,
  getIsContentTypeNewsroomCenter,
  getIsContentTypeNewsroomPage,
} from 'b2b-common/core/content/utils/utils';
import { Props } from './ModularPage.types';

export const ModularPage = ({ content, title, entryId }: Props): ReactElement | null => {
  const lang = useActiveLanguage();
  const t = useTranslate();
  const { data } = content;
  const { contentTypeId, loadedContentTypeIds } = data;
  const headData = getHeadData({
    metaDescription: data.meta_description,
    globalHelperSocialMedia: data.global__helper_socialmedia,
    shopMediaDescription: t(
      'Shop meta description',
      {},
      'Used as default page description',
    ),
  });

  const isNewsroomPage = getIsContentTypeNewsroom(contentTypeId);
  const isSingleNewsroomPage = contentTypeId === CMS_NEWSROOM_PAGE_ID.singleNews;
  const isCustomDownloadPage = contentTypeId === CMS_NEWSROOM_PAGE_ID.downloadPage;
  const isContentTypeNewsroomPage = getIsContentTypeNewsroomPage(contentTypeId);
  const isContentTypeNewsroomCenter = getIsContentTypeNewsroomCenter(contentTypeId);
  const isContentTypeDownloadCenter = getIsContentTypeDownloadCenter(contentTypeId);

  const sidebarComponents = data.container__sidebar
    ? getComponentsFromModules(resolveModules(data.container__sidebar))
    : [];
  const headerComponents = data?.container__top_banner
    ? getComponentsFromModules(resolveModules(data.container__top_banner))
    : [];
  const newsroomHeader = isContentTypeNewsroomPage
    ? getNewsroomComponents([{
      componentName: NEWSROOM_MODULES_NAMES.newsroomHeader,
      componentData: { ...data, lang },
    }])
    : [];
  const newsroomFooter = isContentTypeNewsroomPage
    ? getNewsroomComponents([{
      componentName: NEWSROOM_MODULES_NAMES.newsroomFooter,
      componentData: loadedContentTypeIds,
    }])
    : [];
  const newsroomMain = isContentTypeNewsroomCenter
    ? getNewsroomComponents([{ componentName: NEWSROOM_MODULES_NAMES.newsroomCenter }])
    : isContentTypeDownloadCenter
      ? getNewsroomComponents([{ componentName: NEWSROOM_MODULES_NAMES.downloadCenter }])
      : [];

  const isTitleHidden = data.hide_title
    ? !!parseInt(data.hide_title, 10)
    : isSingleNewsroomPage || isCustomDownloadPage;
  const mptId = data.global__helper_mpt_id?.[0]?.mpt_id;

  const breadcrumbs = isNewsroomPage ? generateNewsroomBreadcrumbs(data, t('Newsroom'), lang) : [];
  useBreadcrumbs(breadcrumbs);

  usePageViewAnalytics({
    pageType: entryId ? TEALIUM_PAGE_TYPE.staticPage : TEALIUM_PAGE_TYPE.homepage,
    remoteRecord: content,
    data: {
      page: {
        id: `${entryId || ''}`,
      },
      contentPageType: getContentPageType(content),
    },
  });

  return (
    <ProductsAnalyticsProvider eventSource={EVENT_SOURCE.cms}>
      <Page
        title={title || headData.title || data.title || ''}
        metaTags={headData.metaTags}
        mptId={mptId}
        className={classNames(
          'cds-cms-Container',
          'cds-cms-ModularPage',
          'cds-cms-TextMediaContainer',
          'Content--CMS',
        )}
      >
        {!isTitleHidden && <h1 className='PageTitle'>{data.title}</h1>}

        {sidebarComponents.length > 0 && (
          <Sidebar header={headerComponents} sidebar={sidebarComponents}>
            {newsroomHeader.length > 0 && newsroomHeader}
            <ContentBlock content={content} />
            {newsroomMain.length > 0 && newsroomMain}
            {newsroomFooter.length > 0 && newsroomFooter}
          </Sidebar>
        )}

        {sidebarComponents.length === 0 && (
          <Full className='cds-cms-LayoutBlock--Full' header={headerComponents}>
            {newsroomHeader.length > 0 && newsroomHeader}
            <ContentBlock content={content} />
            {newsroomMain.length > 0 && newsroomMain}
            {newsroomFooter.length > 0 && newsroomFooter}
          </Full>
        )}
      </Page>
    </ProductsAnalyticsProvider>
  );
};
