import { config } from '@client/config';
import { ROUTES, RouteType } from '@client/routes/components/Router/routes';
import { ICON_NAME } from 'b2b-components/theme/components/Icon';
import { PERMISSION } from 'b2b-common/core/context/Context.types';

export enum MY_ACCOUNT_MENU_ITEM {
  dashboard = 'dashboard',
  balanceDetails = 'balanceDetails',
  orders = 'orders',
  deliveredOrders = 'deliveredOrders',
  invoices = 'invoices',
  creditNotes = 'creditNotes',
  accountDetails = 'accountDetails',
  addresses = 'addresses',
  usersAndRoles = 'usersAndRoles',
  wishlists = 'wishlists',
  rma = 'rma',
  newsletterSettings = 'newsletterSettings',
  costCenters = 'costCenters',
  orderRequests = 'orderRequests',
}

export enum MY_ACCOUNT_DASHBOARD_GROUP {
  dashboard = 'dashboard',
  procurement = 'procurement',
  account = 'account',
}

export enum MY_ACCOUNT_MENU_LINKS_TYPE {
  navigation = 'navigation',
  dropDown = 'dropDown',
}

export type MyAccountMenuConfigItemType = {
  route: RouteType;
  routeParams?: Record<string, any>;
  permissionsRequired: string[];
  icon?: string;
};

export type MyAccountMenuConfigType = Record<MY_ACCOUNT_MENU_ITEM, MyAccountMenuConfigItemType>;

export type MyAccountDashboardConfigType = Record<
  MY_ACCOUNT_DASHBOARD_GROUP,
  { [key in MY_ACCOUNT_MENU_ITEM]?: MyAccountMenuConfigItemType }
>;

export const MY_ACCOUNT_MENU_CONFIG: MyAccountMenuConfigType = Object.freeze({
  [MY_ACCOUNT_MENU_ITEM.dashboard]: {
    route: ROUTES.myAccount,
    permissionsRequired: [PERMISSION.accessMyAccountDashboard],
    icon: ICON_NAME.dashboard,
  },

  [MY_ACCOUNT_MENU_ITEM.balanceDetails]: {
    route: ROUTES.myAccountBalanceDetails,
    permissionsRequired: [PERMISSION.accessMyAccountBasic],
    icon: ICON_NAME.balance,
  },

  [MY_ACCOUNT_MENU_ITEM.orders]: {
    route: ROUTES.myAccountOrders,
    routeParams: {
      page: 1,
    },
    permissionsRequired: [PERMISSION.accessMyAccountBasic],
    icon: ICON_NAME.clock,
  },

  [MY_ACCOUNT_MENU_ITEM.deliveredOrders]: {
    route: ROUTES.myAccountDeliveredOrders,
    routeParams: {
      page: 1,
    },
    permissionsRequired: [PERMISSION.accessMyAccountBasic],
    icon: ICON_NAME.boxCheck,
  },

  [MY_ACCOUNT_MENU_ITEM.invoices]: {
    route: ROUTES.myAccountInvoices,
    routeParams: {
      page: 1,
    },
    permissionsRequired: [PERMISSION.accessMyAccountBasic],
    icon: ICON_NAME.invoice,
  },

  [MY_ACCOUNT_MENU_ITEM.creditNotes]: {
    route: ROUTES.myAccountCredits,
    routeParams: {
      page: 1,
    },
    permissionsRequired: [PERMISSION.accessMyAccountBasic],
    icon: ICON_NAME.wallet,
  },

  [MY_ACCOUNT_MENU_ITEM.accountDetails]: {
    route: ROUTES.myAccountDetails,
    permissionsRequired: [PERMISSION.accessMyAccountBasic],
    icon: ICON_NAME.user,
  },

  [MY_ACCOUNT_MENU_ITEM.addresses]: {
    route: ROUTES.myAccountAddresses,
    routeParams: {
      page: 1,
    },
    permissionsRequired: [PERMISSION.accessMyAccountAddresses],
    icon: ICON_NAME.house,
  },

  [MY_ACCOUNT_MENU_ITEM.usersAndRoles]: {
    route: ROUTES.myAccountUsers,
    routeParams: {
      page: 1,
    },
    permissionsRequired: [PERMISSION.accessMyAccountUserManagement],
    icon: ICON_NAME.userGroup,
  },

  [MY_ACCOUNT_MENU_ITEM.wishlists]: {
    route: ROUTES.myAccountWishlists,
    routeParams: {
      page: 1,
    },
    permissionsRequired: [PERMISSION.accessWishlist],
    icon: ICON_NAME.wishlistSolid,
  },

  [MY_ACCOUNT_MENU_ITEM.rma]: {
    route: ROUTES.myAccountRmas,
    permissionsRequired: [PERMISSION.accessMyAccountRma],
    icon: ICON_NAME.rmaSolid,
  },

  [MY_ACCOUNT_MENU_ITEM.newsletterSettings]: {
    route: ROUTES.myAccountNewsletters,
    permissionsRequired: [PERMISSION.accessMyAccountBasic, PERMISSION.accessMyAccountNewsletters],
    icon: ICON_NAME.newsletter,
  },

  [MY_ACCOUNT_MENU_ITEM.costCenters]: {
    route: ROUTES.myAccountCostCenters,
    routeParams: {
      page: 1,
    },
    permissionsRequired: [PERMISSION.accessMyAccountBasic],
    icon: ICON_NAME.costCenter,
  },

  [MY_ACCOUNT_MENU_ITEM.orderRequests]: {
    route: ROUTES.myAccountCostCenterOrderRequestList,
    routeParams: {
      page: 1,
    },
    permissionsRequired: [PERMISSION.accessMyAccountBasic],
    icon: ICON_NAME.request,
  },
});

export const MY_ACCOUNT_DASHBOARD_CONFIG: MyAccountDashboardConfigType = Object.freeze({
  [MY_ACCOUNT_DASHBOARD_GROUP.dashboard]: {
    [MY_ACCOUNT_MENU_ITEM.dashboard]: MY_ACCOUNT_MENU_CONFIG.dashboard,
    [MY_ACCOUNT_MENU_ITEM.orders]: MY_ACCOUNT_MENU_CONFIG.orders,
    [MY_ACCOUNT_MENU_ITEM.deliveredOrders]: MY_ACCOUNT_MENU_CONFIG.deliveredOrders,
    [MY_ACCOUNT_MENU_ITEM.invoices]: MY_ACCOUNT_MENU_CONFIG.invoices,
    [MY_ACCOUNT_MENU_ITEM.wishlists]: MY_ACCOUNT_MENU_CONFIG.wishlists,
    [MY_ACCOUNT_MENU_ITEM.rma]: MY_ACCOUNT_MENU_CONFIG.rma,
  },
  [MY_ACCOUNT_DASHBOARD_GROUP.procurement]: {
    ...config.features.procurement
      ? { [MY_ACCOUNT_MENU_ITEM.costCenters]: MY_ACCOUNT_MENU_CONFIG.costCenters }
      : {},
    ...config.features.procurement
      ? { [MY_ACCOUNT_MENU_ITEM.orderRequests]: MY_ACCOUNT_MENU_CONFIG.orderRequests }
      : {},
  },
  [MY_ACCOUNT_DASHBOARD_GROUP.account]: {
    [MY_ACCOUNT_MENU_ITEM.accountDetails]: MY_ACCOUNT_MENU_CONFIG.accountDetails,
    [MY_ACCOUNT_MENU_ITEM.usersAndRoles]: MY_ACCOUNT_MENU_CONFIG.usersAndRoles,
    [MY_ACCOUNT_MENU_ITEM.addresses]: MY_ACCOUNT_MENU_CONFIG.addresses,
    [MY_ACCOUNT_MENU_ITEM.balanceDetails]: MY_ACCOUNT_MENU_CONFIG.balanceDetails,
    [MY_ACCOUNT_MENU_ITEM.creditNotes]: MY_ACCOUNT_MENU_CONFIG.creditNotes,
    [MY_ACCOUNT_MENU_ITEM.newsletterSettings]: MY_ACCOUNT_MENU_CONFIG.newsletterSettings,
  },
});

export const MY_ACCOUNT_BACK_PAGE = 'backPage';
