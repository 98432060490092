import { Logger } from '@client/common/utils/logger/logger.types';

export enum LOGGER_LEVEL {
  log = 'log',
  info = 'info',
  warn = 'warn',
  error = 'error',
  debug = 'debug',
}

export let logger: Logger = {
  log: () => undefined,
  info: () => undefined,
  warn: () => undefined,
  error: () => undefined,
  debug: () => undefined,
};

export const setLogger = (currentLogger: Logger): void => {
  logger = currentLogger;
};
