import { ReactElement } from 'react';
import { useTranslate } from '@client/i18n/hooks';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Body } from './Body';
import { FIELD_NAME, Props } from './Form.types';

export const Form = ({
  onSubmit,
  initValue,
  onBack,
}: Props): ReactElement => {
  const t = useTranslate();

  const validationSchema = yup.object().shape({
    [FIELD_NAME.newWishlistName]: yup.mixed().required(t('Field cannot be empty')),
  });

  return (
      <Formik
        initialValues={{
          [FIELD_NAME.newWishlistName]: initValue || '',
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        <Body onBack={onBack} />
      </Formik>
  );
};
