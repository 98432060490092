import { useSelector } from 'react-redux';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { AppState } from '@client/common/redux/store';
import { selectEntriesRecordsByIds } from '@client/content/redux/contentEntries/redux';
import { ContentEntryType } from 'b2b-common/core/content/Content.types';

export type UseEntriesReturnType = Array<RemoteRecord<ContentEntryType>>;

export const useEntries = (entriesIds: string[]): UseEntriesReturnType => useSelector(
  (state: AppState) => selectEntriesRecordsByIds(state, entriesIds),
);
