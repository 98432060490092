import { userSlice } from '@client/myAccount/users/redux/user/redux';
import { usersSlice } from '@client/myAccount/users/redux/users/redux';
import { usersSearchSlice } from '@client/myAccount/users/redux/usersSearch/redux';
import { combineSlices } from '@reduxjs/toolkit';

export const usersReducer = combineSlices(
  userSlice,
  usersSlice,
  usersSearchSlice,
);
