import { ReactElement, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { InvalidPermissionsPage } from '@client/common/components/InvalidPermissionsPage';
import { UnloggedUserPage } from '@client/common/components/UnloggedUserPage';
import { createKey } from '@client/common/utils/key/key';
import { config } from '@client/config';
import { useEntriesMap } from '@client/content/hooks';
import { useUserData } from '@client/context/hooks';
import { useActiveLanguage } from '@client/i18n/hooks';
import { getAvailableLanguages } from '@client/i18n/utils/language';
import { FallbackLoader } from '@client/routes/components/Router/FallbackLoader';
import { useAccessCallbackData } from '@client/routes/hooks';
import { mapEntriesToRoutes } from '@client/routes/utils/mapEntriesToRoutes';
import { getLocation, RouterRootState } from 'connected-react-router';
import { Location } from 'history';
import { RemoteRecordLoading } from 'b2b-apps/core/common/components/RemoteRecordHandler';
import { LANGUAGE_CODE } from 'b2b-common/core/i18n/consts';
import { REDIRECTIONS } from './redirections';
import { Router } from './Router';
import { RedirectionType, ROUTES, RouteType } from './routes';

export const RouterContainer = (props: object): ReactElement => {
  const contentEntriesMap = useEntriesMap();
  const defaultLanguage = useActiveLanguage() || config.languages.default;
  const accessCallbackData = useAccessCallbackData();
  const { isLoggedIn } = useUserData();
  const location: Location = useSelector((state: RouterRootState) => getLocation(state));

  const renderWhenNotAllowed = (isLoggedIn: boolean) => function renderComponent() {
    if (!isLoggedIn) {
      return <UnloggedUserPage />;
    }
    return <InvalidPermissionsPage />;
  };

  const getRedirections = (): ReactElement[] => REDIRECTIONS.reduce<ReactElement[]>(
    (redirections, { when, from, ...redirection }: RedirectionType) => {
      if (when(accessCallbackData)) {
        const fromPaths = Array.isArray(from) ? from : [from];

        fromPaths.forEach((fromPath) => redirections.push(
          <Redirect
            {...redirection}
            from={fromPath}
            key={createKey('redirection', fromPath, redirection.to)}
          />,
        ));
      }
      return redirections;
    },
    [],
  );

  const getLocalisedRoutes = (language: string, routes: Record<string, RouteType>):
    ReactElement[] => Object.entries(routes).map(([routeKey, route]) => {
    const {
      allow = () => true,
      paths,
      component: Component,
      render,
      ...restRoute
    } = route;

    const path = paths[language];

    if (!path) {
      return null;
    }

    const routeConfig = {
      ...restRoute,
      path,
      key: createKey('route', routeKey, paths[language], language),
      render: function renderComponent(routeProps: any) {
        if (Component) {
          return <Component {...routeProps} />;
        }
        if (render) {
          return render(routeProps);
        }
        return null;
      },
    };

    if (!allow(accessCallbackData)) {
      routeConfig.render = renderWhenNotAllowed(isLoggedIn);
    }

    const { key, ...rest } = routeConfig;
    return <Route key={key} {...rest} />;
  }).filter((route): route is ReactElement => route !== null);

  const fixingRedirections = (): ReactElement[] => Object.values(ROUTES).reduce<ReactElement[]>(
    (redirections, route) => {
      const { paths, exact } = route;

      if (paths.fix) {
        redirections.push(
          <Redirect
            exact={exact}
            from={paths.fix}
            to={{
              pathname: paths[defaultLanguage],
              search: location.search,
            }}
            key={createKey('redirect', paths.fix)}
          />,
        );
      }

      return redirections;
    }, [],
  );

  const contentRoutes = contentEntriesMap.isLoaded ? mapEntriesToRoutes(contentEntriesMap.data) : {};

  const routes = [
    ...getRedirections(),
    ...getAvailableLanguages().reduce<ReactElement[]>(
      (acc, languageCode: LANGUAGE_CODE) => ([
        ...acc,
        ...getLocalisedRoutes(languageCode, ROUTES),
      ]),
      [],
    ),
    ...fixingRedirections(),
    ...getAvailableLanguages().reduce<ReactElement[]>(
      (acc, languageCode: LANGUAGE_CODE) => ([
        ...acc,
        ...getLocalisedRoutes(languageCode, contentRoutes),
      ]),
      [],
    ),
  ];

  return (
    <RemoteRecordLoading remoteRecords={[contentEntriesMap]} showOnRefresh={false}>
      <Suspense fallback={<FallbackLoader />}>
        <Router
          {...props}
          contentEntriesMap={contentEntriesMap}
          routes={routes}
        />
      </Suspense>
    </RemoteRecordLoading>
  );
};
