import { ReactElement } from 'react';
import BrandLogo from '@client/common/components/Logo/assets/logo.svg';
import { useActiveLanguage } from '@client/i18n/hooks';
import classNames from 'classnames';
import { Props } from './';
import './Logo.scss';

export const Logo = ({ shop, className }: Props): ReactElement => {
  const activeLanguage = useActiveLanguage();
  const rootClassName = classNames('Logo', className);

  return (
    <a className={rootClassName} aria-label={`${shop}`} href={`/${activeLanguage}/`}>
      <img src={BrandLogo} alt={`${shop}`} className='cds-ImgFluid' />
    </a>
  );
};
