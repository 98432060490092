import { MouseEvent, ReactElement } from 'react';
import { config } from '@client/config';
import { useEntry } from '@client/content/hooks';
import { useActiveLanguage, useChangeLanguageAction } from '@client/i18n/hooks';
import { getAvailableLanguages, LANGUAGE_LABELS } from '@client/i18n/utils/language';
import { useRouterPush } from '@client/routes/hooks';
import { openLink } from '@client/routes/utils/url';
import { TopBarGroupType } from 'b2b-components/theme/components/Header/TopBar';
import { TopBar } from 'b2b-apps/core/app/components/App/Header/TopBar/TopBar';
import { LANGUAGE_CODE } from 'b2b-common/core/i18n/consts';
import { Props } from './TopBarContainer.types';

export const TopBarContainer = ({ className }: Props): ReactElement => {
  const MAX_LANGUAGE_COUNT = 3;
  const push = useRouterPush();
  const activeLanguage = useActiveLanguage();
  const changeLanguage = useChangeLanguageAction();
  const content = useEntry(config.contentStackIds.header);
  const parsedLanguages = getAvailableLanguages().map((languageCode: LANGUAGE_CODE) => ({
    code: languageCode,
    label: LANGUAGE_LABELS[languageCode],
  }));

  const linksGroups = !content.isLoaded || !content.data || !Array.isArray(content.data.group)
    ? []
    : content.data.group.map((group: TopBarGroupType<LANGUAGE_CODE>) => ({
      name: group.name,
      links: group.links,
    }));

  const handleLinkClick = (url: string, event?: MouseEvent): void => {
    openLink(url, push, event);
  };

  return (
    <TopBar
      className={className}
      linksGroups={linksGroups}
      onLinkClick={handleLinkClick}
      activeLanguage={activeLanguage}
      languages={parsedLanguages}
      onLanguageChange={changeLanguage}
      isDropdown={parsedLanguages.length >= MAX_LANGUAGE_COUNT}
    />
  );
};
