import { ReactElement } from 'react';
import { SUGGESTION_TYPE } from '@client//app/components/App/Header/Autosuggest';
import { PrimaryLayout } from '@lib/components/PrimaryLayout';
import { Props } from './Autosuggest.types';
import { Categories } from './Categories';
import { Products } from './Products';
import { SearchSuggestions } from './SearchSuggestions';
import '@client/app/components/App/Header/Autosuggest/Autosuggest.scss';

export const Autosuggest = ({
  activeItem,
  autosuggestRefCallback,
  getCategoryUrl,
  getProductUrl,
  getSearchSuggestionUrl,
  isAutosuggestOpened,
  onAutosuggestContentClick,
  onCategoryClick,
  onCloseAutosuggest,
  onMouseEnter,
  onProductClick,
  onProductAddToCart,
  onSearchSuggestionClick,
  suggestions,
  style,
}: Props): ReactElement | null => {
  const { data } = suggestions;

  if (
    !isAutosuggestOpened
    || !data
    || ((!Array.isArray(data.facets.facet) || data.facets.facet.length === 0)
      && (!Array.isArray(data.suggestions) || data.suggestions.length === 0)
      && (!Array.isArray(data.products) || data.products.length === 0))
  ) {
    return null;
  }

  return (
    <div className='Autosuggest' data-testid='autosuggest'>
      <PrimaryLayout className='Autosuggest-Container'>
        <div
          className='Autosuggest-Content'
          onClick={onAutosuggestContentClick}
          ref={autosuggestRefCallback}
          data-testid='autosuggestContent'
          style={style}
        >
          <div className='Autosuggest-Suggestions'>
            <Categories
              activeItemIndex={activeItem.type === SUGGESTION_TYPE.category ? activeItem.index : null}
              facets={data.facets}
              getCategoryUrl={getCategoryUrl}
              onCategoryClick={onCategoryClick}
              onMouseEnter={onMouseEnter}
              term={data.term}
            />

            <SearchSuggestions
              activeItemIndex={activeItem.type === SUGGESTION_TYPE.search ? activeItem.index : null}
              getSearchSuggestionUrl={getSearchSuggestionUrl}
              onMouseEnter={onMouseEnter}
              onSearchSuggestionClick={onSearchSuggestionClick}
              suggestions={data.suggestions}
            />
          </div>

          <Products
            activeItemIndex={activeItem.type === SUGGESTION_TYPE.product ? activeItem.index : null}
            getProductUrl={getProductUrl}
            onMouseEnter={onMouseEnter}
            onProductClick={onProductClick}
            onProductAddToCart={onProductAddToCart}
            products={data.products}
            term={data.term}
            onCloseAutosuggest={onCloseAutosuggest}
          />
        </div>
      </PrimaryLayout>
    </div>
  );
};
