import { ReactElement } from 'react';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { ModulesType } from '@client/content/components/ModularPage';
import { getComponentsFromModules } from '@client/content/hooks';
import { resolveModules } from '@client/content/utils/modules';
import { ContentEntryType } from 'b2b-common/core/content/Content.types';

interface Props {
  content: RemoteRecord<ContentEntryType>;
}

export const ContentBlock = ({ content }: Props): ReactElement => {
  const getModules = (): ModulesType => {
    const { isLoaded, data } = content;

    if (!isLoaded || !data) {
      return [];
    }

    if (data.container__main_content) {
      return resolveModules(data.container__main_content);
    }

    if (data.content) {
      const moduleData = {
        text_media: {
          title: data.title,
          text: data.content,
        },
      };

      return resolveModules(moduleData);
    }

    return [];
  };

  return (
    <>
      {getComponentsFromModules(getModules())}
    </>
  );
};
