import { lazy } from 'react';
import { getLocalisedPaths } from '@client/routes/utils/paths';
import { PERMISSION } from 'b2b-common/core/context/Context.types';
import { AUTHENTICATED_ROUTE, DEFAULT_ROUTE } from '../constants';
import { ORDERS_ROUTES_NAMES, OrdersRouteType } from './types';

export const orders: OrdersRouteType = {
  [ORDERS_ROUTES_NAMES.myAccountOrders]: {
    ...AUTHENTICATED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/orders/:page?'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/orders" */
      '@client/myAccount/orders/components/ListPage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }) => isLoggedIn
      && permissionsValidator([PERMISSION.accessMyAccountBasic]),
  },
  [ORDERS_ROUTES_NAMES.myAccountOrder]: {
    ...AUTHENTICATED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/order/:orderId'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/order" */
      '@client/myAccount/orders/components/ViewPage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }) => isLoggedIn
      && permissionsValidator([PERMISSION.accessMyAccountBasic]),
  },
  [ORDERS_ROUTES_NAMES.myAccountDeliveredOrders]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/delivered-orders/:page?'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/delivered-orders" */
      '@client/myAccount/deliveredOrders/components/ListPage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }) => isLoggedIn
      && permissionsValidator([PERMISSION.accessMyAccountBasic]),
  },
};
