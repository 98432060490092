export enum META_TAGS {
  description = 'description',
  metaDescription = 'meta:description',
  ogDescription = 'og:description',
  ogImage = 'og:image',
  ogImageAlt = 'og:image:alt',
  ogSitename = 'og:sitename',
  ogTitle = 'og:title',
  ogType = 'og:type',
  ogUrl = 'og:url',
  title = 'title',
  twitterCard = 'twitter:card',
  twitterDescription = 'twitter:description',
  twitterImage = 'twitter:image',
  twitterImageAlt = 'twitter:image:alt',
  twitterSite = 'twitter:site',
  twitterTitle = 'twitter:title',
}
