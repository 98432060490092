import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from '@client/i18n/hooks';
import { selectAppVersionModal } from '@client/settings/redux/redux';
import { ModalButtons } from 'b2b-components/core/components/Modal';
import { ICON_NAME, ICON_SIZE } from 'b2b-components/theme/components/Icon';
import { Modal } from 'b2b-components/theme/components/Modal';

const WAIT_TIME = 2 * 60 * 1000;

export const AppVersionModal = (): ReactElement | null => {
  const t = useTranslate();
  const versionMismatch = useSelector(selectAppVersionModal);
  const [open, setOpen] = useState(false);
  let globalTimeout: NodeJS.Timeout;

  const handleClose = () => {
    setOpen(false);

    globalTimeout = setTimeout(() => {
      setOpen(true);
    }, WAIT_TIME);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (versionMismatch) {
      timeout = setTimeout(() => {
        setOpen(true);
      }, 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [versionMismatch]);

  const handleReload = () => {
    window.location.reload();
    clearTimeout(globalTimeout);
  };

  return (
    <Modal
      isOpen={open}
      onCloseModal={handleClose}
      title={t('Website update detected')}
      hideCloseButton
      preventClose
      content={() => (
        <>
          {t('We’ve updated our website and the page you’re on might not work as expected. Please refresh this page to avoid errors.')}
          <ModalButtons
            primary={{
              label: t('Refresh now'),
              onClick: handleReload,
              icon: ICON_NAME.refresh,
              iconSize: ICON_SIZE.small,
            }}
            cancel={{
              label: t('Remind me in 2 minutes'),
              onClick: handleClose,
            }}
          />
        </>
      )}
    />
  );
};
