import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ComparedProduct } from 'b2b-common/core/product/api/ProductsCompare.types';
import { ComparisonBarProduct } from './types';

const NAME = 'productsCompare';

export const productsCompareSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: RemoteRecordData.getDefault(),
  reducers: {
    fetchProductsCompare: (state, action: PayloadAction<{ skus: string[] }>) => {
      state = RemoteRecordData.init();
      return state;
    },
    fetchProductsCompareFulfilled: (state, action: PayloadAction<{ data: ComparedProduct[] }>) => {
      state = RemoteRecordData.fulfill(action.payload.data);
      return state;
    },
    fetchProductsCompareRejected: (state, action: PayloadAction<{ error: ErrorType }>) => {
      state = RemoteRecordData.reject(action.payload.error);
      return state;
    },
    addProductToCompare: (state, action: PayloadAction<{ product: ComparisonBarProduct }>) => state,
    removeProductFromCompare: (state, action: PayloadAction<{ sku: string }>) => state,
  },
});

export const clearProductsCompare = createAction('clearProductsCompare');

export const {
  fetchProductsCompare,
  fetchProductsCompareFulfilled,
  fetchProductsCompareRejected,
  addProductToCompare,
  removeProductFromCompare,
} = productsCompareSlice.actions;

const getState = (state: AppState) => state.product.productsCompare;

export const selectProductsCompareData = createSelector(
  getState,
  (state) => new RemoteRecord<ComparedProduct[]>(state),
);
