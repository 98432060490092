import { retryRequestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Orders } from '@client/myAccount/orders/api';
import { fetchOrders, fetchOrdersFulfilled, fetchOrdersRejected } from '@client/myAccount/orders/redux/orders/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { OrdersType } from 'b2b-common/core/account/api/orders';

function* fetchOrdersSaga(action: ReturnType<typeof fetchOrders>):
  Generator<CallEffect | PutEffect, void, AxiosResponse<OrdersType>> {
  const { pageSize, startItem } = action.payload;

  try {
    const response = yield call(retryRequestApiSaga, Orders.fetch(pageSize, startItem));

    yield put(fetchOrdersFulfilled(response.data));
  } catch (error) {
    yield put(fetchOrdersRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* ordersSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(fetchOrders.type, fetchOrdersSaga),
  ]);
}
