import { ReactElement, useId } from 'react';
import { withField } from '@client/common/components/Form/withField';
import { STYLE_TYPE, TextArea } from 'b2b-components/theme/components/Form/TextArea';
import { Props } from './FormFieldTextArea.types';

export const FormFieldTextArea = withField((props: Props): ReactElement => {
  const {
    isHotJarSuppressed = true,
    innerComponentProps,
    field,
    form,
    ...otherProps
  } = props;
  const textAreaId = useId();

  return <TextArea
      textareaId={textAreaId}
      {...(isHotJarSuppressed && { dataAttributes: { 'data-hj-suppress': true } })}
      {...innerComponentProps}
      {...field}
      {...otherProps}
    />;
});

export { STYLE_TYPE as TEXTAREA_STYLE_TYPE };
