import { addresses } from './addresses';
import { costCenter } from './costCenter';
import { dashboard } from './dashboard';
import { details } from './details';
import { finance } from './finance';
import { newsletter } from './newsletter';
import { orders } from './orders';
import { rmas } from './rmas';
import { ACCOUNT_ROUTES_NAMES, AccountRouteType } from './types';
import { users } from './users';
import { wishlist } from './wishlist';


export const account: AccountRouteType = {
  ...dashboard,
  ...finance,
  ...orders,
  ...details,
  ...addresses,
  ...users,
  ...wishlist,
  ...newsletter,
  ...rmas,
  ...costCenter,
};

export { ACCOUNT_ROUTES_NAMES };
