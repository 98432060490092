import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RmaSearchType, RmasType } from 'b2b-common/core/account/api/rma';


export const rmasSlice = createSlice({
  name: 'rmas',
  initialState: {
    rmas: RemoteRecordData.getDefault(),
    search: RemoteRecordData.getDefault(),
  },
  reducers: {
    fetchRmas: (state, action: PayloadAction<{
      pageSize: number;
      startItem: number;
    }>) => {
      state.rmas = RemoteRecordData.init();
      return state;
    },
    fetchRmasFulfilled: (state, action: PayloadAction<RmasType>) => {
      state.rmas = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchRmasRejected: (state, action: PayloadAction<ErrorType>) => {
      state.rmas = RemoteRecordData.reject(action.payload);
      return state;
    },
    searchRmas: (state, action: PayloadAction<string>) => {
      state.search = RemoteRecordData.init();
      return state;
    },
    searchRmasFulfilled: (state, action: PayloadAction<RmaSearchType>) => {
      state.search = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    searchRmasRejected: (state, action: PayloadAction<ErrorType>) => {
      state.search = RemoteRecordData.reject(action.payload);
      return state;
    },
    clearAccountRmas: (state) => {
      state.rmas = RemoteRecordData.getDefault();
      state.search = RemoteRecordData.getDefault();
      return state;
    },
  },
});

export const {
  fetchRmas,
  fetchRmasFulfilled,
  fetchRmasRejected,
  searchRmas,
  searchRmasFulfilled,
  searchRmasRejected,
  clearAccountRmas,
} = rmasSlice.actions;


const getState = (state: AppState) => state.myAccount.rma.rmas;

export const selectRmas = createSelector(
  getState,
  state => new RemoteRecord<RmasType>(state.rmas),
);

export const selectSearchResults = createSelector(
  getState,
  state => new RemoteRecord<RmaSearchType>(state.search),
);
