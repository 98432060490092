import { ReactElement } from 'react';
import { useTranslate } from '@client/i18n/hooks';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Body } from './Body';
import { FIELD_NAME, Props } from './Form.types';

export const Form = ({
  onSubmit,
  onModalClose,
  currentWishlist,
  onChange,
  options,
  onLoadOptions,
  onCreate,
}: Props): ReactElement => {
  const t = useTranslate();

  const validationSchema = yup.object().shape({
    [FIELD_NAME.selectedWishlist]: yup.mixed().required(t('Required')),
  });

  return (
      <Formik
        initialValues={{
          [FIELD_NAME.selectedWishlist]: currentWishlist || '',
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Body
          onModalClose={onModalClose}
          onChange={onChange}
          options={options}
          onLoadOptions={onLoadOptions}
          onCreate={onCreate}
        />
      </Formik>
  );
};
