import { ensureArray } from '@client/common/utils/array/array';
import { ModularPage } from '@client/content/components/ModularPage';
import {
  ModularPageProductsGroupContextProvider,
} from '@client/content/components/ModularPage/Context/ModularPageProductsGroupContext';
import { getAvailableLanguages } from '@client/i18n/utils/language';
import { AccessCallbackDataType, DEFAULT_ROUTE, RouteType } from '@client/routes/components/Router/routes';
import { EntryMapType } from 'b2b-common/core/content/Content.types';

export const mapEntriesToRoutes = (
  entries: EntryMapType[] | null | undefined,
): RouteType => ensureArray(entries).reduce(
  (routes: RouteType, entry: EntryMapType) => {
    const paths: Record<string, string> = {};

    getAvailableLanguages().forEach(languageCode => {
      if (entry.url[languageCode]) {
        paths[languageCode] = `/${languageCode}${entry.url[languageCode]}`;
      }
    });

    routes[entry.uid] = {
      ...DEFAULT_ROUTE,
      exact: true,
      paths,
      render: function render() {
        return (
            <ModularPageProductsGroupContextProvider>
              <ModularPage entryId={entry.uid} />
            </ModularPageProductsGroupContextProvider>
        );
      },
      allow: ({ activeLanguage }: AccessCallbackDataType) => entry.restrictedAccess[activeLanguage],
    };

    return routes;
  },
  {},
);
