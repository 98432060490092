import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterElement } from 'b2b-common/core/catalog/Catalog.types';

const NAME = 'inkFinder';

export const inkFinderSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {
    manufacturers: RemoteRecordData.getDefault(),
    models: RemoteRecordData.getDefault(),
  },
  reducers: {
    fetchInkManufacturers: (state) => {
      state.manufacturers = RemoteRecordData.init();
      return state;
    },
    fetchInkManufacturersFulfilled: (state, action: PayloadAction<FilterElement[]>) => {
      state.manufacturers = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchInkManufacturersRejected: (state, action: PayloadAction<ErrorType>) => {
      state.manufacturers = RemoteRecordData.reject(action.payload);
      return state;
    },
    fetchInkModels: (state, action: PayloadAction<string>) => {
      state.models = RemoteRecordData.init();
      return state;
    },
    fetchInkModelsFulfilled: (state, action: PayloadAction<FilterElement[]>) => {
      state.models = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchInkModelsRejected: (state, action: PayloadAction<ErrorType>) => {
      state.models = RemoteRecordData.reject(action.payload);
      return state;
    },
  },
});

export const {
  fetchInkManufacturers,
  fetchInkManufacturersFulfilled,
  fetchInkManufacturersRejected,
  fetchInkModels,
  fetchInkModelsFulfilled,
  fetchInkModelsRejected,
} = inkFinderSlice.actions;
export const toggleInkWidget = createAction<boolean>('toggleInkWidget');

const getState = (state: AppState) => state.catalog.inkFinder;

export const selectInkManufacturers = createSelector(
  getState,
  state => new RemoteRecord<FilterElement[]>(state.manufacturers),
);

export const selectInkModels = createSelector(
  getState,
  state => new RemoteRecord<FilterElement[]>(state.models),
);
