import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { RequestAddAddressType, RequestEditAddressType } from './Address.types';

export class Address {
  public static fetch(id: string): RequestConfigType {
    return RequestConfig.get(`/account/shipping-addresses/${id}`,
      { withLocalization: false });
  }

  public static removeAddress(id: string): RequestConfigType {
    return RequestConfig.delete(`/account/shipping-addresses/${id}`,
      { withLocalization: false });
  }

  public static addAddress(data: RequestAddAddressType): RequestConfigType {
    return RequestConfig.post('/account/shipping-addresses',
      data,
      { withLocalization: false });
  }

  public static editAddress(id: string, data: RequestEditAddressType): RequestConfigType {
    return RequestConfig.put(`/account/shipping-addresses/${id}`,
      data,
      { withLocalization: false });
  }
}
