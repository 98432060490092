import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Product } from '@client/product/api/Product';
import {
  productReportError,
  productReportErrorFulfilled,
  productReportErrorRejected,
} from '@client/product/redux/productReportError/redux';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';

function* reportErrorSaga(
  action: ReturnType<typeof productReportError>,
): Generator<CallEffect | PutEffect, void> {
  const { details } = action.payload;

  try {
    yield call(requestApiSaga, Product.reportError(details));

    yield put(productReportErrorFulfilled());
  } catch (error) {
    yield put(productReportErrorRejected({ error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* productReportErrorSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeEvery(productReportError.type, reportErrorSaga),
  ]);
}
