import { all, fork } from 'redux-saga/effects';
import { checkoutSaga as checkoutDataSaga } from './checkout/sagas';
import { orderSaga } from './order/sagas';

export function* checkoutSaga(): Generator<any, any, any> {
  yield all([
    fork(checkoutDataSaga),
    fork(orderSaga),
  ]);
}
