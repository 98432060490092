import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NewslettersType, RequestNewslettersType } from 'b2b-common/core/account/api/Newsletters.types';

const initialState = {
  newsletters: RemoteRecordData.getDefault(),
  updateNewsletters: RemoteRecordData.getDefault(),
};

const NAME = 'newsletters';

export const newslettersSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState,
  reducers: {
    updateNewsletters: (state, action: PayloadAction<RequestNewslettersType>) => {
      state.updateNewsletters = RemoteRecordData.init();
      return state;
    },
    updateNewslettersFulfilled: (state) => {
      state.updateNewsletters = RemoteRecordData.fulfill({});
      return state;
    },
    updateNewslettersRejected: (state, action: PayloadAction<ErrorType>) => {
      state.updateNewsletters = RemoteRecordData.reject(action.payload);
      return state;
    },
    clearUpdateNewsletters: (state) => {
      state.updateNewsletters = initialState.updateNewsletters;
      return state;
    },
    fetchNewsletters: (state) => {
      state.newsletters = RemoteRecordData.init();
      return state;
    },
    fetchNewslettersFulfilled: (state, action: PayloadAction<NewslettersType>) => {
      state.newsletters = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchNewslettersRejected: (state, action: PayloadAction<ErrorType>) => {
      state.newsletters = RemoteRecordData.reject(action.payload);
      return state;
    },
    clearAccountNewsletters: (state) => {
      state.newsletters = initialState.newsletters;
      return state;
    },
  },
});

export const {
  updateNewsletters,
  updateNewslettersFulfilled,
  updateNewslettersRejected,
  clearUpdateNewsletters,
  fetchNewsletters,
  fetchNewslettersFulfilled,
  fetchNewslettersRejected,
  clearAccountNewsletters,
} = newslettersSlice.actions;

const getState = (state: AppState) => state.myAccount.newsletters;

export const selectNewsletters = createSelector(
  getState,
  state => new RemoteRecord<NewslettersType>(state.newsletters),
);

export const selectUpdateNewsletters = createSelector(
  getState,
  state => new RemoteRecord(state.updateNewsletters),
);
