import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { stringify } from '@client/common/utils/queryParams';
import { AxiosResponse } from 'axios';
import { ContentCollectionEntryType } from 'b2b-common/core/content/Content.types';

export class CollectionEntries {
  public static fetch(
    collectionId: string,
    filters?: Record<string, any>,
  ): RequestConfigType {
    const queryString = stringify({
      filter: filters,
    });

    return RequestConfig.get(
      `/content/collection/${collectionId}/entries?${queryString}`,
      {
        withLocalization: true,
      },
    );
  }

  public static mapResponseToState(
    response: AxiosResponse<ContentCollectionEntryType | null>,
  ): ContentCollectionEntryType | null {
    return response.data;
  }
}
