import { ReactElement } from 'react';
import { SCREENSHOT_TOOL_DATA_HIDE } from '@client/common/screenshotTool';
import { ModalButtons } from 'b2b-components/core/components/Modal';
import { Modal } from 'b2b-components/theme/components/Modal';
import { Props } from './FeatureOnboarding.types';
import { ModalContent } from './ModalContent';

export const FeatureOnboarding = ({
  entry,
  isOpen,
  onDismiss,
  modalButtonLabel,
}: Props): ReactElement | null => {
  if (!entry) {
    return null;
  }

  const { data } = entry;

  return (
    <Modal
      title={data.title}
      content={() => (
        <>
          <ModalContent data={data} />
          <ModalButtons
            primary={{
              label: data.button_label || modalButtonLabel,
              onClick: onDismiss,
            }}
          />
        </>
      )}
      isOpen={isOpen}
      onCloseModal={onDismiss}
      dataAttributes={{
        'data-testid': 'featureOnboardingModal',
        ...SCREENSHOT_TOOL_DATA_HIDE,
      }}
    />
  );
};
