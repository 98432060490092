import { ReactElement } from 'react';
import { usePageViewAnalytics } from '@client/analytics/hooks';
import { TEALIUM_PAGE_TYPE } from '@client/analytics/types/types';
import { UnloggedUserAlert } from '@client/common/components/UnloggedUserPage/UnloggedUserAlert';
import { StatusCodes } from 'http-status-codes';
import { Props } from './UnloggedUserPage.types';

export const UnloggedUserPage = (props: Props): ReactElement => {
  usePageViewAnalytics({
    pageType: TEALIUM_PAGE_TYPE.notAllowedPage,
    data: {
      server: {
        code: StatusCodes.UNAUTHORIZED,
      },
    },
  });

  return (
    <UnloggedUserAlert {...props} />
  );
};
