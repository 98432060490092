import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCheckPermissions } from '@client/context/hooks';
import { getIsLoggedIn } from '@client/context/redux/redux';
import { fetchUserStats, selectUserStatsData } from '@client/user-stats';
import { PERMISSION } from 'b2b-common/core/context/Context.types';
import { UserStatsType } from 'b2b-common/core/user-stats';

export const useUserStatsEffect = (): UserStatsType => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const userStatsData = useSelector(selectUserStatsData);
  const canCostCenterCanReview = useCheckPermissions([PERMISSION.costCenterCanReview]);

  useEffect((): void => {
    if (isLoggedIn && canCostCenterCanReview) {
      dispatch(fetchUserStats());
    }
  }, [isLoggedIn, dispatch]);

  return userStatsData;
};
