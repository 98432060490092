export const REMOTE_RECORD_ACTION_INIT = 'INIT';
export const REMOTE_RECORD_ACTION_FAIL = 'FAIL';
export const REMOTE_RECORD_ACTION_DONE = 'DONE';

export type RemoteRecordDataType<T> = {
  data: T | null;
  error: ErrorType | null;
  identifier: null | string;
  status:
    | null
    | typeof REMOTE_RECORD_ACTION_INIT
    | typeof REMOTE_RECORD_ACTION_FAIL
    | typeof REMOTE_RECORD_ACTION_DONE;
  timestamp: number | null;
};

export class RemoteRecordData<T> {
  //TODO: find solution to type this
  //@ts-ignore
  public static getDefault(): RemoteRecordDataType<T> {
    return {
      identifier: null,
      status: null,
      data: null,
      error: null,
      timestamp: new Date().getTime(),
    };
  }

  public static init(
    identifier: string | null | undefined = null,
    //TODO: find solution to type this
    //@ts-ignore
  ): RemoteRecordDataType<T> {
    return Object.assign(RemoteRecordData.getDefault(), {
      identifier,
      status: REMOTE_RECORD_ACTION_INIT,
    });
  }

  public static reject(
    error: ErrorType,
    identifier: string | null | undefined = null,
    //TODO: find solution to type this
    //@ts-ignore
  ): RemoteRecordDataType<T> {
    return Object.assign(RemoteRecordData.getDefault(), {
      error: error,
      identifier,
      status: REMOTE_RECORD_ACTION_FAIL,
    });
  }

  public static fulfill(
    //TODO: find solution to type this
    //@ts-ignore
    data: T,
    identifier: string | null | undefined = null,
    //TODO: find solution to type this
    //@ts-ignore
  ): RemoteRecordDataType<T> {
    return Object.assign(RemoteRecordData.getDefault(), {
      data: data,
      identifier,
      status: REMOTE_RECORD_ACTION_DONE,
    });
  }
}
