import { createAction } from '@reduxjs/toolkit';

export const analyzeCartPageView = createAction('analyzeCartPageView');

export const analyzeItemClick = createAction<{
  sku: string,
  listPosition: number,
}>('analyzeItemClick');

export const analyzeItemQuantityChange = createAction<{
  sku: string,
  quantity: number,
  listPosition: number,
}>('analyzeItemQuantityChange');

export const analyzeClearCart = createAction('analyzeClearCart');
