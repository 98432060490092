import { ProductDescriptionType, ProductFullType } from '@client/product/redux/products/full/types';
import {
  ProductCrumbType,
  ProductManufacturerType,
  ProductSpecificationType,
} from '@client/product/redux/products/types';
import { ProductEffectivePricingType, ProductSettingsType } from 'b2b-common/core/product/api/Product.types';
import { GetProductConfiguratorResponse } from 'b2b-common/core/trodat';

export const MAX_NUMBER_OF_PRODUCTS_TO_COMPARE = 8;

export type ProductsCompareDetails = {
  title: ProductDescriptionType['title'];
  sku: ProductFullType['sku'];
  ean: ProductFullType['ean'];
  energyLabel: ProductDescriptionType['energyLabel'];
  scaledDiscounts: ProductFullType['scaledDiscounts'];
  manufacturerProductId: ProductManufacturerType['productId'];
  manufacturerUrl: ProductManufacturerType['url'];
  manufacturerName: ProductManufacturerType['name'];
  manufacturerWarranty: ProductManufacturerType['warranty'];
  manufacturerInternalPage: ProductManufacturerType['internalPage'];
  soldSince: ProductFullType['webDateStart'];
  cover: ProductFullType['cover'];
  categoryName: ProductCrumbType['label'];
  categoryUrl: ProductCrumbType['url'];
  pricing: ProductEffectivePricingType;
  settings: ProductSettingsType;
  analytics: ProductFullType['analytics'];
};

export type ProductToCompareSpecification = Omit<ProductSpecificationType, 'ordinalNumber'>;

export type ProductToCompare = {
  details: ProductsCompareDetails;
  specification: ProductToCompareSpecification[];
};

export type ComparedProductDetails = Omit<ProductsCompareDetails, 'cover'> & {
  imageUrl: string;
}

export type ProductsCompare = ProductToCompare[];

export type ComparedProduct = {
  details: ComparedProductDetails;
  specification: ProductToCompareSpecification[];
}

export type StampConfiguratorType = GetProductConfiguratorResponse;
