import { ReactElement } from 'react';
import { withField } from '@client/common/components/Form/withField';
import { Checkbox } from 'b2b-components/theme/components/Form/Checkbox/Checkbox';
import { Props } from './FormFieldCheckbox.types';

export const FormFieldCheckbox = withField((props: Props): ReactElement => {
  const { innerComponentProps, field, form, ...otherProps } = props;

  return (
    <Checkbox {...innerComponentProps} {...field} {...otherProps} />
  );
});
