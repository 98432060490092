import { ReactElement } from 'react';
import { usePageViewAnalytics } from '@client/analytics/hooks';
import { TEALIUM_PAGE_TYPE } from '@client/analytics/types/types';
import { InfoView } from '@client/common/components/InfoView';
import { useTranslate } from '@client/i18n/hooks';
import defaultImage from './assets/locked.svg';
import { Props } from './InvalidPermissionsPage.types';

export const InvalidPermissionsPage = ({
  iconType,
  image = defaultImage,
}: Props): ReactElement => {
  const t = useTranslate();

  usePageViewAnalytics({ pageType: TEALIUM_PAGE_TYPE.notPermittedPage });

  return <InfoView image={image} title={t('You are not allowed to access this section')} iconType={iconType} />;
};
