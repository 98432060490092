import { CatalogType } from '@client/app/hooks/useAssortmentLinks';
import { mapCatalogData } from '@client/app/utils';
import { AssortmentCategoryType } from '@client/assortment/redux/types';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { useEntriesMap } from '@client/content/hooks';
import { getUrlsForLanguage } from '@client/content/utils/entriesMap';
import { useActiveLanguage } from '@client/i18n/hooks';
import { useUrlCreator } from '@client/routes/hooks';

export const useMapAssortment = (assortment: RemoteRecord<AssortmentCategoryType[]>): CatalogType => {
  const entriesMap = useEntriesMap();
  const activeLanguage = useActiveLanguage();
  const urlCreator = useUrlCreator();

  if (!entriesMap.data || !assortment.data) {
    return [];
  }

  const availableUrls = getUrlsForLanguage(entriesMap.data, activeLanguage);
  const langPrefix = `/${activeLanguage}/`;

  return mapCatalogData(assortment, langPrefix, activeLanguage, availableUrls, urlCreator);
};
