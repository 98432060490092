import { userSaga } from '@client/myAccount/users/redux/user/sagas';
import { usersSaga } from '@client/myAccount/users/redux/users/sagas';
import { usersSearchSagas } from '@client/myAccount/users/redux/usersSearch/sagas';
import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';

export function* usersSagas(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    fork(userSaga),
    fork(usersSearchSagas),
    fork(usersSaga),
  ]);
}
