import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { stringify } from '@client/common/utils/queryParams';
import { DEFAULT_LIST_PAGE_SIZE } from '@client/myAccount/costCenters/consts';
import {
  CreateCostCenterAddressRequestBodyType,
  UpdateCostCenterAddressRequestBodyType,
} from 'b2b-common/core/account/api/costCenters/CostCenterAddresses.types';

export class CostCenterAddresses {
  public static list(
    costCenterId: string,
    startItem = 0,
    pageSize: number = DEFAULT_LIST_PAGE_SIZE,
  ): RequestConfigType {
    return RequestConfig.get(
      `/account/cost-centers/${costCenterId}/addresses?${stringify({ pageSize, startItem })}`,
      {
        withLocalization: false,
      },
    );
  }

  public static create(
    costCenterId: string,
    data: CreateCostCenterAddressRequestBodyType,
  ): RequestConfigType {
    return RequestConfig.post(
      `/account/cost-centers/${costCenterId}/addresses`,
      data,
      {
        withLocalization: false,
      },
    );
  }

  public static get(
    costCenterId: string,
    addressId: string,
  ): RequestConfigType {
    return RequestConfig.get(
      `/account/cost-centers/${costCenterId}/addresses/${addressId}`,
      {
        withLocalization: false,
      },
    );
  }

  public static update(
    costCenterId: string,
    data: UpdateCostCenterAddressRequestBodyType,
  ): RequestConfigType {
    const { addressId, ...body } = data;
    return RequestConfig.put(
      `/account/cost-centers/${costCenterId}/addresses/${addressId}`,
      body,
      {
        withLocalization: false,
      },
    );
  }

  public static delete(
    costCenterId: string,
    addressId: string,
  ): RequestConfigType {
    return RequestConfig.delete(
      `/account/cost-centers/${costCenterId}/addresses/${addressId}`,
      {
        withLocalization: false,
      },
    );
  }

  public static setDefault(
    costCenterId: string,
    addressId: string,
  ): RequestConfigType {
    return RequestConfig.put(
      `/account/cost-centers/${costCenterId}/addresses/set-default/${addressId}`,
      {},
      {
        withLocalization: false,
      },
    );
  }
}
