import { retryRequestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Invoices } from '@client/myAccount/invoices/api';
import {
  fetchInvoices,
  fetchInvoicesFulfilled,
  fetchInvoicesRejected,
} from '@client/myAccount/invoices/redux/invoices/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { InvoicesType } from 'b2b-common/core/account/api/invoices';

function* fetchInvoicesSaga(action: ReturnType<typeof fetchInvoices>):
  Generator<CallEffect | PutEffect, void, AxiosResponse<InvoicesType>> {
  const { pageSize, startItem } = action.payload;

  try {
    const response = yield call(retryRequestApiSaga, Invoices.fetch(pageSize, startItem));

    yield put(fetchInvoicesFulfilled(response.data));
  } catch (error) {
    yield put(fetchInvoicesRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* invoicesSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(fetchInvoices.type, fetchInvoicesSaga),
  ]);
}
