import { ReactElement } from 'react';
import { HOT_JAR_TAG } from '@client/analytics/types/types';
import { tagHotJarRecording } from '@client/analytics/utils/analytics';
import { useBrands } from '@client/common/hooks/useBrands';
import { useTranslate } from '@client/i18n/hooks';
import { FlyoutBrands as FlyoutBrandsComponent } from '@lib/components/Header/Flyout';

interface Props {
  withFullBrands?: boolean;
  className?: string;
}

export const FlyoutBrands = ({ withFullBrands, className }: Props): ReactElement => {
  const t = useTranslate();
  const [brands, onLinkClick] = useBrands(!!withFullBrands);

  const handleFlyoutOpen = () => {
    tagHotJarRecording(HOT_JAR_TAG.brandsFlyoutOpen);
  };

  return (
    <FlyoutBrandsComponent
      onLinkClick={onLinkClick}
      brands={brands}
      label={t('Brands')}
      className={className}
      onFlyoutOpen={handleFlyoutOpen}
    />
  );
};
