import { DataAttributesType } from '@lib/utils/getComponentDataAttributes';
import { ProductSettingsType } from 'b2b-common/core/product/api/Product.types';

export enum ADD_TO_CART_DISPLAY_TYPE {
  button = 'button',
  full = 'full',
  icon = 'icon',
}

export type AddToCartTextType = {
  notPermitted: string;
  notAvailable?: string;
  blockedUser: string;
  isSoldOut: string;
  notBuyable: string;
  maximumQuantityReached: (maxQuantity: number) => string;
  maximumOrderQuantityReached: (maxQuantity: number) => string;
  addToCart: string;
  notInCoreAssortment?: string;
};

export interface Props {
  hasCertificationInfo: boolean;
  hasAccessCartPermissions: boolean;
  isBlocked: boolean;
  isLoggedIn: boolean;
  onAddToCart: (quantity: number) => void;
  text: AddToCartTextType;
  isVisible?: boolean;
  showUnavailable?: boolean;
  quantityAdding?: number;
  type: ADD_TO_CART_DISPLAY_TYPE;
  settings: ProductSettingsType | null;
  dataAttributes?: DataAttributesType;
  className?: string;
  shouldHideOnContentMouseOver?: boolean;
}

