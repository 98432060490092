import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { AxiosResponse } from 'axios';
import { ContentEntryType } from 'b2b-common/core/content/Content.types';

export class Entry {
  public static fetch(entryId: string): RequestConfigType {
    return RequestConfig.get(`/content/entries/${entryId}`);
  }

  public static fetchKAM(): RequestConfigType {
    return RequestConfig.get('/content/entries/kam');
  }

  public static mapResponseToState(response: AxiosResponse<ContentEntryType>): ContentEntryType | null {
    return response.data;
  }
}
