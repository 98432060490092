import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LANGUAGE_CODE } from 'b2b-common/core/i18n/consts';
import { NewsletterType } from 'b2b-common/core/newsletter/api/types';

const NAME = 'newsletter';

export const newsletterSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: RemoteRecordData.getDefault(),
  reducers: {
    subscribeNewsletter: (state, action: PayloadAction<{
      email: string;
      language: LANGUAGE_CODE;
    }>) => RemoteRecordData.init(),
    subscribeNewsletterFulfilled: (state, action: PayloadAction<NewsletterType>) => (
      RemoteRecordData.fulfill(action.payload)
    ),
    subscribeNewsletterRejected: (state, action: PayloadAction<ErrorType>) => (
      RemoteRecordData.reject(action.payload)
    ),
  },
});

export const {
  subscribeNewsletter,
  subscribeNewsletterFulfilled,
  subscribeNewsletterRejected,
} = newsletterSlice.actions;

const getState = (state: AppState) => state.newsletter;

export const selectSubscription = createSelector(
  getState,
  state => new RemoteRecord<NewsletterType>(state),
);
