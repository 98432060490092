import { ReactElement } from 'react';
import { usePageViewAnalytics } from '@client/analytics/hooks';
import { TEALIUM_PAGE_TYPE } from '@client/analytics/types/types';
import { InfoView } from '@client/common/components/InfoView';
import { useTranslate } from '@client/i18n/hooks';
import { StatusCodes } from 'http-status-codes';
import defaultImage from './assets/notfound.svg';
import { Props } from './index';

export const NotFoundPage = ({ iconType, image = defaultImage }: Props): ReactElement => {
  const t = useTranslate();

  usePageViewAnalytics({
    pageType: TEALIUM_PAGE_TYPE.errorPage,
    data: {
      server: {
        code: StatusCodes.NOT_FOUND,
      },
    },
  });

  return (
    <div id='not-found-page'>
      <InfoView image={image} title={t('Page Not Found (Error 404)')} iconType={iconType} />
    </div>
  );
};
