import { isEmpty } from '@client/common/utils/empty/empty';
import { config } from '@client/config';
import { useEntry } from '@client/content/hooks';
import { ROUTES } from '@client/routes/components/Router/routes';
import { useRouterPush, useUrlCreator } from '@client/routes/hooks';
import { getBrandUrlParams, openLink } from '@client/routes/utils/url';
import { LinksGroupType } from 'b2b-components/theme/components/Header/Flyout';

export type BrandDataType = {
  href: string;
  title: string;
};

export type BrandGroupDataType = {
  ['group_link']: BrandDataType;
  links: BrandDataType[];
};

const brandMapper = (haveFullLinks: boolean, route: string) => (brand: BrandDataType): LinksGroupType => ({
  href: haveFullLinks
    ? brand.href
    : `${route}${getBrandUrlParams(brand.href)}`,
  title: brand.title,
});

const brandGroupMapper = (
  haveFullLinks: boolean,
  route: string,
) => (brandGroup: BrandGroupDataType): LinksGroupType => ({
  href: haveFullLinks
    ? brandGroup.group_link.href
    : `${route}${getBrandUrlParams(brandGroup.group_link.href)}`,
  title: brandGroup.group_link.title,
  children: brandGroup.links.map(brandMapper(haveFullLinks, route)),
});

const useBrandsLinks = (haveFullLinks = true): LinksGroupType[] => {
  const brandsData = useEntry(config.contentStackIds.brandsMenu);
  const urlCreator = useUrlCreator();

  if (!brandsData.isLoaded || !brandsData.data || isEmpty(brandsData.data)) {
    return [];
  }

  const { link, section } = brandsData.data;

  if (Array.isArray(link)) {
    return link.map(brandMapper(haveFullLinks, urlCreator(ROUTES.brand)));
  }

  if (Array.isArray(section)) {
    return section.map(brandGroupMapper(haveFullLinks, urlCreator(ROUTES.brand)));
  }

  return [];
};

export const useBrands = (haveFullLinks: boolean): [LinksGroupType[], (href: string) => void] => {
  const push = useRouterPush();
  const brands = useBrandsLinks(haveFullLinks);

  const handleLinkClick = (href: string): void => {
    openLink(href, push);
  };

  return [brands, handleLinkClick];
};
