import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { ProductGroup } from '@client/common/components/ProductGroup';
import { chunkArray, ensureArray } from '@client/common/utils/array/array';
import { BREAKPOINTS } from '@client/common/utils/breakpointsLayout';
import { getIsLoggedIn } from '@client/context/redux/redux';
import { useTranslate } from '@client/i18n/hooks';
import { PRODUCT_COUNT_IN_GROUP } from '@client/product/utils/product';
import { PRODUCT_BOX_STYLE_TYPE, ProductList } from '@lib/components/ProductList';
import { Slider, SLIDER_STYLE_TYPE } from '@lib/components/Slider';
import classNames from 'classnames';
import { Props } from '../';
import './ProductBoxList.scss';

const SLIDER_PARAMS = {
  xs: {
    slidesPerView: 2,
    slidesPerGroup: 2,
  },
  md: {
    slidesPerView: 4,
    slidesPerGroup: 4,
  },
  default: {
    slidesPerView: 1,
    slidesPerGroup: 1,
  },
};

const STYLE_TYPE = {
  list: 'List',
  table: 'Table',
  tile: 'Tile',
};

export const ProductBoxList = ({ componentData, className }: Props): ReactElement | null => {
  const t = useTranslate();
  const isLoggedIn = useSelector(getIsLoggedIn);

  if (!Array.isArray(componentData.products)) {
    return null;
  }

  const { label, show_as_slider: isSlider, view, products } = componentData;
  let styleType: PRODUCT_BOX_STYLE_TYPE;

  switch (view) {
    case STYLE_TYPE.list:
      styleType = PRODUCT_BOX_STYLE_TYPE.list;
      break;

    case STYLE_TYPE.table:
      styleType = PRODUCT_BOX_STYLE_TYPE.table;
      break;

    default:
      styleType = PRODUCT_BOX_STYLE_TYPE.tile;
      break;
  }

  const productBoxes: ReactElement[] = chunkArray(
    ensureArray(products),
    PRODUCT_COUNT_IN_GROUP,
  ).map((productGroup: Record<string, any>[], index) => (
    <ProductGroup
      groupNumber={index}
      isForSlider={isSlider}
      isFromContentList={true}
      key={productGroup[0]?.product_sku}
      skus={productGroup.map((product) => product.product_sku.trim())}
      styleType={styleType}
    />
  ));

  return (
    <div className={classNames(className)}>
      {label && <h2 className="cds-cms-SectionTitle">{label}</h2>}
      <div className="cds-cms-GridRow">
        <div className="cds-cms-ModuleContainer">
          <div className="ProductListModule">

            {isSlider && (
              <Slider
                {...SLIDER_PARAMS.default}
                breakpoints={{
                  [BREAKPOINTS.xs]: SLIDER_PARAMS.xs,
                  [BREAKPOINTS.md]: SLIDER_PARAMS.md,
                }}
                isLoop={false}
                isAutoplay={false}
                spaceBetween={16}
                styleType={SLIDER_STYLE_TYPE.withMargin}
                productBoxCount={ensureArray(products).length}
              >
                {productBoxes}
              </Slider>
            )}

            {!isSlider && (
              <div className={classNames(
                'ProductListModule-ListWrapper', {
                  'ProductListModule-ListWrapper--Table':
                  styleType === PRODUCT_BOX_STYLE_TYPE.table,
                },
              )}
              >
                <ProductList
                  styleType={styleType}
                  text={{
                    sku: t('SKU'),
                    manufacturerSku: t('Manufacturer Product ID'),
                    title: t('Title'),
                    price: isLoggedIn
                      ? t('Price<br />(excl. VAT)')
                      : t('Price<br />(incl. VAT)'),
                    stock: t('Stock'),
                  }}
                >
                  {productBoxes}
                </ProductList>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
