import { combineSlices } from '@reduxjs/toolkit';
import { barcodeSearchSlice } from './barcodeSearch/redux';
import { inkFinderSlice } from './inkFinder/redux';
import { searchSlice } from './search/redux';
import { skuListSlice } from './skuList/redux';
import { suggestionsSlice } from './suggestions/redux';

export const catalogReducer = combineSlices(
  barcodeSearchSlice,
  inkFinderSlice,
  searchSlice,
  skuListSlice,
  suggestionsSlice,
);
