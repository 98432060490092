
import {
  ActionPattern,
  cancel,
  fork,
  ForkEffect,
  SagaReturnType,
  take,
} from 'redux-saga/effects';

export function takeLatestActionPerSku(
  actionPattern: ActionPattern<any>,
  worker: (...args: any[]) => any,
): ForkEffect<SagaReturnType<any>> {
  return fork(function* () {
    const actionsPerSku = {};

    while (true) {
      const action: ReturnType<any> = yield take(actionPattern);
      const { sku } = action.payload;

      if (actionsPerSku[sku]) {
        yield cancel(actionsPerSku[sku]);
      }
      actionsPerSku[sku] = yield fork(worker, action);
    }
  });
}
