import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StampConfiguratorType } from 'b2b-common/core/product/api/ProductsCompare.types';

const initialState = RemoteRecordData.getDefault();

const NAME = 'stampConfigurator';

export const stampConfiguratorSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState,
  reducers: {
    fetchStampConfigurator: (state, action: PayloadAction<{
      sku: string,
      partAuxiliaryId?: string,
      quantity?: number,
      skipHookUrlTarget?: boolean
    }>) => RemoteRecordData.init(),
    fetchStampConfiguratorFulfilled: (
      state,
      action: PayloadAction<{ data: StampConfiguratorType }>,
    ) => RemoteRecordData.fulfill(action.payload.data),
    fetchStampConfiguratorRejected: (
      state,
      action: PayloadAction<{ error: ErrorType }>,
    ) => RemoteRecordData.reject(action.payload.error),
  },
});

export const {
  fetchStampConfigurator,
  fetchStampConfiguratorFulfilled,
  fetchStampConfiguratorRejected,
} = stampConfiguratorSlice.actions;

const getState = (state: AppState) => state.product.stampConfigurator;

export const selectStampConfiguratorData = createSelector(
  getState,
  (state) => new RemoteRecord<StampConfiguratorType>(state),
);
