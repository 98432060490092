import { ReactElement } from 'react';
import { useTranslate } from '@client/i18n/hooks';
import { Modal, MODAL_TYPE, ModalButtons } from 'b2b-components/theme/components/Modal';
import { Props } from './TermsModal.types';

export const TermsModal = ({
  isOpen,
  modalContent,
  toggleModal,
}: Props): ReactElement => {
  const t = useTranslate();

  return (
    <Modal
      content={() => (
        <>
          <div
            className='Modal-Content'
            dangerouslySetInnerHTML={{
              __html: modalContent,
            }}
          />
          <ModalButtons
            secondary={{
              label: t('Close'),
              onClick: toggleModal,
            }}
          />
        </>
      )}
      isOpen={isOpen}
      onCloseModal={toggleModal}
      type={MODAL_TYPE.small}
    />
  );
};
