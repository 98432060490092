import { analyzeProductDetailsPageView } from '@client/analytics/redux';
import { analyzeView } from '@client/analytics/redux/tealium/actions';
import {
  ANALYTICS_BOOLEAN,
  TEALIUM_INTERACTION_ID,
  TEALIUM_PAGE_TYPE,
  TEALIUM_PROD_ACTION,
} from '@client/analytics/types/types';
import { prepareAnalyticsBool, preparePrice, prepareStock } from '@client/analytics/utils/analytics';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { selectProductBySku } from '@client/product/redux/products/full/redux';
import { ProductFullType } from '@client/product/redux/products/full/types';
import { call, CallEffect, put, PutEffect, select, SelectEffect } from 'redux-saga/effects';
import { getInteractionProduct } from '../utils';
import { analyzeProductsAddToCartInteraction } from './interactions';

export function* analyzeProductDetailsPageViewSaga(action: ReturnType<typeof analyzeProductDetailsPageView>): Generator<
    PutEffect | SelectEffect,
    void,
    RemoteRecord<ProductFullType>
  > {
  const productState: RemoteRecord<ProductFullType> = yield select(selectProductBySku, action.payload);
  const pageType = TEALIUM_PAGE_TYPE.productDetails;
  const failedPageType = TEALIUM_PAGE_TYPE.productNotFound;

  if (!productState) {
    return;
  }

  const isRecordLoaded = productState?.isLoaded || false;
  const hasRecordFailed = productState?.hasFailed || false;

  if (isRecordLoaded || hasRecordFailed) {
    const product = productState.data;
    const { effectivePricing } = product;

    yield put(analyzeView({
      interaction: TEALIUM_INTERACTION_ID.productDetails,
      prod: [
        {
          id: `${product?.sku}`,
          stock: prepareStock(product?.settings?.quantity),
          price: preparePrice(
            effectivePricing?.hasB2BUserPrices
              ? effectivePricing?.userPrice
              : effectivePricing?.listPrice,
          ),
          action: TEALIUM_PROD_ACTION.productDetails,
          purchasable: prepareAnalyticsBool(
            effectivePricing?.isBuyable && !product?.settings?.isDisabledByCoreAssortment,
          ),
          categories: product?.analytics?.categories,
          hasService: ANALYTICS_BOOLEAN.false,
        },
      ],
      page: {
        type: hasRecordFailed
          ? failedPageType || pageType
          : pageType,
      },
    }));
  }
}

export function* analyzePDPProductAddToCart(sku: string, quantity: number):
  Generator<
    CallEffect | SelectEffect,
    void,
    RemoteRecord<ProductFullType>
  > {
  const productRecord = yield select(selectProductBySku, sku);
  const product = productRecord.data;

  if (!product) {
    return;
  }

  yield call(
    analyzeProductsAddToCartInteraction,
    TEALIUM_PROD_ACTION.addToCartFromPDP,
    [{ ...getInteractionProduct(product), quantity }],
  );
}
