import { ReactElement } from 'react';
import { ProductGroup } from '@client/common/components/ProductGroup';
import { SCREENSHOT_TOOL_DATA_ATTR_MPT_ID } from '@client/common/screenshotTool';
import { chunkArray } from '@client/common/utils/array/array';
import { BREAKPOINTS } from '@client/common/utils/breakpointsLayout';
import { VIEW_TYPE } from '@client/content/common';
import { useTranslate } from '@client/i18n/hooks';
import { PRODUCT_COUNT_IN_GROUP } from '@client/product/utils/product';
import { PRODUCT_BOX_STYLE_TYPE, ProductList } from '@lib/components/ProductList';
import classNames from 'classnames';
import { Slider, SLIDER_STYLE_TYPE } from 'b2b-components/theme/components/Slider';
import { Props } from './ProductBoxes.types';

const SLIDER_PARAMS = {
  xs: {
    slidesPerView: 2,
    slidesPerGroup: 2,
  },
  lg: {
    slidesPerView: 4,
    slidesPerGroup: 4,
  },
  default: {
    slidesPerView: 1,
    slidesPerGroup: 1,
  },
};

export const ProductBoxes = ({
  countSliderLoadedProductTiles,
  isLoggedIn,
  isSlider,
  label,
  mptId,
  skus,
  styleType,
  view,
  priceWithVatLabel,
  productsMptIds,
}: Props): ReactElement => {
  const t = useTranslate();

  const renderProductBoxes = (
    skus: string[],
    isSlider?: boolean,
  ): ReactElement[] => chunkArray(skus, PRODUCT_COUNT_IN_GROUP).map(
    (skuGroup: string[], index: number): ReactElement => (
      <ProductGroup
        groupNumber={index}
        isForSlider={isSlider}
        wereProductsFetched={view === VIEW_TYPE.slider}
        isFromContentList={true}
        skus={skuGroup}
        productsMptIds={productsMptIds}
        styleType={styleType}
        key={skuGroup[0]}
        className={classNames(
          'ProductListModuleV2-ProductBox',
          { 'ProductListModuleV2-ProductBox--Slider': isSlider },
        )}
      />
    ),
  );
  const additionalProps = { dataAttributes: { ...mptId && { [SCREENSHOT_TOOL_DATA_ATTR_MPT_ID]: mptId } } };

  return (
    <>
      {label && <h2 className='cds-cms-SectionTitle'>{label}</h2>}

      {isSlider && (
        <Slider
          {...SLIDER_PARAMS.default}
          {...additionalProps}
          breakpoints={{
            [BREAKPOINTS.xs]: SLIDER_PARAMS.xs,
            [BREAKPOINTS.lg]: SLIDER_PARAMS.lg,
          }}
          isLoop={false}
          isAutoplay={false}
          spaceBetween={16}
          productBoxCount={countSliderLoadedProductTiles}
          styleType={SLIDER_STYLE_TYPE.withMargin}
          containerClassName='ProductListModuleV2-SliderContainer'
        >
          {renderProductBoxes(skus, true)}
        </Slider>
      )}
      {!isSlider && (
        <div className={classNames(
          'ProductListModuleV2-ListWrapper',
          {
            'ProductListModuleV2-ListWrapper--Table':
              styleType === PRODUCT_BOX_STYLE_TYPE.table,
          },
        )}
        >
          <ProductList
            {...additionalProps}
            styleType={styleType}
            text={{
              sku: t('SKU'),
              manufacturerSku: t('Manufacturer Product ID'),
              title: t('Title'),
              price: isLoggedIn ? t('Price<br />(excl. VAT)') : priceWithVatLabel || t('Price<br />(incl. VAT)'),
              stock: t('Stock'),
            }}
          >
            {renderProductBoxes(skus)}
          </ProductList>
        </div>
      )}
    </>
  );
};
