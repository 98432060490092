import { AnalyticsEventData } from '@client/analytics/types/types';
import { logger } from '@client/common/utils/logger/logger';
import { config } from '@client/config';

class Analytics {
  public static delayTealiumEvent = 100;
  public static delayTealiumPageView = 300;

  public static isInteractionAllowed(interaction: string): boolean {
    const disabledInteractions: Array<string> = config.analytics.disabledInteractions;

    return typeof interaction === 'string' && !!interaction && !disabledInteractions.includes(interaction);
  }

  public static pageView(data: AnalyticsEventData): Promise<any> {
    if (!this.isInteractionAllowed(data?.interaction)) {
      logger.warn('Blocking VIEW interaction: ' + data?.interaction, data);
      return;
    }

    if (!window?.utagQ?.view) {
      return;
    }

    if (!data?.interaction) {
      logger.warn('Missing pageView data.interaction: ' + data?.interaction, data);
    }

    return new Promise((resolve, reject) => {
      try {
        window.utagQ.view(data);
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  public static event(data: AnalyticsEventData): Promise<any> {
    if (!this.isInteractionAllowed(data?.interaction)) {
      logger.warn('Blocking LINK interaction: ' + data?.interaction, data);
      return;
    }

    if (!window?.utagQ?.link) {
      return;
    }

    if (!data?.interaction) {
      logger.warn('Missing event data.interaction: ' + data?.interaction, data);
    }

    return new Promise((resolve, reject) => {
      try {
        window.utagQ.link(data);
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export { Analytics };
