import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CostCenterOrdersType } from 'b2b-common/core/account/api/costCenters/CostCenterOrders.types';

const NAME = 'orders';

export const ordersSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {
    orders: RemoteRecordData.getDefault(),
    search: RemoteRecordData.getDefault(),
  },
  reducers: {
    fetchCostCenterOrders: (state, action: PayloadAction<{
      startItem: number;
      pageSize: number;
    }>) => {
      state.orders = RemoteRecordData.init();
      return state;
    },
    fetchCostCenterOrdersFulfilled: (state, action: PayloadAction<CostCenterOrdersType>) => {
      state.orders = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchCostCenterOrdersRejected: (state, action: PayloadAction<ErrorType>) => {
      state.orders = RemoteRecordData.reject(action.payload);
      return state;
    },
    searchCostCenterOrders: (state, action: PayloadAction<{
      startItem: number;
      pageSize: number;
      costCenterId?: string | null;
      status: string | null;
    }>) => {
      state.search = RemoteRecordData.init();
      return state;
    },
    searchCostCenterOrdersFulfilled: (state, action: PayloadAction<CostCenterOrdersType>) => {
      state.search = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    searchCostCenterOrdersRejected: (state, action: PayloadAction<ErrorType>) => {
      state.search = RemoteRecordData.reject(action.payload);
      return state;
    },
    clearCostCenterOrdersSearch: (state) => {
      state.search = RemoteRecordData.getDefault();
      return state;
    },
  },
});

export const {
  fetchCostCenterOrders,
  fetchCostCenterOrdersFulfilled,
  fetchCostCenterOrdersRejected,
  searchCostCenterOrders,
  searchCostCenterOrdersFulfilled,
  searchCostCenterOrdersRejected,
  clearCostCenterOrdersSearch,
} = ordersSlice.actions;

const getState = (state: AppState) => state.myAccount.costCenter.orders;

export const selectCostCenterOrders = createSelector(
  getState,
  (state) => new RemoteRecord<CostCenterOrdersType>(state.orders),
);

export const selectCostCenterOrdersSearch = createSelector(
  getState,
  (state) => new RemoteRecord<CostCenterOrdersType>(state.search),
);
