import { ReactElement, useEffect, useState } from 'react';
import { useBarcodeAnalytics } from '@client/analytics/context/barcode/BarcodeAnalyticsContext';
import { useProductsAnalytics } from '@client/analytics/context/products/ProductsAnalyticsContext';
import { TEALIUM_PROD_ACTION } from '@client/analytics/types/types';
import { ADD_TO_CART_DISPLAY_TYPE, AddToCart } from '@client/cart/components/AddToCart';
import { CodeScanner } from '@client/common/components/CodeScanner/CodeScanner';
import { ProductBox } from '@client/common/components/ProductBox';
import { TOAST_TYPE } from '@client/common/components/Toast';
import { showToast } from '@client/common/utils/showToast';
import { useTranslate } from '@client/i18n/hooks';
import { Alert, ALERT_DISPLAY_TYPE, ALERT_STYLE_TYPE } from 'b2b-components/theme/components/Alert';
import { Button, BUTTON_ICON_POSITION, BUTTON_STYLE_TYPE } from 'b2b-components/theme/components/Button';
import { Icon, ICON_NAME } from 'b2b-components/theme/components/Icon';
import { Props } from './CodeScannerModal.types';
import './CodeScannerModal.scss';

export const CodeScannerModal = ({
  barcode,
  productTile,
  isProductNotFoundVisible,
  isLoadedProductVisible,
  onScan,
  onScanClear,
  onReady,
  onClose,
}: Props): ReactElement => {
  const [isComponentReady, setIsComponentReady] = useState(false);
  const t = useTranslate();

  const { analyzeBarcodeScan } = useBarcodeAnalytics();
  const { analyzeProductClick, analyzeProductAddToCart } = useProductsAnalytics();

  const handleClick = (sku: string): void => {
    analyzeProductClick({ sku, additionalId: barcode });
  };

  const handleAddToCart = (sku: string): void => {
    analyzeProductAddToCart({ sku, additionalId: barcode });
  };

  const handleCameraAccessDenied = (): void => {
    showToast(
      TOAST_TYPE.warning,
      t('Code scanner requires camera access'),
      t('Refresh and allow camera access'),
    );
    onClose();
  };

  useEffect(() => {
    setIsComponentReady(true);
  }, []);

  useEffect(() => {
    if (isComponentReady && isLoadedProductVisible) {
      analyzeBarcodeScan({ barcodeAction: TEALIUM_PROD_ACTION.productFound, barcode, sku: productTile.data.sku });
    }

    if (isComponentReady && isProductNotFoundVisible) {
      analyzeBarcodeScan({ barcodeAction: TEALIUM_PROD_ACTION.productNotFound, barcode });
    }
  }, [isLoadedProductVisible, isProductNotFoundVisible, isComponentReady]);


  if (isProductNotFoundVisible) {
    return (
      <div className="CodeScannerModal-ContentWrapper">
        <div className="CodeScannerModal-Content">
          <Alert
            className='CodeScannerModal-Alert'
            displayType={ALERT_DISPLAY_TYPE.text}
            styleType={ALERT_STYLE_TYPE.info}
            title={t('Product not found')}
          >
            {t('We could not find the item with the barcode {code} in our catalog.',
              { code: barcode })}
          </Alert>

          <Button
            className="CodeScannerModal-ScanAnotherProduct"
            iconPosition={BUTTON_ICON_POSITION.left}
            isFullWidth
            onClick={onScanClear}
          >
            <Icon name={ICON_NAME.codeScanner} />
            {t('Scan another product')}
          </Button>

          <Button
            isFullWidth
            onClick={onClose}
            styleType={BUTTON_STYLE_TYPE.secondary}
          >
            {t('Exit scanner')}
          </Button>
        </div>
      </div>
    );
  }

  if (isLoadedProductVisible && productTile.data) {
    return (
      <div className="CodeScannerModal-ContentWrapper">
        <div className="CodeScannerModal-Content">
          <ProductBox
            areVariantsHidden={true}
            isAddToCartHidden={true}
            product={productTile}
            sku={productTile.data.sku}
            onClick={handleClick}
          />

          <AddToCart
            className="CodeScannerModal-AddToCart"
            isUserCertified={true}
            productName={productTile.data.description.title}
            settings={productTile.data.settings}
            sku={productTile.data.sku}
            type={ADD_TO_CART_DISPLAY_TYPE.button}
            onClick={handleAddToCart}
          />

          <Button
            iconPosition={BUTTON_ICON_POSITION.left}
            isFullWidth
            onClick={onScanClear}
            styleType={BUTTON_STYLE_TYPE.secondary}
          >
            <Icon name={ICON_NAME.codeScanner} />
            {t('Scan another product')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <CodeScanner
      onCameraAccessDenied={handleCameraAccessDenied}
      onReady={onReady}
      onScan={onScan}
    />
  );
};
