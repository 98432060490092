import { MouseEvent, ReactElement } from 'react';
import { CERTIFICATION_INFO_TYPE, CertificationInfo } from '@client/cart/components/AddToCart/CertificationInfo';
import { Tooltip } from '@client/common/components/Tooltip';
import { useDeviceType } from '@client/common/hooks';
import { BUTTON_ICON_ICON_NAME, BUTTON_ICON_SIZE, ButtonIcon } from '@lib/components/ButtonIcon';
import classNames from 'classnames';
import { isMobile } from 'b2b-components/utils/device';
import { Props } from './';
import './Icon.scss';

export const Icon = ({
  className,
  dataAttributes,
  isBlocked,
  hasAccessCartPermissions,
  hasCertificationInfo,
  isLoggedIn,
  onAddToCart,
  settings: {
    isBuyable,
    isSellout,
    quantity,
    isDisabledByCoreAssortment,
  },
  text,
  quantityAdding,
  showUnavailable,
  isVisible,
  shouldHideOnContentMouseOver,
}: Props): ReactElement | null => {
  const deviceType = useDeviceType();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    return onAddToCart(quantityAdding);
  };
  const rootClassName = classNames(
    'AddToCart-Icon',
    {
      'AddToCart-Icon--Hidden': !isMobile(deviceType) && !isVisible,
    },
  );
  const commonProps = {
    className: className,
    dataAttributes: dataAttributes,
    icon: BUTTON_ICON_ICON_NAME.cart,
    size: BUTTON_ICON_SIZE.small,
    title: text.addToCart,
  };

  switch (true) {
    case isDisabledByCoreAssortment:
      return (
        <div className={rootClassName}>
          <Tooltip
            content={text.notInCoreAssortment}
            isApplied
            wrapperClassName="AddToCart-TooltipWrapper"
            contentClassName="AddToCart-Tooltip--CoreAssortment"
            shouldHideOnContentMouseOver={shouldHideOnContentMouseOver}
          >
            <ButtonIcon {...commonProps} isDisabled />
          </Tooltip>
        </div>
      );
    case (isSellout && quantity <= 0) || !isBuyable: {
      if (showUnavailable) {
        return (
          <div className={rootClassName}>
            <Tooltip content={text.notAvailable} isApplied shouldHideOnContentMouseOver={shouldHideOnContentMouseOver}>
              <ButtonIcon {...commonProps} isDisabled={true} />
            </Tooltip>
          </div>
        );
      }

      return null;
    }
    case hasCertificationInfo:
      return (
        <CertificationInfo className={className} type={CERTIFICATION_INFO_TYPE.icon} />
      );
    default:
      return (
        <div className={rootClassName}>
          <Tooltip
            isApplied={isLoggedIn && !hasAccessCartPermissions}
            content={text.notPermitted}
            shouldHideOnContentMouseOver={shouldHideOnContentMouseOver}
          >
            <ButtonIcon
              {...commonProps}
              isDisabled={isBlocked || (isLoggedIn && !hasAccessCartPermissions)}
              onClick={handleClick}
            />
          </Tooltip>
        </div>
      );
  }
};
