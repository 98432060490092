import { ReactElement } from 'react';
import { ProductBox } from '@client/common/components/ProductBox';
import { Props } from './';

export const ProductGroup = ({
  skus,
  productTiles,
  onProductClick,
  onProductAddToCart,
  styleType,
  absolutePosition = 0,
  isForSlider,
  isFromContentList,
  className,
  productsMptIds,
  showDirectDelivery,
}: Props): ReactElement => (
  <>
    {skus.map((sku: string, index): ReactElement => {
      const listPosition = absolutePosition + index + 1;

      return (
        <ProductBox
          mptId={productsMptIds?.[sku]}
          sku={sku}
          product={productTiles[sku]}
          styleType={styleType}
          isForSlider={isForSlider}
          isFromContentList={isFromContentList}
          onClick={(sku) => onProductClick({ sku, listPosition })}
          onAddToCart={(sku) => onProductAddToCart({ sku, listPosition })}
          key={sku}
          className={className}
          showDirectDelivery={showDirectDelivery}
        />
      );
    })}
  </>
);
