import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCartStatus, selectCartStatusData } from '@client/cart/redux/cartStatus/redux';
import { getIsLoggedIn } from '@client/context/redux/redux';
import { CartStatusType } from 'b2b-common/core/cart/api/CartTypes';

export const useCartStatusEffect = (): CartStatusType => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const cartStatusData = useSelector(selectCartStatusData);

  useEffect(
    (): void => {
      if (isLoggedIn) {
        dispatch(fetchCartStatus());
      }
    },
    [isLoggedIn],
  );

  return cartStatusData;
};
