import {
  barcodeAnalyticsSaga,
  cartAnalyticsSaga,
  checkoutAnalyticsSaga,
  contentAnalyticsSaga,
  genericAnalyticsSaga,
  menuAnalyticsSaga,
  productsAnalyticsSaga,
  searchAnalyticsSaga,
  tealiumSagas,
} from '@client/analytics/redux';
import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';

function* analyticsSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    fork(barcodeAnalyticsSaga),
    fork(cartAnalyticsSaga),
    fork(checkoutAnalyticsSaga),
    fork(genericAnalyticsSaga),
    fork(menuAnalyticsSaga),
    fork(productsAnalyticsSaga),
    fork(searchAnalyticsSaga),
    fork(tealiumSagas),
    fork(contentAnalyticsSaga),
  ]);
}

export {
  analyticsSaga,
};
