import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';
import { rmaSaga } from './rma/sagas';
import { rmasSaga } from './rmas/sagas';

export function* rmaSagas(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    fork(rmasSaga),
    fork(rmaSaga),
  ]);
}
