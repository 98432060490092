import {
  addItemsToCartFulfilled as addCartItemsFulfilled,
  addItemsToCartRejected as addCartItemsRejected,
} from '@client/cart/redux/cart/redux';
import { fetchCartStatus } from '@client/cart/redux/cartStatus/redux';
import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { MY_ACCOUNT_MESSAGE, showMyAccountToast } from '@client/myAccount/common/utils/messages';
import { CostCenterOrders } from '@client/myAccount/costCenters/api';
import { ConfirmationStatus } from '@client/myAccount/costCenters/components/OrderRequests/ConfirmationPage';
import {
  addOrderRequestToCart,
  addOrderRequestToCartFulfilled,
  addOrderRequestToCartRejected,
  approveOrderRequest,
  approveOrderRequestFulfilled,
  approveOrderRequestRejected,
  fetchOrderRequestDetail,
  fetchOrderRequestDetailFulfilled,
  fetchOrderRequestDetailRejected,
  fetchOrderRequestVerificationData,
  fetchOrderRequestVerificationDataFulfilled,
  fetchOrderRequestVerificationDataRejected,
  rejectOrderRequest,
  rejectOrderRequestFulfilled,
  rejectOrderRequestRejected,
  sendOrderRequestToSales,
  sendOrderRequestToSalesFulfilled,
  sendOrderRequestToSalesRejected,
} from '@client/myAccount/costCenters/redux/orderRequest/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { ROUTES } from 'b2b-apps/core/routes/components/Router';
import { pushToRoute } from 'b2b-apps/core/routes/redux/utils';
import { fetchUserStats } from 'b2b-apps/core/user-stats';
import { ExtendedCostCenterOrderType } from 'b2b-common/core/account/api/costCenters/CostCenterOrder.types';
import { AddItemsToCartType } from 'b2b-common/core/cart/api/CartTypes';

function* fetchOrderDetailSaga(action: ReturnType<typeof fetchOrderRequestDetail>):
  Generator<CallEffect | PutEffect, void, AxiosResponse<ExtendedCostCenterOrderType>> {
  const orderId = action.payload;

  try {
    const response = yield call(
      requestApiSaga,
      CostCenterOrders.get(orderId),
    );

    yield put(fetchOrderRequestDetailFulfilled({ orderId, data: response.data }));
  } catch (error) {
    yield put(fetchOrderRequestDetailRejected({ orderId, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: { orderId: action.payload } }));
    showMyAccountToast(MY_ACCOUNT_MESSAGE.errorGetOrderDetail);
  }
}

function* fetchOrderDetailVerificationDataSaga(action: ReturnType<typeof fetchOrderRequestVerificationData>):
  Generator<CallEffect | PutEffect, void, AxiosResponse<any>> {
  const orderId = action.payload;

  try {
    const response = yield call(
      requestApiSaga,
      CostCenterOrders.getOrderVerification(orderId),
    );

    yield put(fetchOrderRequestVerificationDataFulfilled({ orderId, items: response.data }));
  } catch (error) {
    yield put(fetchOrderRequestVerificationDataRejected({ orderId, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: { orderId: action.payload } }));
  }
}

function* approveOrderRequestSaga(action: ReturnType<typeof approveOrderRequest>):
  Generator<CallEffect | PutEffect | ReturnType<typeof pushToRoute>, void, AxiosResponse<any>> {
  const { orderId, remarks } = action.payload;

  try {
    const response = yield call(
      requestApiSaga,
      CostCenterOrders.approveOrder(orderId, remarks),
    );

    yield put(approveOrderRequestFulfilled({ orderId, requesterEmail: response.data?.requester?.email }));
    yield put(fetchUserStats());

    yield pushToRoute(ROUTES.myAccountCostCenterOrderRequestConfirmation, {
      id: orderId,
      status: ConfirmationStatus.approved,
    });
  } catch (error) {
    yield put(approveOrderRequestRejected({ orderId, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* rejectOrderRequestSaga(action: ReturnType<typeof rejectOrderRequest>):
  Generator<CallEffect | PutEffect | ReturnType<typeof pushToRoute>, void, AxiosResponse<any>> {
  const { orderId, remarks } = action.payload;

  try {
    const response = yield call(
      requestApiSaga,
      CostCenterOrders.rejectOrder(orderId, remarks),
    );

    yield put(rejectOrderRequestFulfilled({ orderId, requesterEmail: response.data?.requester?.email }));
    yield put(fetchUserStats());

    yield pushToRoute(ROUTES.myAccountCostCenterOrderRequestConfirmation, {
      id: orderId,
      status: ConfirmationStatus.rejected,
    });
  } catch (error) {
    yield put(rejectOrderRequestRejected({ orderId, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
    showMyAccountToast(MY_ACCOUNT_MESSAGE.errorRejectOrder);
  }
}

function* sendOrderRequestToSalesSaga(action: ReturnType<typeof sendOrderRequestToSales>):
  Generator<
    CallEffect | PutEffect | ReturnType<typeof pushToRoute>,
    void,
    AxiosResponse<any>
  > {
  const { orderId, remarks } = action.payload;

  try {
    const response = yield call(
      requestApiSaga,
      CostCenterOrders.sendToSales(orderId, remarks),
    );

    yield put(sendOrderRequestToSalesFulfilled({ orderId, requesterEmail: response.data?.requester?.email }));
    yield put(fetchUserStats());

    yield pushToRoute(ROUTES.myAccountCostCenterOrderRequestConfirmation, {
      id: orderId,
      status: ConfirmationStatus.sentToSales,
    });
  } catch (error) {
    yield put(sendOrderRequestToSalesRejected({ orderId, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
    showMyAccountToast(MY_ACCOUNT_MESSAGE.errorSendOrderToSales);
  }
}

function* addToCartSaga(action: ReturnType<typeof addOrderRequestToCart>):
  Generator<
    CallEffect | PutEffect,
    any,
    AxiosResponse<AddItemsToCartType>
  > {
  const { orderUuid, copyArticleReferences } = action.payload;

  try {
    const response = yield call(requestApiSaga, CostCenterOrders.addToCart(orderUuid, copyArticleReferences));
    yield put(addOrderRequestToCartFulfilled(response.data));
    yield put(addCartItemsFulfilled(response.data));
    yield put(fetchCartStatus());
  } catch (error) {
    const parsedError = parseError(error);
    yield put(addOrderRequestToCartRejected(parsedError));
    yield put(addCartItemsRejected(parsedError));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* orderDetailSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(fetchOrderRequestDetail.type, fetchOrderDetailSaga),
    takeLatest(fetchOrderRequestVerificationData.type, fetchOrderDetailVerificationDataSaga),
    takeLatest(approveOrderRequest.type, approveOrderRequestSaga),
    takeLatest(rejectOrderRequest.type, rejectOrderRequestSaga),
    takeLatest(sendOrderRequestToSales.type, sendOrderRequestToSalesSaga),
    takeLatest(addOrderRequestToCart.type, addToCartSaga),
  ]);
}
