import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CartStatusType } from 'b2b-common/core/cart/api/CartTypes';

const NAME = 'cartStatus';

const initialData: CartStatusType = {
  count: 0,
  lastInsertedQuantity: null,
  messages: [],
  value: 0,
  isLastInsertedNewItem: false,
};

export const cartStatusSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: Object.assign(
    RemoteRecordData.getDefault(),
    {
      data: initialData,
    },
  ),
  reducers: {
    clearCartStatus: () => RemoteRecordData.fulfill(initialData),
    fetchCartStatus: (state) => Object.assign(RemoteRecordData.init(), {
      data: state.data,
    }),
    fetchCartStatusFulfilled: (
      state,
      action: PayloadAction<CartStatusType>,
    ) => RemoteRecordData.fulfill(action.payload),
    replaceCartStatus: (
      state,
      action: PayloadAction<CartStatusType>,
    ) => RemoteRecordData.fulfill(action.payload),
    fetchCartStatusRejected: (
      state,
      action: PayloadAction<ErrorType>,
    ) => RemoteRecordData.reject(action.payload),
  },
});

export const {
  clearCartStatus,
  fetchCartStatus,
  fetchCartStatusFulfilled,
  fetchCartStatusRejected,
  replaceCartStatus,
} = cartStatusSlice.actions;

export const ACTIONS_TO_SYNC = [
  clearCartStatus.type,
  fetchCartStatus.type,
  fetchCartStatusFulfilled.type,
  fetchCartStatusRejected.type,
  replaceCartStatus.type,
];

const getState = (state: AppState) => state.cart.cartStatus;

export const selectCartStatusData = createSelector(
  getState,
  (record) => {
    if (record.data) {
      return record.data;
    }

    return {
      count: 0,
      lastInsertedQuantity: null,
      messages: [],
      value: 0,
      isLastInsertedNewItem: false,
    };
  },
);
