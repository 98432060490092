import { toast } from 'react-toastify';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { castToConstant } from '@client/common/utils/api/api';
import { ensureArray } from '@client/common/utils/array/array';
import { getDate } from '@client/common/utils/date/date';
import { ProductToast } from '@client/product/components/ProductToast';
import { ENERGY_LABEL_RANGE, ENERGY_LABEL_RATING } from 'b2b-components/theme/components/EnergyLabel';
import { ProductQuantityOrderedType } from 'b2b-common/core/product/api/Product.types';
import { ImagesType } from 'b2b-common/core/product/api/ProductGallery.types';
import { ProductTileType } from 'b2b-common/core/product/api/ProductTile.types';

enum PRODUCT_MESSAGES {
  pdfExportError = 'pdfExportError',
  wishlistsSearchError = 'wishlistsSearchError',
}

const showProductToast = (messageId: PRODUCT_MESSAGES): void => {
  toast(<ProductToast messageId={messageId} />);
};

const getProductEnergyLabel = (
  energyLabel: {
    range: string;
    value: string;
    image?: ImagesType;
  } | null,
): {
  range: ENERGY_LABEL_RANGE;
  value: ENERGY_LABEL_RATING;
  image: ImagesType;
} | null => {
  let range;
  let value;
  let image;

  if (energyLabel) {
    range = castToConstant(energyLabel.range, ENERGY_LABEL_RANGE) as ENERGY_LABEL_RANGE;
    value = castToConstant(energyLabel.value, ENERGY_LABEL_RATING) as ENERGY_LABEL_RATING;
    image = energyLabel?.image || null;
  }

  if (!energyLabel || !value || !range) {
    return null;
  }

  return {
    range,
    value,
    image,
  };
};

const mapResponseProductQuantityOrderedToState = (
  quantityOrdered: ProductQuantityOrderedType,
): ProductQuantityOrderedType => ensureArray(quantityOrdered).map((order) => ({
  deliveryDate: order.deliveryDate ? getDate(order.deliveryDate) : null,
  quantityOrdered: parseInt(order.quantityOrdered, 10),
}));

const isProductGroupLoaded = (
  skus: string[],
  productTilesData: Record<string, RemoteRecord<ProductTileType>>,
): boolean => skus.filter((sku) => !productTilesData[sku]?.wasStarted).length === 0;

const PRODUCT_COUNT_IN_GROUP = 24;

export {
  getProductEnergyLabel,
  isProductGroupLoaded,
  mapResponseProductQuantityOrderedToState,
  PRODUCT_COUNT_IN_GROUP,
  PRODUCT_MESSAGES,
  showProductToast,
};
