import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import {
  CloneWishlistRequestData,
  RequestCreateWishlistType,
  RequestUpdateWishlistItemQuantityType,
  RequestUpdateWishlistItemReferenceType,
  RequestUpdateWishlistNameType,
  RequestUpdateWishlistReferenceType,
  SendToSalesDataType,
  WishlistAddItemsRequestData,
  WishlistToCartRequestData,
} from 'b2b-common/core/account/api/wishlists';

export class Wishlist {
  public static addItems(wishlistId: string, skus: string[]): RequestConfigType {
    const data: WishlistAddItemsRequestData = { items: skus.map((sku) => ({ sku: sku, quantity: 1 })) };
    return RequestConfig.post(`/wishlist/${wishlistId}/items`, data,
      { withLocalization: false });
  }

  public static wishlistToCart(wishlistId: string, skus: string[]): RequestConfigType {
    const data: WishlistToCartRequestData = { wishlistId, skus };
    return RequestConfig.post('/wishlist/wishlist-to-cart', data,
      { withLocalization: false });
  }

  public static create(data: RequestCreateWishlistType): RequestConfigType {
    return RequestConfig.post('/wishlist', data);
  }

  public static clone(wishlistId: string, name: string): RequestConfigType {
    const data: CloneWishlistRequestData = { name };
    return RequestConfig.post(`wishlist/${wishlistId}/clone`, data,
      { withLocalization: false });
  }

  public static fetch(wishlistId: string): RequestConfigType {
    return RequestConfig.get(`wishlist/${wishlistId}`);
  }

  public static updateName(wishlistId: string, data: RequestUpdateWishlistNameType): RequestConfigType {
    return RequestConfig.patch(`wishlist/${wishlistId}/name`, data,
      { withLocalization: false });
  }

  public static updateReference(wishlistId: string, reference: string): RequestConfigType {
    const data: RequestUpdateWishlistReferenceType = { reference };
    return RequestConfig.patch(`wishlist/${wishlistId}/reference`, data,
      { withLocalization: false });
  }

  public static updateWishlistItemQuantity(
    wishlistId: string, data: RequestUpdateWishlistItemQuantityType,
  ): RequestConfigType {
    return RequestConfig.patch(`wishlist/${wishlistId}/item/quantity`, data,
      { withLocalization: false });
  }

  public static updateWishlistItemReference(
    wishlistId: string, data: RequestUpdateWishlistItemReferenceType,
  ): RequestConfigType {
    return RequestConfig.patch(`wishlist/${wishlistId}/item/reference`, data,
      { withLocalization: false });
  }

  public static remove(wishlistId: string): RequestConfigType {
    return RequestConfig.delete(`wishlist/${wishlistId}`,
      { withLocalization: false });
  }

  public static removeWishlistItem(wishlistId: string, sku: string): RequestConfigType {
    return RequestConfig.delete(`wishlist/${wishlistId}/item`, {
      data: { sku },
      withLocalization: false,
    });
  }

  public static removeWishlistItems(wishlistId: string, skus: string[]): RequestConfigType {
    return RequestConfig.delete(`wishlist/${wishlistId}/items`, {
      data: { skus },
      withLocalization: false,
    });
  }

  public static generateCSV(wishlistId: string): RequestConfigType {
    return RequestConfig.get(`wishlist/${wishlistId}/csv`,
      { withLocalization: true });
  }

  public static sendToSales(wishlistId: string, data: SendToSalesDataType): RequestConfigType {
    return RequestConfig.post(`wishlist/${wishlistId}/send-to-sales`, data);
  }
}
