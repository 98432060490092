import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { RMA_TYPE } from '@client/myAccount/rma/utils/rma';
import { CreateRmaBodyType, UpdateRmaBodyType } from 'b2b-common/core/account/api/rma';

export class Rma {
  public static create(type: RMA_TYPE, data: CreateRmaBodyType): RequestConfigType {
    return RequestConfig.post(`/account/rma/${type}`, data,
      { withLocalization: false });
  }

  public static fetch(rmaId: string, debitorId?: string): RequestConfigType {
    let url = `/account/rma/${rmaId}`;

    if (debitorId) {
      url += `?debitorId=${debitorId}`;
    }

    return RequestConfig.get(url, { withLocalization: false });
  }

  public static fetchInvoice(invoiceId: string, debitorId?: string): RequestConfigType {
    let url = `/account/rma/invoice/${invoiceId}`;

    if (debitorId) {
      url += `?debitorId=${debitorId}`;
    }

    return RequestConfig.get(url, { withLocalization: false });
  }

  public static fetchInvoiceAddress(invoiceId: string): RequestConfigType {
    const url = `/account/rma/invoice/${invoiceId}/delivery-address`;

    return RequestConfig.get(url, { withLocalization: false });
  }

  public static cancel(rmaId: string): RequestConfigType {
    return RequestConfig.post(`/account/rma/${rmaId}/close`, {},
      { withLocalization: false });
  }

  public static update(rmaId: string, data: UpdateRmaBodyType): RequestConfigType {
    return RequestConfig.put(`/account/rma/${rmaId}`, data,
      { withLocalization: false });
  }

  public static sendMail(debitorId: string, rmaId: string, email: string): RequestConfigType {
    const data = { emailAddress: email };
    return RequestConfig.post(
      `/account/rma/${rmaId}/send-return-shipment-note?debitorId=${debitorId}`, data,
      { withLocalization: false },
    );
  }
}
