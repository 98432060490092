import { Search } from '@client/catalog/api/Search';
import {
  fetchInkManufacturers,
  fetchInkManufacturersFulfilled,
  fetchInkManufacturersRejected,
  fetchInkModels,
  fetchInkModelsFulfilled,
  fetchInkModelsRejected,
  toggleInkWidget,
} from '@client/catalog/redux/inkFinder/redux';
import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { setCookie } from '@client/common/utils/storage/cookies';
import { getActiveLanguage } from '@client/i18n/redux/redux';
import { AxiosResponse } from 'axios';
import {
  all,
  AllEffect,
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeLatest,
} from 'redux-saga/effects';
import { CatalogSearchType } from 'b2b-common/core/catalog/Catalog.types';
import { LANGUAGE_CODE } from 'b2b-common/core/i18n/consts';

export const INK_FINDER_WIDGET_COOKIE_KEY = 'isInkWidgetOpen';

export enum FETCH_MANUFACTURERS_QUERY {
  de = 'path=/computing-software/peripherie/tinte-toner&searchEarlyFilter=true&format=json',
  fr = 'path=/fr/computing-logiciels/peripheriques/encre-toner&searchEarlyFilter=true&format=json',
}

function* fetchInkManufacturersSaga(
  action: ReturnType<typeof fetchInkManufacturers>,
): Generator<
  SelectEffect | CallEffect | PutEffect,
  void,
  LANGUAGE_CODE & AxiosResponse<CatalogSearchType>
> {
  const language = yield select(getActiveLanguage);
  const fetchManufacturersQuery = FETCH_MANUFACTURERS_QUERY[language];

  try {
    const response = yield call(requestApiSaga, Search.fetch(fetchManufacturersQuery));
    yield put(
      fetchInkManufacturersFulfilled(
        Search.mapResponseToInkManufacturersState(response),
      ),
    );
  } catch (error) {
    yield put(fetchInkManufacturersRejected(parseError(error)));
    yield put(reportError({ message: action.type, error }));
  }
}

function* fetchInkModelsSaga(
  action: ReturnType<typeof fetchInkModels>,
): Generator<CallEffect | PutEffect, void, AxiosResponse<CatalogSearchType>> {
  const query = action.payload;

  try {
    const response = yield call(requestApiSaga, Search.fetch(query));
    yield put(fetchInkModelsFulfilled(Search.mapResponseToInkModelsState(response)));
  } catch (error) {
    yield put(fetchInkModelsRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: { error: action.payload } }));
  }
}

function* toggleInkWidgetSaga(
  action: ReturnType<typeof toggleInkWidget>,
): Generator {
  const isOpen = action.payload;
  yield setCookie(INK_FINDER_WIDGET_COOKIE_KEY, +!isOpen);
}

export function* inkFinderSaga(): Generator<AllEffect<ForkEffect>> {
  yield all([
    takeLatest(fetchInkManufacturers.type, fetchInkManufacturersSaga),
    takeLatest(fetchInkModels.type, fetchInkModelsSaga),
    takeLatest(toggleInkWidget.type, toggleInkWidgetSaga),
  ]);
}
