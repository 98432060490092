import { AppState } from '@client/common/redux/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const NAME = 'punchout';

export const punchoutSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: { body: null },
  reducers: {
    setPunchout: (state, action: PayloadAction<{ body: string }>) => action.payload,
  },
});

export const { setPunchout } = punchoutSlice.actions;

export const getPunchoutBody = (state: AppState): string => state.punchout.body;
