import { DEFAULT_PER_PAGE, DEFAULT_SORTING } from '@client/catalog/hooks';
import { ASSORTMENT_FILTERS, FACET_FILTERS, FILTERS } from '@client/catalog/utils/facets';
import { RANGE_VALUE_TYPE } from '@client/common/components/Form/FormFieldRange';
import { isMobile } from '@client/common/utils/env';
import { isObject } from '@client/common/utils/object/object';
import { parse, stringify } from '@client/common/utils/queryParams';
import { DEVICE_TYPE } from 'b2b-components/utils/device';
import {
  FilterGroup,
  FiltersValuesType,
  PaginationFiltersType,
  RangeValueType,
  SiteType,
} from 'b2b-common/core/catalog/Catalog.types';

const MOBILE_PAGE_SIZE = 48;

const getFilterFromSearchParams = (filtersId: string, search?: string) => parse(search)[filtersId];

const getFiltersValuesFromSearchParams = (
  filtersId: string,
  search?: string,
): FiltersValuesType => getFilterFromSearchParams(filtersId, search) || {};

const getBrandFilterFromSearchParams = (search?: string): string => (
  getFiltersValuesFromSearchParams(FILTERS.facetFilters, search)[FACET_FILTERS.manufacturer]?.[0] || ''
);

const getFilterValueFromSearchParams = (
  filtersId: string,
  search?: string,
): Record<string, any> | string => getFilterFromSearchParams(filtersId, search) || null;

const translateFilterOption = (
  t: (...args: any[]) => any,
  filterGroupField: string,
  value: string,
): string => {
  const translations: Record<string, Record<string, string>> = {
    [FACET_FILTERS.art]: {
      new: t('New', {}, 'Angebote filter option'),
      occassion: t('Verified return', {}, 'Angebote filter option'),
      offer: t('Offer', {}, 'Angebote filter option'),
      sale: t('Sale', {}, 'Angebote filter option'), // jamei sellout
      trade: t('Sale', {}, 'Angebote filter option'), // alltron sellout
      sustainableAlternative: t('Conscious alternative', {}, 'Angebote filter option'),
    },
    [FACET_FILTERS.customerAssortment]: {
      [ASSORTMENT_FILTERS.coreAssortment]: t('Core assortment only', {}, 'customerAssortment filter option'),
      [ASSORTMENT_FILTERS.ownAssortment]: t('Customer specific assortment only', {}, 'customerAssortment filter option'),
    },
  };

  if (translations.hasOwnProperty.call(translations, filterGroupField)
    && Object.prototype.hasOwnProperty.call(translations[filterGroupField], value)) {
    return translations[filterGroupField][value] as string;
  }

  return value;
};

const isFilterOptionAvailable = (
  filterGroupField: string,
  value: string,
): boolean => {
  if (filterGroupField !== FACET_FILTERS.art) {
    return true;
  }

  return ['new', 'occassion', 'offer', 'sale', 'trade', 'sustainableAlternative'].includes(value);
};

const isPageSizeAvailable = (pageSize: number): boolean => {
  const TEST_ONLY_PAGE_SIZE = [9, 12];
  return !TEST_ONLY_PAGE_SIZE.includes(pageSize);
};

const sanitizePageSizes = (
  site: SiteType | null | undefined,
): SiteType | null | undefined => {
  if (site) {
    const { allowedProductsPerPage } = site.listView;
    site.listView.allowedProductsPerPage = allowedProductsPerPage.filter(
      (productsPerPage) => isPageSizeAvailable(productsPerPage.numberOfProducts),
    );
  }

  return site;
};

const sanitizeFilters = (filters: FilterGroup[]): FilterGroup[] => filters.map((filter) => {
  filter.elements = filter.elements.filter(({ value }) => isFilterOptionAvailable(filter.field, value));
  return filter;
});

const isSectionNotEmpty = (values: string[] | RangeValueType): boolean => {
  if (!values) {
    return false;
  }

  if (Array.isArray(values)) {
    return values.length > 0;
  }

  return values.min !== undefined || values.max !== undefined;
};

const isRangeFilter = (
  values: Record<string, any>,
): boolean => Object.prototype.hasOwnProperty.call(values, RANGE_VALUE_TYPE.min)
  || Object.prototype.hasOwnProperty.call(values, RANGE_VALUE_TYPE.max);

const transformRangeObjectIntoArray = (
  values: RangeValueType,
): number[] => {
  const min = values.min || 0;
  const max = values.max || Number.MAX_SAFE_INTEGER;

  const minNumber = parseFloat(`${min}`);
  const maxNumber = parseFloat(`${max}`);

  return [minNumber, maxNumber];
};

const prepareFilters = (
  filters: FiltersValuesType | null | undefined,
): string => {
  if (!filters) {
    return '';
  }

  const GROUP_SEPARATOR = '|||';
  const VALUE_SEPARATOR = '~~~';
  const NAME_SEPARATOR = ':::';
  return Object.keys(filters).reduce((filtersUrl, filterName) => {
    if (filters && isSectionNotEmpty(filters[filterName])) {
      if (filtersUrl !== '') {
        filtersUrl += GROUP_SEPARATOR;
      }

      let values = filters[filterName];

      if (isRangeFilter(values)) {
        values = transformRangeObjectIntoArray(values);
      }

      filtersUrl += `${filterName}${NAME_SEPARATOR}${values.join(
        VALUE_SEPARATOR,
      )}`;
    }

    return filtersUrl;
  }, '');
};

const getCatalogSearchQuery = (
  categoryFilter: string,
  facetFilters: string,
  paginationFilters: PaginationFiltersType,
  searchFilter: string,
): string => {
  const { pageSize, page, sorting } = paginationFilters;
  const params = {
    path: categoryFilter || undefined,
    limit: pageSize,
    page,
    ...sorting && { sortProducts: sorting },
    filters: facetFilters || undefined,
    query: searchFilter || undefined,
  };

  return stringify(params);
};

const getPaginationFilters = (search: string, deviceType: DEVICE_TYPE, pageSize: number): PaginationFiltersType => {
  const defaultFilters = getFiltersValuesFromSearchParams(FILTERS.pagination, search);

  return {
    pageSize: isMobile(deviceType)
      ? MOBILE_PAGE_SIZE
      : defaultFilters.pageSize || pageSize || DEFAULT_PER_PAGE,
    sorting: defaultFilters.sorting || DEFAULT_SORTING,
    page: defaultFilters.page || 1,
  };
};

const getCategoryFilter = (search: string): string => {
  const categoryFilter = getFilterValueFromSearchParams(
    FILTERS.category,
    search,
  );

  if (isObject(categoryFilter)) {
    return categoryFilter.category;
  }

  return categoryFilter;
};

export {
  getBrandFilterFromSearchParams,
  getCatalogSearchQuery,
  getCategoryFilter,
  getFiltersValuesFromSearchParams,
  getFilterValueFromSearchParams,
  getPaginationFilters,
  isFilterOptionAvailable,
  isPageSizeAvailable,
  prepareFilters,
  sanitizeFilters,
  sanitizePageSizes,
  translateFilterOption,
};
