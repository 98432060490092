import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { AxiosResponse } from 'axios';

class ProductAccessories {
  public static fetch(sku: string): RequestConfigType {
    return RequestConfig.get(
      `/products/${sku}/accessories`,
    );
  }

  public static mapResponseToState(response: AxiosResponse): any {
    return response.data;
  }
}

export { ProductAccessories };
