import { ReactElement } from 'react';
import classNames from 'classnames';
import { Props } from '../';
import './SectionTitle.scss';

export const SectionTitle = ({ className, componentData }: Props): ReactElement | null => {
  if (!componentData.text) {
    return null;
  }

  return (
    <div className={classNames(className)}>
      <div className="cds-cms-ModuleContainer cds-cms-SectionTitleContainer">
        <h2 className="cds-cms-SectionTitle">{componentData.text}</h2>
      </div>
    </div>
  );
};
