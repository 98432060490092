import { CartApi } from '@client/cart/api/CartApi';
import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';

export class CartCostCenter extends CartApi {
  public static url = `${this.baseUrl}/cost-center`;

  public static get(costCenterId: string): RequestConfigType {
    return RequestConfig.get(
      `${this.url}/${costCenterId}`,
      {
        withLocalization: false,
      },
    );
  }

  public static set(costCenterId: string): RequestConfigType {
    return RequestConfig.post(
      this.url,
      { costCenterId },
      {
        withLocalization: false,
      },
    );
  }
}
