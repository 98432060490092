export enum CMS_MODULE_COMPONENT {
  banner = 'banner',
  card = 'card',
  contact = 'contact_info',
  form = 'form',
  gallery = 'gallery',
  mptId = 'helper_mpt_id',
  socialMedia = 'helper_socialmedia',
  iframe = 'iframe',
  linkTile = 'link_tile',
  logoSlider = 'logo_slider',
  navigation = 'navigation',
  productsGroup = 'products_group',
  productsGroupV2 = 'products_group_v2',
  productsList = 'products_list',
  textEditor = 'rich_text_editor',
  sectionTitle = 'section_title',
  textMedia = 'text_media',
}
