import { ReactElement } from 'react';
import { SUGGESTION_TYPE } from '@client/app/components/App/Header/Autosuggest';
import { useTranslate } from '@client/i18n/hooks';
import classNames from 'classnames';
import { SuggestionType } from 'b2b-common/core/catalog/Suggestions.types';
import { Props } from './SearchSuggestions.types';

export const SearchSuggestions = ({
  activeItemIndex,
  onSearchSuggestionClick,
  getSearchSuggestionUrl,
  onMouseEnter,
  suggestions,
}: Props): ReactElement | null => {
  const t = useTranslate();
  if (!Array.isArray(suggestions) || suggestions.length === 0) {
    return null;
  }

  return (
    <>
      <div className='Autosuggest-Title'>{t('search suggestions')}</div>
      <ul className='Autosuggest-List'>
        {suggestions.map((suggestion: SuggestionType, index: number) => {
          const url = getSearchSuggestionUrl(suggestion.term, suggestion.restTerm);
          const linkClassName = classNames('Autosuggest-ListLink', {
            'Autosuggest-ListLink--Active': index === activeItemIndex,
          });

          return (
            <li
              key={suggestion.restTerm + index}
              onMouseEnter={onMouseEnter(SUGGESTION_TYPE.search, index)}
            >
              <a
                href={url}
                onClick={onSearchSuggestionClick(url)}
                className={linkClassName}
              >
                {suggestion.term}
                <strong>{suggestion.restTerm}</strong>
              </a>
            </li>
          );
        })}
      </ul>
    </>
  );
};
