import { useDispatch } from 'react-redux';
import { addToWishlist } from '@client/myAccount/wishlists/redux/wishlist/redux';
import { bindActionCreators } from '@reduxjs/toolkit';

export const useAddToWishlistAction = (): typeof addToWishlist => {
  const dispatch = useDispatch();

  return bindActionCreators(addToWishlist, dispatch);
};

