
import { assortmentSaga as commonAssortmentSaga } from '@client/assortment/redux/assortment/sagas';
import { ownAssortmentSaga } from '@client/assortment/redux/ownAssortment/sagas';
import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';

export function* assortmentSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    fork(commonAssortmentSaga),
    fork(ownAssortmentSaga),
  ]);
}
