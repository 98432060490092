import { ReactElement, useEffect } from 'react';
import { ExternalLink } from '@client/common/components/ExternalLink';
import { useEntries, useFetchSingleNewsAssetsAction } from '@client/content/hooks';
import { useTranslate } from '@client/i18n/hooks';
import { isExternalUrl } from '@client/routes/utils/url';
import { Icon, ICON_NAME, ICON_SIZE } from '@lib/components/Icon';
import classNames from 'classnames';
import { LOADED_CONTENT_TYPE_ID } from 'b2b-common/core/content/utils/utils';
import { Asset } from '../NewsroomAssets/Asset';
import { Props } from '../';
import './NewsroomFooter.scss';

export const NewsroomFooter = ({ className, componentData }: Props): ReactElement => {
  const t = useTranslate();
  const fetchSingleNewsAssetsAction = useFetchSingleNewsAssetsAction();
  const entriesIds = Object
    .keys(componentData)
    .filter(
      (entryId) => componentData[entryId].some((el: string) => el.includes(LOADED_CONTENT_TYPE_ID.assets)),
    );

  useEffect(
    () => {
      fetchSingleNewsAssetsAction({ entriesIds });
    },
    [entriesIds],
  );

  const assetsData = useEntries(entriesIds);

  if (!assetsData) {
    return null;
  }

  const linksAssets = Array.isArray(assetsData)
      && assetsData.filter(asset => asset?.data?.type === 'URL');
  const filesAssets = Array.isArray(assetsData)
      && assetsData.filter(asset => asset?.data?.type !== 'URL');

  return (
    <div className={classNames('cds-cms-NewsroomFooter', className)}>
      {filesAssets.length > 0 && (
        <div className="cds-cms-NewsroomFooter-Files">
          <h4 className="cds-cms-NewsroomFooter-TitleFiles">{t('PRESS RELEASE ASSETS')}</h4>
          <div className="row">
            {filesAssets.map((file: Record<string, any>, index: number) => file.isLoaded && file.data.asset && (
              <Asset
                fileName={file.data.asset.filename}
                thumbnail={file.data.asset.url}
                url={file.data.asset.url}
                className="col-lg-3 col-md-4 col-sm-6 col-xs-12"
                key={index}
              />
            ))}
          </div>
        </div>
      )}

      {linksAssets.length > 0 && (
        <div className="cds-cms-NewsroomFooter-Links">
          <div className="row">
            <h4 className="cds-cms-NewsroomFooter-TitleLinks">{t('Links: ')}</h4>
            <ul className="cds-List cds-List--Bulleted cds-cms-NewsroomFooter-LinksList">
              {linksAssets.map(
                (link: any, index) => link.data && (
                  <li key={index}>
                    {isExternalUrl(link.data.url)
                      ? <ExternalLink
                        className="cds-cms-NewsroomFooter-Link"
                        url={link.data.url}
                        label={link.data.label}
                      />
                      : <a
                        className="cds-Link cds-cms-NewsroomFooter-Link"
                        title={link.data.title}
                        href={link.data.url}
                      >
                        {link.data.label}
                        {isExternalUrl(link.data.url) && <Icon name={ICON_NAME.arrowLeft} size={ICON_SIZE.small} />}
                      </a>
                    }
                  </li>
                ),
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
