import { analyzeFlyout } from '@client/analytics/redux';
import { analyzeEvent } from '@client/analytics/redux/tealium/actions';
import { TEALIUM_INTERACTION_ID } from '@client/analytics/types/types';
import { FlyoutAnalyticsBuilder } from '@client/analytics/utils/builders/FlyoutAnalyticsBuilder';
import { all, AllEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

function* analyzeFlyoutUsageSaga(action: ReturnType<typeof analyzeFlyout>): Generator<PutEffect> {
  const { path, url, position } = action.payload;

  const componentFlyoutMapper = new FlyoutAnalyticsBuilder()
    .setPath(path)
    .setPosition(position)
    .setUrl(url);

  yield put(analyzeEvent({
    interaction: TEALIUM_INTERACTION_ID.flyoutClick,
    ...componentFlyoutMapper.build(),
  }));
}

export function* menuAnalyticsSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(analyzeFlyout.type, analyzeFlyoutUsageSaga),
  ]);
}
