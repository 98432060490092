import { ReactElement } from 'react';
import { SCREENSHOT_TOOL_DATA_ATTR_MPT_ID } from '@client/common/screenshotTool';
import { isEmpty } from '@client/common/utils/empty/empty';
import { createKey } from '@client/common/utils/key/key';
import { BannerSlideProps } from '@client/content/components/ModularPage/Modules/Banner/Banner.types';
import { useSid } from '@client/context/hooks';
import { useTranslate } from '@client/i18n/hooks';
import { Teaser, TEASER_TYPE } from '@lib/components/CMS/Teaser';
import { VideoTeaser } from '@lib/components/CMS/VideoTeaser';
import { Slider } from '@lib/components/Slider';
import Chance from 'chance';
import classNames from 'classnames';
import { useLinkHandler } from '../useLinkHandler';
import { Props } from '../';
import './Banner.scss';

const GAP_BETWEEN_SLIDES = 16;
const SLIDER_AUTOPLAY_DELAY = 5000;

export const Banner = ({ className, componentData }: Props): ReactElement => {
  const t = useTranslate();
  const sid = useSid();
  const handleClick = useLinkHandler();
  const bannerData = componentData.global__banner?.componentData;

  if (isEmpty(bannerData) || !Array.isArray(bannerData)) {
    return null;
  }

  const validBannerSlides = bannerData.reduce(
    (banners: Record<string, any>[], data: Record<string, any>) => !isEmpty(data) ? [...banners, data] : banners,
    [],
  );

  const hasRandomOrder = componentData.random_order;
  let shuffledBannerSlides = validBannerSlides;

  if (hasRandomOrder) {
    const chance = new Chance(sid);
    const slidesToShuffle = validBannerSlides.filter((slide: any) => !slide.sticky_banner);
    const shuffledSlides = chance.shuffle(slidesToShuffle);

    shuffledBannerSlides = validBannerSlides.reduce(
      (props: any[], slide: any) => {
        if (slide.sticky_banner) {
          return [...props, slide];
        }

        return [...props, shuffledSlides.shift()];
      },
      [],
    );
  }

  const text = {
    ariaCloseModal: t('Close video'),
    ariaLabel: t('Teaser video'),
  };

  const hasSlider = componentData.show_as_slider && bannerData.length > 1;
  const slidesProps = shuffledBannerSlides.map((data: Record<string, any>): BannerSlideProps => {
    const slideProps = {
      ...data.mpt_id && {
        dataAttributes: {
          [SCREENSHOT_TOOL_DATA_ATTR_MPT_ID]: data.mpt_id,
        },
      },
      type: TEASER_TYPE.banner,
    };

    switch (data.size) {
      case 'Teaser-Size-100':
        slideProps.type = TEASER_TYPE.size100;
        break;
      case 'Teaser-Size-66':
        slideProps.type = TEASER_TYPE.size66;
        break;
      case 'Teaser-Size-50':
        slideProps.type = TEASER_TYPE.size50;
        break;
      case 'Teaser-Size-33':
        slideProps.type = TEASER_TYPE.size33;
        break;
      default:
        break;
    }

    if (hasSlider) {
      slideProps.type = TEASER_TYPE.banner;
    }

    return {
      ...slideProps,
      videoUrl: data.video_url,
      buttonLabel: data.cta_label,
      content: data.description,
      contentPosition: data.content_position,
      imagePosition: data.image_focus,
      //TODO: add fallback img
      imageUrl: data.image ? data.image.url : '',
      logoPosition: data.logo_position,
      logoUrl: data.logo && data.logo.url,
      onClick: handleClick,
      styleType: data.style_type,
      title: data.label,
      text,
      url: data.cta_url?.trim() || null,
    };
  });

  const bannerSlides: ReactElement[] = slidesProps.map(
    (props: BannerSlideProps): ReactElement => {
      const { videoUrl } = props;
      const Component = videoUrl ? VideoTeaser : Teaser;

      return (
        <Component
          buttonLabel={props.buttonLabel}
          content={props.content}
          contentPosition={props.contentPosition}
          imagePosition={props.imagePosition}
          imageUrl={props.imageUrl}
          logoPosition={props.logoPosition}
          logoUrl={props.logoUrl}
          onClick={props.onClick}
          styleType={props.styleType}
          title={props.title}
          text={props.text}
          type={props.type}
          url={props.url}
          videoUrl={props.videoUrl}
          key={createKey('banner', props.type, props.imageUrl)}
          dataAttributes={props.dataAttributes}
          {...(!props.videoUrl && {
            ...(props.styleType.includes('DMK') ? { className: 'Banner' } : {}),
            contentInnerClassName: 'Banner-ContentInner',
            contentTitleClassName: 'Banner-Title',
            contentTextClassName: 'Banner-Text',
          })}
        />
      );
    },
  );

  return (
    <div className={classNames('cds-cms-GridRow', className)}>
      {hasSlider ? (
        <div className="cds-cms-BannerContainer">
          <Slider
            autoplayDelay={SLIDER_AUTOPLAY_DELAY}
            isPagination={true}
            spaceBetween={GAP_BETWEEN_SLIDES}
          >
            {bannerSlides}
          </Slider>
        </div>
      ) : (
        <div className="cds-cms-BannerContainer">
          <div className="cds-cms-GridRow">
            {bannerSlides}
          </div>
        </div>
      )}
    </div>
  );
};
