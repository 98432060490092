import { fetchCartStatus } from '@client/cart/redux/cartStatus/redux';
import { CollectedOrder } from '@client/collectedOrder/api/CollectedOrder';
import {
  clearCollectedOrder,
  getCollectedOrderData,
  placeCollectedOrder,
  placeCollectedOrderFulfilled,
  placeCollectedOrderRejected,
} from '@client/collectedOrder/redux/reducer';
import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { ROUTES } from '@client/routes/components/Router/routes';
import { pushToRoute } from '@client/routes/redux/utils';
import { AxiosResponse } from 'axios';
import {
  all,
  AllEffect,
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeLeading,
} from 'redux-saga/effects';
import { CheckoutDataType } from 'b2b-common/core/checkout/Checkout.types';
import { PlaceCollectedOrderDataType } from 'b2b-common/core/collectedOrder/CollectedOrder.types';

function* placeCollectedOrderSaga(action: ReturnType<typeof placeCollectedOrder>): Generator<
  CallEffect | PutEffect | SelectEffect | Generator,
  void,
  PlaceCollectedOrderDataType & AxiosResponse<CheckoutDataType>
> {
  const data = yield select(getCollectedOrderData);

  try {
    const response = yield call(requestApiSaga, CollectedOrder.place(data));

    yield put(placeCollectedOrderFulfilled(response.data));
    yield put(fetchCartStatus());
    yield put(clearCollectedOrder());
  } catch (error) {
    yield put(placeCollectedOrderRejected(parseError(error)));
    yield put(reportError({ message: action.type, error }));
  }

  yield pushToRoute(ROUTES.collectedOrderConfirmation);
}

export function* collectedOrderSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLeading(placeCollectedOrder.type, placeCollectedOrderSaga),
  ]);
}
