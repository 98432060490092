import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { parseError } from '@client/common/utils/api/error';
import { Manufacturers } from '@client/manufacturer/api/Manufacturers';
import {
  fetchManufacturers,
  fetchManufacturersFulfilled,
  fetchManufacturersRejected,
  selectManufacturers,
} from '@client/manufacturer/redux/manufacturers/redux';
import { ManufacturersType } from '@client/manufacturer/redux/manufacturers/types';
import { AxiosResponse } from 'axios';
import {
  all,
  AllEffect,
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeLatest,
} from 'redux-saga/effects';

function* fetchManufacturersSaga(
  action: ReturnType<typeof fetchManufacturers>,
): Generator<
  CallEffect | PutEffect | SelectEffect,
  void,
  RemoteRecord<ManufacturersType> & AxiosResponse
> {
  const { query, filter } = action.payload;

  try {
    let state: ManufacturersType = {
      manufacturers: [],
    };

    if (query) {
      // load _filterChars if not loaded previously
      const manufacturers = yield select(selectManufacturers);

      if (!manufacturers.data || !manufacturers.data._filterChars) {
        const response = yield call(requestApiSaga, Manufacturers.fetch(''));

        state = Manufacturers.mapResponseToState(response);
      }

      const response = yield call(requestApiSaga, Manufacturers.search(query));

      state = Object.assign(
        state,
        Manufacturers.mapSearchResponseToState(response),
      );
    } else {
      const response = yield call(
        requestApiSaga,
        Manufacturers.fetch(filter || ''),
      );

      state = Manufacturers.mapResponseToState(response);
    }

    yield put(fetchManufacturersFulfilled(state));
  } catch (error) {
    yield put(fetchManufacturersRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* manufacturersSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([takeLatest(fetchManufacturers.type, fetchManufacturersSaga)]);
}
