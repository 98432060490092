import { FORM_FIELD_TEXT_TYPE, FormFieldText } from '@client/common/components/Form/FormFieldText';
import { getErrorMessage } from '@client/common/utils/form/form';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { Button, BUTTON_TYPE } from 'b2b-components/theme/components/Button';
import { FIELD_NAME } from '../';
import { ComponentType } from './Body.types';

export const Body: ComponentType = ({
  className,
  isConfirmation = false,
  text,
}) => {
  const { touched, errors, handleSubmit } = useFormikContext();
  const rootClassName = classNames('cds-FooterNewsletter-Form', className);

  return (
    <form className={rootClassName} onSubmit={handleSubmit}>
      <FormFieldText
        name={FIELD_NAME.emailNewsletter}
        innerComponentProps={{
          className: 'cds-FooterNewsletter-Email',
          error: getErrorMessage(
            errors[FIELD_NAME.emailNewsletter],
            touched[FIELD_NAME.emailNewsletter],
          ),
          text: {
            placeholder: text.email,
          },
          type: FORM_FIELD_TEXT_TYPE.email,
        }}
      />

      <Button type={BUTTON_TYPE.submit}>
        {isConfirmation ? text.resend : text.subscribe}
      </Button>
    </form>
  );
};
