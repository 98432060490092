import { ReactElement, useState } from 'react';
import { CreateWishlistContent, SelectWishlistContent } from '@client/common/components/Modal/ModalContents';
import { useTranslate } from '@client/i18n/hooks';
import { Modal, MODAL_ANIMATION_DURATION, MODAL_TYPE } from 'b2b-components/theme/components/Modal';
import './AddToWishListModal.scss';

interface Props {
  isModalOpened: boolean;
  onModalClose: () => void;
  skus: string[];
}

export const AddToWishListModal = ({ isModalOpened, onModalClose, skus }: Props): ReactElement => {
  const t = useTranslate();
  const [initValue, setInitValue] = useState('');
  const [mode, setMode] = useState<'select' | 'create'>('select');

  const handleClose = () => {
    onModalClose();

    setTimeout(() => {
      setMode('select');
      setInitValue('');
    }, MODAL_ANIMATION_DURATION + 50);
  };

  const handleCreate = (value?: string) => {
    setInitValue(value || '');
    setMode('create');
  };

  return (
    <Modal
      className='AddToWishListModal'
      title={mode === 'select' ? t('Add items to wishlist') : t('Create new wishlist')}
      content={() => (
        <>
          {mode === 'select'
            && (
              <SelectWishlistContent
                skus={skus}
                onModalClose={handleClose}
                onCreate={handleCreate}
              />
            )
          }
          {mode === 'create'
            && (
              <CreateWishlistContent
                skus={skus}
                onModalClose={handleClose}
                onBack={() => setMode('select')}
                initValue={initValue}
              />
            )
          }
        </>
      )}
      isOpen={isModalOpened}
      onCloseModal={handleClose}
      type={MODAL_TYPE.default}
    />
  );
};
