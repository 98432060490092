import { AccessCallbackDataType, RedirectionType } from '@client/routes/components/Router/routes';
import { PERMISSION } from 'b2b-common/core/context/Context.types';

const DEFAULT_REDIRECTION: RedirectionType = {
  from: '',
  to: '',
  push: false,
  when: () => true,
};

export const REDIRECTIONS: readonly RedirectionType[] = Object.freeze([
  {
    ...DEFAULT_REDIRECTION,
    exact: false,
    from: ['/:language/rmas/search'],
    to: '/:language/my-account/rmas',
    when: ({
      isLoggedIn,
      permissionsValidator,
    }: AccessCallbackDataType) => isLoggedIn && permissionsValidator([PERMISSION.accessMyAccountRma]),
  },
  {
    ...DEFAULT_REDIRECTION,
    exact: false,
    from: ['/:language/my-account/rma', '/:language/my-account/rmas'],
    to: '/:language/rmas/search',
    when: ({ isLoggedIn, permissionsValidator }: AccessCallbackDataType) => !isLoggedIn
      || (isLoggedIn && !permissionsValidator([PERMISSION.accessMyAccountRma])),
  },
  {
    ...DEFAULT_REDIRECTION,
    exact: false,
    from: ['/:language/register'],
    to: '/:language/',
    when: ({ isLoggedIn }: AccessCallbackDataType) => isLoggedIn,
  },
]);
