import { config } from '@client/config';
import { LANGUAGE_CODE } from 'b2b-common/core/i18n/consts';

export const getAvailableLanguages = (): LANGUAGE_CODE[] => config.languages.available || [];

export const isLanguageAvailable = (language: LANGUAGE_CODE): boolean => getAvailableLanguages().includes(language);

export const sanitizeLanguage = (language?: string): LANGUAGE_CODE | undefined => {
  const value = language?.toLowerCase().split('-')[0] as LANGUAGE_CODE;
  if (isLanguageAvailable(value)) {
    return value;
  }

  return undefined;
};

export const LANGUAGE_REG_EXP = RegExp(`^/(${getAvailableLanguages().join('|')})/`);

export const LANGUAGE_LABELS: Record<LANGUAGE_CODE, string> = {
  [LANGUAGE_CODE.de]: 'Deutsch',
  [LANGUAGE_CODE.en]: 'English',
  [LANGUAGE_CODE.fr]: 'Français',
  [LANGUAGE_CODE.it]: 'Italiano',
};
