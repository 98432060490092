import { ReactElement } from 'react';
import { isEmpty } from '@client/common/utils/empty/empty';
import { createKey } from '@client/common/utils/key/key';
import { ContactInfo as ContactInfoComponent } from '@lib/components/CMS/ContactInfo';
import classNames from 'classnames';
import { Props } from '../';

export const ContactInfo = ({ className, componentData }: Props): ReactElement | null => {
  const { label } = componentData;
  const { componentData: data = [] } = componentData?.global__contact_info
  || componentData?.module__contact_info;

  if (isEmpty(data)) {
    return null;
  }

  return (
    <div className={classNames('cds-cms-GridRow', className)}>
      <div className="cds-cms-ModuleContainer">
        {label && <h2 className="cds-cms-SectionTitle">{label}</h2>}

        <div className="cds-cms-GridRow">
          {data?.map((contact: Record<string, any>, index: number) => (
            <ContactInfoComponent
              key={createKey('contact', contact.name, index)}
              name={contact.name}
              jobTitle={contact.job_title}
              topHtmlText={contact.intro_text}
              bottomHtmlText={contact.additional_text}
              email={contact.email}
              imageUrl={contact.image ? contact.image.url : undefined}
              phone={contact.phone_number}
              size={contact.size}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
