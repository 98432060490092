import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddressesType, AddressType } from 'b2b-common/core/account/api/Address.types';

const NAME = 'addresses';

export const slice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {},
  reducers: {
    fetchAddresses: (state, action: PayloadAction<{ pageSize: number, startItem: number }>) => {
      state = RemoteRecordData.init();
      return state;
    },
    fetchAddressesFulfilled: (state, action: PayloadAction<AddressType>) => {
      state = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchAddressesRejected: (state, action: PayloadAction<ErrorType>) => {
      state = RemoteRecordData.reject(action.payload);
      return state;
    },
    clearAccountAddresses: (state) => {
      state = RemoteRecordData.getDefault();
      return state;
    },
  },
});

export const {
  fetchAddresses,
  fetchAddressesFulfilled,
  fetchAddressesRejected,
  clearAccountAddresses,
} = slice.actions;

const getState = (state: AppState) => state.myAccount.addresses.addresses;

export const selectAddresses = createSelector(
  getState,
  (state) => new RemoteRecord<AddressesType>(state),
);
