import { ReactElement } from 'react';
import { InfoView } from '@client/common/components/InfoView';
import { useLoginAction } from '@client/context/hooks';
import { useTranslate } from '@client/i18n/hooks';
import { ROUTES } from '@client/routes/components/Router/routes';
import { useRouterPush, useUrlCreator } from '@client/routes/hooks';
import defaultImage from './assets/locked.svg';
import { Props } from './UnloggedUserPage.types';

export const UnloggedUserAlert = ({
  iconType,
  image = defaultImage,
  infoViewTestId,
}: Props): ReactElement => {
  const t = useTranslate();

  const push = useRouterPush();
  const urlCreator = useUrlCreator();
  const redirectToLogin = useLoginAction();

  const onLoginClick = (): void => {
    redirectToLogin();
  };

  const onHomepageClick = (): void => {
    push(urlCreator(ROUTES.homepage));
  };

  const buttons = {
    primary: {
      content: t('Login'),
      onClick: onLoginClick,
    },
    secondary: {
      content: t('Homepage'),
      onClick: onHomepageClick,
    },
  };

  return (
    <InfoView
      image={image}
      title={t('Page accessible only to logged-in users')}
      iconType={iconType}
      buttons={buttons}
      testId={infoViewTestId}
    />
  );
};
