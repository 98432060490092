import {
  fetchCartCostCenterDetails,
  fetchCartCostCenterDetailsFulfilled,
  fetchCartCostCenterDetailsRejected,
} from '@client/cart/redux/costCenter/redux';
import { Checkout } from '@client/checkout/api/Checkout';
import {
  checkoutFulfilled,
  checkoutRejected,
  initCheckout,
  saveShippingAddress,
  selectRequestData,
  updateCheckout,
} from '@client/checkout/redux/checkout/redux';
import { clearPlaceOrder } from '@client/checkout/redux/order/redux';
import { getCollectedOrderData } from '@client/collectedOrder/redux/reducer';
import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { isEmpty } from '@client/common/utils/empty/empty';
import { merge } from '@client/common/utils/object/merge';
import { getProfile } from '@client/context/redux/redux';
import { Address } from '@client/myAccount/addresses/api/Address';
import { AxiosResponse } from 'axios';
import {
  all,
  call,
  CallEffect,
  put,
  PutEffect,
  race,
  RaceEffect,
  select,
  SelectEffect,
  take,
  TakeEffect,
  takeLeading,
} from 'redux-saga/effects';
import { CartCostCenterDetailsAddress } from 'b2b-common/core/cart/api/CartTypes';
import { CheckoutDataType, UpdateCheckoutRequestBodyType } from 'b2b-common/core/checkout/Checkout.types';
import { prepareRequestData } from 'b2b-common/core/checkout/utils/checkout';
import { CollectedOrderDataType } from 'b2b-common/core/collectedOrder/CollectedOrder.types';
import { ProfileType } from 'b2b-common/core/context/Context.types';

function* initCostCenterAndGetDefaultDeliveryAddress(costCenterId: string):
  Generator<
    PutEffect | RaceEffect<TakeEffect>,
    null | CartCostCenterDetailsAddress,
    {
      fulfilledAction: ReturnType<typeof fetchCartCostCenterDetailsFulfilled>,
      rejectedAction: ReturnType<typeof fetchCartCostCenterDetailsRejected>,
    }
  > {
  yield put(fetchCartCostCenterDetails({ costCenterId, withAddresses: true }));

  const { fulfilledAction, rejectedAction } = yield race({
    fulfilledAction: take(fetchCartCostCenterDetailsFulfilled.type),
    rejectedAction: take(fetchCartCostCenterDetailsRejected.type),
  });

  if (rejectedAction) {
    return null;
  }

  const { deliveryAddresses } = fulfilledAction.payload;

  return deliveryAddresses?.find((address: CartCostCenterDetailsAddress) => address.isDefault) || null;
}

function* initCheckoutSaga(action: ReturnType<typeof initCheckout>):
  Generator<
    SelectEffect | CallEffect | PutEffect,
    void,
    CollectedOrderDataType &
    AxiosResponse<CheckoutDataType> &
    ProfileType &
    UpdateCheckoutRequestBodyType &
    CartCostCenterDetailsAddress
  > {
  const collectedOrderData = yield select(getCollectedOrderData);
  const {
    licenseHolder: { licenseHolder },
    references: { order: orderReference, sales: salesReference },
  } = collectedOrderData;

  try {
    const { data } = yield call(requestApiSaga, Checkout.init());
    const costCenterUuid = data.costCenter.uuid;
    const deliveryAddress = costCenterUuid
      ? yield call(initCostCenterAndGetDefaultDeliveryAddress, costCenterUuid)
      : null;

    let confirmationEmail: string;

    if (data?.addresses?.addresses?.delivery?.email) {
      confirmationEmail = data.addresses.addresses.delivery.email;
    } else if (isEmpty(data.addresses.addresses.delivery.email)) {
      const { email }: ProfileType = yield select(getProfile);
      confirmationEmail = email;
    }

    const updatedData = { ...data };

    if (deliveryAddress) {
      updatedData.addresses.addresses.delivery = {
        name: deliveryAddress.name,
        street: deliveryAddress.street,
        streetAddition: deliveryAddress.streetAddition,
        contactPerson: deliveryAddress.contactPerson,
        postCode: deliveryAddress.postCode,
        city: deliveryAddress.city,
        email: confirmationEmail,
        phoneNumber: '',
      };
      updatedData.driverInfo = deliveryAddress.deliveryNote;
    }

    yield put(clearPlaceOrder());
    yield put(checkoutFulfilled(merge(updatedData, collectedOrderData)));

    const shouldUpdateCheckout = licenseHolder || orderReference || salesReference || confirmationEmail;

    if (shouldUpdateCheckout) {
      let updatedCheckout = yield select(selectRequestData);
      if (confirmationEmail) {
        updatedCheckout = {
          ...updatedCheckout,
          confirmationEmail,
        };
      }

      yield put(updateCheckout({ checkoutId: data.token, data: prepareRequestData(updatedCheckout) }));
    }
  } catch (error: any) {
    yield put(checkoutRejected(parseError(error)));
    yield put(reportError({ message: action.type, error }));
  }
}

export function* updateCheckoutSaga(action: ReturnType<typeof updateCheckout>):
  Generator<
    CallEffect | PutEffect,
    void,
    AxiosResponse<CheckoutDataType>
  > {
  const { checkoutId, data } = action.payload;

  try {
    const response = yield call(
      requestApiSaga,
      Checkout.update(checkoutId, prepareRequestData(data)),
    );
    yield put(checkoutFulfilled(response.data));
  } catch (error: any) {
    yield put(checkoutRejected(parseError(error)));
    yield put(
      reportError({ message: action.type, error, additionalData: { checkoutId } }),
    );
  }
}

function* checkoutFulfilledSaga(action: ReturnType<typeof checkoutFulfilled>):
  Generator<
    CallEffect | PutEffect,
    void
  > {
  const checkout = action.payload;

  let updatedState;

  // Mark error when selected shipping method does not exist in options
  if (!checkout.shipping.methods.some(shipping => shipping.type === checkout.shipping.selected)) {
    updatedState = { ...checkout, shipping: { ...checkout.shipping, hasError: true } };
  }

  //  Mark error when selected payment method does not exist in options
  if (!checkout.payment.options.some(payment => payment.type === checkout.payment.selected)) {
    const state = updatedState || checkout;
    updatedState = { ...state, payment: { ...state.payment, hasError: true } };
  }

  if (updatedState) {
    yield put(checkoutFulfilled(updatedState));
  }
}

function* saveShippingAddressSaga(action: ReturnType<typeof saveShippingAddress>):
  Generator<CallEffect | PutEffect, void> {
  const address = action.payload;

  try {
    yield call(requestApiSaga, Address.addAddress(address));
  } catch (error) {
    yield put(reportError({ message: action.type, error, additionalData: { address } }));
  }
}

export function* checkoutSaga(): Generator<any, any, any> {
  yield all([
    takeLeading(initCheckout.type, initCheckoutSaga),
    takeLeading(updateCheckout.type, updateCheckoutSaga),
    takeLeading(checkoutFulfilled.type, checkoutFulfilledSaga),
    takeLeading(saveShippingAddress.type, saveShippingAddressSaga),
  ]);
}
