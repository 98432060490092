import { generatePath } from 'react-router-dom';
import { getActiveLanguage } from '@client/i18n/redux/redux';
import { DEFAULT_ROUTE_PARAMS, RouteType } from '@client/routes/components/Router/routes';
import { Action } from '@reduxjs/toolkit';
import { push, replace } from 'connected-react-router';
import { stringify } from 'qs';
import { call, CallEffect, put, PutEffect, select, SelectEffect } from 'redux-saga/effects';
import { LANGUAGE_CODE } from 'b2b-common/core/i18n/consts';

export type PushType = (url: string, state?: Record<string, any>) => void;

export type ReplaceType = (url: string) => void;

export function* generateRoute(
  route: RouteType,
  extraParams: Record<string, any> = {},
  language?: LANGUAGE_CODE | null,
): Generator<SelectEffect, string, LANGUAGE_CODE> {
  let targetLanguage = language;

  if (!targetLanguage) {
    targetLanguage = yield select(getActiveLanguage);
  }

  return generatePath(route.paths[targetLanguage], {
    ...DEFAULT_ROUTE_PARAMS,
    ...extraParams,
  });
}

export function* pushToRoute(
  route: RouteType,
  extraParams: Record<string, any> = {},
  language?: LANGUAGE_CODE | null,
  locationState?: Record<string, any>,
  queryParams?: Record<string, string | number>,
): Generator<CallEffect | PutEffect, void, string & Action> {
  const url = yield call(generateRoute, route, extraParams, language);
  const fullUrl = queryParams ? `${url}?${stringify(queryParams)}` : url;

  yield put(
    yield call<PushType>(push, fullUrl, locationState),
  );
}

export function* pushToUrl(
  url: string,
  extraParams: Record<string, any> = {},
  language?: LANGUAGE_CODE,
): Generator<SelectEffect | PutEffect | CallEffect, void, LANGUAGE_CODE & Action> {
  let targetLanguage = language;

  if (!targetLanguage) {
    targetLanguage = yield select(getActiveLanguage);
  }

  yield put(
    yield call<PushType>(
      push,
      generatePath(url, {
        ...DEFAULT_ROUTE_PARAMS,
        ...extraParams,
        language: targetLanguage,
      }),
    ),
  );
}

export function* replaceRoute(
  route: RouteType,
  extraParams: Record<string, any> = {},
  language?: LANGUAGE_CODE,
): Generator<CallEffect | PutEffect, void, string & Action> {
  const url = yield call(generateRoute, route, extraParams, language);

  yield put(
    yield call<ReplaceType>(replace, url),
  );
}
