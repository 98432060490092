import { SEARCH_ACTION } from '@client/analytics/redux';
import { createAction } from '@reduxjs/toolkit';

export const analyzeSearchEvent = createAction('analyzeSearchEvent');

export const analyzeSearchPageView = createAction('analyzeSearchPageView');

export const analyzeSuggestedCategoryClick = createAction<string>('analyzeSuggestedCategoryClick');

export const analyzeSuggestedSimilarTermClick = createAction('analyzeSearchStart');

export const analyzeSearchFilterClick = createAction<{
  filterName: string,
  filterValue: string,
  filterAction: SEARCH_ACTION
}>('analyzeSearchFilterClick');
