import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BalanceDetailsType } from 'b2b-common/core/account/api/BalanceDetails.types';

const NAME = 'balanceDetails';

export const balanceDetailsSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: RemoteRecordData.getDefault(),
  reducers: {
    fetchBalanceDetails: (state) => {
      state = RemoteRecordData.init();
      return state;
    },
    fetchBalanceDetailsFulfilled: (state, action: PayloadAction<BalanceDetailsType>) => {
      state = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchBalanceDetailsRejected: (state, action: PayloadAction<ErrorType>) => {
      state = RemoteRecordData.reject(action.payload);
      return state;
    },
    clearAccountBalanceDetails: (state) => {
      state = RemoteRecordData.getDefault();
      return state;
    },
  },
});

export const {
  fetchBalanceDetails,
  fetchBalanceDetailsFulfilled,
  fetchBalanceDetailsRejected,
  clearAccountBalanceDetails,
} = balanceDetailsSlice.actions;

const getState = (state: AppState) => state.myAccount.balanceDetails;

export const selectBalanceDetails = createSelector(
  getState,
  (state) => new RemoteRecord<BalanceDetailsType>(state),
);
