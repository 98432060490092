import { META_TAGS } from '@client/common/utils/seo/metaTags';
import { HeadDataType, MetaTagsType, MetaTagType } from '@client/content/components/ModularPage';

export const prepareHeadData = (
  socialMeta: Record<string, any> | undefined,
  defaultPageDescription: MetaTagType,
): HeadDataType => {
  if (!socialMeta) {
    return {
      metaTags: [],
      title: '',
    };
  }

  const { title, _metadata, ...socialMetadata } = socialMeta;
  const keys = Object.keys(socialMetadata);

  const metaTags = keys.reduce((acc, curr) => {
    const val = socialMetadata[curr];
    const key = curr.replace(/_/g, ':');

    if (key === META_TAGS.description) {
      acc[0] = {
        name: 'description',
        content: val,
      };
      return acc;
    }

    if (key === META_TAGS.metaDescription) {
      acc[0] = {
        name: 'description',
        content: val,
      };
      return acc;
    }

    acc.push({
      name: key,
      content: key === META_TAGS.ogImage ? val.url : val,
      property: key,
    });
    return acc;
  }, [
    defaultPageDescription,
  ] as MetaTagsType);

  return {
    title: title || '',
    metaTags,
  };
};

export const extractHeadData = (
  socialData: Record<string, any> | undefined,
  description: string,
  defaultData: MetaTagType,
): HeadDataType => {
  if (description?.length) {
    return prepareHeadData({ ...socialData, meta_description: description }, defaultData);
  }

  return prepareHeadData(socialData, defaultData);
};

interface GetHeadDataProperties {
  metaDescription: any,
  globalHelperSocialMedia: any,
  shopMediaDescription: string;
}

export const getHeadData = ({
  metaDescription,
  globalHelperSocialMedia,
  shopMediaDescription,
}: GetHeadDataProperties): HeadDataType => {
  const defaultValue: { metaTags: MetaTagsType, title: string } = {
    metaTags: [],
    title: '',
  };

  if (metaDescription?.length) {
    const defaultValue = { name: 'description', content: metaDescription };

    if (!globalHelperSocialMedia?.length) {
      return prepareHeadData({}, defaultValue);
    }

    return extractHeadData(globalHelperSocialMedia[0], metaDescription, defaultValue);
  }

  if (!metaDescription?.length) {
    if (globalHelperSocialMedia?.length) {
      const defaultValue = {
        name: 'description',
        content: shopMediaDescription,
      };
      return extractHeadData(globalHelperSocialMedia[0], '', defaultValue);
    }
  }

  return defaultValue;
};

