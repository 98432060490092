import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContentCollectionEntryType } from 'b2b-common/core/content/Content.types';

const NAME = 'contentCollectionsEntries';

export const slice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {},
  reducers: {
    fetchContentCollectionEntity: (state, action: PayloadAction<{ collectionId: string, entryId: string }>) => {
      const { collectionId, entryId } = action.payload;

      if (!Object.hasOwnProperty.call(state, collectionId)) {
        state[collectionId] = {};
      }

      state[collectionId] = {
        [entryId]: RemoteRecordData.init(),
      };
      return state;
    },
    fetchContentCollectionEntityFulfilled: (state, action: PayloadAction<{
      collectionId: string,
      entryId: string,
      data: ContentCollectionEntryType
    }>) => {
      const { collectionId, data, entryId } = action.payload;

      if (!Object.hasOwnProperty.call(state, collectionId)) {
        state[collectionId] = {};
      }

      state[collectionId] = {
        [entryId]: RemoteRecordData.fulfill(data),
      };
      return state;
    },
    fetchContentCollectionEntityRejected: (state, action: PayloadAction<{
      collectionId: string,
      entryId: string,
      error: ErrorType,
    }>) => {
      const { collectionId, entryId, error } = action.payload;

      if (!Object.hasOwnProperty.call(state, collectionId)) {
        state[collectionId] = {};
      }

      state[collectionId] = {
        [entryId]: RemoteRecordData.reject(error),
      };
      return state;
    },
  },
});

export const {
  fetchContentCollectionEntity,
  fetchContentCollectionEntityFulfilled,
  fetchContentCollectionEntityRejected,
} = slice.actions;

const getCollections = (state: AppState) => state.content?.contentCollectionsEntries || {};

export const selectCollectionById = createSelector(
  [getCollections, (state, collectionId: string) => collectionId],
  (state, collectionId) => state[collectionId],
);

export const selectCollectionEntryByIds = createSelector(
  [
    getCollections,
    selectCollectionById,
    (state, collectionId: string, entryId: string) => entryId,
  ],
  (collections, collection, entryId) => {
    if (collection && Object.hasOwnProperty.call(collection, entryId)) {
      return new RemoteRecord<ContentCollectionEntryType>(collection[entryId]);
    }

    return new RemoteRecord<ContentCollectionEntryType>(undefined);
  },
);
