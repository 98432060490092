import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UsersType } from 'b2b-common/core/account/api/Users.types';


export const usersSlice = createSlice({
  name: 'users',
  initialState: RemoteRecordData.getDefault(),
  reducers: {
    fetchUsers: (state, action: PayloadAction<{ startItem: number, pageSize: number }>) => (
      RemoteRecordData.init()
    ),
    fetchUsersFulfilled: (state, action: PayloadAction<UsersType>) => (
      RemoteRecordData.fulfill(action.payload)
    ),
    fetchUsersRejected: (state, action: PayloadAction<ErrorType>) => (
      RemoteRecordData.fulfill(action.payload)
    ),
    clearAccountUsers: (state) => (
      RemoteRecordData.getDefault()
    ),
  },
});

export const {
  fetchUsers,
  fetchUsersFulfilled,
  fetchUsersRejected,
  clearAccountUsers,
} = usersSlice.actions;


const selectState = (state: AppState) => state.myAccount.users.users;

export const selectUsers = createSelector(
  selectState,
  (state) => new RemoteRecord<UsersType>(state),
);
