import { LOGGER_LEVEL } from '@client/common/utils/logger/logger';
import { ExtrasType, Logger } from '@client/common/utils/logger/logger.types';
import { isObject } from '@client/common/utils/object/object';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';

export const sentryLogger: Logger = {
  [LOGGER_LEVEL.log]: (
    message: string,
    extras: ExtrasType = {},
  ) => captureMessage(Severity.Log, message, extras),
  [LOGGER_LEVEL.info]: (
    message: string,
    extras: ExtrasType = {},
  ) => captureMessage(Severity.Info, message, extras),
  [LOGGER_LEVEL.warn]: (
    message: string,
    extras: ExtrasType = {},
  ) => captureMessage(Severity.Warning, message, extras),
  [LOGGER_LEVEL.error]: (
    message: string,
    extras: ExtrasType = {},
  ) => captureMessage(Severity.Error, message, extras),
  [LOGGER_LEVEL.debug]: (
    message: string,
    extras: ExtrasType = {},
  ) => captureMessage(Severity.Debug, message, extras),
};

const captureMessage = (
  level: Severity,
  message: string,
  extras: ExtrasType = {},
): void => {
  const { debitorId, fingerprint, ...otherExtras } = extras;
  const { error } = otherExtras;

  Sentry.withScope(
    (scope) => {
      scope.setLevel(level);

      if (debitorId) {
        scope.setUser({
          id: debitorId,
        });
      }

      if (fingerprint) {
        scope.setFingerprint(fingerprint);
      }

      if (isObject(otherExtras)) {
        scope.setExtras(otherExtras);
      }

      if (error && level === Severity.Error) {
        Sentry.captureException(error);
      } else {
        Sentry.captureMessage(message);
      }
    },
  );
};
