import { backorderSubscribeSaga } from '@client/myAccount/orders/redux/backorderSubscribe/sagas';
import { orderSaga } from '@client/myAccount/orders/redux/order/sagas';
import { ordersSaga } from '@client/myAccount/orders/redux/orders/sagas';
import { ordersSearchSagas } from '@client/myAccount/orders/redux/ordersSearch/sagas';
import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';

export function* ordersSagas(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    fork(backorderSubscribeSaga),
    fork(ordersSaga),
    fork(orderSaga),
    fork(ordersSearchSagas),
  ]);
}
