import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { fetchEntriesMap, selectEntriesMap } from '@client/content/redux/entriesMap/redux';
import { EntryMapType } from 'b2b-common/core/content/Content.types';

export const useEntriesMap = (): RemoteRecord<EntryMapType[]> => {
  const dispatch = useDispatch();
  const map = useSelector(selectEntriesMap);

  useEffect(() => {
    if (!map.isLoaded) {
      dispatch(fetchEntriesMap());
    }
  }, [map.isLoaded]);

  return map;
};
