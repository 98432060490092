import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Infobars } from '@client/content/api/Infobars';
import { fetchInfobars, fetchInfobarsFulfilled, fetchInfobarsRejected } from '@client/content/redux/infobars/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { Infobar } from 'b2b-common/core/content/Infobars.types';

function* fetchInfobarsSaga(action: ReturnType<typeof fetchInfobars>): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<Infobar[]>
> {
  try {
    const response = yield call(
      requestApiSaga,
      Infobars.fetch(),
    );
    yield put(fetchInfobarsFulfilled(Infobars.mapResponseToState<Infobar[]>(response)));
  } catch (error) {
    yield put(fetchInfobarsRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* infobarsSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeEvery(fetchInfobars.type, fetchInfobarsSaga),
  ]);
}
