import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { stringify } from '@client/common/utils/queryParams';
import { WishlistsFetchParamsType } from 'b2b-common/core/account/api/wishlists/Wishlists.types';

const WISHLISTS_FETCH_DEFAULT_PARAMS = {
  pageSize: 50,
  startItem: 0,
  sort: 'updated_at',
  order: 'desc',
};

export class Wishlists {
  public static fetch(requestParams: WishlistsFetchParamsType): RequestConfigType {
    const queryParams = stringify({ ...WISHLISTS_FETCH_DEFAULT_PARAMS, ...requestParams });

    return RequestConfig.get(`/wishlist?${queryParams}`);
  }
}
