import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { stringify } from '@client/common/utils/queryParams';

export class Orders {
  public static fetch(pageSize: number, startItem: number): RequestConfigType {
    const searchParams = {
      pageSize,
      startItem,
    };

    return RequestConfig.get(`/account/orders?${stringify(searchParams)}`,
      { withLocalization: false });
  }

  public static search(pageSize: number, startItem: number, searchQuery: string): RequestConfigType {
    const searchParams = {
      pageSize,
      startItem,
    };

    return RequestConfig.get(`/account/orders?${stringify(searchParams)}&q=${searchQuery}`,
      { withLocalization: false });
  }
}
