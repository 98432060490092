import { useTranslate } from '@client/i18n/hooks';
import { useMyAccountLinks } from '@client/myAccount/common/hooks';
import {
  MY_ACCOUNT_DASHBOARD_CONFIG,
  MY_ACCOUNT_DASHBOARD_GROUP,
  MY_ACCOUNT_MENU_ITEM,
} from '@client/myAccount/common/utils/myAccountMenu';
import { AccountMenuGroupType } from 'b2b-components/theme/components/AccountMenu/Group';

export const useMyAccountDashboardLinks = (active?: MY_ACCOUNT_MENU_ITEM): AccountMenuGroupType[] => {
  const t = useTranslate();
  const text: Record<MY_ACCOUNT_DASHBOARD_GROUP, string> = {
    [MY_ACCOUNT_DASHBOARD_GROUP.dashboard]: null,
    [MY_ACCOUNT_DASHBOARD_GROUP.procurement]: t('E-Procurement'),
    [MY_ACCOUNT_DASHBOARD_GROUP.account]: t('Account'),
  };
  const getLinks = useMyAccountLinks(active);

  return Object.keys(MY_ACCOUNT_DASHBOARD_GROUP).map((dashboardGroup): AccountMenuGroupType => ({
    title: text[dashboardGroup],
    items: getLinks(MY_ACCOUNT_DASHBOARD_CONFIG[dashboardGroup]),
  }));
};
