import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UsersType } from 'b2b-common/core/account/api/Users.types';

export const usersSearchSlice = createSlice({
  name: 'usersSearch',
  initialState: RemoteRecordData.getDefault(),
  reducers: {
    usersSearch: (state, action: PayloadAction<{
      pageSize: number;
      startItem: number;
      searchQuery: string;
    }>) => {
      state = RemoteRecordData.init();
      return state;
    },
    usersSearchFulfilled: (state, action: PayloadAction<{ data: UsersType }>) => {
      state = RemoteRecordData.fulfill(action.payload.data);
      return state;
    },
    usersSearchRejected: (state, action: PayloadAction<{ error: ErrorType }>) => {
      state = RemoteRecordData.reject(action.payload.error);
      return state;
    },
    clearUsersSearch: (state) => {
      state = RemoteRecordData.getDefault();
      return state;
    },
  },
});

export const {
  usersSearch,
  usersSearchFulfilled,
  usersSearchRejected,
  clearUsersSearch,
} = usersSearchSlice.actions;

const selectState = (state: AppState) => state.myAccount.users.usersSearch;

export const selectUsersSearch = createSelector(
  selectState,
  (state) => new RemoteRecord<UsersType>(state),
);
