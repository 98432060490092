import { ReactElement } from 'react';
import { StatusCodes } from 'http-status-codes';
import { Props } from './ServerFail.types';
import { ServerFailMessage } from './ServerFailMessage';
import { ServerFailPage } from './ServerFailPage';
import { ServerForbiddenMessage } from './ServerForbiddenMessage';

export const ServerFail = ({ error, className, isMessage }: Props): ReactElement => {
  const statusCode = error?.statusCode;
  const isErrorTimeout = statusCode === StatusCodes.GATEWAY_TIMEOUT;
  const isErrorForbidden = statusCode === StatusCodes.FORBIDDEN;

  const handleReloadClick = (): void => {
    window.location.reload();
  };

  if (isMessage && isErrorForbidden) {
    return (
      <ServerForbiddenMessage />
    );
  }

  if (isMessage) {
    return (
      <ServerFailMessage
        isErrorTimeout={isErrorTimeout}
        onReload={handleReloadClick}
        className={className}
      />
    );
  }

  return (
    <ServerFailPage
      isErrorTimeout={isErrorTimeout}
      onReload={handleReloadClick}
      className={className}
    />
  );
};
