import { retryRequestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { DeliveredOrders } from '@client/myAccount/deliveredOrders/api/DeliveredOrders';
import {
  deliveredOrdersSearch,
  deliveredOrdersSearchFulfilled,
  deliveredOrdersSearchRejected,
} from '@client/myAccount/deliveredOrders/redux/deliveredOrdersSearch/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { DeliveredOrdersType } from 'b2b-common/core/account/api/DeliveredOrders.types';

function* deliveredOrdersSearchSaga(
  action: ReturnType<typeof deliveredOrdersSearch>,
): Generator<CallEffect | PutEffect, void, AxiosResponse<DeliveredOrdersType>> {
  const searchQuery = action.payload;

  try {
    const { data } = yield call(
      retryRequestApiSaga,
      DeliveredOrders.search(0, searchQuery),
    );

    yield put(deliveredOrdersSearchFulfilled({ data }));
  } catch (error) {
    yield put(deliveredOrdersSearchRejected({ error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: { searchQuery } }));
  }
}

export function* deliveredOrdersSearchSagas(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(deliveredOrdersSearch.type, deliveredOrdersSearchSaga),
  ]);
}
