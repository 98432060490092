import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InvoicesSearchType } from 'b2b-common/core/account/api/invoices';

const NAME = 'invoicesSearch';

export const invoicesSearchSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: RemoteRecordData.getDefault(),
  reducers: {
    fetchInvoicesSearch: (state, action: PayloadAction<{
      searchQuery: string,
      isPendingOnly: number
    }>) => RemoteRecordData.init(),
    fetchInvoicesSearchFulfilled: (state, action: PayloadAction<InvoicesSearchType>) => (
      RemoteRecordData.fulfill(action.payload)
    ),
    fetchInvoicesSearchRejected: (state, action: PayloadAction<ErrorType>) => (
      RemoteRecordData.reject(action.payload)
    ),
    clearInvoicesSearch: () => RemoteRecordData.getDefault(),
  },
});

export const {
  fetchInvoicesSearch,
  fetchInvoicesSearchFulfilled,
  fetchInvoicesSearchRejected,
  clearInvoicesSearch,
} = invoicesSearchSlice.actions;

const getState = (state: AppState) => state.myAccount.invoices.invoicesSearch;

export const selectInvoicesSearch = createSelector(
  getState,
  (state) => new RemoteRecord<InvoicesSearchType>(state),
);
