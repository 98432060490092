import { requestApiSaga, retryRequestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { MY_ACCOUNT_MESSAGE, showMyAccountToast } from '@client/myAccount/common/utils/messages';
import { User } from '@client/myAccount/users/api/User';
import {
  fetchUser,
  fetchUserFulfilled,
  fetchUserRejected,
  removeUser,
  submitUser,
} from '@client/myAccount/users/redux/user/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import emitter, { EVENT } from 'b2b-apps/core/common/utils/eventEmitter';
import { UserType } from 'b2b-common/core/account/api/User.types';

function* fetchUserSaga(action: ReturnType<typeof fetchUser>): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<UserType>
> {
  const accountId = action.payload;

  try {
    const response = yield call(retryRequestApiSaga, User.fetch(accountId));
    yield put(fetchUserFulfilled({ accountId, data: response.data }));
  } catch (error) {
    yield put(fetchUserRejected({ accountId, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: { error: action.payload } }));
  }
}

function* submitUserSaga(action: ReturnType<typeof submitUser>): Generator<CallEffect | PutEffect, void> {
  const { accountId, data } = action.payload;

  try {
    if (accountId) {
      yield call(requestApiSaga, User.update(accountId, data));

      showMyAccountToast(MY_ACCOUNT_MESSAGE.successUpdateUser);
    } else {
      yield call(requestApiSaga, User.create(data));

      showMyAccountToast(MY_ACCOUNT_MESSAGE.successCreateUser);
    }

    emitter.dispatch(EVENT.submitUserFulfilled);
  } catch (error) {
    if (accountId) {
      yield put(reportError({ message: action.type, error, additionalData: { accountId } }));

      showMyAccountToast(MY_ACCOUNT_MESSAGE.errorUpdateUser);
    } else {
      yield put(reportError({ message: action.type, error }));
      showMyAccountToast(MY_ACCOUNT_MESSAGE.errorCreateUser);
    }

    emitter.dispatch(EVENT.submitUserRejected);
  }
}

function* removeUserSaga(action: ReturnType<typeof removeUser>): Generator<CallEffect | PutEffect, void> {
  const accountId = action.payload;

  try {
    yield call(requestApiSaga, User.remove(accountId));

    emitter.dispatch(EVENT.removeUserFulfilled);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.successRemoveUser);
  } catch (error) {
    yield put(reportError({ message: action.type, error, additionalData: { accountId } }));

    emitter.dispatch(EVENT.removeUserRejected);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.errorRemoveUser);
  }
}

export function* userSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(fetchUser.type, fetchUserSaga),
    takeLatest(submitUser.type, submitUserSaga),
    takeLatest(removeUser.type, removeUserSaga),
  ]);
}
