import { isServer } from '@client/common/utils/env';
import {
  getCookie,
  removeCookie,
  setCookie,
} from '@client/common/utils/storage/cookies';

const USER_COOKIE = 'userData';
const COOKIE_TTL = 365;
export const USER_COOKIE_KEYS = {
  identifier: 'identifier',
};

type UserCookieReturnType = {
  identifier: string;
};

type AttributesType = UserCookieReturnType | null;

type UserCookieApiReturnType = {
  clear: () => void;
  get: () => UserCookieReturnType | null;
  getAttribute: (key: string) => AttributesType | null;
  persist: (id: string) => void;
};

export const userCookie: UserCookieApiReturnType = (function createUserCookieApi(): UserCookieApiReturnType {
  let cookie: UserCookieReturnType | null;

  const getCachedCookieValue = () => {
    cookie = cookie || getCookie(USER_COOKIE) as UserCookieReturnType;

    return cookie;
  };

  return {
    clear(): void {
      cookie = null;
      removeCookie(USER_COOKIE);
    },

    get(): UserCookieReturnType {
      return getCachedCookieValue();
    },

    getAttribute(key: string): any {
      const cookie: Record<string, any> = getCachedCookieValue();

      return cookie?.[key] || null;
    },

    persist(id): void {
      const userCookie = getCachedCookieValue();
      const userIdentifier = userCookie?.identifier;

      setCookie(
        USER_COOKIE,
        {
          identifier: userIdentifier || id,
        },
        COOKIE_TTL,
      );
    },
  };
})();

export const getAreCookiesEnabled = (): boolean => {
  if (isServer) {
    return true;
  }

  try {
    return window.navigator.cookieEnabled || false;
  } catch (e) {
    return false;
  }
};
