import { billingAddressSaga } from '@client/myAccount/accountDetails/redux/billingAddress/sagas';
import { detailsSaga } from '@client/myAccount/accountDetails/redux/details/sagas';
import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';

export function* accountDetailsSagas(): Generator<AllEffect<ForkEffect>> {
  yield all([
    fork(billingAddressSaga),
    fork(detailsSaga),
  ]);
}
