import { ReactElement } from 'react';
import { Tooltip } from '@client/common/components/Tooltip';
import { useTranslate } from '@client/i18n/hooks';
import classNames from 'classnames';
import { Icon as IconComponent, ICON_NAME, ICON_SIZE } from 'b2b-components/theme/components/Icon';
import { Props } from './Icon.types';
import './Icon.scss';

const Icon = ({
  className,
  hasCurrencyCode,
  hasTooltip,
  isHiddenPriceAndNotCertified,
}: Props): ReactElement => {
  const t = useTranslate();
  const rootClassName = classNames('IconHiddenPrice', className);

  return (
    <div className={rootClassName}>
      {hasCurrencyCode && <span className="IconHiddenPrice-Currency">CHF</span>}

      <Tooltip
        content={
          isHiddenPriceAndNotCertified
            ? t('Certification is required to see the price')
            : t('Please log in to see price')
        }
        contentClassName="IconHiddenPrice-TooltipContent"
        isApplied={hasTooltip}
      >
        <IconComponent
          className="IconHiddenPrice-Icon"
          name={
            isHiddenPriceAndNotCertified
              ? ICON_NAME.certificate
              : ICON_NAME.lock
          }
          size={ICON_SIZE.small}
        />
      </Tooltip>
    </div>
  );
};

export { Icon };
