/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const CONTAINS_EMAIL_PATTERN = /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
export const PASSWORD_CAPITAL_LETTER_PATTERN = /(?=.*[A-Z])(?=.*[a-z])/;
export const PASSWORD_SPECIAL_CHARACTER_PATTERN = /(?=.*?[0-9])|([^A-Za-z0-9])/;
export const PHONE_PATTERN = /^[0-9\+\-\(\) ]*$/;
export const POST_CODE_PATTERN = /^[1-9]\d\d\d$/;

export const emailRule = (text: Record<string, any>): yup.StringSchema => yup
  .string()
  .matches(EMAIL_PATTERN, text.errorEmailFormat)
  .required(text.errorEmailRequired);

export const phoneRule = (
  text: Record<string, any>,
  isRequired = true,
): yup.StringSchema => {
  const validation = yup.string().matches(PHONE_PATTERN, text.errorPhoneFormat);

  return isRequired ? validation.required(text.errorPhoneRequired) : validation;
};

export const usernameRule = (text: Record<string, any>): yup.StringSchema => yup
  .string()
  .required(text.errorUsernameRequired);

export const passwordRule = (text: Record<string, any>): yup.StringSchema => yup
  .string()
  .min(8, text.errorMinCharacters)
  .matches(PASSWORD_CAPITAL_LETTER_PATTERN, text.errorCapitalLetter)
  .matches(PASSWORD_SPECIAL_CHARACTER_PATTERN, text.errorSpecialCharacter)
  .required(text.errorPasswordRequired);

export const legacyPasswordRule = (text: Record<string, any>): yup.StringSchema => yup
  .string()
  .required(text.errorPasswordRequired);

export const postCodeRule = (text: Record<string, any>): Record<string, any> => (
  yup.string().matches(POST_CODE_PATTERN, text.errorPostCode)
);

export const licenseHolderRule = (emailError: string, requiredError: string): Record<string, any> => yup
  .string()
  .matches(CONTAINS_EMAIL_PATTERN, emailError)
  .required(requiredError);

export const hasSomethingSelectedRule = (message: string): any => yup.array().min(1, message);

export const conditionalRule = (
  condition: boolean,
  than: Record<string, any>,
  otherwise: Record<string, any> | null = null,
): any => (condition ? than : otherwise);
