import { createContext, ReactElement, ReactNode, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { analyzeBarcodeScan } from '@client/analytics/redux';
import { bindActionCreators } from '@reduxjs/toolkit';

interface BarcodeAnalyticsContextValue {
  analyzeBarcodeScan: typeof analyzeBarcodeScan;
}

interface BarcodeAnalyticsProviderProps {
  children: ReactNode;
}

const BarcodeAnalyticsContext = createContext<BarcodeAnalyticsContextValue | undefined>(undefined);

export const BarcodeAnalyticsProvider = ({ children }: BarcodeAnalyticsProviderProps): ReactElement => {
  const dispatch = useDispatch();

  return (
    <BarcodeAnalyticsContext.Provider value={{
      analyzeBarcodeScan: bindActionCreators(analyzeBarcodeScan, dispatch),
    }}>
      {children}
    </BarcodeAnalyticsContext.Provider>
  );
};

export const useBarcodeAnalytics = (): BarcodeAnalyticsContextValue => {
  const context = useContext(BarcodeAnalyticsContext);
  // here we don't throw an error because we need to use this hook in AddToCart component

  return context;
};
