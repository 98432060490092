import { ReactElement, useEffect } from 'react';
import { useCodeScanner } from '@client/common/components/CodeScanner/useCodeScanner';
import { Props } from './CodeScanner.types';
import './CodeScanner.scss';

const CODE_SCANNER_DATA_CAPTURE_ELEMENT_ID = 'data-capture-view';

export const CodeScanner = ({ onScan, onReady, onCameraAccessDenied }: Props): ReactElement => {
  const { init } = useCodeScanner({
    captureElementId: CODE_SCANNER_DATA_CAPTURE_ELEMENT_ID,
    onScan,
    onCameraAccessDenied,
    onReady,
  });

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='CodeScanner' id={CODE_SCANNER_DATA_CAPTURE_ELEMENT_ID} />
  );
};
