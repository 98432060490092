import { CatalogType } from '@client/app/hooks';
import { AssortmentCategoryType } from '@client/assortment/redux/types';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { ROUTES } from '@client/routes/components/Router/routes';
import { UseUrlCreatorReturnType } from '@client/routes/hooks';
import { getCategoryUrlParams } from '@client/routes/utils/url';
import { RecursiveLinksType } from 'b2b-components/theme/components/Header/ModalMenu';

const mapCatalogData = (
  catalogData: RemoteRecord<AssortmentCategoryType[]>,
  langPrefix: string,
  activeLanguage: string,
  availableUrls: string[],
  urlCreator: UseUrlCreatorReturnType,
): CatalogType => {
  const mapper = (
    level: number,
    prevPath: string[] = [],
  ) => (category: AssortmentCategoryType): RecursiveLinksType & { path: string[] } => {
    const path = [...prevPath, category.label];

    let href = `${urlCreator(ROUTES.category)}${getCategoryUrlParams(
      category.url,
    )}`;

    if (level === 1) {
      const notPrefixedUrl = category.url.replace(langPrefix, '/');

      if (availableUrls.includes(notPrefixedUrl)) {
        href = `/${activeLanguage}${notPrefixedUrl}`;
      }
    }

    return {
      path,
      href,
      title: category.label,
      children: category.children.length
        ? category.children.map(mapper(level + 1, path))
        : [],
      hasCoreAssortment: category.hasCoreAssortment,
      level,
    };
  };

  return catalogData.data ? catalogData.data.map(mapper(1)) : [];
};

export {
  mapCatalogData,
};
