import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContentCollectionType } from 'b2b-common/core/content/Content.types';

const NAME = 'contentCollections';

export const slice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {},
  reducers: {
    fetchContentCollection: (state, action: PayloadAction<{ collectionId: string, filters: Record<string, any> }>) => {
      state[action.payload.collectionId] = RemoteRecordData.init();
      return state;
    },
    fetchContentCollectionFulfilled: (state, action: PayloadAction<{
      collectionId: string,
      data: ContentCollectionType[]
    }>) => {
      state[action.payload.collectionId] = RemoteRecordData.fulfill(
        action.payload.data,
      );
      return state;
    },
    fetchContentCollectionRejected: (state, action: PayloadAction<{ collectionId: string, error: ErrorType }>) => {
      state[action.payload.collectionId] = RemoteRecordData.reject(
        action.payload.error,
      );
      return state;
    },
  },
});

export const {
  fetchContentCollection,
  fetchContentCollectionFulfilled,
  fetchContentCollectionRejected,
} = slice.actions;

const getCollections = (state: AppState) => state.content?.contentCollections || {};

export const selectCollectionById = createSelector(
  [getCollections, (state, collectionId: string) => collectionId],
  (state, collectionId) => new RemoteRecord<ContentCollectionType[]>(state[collectionId]),
);
