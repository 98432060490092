import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { AssortmentCategoryType } from '../types';

const NAME = 'ownAssortment';

export const ownAssortmentSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: RemoteRecordData.getDefault(),
  reducers: {
    fetchOwnAssortment: () => (
      RemoteRecordData.init()
    ),
    fetchOwnAssortmentFulfilled: (state, action: { payload: AssortmentCategoryType[] }) => (
      RemoteRecordData.fulfill([...action.payload])
    ),
    fetchOwnAssortmentRejected: (state, action: { payload: ErrorType }) => (
      RemoteRecordData.reject(action.payload)
    ),
  },
});

export const {
  fetchOwnAssortment,
  fetchOwnAssortmentFulfilled,
  fetchOwnAssortmentRejected,
} = ownAssortmentSlice.actions;

const getState = (state: AppState) => state.assortment.ownAssortment;

export const selectOwnAssortment = createSelector(
  [getState],
  state => new RemoteRecord<AssortmentCategoryType[]>(state),
);
