import { AssortmentCategoryType } from '@client/assortment/redux/types';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice } from '@reduxjs/toolkit';

const NAME = 'mainAssortment';
export const mainAssortmentSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: RemoteRecordData.getDefault(),
  reducers: {
    fetchAssortment: (state) => {
      state = RemoteRecordData.getDefault();
      return state;
    },
    fetchAssortmentFulfilled: (state, action: { payload: AssortmentCategoryType[] }) => {
      state = RemoteRecordData.fulfill([...action.payload]);
      return state;
    },
    fetchAssortmentRejected: (state, action: { payload: ErrorType }) => {
      state = RemoteRecordData.reject(action.payload);
      return state;
    },
  },
});

export const {
  fetchAssortment,
  fetchAssortmentFulfilled,
  fetchAssortmentRejected,
} = mainAssortmentSlice.actions;

const getState = (state: AppState) => state.assortment.mainAssortment;

export const selectAssortment = createSelector(
  [getState],
  state => new RemoteRecord<AssortmentCategoryType[]>(state),
);
