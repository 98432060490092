import { ReactElement } from 'react';
import { ErrorPage } from '@client/common/components/ErrorPage';
import { META_TAGS } from '@client/common/utils/seo/metaTags';
import { config } from '@client/config';
import { useActiveLanguage, useTranslate } from '@client/i18n/hooks';
import Image from './assets/image.svg';
import './DisabledCookiesPage.scss';

const LANGUAGE_PLACEHOLDER = 'LANG';
const COOKIES_GUIDE_URL = `https://www.whatismybrowser.com/${LANGUAGE_PLACEHOLDER}/guides/how-to-enable-cookies/`;

export const DisabledCookiesPage = (): ReactElement => {
  const activeLanguage = useActiveLanguage();
  const t = useTranslate();
  const shopName = config.shopName;
  const linkUrl = COOKIES_GUIDE_URL.replace(
    LANGUAGE_PLACEHOLDER,
    activeLanguage,
  );

  const text = {
    cookiesDisabled: t('Error: Cookies disabled'),
    errorDescription: t(
      'Website cannot be loaded because cookies are disabled',
    ),
    cookiesMustBeEnabled: t(
      'To use {shopName} you must have cookies enabled.',
      {
        shopName,
      },
    ),
    reasonsThatCookiesAreDisabled: t(
      'There can be a number of reasons that cookies are disabled on your browser:',
    ),
    cookiesMayBeDisabled: t('You may have disabled them'),
    privacySettingsMayBeChanged: t(
      'You may have your browser privacy setting to "high".',
    ),
    differentModeMayBeUsed: t(
      'You may be using incognito, private, or secret mode in your browser.',
    ),
    thirdPartyExtensionMayBeUsed: t(
      'You may be using a third party browser extension that is blocking or disabling cookies.',
    ),
    followDirections: t(
      'Follow <a class="DisabledCookiesPage-Link" href="{linkUrl}">these directions</a> to enable cookies specific to your browser type and continue to the page you previously attempted to access to continue.',
      {
        linkUrl,
      },
    ),
    contactUs: t(
      'Contact us at <a class="DisabledCookiesPage-Link" href="mailto:{supportEmail}">{supportEmail}</a> with any additional questions or concerns.',
      { supportEmail: config.emails.support },
    ),
  };

  const metaTags = [
    {
      name: META_TAGS.title,
      content: text.cookiesDisabled,
    },
    {
      name: META_TAGS.description,
      content: text.errorDescription,
    },
  ];

  return (
    <ErrorPage
      pageTitle={text.cookiesDisabled}
      metaTags={metaTags}
      title={text.cookiesDisabled}
      subtitle={text.cookiesMustBeEnabled}
      image={Image}
      imageAlt={text.cookiesDisabled}
    >
      <div className="DisabledCookiesPage-Content">
        <p className="DisabledCookiesPage-TextLine">
          {text.reasonsThatCookiesAreDisabled}
        </p>
        <ul className="DisabledCookiesPage-List">
          <li>{text.cookiesMayBeDisabled}</li>
          <li>{text.privacySettingsMayBeChanged}</li>
          <li>{text.differentModeMayBeUsed}</li>
          <li>{text.thirdPartyExtensionMayBeUsed}</li>
        </ul>
        <p
          dangerouslySetInnerHTML={{
            __html: `${text.followDirections}`,
          }}
          className="DisabledCookiesPage-TextLine"
        />
        <p
          dangerouslySetInnerHTML={{
            __html: `${text.contactUs}`,
          }}
          className="DisabledCookiesPage-TextLine"
        />
      </div>
    </ErrorPage>
  );
};
