import { useEffect } from 'react';
import { triggerHotJarRecording } from '@client/analytics/utils/analytics';
import { useActiveLanguage } from '@client/i18n/hooks';
import { useRouterUrl } from '@client/routes/hooks';

export const useHotJarTracking = (): void => {
  const pageUrl = useRouterUrl();
  const activeLanguage = useActiveLanguage();

  useEffect(
    (): void => {
      triggerHotJarRecording(`feedback_${activeLanguage}`);
    },
    [activeLanguage],
  );

  useEffect(
    (): void => {
      if (window?.hj) {
        window?.hj('tagRecording', [pageUrl]);
      }
    },
    [pageUrl],
  );
};
