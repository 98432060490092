import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { LANGUAGE_CODE } from 'b2b-common/core/i18n/consts';

export class Newsletter {
  public static subscribe(
    email: string,
    language: LANGUAGE_CODE,
  ): RequestConfigType {
    return RequestConfig.post(
      '/newsletter/subscribe',
      {
        email,
        language,
      },
    );
  }
}
