import { ReactElement } from 'react';
import { getMaxQuantityLimit } from '@client/cart/utils/cart';
import { Alert, ALERT_DISPLAY_TYPE, ALERT_STYLE_TYPE } from '@lib/components/Alert';
import { Props } from './Hint.types';

export const Hint = (props: Props): ReactElement | null => {
  const {
    settings,
    text,
    currentQuantity,
    attemptedQuantity,
  } = props;
  const {
    isSellout,
    allowOverstockBuy,
    quantity,
  } = settings;
  const maxQuantity = getMaxQuantityLimit(settings);

  if (currentQuantity <= maxQuantity && attemptedQuantity <= maxQuantity) {
    return null;
  }

  if (isSellout || !allowOverstockBuy) {
    return (
      <Alert
        displayType={ALERT_DISPLAY_TYPE.text}
        styleType={ALERT_STYLE_TYPE.error}
      >
        {text.maximumQuantityReached(quantity)}
      </Alert>
    );
  }

  return (
    <Alert
      displayType={ALERT_DISPLAY_TYPE.text}
      styleType={ALERT_STYLE_TYPE.error}
    >
      {text.maximumOrderQuantityReached(maxQuantity)}
    </Alert>
  );
};
