import { ReactElement } from 'react';
import { createKey } from '@client/common/utils/key/key';
import { useRouterUrl } from '@client/routes/hooks';
import { Navigation as NavigationComponent, NAVIGATION_STYLE_TYPE } from '@lib/components/CMS/Navigation';
import classNames from 'classnames';
import { useLinkHandler } from '../useLinkHandler';
import { Props } from '../';
import { NavigationGroupType, NavigationLinkType } from './Navigation.types';

export enum NAVIGATION_VIEW_TYPE {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

export const Navigation = ({ className, componentData }: Props): ReactElement | null => {
  const url = useRouterUrl();
  const handleClick = useLinkHandler();

  if (!Array.isArray(componentData.navigation_group)) {
    return null;
  }

  return (
    <div className={classNames('cds-cms-GridRow', className)}>
      <NavigationComponent
        className='cds-Navigation--CMS'
        key={createKey('navigation')}
        onLinkClick={handleClick}
        styleType={
          componentData.view === NAVIGATION_VIEW_TYPE.vertical
            ? NAVIGATION_STYLE_TYPE.vertical
            : NAVIGATION_STYLE_TYPE.horizontal
        }
        groups={componentData.navigation_group.map(
          (group: NavigationGroupType) => ({
            header: group.header,
            links: group.list_of_links.map((link: NavigationLinkType) => ({
              href: link.url,
              isActive: url === link.url,
              title: link.link_name,
            })),
          }),
        )}
      />
    </div>
  );
};
