import { usePermissionsValidator } from '@client/context/hooks';
import { useTranslate } from '@client/i18n/hooks';
import { MY_ACCOUNT_MENU_ITEM, MyAccountMenuConfigType } from '@client/myAccount/common/utils/myAccountMenu';
import { useRouterPush, useUrlCreator } from '@client/routes/hooks';
import { openLink } from '@client/routes/utils/url';
import { useUserStatsData } from '@client/user-stats';

export type LinkType = {
  href: string;
  isActive: boolean;
  hasBadge?: boolean;
  onClick: () => void;
  title: string;
};

export type UseMyAccountLinksReturnType = (config: MyAccountMenuConfigType) => LinkType[];

export const useMyAccountLinks = (active?: MY_ACCOUNT_MENU_ITEM): UseMyAccountLinksReturnType => {
  const t = useTranslate();
  const text = {
    [MY_ACCOUNT_MENU_ITEM.accountDetails]: t('account details'),
    [MY_ACCOUNT_MENU_ITEM.addresses]: t('addresses'),
    [MY_ACCOUNT_MENU_ITEM.balanceDetails]: t('balance'),
    [MY_ACCOUNT_MENU_ITEM.creditNotes]: t('credit notes'),
    [MY_ACCOUNT_MENU_ITEM.dashboard]: t('dashboard'),
    [MY_ACCOUNT_MENU_ITEM.deliveredOrders]: t('Delivered orders'),
    [MY_ACCOUNT_MENU_ITEM.invoices]: t('invoices'),
    [MY_ACCOUNT_MENU_ITEM.newsletterSettings]: t('newsletter settings'),
    [MY_ACCOUNT_MENU_ITEM.orders]: t('Open orders'),
    [MY_ACCOUNT_MENU_ITEM.rma]: t('RMAs'),
    [MY_ACCOUNT_MENU_ITEM.usersAndRoles]: t('users & roles'),
    [MY_ACCOUNT_MENU_ITEM.wishlists]: t('wishlists'),
    [MY_ACCOUNT_MENU_ITEM.costCenters]: t('Cost Centers'),
    [MY_ACCOUNT_MENU_ITEM.orderRequests]: t('Order requests'),
  };
  const push = useRouterPush();
  const permissionsValidator = usePermissionsValidator();
  const urlCreator = useUrlCreator();
  const { totalOrdersToReview } = useUserStatsData();
  const handleLinkClick = (url: string) => openLink(url, push);

  return (config: MyAccountMenuConfigType): LinkType[] => Object.entries(config).reduce(
    (links: LinkType[], [myAccountMenuItem, myAccountMenuConfigItem]) => {
      if (permissionsValidator(myAccountMenuConfigItem.permissionsRequired)) {
        links.push({
          href: urlCreator(
            myAccountMenuConfigItem.route,
            myAccountMenuConfigItem.routeParams,
          ),
          isActive: active === myAccountMenuItem,
          onClick: () => handleLinkClick(
            urlCreator(
              myAccountMenuConfigItem.route,
              myAccountMenuConfigItem.routeParams,
            ),
          ),
          hasBadge: myAccountMenuItem === MY_ACCOUNT_MENU_ITEM.orderRequests && !!totalOrdersToReview,
          title: text[myAccountMenuItem as MY_ACCOUNT_MENU_ITEM],
          ...(myAccountMenuConfigItem.icon && { icon: myAccountMenuConfigItem.icon }),
        });
      }

      return links;
    },
    [],
  );
};
