import { Search } from '@client/catalog/api/Search';
import {
  fetchCatalogSearch,
  fetchCatalogSearchFulfilled,
  fetchCatalogSearchRejected,
} from '@client/catalog/redux/search/redux';
import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { all, call, put, takeLatest } from 'redux-saga/effects';

function* fetchCatalogSearchSaga(
  action: ReturnType<typeof fetchCatalogSearch>,
): Generator<any, any, any> {
  const { query, identifier } = action.payload;

  try {
    const response = yield call(requestApiSaga, Search.fetch(query));

    yield put(
      fetchCatalogSearchFulfilled({
        data: Search.mapResponseToState(response),
        identifier,
      }),
    );
  } catch (error) {
    yield put(fetchCatalogSearchRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: { error: action.payload } }));
  }
}

export function* catalogSearchSaga(): Generator<any, any, any> {
  yield all([
    takeLatest(fetchCatalogSearch.type, fetchCatalogSearchSaga),
  ]);
}
