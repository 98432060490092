import { FilterGroup } from 'b2b-common/core/catalog/Catalog.types';

export const SORTING_OPTIONS = {
  nameasc: 'nameasc',
  namedesc: 'namedesc',
  priceasc: 'priceasc',
  pricedesc: 'pricedesc',
  stockdesc: 'stockdesc',
};

export const FILTERS = {
  category: 'category',
  facetFilters: 'facetFilters',
  pagination: 'pagination',
  query: 'query',
};

export const FACET_FILTERS = {
  art: 'Art',
  manufacturer: 'facetManufacturerName',
  priceGroup_: 'priceGroup_',
  availability: 'availability',
  inkFinderManufacturer: 'attributeGroupFacet_tonerFinder_manufacturer',
  inkFinderModel: 'attributeGroupFacet_tonerFinder_printer',
  inkFinderOriginalProduct: 'attributeGroupFacet_originalprodukt',
  inkFinderRainbowKit: 'attributeGroupFacet_rainbow-kit',
  customerAssortment: 'customerAssortment',
};

export const ASSORTMENT_FILTERS = {
  coreAssortment: 'coreAssortment',
  ownAssortment: 'ownAssortment',
};

export const FIELD_REG_EXP = {
  facetManufacturerName: /^facetManufacturerName$/ as RegExp,
  Art: /^Art$/ as RegExp,
  priceGroup_: /^priceGroup_/ as RegExp,
  availability: /^availability$/ as RegExp,
};

export const SECTIONS_OPENED_BY_DEFAULT = [
  FACET_FILTERS.availability,
  FACET_FILTERS.art,
  FACET_FILTERS.priceGroup_,
  FACET_FILTERS.customerAssortment,
];

export const CHECKBOX_STATE_KEYS = {
  checked: 'checked',
  unchecked: 'unchecked',
};

export const translateFilterName = (
  t: (...args: any[]) => any,
  filterFacet: string,
  name: string,
): string => {
  const translations: Record<string, string> = {
    [FACET_FILTERS.manufacturer]: t('Brand filter', {}, 'Catalog filter name'),
    [FACET_FILTERS.customerAssortment]: t('Customer assortment filter', {}, 'Catalog filter name'),
  };

  if (translations.hasOwnProperty.call(translations, filterFacet)) {
    return translations[filterFacet] as string;
  }

  return name;
};

export const getActiveSectionKeyArray = (
  facetFilters: FilterGroup[],
): string[] => {
  const activeSectionsFields = facetFilters
    .filter((filterGroup) => {
      const { elements, slider } = filterGroup;
      return (
        slider?.checked || elements.filter((element) => element.checked).length
      );
    })
    .map((filterGroup) => filterGroup.field);
  return [...new Set(SECTIONS_OPENED_BY_DEFAULT.concat(activeSectionsFields))];
};

export const matchFiltersByField = (
  facetFilters: FilterGroup[],
): ((fieldRegExp: RegExp) => FilterGroup[]
  ) => (
  fieldRegExp: RegExp,
): FilterGroup[] => {
  const sortedFilters: FilterGroup[] = [];

  facetFilters.forEach(() => {
    const groupIndex = facetFilters.findIndex((filter: FilterGroup) => fieldRegExp.test(filter.field));

    if (groupIndex >= 0) {
      sortedFilters.push(facetFilters[groupIndex] as FilterGroup);
      facetFilters.splice(groupIndex, 1);
    }
  });

  return sortedFilters;
};

export const getSortedFacetFilters = (
  facetFilters: FilterGroup[],
): FilterGroup[] => {
  const unsortedFacetFilters = [...facetFilters];
  const getMatchingFilters = matchFiltersByField(unsortedFacetFilters);
  const availabilityFilters = getMatchingFilters(FIELD_REG_EXP.availability);
  const manufacturersFilters = getMatchingFilters(
    FIELD_REG_EXP.facetManufacturerName,
  );
  const artFilters = getMatchingFilters(FIELD_REG_EXP.Art);
  const priceFilters = getMatchingFilters(FIELD_REG_EXP.priceGroup_);
  return [
    ...availabilityFilters,
    ...manufacturersFilters,
    ...unsortedFacetFilters,
    ...artFilters,
    ...priceFilters,
  ];
};

export const getNonEmptyFilters = (
  sortedFilters: FilterGroup[],
): FilterGroup[] => sortedFilters.filter(
  (filterGroup) => filterGroup.elements.length || filterGroup.slider,
);

export const mapSectionFieldsToNumbers = (
  fields: string[],
  sortedFilters: FilterGroup[],
): number[] => {
  const nonEmptyFilters = getNonEmptyFilters(sortedFilters);
  return fields
    .map((field) => nonEmptyFilters.findIndex((filterGroup) => {
      if (FIELD_REG_EXP.priceGroup_.test(filterGroup.field)) {
        return filterGroup.field.startsWith(field);
      }

      return filterGroup.field === field;
    }))
    .filter((index) => index >= 0);
};

export const mapNumbersToSectionFields = (
  numbers: number[],
  sortedFilters: FilterGroup[],
): string[] => {
  const nonEmptyFilters = getNonEmptyFilters(sortedFilters);

  return numbers.map((number) => nonEmptyFilters[number]?.field) as string[];
};

export const getFacetFilterKey = (value: string, checked: boolean): string => `${value}-${
  checked ? CHECKBOX_STATE_KEYS.checked : CHECKBOX_STATE_KEYS.unchecked
}`;

export const getFacetFilterCheckedValues = (filter: FilterGroup): string[] => filter.elements
  .filter((element) => element.checked).map((element) => element.value);

export const getIsActiveFilter = (filter: FilterGroup): boolean => filter.elements
  .some((element) => element.checked) || filter.slider?.checked;

export const inkFinderFields = [
  FACET_FILTERS.inkFinderManufacturer,
  FACET_FILTERS.inkFinderModel,
  FACET_FILTERS.inkFinderOriginalProduct,
  FACET_FILTERS.inkFinderRainbowKit,
];

export const getIsInkFinderAvailable = (
  facetFilters: FilterGroup[],
): boolean => facetFilters.some(el => el.field === FACET_FILTERS.inkFinderManufacturer);

export const getInkFinderFilters = (
  facetFilters: FilterGroup[],
): FilterGroup[] => facetFilters
  .filter(el => inkFinderFields
    .some(inkFinderField => inkFinderField === el.field));

export const getIsCoreAssortmentFilterAvailable = (
  facetFilters: FilterGroup[],
): boolean => facetFilters.some(
  filter => filter.field === FACET_FILTERS.customerAssortment && filter.elements.some(
    subFilter => subFilter.value === ASSORTMENT_FILTERS.coreAssortment,
  ),
);

export const getIsCoreAssortmentFilterActive = (
  facetFilters: FilterGroup[],
): boolean => facetFilters.some(
  filter => filter.field === FACET_FILTERS.customerAssortment && filter.elements.some(
    subFilter => subFilter.value === ASSORTMENT_FILTERS.coreAssortment && subFilter.checked === true,
  ),
);
