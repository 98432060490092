import { ReactElement } from 'react';
import { Full } from '@client/common/components/HiddenPrice/Full';
import { Icon } from '@client/common/components/HiddenPrice/Icon';
import { ComponentType, Props } from './';

enum HIDDEN_PRICE_DISPLAY_TYPE {
  full = 'full',
  icon ='icon',
}

const HiddenPrice: ComponentType = ({
  className,
  isHiddenPriceAndNotCertified,
  displayType = HIDDEN_PRICE_DISPLAY_TYPE.icon,
  hasCurrencyCode = true,
  hasTooltip = true,
}: Props): ReactElement => {
  switch (displayType) {
    case HIDDEN_PRICE_DISPLAY_TYPE.full:
      return (
        <Full
          className={className}
          hasCurrencyCode={hasCurrencyCode}
          isHiddenPriceAndNotCertified={isHiddenPriceAndNotCertified}
        />
      );

    case HIDDEN_PRICE_DISPLAY_TYPE.icon:
    default:
      return (
        <Icon
          className={className}
          hasTooltip={hasTooltip}
          hasCurrencyCode={hasCurrencyCode}
          isHiddenPriceAndNotCertified={isHiddenPriceAndNotCertified}
        />
      );
  }
};

export {
  HIDDEN_PRICE_DISPLAY_TYPE,
  HiddenPrice,
};
