import { InteractionProduct } from '@client/analytics/redux';
import { ANALYTICS_BOOLEAN, TEALIUM_PROD_ACTION, TEALIUM_PROD_LIST } from '@client/analytics/types/types';
import { prepareAnalyticsBool, preparePrice, prepareQuantity, prepareStock } from '@client/analytics/utils/analytics';
import { ANALYTICS_ATTRIBUTES, BaseBuilder } from '@client/analytics/utils/builders/BaseBuilder';

export class ProductInteractionAnalyticsProductsPropertyBuilder
  extends BaseBuilder<InteractionProduct> {
  private action: TEALIUM_PROD_ACTION;
  private source: TEALIUM_PROD_LIST;
  private products: InteractionProduct[]

  public setAction(action: TEALIUM_PROD_ACTION): ProductInteractionAnalyticsProductsPropertyBuilder {
    this.action = action;
    return this;
  }

  public setSource(source: TEALIUM_PROD_LIST): ProductInteractionAnalyticsProductsPropertyBuilder {
    this.source = source;
    return this;
  }

  public setProducts(products: InteractionProduct[]): ProductInteractionAnalyticsProductsPropertyBuilder {
    this.products = products;
    return this;
  }

  public build(): any {
    this.analyticsResultObjectArray = this.products.map(product => ({
      attributes: [
        ANALYTICS_ATTRIBUTES.id,
        ANALYTICS_ATTRIBUTES.categories,
        ANALYTICS_ATTRIBUTES.price,
        ANALYTICS_ATTRIBUTES.stock,
        ...(this.action ? [ANALYTICS_ATTRIBUTES.action] : []),
        ...(product.listPosition != null ? [ANALYTICS_ATTRIBUTES.pos] : []),
        ...(this.source ? [ANALYTICS_ATTRIBUTES.list] : []),
        ...(product.quantity != null ? [
          ANALYTICS_ATTRIBUTES.quan,
          ANALYTICS_ATTRIBUTES.purchasable,
          ANALYTICS_ATTRIBUTES.hasService,
        ] : []),
      ],
      analyticsResultObject: {
        [ANALYTICS_ATTRIBUTES.id]: product.sku,
        [ANALYTICS_ATTRIBUTES.categories]: product.categories,
        [ANALYTICS_ATTRIBUTES.price]: preparePrice(product.price),
        [ANALYTICS_ATTRIBUTES.stock]: prepareStock(product.stock),
        ...(this.action && { [ANALYTICS_ATTRIBUTES.action]: this.action }),
        ...(product.listPosition != null && { [ANALYTICS_ATTRIBUTES.pos]: `${product.listPosition}` }),
        ...(this.source && { list: this.source }),
        ...(product.quantity != null && {
          [ANALYTICS_ATTRIBUTES.quan]: prepareQuantity(product.quantity),
          [ANALYTICS_ATTRIBUTES.purchasable]: prepareAnalyticsBool(product.isPurchasable),
          [ANALYTICS_ATTRIBUTES.hasService]: ANALYTICS_BOOLEAN.false,
        }),
      },
    }));


    return {
      [ANALYTICS_ATTRIBUTES.prod]: super.build() || [],
    };
  }
}
