import { analyzeEvent } from '@client/analytics/redux/tealium/actions';
import { TEALIUM_PROD_LIST } from '@client/analytics/types/types';
import {
  ProductListAnalyticsProductPropertyBuilder,
} from '@client/analytics/utils/builders/ProductListAnalyticsProductPropertyBuilder';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { selectProductAccessoriesBySku } from '@client/product/redux/productAccessories/redux';
import { ProductAccessoriesType, ProductAccessoryType } from '@client/product/redux/productAccessories/types';
import { call, CallEffect, put, PutEffect, select, SelectEffect } from 'redux-saga/effects';
import { AnalyzeProductsInteraction } from '../types';
import { getInteractionProduct } from '../utils';
import { analyzeProductAddToCartFromListInteraction, analyzeProductClickInteraction } from './interactions';

type ProductAccessories = { count: number, products: ProductAccessoryType[] };

function* getProductAccessories(sku: string):
  Generator<
    SelectEffect,
    ProductAccessories,
    RemoteRecord<ProductAccessoriesType>
  > {
  const accessoriesRecord = yield select(selectProductAccessoriesBySku, sku);

  if (!accessoriesRecord?.data?.length) {
    return;
  }

  return accessoriesRecord.data.reduce(
    (accessories, accessory) => ({
      count: accessories.count + accessory.count,
      products: [
        ...accessories.products,
        ...accessory.products,
      ],
    }),
    { count: 0, products: [] },
  );
}

export function* analyzeProductAccessoriesListView(sku: string):
  Generator<
    CallEffect | PutEffect | SelectEffect,
    void,
    ProductAccessories
  > {
  const productAccessories = yield call(getProductAccessories, sku);

  if (!productAccessories?.products.length) {
    return;
  }

  const { products, count } = productAccessories;

  const builder = new ProductListAnalyticsProductPropertyBuilder();
  builder
    .setProductList(products)
    .setSkuList(products.map((product) => ({ sku: product.sku })))
    .setPage(1)
    .setPageSize(count)
    .setPageType(TEALIUM_PROD_LIST.accessories);

  yield put(analyzeEvent(
    builder.build(),
  ));
}

function* analyzeAccessoryProductInteraction(
  interaction: AnalyzeProductsInteraction,
  accessorySku: string,
  productSku: string,
  listPosition?: number,
  quantity?: number,
): Generator<
    CallEffect | SelectEffect,
    void,
    ProductAccessories
  > {
  const productAccessories = yield call(getProductAccessories, productSku);

  if (!productAccessories?.products.length) {
    return;
  }

  const { products } = productAccessories;
  const accessory = products.find(accessory => accessory.sku === accessorySku);

  if (!accessory) {
    return;
  }

  yield call(
    interaction,
    TEALIUM_PROD_LIST.accessories,
    {
      products: [{ ...getInteractionProduct(accessory), listPosition, quantity }],
    },
  );
}

export function* analyzeAccessoryProductClick(
  accessorySku: string,
  productSku: string,
  listPosition: number,
): Generator<CallEffect, void> {
  yield call(
    analyzeAccessoryProductInteraction,
    analyzeProductClickInteraction,
    accessorySku,
    productSku,
    listPosition,
  );
}

export function* analyzeAccessoryProductAddToCart(
  accessorySku: string,
  productSku: string,
  listPosition: number,
): Generator<CallEffect, void> {
  yield call(
    analyzeAccessoryProductInteraction,
    analyzeProductAddToCartFromListInteraction,
    accessorySku,
    productSku,
    listPosition,
    1,
  );
}
