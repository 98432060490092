import { lazy } from 'react';
import { AccessCallbackDataType } from '@client/routes/components/Router/routes';
import { getLocalisedPaths } from '@client/routes/utils/paths';
import { PERMISSION } from 'b2b-common/core/context/Context.types';
import { DEFAULT_ROUTE } from './constants';
import { PRODUCT_CONFIGURATOR_ROUTES_NAME, ProductConfiguratorRouteType } from './types';


export const productConfigurator: ProductConfiguratorRouteType = {
  [PRODUCT_CONFIGURATOR_ROUTES_NAME.productConfiguratorFail]: {
    ...DEFAULT_ROUTE,
    paths: getLocalisedPaths('product/:sku/configurator/fail'),
    component: lazy(() => import(
      /* webpackChunkName: "product/configurator/failPage" */
      '@client/product/components/configurators/ProductConfiguratorFailPage'
    )),
    allow: ({
      isLoggedIn,
      isBlocked,
      permissionsValidator,
    }: AccessCallbackDataType): boolean => isLoggedIn && !isBlocked && permissionsValidator([PERMISSION.accessCart]),
  },
  [PRODUCT_CONFIGURATOR_ROUTES_NAME.productConfigurator]: {
    ...DEFAULT_ROUTE,
    paths: getLocalisedPaths('product/:sku/configurator/:partAuxiliaryId?'),
    component: lazy(() => import(
      /* webpackChunkName: "product/configurator/page" */
      '@client/product/components/configurators/ProductConfiguratorPage'
    )),
    allow: ({
      isLoggedIn,
      isBlocked,
      permissionsValidator,
    }: AccessCallbackDataType): boolean => isLoggedIn && !isBlocked && permissionsValidator([PERMISSION.accessCart]),
  },
};
