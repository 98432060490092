import { ReactElement } from 'react';
import { upperCaseFirst } from '@client/common/utils/string/string';
import { useTranslate } from '@client/i18n/hooks';
import { getProductFallbackThumbnailUrl } from '@client/routes/utils/url';
import classNames from 'classnames';
import { Alert, ALERT_DISPLAY_TYPE, ALERT_STYLE_TYPE } from 'b2b-components/theme/components/Alert';
import { Props } from './FailedProductBox.types';
import './FailedProductBox.scss';

export const FailedProductBox = ({
  styleType,
  className,
  sku,
}: Props): ReactElement => {
  const t = useTranslate();

  const rootClassName = classNames(
    'FailedProductBox',
    { [`FailedProductBox--${upperCaseFirst(styleType || '')}`]: styleType },
    className,
  );
  const fallbackImgUrl = getProductFallbackThumbnailUrl();

  const message = t('Sorry, this article could not be loaded due to incorrect data in our systems (sku {sku})',
    { sku });

  return (
    <div className={rootClassName}>
      <img className="FailedProductBox-ImagePlaceholder" src={fallbackImgUrl} alt={t('Inactive product image')} />

      <Alert styleType={ALERT_STYLE_TYPE.warning} displayType={ALERT_DISPLAY_TYPE.text}>
        {message}
      </Alert>
    </div>
  );
};
