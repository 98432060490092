import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CostCentersType } from 'b2b-common/core/account/api/costCenters/CostCenters.types';

const NAME = 'costCenters';

export const costCentersSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {
    costCenters: RemoteRecordData.getDefault(),
    costCentersSearch: RemoteRecordData.getDefault(),
  },
  reducers: {
    fetchCostCenters: (state, action: PayloadAction<number>) => {
      state.costCenters = RemoteRecordData.init();
      return state;
    },
    fetchCostCentersFulfilled: (state, action: PayloadAction<CostCentersType>) => {
      state.costCenters = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchCostCentersRejected: (state, action: PayloadAction<ErrorType>) => {
      state.costCenters = RemoteRecordData.reject(action.payload);
      return state;
    },
    fetchCostCentersSearch: (state, action: PayloadAction<{
      page: number,
      query: string,
    }>) => {
      state.costCentersSearch = RemoteRecordData.init();
      return state;
    },
    fetchCostCentersSearchFulfilled: (state, action: PayloadAction<CostCentersType>) => {
      state.costCentersSearch = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchCostCentersSearchRejected: (state, action: PayloadAction<ErrorType>) => {
      state.costCentersSearch = RemoteRecordData.reject(action.payload);
      return state;
    },
    clearCostCentersSearch: (state, action: PayloadAction<{
      query: string;
      includeInactive: boolean,
    }>) => {
      state.costCentersSearch = RemoteRecordData.getDefault();
      return state;
    },
  },
});

export const {
  fetchCostCenters,
  fetchCostCentersFulfilled,
  fetchCostCentersRejected,
  fetchCostCentersSearch,
  fetchCostCentersSearchFulfilled,
  fetchCostCentersSearchRejected,
  clearCostCentersSearch,
} = costCentersSlice.actions;

export const fetchCostCentersSimpleSearch = createAction<{
  query: string;
  includeInactive?: boolean,
}>('fetchCostCentersSimpleSearch');

const getState = (state: AppState) => state.myAccount.costCenter.costCenters;

export const selectCostCenters = createSelector(
  getState,
  state => new RemoteRecord<CostCentersType>(state.costCenters),
);

export const selectCostCentersSearch = createSelector(
  getState,
  state => new RemoteRecord<CostCentersType>(state.costCentersSearch),
);
