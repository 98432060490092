import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Collection } from '@client/content/api/Collection';
import {
  fetchContentCollection,
  fetchContentCollectionFulfilled,
  fetchContentCollectionRejected,
} from '@client/content/redux/contentCollections/redux';
import { all, call, put, takeEvery } from 'redux-saga/effects';

function* fetchContentCollectionSaga(
  action: ReturnType<typeof fetchContentCollection>,
): Generator<any, any, any> {
  const { collectionId, filters } = action.payload;

  try {
    const response = yield call(
      requestApiSaga,
      Collection.fetch(collectionId, filters),
    );
    yield put(
      fetchContentCollectionFulfilled({
        collectionId,
        data: Collection.mapResponseToState(response),
      }),
    );
  } catch (error) {
    yield put(fetchContentCollectionRejected({ collectionId, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* contentCollectionsSaga(): Generator<any, any, any> {
  yield all([takeEvery(fetchContentCollection.type, fetchContentCollectionSaga)]);
}
