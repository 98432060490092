import { ReactElement } from 'react';
import { Page } from '@client/app/components/App/Page';
import { Logo } from '@client/common/components/Logo';
import classNames from 'classnames';
import { ComponentType, Props } from './';
import './ErrorPage.scss';

const ErrorPage: ComponentType = ({
  pageTitle,
  metaTags,
  image,
  imageAlt,
  title,
  subtitle,
  children,
  className,
}: Props): ReactElement => {
  const rootClassName = classNames('App', 'ErrorPage', className);

  return (
    <Page title={pageTitle} metaTags={metaTags} className={rootClassName}>
      <div className="ErrorPage-TopBar" />

      <div className="ErrorPage-Content">
        <Logo className="ErrorPage-Logo" />
        <img src={image} alt={imageAlt} className="ErrorPage-Image" />
        <h1 className="ErrorPage-Title">{title}</h1>
        <h2 className="ErrorPage-Subtitle">{subtitle}</h2>
        {children}
      </div>
    </Page>
  );
};

export { ErrorPage };
