import { lazy } from 'react';
import { getLocalisedPaths } from '@client/routes/utils/paths';
import { PERMISSION } from 'b2b-common/core/context/Context.types';
import { AUTHENTICATED_ROUTE } from '../constants';
import { FINANCE_ROUTES_NAMES, FinanceRouteType } from './types';


export const finance: FinanceRouteType = {
  [FINANCE_ROUTES_NAMES.myAccountBalanceDetails]: {
    ...AUTHENTICATED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/balance-details'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/balance-details" */
      '@client/myAccount/balanceDetails/components/ViewPage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }) => isLoggedIn
      && permissionsValidator([PERMISSION.accessMyAccountBasic]),
  },
  [FINANCE_ROUTES_NAMES.myAccountInvoices]: {
    ...AUTHENTICATED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/invoices/:page?'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/invoices" */
      '@client/myAccount/invoices/components/ListPage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }) => isLoggedIn
      && permissionsValidator([PERMISSION.accessMyAccountBasic]),
  },
  [FINANCE_ROUTES_NAMES.myAccountInvoice]: {
    ...AUTHENTICATED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/invoice/:invoiceId'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/invoice" */
      '@client/myAccount/invoices/components/ViewPage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }) => isLoggedIn
      && permissionsValidator([PERMISSION.accessMyAccountBasic]),
  },
  [FINANCE_ROUTES_NAMES.myAccountCredits]: {
    ...AUTHENTICATED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/credits/:page?'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/credits" */
      '@client/myAccount/credits/components/ListPage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }) => isLoggedIn
      && permissionsValidator([PERMISSION.accessMyAccountBasic]),
  },
};
