import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { stringify } from '@client/common/utils/queryParams';

type SearchParams = {
  pageSize: number,
  startItem: number,
  searchQuery: string,
  isPendingOnly: number,
}

export class Invoices {
  public static fetch(pageSize: number, startItem: number): RequestConfigType {
    const searchParams = {
      pageSize,
      startItem,
    };

    return RequestConfig.get(`/account/invoices?${stringify(searchParams)}`, { withLocalization: false });
  }

  public static search({
    pageSize,
    startItem,
    searchQuery,
    isPendingOnly,
  }: SearchParams): RequestConfigType {
    const searchParams = {
      pageSize,
      startItem,
      q: searchQuery,
      pendingOnly: isPendingOnly,
    };

    return RequestConfig.get(`/account/invoices/search?${stringify(searchParams)}`, { withLocalization: false });
  }
}
