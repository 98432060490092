import { ReactElement } from 'react';
import { PrimaryLayout } from '@lib/components/PrimaryLayout';
import { DISPLAY_TYPE, Infobar, InfobarType, STYLE_TYPE } from './Infobar';
import { Props } from './Infobars.types';
import './Infobars.scss';

export const Infobars = ({ infobars }: Props): ReactElement => {
  const infobarsList = infobars.map(
    (infobar: InfobarType) => {
      const { title, styleType, displayType, message, id, onDismiss } = infobar;

      return (
        <Infobar
          title={title}
          styleType={styleType}
          displayType={displayType}
          message={message}
          onDismiss={onDismiss}
          id={id}
          key={id}
        />
      );
    },
  );

  return (
    <div className="Infobars">
      <PrimaryLayout>{infobarsList}</PrimaryLayout>
    </div>
  );
};

export {
  DISPLAY_TYPE as INFOBAR_DISPLAY_TYPE,
  STYLE_TYPE as INFOBAR_STYLE_TYPE,
};
