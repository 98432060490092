import stringHash from 'string-hash';

export const calculateHash = (o: Record<string, any>): string => stringHash(JSON.stringify(o)) + '';

export const compact = (obj: Record<string, any>): Record<string, any> => Object
  .keys(obj)
  .reduce(
    (o: Record<string, any>, k: string) => {
      if (typeof obj[k] !== 'undefined') {
        o[k] = obj[k];
      }

      return o;
    },
    {},
  );

export const isPrimitive = (data: any): boolean => {
  switch (true) {
    case data == null:
    case typeof data === 'string':
    case typeof data === 'number':
    case typeof data === 'boolean':
      return true;

    default:
      return false;
  }
};

export const isObject = (value: any): value is Record<string, any> => {
  const type = typeof value;
  return value != null && (type === 'object' || type === 'function');
};

export const castToEnum = <T extends Record<string, any>>(
  valueToCast: string,
  enumObject: T,
  fallbackValue: any,
): T[keyof T] => {
  if (!valueToCast) {
    return fallbackValue;
  }

  const sanitizedValue = `${valueToCast}`.toLowerCase();
  const matchingValue = Object.values(enumObject).find(
    (value) => value.toLowerCase() === sanitizedValue,
  );

  return matchingValue || fallbackValue;
};
