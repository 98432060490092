import { ReactElement } from 'react';
import { CopyableText } from '@client/common/components/CopyableText';
import { HiddenPrice } from '@client/common/components/HiddenPrice/HiddenPrice';
import { FailedProductBox } from '@client/common/components/ProductBox/FailedProductBox';
import { SCREENSHOT_TOOL_DATA_ATTR_MPT_ID } from '@client/common/screenshotTool';
import { useTranslate } from '@client/i18n/hooks';
import { ROUTES } from '@client/routes/components/Router/routes';
import { getCdnImageUrl } from '@client/routes/utils/url';
import {
  PRODUCT_BOX_STYLE_TYPE,
  ProductBox as ProductBoxComponent,
  ProductBoxSkeleton,
} from '@lib/components/ProductList';
import classNames from 'classnames';
import { Actions } from './Actions';
import { Props } from './ProductBox.types';
import './ProductBox.scss';

export const ProductBox = ({
  product: productData,
  sku,
  deviceType,
  category,
  imageUrl,
  variants,
  isAddToCartHidden,
  areVariantsHidden,
  isForSlider,
  isFromContentList,
  mptId,
  push,
  urlCreator,
  styleType,
  onClick,
  onAddToCart,
  className,
  showDirectDelivery,
}: Props): ReactElement => {
  const t = useTranslate();
  const {
    data: product,
    hasFailed,
    isLoaded,
    isLoading,
  } = productData || {};
  const { description: energyLabelData } = product || {};
  const energyLabelImage = energyLabelData?.energyLabel?.image[0]?.sizes?.filter((image: any) => image.size === 'XXL');
  const energyLabelImageUrl = getCdnImageUrl(energyLabelImage?.[0].link);
  const energyLabelText = {
    close: t('close'),
    energyLabel: t('Energy Label'),
  };

  const productBoxAdditionalProps = {
    dataAttributes: {},
  };

  if (mptId) {
    productBoxAdditionalProps.dataAttributes = {
      [SCREENSHOT_TOOL_DATA_ATTR_MPT_ID]: mptId,
    };
  }

  const rootClassName = classNames(
    'cds-ProductBox',
    {
      'cds-ProductBox--List': styleType === PRODUCT_BOX_STYLE_TYPE.list,
    },
    className,
  );

  const handleRenderSku = (skuNumber: string): ReactElement => (
    <CopyableText className='cds-HideMobileNativeStyling'>{skuNumber}</CopyableText>
  );

  const renderHiddenPrice = (isHiddenPriceAndNotCertified: boolean): ReactElement => (
    <HiddenPrice
      className='ProductBox-HiddenPrice'
      hasCurrencyCode={styleType !== PRODUCT_BOX_STYLE_TYPE.table}
      isHiddenPriceAndNotCertified={isHiddenPriceAndNotCertified}
    />
  );

  if (isLoading) {
    return (
      <ProductBoxSkeleton
        styleType={styleType}
        isForSlider={isForSlider}
        {...isForSlider && { className: rootClassName }}
      />
    );
  }

  if (hasFailed || (isLoaded && !product)) {
    if (isFromContentList) {
      return null;
    }

    if (styleType === PRODUCT_BOX_STYLE_TYPE.table) {
      return (
        <tr className='cds-ProductBoxTable-BodyRow'>
          <td colSpan={7}>
            <FailedProductBox sku={sku} styleType={styleType} />
          </td>
        </tr>
      );
    }

    return (
      <li className='cds-ProductBoxWrapper'>
        <FailedProductBox
          sku={sku}
          styleType={styleType}
          className={rootClassName}
        />
      </li>
    );
  }

  if (!isLoaded) {
    return null;
  }

  const text = {
    assortment: t('Assortment'),
    category: t('Category'),
    manufacturerSku: t('Manufacturer Product ID'),
    coreAssortment: t('Core assortment'),
    ownAssortment: t('Customer specific assortment'),
    consciousAlternative: t('Conscious alternative'),
    new: t('new flag', {}, 'Product flag icon - "new" label'),
    occasion: t('verified return flag', {}, 'Product flag icon - "verified return" label'),
    promotion: t('special offer flag', {}, 'Product flag icon - "special offer" label'),
    sellout: t('sale flag', {}, 'Product flag icon - "sale" label'),
    sku: t('SKU'),
    stockCount: {
      date: t('Date'),
      deliveryDates: t('Expected delivery dates'),
      notReadyForShipment: t('Not ready for shipment'),
      orderedQuantity: t('Ordered Quantity'),
      readyForShipment: t(
        [
          '<strong>1</strong> pc. ready for shipment',
          '<strong>{count}</strong> pcs. ready for shipment',
          'count',
        ],
        {
          count: product?.settings.quantity || 0,
        },
      ),
      readyForDirectDelivery: t(
        [
          '<strong>1</strong> pc. ready for direct delivery',
          '<strong>{count}</strong> pcs. ready for direct delivery',
          'count',
        ],
        {
          count: product?.settings.quantity || 0,
        },
      ),
      unknownDeliveryDate: t('Delivery date unknown'),
      specialOrder: {
        full: t('Special Order'),
        short: t('S.O.'),
        explanation: t('We do not stock this item, it will be ordered for you after your order. You will receive an email with details about delivery time.'),
      },
      seeMore: t('see more'),
      directDeliveryStockInfo: t('stock in supplier’s warehouse'),
      deliveryInfoPackage: t('This item ships in a separate package, directly from our supplier.'),
      deliveryInfoTime: t('Delivery time is 3 to 5 business days.'),
    },
    variants: t('Also available in:'),
  };

  if (!product) {
    return null;
  }

  const {
    description,
    effectivePricing,
    manufacturer,
    settings: {
      directDelivery,
      isNew,
      isOccasion,
      isSellout,
      isSpecialOffer,
      isSpecialOrder,
      quantity,
      quantityOrdered,
      isCoreAssortment,
      isOwnAssortment,
      isConsciousAlternative,
    },
    sku: productSku,
  } = product;

  return (
    <ProductBoxComponent
      {...productBoxAdditionalProps}
      basePrice={effectivePricing.basePrice}
      bulletPoints={description.bulletPoints}
      category={category}
      className={className}
      deviceType={deviceType}
      directDelivery={!!showDirectDelivery && !!directDelivery}
      areVariantsHidden={areVariantsHidden}
      isForSlider={isForSlider}
      energyLabel={
        description.energyLabel
          ? {
            range: description.energyLabel.range,
            rating: description.energyLabel.value,
            energyLabelUrl: energyLabelImageUrl,
            text: energyLabelText,
          }
          : null
      }
      imageUrl={imageUrl}
      isNew={isNew}
      isOccasion={isOccasion}
      isPromotion={isSpecialOffer}
      isSellout={isSellout}
      isSpecialOrder={isSpecialOrder}
      isCoreAssortment={isCoreAssortment}
      isOwnAssortment={isOwnAssortment}
      isConsciousAlternative={isConsciousAlternative}
      manufacturerSku={manufacturer.productId}
      onCategoryClick={push}
      onProductClick={onClick}
      price={effectivePricing.mainPrice}
      quantityOrdered={quantityOrdered}
      renderActions={<Actions product={product}
                              isLoaded={isLoaded}
                              deviceType={deviceType}
                              onAddToCart={onAddToCart}
                              isAddToCartHidden={isAddToCartHidden}
      />}
      renderHiddenPrice={
        effectivePricing.isHiddenPrice
          ? renderHiddenPrice(effectivePricing.isHiddenPriceAndNotCertified)
          : null
      }
      sku={productSku}
      renderSku={handleRenderSku}
      stock={quantity || 0}
      styleType={styleType}
      text={text}
      title={description.title}
      url={urlCreator(ROUTES.product, {
        sku: productSku,
      })}
      variantCount={3}
      variants={variants}
    />
  );
};
