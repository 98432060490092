import { analyticsSaga } from '@client/analytics/redux';
import { assortmentSaga } from '@client/assortment/redux/sagas';
import { cartSaga } from '@client/cart/redux/sagas';
import { catalogSaga } from '@client/catalog/redux/sagas';
import { checkoutSaga } from '@client/checkout/redux/sagas';
import { collectedOrderSaga } from '@client/collectedOrder/redux/sagas';
import { errorsSaga } from '@client/common/redux/errors/sagas';
import { contentSaga } from '@client/content/redux/sagas';
import { contextSaga } from '@client/context/redux/sagas';
import { expressOrderSaga } from '@client/expressOrder/redux/sagas';
import { i18nSaga } from '@client/i18n/redux/sagas';
import { kanbanSaga } from '@client/kanban/redux/sagas';
import { manufacturerSaga } from '@client/manufacturer/redux/sagas';
import { myAccountSaga } from '@client/myAccount/common/redux/sagas';
import { newsletterSaga } from '@client/newsletter/redux/sagas';
import { productSaga } from '@client/product/redux/sagas';
import { cXMLSaga } from '@client/punchout/cxml/redux/sagas';
import { ociSaga } from '@client/punchout/oci/redux/sagas';
import { settingsSaga } from '@client/settings/redux/sagas';
import { userStatsSaga } from '@client/user-stats/redux/sagas';
import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';

export function* rootSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    fork(analyticsSaga),
    fork(assortmentSaga),
    fork(cXMLSaga),
    fork(cartSaga),
    fork(catalogSaga),
    fork(checkoutSaga),
    fork(collectedOrderSaga),
    fork(contentSaga),
    fork(contextSaga),
    fork(errorsSaga),
    fork(expressOrderSaga),
    fork(i18nSaga),
    fork(kanbanSaga),
    fork(manufacturerSaga),
    fork(myAccountSaga),
    fork(newsletterSaga),
    fork(ociSaga),
    fork(productSaga),
    fork(settingsSaga),
    fork(userStatsSaga),
  ]);
}
