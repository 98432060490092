import { ReactElement } from 'react';
import { isEmpty } from '@client/common/utils/empty/empty';
import { connect as connectFormik, Field, FieldProps } from 'formik';
import { EnhancedComponentProps, EnhancedComponentType, InjectedProps, WrappedComponentType } from './withField.types';

export const withField = <P extends EnhancedComponentProps>(
  Component: WrappedComponentType<P>,
): EnhancedComponentType<P> => {
  const WithField = (props: P): ReactElement => {
    const { name, onChange, formik, onBlur, innerComponentProps } = props;
    const { errors, setFieldValue, setFieldTouched } = formik;
    const { value: componentValue } = innerComponentProps;
    const isValid = isEmpty(errors[name]);

    const handleChange = async (value: any): Promise<any> => {
      const newValue = componentValue != null ? componentValue : value;

      await setFieldValue(name, newValue);
      onChange?.(name, newValue, isValid);
    };

    const handleBlur = async (value: any): Promise<any> => {
      const newValue = componentValue != null ? componentValue : value;

      await setFieldTouched(name, true);
      onBlur?.(name, newValue, isValid);
    };

    const renderComponent = (formikFieldProps: FieldProps): ReactElement => {
      const injectedProps: InjectedProps = {
        ...formikFieldProps,
        onChange: handleChange,
        onBlur: handleBlur,
      };

      return <Component {...props} {...injectedProps} />;
    };

    return (
      <Field name={name}>
        {renderComponent}
      </Field>
    );
  };

  return connectFormik(WithField) as EnhancedComponentType<P>;
};
