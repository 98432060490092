import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { AxiosResponse } from 'axios';
import { ProductResponseType, ReportProductErrorDetails } from 'b2b-common/shop/product/api/Product.types';

class Product {
  public static fetch(sku: string): RequestConfigType {
    return RequestConfig.get(`/products/${sku}`);
  }

  public static mapResponseToState(response: AxiosResponse<ProductResponseType>): any {
    return response.data;
  }

  public static reportError(formData: ReportProductErrorDetails): RequestConfigType {
    return RequestConfig.post(`/products/${formData.sku}/report`, formData);
  }
}

export { Product };
