import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddItemsToCartType } from 'b2b-common/core/cart/api/CartTypes';
import {
  AddItemsToCartRequestBodyType,
  AddItemsToWishlistRequestBodyType,
  VerifyRequestBodyType,
  VerifyType,
} from 'b2b-common/core/expressOrder/api/ExpressOrder.types';

const NAME = 'expressOrder';

export const expressOrderSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {
    addItemsToCart: RemoteRecordData.getDefault(),
    addItemsToWishlist: RemoteRecordData.getDefault(),
    verify: RemoteRecordData.getDefault(),
  },
  reducers: {
    addExpressOrderItemsToCart: (state, action: PayloadAction<AddItemsToCartRequestBodyType>) => {
      state.addItemsToCart = RemoteRecordData.init();
      return state;
    },
    addExpressOrderItemsToCartFulfilled: (state, action: PayloadAction<AddItemsToCartType>) => {
      state.addItemsToCart = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    addExpressOrderItemsToCartRejected: (state, action: PayloadAction<ErrorType>) => {
      state.addItemsToCart = RemoteRecordData.reject(action.payload);
      return state;
    },
    addExpressOrderItemsToWishlist: (state, action: PayloadAction<AddItemsToWishlistRequestBodyType>) => {
      state.addItemsToWishlist = RemoteRecordData.init();
      return state;
    },
    addExpressOrderItemsToWishlistFulfilled: (state, action: PayloadAction<string>) => {
      state.addItemsToWishlist = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    addExpressOrderItemsToWishlistRejected: (state, action: PayloadAction<ErrorType>) => {
      state.addItemsToWishlist = RemoteRecordData.reject(action.payload);
      return state;
    },
    clearAddExpressOrderItemsToWishlist: (state) => {
      state.addItemsToWishlist = RemoteRecordData.getDefault();
      return state;
    },
    clearExpressOrder: (state) => {
      state.addItemsToCart = RemoteRecordData.getDefault();
      state.addItemsToWishlist = RemoteRecordData.getDefault();
      state.verify = RemoteRecordData.getDefault();
      return state;
    },
    removeVerifyItem: (state, action: PayloadAction<number>) => {
      const data = state.verify && Array.isArray(state.verify.data)
        ? state.verify.data.filter((item, index) => index !== action.payload)
        : [];
      state.verify = RemoteRecordData.fulfill(data);
      return state;
    },
    uploadCsv: (state, action: PayloadAction<File>) => {
      state.verify = Object.assign(RemoteRecordData.init(), { data: state.verify.data });
      return state;
    },
    uploadCsvFulfilled: (state, action: PayloadAction<VerifyType>) => {
      state.verify = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    uploadCsvRejected: (state, action: PayloadAction<ErrorType>) => {
      state.verify = RemoteRecordData.reject(action.payload);
      return state;
    },
    verify: (state, action: PayloadAction<VerifyRequestBodyType>) => {
      state.verify = Object.assign(RemoteRecordData.init(), { data: state.verify.data });
      return state;
    },
    verifyFulfilled: (state, action: PayloadAction<VerifyType>) => {
      state.verify = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    verifyRejected: (state, action: PayloadAction<ErrorType>) => {
      state.verify = RemoteRecordData.reject(action.payload);
      return state;
    },
  },
});

export const {
  addExpressOrderItemsToCart,
  addExpressOrderItemsToCartFulfilled,
  addExpressOrderItemsToCartRejected,
  addExpressOrderItemsToWishlist,
  addExpressOrderItemsToWishlistFulfilled,
  addExpressOrderItemsToWishlistRejected,
  clearAddExpressOrderItemsToWishlist,
  clearExpressOrder,
  removeVerifyItem,
  uploadCsv,
  uploadCsvFulfilled,
  uploadCsvRejected,
  verify,
  verifyFulfilled,
  verifyRejected,
} = expressOrderSlice.actions;

const getState = (state: AppState) => state.expressOrder;

export const selectExpressOrderVerify = createSelector(
  getState,
  (state) => new RemoteRecord<VerifyType>(state.verify),
);

export const selectExpressOrderAddItemsToCart = createSelector(
  getState,
  (state) => new RemoteRecord<AddItemsToCartType>(state.addItemsToCart),
);

export const selectExpressOrderAddItemsToWishlist = createSelector(
  getState,
  (state) => new RemoteRecord<string>(state.addItemsToWishlist),
);
