import { BARCODE_VALUE, BarcodeAnalyticsComponentType, CATEGORY_SET_VALUE } from '@client/analytics/redux';
import { TEALIUM_PROD_ACTION } from '@client/analytics/types/types';
import { ANALYTICS_ATTRIBUTES, BaseBuilder } from '@client/analytics/utils/builders/BaseBuilder';


export class BarcodeAnalyticsComponentPropertyBuilder
  extends BaseBuilder<BarcodeAnalyticsComponentType> {
  protected attributes: Array<ANALYTICS_ATTRIBUTES> = [
    ANALYTICS_ATTRIBUTES.attribute1,
    ANALYTICS_ATTRIBUTES.attribute3,
    ANALYTICS_ATTRIBUTES.category,
    ANALYTICS_ATTRIBUTES.subcategory,
    ANALYTICS_ATTRIBUTES.location,
  ];

  protected analyticsResultObject: Record<string, any> = {
    [ANALYTICS_ATTRIBUTES.attribute1]: [''],
    [ANALYTICS_ATTRIBUTES.attribute3]: [''],
    [ANALYTICS_ATTRIBUTES.category]: [''],
    [ANALYTICS_ATTRIBUTES.subcategory]: [''],
    [ANALYTICS_ATTRIBUTES.location]: [''],
  };

  private action: TEALIUM_PROD_ACTION;
  private barcode: string;

  private BARCODE_PRODUCT_ACTIONS = {
    productFound: TEALIUM_PROD_ACTION.productFound,
    productListClick: TEALIUM_PROD_ACTION.productListClick,
    addToWishlist: TEALIUM_PROD_ACTION.addToWishlist,
    addToCartFromList: TEALIUM_PROD_ACTION.addToCartFromList,
  };

  public setBarcode(barcode: string): BarcodeAnalyticsComponentPropertyBuilder {
    this.barcode = barcode;
    return this;
  }

  public setAction(action: TEALIUM_PROD_ACTION): BarcodeAnalyticsComponentPropertyBuilder {
    this.action = action;
    return this;
  }

  private handleBarcodeAttribute(): void {
    const hasAttribute4 = this.attributes.find((attr) => attr === ANALYTICS_ATTRIBUTES.attribute4);
    if (this.barcode) {
      this.analyticsResultObject.attribute4 = [this.barcode];
      if (!hasAttribute4) {
        this.attributes = [...this.attributes, ANALYTICS_ATTRIBUTES.attribute4];
      }
    }

    if (!this.barcode
      && hasAttribute4) {
      this.attributes = this.attributes.filter((attr) => attr !== ANALYTICS_ATTRIBUTES.attribute4);
    }
  }

  public build(): any {
    this.handleBarcodeAttribute();
    if (this.BARCODE_PRODUCT_ACTIONS[this.action]) {
      this.analyticsResultObject.category = [CATEGORY_SET_VALUE];
      this.analyticsResultObject.attribute3 = [this.action];
      this.analyticsResultObject.location = [BARCODE_VALUE];
      this.analyticsResultObject.subcategory = [BARCODE_VALUE];
    } else {
      this.analyticsResultObject.location = [BARCODE_VALUE];
      this.analyticsResultObject.attribute3 = [this.action];
    }

    return {
      [ANALYTICS_ATTRIBUTES.component]: super.build() || {},
    };
  }
}
