import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';

class ProductVariantsMatrix {
  public static fetch(sku: string): RequestConfigType {
    return RequestConfig.get(
      `/products/${sku}/variants-matrix`,
    );
  }
}

export { ProductVariantsMatrix };
