import { call, CallEffect } from 'redux-saga/effects';
import { TEALIUM_INTERACTION_ID, TEALIUM_PROD_ACTION, TEALIUM_PROD_LIST } from '../../types/types';
import { analyzeProductsInteraction } from '../products/interactions';
import { InteractionProduct } from '../products/types';

export function* analyzeCartProductClickInteraction(product: InteractionProduct): Generator<CallEffect, void> {
  yield call(
    analyzeProductsInteraction,
    TEALIUM_INTERACTION_ID.productListClick,
    TEALIUM_PROD_ACTION.productListClick,
    TEALIUM_PROD_LIST.cart,
    { products: [product] },
  );
}

export function* analyzeCartProductAddInteraction(products: InteractionProduct[]): Generator<CallEffect, void> {
  yield call(
    analyzeProductsInteraction,
    TEALIUM_INTERACTION_ID.addToCart,
    TEALIUM_PROD_ACTION.addToCartFromCart,
    null,
    { products },
  );
}

export function* analyzeCartProductRemoveInteraction(products: InteractionProduct[]): Generator<CallEffect, void> {
  yield call(
    analyzeProductsInteraction,
    TEALIUM_INTERACTION_ID.removeFromCart,
    TEALIUM_PROD_ACTION.removeFromCart,
    null,
    { products },
  );
}
