import { RouteType } from '@client/routes/components/Router/routes';

export enum DASHBOARD_ROUTES_NAMES {
  myAccount = 'myAccount',
}

export enum WISHLIST_ROUTES_NAMES {
  myAccountWishlists = 'myAccountWishlists',
  myAccountWishlist = 'myAccountWishlist',
  wishlist = 'wishlist',
}

export enum USERS_ROUTES_NAMES {
  myAccountUsers = 'myAccountUsers',
  myAccountUserCreate = 'myAccountUserCreate',
  myAccountUserUpdate = 'myAccountUserUpdate',
}

export enum RMAS_ROUTES_NAMES {
  myAccountRmasSearch = 'myAccountRmasSearch',
  rmasSearch = 'rmasSearch',
  myAccountRmas = 'myAccountRmas',
  myAccountCreateRma = 'myAccountCreateRma',
  createRma = 'createRma',
  myAccountRma = 'myAccountRma',
  myAccountRmaConfirmation = 'myAccountRmaConfirmation',
  rmaConfirmation = 'rmaConfirmation',
  myAccountUpdateRma = 'myAccountUpdateRma',
}

export enum ORDERS_ROUTES_NAMES {
  myAccountOrders = 'myAccountOrders',
  myAccountOrder = 'myAccountOrder',
  myAccountDeliveredOrders = 'myAccountDeliveredOrders',
}

export enum FINANCE_ROUTES_NAMES {
  myAccountBalanceDetails = 'myAccountBalanceDetails',
  myAccountInvoices = 'myAccountInvoices',
  myAccountInvoice = 'myAccountInvoice',
  myAccountCredits = 'myAccountCredits',
}

export enum NEWSLETTER_ROUTES_NAMES {
  myAccountNewsletters = 'myAccountNewsletters',
  newsletterConfirmation = 'newsletterConfirmation',
  newsletterSignUpFail = 'newsletterSignUpFail',
  newsletterSignUpSuccess = 'newsletterSignUpSuccess',
}

export enum DETAILS_ROUTES_NAMES {
  myAccountDetails = 'myAccountDetails',
  myAccountBillingAddressUpdate = 'myAccountBillingAddressUpdate',
  myAccountContactInfoUpdate = 'myAccountContactInfoUpdate',
}

export enum ADDRESSES_ROUTES_NAMES {
  myAccountAddresses = 'myAccountAddresses',
  myAccountAddressCreate = 'myAccountAddressCreate',
  myAccountAddressUpdate = 'myAccountAddressUpdate',
}

export enum COST_CENTER_ROUTES_NAMES {
  myAccountCostCenters = 'myAccountCostCenters',
  myAccountCostCenter = 'myAccountCostCenter',
  myAccountCostCenterCreate = 'myAccountCostCenterCreate',
  myAccountCostCenterUpdate = 'myAccountCostCenterUpdate',
  myAccountCostCenterAddresses = 'myAccountCostCenterAddresses',
  myAccountCostCenterAddressCreate = 'myAccountCostCenterAddressCreate',
  myAccountCostCenterAddressEdit = 'myAccountCostCenterAddressEdit',
  myAccountCostCentersUsersCreate = 'myAccountCostCentersUsersCreate',
  myAccountCostCentersUsersUpdate = 'myAccountCostCentersUsersUpdate',
  myAccountCostCenterUsers = 'myAccountCostCenterUsers',
  myAccountCostCenterBudgets = 'myAccountCostCenterBudgets',
  myAccountCostCenterBudgetForm = 'myAccountCostCenterBudgetForm',
  myAccountCostCenterOrderRequestList = 'myAccountCostCenterOrderRequestList',
  myAccountCostCenterOrderRequest = 'myAccountCostCenterOrderRequest',
  myAccountCostCenterOrderRequestConfirmation = 'myAccountCostCenterOrderRequestConfirmation',
}


export type DashboardRouteType = {
  [DASHBOARD_ROUTES_NAMES.myAccount]: RouteType;
};

export type FinanceRouteType = {
  [FINANCE_ROUTES_NAMES.myAccountBalanceDetails]: RouteType;
  [FINANCE_ROUTES_NAMES.myAccountInvoices]: RouteType;
  [FINANCE_ROUTES_NAMES.myAccountInvoice]: RouteType;
  [FINANCE_ROUTES_NAMES.myAccountCredits]: RouteType;
};

export type OrdersRouteType = {
  [ORDERS_ROUTES_NAMES.myAccountOrders]: RouteType;
  [ORDERS_ROUTES_NAMES.myAccountOrder]: RouteType;
  [ORDERS_ROUTES_NAMES.myAccountDeliveredOrders]: RouteType;
};

export type DetailsRouteType = {
  [DETAILS_ROUTES_NAMES.myAccountDetails]: RouteType;
  [DETAILS_ROUTES_NAMES.myAccountBillingAddressUpdate]: RouteType;
  [DETAILS_ROUTES_NAMES.myAccountContactInfoUpdate]: RouteType;
};

export type AddressesRouteType = {
  [ADDRESSES_ROUTES_NAMES.myAccountAddresses]: RouteType;
  [ADDRESSES_ROUTES_NAMES.myAccountAddressCreate]: RouteType;
  [ADDRESSES_ROUTES_NAMES.myAccountAddressUpdate]: RouteType;
};

export type UsersRouteType = {
  [USERS_ROUTES_NAMES.myAccountUsers]: RouteType;
  [USERS_ROUTES_NAMES.myAccountUserCreate]: RouteType;
  [USERS_ROUTES_NAMES.myAccountUserUpdate]: RouteType;
};

export type WishlistRouteType = {
  [WISHLIST_ROUTES_NAMES.myAccountWishlists]: RouteType;
  [WISHLIST_ROUTES_NAMES.myAccountWishlist]: RouteType;
  [WISHLIST_ROUTES_NAMES.wishlist]: RouteType;
};

export type NewsletterRouteType = {
  [NEWSLETTER_ROUTES_NAMES.myAccountNewsletters]: RouteType;
  [NEWSLETTER_ROUTES_NAMES.newsletterConfirmation]: RouteType;
  [NEWSLETTER_ROUTES_NAMES.newsletterSignUpFail]: RouteType;
  [NEWSLETTER_ROUTES_NAMES.newsletterSignUpSuccess]: RouteType;
};

export type RmasRouteType = {
  [RMAS_ROUTES_NAMES.myAccountRmasSearch]: RouteType;
  [RMAS_ROUTES_NAMES.rmasSearch]: RouteType;
  [RMAS_ROUTES_NAMES.myAccountRmas]: RouteType;
  [RMAS_ROUTES_NAMES.myAccountCreateRma]: RouteType;
  [RMAS_ROUTES_NAMES.createRma]: RouteType;
  [RMAS_ROUTES_NAMES.myAccountRma]: RouteType;
  [RMAS_ROUTES_NAMES.myAccountRmaConfirmation]: RouteType;
  [RMAS_ROUTES_NAMES.rmaConfirmation]: RouteType;
  [RMAS_ROUTES_NAMES.myAccountUpdateRma]: RouteType;
};

export type CostCenterRouteType = {
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenters]: RouteType;
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenter]: RouteType;
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenterCreate]: RouteType;
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenterUpdate]: RouteType;
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenterAddresses]: RouteType;
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenterAddressCreate]: RouteType;
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenterAddressEdit]: RouteType;
  [COST_CENTER_ROUTES_NAMES.myAccountCostCentersUsersCreate]: RouteType;
  [COST_CENTER_ROUTES_NAMES.myAccountCostCentersUsersUpdate]: RouteType;
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenterUsers]: RouteType;
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenterBudgets]: RouteType;
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenterBudgetForm]: RouteType;
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenterOrderRequestList]: RouteType;
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenterOrderRequest]: RouteType;
  [COST_CENTER_ROUTES_NAMES.myAccountCostCenterOrderRequestConfirmation]: RouteType;
};

export type AccountRouteType =
  DashboardRouteType &
  FinanceRouteType &
  OrdersRouteType &
  DetailsRouteType &
  AddressesRouteType &
  UsersRouteType &
  WishlistRouteType &
  NewsletterRouteType &
  RmasRouteType &
  CostCenterRouteType;

export const ACCOUNT_ROUTES_NAMES = {
  ...COST_CENTER_ROUTES_NAMES,
  ...DASHBOARD_ROUTES_NAMES,
  ...DETAILS_ROUTES_NAMES,
  ...FINANCE_ROUTES_NAMES,
  ...NEWSLETTER_ROUTES_NAMES,
  ...ORDERS_ROUTES_NAMES,
  ...RMAS_ROUTES_NAMES,
  ...USERS_ROUTES_NAMES,
  ...WISHLIST_ROUTES_NAMES,
  ...ADDRESSES_ROUTES_NAMES,
};
