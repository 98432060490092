import { isObject } from '@client/common/utils/object/object';
import { isString } from '@client/common/utils/string/string';

export const isEmpty = (value: any): boolean => {
  if (typeof value === 'boolean' || typeof value === 'number') {
    return false;
  }

  if (typeof value === 'undefined' || value === null) {
    return true;
  }

  if (typeof value.length !== 'undefined') {
    return value.length === 0;
  }

  for (const key in value) {
    // eslint-disable-next-line no-prototype-builtins
    if (value.hasOwnProperty(key)) {
      return false;
    }
  }

  return true;
};

export const getEmpty = (value: any): any => {
  if (isEmpty(value)) {
    return value;
  }

  if (Array.isArray(value)) {
    return [];
  }

  if (isObject(value)) {
    return {};
  }

  if (isString(value)) {
    return '';
  }

  return null;
};
