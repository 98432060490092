import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData, RemoteRecordDataType } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductGalleryType } from 'b2b-common/core/product/api/ProductGallery.types';

const initialState: { [sku: string]: RemoteRecordDataType<ProductGalleryType> } = {};

const NAME = 'productGallery';

export const productGallerySlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState,
  reducers: {
    fetchProductGallery: (state, action: PayloadAction<{ sku: string }>) => {
      state[action.payload.sku] = RemoteRecordData.init();
      return state;
    },
    fetchProductGalleryFulfilled: (state, action: PayloadAction<{ sku: string, data: ProductGalleryType }>) => {
      state[action.payload.sku] = RemoteRecordData.fulfill(action.payload.data);
      return state;
    },
    fetchProductGalleryRejected: (state, action: PayloadAction<{ sku: string, error: ErrorType }>) => {
      state[action.payload.sku] = RemoteRecordData.reject(action.payload.error);
      return state;
    },
  },
});

export const {
  fetchProductGallery,
  fetchProductGalleryFulfilled,
  fetchProductGalleryRejected,
} = productGallerySlice.actions;

const getState = (state: AppState) => state.product.productGallery;

export const selectProductGalleryBySku = createSelector(
  [getState, (state, sku: string) => sku],
  (state, sku) => new RemoteRecord<ProductGalleryType>(state[sku]),
);
