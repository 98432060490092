import { StatusCodes } from 'http-status-codes';
import {
  REMOTE_RECORD_ACTION_DONE,
  REMOTE_RECORD_ACTION_FAIL,
  REMOTE_RECORD_ACTION_INIT,
  RemoteRecordData,
  RemoteRecordDataType,
} from './RemoteRecordData';

export class RemoteRecord<T> {
  protected record: RemoteRecordDataType<T>;

  /**
   * @param data
   */
  public constructor(data: RemoteRecordDataType<T> | typeof undefined) {
    this.record = Object.assign(
      RemoteRecordData.getDefault(),
      data || {},
    );
  }

  /**
   * Was fetching initialized?
   *
   * @returns {boolean}
   */
  public get wasStarted(): boolean {
    return [
      REMOTE_RECORD_ACTION_INIT,
      REMOTE_RECORD_ACTION_DONE,
      REMOTE_RECORD_ACTION_FAIL,
    ].includes(this.record.status as string);
  }

  /**
   * Awaits fetching?
   *
   * @returns {boolean}
   */
  public get isLoading(): boolean {
    return (
      this.record.status === null
      || this.record.status === REMOTE_RECORD_ACTION_INIT
    );
  }

  /**
   * INITialized, but neither DONE, nor FAILED
   *
   * @returns {boolean}
   */
  public get isInitialized(): boolean {
    return this.record.status === REMOTE_RECORD_ACTION_INIT;
  }

  /**
   * Has fetching succeed?
   *
   * @returns {boolean}
   */
  public get isLoaded(): boolean {
    return this.record.status === REMOTE_RECORD_ACTION_DONE;
  }

  /**
   * Has fetching failed due to HTTP error?
   *
   * @returns {boolean}
   */
  public get hasFailed(): boolean {
    return this.record.status === REMOTE_RECORD_ACTION_FAIL;
  }

  /**
   * Has fetching failed with a specific HTTP code?
   *
   * @returns {boolean}
   */
  public hasFailedWith(statusCode: number): boolean {
    if (this.hasFailed && this.record.error) {
      const requestStatusCode = parseInt(`${this.record.error.statusCode}`, 10);

      return statusCode === requestStatusCode;
    }

    return false;
  }

  /**
   * Has request failed with 503 or 504 error
   *
   * @returns {boolean}
   */
  public get hasServerError(): boolean {
    return this.hasFailedWith(StatusCodes.SERVICE_UNAVAILABLE)
      || this.hasFailedWith(StatusCodes.GATEWAY_TIMEOUT);
  }

  /**
   * Request's status
   *
   * @returns {string|null}
   */
  public get status(): string | null {
    return this.record.status;
  }

  /**
   * Request's identifier
   *
   * @returns {string|null}
   */
  public get identifier(): string | null {
    return this.record.identifier;
  }

  /**
   * Response
   * @template T
   * @returns {T | null}
   */
  public get data(): T | null {
    return this.record.data;
  }

  /**
   * Error object
   *
   * @returns {ErrorType | null}
   */
  public get error(): ErrorType | null {
    return this.record.error;
  }
}
