import { accountDetailsReducer } from '@client/myAccount/accountDetails/redux/redux';
import { addressesReducer } from '@client/myAccount/addresses/redux/redux';
import { balanceDetailsSlice } from '@client/myAccount/balanceDetails/redux/redux';
import { costCenterReducer } from '@client/myAccount/costCenters/redux/reducer';
import { creditsSlice } from '@client/myAccount/credits/redux/redux';
import { deliveredOrdersReducer } from '@client/myAccount/deliveredOrders/redux/redux';
import { invoicesReducer } from '@client/myAccount/invoices/redux/redux';
import { newslettersSlice } from '@client/myAccount/newsletters/redux/redux';
import { ordersReducer } from '@client/myAccount/orders/redux/redux';
import { pdfSlice } from '@client/myAccount/pdf/redux/redux';
import { rmaReducer } from '@client/myAccount/rma/redux/redux';
import { usersReducer } from '@client/myAccount/users/redux/reducer';
import { wishlistsReducer } from '@client/myAccount/wishlists/redux/redux';
import { combineSlices } from '@reduxjs/toolkit';

export const myAccountReducer = combineSlices(
  {
    addresses: addressesReducer,
    accountDetails: accountDetailsReducer,
    invoices: invoicesReducer,
    orders: ordersReducer,
    deliveredOrders: deliveredOrdersReducer,
    rma: rmaReducer,
    users: usersReducer,
    wishlists: wishlistsReducer,
    costCenter: costCenterReducer,
  },
  creditsSlice,
  newslettersSlice,
  pdfSlice,
  balanceDetailsSlice,
);
