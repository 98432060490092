import { FC, ReactElement } from 'react';
import { createKey } from '@client/common/utils/key/key';
import { ModulesType } from '@client/content/components/ModularPage';
import { NewsroomAssets, NewsroomFooter, NewsroomHeader, NewsroomNews } from '@client/content/components/ModularPage/Modules';


const NEWSROOM_HEADER_COMPONENT: Record<string, FC<any>> = {
  newsroomHeader: NewsroomHeader,
};

const NEWSROOM_FOOTER_COMPONENT: Record<string, FC<any>> = {
  newsroomFooter: NewsroomFooter,
};

const NEWSROOM_MAIN_COMPONENT: Record<string, FC<any>> = {
  downloadCenter: NewsroomAssets,
  newsroomCenter: NewsroomNews,
};

export const getNewsroomComponents = (
  componentsData: ModulesType,
): ReactElement[] => {
  const getComponents = (componentData: ModulesType): ReactElement[] => {
    const components: ReactElement[] = [];

    componentData && componentData.forEach(
      (module) => {
        const { componentName, componentData } = module;

        const Component = (
          NEWSROOM_MAIN_COMPONENT[componentName]
          || NEWSROOM_HEADER_COMPONENT[componentName]
          || NEWSROOM_FOOTER_COMPONENT[componentName]
        );

        Component && components.push(
          <Component
            componentData={componentData}
            key={createKey('module', module.componentName, components.length)}
          />,
        );
      },
    );

    return components;
  };

  return getComponents(componentsData);
};
