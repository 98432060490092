import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Newsletter } from '@client/newsletter/api/Newsletter';
import {
  subscribeNewsletter,
  subscribeNewsletterFulfilled,
  subscribeNewsletterRejected,
} from '@client/newsletter/redux/redux';
import { ROUTES } from '@client/routes/components/Router/routes';
import { pushToRoute } from '@client/routes/redux/utils';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { NewsletterType } from 'b2b-common/core/newsletter/api/types';

function* subscribeNewsletterSaga(
  action: ReturnType<typeof subscribeNewsletter>,
): Generator<
  CallEffect | PutEffect | Generator,
  void,
  boolean & AxiosResponse<NewsletterType>
> {
  const { email, language } = action.payload;

  try {
    const response = yield call(
      requestApiSaga,
      Newsletter.subscribe(email, language),
    );

    yield put(subscribeNewsletterFulfilled(response.data));
    yield pushToRoute(ROUTES.newsletterConfirmation, {});
  } catch (error) {
    yield put(subscribeNewsletterRejected(parseError(error)));
    yield put(reportError({ message: action.type, error }));
    yield pushToRoute(ROUTES.newsletterSignUpFail, {});
  }
}

export function* newsletterSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeEvery(subscribeNewsletter.type, subscribeNewsletterSaga),
  ]);
}
