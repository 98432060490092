import { contentCollectionsSaga } from '@client/content/redux/contentCollections/sagas';
import { contentCollectionsEntriesSaga } from '@client/content/redux/contentCollectionsEntries/sagas';
import { contentEntriesSaga } from '@client/content/redux/contentEntries/sagas';
import { entriesMapSaga } from '@client/content/redux/entriesMap/sagas';
import { featureOnboardingSaga } from '@client/content/redux/featureOnboarding/sagas';
import { formsSaga } from '@client/content/redux/forms/sagas';
import { homepageSaga } from '@client/content/redux/homepage/sagas';
import { infobarsSaga } from '@client/content/redux/infobars/sagas';
import { newsroomSaga } from '@client/content/redux/newsroom/sagas';
import { all, fork } from 'redux-saga/effects';

export function* contentSaga(): Generator<any, any, any> {
  yield all([
    fork(contentCollectionsSaga),
    fork(contentCollectionsEntriesSaga),
    fork(contentEntriesSaga),
    fork(entriesMapSaga),
    fork(formsSaga),
    fork(newsroomSaga),
    fork(homepageSaga),
    fork(infobarsSaga),
    fork(featureOnboardingSaga),
  ]);
}
