import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { getCookie, removeCookie, setCookie } from '@client/common/utils/storage/cookies';
import { ProductsCompare } from '@client/product/api/ProductsCompare';
import {
  addProductToCompare,
  clearProductsCompare,
  fetchProductsCompare,
  fetchProductsCompareFulfilled,
  fetchProductsCompareRejected,
  removeProductFromCompare,
} from '@client/product/redux/productsCompare/redux';
import { clearProductsCompareSettings, updateProductsCompareSettings } from '@client/settings/redux/redux';
import { SettingsProductsCompareType } from '@client/settings/redux/types';
import { AxiosResponse } from 'axios';
import {
  all,
  AllEffect,
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  SelectEffect,
  takeEvery,
} from 'redux-saga/effects';
import {
  MAX_NUMBER_OF_PRODUCTS_TO_COMPARE,
  ProductsCompare as ProductsCompareType,
} from 'b2b-common/core/product/api/ProductsCompare.types';
import { COMPARE_PRODUCTS_COOKIE_NAME } from './types';

function* fetchProductsCompareSaga(
  action: ReturnType<typeof fetchProductsCompare>,
): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<ProductsCompareType>
  > {
  const { skus } = action.payload;

  try {
    const response: AxiosResponse<ProductsCompareType> = yield call(
      requestApiSaga, ProductsCompare.fetch(skus),
    );
    yield put(fetchProductsCompareFulfilled({ data: ProductsCompare.mapResponseToState(response) }));
  } catch (error) {
    yield put(fetchProductsCompareRejected({ error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* addProductToCompareSaga(action: ReturnType<typeof addProductToCompare>): Generator<
  SelectEffect | CallEffect | PutEffect,
  void,
  SettingsProductsCompareType
  & AxiosResponse<SettingsProductsCompareType>
  > {
  const { product } = action.payload;
  const cookie = getCookie(COMPARE_PRODUCTS_COOKIE_NAME) as SettingsProductsCompareType;
  const products = cookie?.products
    ? [...cookie.products?.filter(p => p.sku !== product.sku), product]
    : [product];

  if (products.length > MAX_NUMBER_OF_PRODUCTS_TO_COMPARE) {
    return;
  }

  const settings = { isClosed: false, products };

  yield put(updateProductsCompareSettings(settings));
  setCookie(COMPARE_PRODUCTS_COOKIE_NAME, settings);
}

function* removeProductFromCompareSaga(action: ReturnType<typeof removeProductFromCompare>): Generator<
  SelectEffect | CallEffect | PutEffect,
  void,
  SettingsProductsCompareType & AxiosResponse<SettingsProductsCompareType>
  > {
  const cookie = getCookie(COMPARE_PRODUCTS_COOKIE_NAME) as SettingsProductsCompareType;

  if (!cookie || !cookie.products?.length) {
    return;
  }

  const { sku } = action.payload;
  const products = cookie.products.filter(product => product.sku !== sku);
  const settings = { ...cookie, products };

  yield put(updateProductsCompareSettings(settings));
  setCookie(COMPARE_PRODUCTS_COOKIE_NAME, settings);
}

function* clearProductsCompareSaga(action: ReturnType<typeof clearProductsCompare>): Generator<
  SelectEffect | CallEffect | PutEffect,
  void,
  SettingsProductsCompareType & AxiosResponse<SettingsProductsCompareType>
  > {
  removeCookie(COMPARE_PRODUCTS_COOKIE_NAME);
  yield put(fetchProductsCompare({ skus: [] }));
  yield put(clearProductsCompareSettings());
}

export function* productsCompareSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeEvery(fetchProductsCompare.type, fetchProductsCompareSaga),
    takeEvery(addProductToCompare.type, addProductToCompareSaga),
    takeEvery(removeProductFromCompare.type, removeProductFromCompareSaga),
    takeEvery(clearProductsCompare.type, clearProductsCompareSaga),
  ]);
}
