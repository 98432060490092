import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Forms } from '@client/content/api/Forms';
import {
  fetchForm,
  fetchFormFulfilled,
  fetchFormRejected,
  submitForm,
  submitFormFulfilled,
  submitFormRejected,
} from '@client/content/redux/forms/redux';
import { AxiosResponse } from 'axios';
import { all, call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { FormElementType } from 'b2b-common/core/content/Content.types';

function* fetchFormsSaga(action: ReturnType<typeof fetchForm>): Generator<CallEffect | PutEffect | Generator,
  void,
   AxiosResponse<FormElementType[]>> {
  const { formId } = action.payload;

  try {
    const response = yield call(requestApiSaga, Forms.fetch(formId));
    yield put(
      fetchFormFulfilled({ formId, data: response.data }),
    );
  } catch (error) {
    yield put(fetchFormRejected({ formId, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* submitFormSaga(action: ReturnType<typeof submitForm>): Generator<any, any, any> {
  const { data, formId } = action.payload;

  try {
    yield call(
      requestApiSaga,
      Forms.submit(formId, Forms.mapFormDataToRequestBody(data)),
    );
    yield put(submitFormFulfilled({ formId }));
  } catch (error) {
    yield put(
      submitFormRejected({ formId, error: Forms.mapResponseToErrorsState(error) }),
    );
    yield put(reportError({ message: action.type, error: formId }));
  }
}

export function* formsSaga(): Generator<any, any, any> {
  yield all([
    takeLatest(fetchForm.type, fetchFormsSaga),
    takeLatest(submitForm.type, submitFormSaga),
  ]);
}
