import { MouseEvent, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { createKey } from '@client/common/utils/key/key';
import { config } from '@client/config';
import { useEntry } from '@client/content/hooks';
import { useTranslate } from '@client/i18n/hooks';
import { useRouterPush } from '@client/routes/hooks';
import { getAbsoluteUrl, isExternalUrl, openLink } from '@client/routes/utils/url';
import { useStickyFooter } from '@lib/context/StickyFooterContext';
import classNames from 'classnames';
import { HydratedContent } from 'b2b-components/core/components/HydratedContent';
import { Footer as FooterComponent } from 'b2b-components/theme/components/Footer';
import { LogoSliderItem, Props, SectionType } from './Footer.types';
import { NewsletterForm } from './NewsletterForm';
import './Footer.scss';

export const Footer = ({ hasNewsletterDisplayed = false }: Props): ReactElement => {
  const t = useTranslate();
  const content = useEntry(config.contentStackIds.footer);
  const footerLogos = useEntry(config.contentStackIds.logoSlider);
  const push = useRouterPush();
  const location = useLocation();
  const { isStickyFooterEnabled } = useStickyFooter();
  const { contact, footer_bar: footerBar, sections } = content?.data || {};

  const handleLinkClick = (url: string): OnAnchorClickType => (e: MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    openLink(url, push, e);
  };

  const barSocialData = {
    facebook: footerBar?.facebook ? footerBar.facebook.href || null : null,
    instagram: footerBar?.instagram ? footerBar.instagram.href || null : null,
    linkedin: footerBar?.linkedin ? footerBar.linkedin.href || null : null,
    twitter: footerBar?.twitter ? footerBar.twitter.href || null : null,
    youtube: footerBar?.youtube ? footerBar.youtube.href || null : null,
  };

  const barLinks = (
    <ul className='cds-List cds-List--Unstyled cds-List--Inline'>
      {footerBar?.link.map((link: LinkType, index: number) => (
        <li key={createKey(link.title, index)}>
          <a
            className='cds-Footer-Link'
            href={link.href}
            onClick={handleLinkClick(link.href)}
          >
            {link.title}
          </a>
        </li>
      ))}
    </ul>
  );

  const renderNewsletterForm = (): ReactElement => (
    <HydratedContent>
      <NewsletterForm key={`${location.pathname}${location.search}`} />
    </HydratedContent>
  );

  const contactData = contact && {
    address: contact.address.split('\n').map((line: string) => <div key={line}>{line}</div>),
    email: (
      <a href={`mailto:${contact.email}`} className='cds-Footer-Link'>
        {t(
          'E-mail {email}',
          { email: contact.email },
          'Email address in footer',
        )}
      </a>
    ),
    label: contact.label,
    phoneNumber: (
      <a href={`tel:${contact.phone}`} className='cds-Footer-Link'>
        {t(
          'Phone {number}',
          { number: contact.phone },
          'Phone number in footer',
        )}
      </a>
    ),
  };

  const logoSliderData = {
    title: footerLogos?.data?.module__logo_slider?.title || '',
    items: footerLogos?.data?.module__logo_slider?.group?.reduce?.(
      (result: LogoSliderItem[], item: Record<string, any>): LogoSliderItem[] => item.logo_image
        ? [...result, { url: item.url, brandName: item.brand_name, src: item.logo_image.url }]
        : result,
      [],
    ) || [],
  };

  const footerSections = sections?.map((section: SectionType) => ({
    label: section.label,
    content: (
        <ul className='cds-FooterSection-List'>
          {section.link.map(link => ({
            ...link,
            href: link.href.replace(' ', ''),
          })).map((link) => (
            <li className='cds-FooterSection-ListItem' key={link.title}>
              <a
                className='cds-Footer-Link'
                href={link.href}
                onClick={handleLinkClick(link.href)}
              >
                {link.title}
              </a>
            </li>
          ))}
        </ul>
    ),
  })) || [];


  const logos = {
    title: logoSliderData.title,
    items: logoSliderData.items.reduce(
      (result: ReactElement[], logo: LogoSliderItem): ReactElement[] => {
        result.push(
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          href={isExternalUrl(logo.url) ? logo.url : getAbsoluteUrl(logo.url)}
          onClick={() => handleLinkClick(logo.url)}
          rel={isExternalUrl(logo.url) ? 'noopener noreferrer' : undefined}
          target={isExternalUrl(logo.url) ? '_blank' : undefined}
        >
          <img
            alt={logo.brandName}
            src={logo.src}
          />
        </a>,
        );

        return result;
      },
      [],
    ),
  };

  return (
    <footer className={classNames('Footer', { 'Footer-Sticky': isStickyFooterEnabled })}>
      <FooterComponent
        bar={{ social: barSocialData, links: barLinks }}
        hasNewsletterDisplayed={hasNewsletterDisplayed}
        renderNewsletterForm={renderNewsletterForm}
        contact={contactData}
        sections={footerSections}
        logos={logos}
        text={{
          copyright:
            '\xA9 '
            + t('{year} {shopName} - All rights reserved.', {
              year: new Date().getFullYear(),
              shopName: config.shopName,
            }),
          newsletterTitle: t('Email Newsletter!'),
          newsletterText: t(
            'Subscribe now and receive weekly emails with exciting offers, latest news and updates.',
          ),
        }}
      />
    </footer>
  );
};
