import { matchPath, useLocation } from 'react-router-dom';
import { useDeviceType } from '@client/common/hooks';
import { isPhone, isServer } from '@client/common/utils/env';
import { useActiveLanguage } from '@client/i18n/hooks';
import { ROUTES } from '@client/routes/components/Router/routes';

const DISABLED_PATHS = [
  ROUTES.checkout,
  ROUTES.checkoutConfirmation,
  ROUTES.checkoutFail,
  ROUTES.productsCompare,
  ROUTES.myAccount,
  ROUTES.myAccountBalanceDetails,
  ROUTES.myAccountInvoices,
  ROUTES.myAccountInvoice,
  ROUTES.myAccountCredits,
  ROUTES.myAccountOrders,
  ROUTES.myAccountOrder,
  ROUTES.myAccountDeliveredOrders,
  ROUTES.myAccountDetails,
  ROUTES.myAccountBillingAddressUpdate,
  ROUTES.myAccountContactInfoUpdate,
  ROUTES.myAccountAddresses,
  ROUTES.myAccountAddressCreate,
  ROUTES.myAccountAddressUpdate,
  ROUTES.myAccountUsers,
  ROUTES.myAccountUserCreate,
  ROUTES.myAccountUserUpdate,
  ROUTES.myAccountWishlists,
  ROUTES.myAccountWishlist,
  ROUTES.wishlist,
  ROUTES.myAccountRmasSearch,
  ROUTES.rmasSearch,
  ROUTES.myAccountRmas,
  ROUTES.myAccountCreateRma,
  ROUTES.createRma,
  ROUTES.myAccountRma,
  ROUTES.myAccountRmaConfirmation,
  ROUTES.rmaConfirmation,
  ROUTES.myAccountUpdateRma,
  ROUTES.myAccountNewsletters,
  ROUTES.myAccountCostCenters,
  ROUTES.myAccountCostCenter,
  ROUTES.myAccountCostCenterCreate,
];

export const useIsActive = (): boolean => {
  const location = useLocation();
  const deviceType = useDeviceType();
  const isDevicePhone = isPhone(deviceType);
  const language = useActiveLanguage();

  if (isServer || isDevicePhone) {
    return false;
  }

  const isDisabledPath = DISABLED_PATHS
    .find(path => matchPath(
      location.pathname,
      { path: path.paths[language], exact: false, strict: false },
    ));

  return !isDisabledPath;
};
