import {
  ADDRESS_TYPE,
  BillingAddressRequestType,
  BillingAddressType,
  CheckoutAddressType,
  DROP_SHIPPING_OPTION,
  PAYMENT_TYPE,
  SALUTATION,
  SHIPPING_METHOD,
  ShippingAddressRequestType,
  UpdateCheckoutRequestBodyType,
} from '../Checkout.types';

export const hasCustomerReference = (
  dropShippingOption: DROP_SHIPPING_OPTION | any,
): boolean => dropShippingOption === DROP_SHIPPING_OPTION.anonymous;

export const isAddressCorrect = (address: CheckoutAddressType | Record<string, any>): boolean => !!(
  (address.name || (address.firstName && address.lastName))
  && address.street
  && address.postCode
  && address.city
);

export const areAddressesEqual = (a: Record<string, any>, b: Record<string, any>): boolean => (a.street || '') === (b.street || '')
  && (a.streetAddition || '') === (b.streetAddition || '')
  && (a.contactPerson || '') === (b.contactPerson || '')
  && (a.name || '') === (b.name || '')
  && (a.city || '') === (b.city || '')
  && (a.postCode || '') === (b.postCode || '');

export const getAddressType = (address: CheckoutAddressType, addresses: Record<string, any>): ADDRESS_TYPE => {
  switch (true) {
    case !isAddressCorrect(addresses.preferred):
      return ADDRESS_TYPE.new;

    case areAddressesEqual(address, addresses.preferred):
      return ADDRESS_TYPE.preferred;

    case areAddressesEqual(address, addresses.billing):
      return ADDRESS_TYPE.billing;

    case Array.isArray(addresses.saved)
    && addresses.saved.some((adr) => areAddressesEqual(address, adr)):
      return ADDRESS_TYPE.saved;

    default:
      return ADDRESS_TYPE.new;
  }
};

export const comparePaymentMethods = (
  firstMethod: {
    order: number;
    type: string;
  },
  secondMethod: {
    order: number;
    type: string;
  },
): number => {
  const priorityMethods = [
    PAYMENT_TYPE.bill as string,
    PAYMENT_TYPE.masterCard as string,
    PAYMENT_TYPE.visa as string,
    PAYMENT_TYPE.postFinanceCard as string,
    PAYMENT_TYPE.postFinanceEFinance as string,
    PAYMENT_TYPE.cash as string,
    PAYMENT_TYPE.cashOnDelivery as string,
    PAYMENT_TYPE.prepayment as string,
  ];

  const firstMethodType = firstMethod.type;
  const secondMethodType = secondMethod.type;
  const haveFirstMethodPriority = priorityMethods.includes(firstMethodType);
  const haveSecondMethodPriority = priorityMethods.includes(secondMethodType);

  switch (true) {
    case haveFirstMethodPriority && haveSecondMethodPriority:
      return (
        priorityMethods.indexOf(firstMethodType)
        - priorityMethods.indexOf(secondMethodType)
      );

    case haveFirstMethodPriority:
      return -1;

    case haveSecondMethodPriority:
      return 1;

    default:
      return firstMethod.order - secondMethod.order;
  }
};

export const isAddresseePrivate = (
  { salutation }: CheckoutAddressType,
): boolean => [SALUTATION.male, SALUTATION.female].includes(salutation as SALUTATION);

export const mapBillingToRequest = (address: CheckoutAddressType): BillingAddressRequestType => {
  const isPrivate = isAddresseePrivate(address);
  const billingAddress: BillingAddressType = isPrivate
    ? {
      company: null,
      contactPerson: null,
      firstname: address.firstName || null,
      lastname: address.lastName || null,
      salutation: address.salutation || null,
    }
    : {
      company: address.name,
      contactPerson: address.contactPerson,
      firstname: null,
      lastname: null,
      salutation: address.salutation || null,
    };

  return {
    ...billingAddress,
    city: address.city,
    street: address.street,
    streetaddition: address.streetAddition,
    zip: address.postCode,
  };
};

export const mapShippingToRequest = (address: CheckoutAddressType): ShippingAddressRequestType => ({
  city: address.city,
  contactPerson: address.contactPerson,
  displayName: address.name,
  street: address.street,
  streetaddition: address.streetAddition,
  zip: address.postCode,
});

export const isShippingMethodPickup = (shippingMethod: SHIPPING_METHOD): boolean => {
  const pickupShippingMethods = [
    SHIPPING_METHOD.pickup,
    SHIPPING_METHOD.pickupStaff,
    SHIPPING_METHOD.pickupWillisau,
    SHIPPING_METHOD.pickupRenens,
  ];
  return pickupShippingMethods.includes(shippingMethod);
};

export const isDatatransPaymentType = (paymentType: PAYMENT_TYPE): boolean => [
  PAYMENT_TYPE.cash,
  PAYMENT_TYPE.americanExpress,
  PAYMENT_TYPE.masterCard,
  PAYMENT_TYPE.visa,
  PAYMENT_TYPE.twint,
  PAYMENT_TYPE.postFinanceCard,
  PAYMENT_TYPE.postFinanceEFinance,
].includes(paymentType);

export const prepareRequestData = (requestData: UpdateCheckoutRequestBodyType): UpdateCheckoutRequestBodyType => {
  isShippingMethodPickup(requestData.shipping.type)
  && delete requestData.shippingAddress;
  return requestData;
};
