import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { Action, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

const NAME = 'sendEmail';

export const sendEmailSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: RemoteRecordData.getDefault(),
  reducers: {
    sendInvoiceToEmail: (state, action: PayloadAction<{
      id: string;
      email: string;
    }>) => RemoteRecordData.init(),
    sendInvoiceToEmailFulfilled: (state, action: Action) => RemoteRecordData.fulfill({}),
    sendInvoiceToEmailRejected: (state, action: PayloadAction<ErrorType>) => RemoteRecordData.reject(action.payload),
    clearSendInvoiceToEmail: () => RemoteRecordData.getDefault(),
  },
});

export const {
  sendInvoiceToEmail,
  sendInvoiceToEmailFulfilled,
  sendInvoiceToEmailRejected,
  clearSendInvoiceToEmail,
} = sendEmailSlice.actions;

const getEmailState = (state: AppState) => state.myAccount.invoices.sendEmail;

export const selectSendEmail = createSelector(
  getEmailState,
  state => new RemoteRecord(state),
);
