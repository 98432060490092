import { InteractionProduct } from '@client/analytics/redux';
import { ProductAccessoryType } from '@client/product/redux/productAccessories/types';
import { ProductFullType } from '@client/product/redux/products/full/types';
import { DeliveredOrderProductType } from 'b2b-common/core/account/api/DeliveredOrders.types';
import { WishlistItemType } from 'b2b-common/core/account/api/wishlists';
import { CartProductType } from 'b2b-common/core/cart/api/CartTypes';
import { ComparedProductDetails } from 'b2b-common/core/product/api/ProductsCompare.types';
import { ProductTileType } from 'b2b-common/core/product/api/ProductTile.types';

export const getInteractionProduct = ({
  sku,
  effectivePricing,
  settings,
  analytics,
}: ProductFullType | ProductTileType | ProductAccessoryType): InteractionProduct => ({
  sku: sku,
  price: effectivePricing.userPriceWithoutVat,
  isPurchasable: settings.isBuyable && !settings.isDisabledByCoreAssortment,
  categories: analytics.categories,
  stock: settings.quantity,
});

export const getInteractionProductFromDeliveredOrderItem = ({
  sku,
  settings,
  category,
  priceValueWithoutVat,
}: DeliveredOrderProductType): InteractionProduct => ({
  sku: sku,
  price: priceValueWithoutVat,
  isPurchasable: settings.isBuyable && !settings.isDisabledByCoreAssortment,
  categories: [category.label],
  stock: settings.quantity,
});

export const getInteractionProductFromCartItem = ({
  sku,
  single,
  settings,
  analytics,
}: CartProductType | WishlistItemType): InteractionProduct => ({
  sku: sku,
  price: single.priceWithoutVat,
  isPurchasable: settings.isBuyable && !settings.isDisabledByCoreAssortment,
  categories: analytics.categories,
  stock: settings.quantity,
});

export const getInteractionProductFromComparison = ({
  sku,
  pricing,
  settings,
  analytics,
}: ComparedProductDetails): InteractionProduct => ({
  sku: sku,
  price: pricing.userPriceWithoutVat,
  isPurchasable: settings.isBuyable && !settings.isDisabledByCoreAssortment,
  categories: analytics.categories,
  stock: settings.quantity,
});
