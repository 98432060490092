import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { RequestNewslettersType } from 'b2b-common/core/account/api/Newsletters.types';

export class Newsletters {
  public static fetch(): RequestConfigType {
    return RequestConfig.get('/account/newsletters', { withLocalization: false });
  }

  public static update(data: RequestNewslettersType): RequestConfigType {
    return RequestConfig.put('/account/newsletters', data, { withLocalization: false });
  }
}
