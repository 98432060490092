import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { RequestBackorderSubscribeType } from 'b2b-common/core/account/api/orders';

export class Backorder {
  public static subscribe(email: string, isSubscribed: boolean, subscriptionType: string): RequestConfigType {
    const body: RequestBackorderSubscribeType = {
      email,
      subscribed: isSubscribed,
      subscriptionType,
    };

    return RequestConfig.put('/account/orders/backorders-subscription', body);
  }
}
