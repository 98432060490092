import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CostCenterUserData,
  CostCenterUsersType,
  CostCenterUserType,
} from 'b2b-common/core/account/api/costCenters/CostCenterUsers.types';

const NAME = 'user';

export const usersSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {
    users: RemoteRecordData.getDefault(),
  },
  reducers: {
    fetchCostCenterUser: (state, action: PayloadAction<{
      costCenterId: string;
      userId: string;
    }>) => {
      state[action.payload.userId] = RemoteRecordData.init();
      return state;
    },
    fetchCostCenterUserFulfilled: (state, action: PayloadAction<{
      userId: string;
      data: CostCenterUserType;
    }>) => {
      state[action.payload.userId] = RemoteRecordData.fulfill(action.payload.data);
      return state;
    },
    fetchCostCenterUserRejected: (state, action: PayloadAction<{
      userId: string;
      error: ErrorType;
    }>) => {
      state[action.payload.userId] = RemoteRecordData.reject(action.payload.error);
      return state;
    },
    fetchCostCenterUsers: (state, action: PayloadAction<{
      costCenterId: string,
      page?: number,
      pageSize?: number,
    }>) => {
      state.users = RemoteRecordData.init();
      return state;
    },
    fetchCostCenterUsersFulfilled: (state, action: PayloadAction<CostCenterUsersType>) => {
      state.users = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchCostCenterUsersRejected: (state, action: PayloadAction<ErrorType>) => {
      state.users = RemoteRecordData.reject(action.payload);
      return state;
    },
  },
});

export const {
  fetchCostCenterUser,
  fetchCostCenterUserFulfilled,
  fetchCostCenterUserRejected,
  fetchCostCenterUsers,
  fetchCostCenterUsersFulfilled,
  fetchCostCenterUsersRejected,
} = usersSlice.actions;

export const removeCostCenterUser = createAction<{
  costCenterId: string;
  userId: string;
}>('removeCostCenterUser');

export const submitCostCenterUser = createAction<{
  costCenterId: string,
  userId: string | null
  data: CostCenterUserData,
  onSubmitEndCallback:(isSubmitting: boolean) => void,
    }>('submitCostCenterUser');

export const submitCostCenterUserFulfilled = createAction<CostCenterUsersType>('submitCostCenterUserFulfilled');

const getState = (state: AppState) => state.myAccount.costCenter.user;

export const selectCostCenterUser = createSelector(
  [getState, (state, id) => id],
  (state, id: string) => new RemoteRecord<CostCenterUserType>(state[id]),
);

export const selectCostCenterUsers = createSelector(
  getState,
  (state) => new RemoteRecord<CostCenterUsersType>(state.users),
);
