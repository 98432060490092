import { ReactElement } from 'react';
import { upperCaseFirst } from '@client/common/utils/string/string';
import { useTranslate } from '@client/i18n/hooks';
import { ROUTES } from '@client/routes/components/Router/routes';
import { useRouterPush, useUrlCreator } from '@client/routes/hooks';
import classNames from 'classnames';
import { Button, BUTTON_STYLE_TYPE } from 'b2b-components/theme/components/Button';
import { Icon, ICON_NAME, ICON_SIZE } from 'b2b-components/theme/components/Icon';
import { ButtonsType, Props } from './InfoView.types';
import '@client/common/components/InfoView/InfoView.scss';

export const InfoView = ({
  className,
  buttons,
  image,
  iconType,
  title,
  message,
  testId,
  buttonsGroupClassName,
}: Props): ReactElement => {
  const imageSrcAttributes: {src?: string, srcSet?: string} = {};
  const rootClassName = classNames('InfoView', className);
  const buttonGroupClassName = classNames('InfoView-ButtonGroup', buttonsGroupClassName);
  const t = useTranslate();
  const push = useRouterPush();
  const urlCreator = useUrlCreator();

  if (typeof image === 'string') {
    imageSrcAttributes.src = image;
  } else if (image.default && image.retina) {
    imageSrcAttributes.src = image.default;
    imageSrcAttributes.srcSet = `${image.retina} 2x`;
  }

  const onHomeClick = (): void => {
    push(urlCreator(ROUTES.homepage));
  };

  const defaultButtons = {
    primary: {
      content: t('Homepage'),
      onClick: onHomeClick,
    },
  };

  const selectedButtons: ButtonsType = buttons || defaultButtons;

  const renderIcon = (): ReactElement => {
    if (!iconType) {
      return null;
    }

    const iconName = `IconAlert${upperCaseFirst(iconType)}` as ICON_NAME;
    const iconClassName = classNames(
      'InfoView-TitleIcon',
      `InfoView-TitleIcon--${upperCaseFirst(iconType)}`,
    );

    return (
        <Icon name={iconName} size={ICON_SIZE.large} className={iconClassName} />
    );
  };

  return (
    <div data-testid={testId} className={rootClassName}>
      <div className='InfoView-ImageWrapper'>
        <img
          {...imageSrcAttributes}
          alt={title}
          title={title}
          className='InfoView-Image cds-ImgFluid'
        />
      </div>

      <div className='InfoView-Content'>
        <div className="InfoView-Title">
          {renderIcon()}
          <span className="InfoView-TitleText">{title}</span>
        </div>
        <div className="InfoView-MessageText">{message}</div>
        <div className={buttonGroupClassName}>
          {selectedButtons.secondary && (
            <Button
              onClick={buttons.secondary.onClick}
              styleType={BUTTON_STYLE_TYPE.secondary}
            >
              {selectedButtons.secondary.content}
            </Button>
          )}
          {selectedButtons.primary && (
            <Button
              onClick={selectedButtons.primary.onClick}
              styleType={BUTTON_STYLE_TYPE.primary}
            >
              {selectedButtons.primary.content}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
