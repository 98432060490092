import { ReactElement } from 'react';
import { cssTransition, ToastContainer } from 'react-toastify';
import './ToastWrapper.scss';

export const ToastWrapper = (): ReactElement => (
  <ToastContainer
    hideProgressBar
    closeButton={false}
    position='bottom-right'
    toastClassName="ToastWrapper"
    closeOnClick={true}
    transition={cssTransition({
      collapse: false,
      enter: 'ToastWrapper-AnimationEnter',
      exit: 'ToastWrapper-AnimationLeave',
    })}
  />
);
