import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { stringify } from '@client/common/utils/queryParams';
import { USERS_LIST_LIMITED_PAGE_SIZE } from '@client/myAccount/costCenters/consts';
import { CostCenterUserData } from 'b2b-common/core/account/api/costCenters/CostCenterUsers.types';

export class CostCenterUsers {
  public static get(
    costCenterId: string,
    startItem = 0,
    pageSize = USERS_LIST_LIMITED_PAGE_SIZE,
  ): RequestConfigType {
    return RequestConfig.get(
      `/account/cost-centers/${costCenterId}/users?${stringify({
        pageSize,
        startItem,
      })}`,
      {
        withLocalization: false,
      },
    );
  }

  public static create(
    costCenterId: string,
    data: CostCenterUserData,
  ): RequestConfigType {
    return RequestConfig.post(
      `/account/cost-centers/${costCenterId}/users`,
      data,
    );
  }

  public static update(
    costCenterId: string,
    userId: string,
    data: CostCenterUserData,
  ): RequestConfigType {
    return RequestConfig.put(
      `/account/cost-centers/${costCenterId}/users/${userId}`,
      data,
    );
  }

  public static delete(
    costCenterId: string,
    userId: string,
  ): RequestConfigType {
    return RequestConfig.delete(
      `/account/cost-centers/${costCenterId}/users/${userId}`,
      {
        withLocalization: false,
      },
    );
  }

  public static getAccounts(
    costCenterId: string,
  ): RequestConfigType {
    return RequestConfig.get(
      `/account/cost-centers/${costCenterId}/users/accounts`,
      {
        withLocalization: false,
      },
    );
  }
}
