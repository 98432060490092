import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductPdfState } from './types';

const initialState: ProductPdfState = {};

const NAME = 'productPdf';

export const productPdfSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState,
  reducers: {
    downloadProductPdf: (state, action: PayloadAction<{ sku: string, content: string }>) => {
      state.download = RemoteRecordData.init();
      return state;
    },
    downloadProductPdfFulfilled: (state) => {
      state.download = RemoteRecordData.fulfill({});
      return state;
    },
    downloadProductPdfRejected: (state, action: PayloadAction<{ error: ErrorType }>) => {
      state.download = RemoteRecordData.reject(action.payload.error);
      return state;
    },
    printProductPdf: (state, action: PayloadAction<{ sku: string, content: string }>) => {
      state.print = RemoteRecordData.init();
      return state;
    },
    printProductPdfFulfilled: (state) => {
      state.print = RemoteRecordData.fulfill({});
      return state;
    },
    printProductPdfRejected: (state, action: PayloadAction<{ error: ErrorType }>) => {
      state.print = RemoteRecordData.reject(action.payload.error);
      return state;
    },
  },
});

export const {
  downloadProductPdf,
  downloadProductPdfFulfilled,
  downloadProductPdfRejected,
  printProductPdf,
  printProductPdfFulfilled,
  printProductPdfRejected,
} = productPdfSlice.actions;

const getState = (state: AppState) => state.product.productPdf || {};

export const selectProductPdfDownload = createSelector(
  getState,
  (state) => new RemoteRecord(state.download),
);

export const selectProductPdfPrint = createSelector(
  getState,
  (state) => new RemoteRecord(state.print),
);
