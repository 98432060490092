import { accountDetailsSagas } from '@client/myAccount/accountDetails/redux/sagas';
import { addressesSagas } from '@client/myAccount/addresses/redux/sagas';
import { balanceDetailsSaga } from '@client/myAccount/balanceDetails/redux/sagas';
import { costCenterSagas } from '@client/myAccount/costCenters/redux/sagas';
import { creditsSaga } from '@client/myAccount/credits/redux/sagas';
import { deliveredOrdersSagas } from '@client/myAccount/deliveredOrders/redux/sagas';
import { invoicesSagas } from '@client/myAccount/invoices/redux/sagas';
import { newslettersSaga } from '@client/myAccount/newsletters/redux/sagas';
import { ordersSagas } from '@client/myAccount/orders/redux/sagas';
import { pdfSaga } from '@client/myAccount/pdf/redux/sagas';
import { rmaSagas } from '@client/myAccount/rma/redux/sagas';
import { usersSagas } from '@client/myAccount/users/redux/sagas';
import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';
import { wishlistsSagas } from 'b2b-apps/core/myAccount/wishlists/redux/sagas';

export function* myAccountSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    fork(addressesSagas),
    fork(accountDetailsSagas),
    fork(creditsSaga),
    fork(invoicesSagas),
    fork(newslettersSaga),
    fork(deliveredOrdersSagas),
    fork(ordersSagas),
    fork(pdfSaga),
    fork(rmaSagas),
    fork(usersSagas),
    fork(wishlistsSagas),
    fork(balanceDetailsSaga),
    fork(costCenterSagas),
  ]);
}
