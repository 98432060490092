import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CatalogSearchType } from 'b2b-common/core/catalog/Catalog.types';

const initialData: CatalogSearchType = {
  filters: [],
  navigation: {
    available: [],
    selected: [],
    suggested: [],
  },
  products: [],
  productsCount: 0,
  query: '',
  requestedQuery: '',
  site: null,
  sortingOptions: [],
};

const NAME = 'search';

export const searchSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: Object.assign(RemoteRecordData.getDefault(), { data: initialData }),
  reducers: {
    fetchCatalogSearch: (state, action: PayloadAction<{query: string, identifier: string}>) => {
      state = Object.assign(RemoteRecordData.init(), {
        data: state.data,
      });
      return state;
    },
    fetchCatalogSearchFulfilled: (state, action: PayloadAction<{
      data: CatalogSearchType;
      identifier: string;
    }>) => {
      state = RemoteRecordData.fulfill(action.payload.data, action.payload.identifier);
      return state;
    },
    fetchCatalogSearchRejected: (state, action: PayloadAction<ErrorType>) => {
      state = Object.assign(
        RemoteRecordData.reject(action.payload, state.identifier),
        {
          data: state.data,
        },
      );
      return state;
    },
    clearCatalogSearch: state => {
      state.data.products = initialData.products;
      state.data.productsCount = initialData.productsCount;
      return state;
    },
  },
});

export const {
  fetchCatalogSearch,
  fetchCatalogSearchFulfilled,
  fetchCatalogSearchRejected,
  clearCatalogSearch,
} = searchSlice.actions;

export const DEFAULT_EMPTY_QUERY = '*:*';
export const DEFAULT_EMPTY_REQUESTED_QUERY = '*';

const getState = (state: AppState) => state.catalog.search;

export const selectSearch = createSelector(
  getState,
  (state) => new RemoteRecord<CatalogSearchType>(state),
);

export const selectQuery = createSelector(
  selectSearch,
  state => {
    if (state.data?.query && state.data.query !== DEFAULT_EMPTY_QUERY) {
      return state.data.query;
    }

    return '';
  },
);

export const selectRequestedQuery = createSelector(
  selectSearch,
  state => {
    if (
      state.data?.requestedQuery
      && state.data.requestedQuery !== DEFAULT_EMPTY_REQUESTED_QUERY
    ) {
      return state.data.requestedQuery;
    }

    return '';
  },
);
