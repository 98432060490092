import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';

export class CostCenterUser {
  public static get(
    costCenterId: string,
    userId: string,
  ): RequestConfigType {
    return RequestConfig.get(
      `/account/cost-centers/${costCenterId}/users/${userId}`,
      {
        withLocalization: false,
      },
    );
  }
}
