import { lazy } from 'react';
import { getLocalisedPaths } from '@client/routes/utils/paths';
import { PERMISSION } from 'b2b-common/core/context/Context.types';
import { AUTHENTICATED_ROUTE } from '../constants';
import { WISHLIST_ROUTES_NAMES, WishlistRouteType } from './types';

export const wishlist: WishlistRouteType = {
  [WISHLIST_ROUTES_NAMES.myAccountWishlists]: {
    ...AUTHENTICATED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/wishlists/:page?'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/wishlists" */
      '@client/myAccount/wishlists/components/ListPage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }) => isLoggedIn && permissionsValidator([PERMISSION.accessWishlist]),
  },
  [WISHLIST_ROUTES_NAMES.myAccountWishlist]: {
    ...AUTHENTICATED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/wishlist/:wishlistId'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/wishlist" */
      'b2b-apps/core/myAccount/wishlists/components/ViewPage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }) => isLoggedIn && permissionsValidator([PERMISSION.accessWishlist]),
  },
  [WISHLIST_ROUTES_NAMES.wishlist]: {
    ...AUTHENTICATED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('wishlist/:wishlistId'),
    component: lazy(() => import(
      /* webpackChunkName: "wishlist" */
      'b2b-apps/core/myAccount/wishlists/components/StandaloneViewPage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }) => isLoggedIn && permissionsValidator([PERMISSION.accessWishlist]),
  },
};
