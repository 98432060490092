import { DEFAULT_PER_PAGE, DEFAULT_VIEW_TYPE } from '@client/catalog/hooks';
import { AppState } from '@client/common/redux/store';
import { isServer } from '@client/common/utils/env';
import { getCookie } from '@client/common/utils/storage/cookies';
import { COMPARE_PRODUCTS_COOKIE_NAME } from '@client/product/redux/productsCompare/types';
import { getCookieSetting } from '@client/settings/redux/sagas';
import { PRODUCT_BOX_STYLE_TYPE } from '@lib/components/ProductList';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import deviceType from 'ua-device-type';
import { SettingsProductsCompareType, SettingsState } from './types';

const userAgent = !isServer ? window.navigator.userAgent : undefined;
const productsCompareCookie = getCookie(COMPARE_PRODUCTS_COOKIE_NAME) as SettingsProductsCompareType;

export const initialState: SettingsState = {
  deviceType: deviceType(userAgent),
  versionMismatch: false,
  catalog: {
    viewType: getCookieSetting('viewType', DEFAULT_VIEW_TYPE),
    pageSize: getCookieSetting('pageSize', DEFAULT_PER_PAGE),
  },
  productsCompare: {
    products: productsCompareCookie?.products || [],
    isClosed: !!productsCompareCookie?.isClosed || false,
  },
};

const NAME = 'settings';

export const settingsSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState,
  reducers: {
    changePageSize: (state, action: PayloadAction<number>) => {
      state.catalog.pageSize = action.payload;
      return state;
    },
    changeViewType: (state, action: PayloadAction<PRODUCT_BOX_STYLE_TYPE>) => {
      state.catalog.viewType = action.payload;
      return state;
    },
    updateProductsCompareSettings: (state, action: PayloadAction<Partial<SettingsProductsCompareType>>) => {
      state.productsCompare = {
        ...state.productsCompare,
        ...action.payload,
      };
      return state;
    },
    clearProductsCompareSettings: (state) => {
      state.productsCompare = {
        products: [],
        isClosed: true,
      };
      return state;
    },
    setIsComparisonBarClosed: (state, action: PayloadAction<{ isClosed: boolean }>) => state,
    setAppVersionModal: (state, action: PayloadAction<{ versionMismatch: boolean }>) => {
      state.versionMismatch = action.payload.versionMismatch;
      return state;
    },
  },
});

export const {
  changePageSize,
  changeViewType,
  updateProductsCompareSettings,
  clearProductsCompareSettings,
  setIsComparisonBarClosed,
  setAppVersionModal,
} = settingsSlice.actions;

const getState = (state: AppState) => state.settings;

export const selectDeviceType = createSelector(
  getState,
  state => state.deviceType,
);

export const selectCatalogSettings = createSelector(
  getState,
  state => state.catalog,
);

export const selectProductsCompareSettings = createSelector(
  getState,
  state => state.productsCompare,
);

export const selectAppVersionModal = createSelector(
  getState,
  state => state.versionMismatch,
);
