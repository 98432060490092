import { retryRequestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { DeliveredOrders } from '@client/myAccount/deliveredOrders/api/DeliveredOrders';
import {
  fetchDeliveredOrders,
  fetchDeliveredOrdersFulfilled,
  fetchDeliveredOrdersRejected,
} from '@client/myAccount/deliveredOrders/redux/deliveredOrders/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { DeliveredOrdersType } from 'b2b-common/core/account/api/DeliveredOrders.types';

function* fetchDeliveredOrdersSaga(
  action: ReturnType<typeof fetchDeliveredOrders>,
): Generator<CallEffect | PutEffect, void, AxiosResponse<DeliveredOrdersType>> {
  const startItem = action.payload;

  try {
    const { data } = yield call(
      retryRequestApiSaga,
      DeliveredOrders.fetch(startItem),
    );

    yield put(fetchDeliveredOrdersFulfilled({ data }));
  } catch (error) {
    yield put(fetchDeliveredOrdersRejected({ error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: { startItem } }));
  }
}

export function* deliveredOrdersSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([takeLatest(fetchDeliveredOrders.type, fetchDeliveredOrdersSaga)]);
}
