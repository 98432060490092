import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrdersType } from 'b2b-common/core/account/api/orders';

const NAME = 'orders';

export const ordersSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: RemoteRecordData.getDefault(),
  reducers: {
    fetchOrders: (state, action: PayloadAction<{
      startItem: number;
      pageSize: number;
    }>) => RemoteRecordData.init(),
    fetchOrdersFulfilled: (state, action: PayloadAction<OrdersType>) => RemoteRecordData.fulfill(action.payload),
    fetchOrdersRejected: (state, action: PayloadAction<ErrorType>) => RemoteRecordData.reject(action.payload),
  },
});

export const {
  fetchOrders,
  fetchOrdersFulfilled,
  fetchOrdersRejected,
} = ordersSlice.actions;

const getState = (state: AppState) => state.myAccount.orders.orders;

export const selectOrders = createSelector(
  getState,
  state => new RemoteRecord<OrdersType>(state),
);
