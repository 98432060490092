import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Entry } from '@client/content/api/Entry';
import {
  fetchContentEntry,
  fetchContentEntryFulfilled,
  fetchContentEntryRejected,
  fetchKAM,
  fetchKAMFulfilled,
  fetchKAMRejected,
} from '@client/content/redux/contentEntries/redux';
import { AxiosResponse } from 'axios';
import { all, call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { ContentEntryType } from 'b2b-common/core/content/Content.types';

function* fetchContentEntriesSaga(action: ReturnType<typeof fetchContentEntry>): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<ContentEntryType>
> {
  const entryId = action.payload;

  try {
    const response = yield call(requestApiSaga, Entry.fetch(entryId));
    yield put(fetchContentEntryFulfilled({ entryId, data: Entry.mapResponseToState(response) }));
  } catch (error) {
    yield put(fetchContentEntryRejected({ entryId, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: { error: action.payload } }));
  }
}

function* fetchKAMSaga(action: ReturnType<typeof fetchKAM>): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<ContentEntryType>
> {
  try {
    const response = yield call(requestApiSaga, Entry.fetchKAM());
    yield put(fetchKAMFulfilled(Entry.mapResponseToState(response)));
  } catch (error) {
    yield put(fetchKAMRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: { error: action.payload } }));
  }
}

export function* contentEntriesSaga(): Generator<any, any, any> {
  yield all([
    takeEvery(fetchContentEntry.type, fetchContentEntriesSaga),
    takeEvery(fetchKAM.type, fetchKAMSaga),
  ]);
}
