import { ReactElement } from 'react';
import classNames from 'classnames';
import { Icon, ICON_NAME, ICON_SIZE } from 'b2b-components/theme/components/Icon';
import { ComponentType, Props } from './';
import './ExternalLink.scss';

const ExternalLink: ComponentType = (
  props: Props,
): ReactElement => {
  const { url, label, className } = props;

  return (
    <a
      className={classNames('cds-Link', 'ExternalLink', className)}
      href={url}
      rel="noopener noreferrer"
      target="_blank"
    >
      {label || url}
      &nbsp;
      <Icon
        className="ExternalLink-Icon"
        name={ICON_NAME.linkExternal}
        size={ICON_SIZE.xsmall}
      />
    </a>
  );
};

export { ExternalLink };
