import { CartCostCenter } from '@client/cart/api/CartCostCenter';
import { selectCostCenterFulfilled } from '@client/cart/redux/cart/redux';
import {
  fetchCartCostCenterDetails,
  fetchCartCostCenterDetailsFulfilled,
  fetchCartCostCenterDetailsRejected,
  fetchCartCostCenters,
  fetchCartCostCentersFulfilled,
  fetchCartCostCentersRejected,
  selectCartCostCenter,
} from '@client/cart/redux/costCenter/redux';
import { Checkout } from '@client/checkout/api/Checkout';
import { requestApiSaga } from '@client/common/redux/api/sagas';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { parseError } from '@client/common/utils/api/error';
import emitter, { EVENT } from '@client/common/utils/eventEmitter';
import { CostCenters } from '@client/myAccount/costCenters/api';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { CostCenterSearchListItemType } from 'b2b-common/core/account/api/costCenters/CostCenters.types';
import { CartCostCenterDetails, FetchCartResponseType } from 'b2b-common/core/cart/api/CartTypes';

function* fetchCartCostCentersSaga(): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<CostCenterSearchListItemType[]>
  > {
  try {
    const response = yield call(requestApiSaga, CostCenters.simpleSearch());

    yield put(fetchCartCostCentersFulfilled(response.data));
  } catch (error) {
    yield put(fetchCartCostCentersRejected(parseError(error)));
  }
}

function* selectCartCostCenterSaga(action: ReturnType<typeof selectCartCostCenter>): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<FetchCartResponseType>
  > {
  const costCenterId = action.payload;

  try {
    const { data: cart } = yield call(requestApiSaga, CartCostCenter.set(costCenterId));
    yield put(selectCostCenterFulfilled(cart.summary.costCenter));

    emitter.dispatch(EVENT.selectCartCostCenterFulfilled, RemoteRecordData.fulfill({ done: true }));
  } catch (error) {
    emitter.dispatch(EVENT.selectCartCostCenterRejected, RemoteRecordData.reject(parseError(error)));
  }
}

function* fetchCartCostCenterDetailsSaga(action: ReturnType<typeof fetchCartCostCenterDetails>):
  Generator<CallEffect | PutEffect, void, AxiosResponse<CartCostCenterDetails>> {
  const { costCenterId, withAddresses } = action.payload;
  try {
    const response = yield call(
      requestApiSaga,
      withAddresses
        ? Checkout.getCostCenter(costCenterId)
        : CartCostCenter.get(costCenterId),
    );

    yield put(fetchCartCostCenterDetailsFulfilled(response.data));
  } catch (error) {
    yield put(fetchCartCostCenterDetailsRejected(parseError(error)));
  }
}

export function* cartCostCenterSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(fetchCartCostCenters.type, fetchCartCostCentersSaga),
    takeLatest(selectCartCostCenter.type, selectCartCostCenterSaga),
    takeLatest(fetchCartCostCenterDetails.type, fetchCartCostCenterDetailsSaga),
  ]);
}
