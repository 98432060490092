import {
  AnalyticsProductType,
  OssDataLayerType,
  TEALIUM_INTERACTION_ID,
  TEALIUM_PAGE_TYPE,
  TEALIUM_PROD_ACTION,
  TEALIUM_PROD_LIST,
} from '@client/analytics/types/types';
import { ProductAccessoryType } from '@client/product/redux/productAccessories/types';
import { ProductFullType } from '@client/product/redux/products/full/types';
import { PRODUCT_BOX_STYLE_TYPE } from '@lib/components/ProductList';
import { ProductTileType } from 'b2b-common/core/product/api/ProductTile.types';

export enum SEARCH_COMPONENT_CATEGORY {
  srchFilter = 'srch_filter'
}

export enum SEARCH_ACTION {
  select = 'select',
  deselect = 'deselect'
}

export enum OSS_INTERACTION {
  start = 'start',
  view = 'view',
  click = 'click',
  other = 'other',
  filter = 'filter',
}

export enum OSS_INTERACTION_LABEL {
  click = 'std_srch:start:via_button_or_enter',
  viewResultPage = 'std_srch:view:results_page',
  productClick = 'std_srch:click:prod',
  suggestionStart = 'sug_srch:start',
  suggestionClick = 'sug_srch:click:prod',
  suggestionStartFromSimmilars = 'std_srch:start:from_similar_query',
  suggestionCategory = 'std_srch:start:from_suggested_category',
  filter = 'std_srch:filter' // remember to add :filterName after
}

export type SearchAnalyticsData = {
  interaction: TEALIUM_INTERACTION_ID;
  prod?: AnalyticsProductType[],
  page?: {
    type: TEALIUM_PAGE_TYPE;
  },
  oss: OssDataLayerType,
}

export type SearchOssData = {
  query: string;
  originalQuery?: string;
  sortType?: string;
  resultsPerPage?: number;
  page?: number;
  productsCount?: number;
  listType?: PRODUCT_BOX_STYLE_TYPE;
}

export type SearchData = SearchOssData & {
  id: TEALIUM_INTERACTION_ID;
  pageType?: TEALIUM_PAGE_TYPE;
  products?: (ProductFullType | ProductTileType | ProductAccessoryType)[];
  productsAction?: TEALIUM_PROD_ACTION;
  productsList?: TEALIUM_PROD_LIST;
  productQuantity?: number,
}

export enum FILTER_TYPE {
  catalogAll = 'catalog_finder',
  inkTonerFilter = 'ink_finder'
}

