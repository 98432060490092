import { KeyboardEvent, ReactElement, useState } from 'react';
import { useDeviceType } from '@client/common/hooks';
import { Header } from './Header';
import { Props } from './HeaderContainer.types';

export const HeaderContainer = (props: Props): ReactElement => {
  const deviceType = useDeviceType();
  const defaultOnKeyDown = () => (event: KeyboardEvent) => {
    event.preventDefault();
  };

  const [onKeyDown, setOnKeyDown] = useState(defaultOnKeyDown);

  return (
    <Header
      {...props}
      deviceType={deviceType}
      onKeyDown={onKeyDown}
      setOnKeyDown={setOnKeyDown}
    />
  );
};
