import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useUpdateSearchUrlParams } from '@client/catalog/hooks/index';
import { FILTERS } from '@client/catalog/utils/facets';
import { getPaginationFilters } from '@client/catalog/utils/filters';
import { useDeviceType } from '@client/common/hooks';
import { changePageSize, selectCatalogSettings } from '@client/settings/redux/redux';
import { ValueType } from '@lib/components/Form/common';
import { PRODUCT_BOX_STYLE_TYPE } from '@lib/components/ProductList';
import { bindActionCreators } from '@reduxjs/toolkit';
import { PaginationFiltersType } from 'b2b-common/core/catalog/Catalog.types';

export const DEFAULT_SORTING = 'standard';
export const DEFAULT_PER_PAGE = 48;
export const DEFAULT_VIEW_TYPE = PRODUCT_BOX_STYLE_TYPE.list;

type UseCatalogPaginationFiltersReturnType = {
  paginationFilters: PaginationFiltersType;
  onPageChange: (page: number) => void;
  onSortingChange: (sorting: ValueType) => void;
  onPageSizeChange: (pageSize: number) => void;
};

export const useCatalogPaginationFilters = (): UseCatalogPaginationFiltersReturnType => {
  const { search } = useLocation();
  const { updateSearchUrlParams } = useUpdateSearchUrlParams();
  const { pageSize } = useSelector(selectCatalogSettings);

  const deviceType = useDeviceType();
  const paginationFilters: PaginationFiltersType = getPaginationFilters(search, deviceType, pageSize);
  const onChangePageSize = bindActionCreators(changePageSize, useDispatch());

  const handlePaginationPageChange = (page: number): void => {
    updateSearchUrlParams({
      [FILTERS.pagination]: {
        ...paginationFilters,
        page,
      },
    });
  };

  const handlePageSizeChange = (pageSize: number): void => {
    onChangePageSize(pageSize);
    updateSearchUrlParams({
      [FILTERS.pagination]: {
        ...paginationFilters,
        pageSize,
        page: 1,
      },
    });
  };


  const handleSortingChange = (sorting: ValueType): void => {
    const value = isNaN(parseInt(sorting as string)) ? `${sorting}` : null;

    updateSearchUrlParams({
      [FILTERS.pagination]: {
        ...paginationFilters,
        sorting: value || DEFAULT_SORTING,
        page: 1,
      },
    });
  };

  return {
    paginationFilters,
    onPageChange: handlePaginationPageChange,
    onPageSizeChange: handlePageSizeChange,
    onSortingChange: handleSortingChange,
  };
};
