import { ReactElement } from 'react';
import { Tooltip } from '@client/common/components/Tooltip';
import { useTranslate } from '@client/i18n/hooks';
import { ACTION_LINK_ICON_NAME, ACTION_LINK_ICON_SIZE, ActionLink } from 'b2b-components/theme/components/ActionLink';
import {
  BUTTON_ICON_ICON_NAME,
  BUTTON_ICON_SIZE,
  BUTTON_ICON_STYLE_TYPE,
  ButtonIcon,
} from 'b2b-components/theme/components/ButtonIcon';
import { Props } from './AddToWishlistAction.types';
import { ADD_TO_WISHLIST_STYLE_TYPE } from './AddToWishlistActionContainer.types';
import { AddToWishListModal } from './AddToWishListModal';

export const AddToWishlistAction = ({
  isModalOpened,
  onModalOpen,
  onModalClose,
  skus,
  isLoggedIn,
  hasUserWishlistAccessPermission,
  styleType,
  className,
}: Props): ReactElement | null => {
  const t = useTranslate();

  if (styleType === ADD_TO_WISHLIST_STYLE_TYPE.link && isLoggedIn && !hasUserWishlistAccessPermission) {
    return null;
  }

  return (
    <div
      data-testid='addItemToWishlistActionButton'
      className={className}
    >
      {styleType === ADD_TO_WISHLIST_STYLE_TYPE.link ? (
        <ActionLink
          onClick={onModalOpen}
          iconName={ACTION_LINK_ICON_NAME.wishlistAdd}
          iconSize={ACTION_LINK_ICON_SIZE.small}
        >
          {t('Save to wishlist')}
        </ActionLink>
      ) : (
        <Tooltip
          isApplied={isLoggedIn && !hasUserWishlistAccessPermission}
          content={t('You do not have the permission to access wishlist features.')}
        >
          <ButtonIcon
            icon={BUTTON_ICON_ICON_NAME.wishlistAdd}
            isDisabled={isLoggedIn && !hasUserWishlistAccessPermission}
            onClick={onModalOpen}
            size={
              styleType === ADD_TO_WISHLIST_STYLE_TYPE.icon
                ? BUTTON_ICON_SIZE.small
                : BUTTON_ICON_SIZE.default
            }
            styleType={BUTTON_ICON_STYLE_TYPE.secondary}
            title={t('Add item to wishlist')}
          />
        </Tooltip>
      )}

      <AddToWishListModal
        isModalOpened={isModalOpened}
        onModalClose={onModalClose}
        skus={skus}
      />
    </div>
  );
};
