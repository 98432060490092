import { lazy } from 'react';
import { getLocalisedPaths } from '@client/routes/utils/paths';
import { PERMISSION } from 'b2b-common/core/context/Context.types';
import { AUTHENTICATED_ROUTE } from '../constants';
import { ADDRESSES_ROUTES_NAMES, AddressesRouteType } from './types';

export const addresses: AddressesRouteType = {
  [ADDRESSES_ROUTES_NAMES.myAccountAddresses]: {
    ...AUTHENTICATED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/addresses/:page?'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/addresses" */
      '@client/myAccount/addresses/components/ListPage'
    )),
    allow:
      ({ isLoggedIn, permissionsValidator }) => isLoggedIn
        && permissionsValidator([PERMISSION.accessMyAccountAddresses]),
  },
  [ADDRESSES_ROUTES_NAMES.myAccountAddressCreate]: {
    ...AUTHENTICATED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/address/create'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/address/create" */
      '@client/myAccount/addresses/components/CreatePage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }) => isLoggedIn
      && permissionsValidator([PERMISSION.accessMyAccountBasic]),
  },
  [ADDRESSES_ROUTES_NAMES.myAccountAddressUpdate]: {
    ...AUTHENTICATED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/address/update/:id'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/address/update" */
      '@client/myAccount/addresses/components/UpdatePage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }) => isLoggedIn
      && permissionsValidator([PERMISSION.accessMyAccountBasic]),
  },
};
