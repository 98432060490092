import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { AxiosResponse } from 'axios';
import { ManufacturerType } from 'b2b-common/core/manufacturer';

export class Manufacturer {
  public static fetch(manufacturerId: string): RequestConfigType {
    return RequestConfig.get(
      `/manufacturers/${manufacturerId}`,
      {
        withLocalization: false,
      },
    );
  }

  public static mapResponseToState(response: AxiosResponse): ManufacturerType {
    const { data } = response;

    return data;
  }
}
