import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestBillingAddressChangeType } from 'b2b-common/core/account/api/BillingAddress.types';

const NAME = 'billingAddress';

export const slice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: RemoteRecordData.getDefault(),
  reducers: {
    updateBillingAddress: (state, action: PayloadAction<RequestBillingAddressChangeType>) => {
      state = RemoteRecordData.init();
      return state;
    },
    updateBillingAddressFulfilled: (state) => {
      state = RemoteRecordData.fulfill({});
      return state;
    },
    updateBillingAddressRejected: (state, action: PayloadAction<ErrorType>) => {
      state = RemoteRecordData.reject(action.payload);
      return state;
    },
    clearBillingAddress: (state) => {
      state = RemoteRecordData.getDefault();
      return state;
    },
  },
});

export const {
  updateBillingAddress,
  updateBillingAddressFulfilled,
  updateBillingAddressRejected,
  clearBillingAddress,
} = slice.actions;

const getState = (state: AppState) => state.myAccount.accountDetails.billingAddress;

export const selectBillingAddress = createSelector(
  getState,
  state => new RemoteRecord<RequestBillingAddressChangeType>(state),
);
