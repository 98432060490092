import { ReactElement } from 'react';
import { useTranslate } from '@client/i18n/hooks';
import classNames from 'classnames';
import {
  PRODUCT_LABEL_SIZE,
  PRODUCT_LABEL_STYLE_TYPE,
  ProductLabel,
} from 'b2b-components/theme/components/ProductLabel';
import { Props } from './Labels.types';
import './Labels.scss';

export const Labels = ({
  className,
  size,
  isNew, isSellout, isOccasion, isSpecialOffer, isCoreAssortment, isOwnAssortment, isConsciousAlternative,
}: Props): ReactElement | null => {
  const t = useTranslate();

  if (!isNew
    && !isSellout
    && !isOccasion
    && !isSpecialOffer
    && !isCoreAssortment
    && !isOwnAssortment
    && !isConsciousAlternative
  ) {
    return null;
  }

  return (
    <div className={classNames('Labels-Container', className)}>
      {isOwnAssortment && (
        <ProductLabel
          className='Labels-LabelIcon'
          size={size || PRODUCT_LABEL_SIZE.small}
          styleType={PRODUCT_LABEL_STYLE_TYPE.ownAssortment}
          label={t('Customer specific assortment')}
        />
      )}
      {isCoreAssortment && (
        <ProductLabel
          className='Labels-LabelIcon'
          size={size || PRODUCT_LABEL_SIZE.small}
          styleType={PRODUCT_LABEL_STYLE_TYPE.coreAssortment}
          label={t('Core assortment')}
        />
      )}
      {isNew && (
        <ProductLabel
          isInline
          className='Labels-Label'
          styleType={PRODUCT_LABEL_STYLE_TYPE.new}
          label={t('new flag', {}, 'Product flag icon - "new" label')}
        />
      )}

      {isSpecialOffer && (
        <ProductLabel
          isInline
          className='Labels-Label'
          styleType={PRODUCT_LABEL_STYLE_TYPE.promotion}
          label={t('special offer flag', {}, 'Product flag icon - "special offer" label')}
        />
      )}

      {isOccasion && (
        <ProductLabel
          isInline
          className='Labels-Label'
          styleType={PRODUCT_LABEL_STYLE_TYPE.occasion}
          label={t('verified return flag', {}, 'Product flag icon - "verified return" label')}
        />
      )}

      {isSellout && (
        <ProductLabel
          isInline
          className='Labels-Label'
          styleType={PRODUCT_LABEL_STYLE_TYPE.sellout}
          label={t('sale flag', {}, 'Product flag icon - "sale" label')} />
      )}

      {isConsciousAlternative && (
        <ProductLabel
          className='Labels-Label Labels-Label--FullHeight'
          styleType={PRODUCT_LABEL_STYLE_TYPE.consciousAlternative}
          label={t('conscious alternative flag', {}, 'Product flag icon - "conscious alternative" label')} />
      )}
    </div>
  );
};
