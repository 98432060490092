import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CatalogType } from '@client/app/hooks';
import { useMapAssortment } from '@client/app/hooks/useMapAssortment';
import { getIsLoggedIn } from '@client/context/redux/redux';
import { fetchOwnAssortment, selectOwnAssortment } from '../redux/ownAssortment/redux';

export const useOwnAssortment = (): CatalogType => {
  const dispatch = useDispatch();
  const ownAssortment = useSelector(selectOwnAssortment);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const content = useMapAssortment(ownAssortment);

  useEffect(
    (): void => {
      if (!ownAssortment.isLoaded && isLoggedIn) {
        dispatch(fetchOwnAssortment());
      }
    },
    [ownAssortment.isLoaded, isLoggedIn],
  );

  return content;
};
