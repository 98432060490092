import emitter, { EVENT } from 'b2b-apps/core/common/utils/eventEmitter';

export type OnSearch<OptionType> = (phrase: string) => Promise<OptionType[]>;

export const getOnSearchableSelectSearch = <DataItem, Option>(
  onSearch: (phrase: string) => void,
  fulfilledEventName: EVENT,
  rejectedEventName: EVENT,
  mapper: (data: DataItem[]) => Option[],
): OnSearch<Option> => (searchPhrase) => new Promise((resolve, reject) => {
    if (searchPhrase) {
      onSearch(searchPhrase);

      emitter.on(fulfilledEventName, (data: DataItem[]): void => {
        resolve(mapper(data));
        emitter.off(fulfilledEventName);
      });

      emitter.on(rejectedEventName, (data: DataItem[]): void => {
        reject(data);
        emitter.off(rejectedEventName);
      });
    }
  });
