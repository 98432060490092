import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureOnboarding } from 'b2b-common/core/content/FeatureOnboarding.types';

const NAME = 'featureOnboarding';

export const slice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {},
  reducers: {
    fetchFeatureOnboarding: (state) => {
      state = RemoteRecordData.init();
      return state;
    },
    fetchFeatureOnboardingFulfilled: (state, action: PayloadAction<FeatureOnboarding[]>) => {
      state = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchFeatureOnboardingRejected: (state, action: PayloadAction<ErrorType>) => {
      state = RemoteRecordData.reject(action.payload);
      return state;
    },
  },
});


export const {
  fetchFeatureOnboarding,
  fetchFeatureOnboardingFulfilled,
  fetchFeatureOnboardingRejected,
} = slice.actions;

const getState = (state: AppState)=> state.content;

export const selectFeatureOnboarding = createSelector(
  getState,
  state => new RemoteRecord<FeatureOnboarding[]>(state[NAME]),
);
