import { logger } from '@client/common/utils/logger/logger';
import { ExtrasType } from '@client/common/utils/logger/logger.types';
import { AxiosError, AxiosResponse } from 'axios';

export const parseError = (error: unknown): ErrorType => {
  const {
    message,
    response,
  } = error as AxiosError;

  if (response) {
    const {
      data,
      status,
    } = response as AxiosResponse<any>;

    return {
      data,
      message: data.message,
      statusCode: status,
    };
  }

  return {
    message,
    statusCode: null,
  };
};

export const handleError = (
  message: string,
  extras: ExtrasType,
  ignoreNetwork = true,
): void => {
  if (!extras.error) {
    return;
  }

  if (extras.error.isAuthError) {
    return;
  }

  if (ignoreNetwork && extras.error?.isAxiosError) {
    return;
  }

  logger.error(message, extras);
};
