import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import {
  fetchCostCenterAccounts,
  fetchCostCenterAccountsFulfilled,
  fetchCostCenterAccountsRejected,
} from '@client/myAccount/costCenters/redux/accounts/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { CostCenterAccounts } from 'b2b-common/core/account/api/costCenters/CostCenterAccounts.types';
import { CostCenterUsers } from '../../api/CostCenterUsers';

function* fetchCostCenterAccountsSaga(action: ReturnType<typeof fetchCostCenterAccounts>):
  Generator<CallEffect | PutEffect, void, AxiosResponse<CostCenterAccounts>> {
  const costCenterId = action.payload;

  try {
    const response = yield call(
      requestApiSaga,
      CostCenterUsers.getAccounts(costCenterId),
    );

    yield put(fetchCostCenterAccountsFulfilled(response.data));
  } catch (error) {
    yield put(fetchCostCenterAccountsRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: { error: action.payload } }));
  }
}

export function* costCenterAccountsSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(fetchCostCenterAccounts.type, fetchCostCenterAccountsSaga),
  ]);
}
