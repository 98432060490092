enum RMA_TYPE {
  repair ='repair',
  return = 'return',
}

enum RMA_STATUS {
  awaitingGoods = 'AWAITING_FOR_GOODS',
  checkingGoods = 'GOODS_ARE_BEING_CHECKED',
  closed = 'RMA_CLOSED',
  goodsInRepair = 'GOODS_IN_REPAIR',
  goodsOnTheWayBack = 'GOODS_READY_TO_COLLECT_OR_SHIPPING',
  goodsReceived = 'GOODS_RECEIVED',
  unknown = 'UNKNOWN',
  warrantyExchange = 'ADVANCE_WARRANTY_EXCHANGE',
}

enum RMA_SHIPPING_METHOD {
  post = 'MAIL',
  pickup = 'PICKUP',
}

const RMA_CUSTOMER_REF_MAX_LENGTH = 40;

export {
  RMA_CUSTOMER_REF_MAX_LENGTH,
  RMA_SHIPPING_METHOD,
  RMA_STATUS,
  RMA_TYPE,
};
