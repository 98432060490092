import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { AxiosResponse } from 'axios';
import { SkuListSearchType } from 'b2b-common/core/catalog/Catalog.types';

export class SkuList {
  public static fetch(queryString: string): RequestConfigType {
    return RequestConfig.get(
      `/catalog/find-products?${queryString}`,
      {
        withLocalization: true,
      },
    );
  }

  public static mapResponseToState(response: AxiosResponse): SkuListSearchType {
    return response.data;
  }
}
