import { useSelector } from 'react-redux';
import { AppState } from '@client/common/redux/store';
import md5 from 'md5';
import { ContentEntryType } from 'b2b-common/core/content/Content.types';

export const useProductsFromMultipleQueries = (products: ContentEntryType[]): any => {
  const state = useSelector((state: AppState) => state.catalog.skuList);
  const preparedData = products?.map((product: ContentEntryType) => ({
    query: product?.source_of_products?.substring(product?.source_of_products?.indexOf('?')),
    position: product?.position,
    limit: product?.limit,
  }))
    ?.map((item: any) => ({
      ...item,
      queryId: md5(item?.query?.toLowerCase()),
    }));

  const content = preparedData?.map((product: ContentEntryType) => ({
    skus: state[product?.queryId]?.data?.skuList?.slice(0, product?.limit || 1) || [],
    position: product?.position,
  }));

  return content;
};
