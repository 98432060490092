import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { stringify } from '@client/common/utils/queryParams';
import { FormValuesType } from '@client/myAccount/costCenters/components/Budgets/FormPage';
import { BUDGETS_LIST_DEFAULT_PAGE_SIZE } from '@client/myAccount/costCenters/consts';

export class CostCenterBudgets {
  public static list(
    costCenterId: string,
    startItem = 0,
    pageSize: number = BUDGETS_LIST_DEFAULT_PAGE_SIZE,
  ): RequestConfigType {
    return RequestConfig.get(
      `/account/cost-centers/${costCenterId}/budgets?${stringify({ pageSize, startItem })}`,
      {
        withLocalization: false,
      },
    );
  }

  public static create(
    costCenterId: string,
    data: FormValuesType,
  ): RequestConfigType {
    return RequestConfig.post(
      `/account/cost-centers/${costCenterId}/budgets`,
      data,
      {
        withLocalization: false,
      },
    );
  }

  public static get(
    costCenterId: string,
    budgetId: string,
  ): RequestConfigType {
    return RequestConfig.get(
      `/account/cost-centers/${costCenterId}/budgets/${budgetId}`,
      {
        withLocalization: false,
      },
    );
  }

  public static update(
    costCenterId: string,
    budgetId: string,
    data: FormValuesType,
  ): RequestConfigType {
    return RequestConfig.put(
      `/account/cost-centers/${costCenterId}/budgets/${budgetId}`,
      data,
      {
        withLocalization: false,
      },
    );
  }

  public static delete(
    costCenterId: string,
    budgetId: string,
  ): RequestConfigType {
    return RequestConfig.delete(
      `/account/cost-centers/${costCenterId}/budgets/${budgetId}`,
      {
        withLocalization: false,
      },
    );
  }
}
