import { DEVICE_TYPE } from 'b2b-components/utils/device';

export const isMobile = (deviceType: DEVICE_TYPE): boolean => ['phone', 'tablet'].includes(deviceType);
export const isPhone = (deviceType: DEVICE_TYPE): boolean => deviceType === DEVICE_TYPE.phone;

export const isServer = !(
  typeof window !== 'undefined'
  && window.document
  && window.document.createElement
);
