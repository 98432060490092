import { fetchCartStatus } from '@client/cart/redux/cartStatus/redux';
import { Checkout } from '@client/checkout/api/Checkout';
import { Order } from '@client/checkout/api/Order';
import { selectCheckout, selectRequestData, updateCheckout } from '@client/checkout/redux/checkout/redux';
import { setCheckoutDetails } from '@client/checkout/redux/details/redux';
import { placeOrder, placeOrderFulfilled, placeOrderRejected } from '@client/checkout/redux/order/redux';
import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { getProfile } from '@client/context/redux/redux';
import { ROUTES } from '@client/routes/components/Router/routes';
import { pushToRoute } from '@client/routes/redux/utils';
import { getAbsoluteUrl } from '@client/routes/utils/url';
import { AxiosResponse } from 'axios';
import { all, call, CallEffect, put, PutEffect, select, SelectEffect, takeLeading } from 'redux-saga/effects';
import { CheckoutDetails } from 'b2b-common/core/checkout/CheckoutDetails.types';
import { updateCheckoutSaga } from '../checkout/sagas';

function* processPayment(checkoutId: string, checkoutDetails: CheckoutDetails):
  Generator<
    CallEffect,
    void,
    AxiosResponse<{ redirectUrl: string }>
  > {
  const callbackUrls = {
    success: getAbsoluteUrl('checkout/payment/success'),
    cancel: getAbsoluteUrl('checkout/payment/cancel'),
    error: getAbsoluteUrl('checkout/payment/error'),
  };

  const response = yield call(
    requestApiSaga,
    Checkout.datatransInit(checkoutId, callbackUrls, checkoutDetails),
  );

  window.location.replace(response.data?.redirectUrl);
}

export function* placeOrderSaga(action: ReturnType<typeof placeOrder>):
  Generator<
    SelectEffect | CallEffect | PutEffect | Generator,
    void,
    any
  > {
  const checkoutData = yield select(selectCheckout);

  if (!checkoutData.isLoaded) {
    return;
  }

  const { data: { payment, token, confirmationEmail, costCenter } } = checkoutData;
  const checkoutId = action.payload;
  const checkoutDetails = { confirmationEmail, isApprovalNeeded: costCenter?.isApprovalNeeded };

  if (payment.isDatatrans) {
    yield call(processPayment, token, checkoutDetails);

    return;
  }

  yield pushToRoute(ROUTES.checkoutConfirmation);

  const requestData = yield select(selectRequestData);
  const loggedUserProfile = yield select(getProfile);

  try {
    if (requestData && !requestData.reference && loggedUserProfile?.email) {
      yield call(
        updateCheckoutSaga,
        updateCheckout({
          checkoutId,
          data: {
            ...requestData,
            reference: loggedUserProfile.email,
          },
        }),
      );
    }

    const response = yield call(requestApiSaga, Order.place(checkoutId, checkoutDetails));

    yield put(setCheckoutDetails(checkoutDetails));
    yield put(placeOrderFulfilled({ checkoutId, data: response.data }));
    yield put(fetchCartStatus());
  } catch (error: any) {
    yield put(placeOrderRejected({ checkoutId, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: { checkoutId } }));
    yield pushToRoute(ROUTES.checkoutFail);
  }
}

export function* orderSaga(): Generator<any, any, any> {
  yield all([
    takeLeading(placeOrder.type, placeOrderSaga),
  ]);
}
