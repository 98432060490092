import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { AxiosResponse } from 'axios';
import { SuggestionsType } from 'b2b-common/core/catalog/Suggestions.types';

export class Suggestions {
  public static fetch(query: string): RequestConfigType {
    const encodedQuery = encodeURIComponent(query);
    return RequestConfig.get(
      `/catalog/suggest?query=${encodedQuery}`,
    );
  }

  public static mapResponseToState(response: AxiosResponse<SuggestionsType>): SuggestionsType {
    return response.data;
  }
}
