import { coreAssortmentSlice } from '@client/product/redux/coreAssortment/redux';
import { productAccessoriesSlice } from '@client/product/redux/productAccessories/redux';
import { productGallerySlice } from '@client/product/redux/productGallery/redux';
import { productPdfSlice } from '@client/product/redux/productPdf/redux';
import { productReportErrorSlice } from '@client/product/redux/productReportError/redux';
import { productsReducer } from '@client/product/redux/products/redux';
import { productsCompareSlice } from '@client/product/redux/productsCompare/redux';
import { productVariantsMatrixSlice } from '@client/product/redux/productVariantsMatrix/redux';
import { similarProductsSlice } from '@client/product/redux/similarProducts/redux';
import { stampConfiguratorSlice } from '@client/product/redux/stampConfigurator/redux';
import { combineSlices } from '@reduxjs/toolkit';

export const productReducer = combineSlices(
  coreAssortmentSlice,
  productAccessoriesSlice,
  productGallerySlice,
  productPdfSlice,
  productReportErrorSlice,
  productsCompareSlice,
  productVariantsMatrixSlice,
  similarProductsSlice,
  stampConfiguratorSlice,
  { products: productsReducer },
);
