import { TEALIUM_INTERACTION_ID } from '@client/analytics/types/types';
import { ANALYTICS_ATTRIBUTES, BaseBuilder } from '@client/analytics/utils/builders/BaseBuilder';

export class ContentAnalyticsBuilder extends BaseBuilder<any> {
  protected attributes = [
    ANALYTICS_ATTRIBUTES.category,
    ANALYTICS_ATTRIBUTES.subcategory,
    ANALYTICS_ATTRIBUTES.attribute1,
    ANALYTICS_ATTRIBUTES.attribute2,
    ANALYTICS_ATTRIBUTES.attribute3,
  ];

  protected attribute1: string[];
  protected attribute2: string[];

  public setAttribute1(attribute1: string): ContentAnalyticsBuilder {
    this.attribute1 = [attribute1];
    return this;
  }

  public setAttribute2(attribute2: string): ContentAnalyticsBuilder {
    this.attribute2 = [attribute2];
    return this;
  }

  public build(): any {
    this.analyticsResultObject = {
      [ANALYTICS_ATTRIBUTES.category]: ['nav'],
      [ANALYTICS_ATTRIBUTES.subcategory]: ['generic_form'],
      [ANALYTICS_ATTRIBUTES.attribute1]: this.attribute1,
      [ANALYTICS_ATTRIBUTES.attribute2]: this.attribute2,
      [ANALYTICS_ATTRIBUTES.attribute3]: ['submit_form'],
    };


    return {
      interaction: TEALIUM_INTERACTION_ID.formSubmissions,
      [ANALYTICS_ATTRIBUTES.component]: {
        ...super.build(),
      },
    };
  }
}
