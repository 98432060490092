import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { MY_ACCOUNT_MESSAGE, showMyAccountToast } from '@client/myAccount/common/utils/messages';
import { CostCenterBudgets } from '@client/myAccount/costCenters/api';
import { BUDGETS_LIST_DEFAULT_PAGE_SIZE } from '@client/myAccount/costCenters/consts';
import {
  fetchCostCenterBudget,
  fetchCostCenterBudgetFulfilled,
  fetchCostCenterBudgetRejected,
  fetchCostCenterBudgets,
  fetchCostCenterBudgetsFulfilled,
  fetchCostCenterBudgetsRejected,
  removeCostCenterBudget,
  submitCostCenterBudget,
} from '@client/myAccount/costCenters/redux/budget';
import { ROUTES } from '@client/routes/components/Router/routes';
import { pushToRoute } from '@client/routes/redux/utils';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import emitter, { EVENT } from 'b2b-apps/core/common/utils/eventEmitter';
import { BudgetType, CostCenterBudgetsType } from 'b2b-common/core/account/api/costCenters/CostCenterBudget.types';

function* fetchBudgetSaga(action: ReturnType<typeof fetchCostCenterBudget>):
  Generator<CallEffect | PutEffect, void, AxiosResponse<BudgetType>> {
  const { costCenterId, budgetId } = action.payload;

  try {
    const response = yield call(
      requestApiSaga,
      CostCenterBudgets.get(costCenterId, budgetId),
    );

    yield put(fetchCostCenterBudgetFulfilled({ budgetId, data: response.data }));
  } catch (error) {
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
    yield put(fetchCostCenterBudgetRejected({ budgetId, error: parseError(error) }));
  }
}

function* fetchBudgetsSaga(action: ReturnType<typeof fetchCostCenterBudgets>):
  Generator<CallEffect | PutEffect, void, AxiosResponse<CostCenterBudgetsType>> {
  const { costCenterId, page, pageSize } = action.payload;
  const startItem = (page - 1) * BUDGETS_LIST_DEFAULT_PAGE_SIZE;

  try {
    const response = yield call(
      requestApiSaga,
      CostCenterBudgets.list(costCenterId, startItem, pageSize),
    );

    yield put(fetchCostCenterBudgetsFulfilled({ costCenterId, data: response.data }));
  } catch (error) {
    yield put(fetchCostCenterBudgetsRejected({ costCenterId, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* submitBudgetSaga(action: ReturnType<typeof submitCostCenterBudget>):
  Generator<CallEffect | PutEffect | Generator, void> {
  const { costCenterId, budgetId, data } = action.payload;

  try {
    if (budgetId) {
      yield call(
        requestApiSaga,
        CostCenterBudgets.update(costCenterId, budgetId, data),
      );
    } else {
      yield call(
        requestApiSaga,
        CostCenterBudgets.create(costCenterId, data),
      );
    }

    yield pushToRoute(ROUTES.myAccountCostCenterBudgets, { costCenterId });
    emitter.dispatch(EVENT.submitCostCenterBudgetFulfilled);
  } catch (error) {
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));

    emitter.dispatch(EVENT.submitCostCenterBudgetRejected);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.submitBudgetError);
  }
}

function* removeBudgetSaga(action: ReturnType<typeof removeCostCenterBudget>):
  Generator<CallEffect | PutEffect | Generator, void> {
  const { costCenterId, budgetId } = action.payload;

  try {
    yield call(
      requestApiSaga,
      CostCenterBudgets.delete(costCenterId, budgetId),
    );

    emitter.dispatch(EVENT.removeCostCenterBudgetFulfilled);
  } catch (error) {
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));

    emitter.dispatch(EVENT.removeCostCenterBudgetRejected);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.removeBudgetError);
  }
}

export function* budgetSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(fetchCostCenterBudget.type, fetchBudgetSaga),
    takeLatest(submitCostCenterBudget.type, submitBudgetSaga),
    takeLatest(removeCostCenterBudget.type, removeBudgetSaga),
    takeLatest(fetchCostCenterBudgets.type, fetchBudgetsSaga),
  ]);
}
