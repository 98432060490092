import { FC, ReactElement } from 'react';
import { useLoginUrl } from '@client/context/hooks';
import { useTranslate } from '@client/i18n/hooks';
import classNames from 'classnames';
import { Icon, ICON_NAME } from 'b2b-components/theme/components/Icon';
import { Props } from './';
import './Full.scss';

const Full: FC<Props> = ({
  className,
  hasCurrencyCode,
  isHiddenPriceAndNotCertified,
}: Props): ReactElement => {
  const t = useTranslate();
  const getLoginUrl = useLoginUrl();
  const loginUrl = getLoginUrl();
  const rootClassName = classNames('FullHiddenPrice', className);


  return (
    <div className={rootClassName}>
      {hasCurrencyCode && <span className="FullHiddenPrice-Currency">CHF</span>}

      <Icon
        className="FullHiddenPrice-Icon"
        name={
          isHiddenPriceAndNotCertified ? ICON_NAME.certificate : ICON_NAME.lock
        }
      />

      {isHiddenPriceAndNotCertified ? (
        <span className="FullHiddenPrice-Info">
          {t('Certification is required to see the price')}
        </span>
      ) : (
        <a
          className="FullHiddenPrice-Info"
          href={loginUrl}
          dangerouslySetInnerHTML={{
            __html: t('<button class="cds-ButtonLink">Log in</button> to see prices'),
          }}
        />
      )}
    </div>
  );
};

export { Full };
