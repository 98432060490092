import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { FeatureOnboarding as API } from '@client/content/api/FeatureOnboarding';
import {
  fetchFeatureOnboarding,
  fetchFeatureOnboardingFulfilled,
  fetchFeatureOnboardingRejected,
} from '@client/content/redux/featureOnboarding/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { FeatureOnboarding } from 'b2b-common/core/content/FeatureOnboarding.types';


function* fetchFeatureOnboardingSaga(action: ReturnType<typeof fetchFeatureOnboarding>): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<FeatureOnboarding[]>
> {
  try {
    const response = yield call(
      requestApiSaga,
      API.fetch(),
    );
    yield put(fetchFeatureOnboardingFulfilled(API.mapResponseToState<FeatureOnboarding[]>(response)));
  } catch (error) {
    yield put(fetchFeatureOnboardingRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* featureOnboardingSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeEvery(fetchFeatureOnboarding.type, fetchFeatureOnboardingSaga),
  ]);
}
