import { barcodeSearchSaga } from '@client/catalog/redux/barcodeSearch/sagas';
import { inkFinderSaga } from '@client/catalog/redux/inkFinder/sagas';
import { catalogSearchSaga } from '@client/catalog/redux/search/sagas';
import { skuListSearchSaga } from '@client/catalog/redux/skuList/sagas';
import { suggestionsSaga } from '@client/catalog/redux/suggestions/sagas';
import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';

export function* catalogSaga(): Generator<AllEffect<ForkEffect>> {
  yield all([
    fork(catalogSearchSaga),
    fork(barcodeSearchSaga),
    fork(inkFinderSaga),
    fork(skuListSearchSaga),
    fork(suggestionsSaga),
  ]);
}
