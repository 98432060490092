import { ReactElement, useState } from 'react';
import { useCheckPermissions, useLoginAction, useUserData } from '@client/context/hooks';
import { PERMISSION } from 'b2b-common/core/context/Context.types';
import { AddToWishlistAction } from './AddToWishlistAction';
import { Props } from './AddToWishlistActionContainer.types';

export const AddToWishlistActionContainer = ({
  skus = [],
  styleType,
  className,
}: Props): ReactElement => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const { isLoggedIn } = useUserData();
  const hasUserWishlistAccessPermission = useCheckPermissions([PERMISSION.accessWishlist]);
  const redirectToLogin = useLoginAction();

  const handleModalOpen = (): void => {
    if (!isLoggedIn) {
      return redirectToLogin();
    }

    setIsModalOpened(true);
  };

  const handleModalClose = () => {
    setIsModalOpened(false);
  };

  return (
    <AddToWishlistAction
      onModalOpen={handleModalOpen}
      onModalClose={handleModalClose}
      isModalOpened={isModalOpened}
      skus={skus}
      isLoggedIn={isLoggedIn}
      hasUserWishlistAccessPermission={hasUserWishlistAccessPermission}
      styleType={styleType}
      className={className}
    />
  );
};
