import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { analyzeProductsListView } from '@client/analytics/redux';
import { EVENT_SOURCE } from '@client/analytics/types/types';
import { categorizeProductGroupV2 } from '@client/analytics/utils/categorizeProductGroupV2';
import { getProductsSkusFromContentDataProductsGroupV2 } from '@client/analytics/utils/getProductsSkusFromContentDataProductsGroupV2';
import { ModularPageProductsGroupContext } from '@client/content/components/ModularPage/Context/ModularPageProductsGroupContext';
import { useProductsFromMultipleQueries } from '@client/content/hooks';
import { useProductTilesData } from '@client/product/hooks';
import { useRouterUrl } from '@client/routes/hooks';
import { ContentEntryType } from 'b2b-common/core/content/Content.types';

export interface ProductListType {
  skus: string[];
  position: number;
}

export const useCmsAnalytics = (content: ContentEntryType): void => {
  const url = useRouterUrl();
  const dispatch = useDispatch();
  const { skus, setSkus } = useContext(ModularPageProductsGroupContext);
  const [areAnalyticsEnabled, setAreAnalyticsEnabled] = useState(false);
  const [queriesData, setQueriesData] = useState<ContentEntryType[]>([]);
  const productsFromMultipleQueries = useProductsFromMultipleQueries(queriesData);
  const productTilesData = useProductTilesData(skus);
  const areProductsTilesLoaded = skus.every(sku => productTilesData[sku]?.isLoaded);

  useEffect(() => {
    if (content?.data) {
      const { data } = content;
      const contentData = data?.container__main_content;
      const isModule = contentData?.some((item: ContentEntryType) => Object.hasOwn(item, 'container__module_products_group_v2'));
      const categorizeProducts = categorizeProductGroupV2(data, isModule);
      if (categorizeProducts?.queries?.length) {
        setQueriesData(categorizeProducts.queries);
      }
      const productsListFromQueries: ProductListType[] = productsFromMultipleQueries
        || [{ skus: [], position: 0 }];

      const productsListFromSkus: ProductListType[] = getProductsSkusFromContentDataProductsGroupV2(
        isModule,
        categorizeProducts.skus,
      ) || [{ skus: [], position: 0 }];
      const mergedProductsList = [...productsListFromQueries, ...productsListFromSkus]
        .filter((item: ProductListType) => item?.skus?.length)
        .sort((a: ProductListType, b: ProductListType) => a.position - b.position);

      const skusList: string[] = Object.values(mergedProductsList || [])
        .reduce((
          previousValue: string[],
          currentValue: ProductListType,
        ) => {
          const skus = [...previousValue, ...currentValue.skus];

          return skus;
        }, []);

      setSkus(skusList);
    }
  }, [queriesData.length]);

  useEffect(() => {
    setAreAnalyticsEnabled(true);
  }, []);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (areProductsTilesLoaded && skus.length && areAnalyticsEnabled) {
      timeout = setTimeout(() => {
        dispatch(analyzeProductsListView({ eventSource: EVENT_SOURCE.cms, skus }));
      }, 1500);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [areProductsTilesLoaded, areAnalyticsEnabled, skus.length, url]);
};
