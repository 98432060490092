import {
  addItemsToCartFulfilled as addCartItemsFulfilled,
  addItemsToCartRejected as addCartItemsRejected,
} from '@client/cart/redux/cart/redux';
import { fetchCartStatus } from '@client/cart/redux/cartStatus/redux';
import { requestApiSaga, retryRequestApiSaga } from '@client/common/redux/api/sagas';
import { takeLatestActionPerSku } from '@client/common/redux/effects/takeLatestActionPerSku';
import { reportError } from '@client/common/redux/errors/actions';
import { printPDFSaga } from '@client/common/redux/pdf/saga';
import { parseError } from '@client/common/utils/api/error';
import { MY_ACCOUNT_MESSAGE, showMyAccountToast } from '@client/myAccount/common/utils/messages';
import { Wishlist } from '@client/myAccount/wishlists/api/Wishlist';
import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import { StatusCodes } from 'http-status-codes';
import {
  all,
  AllEffect,
  call,
  CallEffect,
  delay,
  ForkEffect,
  put,
  PutEffect,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import emitter, { EVENT } from 'b2b-apps/core/common/utils/eventEmitter';
import { AddItemsToCartType } from 'b2b-common/core/cart/api/CartTypes';
import {
  addToWishlist,
  addWishlistItemsToCart,
  addWishlistItemsToCartFulfilled,
  addWishlistItemsToCartRejected,
  cloneWishlist,
  cloneWishlistFulfilled,
  cloneWishlistRejected,
  createAndAddToWishlist,
  createWishlist,
  delayedUpdateItemReference,
  exportWishlist,
  fetchWishlist,
  fetchWishlistFulfilled,
  fetchWishlistRejected,
  printWishlist,
  removeWishlist,
  removeWishlistItem,
  removeWishlistItems,
  removeWishlistItemsFulfilled,
  removeWishlistItemsRejected,
  sendWishlist,
  updateItemReference,
  updateItemReferenceFulfilled,
  updateItemReferenceRejected,
  updateWishlistItemQuantity,
  updateWishlistName,
  updateWishlistReference,
} from './redux';

const REFERENCE_UPDATE_DELAY = 1000;

function* fetchWishlistSaga(
  action: ReturnType<typeof fetchWishlist>,
): Generator<CallEffect | PutEffect, any, AxiosResponse> {
  const wishlistId = action.payload;

  try {
    const response = yield call(retryRequestApiSaga, Wishlist.fetch(wishlistId));
    yield put(fetchWishlistFulfilled({ wishlistId, data: response.data }));
  } catch (error) {
    yield put(fetchWishlistRejected({ wishlistId, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: { wishlistId } }));
  }
}

function* createWishlistSaga(action: ReturnType<typeof createWishlist>): Generator<CallEffect | PutEffect, any, void> {
  const data = action.payload;

  try {
    yield call(requestApiSaga, Wishlist.create(data));

    emitter.dispatch(EVENT.submitWishlistFulfilled);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.successCreateWishlist);
  } catch (error) {
    yield put(reportError({ message: action.type, error, additionalData: data }));

    emitter.dispatch(EVENT.submitWishlistRejected);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.errorCreateWishlist);
  }
}

function* createAndAddToWishlistSaga(
  action: ReturnType<typeof createAndAddToWishlist>,
): Generator<CallEffect | PutEffect, any, AxiosResponse> {
  const { name, skus } = action.payload;

  try {
    const response = yield call(requestApiSaga, Wishlist.create({ name }));
    const wishlistId = response.data.id;

    yield call(requestApiSaga, Wishlist.addItems(wishlistId, skus));

    emitter.dispatch(EVENT.addToWishlistFulfilled, { wishlistId, wishlistName: name });
  } catch (error) {
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));

    emitter.dispatch(EVENT.addToWishlistRejected);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.errorCreateAndAddToWishlist);
  }
}

function* cloneWishlistSaga(action: ReturnType<typeof cloneWishlist>):
  Generator<CallEffect | PutEffect, any, AxiosResponse> {
  const { wishlistId, name } = action.payload;

  try {
    const response = yield call(requestApiSaga, Wishlist.clone(wishlistId, name));
    yield put(cloneWishlistFulfilled(response.data));
    emitter.dispatch(EVENT.cloneWishlistFulfilled, response.data.wishlistId);
  } catch (error) {
    yield put(cloneWishlistRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* removeWishlistSaga(action: ReturnType<typeof removeWishlist>): Generator<CallEffect | PutEffect, any, void> {
  const wishlistId = action.payload;

  try {
    yield call(requestApiSaga, Wishlist.remove(wishlistId));

    emitter.dispatch(EVENT.removeWishlistFulfilled);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.successRemoveWishlist);
  } catch (error) {
    yield put(reportError({ message: action.type, error, additionalData: { wishlistId } }));

    emitter.dispatch(EVENT.removeWishlistRejected);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.errorRemoveWishlist);
  }
}

function* addToWishlistSaga(action: ReturnType<typeof addToWishlist>): Generator<CallEffect | PutEffect, any, void> {
  const { name, wishlistId, skus } = action.payload;

  try {
    yield call(requestApiSaga, Wishlist.addItems(wishlistId, skus));

    emitter.dispatch(EVENT.addToWishlistFulfilled, { wishlistId, wishlistName: name });
  } catch (error) {
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));

    const errorData = parseError(error);

    if (errorData.statusCode === StatusCodes.CONFLICT) {
      showMyAccountToast(MY_ACCOUNT_MESSAGE.duplicationErrorAddToWishlist);
    } else {
      showMyAccountToast(MY_ACCOUNT_MESSAGE.errorAddToWishlist);
    }

    emitter.dispatch(EVENT.addToWishlistRejected);
  }
}

function* removeWishlistItemsSaga(
  action: ReturnType<typeof removeWishlistItems>,
): Generator<CallEffect | PutEffect, any, void> {
  const { wishlistId, skus } = action.payload;

  try {
    yield call(requestApiSaga, Wishlist.removeWishlistItems(wishlistId, skus));
    showMyAccountToast(MY_ACCOUNT_MESSAGE.successRemoveItems);
    yield put(removeWishlistItemsFulfilled());
    yield put(fetchWishlist(wishlistId));
  } catch (error) {
    showMyAccountToast(MY_ACCOUNT_MESSAGE.errorRemoveWishlistItems);
    yield put(removeWishlistItemsRejected());
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* updateWishlistNameSaga(
  action: ReturnType<typeof updateWishlistName>,
): Generator<CallEffect | PutEffect, any, void> {
  const { wishlistId, data } = action.payload;

  try {
    yield call(requestApiSaga, Wishlist.updateName(wishlistId, data));

    emitter.dispatch(EVENT.submitWishlistFulfilled);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.successUpdateWishlist);
    yield put(fetchWishlist(wishlistId));
  } catch (error) {
    yield put(reportError({ message: action.type, error, additionalData: { wishlistId } }));

    emitter.dispatch(EVENT.submitWishlistRejected);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.errorUpdateWishlist);
  }
}

function* updateWishlistReferenceSaga(
  action: ReturnType<typeof updateWishlistReference>,
): Generator<CallEffect | PutEffect, any, void> {
  const { reference, wishlistId } = action.payload;

  try {
    yield call(requestApiSaga, Wishlist.updateReference(wishlistId, reference));
    yield put(fetchWishlist(wishlistId));
  } catch (error) {
    showMyAccountToast(MY_ACCOUNT_MESSAGE.errorUpdateWishlistReference);
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* updateWishlistItemQuantitySaga(
  action: ReturnType<typeof updateWishlistItemQuantity>,
): Generator<CallEffect | PutEffect, any, void> {
  const { wishlistId, sku, quantity } = action.payload;
  const itemQuantityData = {
    sku,
    quantity,
  };

  try {
    yield call(
      requestApiSaga,
      Wishlist.updateWishlistItemQuantity(wishlistId, itemQuantityData),
    );
    yield put(fetchWishlist(wishlistId));
  } catch (error) {
    showMyAccountToast(MY_ACCOUNT_MESSAGE.errorUpdateWishlistItem);
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* delayedUpdateItemReferenceSaga(action: ReturnType<typeof delayedUpdateItemReference>): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<any>
> {
  const { wishlistId, sku, reference } = action.payload;
  const itemReferenceData = {
    sku,
    reference,
  };

  try {
    yield call(
      requestApiSaga,
      Wishlist.updateWishlistItemReference(wishlistId, itemReferenceData),
    );
    yield put(updateItemReferenceFulfilled({ sku }));
    yield put(fetchWishlist(wishlistId));
  } catch (error) {
    yield put(updateItemReferenceRejected({ sku, error: parseError(error) }));
    showMyAccountToast(MY_ACCOUNT_MESSAGE.errorUpdateWishlistItem);
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* handleDelay(action: ReturnType<typeof updateItemReference>) : Generator<any | PutEffect<any>> {
  yield delay(REFERENCE_UPDATE_DELAY);
  yield put(delayedUpdateItemReference(action.payload));
}

function* removeWishlistItemSaga(
  action: ReturnType<typeof removeWishlistItem>,
): Generator<CallEffect | PutEffect, any, void> {
  const { wishlistId, sku } = action.payload;

  try {
    yield call(requestApiSaga, Wishlist.removeWishlistItem(wishlistId, sku));
    showMyAccountToast(MY_ACCOUNT_MESSAGE.successRemoveItem);
    yield put(fetchWishlist(wishlistId));
  } catch (error) {
    showMyAccountToast(MY_ACCOUNT_MESSAGE.errorRemoveWishlistItem);
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* addItemsToCartSaga(action: ReturnType<typeof addWishlistItemsToCart>):
  Generator<
    CallEffect | PutEffect,
    void,
    AxiosResponse<AddItemsToCartType>
  > {
  const { wishlistId, items } = action.payload;

  try {
    const response = yield call(requestApiSaga, Wishlist.wishlistToCart(wishlistId, items));
    yield put(addWishlistItemsToCartFulfilled(response.data));
    yield put(addCartItemsFulfilled(response.data));
    yield put(fetchCartStatus());
  } catch (error) {
    const parsedError = parseError(error);
    yield put(addWishlistItemsToCartRejected(parsedError));
    yield put(addCartItemsRejected(parsedError));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}


function* sendWishlistSaga(action: ReturnType<typeof sendWishlist>): Generator<CallEffect | PutEffect, void> {
  const { wishlistId, data } = action.payload;

  try {
    yield call(requestApiSaga, Wishlist.sendToSales(wishlistId, data));

    emitter.dispatch(EVENT.sendWishlistToSalesFulfilled);
  } catch (error) {
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
    emitter.dispatch(EVENT.sendWishlistToSalesRejected, parseError(error));
  }
}

function* printWishlistSaga(
  action: ReturnType<typeof printWishlist>,
): Generator<CallEffect | PutEffect, any, AxiosResponse> {
  const { content, fileName } = action.payload;

  try {
    yield call(printPDFSaga, content, fileName);
    emitter.dispatch(EVENT.printWishlistFulfilled);
  } catch (error) {
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
    emitter.dispatch(EVENT.printWishlistRejected, parseError(error));
  }
}

const GENERATE_CSV_DELAY = 1000;

function* exportWishlistCSVSaga(
  wishlistId: string,
  fileName: string,
): Generator<CallEffect, AxiosResponse, AxiosResponse> {
  const response = yield call(requestApiSaga, Wishlist.generateCSV(wishlistId));
  saveAs(
    new Blob([response.data], {
      type: 'text/csv;charset=utf-8;',
    }),
    `${fileName}.csv`,
  );
  return response;
}

function* exportWishlistSaga(
  action: ReturnType<typeof exportWishlist>,
): Generator<CallEffect | PutEffect, any, any> {
  const { wishlistId, fileName } = action.payload;

  try {
    yield call(exportWishlistCSVSaga, wishlistId, fileName);
    yield delay(GENERATE_CSV_DELAY);
    emitter.dispatch(EVENT.exportWishlistFulfilled);
  } catch (error) {
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
    emitter.dispatch(EVENT.exportWishlistRejected, parseError(error));
  }
}

export function* wishlistSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(fetchWishlist.type, fetchWishlistSaga),
    takeLatest(createWishlist.type, createWishlistSaga),
    takeLatest(createAndAddToWishlist.type, createAndAddToWishlistSaga),
    takeLatest(cloneWishlist.type, cloneWishlistSaga),
    takeLatest(removeWishlist.type, removeWishlistSaga),
    takeLatest(addToWishlist.type, addToWishlistSaga),
    takeLatest(removeWishlistItems.type, removeWishlistItemsSaga),
    takeLatest(updateWishlistName.type, updateWishlistNameSaga),
    takeLatest(updateWishlistReference.type, updateWishlistReferenceSaga),
    takeLatest(updateWishlistItemQuantity.type, updateWishlistItemQuantitySaga),
    takeLatestActionPerSku(updateItemReference.type, handleDelay),
    takeEvery(delayedUpdateItemReference.type, delayedUpdateItemReferenceSaga),
    takeLatest(removeWishlistItem.type, removeWishlistItemSaga),
    takeLatest(addWishlistItemsToCart.type, addItemsToCartSaga),
    takeLatest(sendWishlist.type, sendWishlistSaga),
    takeLatest(printWishlist.type, printWishlistSaga),
    takeLatest(exportWishlist.type, exportWishlistSaga),
  ]);
}
