import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductTileType } from 'b2b-common/core/product/api/ProductTile.types';

const initialState = RemoteRecordData.getDefault();

const NAME = 'similarProducts';

export const similarProductsSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState,
  reducers: {
    similarProductsFetch: (state, action: PayloadAction<string>) => RemoteRecordData.init(),
    similarProductsFulfilled: (state, action: PayloadAction<Record<string, ProductTileType>>) => (
      RemoteRecordData.fulfill(action.payload)
    ),
    similarProductsRejected: (state, action: { payload: ErrorType }) => (
      RemoteRecordData.reject(action.payload)
    ),
  },
});

export const {
  similarProductsFetch,
  similarProductsFulfilled,
  similarProductsRejected,
} = similarProductsSlice.actions;

const getState = (state: AppState) => state.product.similarProducts;

export const selectSimilarProducts = createSelector(
  getState,
  state => new RemoteRecord<Record<string, ProductTileType>>(state),
);

export const selectSimilarProduct = createSelector(
  [selectSimilarProducts, (state, sku) => sku],
  (state, sku) => state.data?.[sku] || null,
);

export const selectSimilarProductsRecords = createSelector(
  selectSimilarProducts,
  (state) => (skus: string[]) => skus
    .map(sku => state.data?.[sku])
    .filter(productTile => productTile)
    .reduce(
      (similarProducts, productTile) => ({
        ...similarProducts,
        [productTile.sku]: new RemoteRecord<ProductTileType>(RemoteRecordData.fulfill(productTile)),
      }),
      {},
    ),
);
