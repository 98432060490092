import { ReactElement } from 'react';
import { SCREENSHOT_TOOL_DATA_ATTR_MPT_ID } from '@client/common/screenshotTool';
import { isEmpty } from '@client/common/utils/empty/empty';
import { createKey } from '@client/common/utils/key/key';
import { Card as CardComponent } from '@lib/components/CMS/Card';
import classNames from 'classnames';
import { useLinkHandler } from '../useLinkHandler';
import { Props } from '../';

export const Card = ({ className, componentData }: Props): ReactElement | null => {
  const cardData = componentData.global__card && componentData.global__card.componentData;
  const handleClick = useLinkHandler();

  if (isEmpty(cardData) || !Array.isArray(cardData)) {
    return null;
  }

  return (
    <div className={classNames(className)}>
      {componentData.group_label && (
        <h2 className='cds-cms-SectionTitle'>{componentData.group_label}</h2>
      )}
      <div className='cds-cms-GridRow'>
        <div className='cds-cms-ModuleContainer'>
          <div className='cds-cms-GridRow'>
            {cardData.map(
              (card: Record<string, any>, index: number) => {
                const additionalProps = { ...card.mpt_id && { [SCREENSHOT_TOOL_DATA_ATTR_MPT_ID]: card.mpt_id } };

                return (
                  <CardComponent
                    {...additionalProps}
                    title={card.heading}
                    topHtmlText={card.top_text}
                    bottomHtmlText={card.bottom_text}
                    imageUrl={card.image ? card.image.url : undefined}
                    videoUrl={card.video_url || undefined}
                    cta={{
                      text: card.text_on_cta_button,
                      url: card.cta_url,
                    }}
                    onCtaClick={handleClick}
                    size={card.size}
                    key={createKey('card', index)}
                  />
                );
              },
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
