import { ReactElement } from 'react';
import { useTranslate } from '@client/i18n/hooks';
import classNames from 'classnames';
import { Icon, ICON_NAME } from 'b2b-components/theme/components/Icon';
import { Props } from './ServerFailMessage.types';
import './ServerFailMessage.scss';

export const ServerFailMessage = ({ isErrorTimeout, onReload, className }: Props): ReactElement => {
  const t = useTranslate();

  return (
    <div className={classNames('ServerFailMessage', className)}>
      <div className='ServerFailMessage-Icon'>
        <Icon name={ICON_NAME.alertWarning} />
      </div>

      <div className='ServerFailMessage-Content'>
        {isErrorTimeout ? (
          <>
            <div className='ServerFailMessage-TextLine'>
              {t('Sorry, content could not be loaded because connection has timed out.')}
            </div>
            <div
              className='ServerFailMessage-TextLine'
              dangerouslySetInnerHTML={{ __html: t('Try <span class="cds-Link ServerFailMessage-Link">refreshing</span> the page') }}
              onClick={onReload}
            />
          </>
        ) : (
          <>
            <div className="ServerFailMessage-TextLine">
              {t('Sorry, content could not be loaded because some of our services are temporary unavailable.')}
            </div>
            <div className='ServerFailMessage-TextLine'>
              {t('Our technical support has been notified about this issue.')}
            </div>
            <div className='ServerFailMessage-TextLine'>{t('Please check again in a few moments.')}</div>
          </>
        )}
      </div>
    </div>
  );
};
