import { TEALIUM_PROD_LIST } from '@client/analytics/types/types';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { selectWishlist } from '@client/myAccount/wishlists/redux/wishlist/redux';
import { call, CallEffect, select, SelectEffect } from 'redux-saga/effects';
import { WishlistDetailedType, WishlistItemType } from 'b2b-common/core/account/api/wishlists';
import { AnalyzeProductsInteraction } from '../types';
import { getInteractionProductFromCartItem } from '../utils';
import { analyzeProductAddToCartFromListInteraction, analyzeProductClickInteraction } from './interactions';

function* getWishlistItems(wishlistId: string):
  Generator<
    SelectEffect,
    WishlistItemType[],
    RemoteRecord<WishlistDetailedType>
  > {
  const wishlistRecord = yield select(selectWishlist, wishlistId);
  const wishlist = wishlistRecord.data;

  return wishlist?.items || null;
}

function* analyzeWishlistProductInteraction(
  interaction: AnalyzeProductsInteraction,
  sku: string,
  wishlistId: string,
  listPosition?: number,
  quantity?: number,
): Generator<
    CallEffect,
    void,
    WishlistItemType[]
  > {
  const items = yield call(getWishlistItems, wishlistId);

  if (!items?.length) {
    return;
  }

  const wishlistItem = items.find(item => item.sku === sku);

  yield call(
    interaction,
    TEALIUM_PROD_LIST.wishlist,
    {
      products: [{ ...getInteractionProductFromCartItem(wishlistItem), listPosition, quantity }],
    },
  );
}

export function* analyzeWishlistProductClick(
  sku: string,
  wishlistId: string,
  listPosition: number,
): Generator<CallEffect, void> {
  yield call(
    analyzeWishlistProductInteraction,
    analyzeProductClickInteraction,
    sku,
    wishlistId,
    listPosition,
  );
}

export function* analyzeWishlistProductAddToCart(
  sku: string,
  wishlistId: string,
  listPosition: number,
  quantity: number,
): Generator<CallEffect, void> {
  yield call(
    analyzeWishlistProductInteraction,
    analyzeProductAddToCartFromListInteraction,
    sku,
    wishlistId,
    listPosition,
    quantity,
  );
}

export function* analyzeWishlistProductsAddToCart(
  products: { sku: string, quantity: number, listPosition: number }[],
  wishlistId: string,
):
  Generator<
    CallEffect,
    void,
    WishlistItemType[]
  > {
  const items = yield call(getWishlistItems, wishlistId);

  if (!items?.length) {
    return;
  }

  const mergedProducts = products.reduce(
    (allProducts, product) => {
      const matchingProduct: WishlistItemType = items.find(({ sku }) => sku === product.sku);

      return matchingProduct
        ? [...allProducts, { ...getInteractionProductFromCartItem(matchingProduct), ...product }]
        : allProducts;
    },
    [],
  );

  yield call(
    analyzeProductAddToCartFromListInteraction,
    TEALIUM_PROD_LIST.wishlist,
    { products: mergedProducts },
  );
}
