import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { ProductAccessories } from '@client/product/api/ProductAccessories';
import {
  fetchProductAccessories,
  fetchProductAccessoriesFulfilled,
  fetchProductAccessoriesRejected,
} from '@client/product/redux/productAccessories/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { ProfileType } from 'b2b-common/core/context/Context.types';

function* fetchProductAccessoriesSaga(
  action: ReturnType<typeof fetchProductAccessories>,
): Generator<CallEffect | PutEffect, void, AxiosResponse & ProfileType> {
  const { sku } = action.payload;

  try {
    const response = yield call(requestApiSaga, ProductAccessories.fetch(sku));
    const productAccessories = ProductAccessories.mapResponseToState(
      response,
    );
    yield put(fetchProductAccessoriesFulfilled({ sku, data: productAccessories }));
  } catch (error) {
    yield put(fetchProductAccessoriesRejected({ sku, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* productAccessoriesSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeEvery(fetchProductAccessories.type, fetchProductAccessoriesSaga),
  ]);
}
