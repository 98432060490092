import { analyzeEvent } from '@client/analytics/redux/tealium/actions';
import { TEALIUM_INTERACTION_ID, TEALIUM_PROD_ACTION, TEALIUM_PROD_LIST } from '@client/analytics/types/types';
import {
  ProductInteractionAnalyticsProductsPropertyBuilder,
} from '@client/analytics/utils/builders/ProductInteractionAnalyticsProductsPropertyBuilder';
import {
  SearchAnalyticsSearchOssPropertyBuilder,
} from '@client/analytics/utils/builders/SearchAnalyticsSearchOssPropertyBuilder';
import { put, PutEffect } from 'redux-saga/effects';
import { InteractionData } from './types';

export function* analyzeProductsInteraction(
  interaction: TEALIUM_INTERACTION_ID,
  action: TEALIUM_PROD_ACTION | null,
  source: TEALIUM_PROD_LIST | null,
  interactionData: InteractionData,
): Generator<PutEffect, void> {
  const { products, search } = interactionData;
  const productBuilder = new ProductInteractionAnalyticsProductsPropertyBuilder();
  const ossBuilder = new SearchAnalyticsSearchOssPropertyBuilder();
  productBuilder
    .setAction(action)
    .setSource(source)
    .setProducts(products);

  yield put(analyzeEvent({
    interaction,
    ...productBuilder.build(),
    ...(search && {
      ...ossBuilder
        .setInteraction(products[0].quantity != null
          ? TEALIUM_INTERACTION_ID.productSearchAddToCart
          : TEALIUM_INTERACTION_ID.productSearchListClick)
        .setTerm(search.query)
        .setOriginalQuery(search.originalQuery)
        .setSortType(search.sortType)
        .setResultsPerPage(search.resultsPerPage)
        .setPage(search.page)
        .setProductsCount(search.productsCount)
        .setType(TEALIUM_PROD_LIST.search)
        .setListType(search.listType)
        .build(),
    }),
  }));
}
