import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { stringify } from '@client/common/utils/queryParams';

export class Credits {
  public static fetch(pageSize: number, startItem: number): RequestConfigType {
    const searchParams = {
      pageSize,
      startItem,
      unusedOnly: true,
    };
    return RequestConfig.get(`/account/credits?${stringify(searchParams)}`, { withLocalization: false });
  }
}
