import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { ProductVariantsMatrix } from '@client/product/api/ProductVariantsMatrix';
import {
  fetchProductVariantsMatrix,
  fetchProductVariantsMatrixFulfilled,
  fetchProductVariantsMatrixRejected,
} from '@client/product/redux/productVariantsMatrix/redux';
import { ProductVariantsMatrixType } from '@client/product/redux/productVariantsMatrix/types';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';

function* fetchProductVariantsMatrixSaga(
  action: ReturnType<typeof fetchProductVariantsMatrix>,
): Generator<CallEffect | PutEffect, void, AxiosResponse<ProductVariantsMatrixType>> {
  const { sku } = action.payload;

  try {
    const response = yield call(
      requestApiSaga,
      ProductVariantsMatrix.fetch(sku),
    );
    yield put(fetchProductVariantsMatrixFulfilled({ sku, data: response.data }));
  } catch (error) {
    yield put(fetchProductVariantsMatrixRejected({ sku, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* productVariantsMatrixSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeEvery(fetchProductVariantsMatrix.type, fetchProductVariantsMatrixSaga),
  ]);
}
