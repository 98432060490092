import { ReactElement } from 'react';
import { isEmpty } from '@client/common/utils/empty/empty';
import { createKey } from '@client/common/utils/key/key';
import { ModulesType } from '@client/content/components/ModularPage';
import { MODULAR_PAGE_COMPONENT } from '@client/content/utils/modules';

type ModularPageComponent = keyof typeof MODULAR_PAGE_COMPONENT;

export const getComponentsFromModules = (componentsData: ModulesType): ReactElement[] => {
  const getComponents = (object: ModulesType): ReactElement[] => {
    const components: ReactElement[] = [];

    object.forEach(
      (module) => {
        const { componentName } = module;
        const Component = MODULAR_PAGE_COMPONENT[componentName as ModularPageComponent];

        Component && module.componentData.forEach(
          (data: any) => {
            if (!isEmpty(data)) {
              components.push(
                <Component
                  componentData={data}
                  key={createKey('module', module.componentName, components.length)}
                />,
              );
            }
          },
        );
      },
    );

    return components;
  };

  return getComponents(componentsData);
};
