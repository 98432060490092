import { BARCODE_ACTIONS_VALUES, BARCODE_VALUE } from '@client/analytics/redux';
import { BarcodeAnalyticsProductType } from '@client/analytics/redux/barcode/types';
import { TEALIUM_PROD_ACTION } from '@client/analytics/types/types';
import { preparePrice, prepareStock } from '@client/analytics/utils/analytics';
import { ANALYTICS_ATTRIBUTES, BaseBuilder } from '@client/analytics/utils/builders/BaseBuilder';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { ProductTileType } from 'b2b-common/core/product/api/ProductTile.types';

export class BarcodeAnalyticsProductPropertyBuilder
  extends BaseBuilder<BarcodeAnalyticsProductType> {
  private barcodeAction: TEALIUM_PROD_ACTION;
  private productTile: RemoteRecord<ProductTileType>;

  protected attributes: ANALYTICS_ATTRIBUTES[] = [
    ANALYTICS_ATTRIBUTES.id,
    ANALYTICS_ATTRIBUTES.price,
    ANALYTICS_ATTRIBUTES.pos,
    ANALYTICS_ATTRIBUTES.stock,
    ANALYTICS_ATTRIBUTES.categories,
    ANALYTICS_ATTRIBUTES.action,
    ANALYTICS_ATTRIBUTES.list,
  ];

  protected analyticsResultObject: Record<string, any> = {
    [ANALYTICS_ATTRIBUTES.id]: '',
    [ANALYTICS_ATTRIBUTES.price]: '',
    [ANALYTICS_ATTRIBUTES.pos]: '',
    [ANALYTICS_ATTRIBUTES.stock]: '',
    [ANALYTICS_ATTRIBUTES.categories]: [],
    [ANALYTICS_ATTRIBUTES.action]: '',
    [ANALYTICS_ATTRIBUTES.list]: '',
  };

  public setBarcodeAction(action: TEALIUM_PROD_ACTION): BarcodeAnalyticsProductPropertyBuilder {
    this.barcodeAction = action;
    return this;
  }

  public setProductTile(productTile: RemoteRecord<ProductTileType>): BarcodeAnalyticsProductPropertyBuilder {
    this.productTile = productTile;
    return this;
  }

  public build(): any {
    if (this.barcodeAction === TEALIUM_PROD_ACTION.addToCartFromList) {
      this.analyticsResultObject.quan = '1';
      if (this.attributes.indexOf(ANALYTICS_ATTRIBUTES.quan) === -1) {
        this.attributes = [...this.attributes, ANALYTICS_ATTRIBUTES.quan];
      } else {
        this.attributes = this.attributes.filter((attr) => attr !== ANALYTICS_ATTRIBUTES.quan);
      }
    }
    this.analyticsResultObject.id = this.productTile.data.sku;
    this.analyticsResultObject.price = preparePrice(this.productTile.data.pricing.user.price);
    this.analyticsResultObject.pos = '1';
    this.analyticsResultObject.stock = prepareStock(this.productTile.data.settings.quantity);
    this.analyticsResultObject.categories = this.productTile.data.analytics.categories;
    this.analyticsResultObject.action = BARCODE_ACTIONS_VALUES[this.barcodeAction];
    this.analyticsResultObject.list = BARCODE_VALUE;

    return {
      [ANALYTICS_ATTRIBUTES.prod]: [super.build()] || {},
    };
  }
}
