import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useAssortmentLinks } from '@client/app/hooks';
import { useAssortment, useOwnAssortment } from '@client/assortment/hooks';
import { config } from '@client/config';
import { useEntry } from '@client/content/hooks';
import { useCheckPermissions, useLogoutAction, useUserData } from '@client/context/hooks';
import { getProfile } from '@client/context/redux/redux';
import { useActiveLanguage, useChangeLanguageAction, useTranslate } from '@client/i18n/hooks';
import { getAvailableLanguages, LANGUAGE_LABELS } from '@client/i18n/utils/language';
import { ROUTES } from '@client/routes/components/Router/routes';
import { useRouterPush, useUrlCreator } from '@client/routes/hooks';
import { openLink } from '@client/routes/utils/url';
import {
  ModalMenu as ModalMenuComponent,
  Props as ModalMenuProps,
  RecursiveLinksType,
} from '@lib/components/Header/ModalMenu';
import { TopBarGroupType } from '@lib/components/Header/TopBar';
import { PERMISSION } from 'b2b-common/core/context/Context.types';
import { LANGUAGE_CODE } from 'b2b-common/core/i18n/consts';
import { Props } from './ModalMenu.types';

export const ModalMenu = (props: Props): ReactElement => {
  const catalog = useAssortment();
  const ownAssortment = useOwnAssortment();
  const { catalogLinks } = useAssortmentLinks(catalog);
  const t = useTranslate();
  const activeLanguage = useActiveLanguage();
  const { isLoggedIn } = useUserData();
  const push = useRouterPush();
  const content = useEntry(config.contentStackIds.header);
  const profile = useSelector(getProfile);
  const changeLanguage = useChangeLanguageAction();
  const hasMyAccountPermissions = useCheckPermissions([
    PERMISSION.accessMyAccountDashboard,
  ]);
  const hasAccessWishlistPermissions = useCheckPermissions([
    PERMISSION.accessWishlist,
  ]);
  const urlCreator = useUrlCreator();
  const redirectToLogout = useLogoutAction();

  const contentLinks = !content.isLoaded || !content.data || !Array.isArray(content.data.group)
    ? []
    : content.data.group.map((group: TopBarGroupType<LANGUAGE_CODE>) => {
      if (group.links.length === 1) {
        const { href, title } = group.links[0];

        return {
          href,
          title,
        };
      }

      return {
        title: group.name,
        href: '#',
        children: group.links,
      };
    });

  const getLinks = (): RecursiveLinksType[][] => {
    const myAccountLinksGroup = [];

    if (isLoggedIn) {
      if (hasMyAccountPermissions) {
        myAccountLinksGroup.push({
          href: urlCreator(ROUTES.myAccount),
          title: t('My Account'),
        });
      }

      if (hasAccessWishlistPermissions) {
        myAccountLinksGroup.push({
          href: urlCreator(ROUTES.myAccountWishlists),
          title: t('Wishlists'),
        });
      }

      return [myAccountLinksGroup, contentLinks];
    }

    return [contentLinks];
  };

  const handleModalLinkClick = (url: string): void => {
    openLink(url, push);
  };

  const languages = getAvailableLanguages().map((languageCode: LANGUAGE_CODE) => ({
    code: languageCode,
    label: LANGUAGE_LABELS[languageCode],
  }));

  const text = {
    catalog: t('Assortment'),
    brands: t('Brands'),
    home: t('Home'),
    logout: t('Logout'),
    seeAll: t('See all'),
    ariaCloseModalMobile: t('Close menu'),
    ariaLabelMobile: t('Menu'),
  };

  return (
    <ModalMenuComponent
      {...
        {
          ...props,
          text,
          isLoggedIn,
          languages,
          activeLanguage,
          links: getLinks(),
          onLanguageChange: changeLanguage,
          onLogout: redirectToLogout,
          onModalLinkClick: handleModalLinkClick,
          catalogLinks,
          catalog,
          companyName: profile?.debitor?.companyName,
          ownAssortment,
        } as ModalMenuProps<LANGUAGE_CODE>}
    />
  );
};
