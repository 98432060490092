import { useFlyoutAnalytics } from '@client/analytics/hooks';
import { ASSORTMENT_FILTERS, FACET_FILTERS, FILTERS } from '@client/catalog/utils/facets';
import { stringify } from '@client/common/utils/queryParams';
import { useUserData } from '@client/context/hooks';
import { useTranslate } from '@client/i18n/hooks';
import { ROUTES } from '@client/routes/components/Router/routes';
import { useRouterPush, useUrlCreator } from '@client/routes/hooks';
import { openLink } from '@client/routes/utils/url';
import { ICON_NAME } from 'b2b-components/core/components/Icon';
import { LinksGroupType } from 'b2b-components/theme/components/Header/Flyout';

export type CatalogType = LinksGroupType[];

interface UseAssortmentReturnType {
  onLinkClick: (category: Record<string, any>, position: number) => void;
  catalogLinks: CatalogType;
}

export const useAssortmentLinks = (catalog: CatalogType): UseAssortmentReturnType => {
  const urlCreator = useUrlCreator();
  const push = useRouterPush();
  const flyoutAnalytics = useFlyoutAnalytics();
  const { isLoggedIn, profile: userProfile } = useUserData();
  const t = useTranslate();

  const handleLinkClick = (category: Record<string, any>, position: number) => {
    openLink(category.href, push);
    flyoutAnalytics({ path: category.path, url: category.href, position });
  };

  const getCatalogLinks = (): LinksGroupType[] => {
    if (!isLoggedIn || userProfile.hasOnlyCoreAssortment || !catalog.some(category => category.hasCoreAssortment)) {
      return [];
    }

    const coreAssortmentFilter = {
      [FILTERS.facetFilters]: {
        [FACET_FILTERS.customerAssortment]: [
          ASSORTMENT_FILTERS.coreAssortment,
        ],
      },
    };

    return [
      {
        title: t('Core assortment'),
        href: `${urlCreator(ROUTES.search)}?${stringify(coreAssortmentFilter)}`,
        iconName: ICON_NAME.coreAssortment,
        tooltipText: t('Browse selection of your core assortment products'),
      },
    ];
  };

  return {
    onLinkClick: handleLinkClick,
    catalogLinks: getCatalogLinks(),
  };
};
