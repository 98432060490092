import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Newsroom } from '@client/content/api/Newsroom';
import { fetchContentEntry } from '@client/content/redux/contentEntries/redux';
import {
  fetchNewsroomAssets,
  fetchNewsroomAssetsFulfilled,
  fetchNewsroomAssetsRejected,
  fetchNewsroomNews,
  fetchNewsroomNewsFulfilled,
  fetchNewsroomNewsRejected,
  fetchSingleNewsAssets,
} from '@client/content/redux/newsroom/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { NewsroomAssetsType, NewsroomNewsType } from 'b2b-common/core/content/Newsroom.types';

export const NEWSROOM_ASSETS_PAGE_SIZE = 16;
export const NEWSROOM_NEWS_PAGE_SIZE = 12;

function* fetchNewsroomAssetsSaga(
  action: ReturnType<typeof fetchNewsroomAssets>,
): Generator<CallEffect | PutEffect, void, AxiosResponse<NewsroomAssetsType>> {
  const { page } = action.payload;

  try {
    const response = yield call(requestApiSaga, Newsroom.fetchAssets(page || 1, NEWSROOM_ASSETS_PAGE_SIZE));
    yield put(
      fetchNewsroomAssetsFulfilled({ data: response.data }),
    );
  } catch (error) {
    yield put(fetchNewsroomAssetsRejected({ error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* fetchNewsroomNewsSaga(
  action: ReturnType<typeof fetchNewsroomNews>,
): Generator<CallEffect | PutEffect, void, AxiosResponse<NewsroomNewsType>> {
  const { page } = action.payload;

  try {
    const response = yield call(requestApiSaga, Newsroom.fetchNews(page || 1, NEWSROOM_NEWS_PAGE_SIZE));
    yield put(
      fetchNewsroomNewsFulfilled({ data: response.data }),
    );
  } catch (error) {
    yield put(fetchNewsroomNewsRejected({ error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* fetchSingleNewsAssetsSaga(
  action: ReturnType<typeof fetchSingleNewsAssets>,
): Generator<AllEffect<PutEffect> | PutEffect, void> {
  const { entriesIds } = action.payload;

  try {
    yield all(
      entriesIds.map((entryId) => put(fetchContentEntry(entryId))),
    );
  } catch (error) {
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* newsroomSaga(): Generator<AllEffect<ForkEffect>> {
  yield all([takeEvery(fetchNewsroomAssets.type, fetchNewsroomAssetsSaga)]);
  yield all([takeEvery(fetchNewsroomNews.type, fetchNewsroomNewsSaga)]);
  yield all([takeEvery(fetchSingleNewsAssets.type, fetchSingleNewsAssetsSaga)]);
}
