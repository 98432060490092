import { lazy } from 'react';
import { AccessCallbackDataType } from '@client/routes/components/Router/routes';
import { getLocalisedPaths } from '@client/routes/utils/paths';
import { PERMISSION } from 'b2b-common/core/context/Context.types';
import { DEFAULT_ROUTE } from './constants';
import { KANBAN_ROUTES_NAMES, KanbanRouteType } from './types';

export const kanban: KanbanRouteType = {
  [KANBAN_ROUTES_NAMES.kanban]: {
    ...DEFAULT_ROUTE,
    paths: getLocalisedPaths('kanban'),
    component: lazy(() => import(
      /* webpackChunkName: "kanban" */
      '@client/kanban/components/KanbanPage'
    )),
    allow: ({
      isLoggedIn,
      permissionsValidator,
    }: AccessCallbackDataType): boolean => isLoggedIn && permissionsValidator([PERMISSION.accessKanban]),
  },
  [KANBAN_ROUTES_NAMES.kanbanOrderConfirmation]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('kanban-order/confirmation'),
    component: lazy(() => import(
      /* webpackChunkName: "kanban-order/confirmation" */
      '@client/kanban/components/ConfirmationPage'
    )),
    allow: ({
      isLoggedIn,
      permissionsValidator,
    }: AccessCallbackDataType): boolean => isLoggedIn && permissionsValidator([PERMISSION.accessKanban]),
  },
  [KANBAN_ROUTES_NAMES.kanbanOrderFail]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('kanban-order/fail'),
    component: lazy(() => import(
      /* webpackChunkName: "kanban-order/fail" */
      '@client/kanban/components/FailPage'
    )),
    allow: ({
      isLoggedIn,
      permissionsValidator,
    }: AccessCallbackDataType): boolean => isLoggedIn && permissionsValidator([PERMISSION.accessKanban]),
  },
};
