import { ReactElement } from 'react';
import { FormFieldText, TEXT_STYLE_TYPE } from '@client/common/components/Form/FormFieldText';
import { ensureArray } from '@client/common/utils/array/array';
import { createKey } from '@client/common/utils/key/key';
import { useTranslate } from '@client/i18n/hooks';
import { ArrayHelpers, FieldArray, useField } from 'formik';
import { Button, BUTTON_ICON_POSITION, BUTTON_SIZE, BUTTON_STYLE_TYPE } from 'b2b-components/theme/components/Button';
import {
  BUTTON_ICON_ICON_NAME,
  BUTTON_ICON_SIZE,
  BUTTON_ICON_STYLE_TYPE,
  ButtonIcon,
} from 'b2b-components/theme/components/ButtonIcon';
import {
  BodyRowType,
  DATA_TABLE_BREAKPOINT,
  DataTable,
  HeadCellType,
} from 'b2b-components/theme/components/DataTable';
import { Icon, ICON_NAME, ICON_SIZE } from 'b2b-components/theme/components/Icon';
import { FORM_ELEMENT_TYPE } from '../../index';
import { Props } from './FormTable.types';
import './FormTable.scss';

export const FormTable = ({
  fieldName,
  element,
  fieldText,
  displayFormError,
  globalError,
}: Props): ReactElement => {
  const t = useTranslate();
  const {
    columns: elementColumns,
    fieldId,
    label,
    required,
  } = element;
  const columns = ensureArray(elementColumns);
  const [field, meta] = useField(fieldName);
  const values = field.value;
  const error = meta.error;
  const touched = meta.touched;

  const generateEmptyTableRow = (): BodyRowType => columns.reduce(
    (column, fieldName) => ({ ...column, [fieldName]: '' }),
    {},
  );

  const getDataTableHeader = (): HeadCellType[] => [
    ...columns.map((column) => ({
      value: column,
      mobileLabel: column,
    })),
    {
      value: '',
    },
  ];

  const getDataTableRows = (remove: <T>(index: number) => T | null | undefined): BodyRowType[] => (
    values.map((row: BodyRowType, index: number) => ({
      cells: [
        ...Object.keys(row).map((key) => ({
          className: 'cds-cms-Form-DataTableCell',
          value: (
            <FormFieldText
              innerComponentProps={{
                styleType: TEXT_STYLE_TYPE.outlined,
              }}
              name={`${fieldId}.${index}.${key}`}
            />
          ),
        })),
        {
          className: 'cds-cms-Form-DataTableCell--ButtonRemove',
          value: (
            <ButtonIcon
              icon={BUTTON_ICON_ICON_NAME.remove}
              size={BUTTON_ICON_SIZE.medium}
              styleType={BUTTON_ICON_STYLE_TYPE.ghost}
              onClick={() => {
                remove(index);
              }}
            />
          ),
        },
      ],
      subRow: {
        value: Array.isArray(error) && error[index] && touched && (
          <span className="cds-cms-Form-TableError">
            {displayFormError(fieldId, [error[index], touched])}
          </span>
        ),
      },
    })));

  const getAddRowButton = (push: (bodyRow: BodyRowType) => void): ReactElement => (
    <Button
      iconPosition={BUTTON_ICON_POSITION.left}
      size={BUTTON_SIZE.small}
      styleType={BUTTON_STYLE_TYPE.light}
      onClick={() => {
        push(generateEmptyTableRow());
      }}
    >
      <Icon name={ICON_NAME.plus} size={ICON_SIZE.xsmall} />
      {t('Add next item')}
    </Button>
  );

  const renderFormDataTable = ({ push, remove }: ArrayHelpers): ReactElement => (
    <DataTable
      breakpoint={DATA_TABLE_BREAKPOINT.xs}
      className="cds-cms-Form-DataTable"
      header={getDataTableHeader()}
      rows={getDataTableRows(remove)}
      additionOptions={{
        button: getAddRowButton(push),
      }}
    />
  );

  return (
    <div className="FormTable">
      <label className="cds-Label">
        <span>{label}</span>
        {required && (
          <span className="cds-Label-Required">{fieldText.required}</span>
        )}
      </label>

      <FieldArray
        name={fieldId}
        key={createKey(FORM_ELEMENT_TYPE.table, fieldId)}
      >
        {renderFormDataTable}
      </FieldArray>

      {globalError && touched && (
        <span className="cds-cms-Form-TableError">
          {displayFormError(fieldId, [globalError, touched])}
        </span>
      )}
    </div>
  );
};
