import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { isAxiosError } from 'axios';
import { FormDataType } from 'b2b-common/core/content/Content.types';

export class Forms {
  public static fetch(formId: string): RequestConfigType {
    return RequestConfig.get(
      `/content/forms/${formId}`,
    );
  }

  public static submit(formId: string, data: FormDataType): RequestConfigType {
    return RequestConfig.post(
      `/content/forms/${formId}/send`,
      data,
    );
  }
  // AxiosError<{ errors: any }

  public static mapResponseToErrorsState(error: unknown): ErrorType {
    if (isAxiosError(error)) {
      return error.response.data.errors;
    }

    return { statusCode: null, message: null };
  }

  public static mapFormDataToRequestBody(data: FormDataType): FormDataType {
    for (const [key, value] of Object.entries(data)) {
      if (typeof value === 'boolean') {
        data[key] = `${value}`;
      }
    }

    return data;
  }
}
