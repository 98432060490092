import { ensureArray } from '@client/common/utils/array/array';
import { logger } from '@client/common/utils/logger/logger';
import { LANGUAGE_CODE } from 'b2b-common/core/i18n/consts';
import { parseBoolean } from 'b2b-common/core/utils/env';
import packageJson from '../../../../../package.json';
import { ConfigType } from './config.types';

export const parseJSON = (json: string): any => {
  try {
    return JSON.parse(json);
  } catch (e: any) {
    logger.error('Could not parse process.env JSON value on client side', { json });
  }
};


export const config: ConfigType = Object.freeze({
  version: packageJson.version,
  languages: {
    available: ensureArray(parseJSON(process.env.REACT_APP_AVAILABLE_LANGUAGES)),
    default: LANGUAGE_CODE.de,
    fallback: LANGUAGE_CODE.en,
  },
  env: process.env.REACT_APP_ENV,
  shopName: process.env.REACT_APP_SHOP_NAME,
  emails: {
    support: process.env.REACT_APP_EMAIL_SUPPORT,
  },
  showsErrors: parseBoolean(process.env.REACT_APP_SHOW_ERRORS),
  url: {
    root: process.env.REACT_APP_URL_ROOT,
    api: process.env.REACT_APP_URL_API,
    cdn: {
      images: process.env.REACT_APP_URL_CDN_IMAGES,
      documents: process.env.REACT_APP_URL_CDN_DOCUMENTS,
    },
  },
  features: {
    cookieBanner: parseBoolean(process.env.REACT_APP_FEATURE_COOKIE_BANNER),
    newsletter: parseBoolean(process.env.REACT_APP_FEATURE_NEWSLETTER),
    newsletterForUnlogged: parseBoolean(process.env.REACT_APP_FEATURE_NEWSLETTER_FOR_UNLOGGED),
    expressOrder: parseBoolean(process.env.REACT_APP_FEATURE_EXPRESS_ORDER),
    priceDiscount: parseBoolean(process.env.REACT_APP_FEATURE_PRICE_DISCOUNT),
    searchEarlyFilter: parseBoolean(process.env.REACT_APP_FEATURE_SEARCH_EARLY_FILTER),
    selfRegistration: parseBoolean(process.env.REACT_APP_FEATURE_SELF_REGISTRATION),
    inkTonerFinder: parseBoolean(process.env.REACT_APP_FEATURE_INK_TONER_FINDER),
    procurement: parseBoolean(process.env.REACT_APP_FEATURE_PROCUREMENT),
    ownAssortment: parseBoolean(process.env.REACT_APP_FEATURE_CORE_ASSORTMENT),
  },
  selfRegistrationUrl: '/{language}/register',
  //Remove cookies and local storage values older (<) than given version
  storage: {
    cookie: {
      compatibility: {
        cart: process.env.REACT_APP_STORAGE_COOKIE_COMPATIBILITY_CART,
        language: process.env.REACT_APP_STORAGE_COOKIE_COMPATIBILITY_LANGUAGE,
        settings: process.env.REACT_APP_STORAGE_COOKIE_COMPATIBILITY_SETTINGS,
        message: process.env.REACT_APP_STORAGE_COOKIE_COMPATIBILITY_MESSAGE,
      },
    },
    localStorage: {
      compatibility: {
        clientToken: process.env.REACT_APP_STORAGE_LOCAL_STORAGE_COMPATIBILITY_CLIENT_TOKEN,
        userToken: process.env.REACT_APP_STORAGE_LOCAL_STORAGE_COMPATIBILITY_USER_TOKEN,
      },
    },
  },
  analytics: {
    disabledInteractions: ensureArray(parseJSON(process.env.REACT_APP_ANALYTICS_DISABLED_INTERACTIONS)),
  },
  abTesting: {
    disabledTests: ensureArray(parseJSON(process.env.REACT_APP_AB_TESTING_DISABLED_TESTS)),
  },
  sentry: {
    appId: process.env.REACT_APP_SENTRY_APP_ID,
    publicKey: process.env.REACT_APP_SENTRY_PUBLIC_KEY,
    sampleRate: parseInt(process.env.REACT_APP_SENTRY_SAMPLE_RATE),
  },
  guuru: {
    enabled: parseBoolean(process.env.REACT_APP_GUURU_ENABLED),
    debug: parseBoolean(process.env.REACT_APP_GUURU_DEBUG),
    appId: parseBoolean(process.env.REACT_APP_GUURU_ENABLED) ? process.env.REACT_APP_GUURU_APP_ID : null,
  },
  scandit: {
    enabled: parseBoolean(process.env.REACT_APP_SCANDIT_ENABLED),
    licenseKey: parseBoolean(process.env.REACT_APP_SCANDIT_ENABLED) ? process.env.REACT_APP_SCANDIT_LICENSE_KEY : null,
  },
  contentStackIds: {
    brandsMenu: process.env.REACT_APP_CONTENTSTACK_BRANDSMENU,
    footer: process.env.REACT_APP_CONTENTSTACK_FOOTER,
    header: process.env.REACT_APP_CONTENTSTACK_HEADER,
    infoUserRoles: process.env.REACT_APP_CONTENTSTACK_INFOUSERROLES,
    logoSlider: process.env.REACT_APP_CONTENTSTACK_LOGOSLIDER,
    userRoles: process.env.REACT_APP_CONTENTSTACK_USERROLES,
  },
});
