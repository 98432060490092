import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { FormValuesType } from '@client/myAccount/costCenters/components/Budgets/FormPage';
import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BudgetType, CostCenterBudgetsType } from 'b2b-common/core/account/api/costCenters/CostCenterBudget.types';

const NAME = 'budgets';

export const budgetSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {
    budget: RemoteRecordData.getDefault(),
    budgets: RemoteRecordData.getDefault(),
  },
  reducers: {
    fetchCostCenterBudget: (state, action: PayloadAction<{
      costCenterId: string,
      budgetId: string,
    }>) => {
      state.budget[action.payload.budgetId] = RemoteRecordData.init();
      return state;
    },
    fetchCostCenterBudgetFulfilled: (state, action: PayloadAction<{
      budgetId: string,
      data: BudgetType,
    }>) => {
      state.budget[action.payload.budgetId] = RemoteRecordData.fulfill(action.payload.data);
      return state;
    },
    fetchCostCenterBudgetRejected: (state, action: PayloadAction<{
      budgetId: string,
      error: ErrorType,
    }>) => {
      state.budget[action.payload.budgetId] = RemoteRecordData.reject(action.payload.error);
      return state;
    },
    fetchCostCenterBudgets: (state, action: PayloadAction<{
      costCenterId: string,
      page: number,
      pageSize?: number,
    }>) => {
      state.budgets[action.payload.costCenterId] = RemoteRecordData.init();
      return state;
    },
    fetchCostCenterBudgetsFulfilled: (state, action: PayloadAction<{
      costCenterId: string,
      data: CostCenterBudgetsType
    }>) => {
      state.budgets[action.payload.costCenterId] = RemoteRecordData.fulfill(action.payload.data);
      return state;
    },
    fetchCostCenterBudgetsRejected: (state, action: PayloadAction<{
      costCenterId: string,
      error: ErrorType;
    }>) => {
      state.budgets[action.payload.costCenterId] = RemoteRecordData.reject(action.payload.error);
      return state;
    },
  },
});

export const {
  fetchCostCenterBudget,
  fetchCostCenterBudgetFulfilled,
  fetchCostCenterBudgetRejected,
  fetchCostCenterBudgets,
  fetchCostCenterBudgetsFulfilled,
  fetchCostCenterBudgetsRejected,
} = budgetSlice.actions;

export const submitCostCenterBudget = createAction<{
  costCenterId: string,
  budgetId?: string,
  data: FormValuesType,
}>('submitCostCenterBudget');

export const removeCostCenterBudget = createAction<{
  costCenterId: string,
  budgetId: string,
  callback?:() => void,
    }>('removeCostCenterBudget');

const getState = (state: AppState) => state.myAccount.costCenter.budgets;

export const selectCostCenterBudgetById = createSelector(
  [getState, (state, costCenterId) => costCenterId],
  (state, costCenterId) => new RemoteRecord<BudgetType>(state.budget[costCenterId]),
);

export const selectCostCenterBudgetsById = createSelector(
  [getState, (state, costCenterId) => costCenterId],
  (state, costCenterId) => new RemoteRecord<CostCenterBudgetsType>(state.budgets[costCenterId]),
);
