import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { AppState } from '@client/common/redux/store';
import { fetchForm, selectFormElementsById } from '@client/content/redux/forms/redux';

export type UseFormElementsReturnType = RemoteRecord<any>;

export const useFormElements = (formId: string): UseFormElementsReturnType => {
  const dispatch = useDispatch();
  const form = useSelector((state: AppState) => selectFormElementsById(state, formId));

  useEffect((): void => {
    dispatch(fetchForm({ formId }));
  }, [formId]);

  return form;
};
