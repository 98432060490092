import { DEPRECATED_TEALIUM_PAGE_TYPE } from '@client/analytics/types/types';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { ContentEntryType } from 'b2b-common/core/content/Content.types';

type ContentRemoteType = RemoteRecord<ContentEntryType>;

export enum CONTENT_COMPONENTS {
  analytics = 'global__helper_analytics',
}

export enum CONTENT_FIELDS {
  title = 'title',
  pageType = 'page_type',
}

const getContentData = (
  content: ContentRemoteType,
): Record<string, any> | null => content?.data || null;

const getContentComponent = (
  content: ContentRemoteType,
  componentName: string,
): Record<string, any>[] | null => {
  const contentData = getContentData(content);
  return contentData?.[componentName] || null;
};

const getContentGlobalHelperAnalytics = (
  content: ContentRemoteType,
): Record<string, any> | null => {
  const globalHelperAnalytics = getContentComponent(
    content,
    CONTENT_COMPONENTS.analytics,
  );
  return globalHelperAnalytics?.[0] || null;
};

export const getContentPageType = (content: ContentRemoteType): string | null => {
  const globalHelperAnalytics = getContentGlobalHelperAnalytics(content);

  if (globalHelperAnalytics) {
    const pageType = globalHelperAnalytics[CONTENT_FIELDS.pageType];

    return DEPRECATED_TEALIUM_PAGE_TYPE[pageType] || pageType;
  }

  return null;
};
