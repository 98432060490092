import { ReactElement } from 'react';
import { Props, Toast as CoreToast, TOAST_TYPE } from '@lib/components/Toast';

export const Toast = (props: Props): ReactElement => (
  <CoreToast
    {...props}
    dataAttributes={{ 'data-testid': 'toast' }}
  />
);

export { TOAST_TYPE };
export type { Props };
