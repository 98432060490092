import { FC } from 'react';
import { emailRule } from '@client/common/utils/validations/validationRules';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Body } from './Body/Body';
import { Props } from './NewsletterForm.types';
import { FIELD_NAME as FIELD_NAME_ENUM } from './NewsletterFormContainer';

export const NewsletterForm: FC<Props> = (props: Props) => {
  const {
    email,
    onSubmit,
    text,
  } = props;
  const validationSchema = (): Record<string, any> => {
    const schema: Record<string, any> = {
      [FIELD_NAME_ENUM.emailNewsletter]: emailRule({
        errorEmailFormat: text.emailIsNotValid,
        errorEmailRequired: text.emailIsRequired,
      }),
    };

    return yup.object().shape(schema);
  };

  return (
    <Formik
      initialValues={{
        [FIELD_NAME_ENUM.emailNewsletter]: email || '',
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <Body {...props} />
    </Formik>
  );
};
