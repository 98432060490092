import { analyzeEvent } from '@client/analytics/redux/tealium/actions';
import { TEALIUM_PROD_LIST } from '@client/analytics/types/types';
import {
  ProductListAnalyticsProductPropertyBuilder,
} from '@client/analytics/utils/builders/ProductListAnalyticsProductPropertyBuilder';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { AppState } from '@client/common/redux/store';
import { selectProductsBySkus, selectProductTileBySku } from '@client/product/redux/products/tile/redux';
import { getLocation, RouterLocation } from 'connected-react-router';
import { call, CallEffect, put, PutEffect, select, SelectEffect } from 'redux-saga/effects';
import { ProductTileType } from 'b2b-common/core/product/api/ProductTile.types';
import { AnalyzeProductsInteraction } from '../types';
import { getInteractionProduct } from '../utils';
import { analyzeProductAddToCartFromListInteraction, analyzeProductClickInteraction } from './interactions';

function* getCMSEventSource(): Generator<SelectEffect, TEALIUM_PROD_LIST, RouterLocation<AppState>> {
  const location = yield select(getLocation);

  return `${TEALIUM_PROD_LIST.cms}${location.pathname || '/'}` as TEALIUM_PROD_LIST;
}

export function* analyzeCmsProductsListView(skus: string[]): Generator<
  PutEffect | SelectEffect | CallEffect,
  void,
  Record<string, RemoteRecord<ProductTileType>> & TEALIUM_PROD_LIST
  > {
  const products = yield select(selectProductsBySkus, skus);
  const pageType = yield call(getCMSEventSource);
  const builder = new ProductListAnalyticsProductPropertyBuilder();
  builder
    .setProductList(products)
    .setSkuList(skus?.map((sku) => ({ sku })))
    .setPage(1)
    .setPageSize(skus.length)
    .setPageType(pageType);

  yield put(analyzeEvent(
    builder.build(),
  ));
}

function* analyzeCMSProductInteraction(
  interaction: AnalyzeProductsInteraction,
  sku: string,
  listPosition: number,
  quantity?: number,
): Generator<
  SelectEffect | CallEffect,
  void,
  RemoteRecord<ProductTileType> & TEALIUM_PROD_LIST
  > {
  const productTileRecord = yield select(selectProductTileBySku, sku);
  const productTile = productTileRecord.data;

  if (!productTile) {
    return;
  }

  const eventSource = yield call(getCMSEventSource);

  yield call(
    interaction,
    eventSource,
    { products: [{ ...getInteractionProduct(productTile), listPosition, quantity }] },
  );
}

export function* analyzeCMSProductClick(sku: string, listPosition: number): Generator<CallEffect | void> {
  yield call(
    analyzeCMSProductInteraction,
    analyzeProductClickInteraction,
    sku,
    listPosition,
  );
}

export function* analyzeCMSProductAddToCart(sku: string, listPosition: number): Generator<CallEffect, void> {
  yield call(
    analyzeCMSProductInteraction,
    analyzeProductAddToCartFromListInteraction,
    sku,
    listPosition,
    1,
  );
}
