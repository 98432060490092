import { ReactElement } from 'react';
import { isEmpty } from '@client/common/utils/empty/empty';
import { CMSImageType, Gallery as GalleryComponent } from '@lib/components/CMS/Gallery';
import classNames from 'classnames';
import { Props } from '../';

export const Gallery = ({ className, componentData }: Props): ReactElement | null => {
  const images: CMSImageType[] = !Array.isArray(componentData.images) || isEmpty(componentData.images)
    ? []
    : componentData.images.map((image: Record<any, any>) => ({
      ...(image.caption && { caption: image.caption }),
      ...(image.image.title && { title: image.image.title }),
      url: image.image.url,
    }));

  if (!images.length) {
    return null;
  }

  return (
    <div className={classNames('cds-cms-GridRow', className)}>
      <div className="cds-cms-TextMediaContainer">
        <div className="GalleryModule">
          <GalleryComponent images={images} title={componentData.title} />
        </div>
      </div>
    </div>
  );
};
