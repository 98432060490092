import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { AxiosResponse } from 'axios';
import { CxmlCartResponseType } from 'b2b-common/core/punchout/cxml/cxml.types';

class CXMLPunchout {
  public static fetchCart(): RequestConfigType {
    return RequestConfig.get('/cart/cxml', {
      withLocalization: false,
    });
  }

  public static mapCartResponseToState(
    response: AxiosResponse,
  ): CxmlCartResponseType {
    return response.data;
  }
}

export { CXMLPunchout };
