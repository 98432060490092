import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Product } from '@client/product/api/Product';
import { fetchProduct, fetchProductFulfilled, fetchProductRejected } from '@client/product/redux/products/full/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { ProfileType } from 'b2b-common/core/context/Context.types';
import { ProductResponseType } from 'b2b-common/shop/product/api/Product.types';

function* fetchProductSaga(
  action: ReturnType<typeof fetchProduct>,
): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<ProductResponseType> & ProfileType
> {
  const { sku } = action.payload;

  try {
    const response = yield call(requestApiSaga, Product.fetch(sku));
    const product = Product.mapResponseToState(response);

    yield put(fetchProductFulfilled({ sku, data: product }));
  } catch (error) {
    yield put(fetchProductRejected({ sku, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* productsSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeEvery(fetchProduct.type, fetchProductSaga),
  ]);
}
