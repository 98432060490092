import { useSelector } from 'react-redux';
import { getFullLocation } from '@client/routes/utils/url';
import { getLocation, RouterRootState } from 'connected-react-router';
import { Location } from 'history';

export const useRouterUrl = (): string => {
  const location: Location = useSelector((state: RouterRootState) => getLocation(state));

  return getFullLocation(location);
};
