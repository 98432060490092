import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

const NAME = 'backorderSubscribe';

export const backorderSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: RemoteRecordData.getDefault(),
  reducers: {
    backorderSubscribe: (state, action: PayloadAction<{
      email: string;
      isSubscribed: boolean;
    }>) => RemoteRecordData.init(),
    backorderSubscribeFulfilled: (state, action: PayloadAction<{
      email: string;
      isSubscribed: boolean;
    }>) => RemoteRecordData.fulfill(action.payload),
    backorderSubscribeRejected: (state, action: PayloadAction<ErrorType>) => RemoteRecordData.reject(action.payload),
    clearBackorderSubscribe: () => RemoteRecordData.getDefault(),
  },
});

export const {
  backorderSubscribe,
  backorderSubscribeFulfilled,
  backorderSubscribeRejected,
  clearBackorderSubscribe,
} = backorderSlice.actions;

const getState = (state: AppState) => state.myAccount.orders.backorderSubscribe;

export const selectBackorderSubscribe = createSelector(
  getState,
  (state) => new RemoteRecord<{ email: string; isSubscribed: boolean; }>(state),
);
