import { ReactElement } from 'react';
import { Props as TopBarProps, TopBar as TopBarComponent } from 'b2b-components/theme/components/Header/TopBar';
import { LANGUAGE_CODE } from 'b2b-common/core/i18n/consts';
import { Props } from './TopBar.types';

export const TopBar = (props: Props): ReactElement => {
  const topBarProps = {
    ...props,
    linksDataAttributes: {
      'data-testid': 'headerLanguageDropdown',
    },
    langDataAttributes: {
      'data-testid': 'headerLanguageSelector',
    },
    dropdownDataAttributes: {
      'data-testid': 'headerLanguageDropdownMenu',
    },
  } as TopBarProps<LANGUAGE_CODE>;

  return (
    <TopBarComponent {...topBarProps} />
  );
};
