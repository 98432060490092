export enum ANALYTICS_ATTRIBUTES {
  action = 'action',
  categories = 'categories',
  id = 'id',
  pos = 'pos',
  price = 'price',
  stock = 'stock',
  list = 'list',
  quan = 'quan',
  purchasable = 'purchasable',
  attribute1 = 'attribute1',
  attribute2 = 'attribute2',
  attribute3 = 'attribute3',
  attribute4 = 'attribute4',
  attribute5 = 'attribute5',
  attribute6 = 'attribute6',
  location = 'location',
  innerlocation = 'innerlocation',
  category = 'category',
  subcategory = 'subcategory',
  revenue = 'revenue',
  shipping = 'shipping',
  smallQuantitySurcharge = 'smallQuantitySurcharge',
  payment = 'payment',
  interaction = 'interaction',
  oss = 'oss',
  page = 'page',
  prod = 'prod',
  component = 'component',
  server = 'server',
  order = 'order',
  checkout = 'checkout',
  virtualPageView = 'virtualPageView',
  hasService = 'hasService',
  vat = 'vat',
  interactionLabel = 'interactionLabel',
  term = 'term',
  type = 'type',
  originalQuery = 'originalQuery',
  listType = 'listType',
  sortType = 'sortType',
  resultsPerPage = 'resultsPerPage',
  resultsCount = 'resultsCount',
  productsCount = 'productsCount',
  pageIndex = 'pageIndex',
}

interface AnalyticsResultObjectCombined {
  attributes: ANALYTICS_ATTRIBUTES[];
  analyticsResultObject: Record<string, any>;
}

export class BaseBuilder<T> {
  // list of all attributes used by analytics.
  // should be overwritten by child classes to include only the attributes they need.
  protected attributes: ANALYTICS_ATTRIBUTES[] = [
    ...Object.values(ANALYTICS_ATTRIBUTES),
  ];

  protected analyticsResultObject: Record<string, any>;
  protected analyticsResultObjectArray: AnalyticsResultObjectCombined[];

  protected build(): T | T[] | null {
    if (this.analyticsResultObjectArray?.length) {
      return this.analyticsResultObjectArray.map((object, index) => (
        this.analyticsResultObjectArray[index].attributes.reduce((accumulator, currentValue) => (
          { ...accumulator, [currentValue]: object.analyticsResultObject[currentValue] }
        ), {} as T)
      ));
    }

    if (this.analyticsResultObject) {
      return this.attributes?.reduce(
        (accumulator, currentValue) => currentValue != null
          ? { ...accumulator, [currentValue]: this.analyticsResultObject[currentValue] }
          : accumulator,
          {} as T,
      ) || {} as T;
    }

    return null;
  }
}
