import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { ProductGallery } from '@client/product/api/ProductGallery';
import {
  fetchProductGallery,
  fetchProductGalleryFulfilled,
  fetchProductGalleryRejected,
} from '@client/product/redux/productGallery/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { ProductGalleryType } from 'b2b-common/core/product/api/ProductGallery.types';

function* fetchProductGallerySaga(
  action: ReturnType<typeof fetchProductGallery>,
): Generator<CallEffect | PutEffect, void, AxiosResponse<ProductGalleryType>> {
  const { sku } = action.payload;

  try {
    const response = yield call(requestApiSaga, ProductGallery.fetch(sku));
    const { data } = response;
    yield put(
      fetchProductGalleryFulfilled({ sku, data }),
    );
  } catch (error) {
    yield put(fetchProductGalleryRejected({ sku, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* productGallerySaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeEvery(fetchProductGallery.type, fetchProductGallerySaga),
  ]);
}
