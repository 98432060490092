import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { DatatransInitRequestBodyType, UpdateCheckoutRequestBodyType } from 'b2b-common/core/checkout/Checkout.types';
import { CheckoutDetails } from 'b2b-common/core/checkout/CheckoutDetails.types';

export class Checkout {
  public static init(): RequestConfigType {
    return RequestConfig.post('/checkout/init', {},
      { withLocalization: true });
  }

  public static datatransInit(
    checkoutId: string,
    callbackUrls: Record<string, any>,
    checkoutDetails: CheckoutDetails,
  ): RequestConfigType {
    const data: DatatransInitRequestBodyType = {
      urls: callbackUrls,
      checkoutDetails,
    };

    return RequestConfig.post(`/checkout/${checkoutId}/datatrans-init`, data,
      { withLocalization: true });
  }

  public static update(checkoutId: string, data: UpdateCheckoutRequestBodyType): RequestConfigType {
    return RequestConfig.patch(`/checkout/${checkoutId}`, data,
      { withLocalization: true });
  }

  public static getCostCenter(costCenterId: string): RequestConfigType {
    return RequestConfig.get(
      `/checkout/cost-center/${costCenterId}`,
      { withLocalization: false },
    );
  }
}
