import { config } from '@client/config';
import {
  Barcode,
  BarcodeCapture,
  barcodeCaptureLoader,
  BarcodeCaptureOverlay,
  BarcodeCaptureSession,
  BarcodeCaptureSettings,
  Symbology,
} from 'scandit-web-datacapture-barcode';
import {
  Camera,
  CameraSwitchControl,
  configure,
  DataCaptureContext,
  DataCaptureView,
  FrameSourceState,
  Logger,
  RectangularViewfinder,
  RectangularViewfinderLineStyle,
  RectangularViewfinderStyle,
} from 'scandit-web-datacapture-core';

interface Props {
  captureElementId: string;
  onScan: (value: string) => void;
  onCameraAccessDenied: () => void;
  onReady?: () => void;
}

export const useCodeScanner = ({
  captureElementId,
  onScan,
  onReady,
  onCameraAccessDenied,
}: Props): { init: () => Promise<void> } => {
  const init = async () => {
    const view = new DataCaptureView();

    view.connectToElement(document.getElementById(captureElementId));

    view.showProgressBar();
    view.setProgressBarMessage('Accessing Camera...');

    await configure({
      licenseKey: config.scandit.licenseKey,
      libraryLocation: '/assets/scandit/',
      moduleLoaders: [barcodeCaptureLoader()],
      logLevel: Logger.Level.Error,
    });

    view.setProgressBarPercentage(null);

    const context: DataCaptureContext = await DataCaptureContext.create();

    await view.setContext(context);

    const camera: Camera = Camera.default;
    const cameraSettings = BarcodeCapture.recommendedCameraSettings;
    await camera.applySettings(cameraSettings);
    await context.setFrameSource(camera);

    const settings: BarcodeCaptureSettings = new BarcodeCaptureSettings();

    settings.enableSymbologies([
      Symbology.EAN13UPCA,
      Symbology.EAN8,
      Symbology.UPCE,
      Symbology.QR,
      Symbology.DataMatrix,
      // Symbology.Code39, // todo - we need Code39 as well, we are waiting for license changes
      Symbology.Code128,
    ]);

    // Some linear/1D barcode symbologies allow you to encode variable-length data. By default, the Scandit
    // Data Capture SDK only scans barcodes in a certain length range. If your application requires scanning of one
    // of these symbologies, and the length is falling outside the default range, you may need to adjust the "active
    // symbol counts" for this symbology. This is shown in the following few lines of code for one of the
    // variable-length symbologies.
    // const symbologySettings: SymbologySettings = settings.settingsForSymbology(Symbology.Code39); // todo
    // symbologySettings.activeSymbolCounts = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]; // todo

    const barcodeCapture = await BarcodeCapture.forContext(context, settings);
    await barcodeCapture.setEnabled(false);

    view.addControl(new CameraSwitchControl());

    const barcodeCaptureOverlay: BarcodeCaptureOverlay = await BarcodeCaptureOverlay.withBarcodeCaptureForView(
      barcodeCapture,
      view,
    );

    barcodeCapture.addListener({
      didScan: async (barcodeCaptureMode: BarcodeCapture, session: BarcodeCaptureSession) => {
        await barcodeCapture.setEnabled(false);
        await barcodeCaptureOverlay.setViewfinder(null);
        const barcode: Barcode = session.newlyRecognizedBarcodes[0];
        onScan(barcode.data);
      },
    });

    const viewfinder: RectangularViewfinder = new RectangularViewfinder(
      RectangularViewfinderStyle.Square,
      RectangularViewfinderLineStyle.Light,
    );
    await barcodeCaptureOverlay.setViewfinder(viewfinder);

    try {
      await camera.switchToDesiredState(FrameSourceState.On);
      onReady();
    } catch (error) {
      onCameraAccessDenied();
    }
    await barcodeCapture.setEnabled(true);

    view.hideProgressBar();
  };

  return { init };
};
