import { usePermissionsValidator, useUserData } from '@client/context/hooks';
import { useActiveLanguage } from '@client/i18n/hooks';
import { AccessCallbackDataType } from '@client/routes/components/Router/routes';

export const useAccessCallbackData = (): AccessCallbackDataType => {
  const permissionsValidator = usePermissionsValidator();
  const { isLoggedIn, isBlocked } = useUserData();
  const activeLanguage = useActiveLanguage();

  return { activeLanguage, isLoggedIn, isBlocked, permissionsValidator };
};
