import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';
import { wishlistSaga } from 'b2b-apps/core/myAccount/wishlists/redux/wishlist/sagas';
import { wishlistsSaga } from 'b2b-apps/core/myAccount/wishlists/redux/wishlists/sagas';

export function* wishlistsSagas(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    fork(wishlistsSaga),
    fork(wishlistSaga),
  ]);
}
