import { analyzeEvent } from '@client/analytics/redux/tealium/actions';
import { TEALIUM_INTERACTION_ID, TEALIUM_PROD_LIST } from '@client/analytics/types/types';
import {
  ProductListAnalyticsProductPropertyBuilder,
} from '@client/analytics/utils/builders/ProductListAnalyticsProductPropertyBuilder';
import {
  SearchAnalyticsSearchOssPropertyBuilder,
} from '@client/analytics/utils/builders/SearchAnalyticsSearchOssPropertyBuilder';
import { DEFAULT_PER_PAGE } from '@client/catalog/hooks';
import { selectQuery, selectSearch } from '@client/catalog/redux/search/redux';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { selectProductsBySkus, selectProductTileBySku } from '@client/product/redux/products/tile/redux';
import { selectCatalogSettings } from '@client/settings/redux/redux';
import { SettingsCatalogType } from '@client/settings/redux/types';
import { PRODUCT_BOX_STYLE_TYPE } from '@lib/components/ProductList';
import { call, CallEffect, put, PutEffect, select, SelectEffect } from 'redux-saga/effects';
import { CatalogSearchType } from 'b2b-common/core/catalog/Catalog.types';
import { ProductTileType } from 'b2b-common/core/product/api/ProductTile.types';
import { AnalyzeProductsInteraction } from '../types';
import { getInteractionProduct } from '../utils';
import { analyzeProductAddToCartFromListInteraction, analyzeProductClickInteraction } from './interactions';

export function* analyzeCatalogProductListView():
  Generator<
    PutEffect | SelectEffect,
    void,
    & RemoteRecord<CatalogSearchType>
    & Record<string, RemoteRecord<ProductTileType>>
    & SettingsCatalogType
    > {
  const catalogRecord = yield select(selectSearch);
  const catalog = catalogRecord.data;

  if (!catalog) {
    return null;
  }

  const productTiles = yield select(selectProductsBySkus, catalogRecord.data.products.map(({ sku }) => sku));
  const query = yield select(selectQuery);
  const site = catalog?.site;
  const skuList = catalog?.products;
  const navigation = catalog?.navigation;
  const isLowerLevelCategory = navigation?.selected.length > 1;
  const pageSize = site?.listView.allowedProductsPerPage.find(page => page.current)?.numberOfProducts || 48;
  const page = site?.page || 1;
  const shouldIncludeSearchData = isLowerLevelCategory || query;
  const { viewType } = yield select(selectCatalogSettings);

  const productBuilder = new ProductListAnalyticsProductPropertyBuilder();
  productBuilder
    .setProductList(productTiles)
    .setSkuList(skuList)
    .setPage(page)
    .setPageSize(pageSize)
    .setPageType(TEALIUM_PROD_LIST.search);

  const ossBuilder = new SearchAnalyticsSearchOssPropertyBuilder();
  ossBuilder
    .setInteraction(TEALIUM_INTERACTION_ID.searchPageView)
    .setTerm('')
    .setResultsPerPage(pageSize)
    .setPage(page)
    .setListType(viewType || PRODUCT_BOX_STYLE_TYPE.list)
    .setType(TEALIUM_PROD_LIST.search)
    .setProductsCount(catalogRecord.data.productsCount || 0);

  yield put(analyzeEvent({
    ...productBuilder.build(),
    ...shouldIncludeSearchData && {
      ...ossBuilder.build(),
    },
  }));
}

function* analyzeCategoryProductInteraction(
  interaction: AnalyzeProductsInteraction,
  sku: string,
  listPosition: number,
  quantity?: number,
):
  Generator<
    CallEffect | SelectEffect,
    void,
    RemoteRecord<ProductTileType>
    & RemoteRecord<CatalogSearchType>
    & SettingsCatalogType
  > {
  const productTileRecord = yield select(selectProductTileBySku, sku);
  const productTile = productTileRecord.data;
  const catalogRecord = yield select(selectSearch);
  const catalog = catalogRecord.data;

  if (!productTile || !catalog) {
    return null;
  }

  const { navigation, site } = catalog;
  const numberOfProducts = site?.listView.allowedProductsPerPage.find(page => page.current)?.numberOfProducts;
  const pageSize = numberOfProducts || DEFAULT_PER_PAGE;
  const page = site?.page || 1;
  const isLowerLevelCategory = navigation.selected.length > 1;
  const { viewType } = yield select(selectCatalogSettings);

  yield call(
    interaction,
    TEALIUM_PROD_LIST.search,
    {
      products: [{ ...getInteractionProduct(productTile), listPosition, quantity }],
      ...isLowerLevelCategory && {
        search: {
          query: '',
          page,
          resultsPerPage: pageSize,
          listType: viewType || PRODUCT_BOX_STYLE_TYPE.list,
          productsCount: catalog.productsCount,
        },
      },
    },
  );
}

export function* analyzeCategoryProductClick(sku: string, listPosition: number): Generator<CallEffect | void> {
  yield call(
    analyzeCategoryProductInteraction,
    analyzeProductClickInteraction,
    sku,
    listPosition,
  );
}

export function* analyzeCategoryProductAddToCart(sku: string, listPosition: number): Generator<CallEffect, void> {
  yield call(
    analyzeCategoryProductInteraction,
    analyzeProductAddToCartFromListInteraction,
    sku,
    listPosition,
    1,
  );
}
