import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { RequestBillingAddressChangeType } from 'b2b-common/core/account/api/BillingAddress.types';

export class BillingAddress {
  public static update(data: RequestBillingAddressChangeType): RequestConfigType {
    return RequestConfig.put('/account/account-details/billing-address',
      data,
      { withLocalization: false });
  }
}
