import { coreAssortmentSaga } from '@client/product/redux/coreAssortment/sagas';
import { productAccessoriesSaga } from '@client/product/redux/productAccessories/sagas';
import { productGallerySaga } from '@client/product/redux/productGallery/sagas';
import { productPdfSaga } from '@client/product/redux/productPdf/sagas';
import { productReportErrorSaga } from '@client/product/redux/productReportError/sagas';
import { productsSaga } from '@client/product/redux/products/sagas';
import { productsCompareSaga } from '@client/product/redux/productsCompare/sagas';
import { productVariantsMatrixSaga } from '@client/product/redux/productVariantsMatrix/sagas';
import { similarProductsSaga } from '@client/product/redux/similarProducts/sagas';
import { stampConfiguratorSaga } from '@client/product/redux/stampConfigurator/sagas';
import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';

export function* productSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    fork(productAccessoriesSaga),
    fork(productGallerySaga),
    fork(productPdfSaga),
    fork(productsSaga),
    fork(productVariantsMatrixSaga),
    fork(productReportErrorSaga),
    fork(productsCompareSaga),
    fork(coreAssortmentSaga),
    fork(stampConfiguratorSaga),
    fork(similarProductsSaga),
  ]);
}
