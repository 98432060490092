import { ReactElement } from 'react';
import { SCREENSHOT_TOOL_DATA_HIDE } from '@client/common/screenshotTool';
import { Alert, ALERT_DISPLAY_TYPE, ALERT_STYLE_TYPE } from 'b2b-components/theme/components/Alert';
import { ComponentType, Props } from './';
import './Infobar.scss';

const Infobar: ComponentType = ({
  title,
  styleType,
  displayType = ALERT_DISPLAY_TYPE.notification,
  message,
  onDismiss,
}: Props): ReactElement => (
  <div
    className="Infobar-AlertContainer"
    {...SCREENSHOT_TOOL_DATA_HIDE}
>
    <Alert
      title={title}
      styleType={ALERT_STYLE_TYPE[styleType]}
      displayType={ALERT_DISPLAY_TYPE[displayType]}
      isDismissible={true}
      onClose={onDismiss}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: message,
        }}
      />
    </Alert>
  </div>
);

export {
  ALERT_DISPLAY_TYPE as DISPLAY_TYPE,
  Infobar,
  ALERT_STYLE_TYPE as STYLE_TYPE,
};
