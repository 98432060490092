import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { CollectedOrderDataType } from 'b2b-common/core/collectedOrder/CollectedOrder.types';

export class CollectedOrder {
  public static place(data: CollectedOrderDataType): RequestConfigType {
    const { licenseHolder, references } = data;

    return RequestConfig.post(
      '/collect-order',
      {
        reference: references.order,
        remarks: references.sales,
        licenceHolderData: licenseHolder.licenseHolder,
      },
      {
        withLocalization: false,
      },
    );
  }
}
