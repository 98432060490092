import { USER_ROLE } from '../identity';

export enum PERMISSION {
  accessCart = 'access_cart',
  accessCheckout = 'access_checkout',
  accessCheckoutCollectOrder = 'access_checkout_collect_order',
  accessCostCenter = 'access_cost_center',
  accessKanban = 'access_kanban',
  accessMyAccountAddresses = 'access_my_account_addresses',
  accessMyAccountBalance = 'access_my_account_balance',
  accessMyAccountBasic = 'access_my_account_basic',
  accessMyAccountCredit = 'access_my_account_credit',
  accessMyAccountDashboard = 'access_my_account_dashboard',
  accessMyAccountDeliveredOrders = 'access_my_account_delivered_orders',
  accessMyAccountInvoices = 'access_my_account_invoices',
  accessMyAccountNewsletters = 'access_my_account_newsletters',
  accessMyAccountOrders = 'access_my_account_orders',
  accessMyAccountRma = 'access_my_account_rma',
  accessMyAccountUserBillingAddressChange = 'access_my_account_billing_address_change',
  accessMyAccountUserContactInformationChange = 'access_my_account_edit_contact_information',
  accessMyAccountUserManagement = 'access_my_account_user_management',
  accessMyAccountUserRole = 'access_my_account_user_role',
  accessWishlist = 'access_wishlist',
  changePasswordLink = 'change_password_link',
  costCenterCanReview = 'cost_center_can_review',
  costCenterCreate = 'cost_center_create',
  cxml = 'cxml',
  editCxmlCart = 'edit_cxml_cart',
  myAccountDeleteUser = 'my_account_delete_user',
  myAccountEditUser = 'my_account_edit_user',
  oci = 'oci',
  productPdfExport = 'pdf_export',
  seeUserPrice = 'see_user_price',
}

export type ProfileType = null | {
  userId: string;
  email: string;
  emailSha256Hash: string;
  emailMd5Hash: string;
  debitor: {
    companyName: string;
  },
  debitorId: string;
  debitorIdMd5Hash: string;
  role: USER_ROLE;
  permissions: string[];
  certificates: string[];
  isBlocked: boolean;
  isEndCustomer: boolean;
  isFirstOrder: boolean;
  hasOnlyCoreAssortment: boolean;
  customerType: string;
  customerTypeGroup: string;
  sid?: string;
};

export type ProfileDetailsType = {
  isLoggedIn: boolean;
  debitorId: string | null;
  role: USER_ROLE | null;
  permissions: string[];
  certificates: string[];
  isBlocked: boolean;
  isEndCustomer: boolean;
  isFirstOrder: boolean,
};

export type ContextTypes = {
  isLoggedIn: boolean,
  profile: ProfileType;
  sid: string,
};
