import { requestApiSaga, retryRequestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Newsletters } from '@client/myAccount/newsletters/api/Newsletters';
import {
  fetchNewsletters,
  fetchNewslettersFulfilled,
  fetchNewslettersRejected,
  updateNewsletters,
  updateNewslettersFulfilled,
  updateNewslettersRejected,
} from '@client/myAccount/newsletters/redux/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { NewslettersType } from 'b2b-common/core/account/api/Newsletters.types';

function* fetchNewslettersSaga(
  action: ReturnType<typeof fetchNewsletters>,
): Generator<CallEffect | PutEffect, void, AxiosResponse<NewslettersType>> {
  try {
    const response = yield call(retryRequestApiSaga, Newsletters.fetch());

    yield put(fetchNewslettersFulfilled(response.data));
  } catch (error) {
    yield put(fetchNewslettersRejected(parseError(error)));
    yield put(reportError({ message: action.type, error }));
  }
}

function* updateNewslettersSaga(action: ReturnType<typeof updateNewsletters>): Generator<CallEffect | PutEffect, void> {
  const newsletters = action.payload;

  try {
    yield call(requestApiSaga, Newsletters.update(newsletters));
    yield put(updateNewslettersFulfilled());
  } catch (error) {
    yield put(updateNewslettersRejected(parseError(error)));
    yield put(reportError({ message: action.type, error }));
  }
}

export function* newslettersSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(fetchNewsletters.type, fetchNewslettersSaga),
    takeLatest(updateNewsletters.type, updateNewslettersSaga),
  ]);
}
