import { createContext, ReactElement, ReactNode, useContext } from 'react';
import { useDispatch } from 'react-redux';
import {
  analyzeContentForm,
} from '@client/analytics/redux';
import { bindActionCreators } from '@reduxjs/toolkit';

interface SearchAnalyticsContextValue {
  analyzeContentForm: typeof analyzeContentForm;
}

const ContentAnalyticsContext = createContext<SearchAnalyticsContextValue | undefined>(undefined);

export const ContentAnalyticsProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const dispatch = useDispatch();

  return (
    <ContentAnalyticsContext.Provider value={{
      analyzeContentForm: bindActionCreators(analyzeContentForm, dispatch),
    }}>
      {children}
    </ContentAnalyticsContext.Provider>
  );
};

export const useContentAnalytics = (): SearchAnalyticsContextValue => {
  const context = useContext(ContentAnalyticsContext);

  if (context === undefined) {
    throw new Error('useContentAnalytics must be used within a ContentAnalyticsProvider');
  }

  return context;
};
