import {
  CHECKOUT_STEP,
  CHECKOUT_TYPE,
  OSS_INTERACTION,
  OSS_INTERACTION_LABEL,
  SEARCH_ACTION,
  SEARCH_COMPONENT_CATEGORY,
} from '@client/analytics/redux';
import { PRODUCT_BOX_STYLE_TYPE } from '@lib/components/ProductList';
import { StatusCodes } from 'http-status-codes';

export type BaseAnalyticsType = string[] | ['']
export const ZERO = 'zero';

export type OssDataLayerType = {
  interaction: OSS_INTERACTION;
  interactionLabel: OSS_INTERACTION_LABEL | string;
  searchPasses?: string;
  resultsPerPage?: string;
  pageIndex?: string;
  listType?: PRODUCT_BOX_STYLE_TYPE;
  resultsCount?: string;
  term: string;
  type: TEALIUM_PROD_LIST;
  suggestionSource?: string;
  originalQuery?: string;
}

export enum ANALYTICS_BOOLEAN {
  false = 'n',
  true = 'y',
}

export enum TEALIUM_PAGE_TYPE {
  account = 'Account',
  blog = 'Blog',
  brand = 'Brand',
  cart = 'Cart',
  checkout = 'Checkout',
  company = 'Company',
  errorPage = 'ErrorPage',
  notAllowedPage = 'NotAllowed',
  notPermittedPage = 'NotPermitted',
  homepage = 'Home',
  orderConfirmation = 'Confirmation',
  other = 'Other',
  productCategory = 'Category',
  productComparison = 'Comparison',
  productDetails = 'Product',
  productNotFound = 'ProductNotFound',
  productSearch = 'Search',
  productService = 'Service',
  productSubcategory = 'LowerLevelCategory',
  staticPage = 'StaticPage',
  expressOrder = 'ExpressOrder',
}

export enum DEPRECATED_TEALIUM_PAGE_TYPE {
  'Theme' = TEALIUM_PAGE_TYPE.staticPage,
}

export enum TEALIUM_INTERACTION_ID {
  addToCart = 'EC-ADD',
  addToCartFromList = 'EC-ADL',
  generic = 'GE-ALL',
  orderConfirmation = 'EC-ORD',
  productDetails = 'EC-PDV',
  removeFromCart = 'EC-REM',
  checkout = 'EC-CHS',
  cartView = 'EC-CRT',
  productList = 'EC-PLI',
  productListClick = 'EC-PCL',
  barcodeScan = 'BARCODE',
  searchPageView = 'IS-STPV',
  searchPageEmptyView = 'IS-ST0R',
  searchStart = 'IS-STST',
  productSearchListClick = 'IS-STPC',
  productSearchAddToCart = 'IS-STAD',
  suggestedProductClick = 'IS-SUPC',
  suggestedProductAddToCart = 'IS-SUPA',
  similarQueryClick = 'IS-STSI',
  suggestedCategoriesSearchStart = 'IS-STCA',
  searchFilterClick = 'IS-SEFI',
  flyoutClick = 'FLYOUT',
  formSubmissions = 'FORM-SUBMISSION',
}

export enum EVENT_SOURCE {
  category = 'category',
  brand = 'brand',
  search = 'search',
  searchAutosuggest = 'searchAutosuggest',
  accessories = 'accessories',
  cms = 'cms',
  wishlist = 'wishlist',
  pdp = 'pdp',
  cart = 'cart',
  deliveredOrder = 'deliveredOrder',
  barcode = 'barcode',
  comparison = 'comparison',
  recommendations = 'recommendations',
}

export enum TEALIUM_PROD_LIST {
  brand = 'brand:',
  accessories = 'accessories',
  barcode = 'barcode_scan',
  cms = 'tp:',
  deliveredOrder = 'buying_hist',
  suggestion = 'sug_srch',
  search = 'std_srch',
  wishlist = 'wishlist',
  comparison = 'comparison',
  cart = 'shoppinglist',
  recommendations = 'recommendations',
  default = '',
}

export enum TEALIUM_PROD_ACTION {
  addToCartFromList = 'add_list',
  addToCartFromPDP = 'add_pdp',
  addToCartFromCart = 'add_cart',
  removeFromCart = 'remove_cart',
  productDetails = 'detail',
  productsList = 'list',
  productListClick = 'list_click',
  addToWishlist = 'wishlist_add',
  removeFromWishlist = 'wishlist_remove',
  addToCompare = 'comparelist_add',
  removeFromCompare = 'comparelist_remove',
  checkoutCart = 'checkout_cart',
  open = 'open',
  cameraSuccess = 'camera_activation_success',
  cameraError = 'camera_activation_error',
  productFound = 'product_found',
  productNotFound = 'product_not_found',
  purchase = 'purchase',
  click = 'click',
  defaultAction = ''
}

export enum HOT_JAR_TAG {
  assortmentFlyoutOpen = 'assortment_flyout_open',
  authErrorClient = 'auth_error_client',
  authErrorUser = 'auth_error_user',
  brandsFlyoutOpen = 'brands_flyout_open',
  featureOnboardingOpen = 'feature_onboarding_open',
  catalogBottomReached = 'catalog_bottom_reached',
  productNotLoaded = 'product_load_failed',
  myAccountAddressesNotLoaded = 'my_account_addresses_load_failed',
  myAccountBalanceNotLoaded = 'my_account_balance_load_failed',
  myAccountCreditsNotLoaded = 'my_account_credits_load_failed',
  myAccountInvoicesNotLoaded = 'my_account_invoices_load_failed',
  myAccountOrdersNotLoaded = 'my_account_orders_load_failed',
  myAccountRmasNotLoaded = 'my_account_rmas_load_failed',
  myAccountUsersNotLoaded = 'my_account_users_load_failed',
  myAccountWishlistsNotLoaded = 'my_account_wishlists_load_failed',
}

export type AnalyticsProductType = {
  action: TEALIUM_PROD_ACTION | CHECKOUT_STEP,
  categories: string[],
  id?: string,
  pos?: string,
  price: string,
  stock?: string,
  list?: string,
  quan?: string,
  purchasable?: ANALYTICS_BOOLEAN,
  hasService?: ANALYTICS_BOOLEAN,
}

export type AnalyticsComponentType = {
  attribute1?: string[],
  attribute2?: string[],
  attribute3?: [''] | string[] | SEARCH_ACTION[] | TEALIUM_PROD_ACTION[],
  attribute4?: string[],
  attribute5?: string[],
  attribute6?: string[],
  location?: string[],
  innerlocation?: string[],
  category?: SEARCH_COMPONENT_CATEGORY[] | string[],
  subcategory?: string[],
}

export type AnalyticsOrderType = {
  id?: string;
  revenue?: string;
  shipping?: string;
  smallQuantitySurcharge?: string;
  payment?: {
    type: string;
    cost?: string;
  }
};

export interface AnalyticsEventData {
  interaction: TEALIUM_INTERACTION_ID;
  oss?: OssDataLayerType;
  page?: {
    type: TEALIUM_PAGE_TYPE
  };
  prod?: AnalyticsProductType[];
  component?: AnalyticsComponentType;
  server?: {
    code: StatusCodes;
  };
  order?: AnalyticsOrderType;
  checkout?: {
    deliverydest: string
    type: CHECKOUT_TYPE
  };
  virtualPageView?: ANALYTICS_BOOLEAN;
}
