import { ReactElement } from 'react';
import { EMAIL_PATTERN } from '@client/common/utils/validations/validationRules';
import { isEmailField } from '@client/content/utils/forms';
import { useTranslate } from '@client/i18n/hooks';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Body, MAX_FILES_AMOUNT, MAX_FILES_SIZE } from './Body';
import { Props } from './FormMapper.types';
import { FORM_ELEMENT_TYPE } from './FormMapperContainer';

export const GLOBAL_ERRORS_KEY = '_errors';

export const FormMapper = (props: Props): ReactElement => {
  const {
    elements,
    initialValues,
    onSubmit,
  } = props;
  const t = useTranslate();
  const text = {
    acceptTerms: t('Terms & Conditions must be accepted'),
    errorEmailFormat: t('Email is not valid'),
    errorEmailRequired: t('Email is required'),
    fieldRequired: t('Required'),
    errorOneFilledRow: t('Each of row columns has to be filled up'),
    errorEmptyTable: t('At least one row has to be filled up'),
    errorFilesSizeExceeded: t('Maximum total size of uploaded files: 10 MB'),
    maxFilesSize: t(
      'Total size of uploaded files must be under {maxSize}MB',
      {
        maxSize: Math.floor(MAX_FILES_SIZE / 1000000),
      },
    ),
  };

  const validate = (values: Record<string, any>): void | Record<string, any> => {
    const globalErrors = elements.reduce((globalErrors, current) => {
      const { fieldId, maxFiles, required, type } = current;
      const table = values[fieldId];

      if (
        type === FORM_ELEMENT_TYPE.table
        && required
        && table
        && Array.isArray(table)
      ) {
        const isTableEmpty = !table.some((row) => Object.values(row).some((cell) => !!cell));

        if (isTableEmpty) {
          globalErrors[fieldId] = text.errorEmptyTable;
        }
      }

      if (
        type === FORM_ELEMENT_TYPE.fileUpload
        && Array.isArray(values[fieldId])
      ) {
        const maxFilesAmount = maxFiles || MAX_FILES_AMOUNT;
        const isFilesAmountExceeded = values[fieldId].length > maxFilesAmount;
        const isFilesSizeExceeded = values[fieldId].reduce(
          (acc: number, curr: { size: number }) => curr.size + acc,
          0,
        ) > MAX_FILES_SIZE;

        if (isFilesSizeExceeded || isFilesAmountExceeded) {
          const errors = [];
          isFilesAmountExceeded
            && errors.push(
              t(
                [
                  'Only one file is allowed',
                  'Only {maxFilesAmount} files are allowed',
                  'maxFilesAmount',
                ],
                {
                  maxFilesAmount,
                },
              ),
            );
          isFilesSizeExceeded && errors.push(text.errorFilesSizeExceeded);
          globalErrors[fieldId] = errors.join(', ');
        }
      }

      return globalErrors;
    }, {} as any);

    if (Object.keys(globalErrors).length) {
      return {
        [GLOBAL_ERRORS_KEY]: globalErrors,
      };
    }
  };

  const validationSchema = (): Record<string, any> => {
    const elementsToValidate = elements.reduce((sum, current) => {
      const { type, fieldId, required } = current;

      const getYupRule = (type: string | undefined, fieldId: string) => {
        switch (type) {
          case FORM_ELEMENT_TYPE.text: {
            let rule = yup.string();

            if (required) {
              rule = rule.required(text.fieldRequired);
            }

            if (isEmailField(fieldId)) {
              rule = rule.matches(EMAIL_PATTERN, text.errorEmailFormat);
            }

            return rule;
          }
          case FORM_ELEMENT_TYPE.radio:
          case FORM_ELEMENT_TYPE.checkbox: {
            let rule = yup.array();

            if (required) {
              rule = rule.min(1, text.fieldRequired);
            }

            return rule;
          }
          case FORM_ELEMENT_TYPE.terms: {
            const rule = yup
              .boolean()
              .test('terms', text.acceptTerms, (value) => value === true);
            return rule;
          }
          case FORM_ELEMENT_TYPE.table: {
            const rule = yup.array().of(
              yup.object().test(fieldId, text.errorOneFilledRow, (row) => {
                if (!row || !Array.isArray(Object.values(row))) {
                  return false;
                }

                let isRowValid = true;
                let isRowEmpty = true;
                Object.values(row).forEach((cell) => {
                  if (cell) {
                    isRowEmpty = false;
                  }
                });

                if (!isRowEmpty) {
                  Object.values(row).forEach((cell) => {
                    if (!cell) {
                      isRowValid = false;
                    }
                  });
                }

                return isRowValid;
              }),
            );
            return rule;
          }
          default: {
            let rule = yup.mixed();
            if (required) {
              rule = rule.required(text.fieldRequired);
            }

            return rule;
          }
        }
      };

      sum[current.fieldId] = getYupRule(type, fieldId);
      return sum;
    }, {} as any);

    return yup.object().shape(elementsToValidate);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      validationSchema={validationSchema}
    >
      <Body {...props} elements={elements} />
    </Formik>
  );
};
