import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';
import { costCenterAccountsSaga } from './accounts';
import { costCenterAddressSaga } from './address';
import { budgetSaga } from './budget';
import { costCenterSaga } from './costCenter';
import { costCentersSaga } from './costCenters';
import { orderDetailSaga } from './orderRequest';
import { costCenterOrdersSaga } from './orders';
import { costCenterUserSaga } from './user';

export function* costCenterSagas(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    fork(costCenterAccountsSaga),
    fork(costCenterAddressSaga),
    fork(costCentersSaga),
    fork(costCenterSaga),
    fork(budgetSaga),
    fork(costCenterUserSaga),
    fork(orderDetailSaga),
    fork(costCenterOrdersSaga),
  ]);
}
