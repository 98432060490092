import { ReactElement } from 'react';
import { SUGGESTION_TYPE } from '@client/app/components/App/Header/Autosuggest';
import { ADD_TO_CART_DISPLAY_TYPE, AddToCart } from '@client/cart/components/AddToCart';
import { HiddenPrice } from '@client/common/components/HiddenPrice/HiddenPrice';
import { StockCount } from '@client/common/components/StockCount';
import { useTranslate } from '@client/i18n/hooks';
import { Labels } from '@client/product/components/ProductData/Labels';
import { getCdnImageUrl } from '@client/routes/utils/url';
import { DataTable } from 'b2b-components/theme/components/DataTable';
import { Price, PRICE_SIZE } from 'b2b-components/theme/components/Price';
import { ProductType } from 'b2b-common/core/catalog/Suggestions.types';
import { Props } from './Products.types';
import './Products.scss';

export const Products = ({
  activeItemIndex,
  onMouseEnter,
  onProductClick,
  onProductAddToCart,
  products,
  term,
  onCloseAutosuggest,
}: Props): ReactElement | null => {
  const t = useTranslate();

  if (!Array.isArray(products) || products.length === 0) {
    return null;
  }

  const getProductCells = (product: ProductType, index: number) => {
    const { effectivePricing } = product;

    const renderPrice = (
      isForMobiles = false,
    ): ReactElement => {
      if (effectivePricing.isHiddenPrice) {
        return (
          <HiddenPrice
            className={
              isForMobiles
                ? 'Autosuggest-BodyCellHiddenPrice--Mobile'
                : 'Autosuggest-BodyCellHiddenPrice'
            }
            hasTooltip={false}
            isHiddenPriceAndNotCertified={
              effectivePricing.isHiddenPriceAndNotCertified
            }
          />
        );
      }

      return (
        <Price
          className={
            isForMobiles ? 'Autosuggest-BodyCellPrice--Mobile' : undefined
          }
          currency="CHF"
          price={effectivePricing.mainPrice}
          size={PRICE_SIZE.small}
        />
      );
    };

    return [
      {
        className: 'Autosuggest-BodyCell Autosuggest-BodyCell--Image',
        value: (
          <img
            alt={product.title}
            className='Autosuggest-BodyCellImage'
            src={getCdnImageUrl(product.cover)}
          />
        ),
      },
      {
        className: 'Autosuggest-BodyCell Autosuggest-BodyCell--Title',
        value: (
          <>
            <span className='Autosuggest-BodyCellTitle'>
              {product.title}
            </span>

            <div className='Autosuggest-BodyCellWrapper'>
              <Labels
                isCoreAssortment={product.settings.isCoreAssortment}
                isOwnAssortment={product.settings.isOwnAssortment}
              />

              <span className='Autosuggest-BodyCellFeature Autosuggest-BodyCellFeature--Sku'>
                {product.sku}
              </span>

              <span className='Autosuggest-BodyCellFeature'>
                {product.manufacturerProductId}
              </span>
              {renderPrice(true)}
            </div>
          </>
        ),
      },
      {
        className: 'Autosuggest-BodyCell Autosuggest-BodyCell--StockCount',
        value: (
          <StockCount
            className="Autosuggest-BodyCellStockCount"
            hasTooltip={false}
            isSpecialOrder={product.settings.isSpecialOrder}
            stockCount={product.settings.quantity}
            tooltipLabel={product.settings.isSpecialOrder ? t('S.O.') : undefined}
          />
        ),
      },
      {
        className: 'Autosuggest-BodyCell Autosuggest-BodyCell--Price',
        value: renderPrice(),
      },
      {
        className: 'Autosuggest-BodyCell Autosuggest-BodyCell--AddToCart',
        value: effectivePricing.isLoggedIn ? (
          <AddToCart
            className="Autosuggest-AddToCart"
            dataAttributes={{
              'data-testid': 'autosuggestButtonAddToCart',
            }}
            isUserCertified={effectivePricing.isCertified}
            productName={product.title}
            settings={{
              allowOverstockBuy: true,
              isBuyable: product.settings.isBuyable,
              isNew: false,
              isOccasion: false,
              isSellout: product.settings.isSellout,
              isSpecialOffer: false,
              isSpecialOrder: product.settings.isSpecialOrder,
              isConsciousAlternative: product.settings.isConsciousAlternative,
              maximumOrderQuantity: 999,
              quantity: product.settings.quantity,
              quantityOrdered: [],
              shouldShowPrice: true,
              isCoreAssortment: product.settings.isCoreAssortment,
              isOwnAssortment: product.settings.isOwnAssortment,
              isDisabledByCoreAssortment: product.settings.isDisabledByCoreAssortment,
              configurableType: product.settings.configurableType,
            }}
            sku={product.sku}
            type={ADD_TO_CART_DISPLAY_TYPE.icon}
            isVisible={index === activeItemIndex}
            onConfiguratorOpen={onCloseAutosuggest}
            onClick={sku => onProductAddToCart(sku, index)}
          />
        ) : null,
      },
    ];
  };

  const rows = products.map(
    (product: ProductType, index: number) => ({
      cells: getProductCells(product, index),
      className: index === activeItemIndex ? 'Autosuggest-ProductRow--Active' : '',
      onClick: onProductClick(product, index),
      onMouseEnter: onMouseEnter(SUGGESTION_TYPE.product, index),
    }),
  );

  return (
    <div className='Autosuggest-Results' data-testid='autosuggestResults'>
      <div className='Autosuggest-Title Autosuggest-Title--Result'>
        {t('top results for')} &quot;{term}&quot;
      </div>

      <DataTable rows={rows} />
    </div>
  );
};
