import { config } from '@client/config';
import { useActiveLanguage } from '@client/i18n/hooks';
import cookie from 'js-cookie';
import { useScript } from 'b2b-components/hooks/useScript';
import { DISABLED_TRACKING_COOKIE_NAME } from 'b2b-server/core/utils/constants';

export const GUURU_CONTAINER = 'guuru-container';

export const GuuruSnippet = (): null => {
  const activeLanguage = useActiveLanguage();
  const isTrackingDisabled = !!cookie.get(DISABLED_TRACKING_COOKIE_NAME);
  const isEnabled = config.guuru.enabled && !isTrackingDisabled;

  useScript(
    'https://static.guuru.com/loader/v1.0/chat.min.js',
    () => {
      if (isEnabled && window && window.Guuru) {
        window.guuru = window.Guuru();
        window.guuru.init({
          appId: config.guuru.appId || 'unknown',
          debug: config.guuru.debug,
          locale: activeLanguage,
        });
      }
    },
    () => {
      if (isEnabled && window && window.guuru) {
        window.guuru.destroy?.();
        window.guuru = undefined;
      }
    },
  );

  return null;
};
