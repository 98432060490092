import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData, RemoteRecordDataType } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { ProductFullType } from '@client/product/redux/products/full/types';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {} as Record<string, RemoteRecordDataType<ProductFullType>>;

const NAME = 'full';

export const fullSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState,
  reducers: {
    fetchProduct: (state, action: PayloadAction<{ sku: string }>) => {
      state[action.payload.sku] = RemoteRecordData.init();
      return state;
    },
    fetchProductFulfilled: (state, action: PayloadAction<{ sku: string, data: ProductFullType }>) => {
      state[action.payload.sku] = RemoteRecordData.fulfill(action.payload.data);
      return state;
    },
    fetchProductRejected: (state, action: PayloadAction<{ sku: string, error: ErrorType }>) => {
      state[action.payload.sku] = RemoteRecordData.reject(action.payload.error);
      return state;
    },
  },
});

export const {
  fetchProduct,
  fetchProductFulfilled,
  fetchProductRejected,
} = fullSlice.actions;

const getState = (state: AppState) => state.product.products.full;

export const selectProductBySku = createSelector(
  [getState, (state, sku: string) => sku],
  (state, sku) => new RemoteRecord<ProductFullType>(state[sku]),
);
