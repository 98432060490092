import { analyzePageView, analyzeProductNotFoundPageView } from '@client/analytics/redux';
import { analyzeView } from '@client/analytics/redux/tealium/actions';
import { TEALIUM_INTERACTION_ID, TEALIUM_PAGE_TYPE } from '@client/analytics/types/types';
import { StatusCodes } from 'http-status-codes';
import { all, AllEffect, ForkEffect, put, PutEffect, takeEvery, takeLatest } from 'redux-saga/effects';

function* analyzePageViewSaga(action: ReturnType<typeof analyzePageView>): Generator<PutEffect> {
  yield put(analyzeView({
    interaction: TEALIUM_INTERACTION_ID.generic,
    ...action.payload,
  }));
}

function* analyzeProductNotFoundPageViewSaga(): Generator<PutEffect> {
  yield put(analyzeView({
    interaction: TEALIUM_INTERACTION_ID.generic,
    page: {
      type: TEALIUM_PAGE_TYPE.productNotFound,
    },
    server: {
      code: StatusCodes.NOT_FOUND,
    },
  }));
}

export function* genericAnalyticsSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeEvery(analyzePageView.type, analyzePageViewSaga),
    takeLatest(analyzeProductNotFoundPageView.type, analyzeProductNotFoundPageViewSaga),
  ]);
}
