import { getApiClient } from '@client/common/api/axios';
import { RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { isServer } from '@client/common/utils/env';
import { config as clientConfig } from '@client/config';
import { getActiveLanguage } from '@client/i18n/redux/redux';
import { setAppVersionModal } from '@client/settings/redux/redux';
import { AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { call, CallEffect, delay, put, PutEffect, select, SelectEffect } from 'redux-saga/effects';
import { LANGUAGE_CODE } from 'b2b-common/core/i18n/consts';
import packageJson from '../../../../../../../package.json';

const MAX_TRIES = 3;
const RETRY_DELAY = 3 * 1000;
const RETRY_CODES = [
  StatusCodes.INTERNAL_SERVER_ERROR,
  StatusCodes.BAD_GATEWAY,
  StatusCodes.SERVICE_UNAVAILABLE,
  StatusCodes.GATEWAY_TIMEOUT,
];
const SERVER_SIDE_TIMEOUT = 10 * 1000;

export function* requestApiSaga(
  requestConfig: RequestConfigType,
): Generator<
  CallEffect | SelectEffect | PutEffect,
  AxiosResponse,
  AxiosResponse & LANGUAGE_CODE
> {
  const {
    withLocalization = true,
    ...config
  } = requestConfig;

  if (isServer) {
    config.timeout = SERVER_SIDE_TIMEOUT;
  }

  const language = withLocalization ? yield select(getActiveLanguage) : null;

  const apiClient = getApiClient(language);

  const response = yield call(apiClient.request, { ...config });

  const serverAppVersion = response.headers['x-app-version'];
  const clientAppVersion = packageJson.version;

  const requestUrl = response.request.responseURL;
  const apiUrl = clientConfig.url.api;
  const isApiRequest = requestUrl.startsWith(apiUrl);

  if (isApiRequest && serverAppVersion && clientAppVersion && serverAppVersion !== clientAppVersion) {
    yield put(setAppVersionModal({ versionMismatch: true }));
  }

  return response;
}

export function* retryRequestApiSaga(
  config: RequestConfigType,
): Generator<CallEffect, CallEffect | undefined, CallEffect> {
  for (let requestNumber = 0; requestNumber < MAX_TRIES; requestNumber++) {
    try {
      return yield call(requestApiSaga, config);
    } catch (error: any) {
      const statusCode = error?.response?.status;

      if (RETRY_CODES.includes(statusCode) && requestNumber < MAX_TRIES - 1) {
        yield delay(RETRY_DELAY);
        continue;
      }

      throw error;
    }
  }
}
