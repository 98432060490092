import { ReactElement } from 'react';
import { Tooltip } from '@client/common/components/Tooltip';
import { upperCaseFirst } from '@client/common/utils/string/string';
import { useTranslate } from '@client/i18n/hooks';
import { Icon, ICON_NAME, ICON_SIZE } from '@lib/components/Icon';
import classNames from 'classnames';
import { Props } from './CertificationInfo.types';
import './CertificationInfo.scss';

export enum CERTIFICATION_INFO_TYPE {
  full = 'full',
  icon = 'icon',
}

export const CertificationInfo = ({ className, type = CERTIFICATION_INFO_TYPE.full }: Props): ReactElement => {
  const t = useTranslate();
  const rootClassName = classNames(
    'CertificationInfo',
    [`CertificationInfo--${upperCaseFirst(type)}`],
    className,
  );

  const text = {
    certificationRequired: t(
      'Certification is required to purchase this product.',
    ),
    contactCustomer: t(
      'Contact Customer Service for details.',
      {},
      'HTML tags are allowed.',
    ),
  };

  if (type === CERTIFICATION_INFO_TYPE.icon) {
    return (
      <div className={rootClassName}>
        <Tooltip
          content={text.certificationRequired}
          className="CertificationInfo-Tooltip"
          triggerClassName="CertificationInfo-TooltipTrigger"
        >
          <Icon
            className="CertificationInfo-Icon"
            name={ICON_NAME.certificate}
            size={ICON_SIZE.default}
          />
        </Tooltip>
      </div>
    );
  }

  return (
    <div className={rootClassName}>
      <Icon
        className="CertificationInfo-Icon"
        name={ICON_NAME.certificate}
        size={ICON_SIZE.large}
      />
      <div>
        <span>{text.certificationRequired}</span>

        <br />

        <span
          dangerouslySetInnerHTML={{
            __html: text.contactCustomer,
          }}
        />
      </div>
    </div>
  );
};
