import { PDF } from '@client/common/redux/api/PDF';
import { requestApiSaga } from '@client/common/redux/api/sagas';
import { isServer } from '@client/common/utils/env';
import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import { call, CallEffect } from 'redux-saga/effects';

function throwPrintJSServerSideError(url: string) {
  throw new Error(
    `Can't generate PDF from ${url}. PrintJS library can be used on client side only.`,
  );
}

const printJS = !isServer ? require('print-js') : throwPrintJSServerSideError;

export function* downloadPDFSaga(
  content: string,
  fileName: string,
): Generator<CallEffect, AxiosResponse, AxiosResponse> {
  const response = yield call(requestApiSaga, PDF.generate(content, fileName));

  saveAs(
    new Blob([response.data], {
      type: 'application/pdf',
    }),
    `${fileName}.pdf`,
  );

  return response;
}

export function* printPDFSaga(
  content: string,
  fileName: string,
): Generator<CallEffect, AxiosResponse, AxiosResponse> {
  const response = yield call(requestApiSaga, PDF.generate(content, fileName));

  const url = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

  printJS(url);

  return response;
}
