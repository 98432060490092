import { MouseEvent } from 'react';
import { useRouterPush } from '@client/routes/hooks';
import uaParser from 'ua-parser-js';

export type OnOpenLink = (e: MouseEvent<any>, url: string) => void;

export const useOpenLink = (): OnOpenLink => {
  const push = useRouterPush();
  const osName = uaParser().os.name || '';

  return (e: MouseEvent<any>, url: string): void => {
    if (
      (osName === 'Mac OS' && e.metaKey)
        || (['Windows', 'Linux'].includes(osName) && e.ctrlKey)
    ) {
      const win = window.open(url, '_blank');
      win?.focus();
    } else {
      push(url);
    }
  };
};
