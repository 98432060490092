import { lazy } from 'react';
import { getLocalisedPaths } from '@client/routes/utils/paths';
import { PERMISSION } from 'b2b-common/core/context/Context.types';
import { AUTHENTICATED_ROUTE } from '../constants';
import { DETAILS_ROUTES_NAMES, DetailsRouteType } from './types';

export const details: DetailsRouteType = {
  [DETAILS_ROUTES_NAMES.myAccountDetails]: {
    ...AUTHENTICATED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/details'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/details" */
      '@client/myAccount/accountDetails/components/ViewPage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }) => isLoggedIn
      && permissionsValidator([PERMISSION.accessMyAccountBasic]),
  },
  [DETAILS_ROUTES_NAMES.myAccountBillingAddressUpdate]: {
    ...AUTHENTICATED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/details/update-billing-address'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/details/update-billing-address" */
      '@client/myAccount/accountDetails/components/UpdateBillingAddressPage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }) => isLoggedIn
      && permissionsValidator([PERMISSION.accessMyAccountUserBillingAddressChange]),
  },
  [DETAILS_ROUTES_NAMES.myAccountContactInfoUpdate]: {
    ...AUTHENTICATED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/details/update-contact-information'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/details/update-contact-information" */
      '@client/myAccount/accountDetails/components/UpdateContactInformationPage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }) => isLoggedIn
      && permissionsValidator([PERMISSION.accessMyAccountUserContactInformationChange]),
  },
};
