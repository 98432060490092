import { deliveredOrdersSaga } from '@client/myAccount/deliveredOrders/redux/deliveredOrders/sagas';
import { deliveredOrdersSearchSagas } from '@client/myAccount/deliveredOrders/redux/deliveredOrdersSearch/sagas';
import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';

export function* deliveredOrdersSagas(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    fork(deliveredOrdersSaga),
    fork(deliveredOrdersSearchSagas),
  ]);
}
