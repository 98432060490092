import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { MY_ACCOUNT_MESSAGE, showMyAccountToast } from '@client/myAccount/common/utils/messages';
import { CostCenterUsers } from '@client/myAccount/costCenters/api';
import {
  fetchCostCenterUser,
  fetchCostCenterUserFulfilled,
  fetchCostCenterUserRejected,
  fetchCostCenterUsers,
  fetchCostCenterUsersFulfilled,
  fetchCostCenterUsersRejected,
  removeCostCenterUser,
  submitCostCenterUser,
  submitCostCenterUserFulfilled,
} from '@client/myAccount/costCenters/redux/user/redux';
import { ROUTES } from '@client/routes/components/Router/routes';
import { pushToRoute } from '@client/routes/redux/utils';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import emitter, { EVENT } from 'b2b-apps/core/common/utils/eventEmitter';
import { CostCenterUsersType } from 'b2b-common/core/account/api/costCenters/CostCenterUsers.types';
import { CostCenterUser } from '../../api/CostCenterUser';

function* fetchCostCenterUserSaga(
  action: ReturnType<typeof fetchCostCenterUser>,
): Generator<CallEffect | PutEffect, any, AxiosResponse> {
  const { costCenterId, userId } = action.payload;

  try {
    const response = yield call(requestApiSaga, CostCenterUser.get(costCenterId, userId));
    yield put(fetchCostCenterUserFulfilled({ userId, data: response.data }));
  } catch (error) {
    yield put(fetchCostCenterUserRejected({ userId, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* fetchCostCenterUsersSaga(
  action: ReturnType<typeof fetchCostCenterUsers>,
): Generator<CallEffect | PutEffect, void, AxiosResponse<CostCenterUsersType>> {
  const { costCenterId, page = 1, pageSize = 5 } = action.payload;
  const startItem = (page - 1) * (pageSize);

  try {
    const response = yield call(
      requestApiSaga,
      CostCenterUsers.get(costCenterId, startItem, pageSize),
    );

    yield put(fetchCostCenterUsersFulfilled(response.data));
  } catch (error) {
    yield put(fetchCostCenterUsersRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* submitCostCenterUserSage(
  action: ReturnType<typeof submitCostCenterUser>,
): Generator<
  CallEffect | PutEffect | Generator,
  void,
  AxiosResponse<CostCenterUsersType>
> {
  const { costCenterId, userId, data } = action.payload;

  try {
    let response;
    if (userId) {
      response = yield call(requestApiSaga, CostCenterUsers.update(costCenterId, userId, data));
    } else {
      response = yield call(requestApiSaga, CostCenterUsers.create(costCenterId, data));
    }

    yield put(submitCostCenterUserFulfilled(response.data));
    yield pushToRoute(ROUTES.myAccountCostCenterUsers, { costCenterId });
  } catch (error) {
    showMyAccountToast(MY_ACCOUNT_MESSAGE.errorCreateUser);
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* removeCostCenterUserSaga(
  action: ReturnType<typeof removeCostCenterUser>,
): Generator<CallEffect | PutEffect, void, AxiosResponse> {
  const { costCenterId, userId } = action.payload;

  try {
    yield call(
      requestApiSaga,
      CostCenterUsers.delete(costCenterId, userId),
    );

    emitter.dispatch(EVENT.removeCostCenterUserFulfilled);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.successRemoveUser);
  } catch (error) {
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));

    emitter.dispatch(EVENT.removeCostCenterUserRejected);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.errorRemoveUser);
  }
}

export function* costCenterUserSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(fetchCostCenterUser.type, fetchCostCenterUserSaga),
    takeLatest(fetchCostCenterUsers.type, fetchCostCenterUsersSaga),
    takeLatest(removeCostCenterUser.type, removeCostCenterUserSaga),
    takeLatest(submitCostCenterUser.type, submitCostCenterUserSage),
  ]);
}
