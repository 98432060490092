import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectBarcodeSearch } from '@client/catalog/redux/barcodeSearch/redux';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { AppState } from '@client/common/redux/store';
import { fetchProductTileByBarcode, selectProductTileBySku } from '@client/product/redux/products/tile/redux';
import { BarcodeSearchType } from 'b2b-common/core/catalog/Catalog.types';
import { ProductTileType } from 'b2b-common/core/product/api/ProductTile.types';

type OnScanBarcodeType = (barcode: string) => void;
type OnScanBarcodeClearType = () => void;

export type UseFetchProductTileByBarcodeEffectReturnType = {
  barcode: string;
  onScanBarcode: OnScanBarcodeType;
  onScanBarcodeClear: OnScanBarcodeClearType;
  barcodeSearch: RemoteRecord<BarcodeSearchType>;
  productTile: RemoteRecord<ProductTileType>;
};

export const useFetchProductTileByBarcodeEffect = (): UseFetchProductTileByBarcodeEffectReturnType => {
  const dispatch = useDispatch();
  const [barcode, setBarcode] = useState('');
  const barcodeSearch = useSelector(
    (state: AppState) => selectBarcodeSearch(state, barcode),
  );
  const productSku = barcodeSearch.data?.[0] || '';
  const productTile = useSelector(
    (state: AppState) => selectProductTileBySku(state, productSku),
  );

  const handleScanBarcode: OnScanBarcodeType = (barcode) => {
    setBarcode(barcode);
    dispatch(fetchProductTileByBarcode({ barcode }));
  };

  const handleScanBarcodeClear: OnScanBarcodeClearType = () => {
    setBarcode('');
  };

  return {
    barcode,
    onScanBarcode: handleScanBarcode,
    onScanBarcodeClear: handleScanBarcodeClear,
    barcodeSearch,
    productTile,
  };
};
