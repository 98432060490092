import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { BillingAddress } from '@client/myAccount/accountDetails/api/BillingAddress';
import {
  updateBillingAddress,
  updateBillingAddressFulfilled,
  updateBillingAddressRejected,
} from '@client/myAccount/accountDetails/redux/billingAddress/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';

function* updateBillingAddressSaga(
  action: ReturnType<typeof updateBillingAddress>,
): Generator<CallEffect | PutEffect, any, AxiosResponse> {
  const billingAddress = action.payload;

  try {
    yield call(requestApiSaga, BillingAddress.update(billingAddress));
    yield put(updateBillingAddressFulfilled());
  } catch (error) {
    yield put(updateBillingAddressRejected(parseError(error)));
    yield put(reportError({ message: action.type, error }));
  }
}

export function* billingAddressSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeEvery(updateBillingAddress.type, updateBillingAddressSaga),
  ]);
}
