import { lazy } from 'react';
import { getLocalisedPaths } from '@client/routes/utils/paths';
import { PERMISSION } from 'b2b-common/core/context/Context.types';
import { AUTHENTICATED_ROUTE } from '../constants';
import { USERS_ROUTES_NAMES, UsersRouteType } from './types';

export const users: UsersRouteType = {
  [USERS_ROUTES_NAMES.myAccountUsers]: {
    ...AUTHENTICATED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/users/:page?'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/users" */
      '@client/myAccount/users/components/ListPage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }) => isLoggedIn
      && permissionsValidator([PERMISSION.accessMyAccountUserManagement]),
  },
  [USERS_ROUTES_NAMES.myAccountUserCreate]: {
    ...AUTHENTICATED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/user/create'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/user/create" */
      '@client/myAccount/users/components/CreatePage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }) => isLoggedIn && permissionsValidator([PERMISSION.myAccountEditUser]),
  },
  [USERS_ROUTES_NAMES.myAccountUserUpdate]: {
    ...AUTHENTICATED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/user/update/:accountId'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/user/update" */
      '@client/myAccount/users/components/UpdatePage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }) => isLoggedIn && permissionsValidator([PERMISSION.myAccountEditUser]),
  },
};
