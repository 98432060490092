import { retryRequestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Users } from '@client/myAccount/users/api/Users';
import {
  usersSearch,
  usersSearchFulfilled,
  usersSearchRejected,
} from '@client/myAccount/users/redux/usersSearch/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { UsersType } from 'b2b-common/core/account/api/Users.types';

function* usersSearchSaga(
  action: ReturnType<typeof usersSearch>,
): Generator<CallEffect | PutEffect, void, AxiosResponse<UsersType>> {
  const { pageSize, startItem, searchQuery } = action.payload;

  try {
    const { data } = yield call(
      retryRequestApiSaga,
      Users.search(pageSize, startItem, searchQuery),
    );

    yield put(usersSearchFulfilled({ data }));
  } catch (error) {
    yield put(usersSearchRejected({ error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: { searchQuery } }));
  }
}

export function* usersSearchSagas(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(usersSearch.type, usersSearchSaga),
  ]);
}
