import { CHECKOUT_STEP, CHECKOUT_TYPE } from '@client/analytics/redux';
import {
  TEALIUM_INTERACTION_ID,
  TEALIUM_PAGE_TYPE,
  TEALIUM_PROD_ACTION,
} from '@client/analytics/types/types';
import {
  prepareDeliveryDestination,
  preparePrice,
  prepareQuantity,
  prepareStock,
} from '@client/analytics/utils/analytics';
import { ANALYTICS_ATTRIBUTES, BaseBuilder } from '@client/analytics/utils/builders/BaseBuilder';
import { CartProductType } from 'b2b-common/core/cart/api/CartTypes';
import { CheckoutDataType, SHIPPING_METHOD } from 'b2b-common/core/checkout/Checkout.types';

interface ShippingType {
  type: string;
  selected: SHIPPING_METHOD;
}

export interface OrderType {
  id: number | string,
  revenue: number,
  shipping: number,
  smallQuantitySurcharge: number,
  payment: {
    cost: number,
    type: number | string,
  },
}

export class CheckoutBaseAnalyticsBuilder extends BaseBuilder<CheckoutDataType> {
  public constructor(hasVat = false) {
    super();
    this.hasVat = hasVat;
  }

  protected step: CHECKOUT_STEP | TEALIUM_PROD_ACTION;
  protected hasVat: boolean;
  protected shipping: ShippingType;
  protected products: CartProductType[];
  protected paymentType: string;
  protected interaction: TEALIUM_INTERACTION_ID;
  protected pageType: TEALIUM_PAGE_TYPE;
  protected order: OrderType;
  protected prodAttributes = [
    ANALYTICS_ATTRIBUTES.action,
    ANALYTICS_ATTRIBUTES.categories,
    ANALYTICS_ATTRIBUTES.id,
    ANALYTICS_ATTRIBUTES.price,
    ANALYTICS_ATTRIBUTES.quan,
    ANALYTICS_ATTRIBUTES.stock,
  ];

  public setShipping(shipping: ShippingType): CheckoutBaseAnalyticsBuilder {
    this.shipping = shipping;
    return this;
  }

  public setProducts(products: CartProductType[]): CheckoutBaseAnalyticsBuilder {
    this.products = products;
    return this;
  }

  public setPaymentType(paymentType: string): CheckoutBaseAnalyticsBuilder {
    this.paymentType = paymentType;
    return this;
  }

  public setInteraction(interaction: TEALIUM_INTERACTION_ID): CheckoutBaseAnalyticsBuilder {
    this.interaction = interaction;
    return this;
  }

  public setPageType(pageType: TEALIUM_PAGE_TYPE): CheckoutBaseAnalyticsBuilder {
    this.pageType = pageType;
    return this;
  }

  public setStep(step: CHECKOUT_STEP | TEALIUM_PROD_ACTION): CheckoutBaseAnalyticsBuilder {
    this.step = step;
    return this;
  }

  public build(): any {
    if (this.hasVat) {
      this.prodAttributes = [
        ...this.prodAttributes,
        ANALYTICS_ATTRIBUTES.vat,
      ];
    }

    this.analyticsResultObjectArray = this.products.map((product) => ({
      attributes: this.prodAttributes,
      analyticsResultObject: {
        action: this.step,
        categories: product.analytics.categories,
        id: product.sku,
        price: preparePrice(product.single.price),
        quan: prepareQuantity(product.cart.quantity),
        stock: prepareStock(product.settings.quantity),
        ...(this.hasVat && { vat: preparePrice(product.single.price - product.single.priceWithoutVat) }),
      },
    }));

    return {
      page: {
        type: this.pageType,
      },
      interaction: this.interaction,
      checkout: {
        deliverydest: prepareDeliveryDestination(this.shipping.selected),
        type: CHECKOUT_TYPE.login,
      },
      prod: super.build(),
      order: {
        payment: {
          type: this.paymentType,
        },
      },
    };
  }
}
