import { Cart } from '@client/cart/api/Cart';
import { fetchCartWishlistSearch } from '@client/cart/redux/wishlistSearch/redux';
import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import emitter, { EVENT } from '@client/common/utils/eventEmitter';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, debounce, ForkEffect, put, PutEffect } from 'redux-saga/effects';
import { CartWishlistSearchType } from 'b2b-common/core/cart/api/CartTypes';

const WISHLIST_SEARCH_DEBOUNCE = 500;

function* fetchCartWishlistSearchSaga(
  action: ReturnType<typeof fetchCartWishlistSearch>,
): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<CartWishlistSearchType>
> {
  const query = action.payload;

  try {
    const response = yield call(
      requestApiSaga,
      Cart.fetchWishlistSearch(query),
    );

    emitter.dispatch(EVENT.searchWishlistsFulfilled, response.data);
  } catch (error) {
    yield put(reportError({ message: action.type, error, additionalData: { query: action.payload } }));
    emitter.dispatch(EVENT.searchWishlistsRejected, []);
  }
}

export function* cartWishlistSearchSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    debounce(WISHLIST_SEARCH_DEBOUNCE, fetchCartWishlistSearch.type, fetchCartWishlistSearchSaga),
  ]);
}
