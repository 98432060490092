import { ReactElement, useEffect } from 'react';
import { useContentAnalytics } from '@client/analytics/context/content/ContentAnalyticsContext';
import { TOAST_TYPE } from '@client/common/components/Toast';
import { usePrevious, useToggle } from '@client/common/hooks';
import { ensureArray } from '@client/common/utils/array/array';
import { isEmpty } from '@client/common/utils/empty/empty';
import { getErrorMessage } from '@client/common/utils/form/form';
import { showToast } from '@client/common/utils/showToast';
import { useFormElements, useFormSubmission, useSubmitFormAction } from '@client/content/hooks';
import { useTranslate } from '@client/i18n/hooks';
import { FormElementFileType, FormElementType } from 'b2b-common/core/content/Content.types';
import { FormMapper } from './FormMapper';

type FileType = {
  data: string,
  fileName: string,
}

export enum FORM_ELEMENT_TYPE {
  checkbox = 'checkbox',
  fileUpload = 'fileUpload',
  label = 'label',
  radio = 'radio',
  select = 'select',
  submit = 'submit',
  table = 'table',
  terms = 'terms',
  text = 'text',
}

const DEFAULT_TABLE_ROWS_AMOUNT = 5;

type Props = {
  className?: string;
  formId: string;
};

export const FormMapperContainer = ({ formId, className }: Props): ReactElement | null => {
  const t = useTranslate();
  const text = {
    error: t('Error'),
    success: t('Success'),
    submitError: t(
      'Form contains errors - please review and verify again.',
    ),
    submitSuccess: t('Form has been successfully submitted.'),
  };

  const { analyzeContentForm } = useContentAnalytics();
  const submitData = useFormSubmission(formId);
  const elements = useFormElements(formId).data;
  const wasDataLoading = usePrevious(submitData.isLoading);
  const submitFormAction = useSubmitFormAction();
  const [isTermsModalOpened, setIsTermsModalOpened] = useToggle();
  const [isFileNameDisplayed, setIsFileNameDisplayed] = useToggle(true);


  const handleTermsModalToggle = (): void => {
    setIsTermsModalOpened();
  };

  const displayFormError = (fieldId: string, rest: [string, boolean]): string => {
    const [error, touched] = rest;

    if (getErrorMessage(error, touched)) {
      return getErrorMessage(error, touched);
    }

    if (submitData.error && submitData.error[fieldId]) {
      return submitData.error[fieldId];
    }
  };

  useEffect(
    () => {
      setIsFileNameDisplayed(submitData.isLoaded);
    },
    [submitData],
  );

  useEffect(
    () => {
      if (wasDataLoading && submitData.hasFailed) {
        showToast(TOAST_TYPE.error, text.error, text.submitError);
      }

      if (wasDataLoading && submitData.isLoaded) {
        showToast(TOAST_TYPE.success, text.success, text.submitSuccess);
      }
    },
    [wasDataLoading, submitData, text],
  );

  const trimEmptyTableRows = (rows: Record<string, any>[]): Record<string, any>[] => ensureArray(rows).filter(
    (row) => Object.values(row).filter((cell) => !!cell).length,
  );

  const trimFilesSize = (files: FileType[]): Record<string, any>[] => ensureArray(files).map((file: FileType) => ({
    fileName: file.fileName,
    data: file.data,
  }));

  const convertFileToBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      if (reader.result) {
        const fileBinary = (reader.result as string)
          .replace('data:', '')
          .replace(/^.+,/, '');
        resolve(fileBinary);
      } else {
        reject();
      }
    };
  });

  const getAllowedFileTypes = (element: FormElementType) => Array.isArray(element.allowedFileTypes)
    ? element.allowedFileTypes.reduce((prev: Record<string, string[]>, curr: FormElementFileType) => {
      prev[curr.type] = prev.extensions;

      return prev;
    }, {})
    : {};

  const getAllowedFileExtensions = (element: FormElementType, separator = ',') => Array.isArray(element.allowedFileTypes)
    ? element.allowedFileTypes.reduce(
      (
        prev: string,
        curr: FormElementFileType,
        idx: number,
      ) => idx === 0
        ? curr.extensions.join(separator)
        : prev + separator + curr.extensions.join(separator),
      '',
    )
    : '';

  const handleSubmit = (values: Record<string, any>): void => {
    if (!Array.isArray(elements)) {
      return;
    }

    const requestData: Record<string, any> = {};

    Object.entries(values).forEach(([key, value]) => {
      const fieldDefinition = elements.find(
        ({ fieldId }) => fieldId === key,
      );

      if (!fieldDefinition || !fieldDefinition.type) {
        return;
      }

      switch (fieldDefinition.type) {
        case FORM_ELEMENT_TYPE.fileUpload:
          requestData[key] = trimFilesSize(value);
          break;
        case FORM_ELEMENT_TYPE.table:
          requestData[key] = trimEmptyTableRows(value);
          break;
        case FORM_ELEMENT_TYPE.radio:
          requestData[key] = Array.isArray(value) ? value[0] : '';
          break;
        case FORM_ELEMENT_TYPE.text:
          requestData[key] = value.replace(/\n+/g, '<br>');
          break;
        default:
          requestData[key] = value;
      }
    });

    submitFormAction({ formId, data: requestData });
    analyzeContentForm({ formId, formName: document?.title });
  };

  if (!Array.isArray(elements) || isEmpty(elements)) {
    return null;
  }

  const initialValues = Array.isArray(elements)
    && elements
      .filter((el) => el.fieldId)
      .reduce((sum, current) => {
        switch (current.type) {
          case FORM_ELEMENT_TYPE.checkbox:
            sum[current.fieldId] = [];
            break;
          case FORM_ELEMENT_TYPE.fileUpload:
            sum[current.fieldId] = [];
            break;
          case FORM_ELEMENT_TYPE.radio:
            sum[current.fieldId] = [];
            break;
          case FORM_ELEMENT_TYPE.table:
            sum[current.fieldId] = Array(DEFAULT_TABLE_ROWS_AMOUNT).fill(
              current.columns.reduce(
                (obj: any, item: any) => ({
                  ...obj,
                  [item.replace(/\./g, ' ')]: '',
                }),
                {},
              ),
            );
            break;
          case FORM_ELEMENT_TYPE.terms:
            sum[current.fieldId] = false;
            break;
          case FORM_ELEMENT_TYPE.text:
            sum[current.fieldId] = '';
            break;
          default:
            sum[current.fieldId] = '';
            break;
        }

        return sum;
      }, {});

  return (
    <FormMapper
      formId={formId}
      convertFileToBase64={convertFileToBase64}
      displayFormError={displayFormError}
      elements={elements}
      getAllowedFileTypes={getAllowedFileTypes}
      getAllowedFileExtensions={getAllowedFileExtensions}
      initialValues={initialValues}
      isFileNameDisplayed={isFileNameDisplayed}
      isTermsModalOpened={isTermsModalOpened}
      onSubmit={handleSubmit}
      onTermsModalToggle={handleTermsModalToggle}
      submitData={submitData}
      className={className}
    />
  );
};
