import { ComponentType, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  ACCOUNT_ROUTES_NAMES,
} from '@client/routes/components/Router/routes/account';
import { AccountRouteType } from '@client/routes/components/Router/routes/account/types';
import { LANGUAGE_CODE } from 'b2b-common/core/i18n/consts';


export interface AccessCallbackDataType {
  activeLanguage: LANGUAGE_CODE;
  isLoggedIn: boolean;
  isBlocked: boolean;
  permissionsValidator: (permissionsRequired: string[]) => boolean;
}

export interface RouteType {
  paths: Record<string, string>;
  path?: string;
  component?: ComponentType<any>;
  render?: (router: RouteComponentProps) => ReactElement;
  exact?: boolean;
  allow?: (data: AccessCallbackDataType) => boolean;
  allowTranslation?: boolean;
}

export enum KANBAN_ROUTES_NAMES {
  kanban = 'kanban',
  kanbanOrderConfirmation = 'kanbanOrderConfirmation',
  kanbanOrderFail = 'kanbanOrderFail',
}

export enum MAIN_ROUTES_ENUM {
  homepage = 'homepage',
  category = 'category',
  search = 'search',
  brand = 'brand',
  manufacturer = 'manufacturer',
  manufacturers = 'manufacturers',
  product = 'product',
  page = 'page',
  productsCompare = 'productsCompare',
  unlocalizedContent = 'unlocalizedContent',
  trackingOptOut = 'trackingOptOut',
}

export enum ORDER_ROUTES_ENUM {
  cart = 'cart',
  checkout = 'checkout',
  checkoutConfirmation = 'checkoutConfirmation',
  checkoutFail = 'checkoutFail',
  expressOrder = 'expressOrder',
  collectedOrderConfirmation = 'collectedOrderConfirmation',
  collectedOrderFail = 'collectedOrderFail',
}

export enum PRODUCT_CONFIGURATOR_ROUTES_NAME {
  productConfigurator = 'productConfigurator',
  productConfiguratorFail = 'productConfiguratorFail',
}

export enum PUNCHOUT_ROUTES_NAMES {
  punchoutLoginUnsuccessful = 'punchoutLoginUnsuccessful',
  punchoutOrderConfirmation = 'punchoutOrderConfirmation',
  ociTestForm = 'ociTestForm',
  ociTestResult = 'ociTestResult',
  cxmlTestForm = 'cxmlTestForm',
  cxmlTestResult = 'cxmlTestResult',
}

export type MainRouteType = {
  [MAIN_ROUTES_ENUM.homepage]: RouteType;
  [MAIN_ROUTES_ENUM.category]: RouteType;
  [MAIN_ROUTES_ENUM.search]: RouteType;
  [MAIN_ROUTES_ENUM.brand]: RouteType;
  [MAIN_ROUTES_ENUM.manufacturer]: RouteType;
  [MAIN_ROUTES_ENUM.manufacturers]: RouteType;
  [MAIN_ROUTES_ENUM.product]: RouteType;
  [MAIN_ROUTES_ENUM.page]: RouteType;
  [MAIN_ROUTES_ENUM.productsCompare]: RouteType;
  [MAIN_ROUTES_ENUM.unlocalizedContent]: RouteType;
  [MAIN_ROUTES_ENUM.trackingOptOut]: RouteType;
};

export type OrderRouteType = {
  [ORDER_ROUTES_ENUM.cart]: RouteType;
  [ORDER_ROUTES_ENUM.checkout]: RouteType;
  [ORDER_ROUTES_ENUM.checkoutConfirmation]: RouteType;
  [ORDER_ROUTES_ENUM.checkoutFail]: RouteType;
  [ORDER_ROUTES_ENUM.expressOrder]: RouteType;
  [ORDER_ROUTES_ENUM.collectedOrderConfirmation]: RouteType;
  [ORDER_ROUTES_ENUM.collectedOrderFail]: RouteType;
};

export type PunchoutRouteType = {
  [PUNCHOUT_ROUTES_NAMES.punchoutLoginUnsuccessful]: RouteType;
  [PUNCHOUT_ROUTES_NAMES.punchoutOrderConfirmation]: RouteType;
  [PUNCHOUT_ROUTES_NAMES.ociTestForm]: RouteType;
  [PUNCHOUT_ROUTES_NAMES.ociTestResult]: RouteType;
  [PUNCHOUT_ROUTES_NAMES.cxmlTestForm]: RouteType;
  [PUNCHOUT_ROUTES_NAMES.cxmlTestResult]: RouteType;
};

export type ProductConfiguratorRouteType = {
  [PRODUCT_CONFIGURATOR_ROUTES_NAME.productConfigurator]: RouteType;
  [PRODUCT_CONFIGURATOR_ROUTES_NAME.productConfiguratorFail]: RouteType;
};

export type KanbanRouteType = {
  [KANBAN_ROUTES_NAMES.kanban]: RouteType;
  [KANBAN_ROUTES_NAMES.kanbanOrderConfirmation]: RouteType;
  [KANBAN_ROUTES_NAMES.kanbanOrderFail]: RouteType;
};

export const ROUTES_NAMES = {
  ...MAIN_ROUTES_ENUM,
  ...ORDER_ROUTES_ENUM,
  ...PUNCHOUT_ROUTES_NAMES,
  ...PRODUCT_CONFIGURATOR_ROUTES_NAME,
  ...KANBAN_ROUTES_NAMES,
  ...ACCOUNT_ROUTES_NAMES,
};

export type RedirectionType = {
  from: string | string[];
  to: string;
  exact?: boolean;
  push?: boolean;
  when: (accessCallbackData: AccessCallbackDataType) => boolean;
}

export enum SERVER_ROUTES {
  authorize = '/auth/authorize',
  login = '/auth/login',
  logout = '/auth/logout',
  register = '/auth/register',
  settings = '/auth/settings',
  trivet = '/cart/trivet/add/', // reason why it's here: https://competecconf.atlassian.net/wiki/spaces/PMA3/pages/edit-v2/4130799699?draftShareId=0b0949c8-d3e3-4dbb-9ffd-ebdd34d56639
}

export type CombinedRoutesKeys =
  keyof MainRouteType |
  keyof OrderRouteType |
  keyof PunchoutRouteType |
  keyof ProductConfiguratorRouteType |
  keyof KanbanRouteType |
  keyof AccountRouteType;

export type CombinedRouteType = {
  [K in CombinedRoutesKeys]: RouteType;
};
