import {
  fetchBarcodeSearch,
  fetchBarcodeSearchFulfilled,
  fetchBarcodeSearchRejected,
  selectBarcodeSearch,
} from '@client/catalog/redux/barcodeSearch/redux';
import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { ProductTile } from '@client/product/api/ProductTile';
import {
  fetchProductTile,
  fetchProductTileByBarcode,
  fetchProductTileFulfilled,
  fetchProductTileRejected,
  fetchProductTiles,
  fetchProductTilesFulfilled,
  fetchProductTilesRejected,
  selectProductTileBySku,
} from '@client/product/redux/products/tile/redux';
import { AxiosResponse } from 'axios';
import {
  all,
  AllEffect,
  call,
  CallEffect,
  delay,
  ForkEffect,
  put,
  PutEffect,
  race,
  RaceEffect,
  select,
  SelectEffect,
  take,
  TakeEffect,
  takeEvery,
} from 'redux-saga/effects';
import { ProfileType } from 'b2b-common/core/context/Context.types';
import { ProductTileResponseType, ProductTilesResponseType } from 'b2b-common/shop/product/api/ProductTile.types';

const FETCH_PRODUCT_TILES_DELAY = 250;

function* fetchProductTileSaga(
  action: ReturnType<typeof fetchProductTile>,
): Generator<
  CallEffect | PutEffect | SelectEffect,
  void,
  AxiosResponse<ProductTileResponseType> & ProfileType
> {
  const { sku } = action.payload;

  try {
    const response = yield call(requestApiSaga, ProductTile.fetch(sku));
    const productTile = ProductTile.mapResponseToState(response);

    yield put(fetchProductTileFulfilled({ sku, data: productTile }));
  } catch (error) {
    yield put(fetchProductTileRejected({ sku, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* fetchProductTileByBarcodeSaga(
  action: ReturnType<typeof fetchProductTileByBarcode>,
): Generator<
  CallEffect | SelectEffect | PutEffect | RaceEffect<TakeEffect>,
  undefined,
  ReturnType<typeof selectBarcodeSearch>
  & {
    isBarcodeSearchFulfilled: ReturnType<typeof fetchBarcodeSearchFulfilled>,
    isBarcodeSearchRejected: ReturnType<typeof fetchBarcodeSearchRejected>,
  isProductTileFulfilled: ReturnType<typeof fetchProductTile>,
  }
> {
  const barcode = action.payload.barcode;
  const barcodeSearchData = yield select(selectBarcodeSearch, barcode);
  let data = barcodeSearchData.data;

  const isBarcodeSearchNotLoaded = !barcodeSearchData
    || !barcodeSearchData.data
    || barcodeSearchData.hasFailed;

  if (isBarcodeSearchNotLoaded) {
    yield put(fetchBarcodeSearch(barcode));
    const { isBarcodeSearchFulfilled, isBarcodeSearchRejected } = yield race({
      isBarcodeSearchFulfilled: take(fetchBarcodeSearchFulfilled.type),
      isBarcodeSearchRejected: take(fetchBarcodeSearchRejected.type),
    });

    if (isBarcodeSearchRejected) {
      return;
    }

    if (isBarcodeSearchFulfilled) {
      data = isBarcodeSearchFulfilled.payload.data;
    }
  }

  const productSku = data?.[0];

  const productTile = yield select(selectProductTileBySku, productSku);
  const isProductTileNotLoaded = !productTile || !productTile.data || productTile.hasFailed;

  if (isProductTileNotLoaded) {
    yield put(fetchProductTile({ sku: productSku }));
  }
}

function* fetchProductTilesSaga(
  action: ReturnType<typeof fetchProductTiles>,
): Generator<
  CallEffect | PutEffect | SelectEffect,
  void,
  AxiosResponse<ProductTilesResponseType> & ProfileType
> {
  const { groupNumber, skus } = action.payload;

  if (groupNumber !== 0) {
    yield delay(groupNumber * FETCH_PRODUCT_TILES_DELAY);
  }

  try {
    const response = yield call(
      requestApiSaga,
      ProductTile.fetchMultiple(skus),
    );
    const productTiles = ProductTile.mapMultipleTilesResponseToState(response);
    yield put(fetchProductTilesFulfilled({ skus, data: productTiles }));
  } catch (error) {
    yield put(fetchProductTilesRejected({ skus, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* productTilesSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeEvery(fetchProductTile.type, fetchProductTileSaga),
    takeEvery(fetchProductTileByBarcode.type, fetchProductTileByBarcodeSaga),
    takeEvery(fetchProductTiles.type, fetchProductTilesSaga),
  ]);
}
