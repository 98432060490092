import { CartProductType } from 'b2b-common/core/cart/api/CartTypes';
import { CheckoutDetails } from 'b2b-common/core/checkout/CheckoutDetails.types';
import { ProductQuantityOrderedType } from 'b2b-common/core/product/api/Product.types';

export enum ADDRESS_TYPE {
  new = 'new',
  preferred = 'preferred',
  billing = 'billing',
  saved = 'saved',
  costCenter = 'costCenter',
}

export enum PAYMENT_STATUS {
  canceled = 'canceled',
  failed = 'failed',
}

export enum PAYMENT_TYPE {
  bill = 'bill',
  cash = 'cash',
  cashOnDelivery = 'cashOnDelivery',
  masterCard = 'masterCard',
  postFinanceCard = 'postfinanceCard',
  postFinanceEFinance = 'postfinanceEFinance',
  prepayment = 'prepayment',
  twint = 'twint',
  visa = 'visa',
  payPal = 'paypal',
  americanExpress = 'americanExpress',
  samsungPay = 'samsungpay',
}

export enum DROP_SHIPPING_OPTION {
  alltron = 'alltron',
  industry = 'industry',
  anonymous = 'anonymous',
}

export enum SALUTATION {
  male = 'Herr',
  female = 'Frau',
  company = 'Firma',
}

export enum SHIPPING_METHOD {
  mail = 'mail',
  mailBulky = 'mailBulky',
  pickup = 'pickup',
  pickupStaff = 'pickupStaff',
  pickupWillisau = 'pickupWillisau',
  pickupRenens = 'pickupRenens',
  saturdayMail = 'saturdayMail',
  express = 'express',
  carrier = 'carrier',
  carrierFloorDelivery = 'carrierFloorDelivery',
  carrierFloorDeliveryNotification = 'carrierFloorDeliveryNotification',
  npu = 'npu',
  distribution = 'distribution',
  distributionMaegenwil = 'distributionMaegenwil',
  distributionOhringen = 'distributionOhringen',
  distributionWillisau = 'distributionWillisau'
}

export enum SHIPPING_OPTION {
  allInOne = 'allInOne',
  twoParts = 'twoParts',
  eachSeparately = 'eachSeparately',
}

export type BillingAddressType = {
  company: string | null,
  contactPerson: string | null,
  firstname: string | null,
  lastname: string | null,
  salutation: string | null
}

export type ProductResponseSettingsType = {
  allowOverstockBuy: 0 | 1;
  isBuyable: 0 | 1;
  isNew: 0 | 1;
  isRefurbished: 0 | 1;
  isSellout: 0 | 1;
  isSpecialOffer: 0 | 1;
  isSpecialOrder: 0 | 1;
  maximumOrderQuantity: number;
  quantity: number;
  quantityOrdered: ProductQuantityOrderedType;
  requiresLicense: 0 | 1;
  showPrice: 0 | 1;
};

export type CheckoutResponseType = {
  driverInfo?: string | null;
  addresses: {
    displayInvoiceAddress: {
      company?: string;
      displayName: string | null;
      contactPerson: string;
      salutation: string | null;
      firstname: string | null;
      lastname: string | null;
      street: string;
      streetaddition: string;
      zip: string;
      city: string;
      homeNumber?: string | null;
      mobileNumber?: string;
      empty?: boolean;
      fieldErrors?: Array<any>;
    };
    billingAddress: {
      company?: string;
      displayName: string | null;
      contactPerson: string;
      salutation: string | null;
      firstname: string | null;
      lastname: string | null;
      street: string;
      streetaddition: string;
      zip: string;
      city: string;
      homeNumber?: string | null;
      mobileNumber?: string;
      empty?: boolean;
      fieldErrors?: Array<any>;
    };
    defaultShippingAddress: {
      company: string | null;
      contactPerson: string | null;
      displayName: string | null;
      salutation: string | null;
      firstname: string | null;
      lastname: string | null;
      street: string | null;
      streetaddition: string | null;
      zip: string | null;
      city: string | null;
      homeNumber: string | null;
      mobileNumber: string | null;
    };
    shippingAddress: {
      id?: number;
      name?: string | null;
      displayName: string | null;
      contactPerson: string | null;
      salutation: string | null;
      firstname: string | null;
      lastname: string | null;
      street: string | null;
      streetaddition: string | null;
      city: string | null;
      zip: string | null;
      phoneNumber: string | null;
      reference?: string | null;
      pickPostAddress?: boolean;
      empty?: boolean;
      fieldErrors?: Array<any>;
    };
    pickupAddress: {
      displayName: string | null;
      contactPerson: string | null;
      street: string | null;
      streetaddition: string | null;
      zip: string | null;
      city: string | null;
      empty: boolean;
      fieldErrors: Array<any>;
      addressAsString: string;
    };
    licenceHolderAddress: {
      addressAsString: string;
      city: string | null;
      contactPerson: string | null;
      displayName: string | null;
      email: string | null;
      empty: boolean;
      fieldErrors: Array<any>;
      phoneNumber: string | null;
      street: string | null;
      streetaddition: string | null;
      zip: string | null;
    };
    licenceHolderData: null;
    fieldErrors: Array<any>;
  };
  anonymShipping: boolean;
  cart: {
    hasCartItemNPU: boolean;
    hasCartOnlyNPUItems: boolean;
    hasOnlyDeliveryProductServiceItems: boolean;
    hasOnlyStockItems: boolean;
    hasSelectedDeliveryProductServiceItems: boolean;
    hasSelectedMilaProfiService: boolean;
    items: Array<{
      analytics: {
        categories: Array<{
          depth: number;
          label: string;
          url: string;
        }>;
        title: string;
      };
      cart: {
        price: number;
        priceWithoutVat: number;
        quantity: number;
        reference: string | null;
      };
      category: {
        label: string;
        url: string;
      };
      coverLink: string;
      errors: Array<any>;
      expectedRedeliveryDate: null | string;
      manufacturer: {
        id: string;
        name: string;
      };
      name: string;
      priceWithVat: number;
      quantity: number;
      reference: string | null;
      settings: ProductResponseSettingsType;
      single: {
        currency: string;
        discount: number;
        discountWithoutVat: number;
        final: number;
        finalWithoutVat: number;
        price: number;
        priceWithoutVat: number;
      };
      sku: number;
      total: {
        currency: string;
        discount: number;
        discountWithoutVat: number;
        final: number;
        finalWithoutVat: number;
        price: number;
        priceWithoutVat: number;
      };
    }>;
    phoneNumberRequired: boolean;
    productServicePickupSelected: boolean;
    totalDiscount: number;
    totalDiscountWithoutVat: number;
    totalPrice: number;
    totalPriceWithoutVat: number;
    weight: number;
  };
  confirmationEmail: string;
  debitorAddresses: Array<{
    city: string;
    displayName: string;
    street: string;
    streetAddition: string;
    zip: string;
  }>;
  deviatingShippingAddress: boolean;
  fieldErrors: Array<any>;
  licenceHolderData: string | null;
  mobileNumber: string | null;
  payment: {
    additionalInformation: string | null;
    availablePaymentMethods: Array<{
      additionalCostsInformation: {
        absoluteCosts: number;
        costsInformation: string | null;
      };
      order: number;
      type: string;
    }>;
    datatransPayment: boolean;
    error: boolean;
    type: string;
  };
  prices: {
    dropShipmentTypeCharge: number;
    navisionCheckedDiscount: number;
    partialShippingCosts: number;
    smallQuantitiesCharge: number;
    totalPriceWithDiscounts: number;
    totalPriceWithoutDiscounts: number;
    voucherDiscount: number;
  };
  reference: string | null;
  remarks: string | null;
  separateInvoiceDelivery: boolean;
  shipping: {
    availableShippingTypes: Array<{
      deliveryDays: string | null;
      shippingCosts: number;
      shippingType: {
        information: string | null;
        navIdentifier?: string;
        order: number;
        partialShippingCosts: number;
        phoneNumberRequired: boolean;
        pickupAddress?: {
          addressAsString: string;
          city: string | null;
          contactPerson: string | null;
          displayName: string | null;
          empty: boolean;
          fieldErrors: Array<any>;
          street: string | null;
          streetaddition: null;
          zip: string | null;
        };
        type: SHIPPING_METHOD;
      };
    }>;
    collectOrder: boolean;
    defaultType: string;
    deliveryDate: string | null;
    dropShipmentTypes: {
      availableDropShipmentTypes: Array<{
        type: string;
      }>;
      defaultType: string;
      fieldError: null;
      type: null;
    };
    error: boolean;
    options: {
      availableShippingOptions: Array<{
        charged: boolean;
        navIdentifier: string;
        type: string;
      }>;
      defaultType: string;
      type: null;
    };
    type: string;
  };
  token: string;
  voucher: string | null;
  costCenter?: string;
  costCenterData?: {
    uuid: string | null;
    isApprovalNeeded: boolean;
  };
}

export type CheckoutShippingMethodType = {
  deliveryDays?: Array<Record<string, any>>;
  information: string | null;
  price: number;
  type: SHIPPING_METHOD;
}

export type CheckoutReferencesType = {
  customer: string;
  isCustomerReferenceEnabled: boolean;
  order: string;
  sales: string;
}

export type CheckoutShippingType = {
  deliveryDate: string | null;
  hasError: boolean;
  isAllInStock: boolean;
  isCollectedOrder: boolean;
  methods: CheckoutShippingMethodType[];
  options: {
    options: {
      isExtraCharged: boolean;
      type: SHIPPING_OPTION;
    }[];
    selected: SHIPPING_OPTION;
  };
  selected: SHIPPING_METHOD;
}

export type CheckoutAddressType = {
  name: string | null;
  street: string | null;
  streetAddition: string | null;
  contactPerson: string | null;
  postCode: string | null;
  city: string | null;
  salutation?: SALUTATION;
  firstName?: string | null;
  lastName?: string | null;
  email: string | null;
  phoneNumber: string | null;
}

export type CheckoutAddressesAddressesType = {
  preferred: CheckoutAddressType;
  billing: CheckoutAddressType;
  delivery: CheckoutAddressType;
  saved: CheckoutAddressType[];
  pickup: CheckoutAddressType;
  licenceHolder: CheckoutAddressType;
}

export type CheckoutAddressesType = {
  hasInvalidBillingAddress: boolean;
  hasError: boolean;
  isPhoneNumberRequired: boolean;
  addresses: CheckoutAddressesAddressesType;
  options: {
    default: DROP_SHIPPING_OPTION | null;
    error: string | null;
    options: DROP_SHIPPING_OPTION[];
    selected: DROP_SHIPPING_OPTION | null;
  };
}

export type CheckoutPaymentMethodType = {
  price: number;
  type: string;
}

export type CheckoutPaymentType = {
  isDatatrans: boolean;
  hasError: boolean;
  information: string | null;
  options: CheckoutPaymentMethodType[];
  selected: string | null;
}

export type CheckoutLicenseHolderType = {
  hasError: boolean;
  licenseHolder: string | null;
}

type CheckoutPrices = {
  discount: number;
  dropShipmentCosts?: number | null;
  final: number;
  finalVat: number;
  finalWithoutDiscounts: number;
  partialShippingCosts?: number | null;
  paymentCosts?: number | null;
  priceWithoutVat: number;
  shippingCosts?: number;
  smallQuantitiesCost?: number | null;
};

export type CheckoutDataType = {
  addresses: CheckoutAddressesType;
  confirmationEmail: string;
  licenseHolder: CheckoutLicenseHolderType;
  payment: CheckoutPaymentType;
  prices: CheckoutPrices;
  products: CartProductType[];
  references: CheckoutReferencesType;
  shipping: CheckoutShippingType;
  token: string;
  driverInfo?: string | null;
  costCenter: {
    uuid: string | null;
    isApprovalNeeded: boolean;
  }
}

export interface ShippingAddressRequestType {
  displayName: string | null;
  contactPerson: string | null;
  street: string | null;
  streetaddition: string | null;
  zip: string | null;
  city: string | null;
}

export interface BillingAddressRequestType {
  company: string | null;
  contactPerson: string | null;
  salutation: string | null | SALUTATION;
  firstname: string | null;
  lastname: string | null;
  street: string | null;
  streetaddition: string | null;
  zip: string | null;
  city: string | null;
}

export interface UpdateCheckoutRequestBodyType {
  additionalInformation?: string;
  confirmationEmail: string;
  licenceHolderData?: string;
  mobileNumber: string;
  payment: string;
  reference: string;
  remarks: string;
  shipping: {
    deliveryDate?: string | null;
    dropShipmentTypes?: {
      type: DROP_SHIPPING_OPTION | '';
    };
    options?: {
      type: SHIPPING_OPTION;
    };
    type: SHIPPING_METHOD;
  };
  shippingAddress?: ShippingAddressRequestType & {
    reference: string | null;
  };
  billingAddress?: BillingAddressRequestType;
  driverInfo?: string | null;
}

export interface DatatransInitRequestBodyType {
  urls: Record<string, any>;
  checkoutDetails: CheckoutDetails
}
