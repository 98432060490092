import {
  analyzeSearchEvent,
  analyzeSearchFilterClick,
  analyzeSearchPageView,
  analyzeSuggestedCategoryClick,
  analyzeSuggestedSimilarTermClick,
  getResultsPerPageFromSearchData,
  OSS_INTERACTION_LABEL,
  SEARCH_COMPONENT_CATEGORY,
} from '@client/analytics/redux';
import { analyzeEvent, analyzeView } from '@client/analytics/redux/tealium/actions';
import {
  TEALIUM_INTERACTION_ID,
  TEALIUM_PAGE_TYPE,
  TEALIUM_PROD_ACTION,
  TEALIUM_PROD_LIST,
} from '@client/analytics/types/types';
import {
  SearchAnalyticsSearchOssPropertyBuilder,
} from '@client/analytics/utils/builders/SearchAnalyticsSearchOssPropertyBuilder';
import {
  SearchAnalyticsSearchProductPropertyBuilder,
} from '@client/analytics/utils/builders/SearchAnalyticsSearchProductPropertyBuilder';
import { DEFAULT_SORTING } from '@client/catalog/hooks';
import { fetchCatalogSearch, selectSearch } from '@client/catalog/redux/search/redux';
import { selectSuggestions } from '@client/catalog/redux/suggestions/redux';
import { FILTERS } from '@client/catalog/utils/facets';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { AppState } from '@client/common/redux/store';
import { selectProductsTiles } from '@client/product/redux/products/tile/redux';
import { selectCatalogSettings } from '@client/settings/redux/redux';
import { SettingsCatalogType } from '@client/settings/redux/types';
import { PRODUCT_BOX_STYLE_TYPE } from '@lib/components/ProductList';
import { RouterLocation } from 'connected-react-router';
import {
  all,
  AllEffect,
  ForkEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  take,
  TakeEffect,
  takeLatest,
} from 'redux-saga/effects';
import { CatalogSearchType } from 'b2b-common/core/catalog/Catalog.types';
import { SuggestionsType } from 'b2b-common/core/catalog/Suggestions.types';
import { ProductTileType } from 'b2b-common/core/product/api/ProductTile.types';

function* analyzeSearchEventSaga(): Generator<
  PutEffect | TakeEffect | SelectEffect,
  void,
  ReturnType<typeof fetchCatalogSearch>
  > {
  const fetchCatalogSearchFulfilledAction = yield take(fetchCatalogSearch.type);

  if (fetchCatalogSearchFulfilledAction) {
    const { query: requestedQuery } = fetchCatalogSearchFulfilledAction.payload;
    const queryTerm = new URLSearchParams(requestedQuery).get(FILTERS.query);
    const builder = new SearchAnalyticsSearchOssPropertyBuilder();
    builder.setInteraction(TEALIUM_INTERACTION_ID.searchStart)
      .setTerm(queryTerm)
      .setType(TEALIUM_PROD_LIST.search);

    yield put(analyzeEvent(
      {
        interaction: TEALIUM_INTERACTION_ID.searchStart,
        ...builder.build(),
      },
    ));
  }
}

function* analyzeSearchPageViewSaga(): Generator<
  PutEffect | SelectEffect,
  void,
  RemoteRecord<CatalogSearchType>
  & Record<string, RemoteRecord<ProductTileType>>
  & RouterLocation<AppState>
  & SettingsCatalogType
  > {
  const productBuilder = new SearchAnalyticsSearchProductPropertyBuilder({});
  const ossBuilder = new SearchAnalyticsSearchOssPropertyBuilder();
  const search: RemoteRecord<CatalogSearchType> = yield select(selectSearch);
  const productTiles: Record<string, RemoteRecord<ProductTileType>> = yield select(selectProductsTiles);
  const {
    site,
    productsCount,
    query,
    requestedQuery,
    products: productsSkus,
    sortingOptions,
  } = search.data;
  const { page } = site;

  const products = productsSkus
    .filter(product => productTiles[product.sku]?.data)
    .map((product, index) => ({
      ...productTiles[product.sku].data,
      position: index,
    }));

  const sorting = sortingOptions
    ?.reduce((all, option) => [...all, option], [])
    .find(option => option.selected)?.value || DEFAULT_SORTING;

  const originalQuery = query !== requestedQuery
    ? requestedQuery : null;

  const { viewType } = yield select(selectCatalogSettings);

  ossBuilder
    .setInteraction(TEALIUM_INTERACTION_ID.searchPageView)
    .setTerm(query)
    .setOriginalQuery(originalQuery)
    .setSortType(sorting)
    .setResultsPerPage(getResultsPerPageFromSearchData(search.data))
    .setPage(page)
    .setProductsCount(productsCount)
    .setType(TEALIUM_PROD_LIST.search)
    .setListType(viewType || PRODUCT_BOX_STYLE_TYPE.list);

  yield put(analyzeView(
    {
      interaction: productsSkus.length
        ? TEALIUM_INTERACTION_ID.searchPageView
        : TEALIUM_INTERACTION_ID.searchPageEmptyView,
      page: { type: TEALIUM_PAGE_TYPE.productSearch },
      ...ossBuilder.build(),
      ...(products?.length && {
        ...(productBuilder
          .setAction(TEALIUM_PROD_ACTION.productsList)
          .setList(TEALIUM_PROD_LIST.search)
          .setQuantity(productsCount)
          .setProducts(products)
          .build()),
      }),
    },
  ));
}

function* analyzeSuggestedCategoryClickSaga(
  action: ReturnType<typeof analyzeSuggestedCategoryClick>,
): Generator<PutEffect | SelectEffect, void, RemoteRecord<SuggestionsType>> {
  const suggestions: RemoteRecord<SuggestionsType> = yield select(selectSuggestions);
  const facets = suggestions.data.facets.facet;
  const ossBuilder = new SearchAnalyticsSearchOssPropertyBuilder();

  ossBuilder.setInteraction(TEALIUM_INTERACTION_ID.suggestedCategoriesSearchStart)
    .setResultsCount(facets?.length)
    .setTerm(suggestions?.data?.term)
    .setType(TEALIUM_PROD_LIST.search);

  yield put(analyzeEvent({
    interaction: TEALIUM_INTERACTION_ID.suggestedCategoriesSearchStart,
    component: {
      attribute1: [action.payload],
    },
    ...ossBuilder.build(),
  }));
}

function* analyzeSuggestedSimilarTermClickSaga(): Generator<
  PutEffect | SelectEffect,
  void,
  RemoteRecord<SuggestionsType>
  > {
  const suggestions: RemoteRecord<SuggestionsType> = yield select(selectSuggestions);
  const suggestedTerms = suggestions.data.suggestions;
  const ossBuilder = new SearchAnalyticsSearchOssPropertyBuilder();
  ossBuilder.setInteraction(TEALIUM_INTERACTION_ID.similarQueryClick)
    .setResultsCount(suggestedTerms?.length)
    .setTerm(suggestions?.data?.term)
    .setType(TEALIUM_PROD_LIST.search);

  yield put(analyzeEvent({
    interaction: TEALIUM_INTERACTION_ID.similarQueryClick,
    ...ossBuilder.build(),
  }));
}

function* analyzeSearchFilterClickSaga(
  action: ReturnType<typeof analyzeSearchFilterClick>,
): Generator<PutEffect | SelectEffect, void, RemoteRecord<CatalogSearchType>> {
  const search: RemoteRecord<CatalogSearchType> = yield select(selectSearch);
  const filterValue = action.payload.filterValue;
  const ossBuilder = new SearchAnalyticsSearchOssPropertyBuilder();
  ossBuilder
    .setInteraction(TEALIUM_INTERACTION_ID.searchFilterClick)
    .setInteractionLabel(`${OSS_INTERACTION_LABEL.filter}:${action.payload.filterName}`)
    .setResultsCount(search?.data?.productsCount)
    .setTerm(search?.data?.query)
    .setType(TEALIUM_PROD_LIST.search);

  yield put(analyzeEvent({
    interaction: TEALIUM_INTERACTION_ID.searchFilterClick,
    component: {
      attribute1: [action.payload.filterName],
      ...filterValue && { attribute2: [filterValue] },
      attribute3: [action.payload.filterAction],
      category: [SEARCH_COMPONENT_CATEGORY.srchFilter],
    },
    ...ossBuilder.build(),
  }));
}

export function* searchAnalyticsSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(analyzeSearchEvent.type, analyzeSearchEventSaga),
    takeLatest(analyzeSearchPageView.type, analyzeSearchPageViewSaga),
    takeLatest(analyzeSuggestedCategoryClick.type, analyzeSuggestedCategoryClickSaga),
    takeLatest(analyzeSuggestedSimilarTermClick.type, analyzeSuggestedSimilarTermClickSaga),
    takeLatest(analyzeSearchFilterClick.type, analyzeSearchFilterClickSaga),
  ]);
}
