import { ReactElement } from 'react';
import { SCREENSHOT_TOOL_DATA_HIDE } from '@client/common/screenshotTool';
import { isServer } from '@client/common/utils/env';
import { getCookie, setCookie } from '@client/common/utils/storage/cookies';
import { config } from '@client/config';
import { CookieBanner as Banner } from 'b2b-components/theme/components/CookieBanner';
import { useTranslate } from 'b2b-apps/core/i18n/hooks';

export const COOKIE_NAME = 'cookiesEnabled';
const COOKIE_TTL = 365;

export const CookieBanner = (): ReactElement | null => {
  const t = useTranslate();

  const cookieValue = getCookie(COOKIE_NAME);
  const isVisible = config.features.cookieBanner
    && !isServer
    && cookieValue === null;

  const handleAccept = () => {
    setCookie(COOKIE_NAME, true, COOKIE_TTL);
  };

  const text = {
    acceptMessage: t('Accept'),
    rejectMessage: t('Reject'),
    message: t(
      '<b>We use cookies</b> to personalize content, improve your experience and analyze web traffic. Read more: <a href="#" class="cds-Link">terms and conditons</a> · <a href="#" class="cds-Link">data protection</a>',
    ),
  };

  return (
    <Banner
      isVisible={isVisible}
      onAccept={handleAccept}
      text={text}
      dataAttributes={SCREENSHOT_TOOL_DATA_HIDE}
    />
  );
};
