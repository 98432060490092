import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { ensureArray } from '@client/common/utils/array/array';
import { getCdnImageUrl, getProductFallbackThumbnailUrl } from '@client/routes/utils/url';
import { AxiosResponse } from 'axios';
import { ComparedProduct, ProductsCompare as ProductsCompareType, ProductToCompare } from 'b2b-common/core/product/api/ProductsCompare.types';

class ProductsCompare {
  public static fetch(skus: string[] = []): RequestConfigType {
    return RequestConfig.get(`/products/compare/${skus.join(',')}`);
  }

  public static mapResponseToState(response: AxiosResponse<ProductsCompareType>): ComparedProduct[] {
    return ensureArray(response.data).map((product: ProductToCompare): ComparedProduct => {
      const { cover, ...details } = product.details;

      return {
        ...product,
        details: {
          ...details,
          imageUrl: getCdnImageUrl(product.details.cover.sizes.find(sizeData => sizeData.size === 'XXL').link)
            || getProductFallbackThumbnailUrl(),
        },
      };
    });
  }
}

export { ProductsCompare };
