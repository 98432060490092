import { ReactElement } from 'react';
import classNames from 'classnames';
import { Props } from './index';

export const Full = ({ children, className, header }: Props): ReactElement => (
  <>
    {header.length > 0 && (
      <div className="cds-cms-LayoutBlock--Banner">{header}</div>
    )}

    <div className={classNames('cds-cms-LayoutBlock--Main', className)}>
      {children}
    </div>
  </>
);
