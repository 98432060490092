import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { HOT_JAR_TAG } from '@client/analytics/types/types';
import { tagHotJarRecording } from '@client/analytics/utils/analytics';
import { useAssortmentLinks } from '@client/app/hooks';
import { useAssortment, useOwnAssortment } from '@client/assortment/hooks';
import { useDeviceType } from '@client/common/hooks';
import { getProfile } from '@client/context/redux/redux';
import { useTranslate } from '@client/i18n/hooks';
import { FlyoutCatalog as FlyoutCatalogComponent } from '@lib/components/Header/Flyout';
import { ICON_NAME } from 'b2b-components/theme/components/Icon';
import { Props } from './FlyoutCatalog.types';

export const FlyoutCatalog = ({ className }: Props): ReactElement => {
  const t = useTranslate();
  const deviceType = useDeviceType();
  const profile = useSelector(getProfile);
  const catalog = useAssortment();
  const ownAssortment = useOwnAssortment();
  const { catalogLinks, onLinkClick } = useAssortmentLinks(catalog);

  const handleFlyoutOpen = () => {
    tagHotJarRecording(HOT_JAR_TAG.assortmentFlyoutOpen);
  };

  const catalogWithOwnAssortment = ownAssortment?.length > 0
    ? [
      ...catalog,
      {
        path: [ownAssortment[0].title],
        href: ownAssortment[0].href,
        title: ownAssortment[0].title,
        subtitle: profile?.debitor?.companyName,
        tooltipText: t('Browse the selection of your customer-specific assortment'),
        iconName: ICON_NAME.printAndPromo,
        children: ownAssortment[0].children,
      },
    ]
    : catalog;

  return (
    <FlyoutCatalogComponent
      catalog={catalogWithOwnAssortment}
      catalogLinks={catalogLinks}
      className={className}
      coreAssortmentTooltipText={t('Core assortment')}
      dataAttributes={{ 'data-testid': 'headerFlyoutCatalog' }}
      deviceType={deviceType}
      label={t('Assortment')}
      onFlyoutOpen={handleFlyoutOpen}
      onLinkClick={onLinkClick}
    />
  );
};
