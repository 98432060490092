import { removeCart } from '@client/cart/redux/cart/redux';
import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { PUNCHOUT_TYPE } from '@client/punchout/common/types';
import { CXMLPunchout } from '@client/punchout/cxml/api/CXML';
import { PLACE_CXML_ORDER } from '@client/punchout/cxml/redux/actionTypes';
import { PlaceCXMLOrderAction } from '@client/punchout/cxml/redux/types';
import { ROUTES } from '@client/routes/components/Router/routes';
import { pushToRoute } from '@client/routes/redux/utils';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { CxmlCartResponseType } from 'b2b-common/core/punchout/cxml/cxml.types';

function* placeCXMLOrderSaga(
  action: PlaceCXMLOrderAction,
): Generator<CallEffect | PutEffect | ReturnType<typeof pushToRoute>,
  void,
  AxiosResponse<CxmlCartResponseType>> {
  try {
    const response = yield call(requestApiSaga, CXMLPunchout.fetchCart());

    const {
      cartXml,
      actionUrl,
    } = CXMLPunchout.mapCartResponseToState(response);

    const form = Object.assign(document.createElement('form'), {
      action: actionUrl,
      enctype: 'application/x-www-form-urlencoded',
      id: 'cxml_form',
      method: 'POST',
    });

    form.appendChild(
      Object.assign(document.createElement('input'), {
        type: 'hidden',
        name: 'cXML-urlencoded',
        value: cartXml,
      }),
    );

    document.body && document.body.appendChild(form);
    form.submit();
    document.body && document.body.removeChild(form);
  } catch (error) {
    yield put(reportError({ message: action.type, error }));
  } finally {
    yield put(removeCart());
    yield pushToRoute(ROUTES.punchoutOrderConfirmation, {
      punchoutType: PUNCHOUT_TYPE.cxml,
    });
  }
}

function* cXMLSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(PLACE_CXML_ORDER, placeCXMLOrderSaga),
  ]);
}

export { cXMLSaga };
