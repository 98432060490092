import { retryRequestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Orders } from '@client/myAccount/orders/api';
import {
  ordersSearch,
  ordersSearchFulfilled,
  ordersSearchRejected,
} from '@client/myAccount/orders/redux/ordersSearch/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { OrdersType } from 'b2b-common/core/account/api/orders';

function* ordersSearchSaga(
  action: ReturnType<typeof ordersSearch>,
): Generator<CallEffect | PutEffect, void, AxiosResponse<OrdersType>> {
  const { pageSize, startItem, searchQuery } = action.payload;

  try {
    const { data } = yield call(
      retryRequestApiSaga,
      Orders.search(pageSize, startItem, searchQuery),
    );

    yield put(ordersSearchFulfilled({ data }));
  } catch (error) {
    yield put(ordersSearchRejected({ error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: { searchQuery } }));
  }
}

export function* ordersSearchSagas(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(ordersSearch.type, ordersSearchSaga),
  ]);
}
