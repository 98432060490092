export const DEFAULT_DEBOUNCE_DELAY = 100;

export const debounce = (
  func: (...args: any) => any,
  delay = DEFAULT_DEBOUNCE_DELAY,
  { leading }: {leading?: boolean} = {},
): (...args: any
) => any => {
  let timerId: NodeJS.Timeout;

  return (...args) => {
    if (!timerId && leading) {
      func(...args);
    }
    clearTimeout(timerId);

    timerId = setTimeout(() => func(...args), delay);
  };
};
