import { CrumbType } from '@lib/components/Breadcrumbs';
import { CMS_NEWSROOM_PAGE_ID } from 'b2b-common/core/content/utils/utils';

export enum NEWSROOM_MODULES_NAMES {
  newsroomHeader = 'newsroomHeader',
  newsroomFooter = 'newsroomFooter',
  newsroomCenter = 'newsroomCenter',
  downloadCenter = 'downloadCenter',
}

export const generateNewsroomBreadcrumbs = (
  data: Record<string, any>,
  title: string,
  lang: string,
): CrumbType[] => {
  const crumbs: CrumbType[] = [];
  crumbs.push({ title });

  switch (data.contentTypeId) {
    case CMS_NEWSROOM_PAGE_ID.downloadPage:
    case CMS_NEWSROOM_PAGE_ID.downloadCenter:
      crumbs.push({ title: data.title });
      break;

    case CMS_NEWSROOM_PAGE_ID.singleNews:
      crumbs.push({ title: new Date(data.publish_date).toLocaleString(lang, { year: 'numeric' }) });
      crumbs.push({ title: new Date(data.publish_date).toLocaleString(lang, { month: 'long' }) });
      crumbs.push({ title: data.title });
      break;

    default:
      break;
  }

  return crumbs;
};
