import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReportProductErrorDetails } from 'b2b-common/core/product/api/Product.types';

const initialState = RemoteRecordData.getDefault();

const NAME = 'productReportError';

export const productReportErrorSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState,
  reducers: {
    productReportError: (
      state,
      action: PayloadAction<{ details: ReportProductErrorDetails }>,
    ) => RemoteRecordData.init(),
    productReportErrorFulfilled: (state) => RemoteRecordData.fulfill(null),
    productReportErrorRejected: (
      state,
      action: PayloadAction<{ error: ErrorType }>,
    ) => RemoteRecordData.reject(action.payload.error),
    clearProductReportError: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const {
  productReportError,
  productReportErrorFulfilled,
  productReportErrorRejected,
  clearProductReportError,
} = productReportErrorSlice.actions;

const getState = (state: AppState) => state.product.productReportError;

export const selectProductReportError = createSelector(
  getState,
  (state) => new RemoteRecord(state),
);
