import { ReactElement } from 'react';
import { useTranslate } from '@client/i18n/hooks';
import { Asset as CoreAsset } from '@lib/components/CMS/Asset';
import classNames from 'classnames';
import download from 'downloadjs';
import { SingleNewsroomAssetType } from 'b2b-common/core/content/Newsroom.types';
import fallbackImg from './assets/file-fallback.jpg';

export const Asset = ({
  fileName,
  url,
  thumbnail,
  className,
}: Partial<SingleNewsroomAssetType> & { className?: string }): ReactElement => {
  const t = useTranslate();
  if (!fileName || !url || !thumbnail) {
    return null;
  }

  const handleDownloadButtonClick = (url: string): void => {
    download(url);
  };

  const isImageAsset = url && /\.(jpe?g|png|webp|gif)$/.test(url.toLowerCase());

  return (
    <CoreAsset
      className={classNames(
        className,
        { 'cds-cms-Asset--FallbackImg': !isImageAsset },
      )}
      fileName={fileName}
      onClick={handleDownloadButtonClick}
      text={{ download: t('Download') }}
      thumbnail={isImageAsset ? thumbnail : fallbackImg}
      url={url}
    />
  );
};
