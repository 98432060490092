import { ReactElement, useCallback, useEffect, useRef } from 'react';
import { animateScroll } from 'react-scroll/modules';
import { useTranslate } from '@client/i18n/hooks';
import { Pagination as PaginationComponent } from 'b2b-components/theme/components/Pagination';
import { Props } from './Pagination.types';

const SCROLL_DELAY = 300;

export const Pagination = ({
  className,
  onPageChange,
  page,
  total,
  areButtonsVisible,
}: Props): ReactElement => {
  const t = useTranslate();
  const timeoutRef = useRef(null);

  const handleClick = useCallback((page: number): void => {
    animateScroll.scrollToTop({
      duration: SCROLL_DELAY,
    });
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(
      () => onPageChange(page),
      SCROLL_DELAY,
    );
  }, [onPageChange]);

  const text = {
    previousPage: t('Previous Page'),
    nextPage: t('Next Page'),
  };

  useEffect(() => () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  return (
    <PaginationComponent
      className={className}
      current={page}
      onClick={handleClick}
      text={text}
      total={total}
      arePrevAndNextShown={areButtonsVisible}
    />
  );
};
