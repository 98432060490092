import { ReactElement } from 'react';
import { CART_MESSAGES } from '@client/cart/utils/cart';
import { Toast, TOAST_TYPE } from '@client/common/components/Toast';
import { useTranslate } from '@client/i18n/hooks';
import { Props } from './CartToast.types';

export const CartToast = (props: Props): ReactElement => {
  const t = useTranslate();
  const {
    messageId,
    addedQuantity,
    maxAllowedQuantity,
    productName,
  } = props;

  switch (messageId) {
    case CART_MESSAGES.configurationRequired:
      return (
        <Toast
          type={TOAST_TYPE.error}
          header={t('Cannot add configurable product to cart without configuration.')}
          content={productName}
        />
      );

    case CART_MESSAGES.soldOut:
      return (
        <Toast
          type={TOAST_TYPE.error}
          header={t('Product is sold out.')}
          content={productName}
        />
      );

    case CART_MESSAGES.notAvailable:
    case CART_MESSAGES.notBuyable:
    case CART_MESSAGES.unknownSku:
      return (
        <Toast
          type={TOAST_TYPE.error}
          header={t('Product not available for purchase.')}
          content={productName}
        />
      );

    case CART_MESSAGES.missingPermissions:
    case CART_MESSAGES.missingCertificate:
      return (
        <Toast
          type={TOAST_TYPE.error}
          header={t('You are not allowed to purchase.')}
          content={productName}
        />
      );

    case CART_MESSAGES.quantityReduced:
      return (
        <Toast
          type={TOAST_TYPE.warning}
          header={t(
            'Quantity reduced. Maximum quantity allowed per order is {maxAllowedQuantity}.',
            {
              maxAllowedQuantity,
            },
          )}
          content={productName}
        />
      );

    case CART_MESSAGES.maxOrderQuantityExceeded:
      return (
        <Toast
          type={TOAST_TYPE.error}
          header={t(
            'This is a sell-out item. Available quantity for purchase is {maxAllowedQuantity}.',
            {
              maxAllowedQuantity,
            },
          )}
          content={productName}
        />
      );

    case CART_MESSAGES.maxQuantityReached:
    case CART_MESSAGES.maxOrderQuantityReached:
      return (
        <Toast
          type={TOAST_TYPE.error}
          header={t(
            'Maximum quantity allowed per order is {maxAllowedQuantity}.',
            {
              maxAllowedQuantity,
            },
          )}
          content={productName}
        />
      );

    case CART_MESSAGES.success:
      return (
        <Toast
          type={TOAST_TYPE.success}
          header={t(
            [
              '1 item added to the cart.',
              '{addedQuantity} items added to the cart.',
              'addedQuantity',
            ],
            {
              addedQuantity,
            },
          )}
          content={productName}
        />
      );

    case CART_MESSAGES.addingToCart:
      return (
        <Toast
          type={TOAST_TYPE.info}
          header={t(
            [
              'Adding 1 item to the cart.',
              'Adding {addedQuantity} items to the cart.',
              'addedQuantity',
            ],
            {
              addedQuantity,
            },
          )}
          content={productName}
          isLoading
        />
      );

    case CART_MESSAGES.unknownError:
    default:
      return (
        <Toast
          type={TOAST_TYPE.error}
          header={t('Could not connect to the Cart.')}
          content={productName}
        />
      );
  }
};
