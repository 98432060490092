import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Manufacturer } from '@client/manufacturer/api/Manufacturer';
import {
  fetchManufacturer,
  fetchManufacturerFulfilled,
  fetchManufacturerRejected,
} from '@client/manufacturer/redux/manufacturer/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

function* fetchManufacturerSaga(
  action: ReturnType<typeof fetchManufacturer>,
): Generator<CallEffect | PutEffect, void, AxiosResponse> {
  const manufacturerId = action.payload;

  try {
    const response = yield call(
      requestApiSaga,
      Manufacturer.fetch(manufacturerId),
    );
    yield put(
      fetchManufacturerFulfilled({
        manufacturerId,
        data: Manufacturer.mapResponseToState(response),
      }),
    );
  } catch (error) {
    yield put(fetchManufacturerRejected({ manufacturerId, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: { manufacturerId } }));
  }
}

export function* manufacturerSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([takeLatest(fetchManufacturer.type, fetchManufacturerSaga)]);
}
