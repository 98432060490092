import { addressSaga } from '@client/myAccount/addresses/redux/address/sagas';
import { addressesSaga } from '@client/myAccount/addresses/redux/addresses/sagas';
import { addressesSearchSagas } from '@client/myAccount/addresses/redux/addressesSearch/sagas';
import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';

export function* addressesSagas(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    fork(addressesSaga),
    fork(addressSaga),
    fork(addressesSearchSagas),
  ]);
}
