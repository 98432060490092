import { ReactElement } from 'react';
import { useTranslate } from '@client/i18n/hooks';
import classNames from 'classnames';
import { Button } from 'b2b-components/theme/components/Button';
import { Icon, ICON_NAME, ICON_SIZE } from 'b2b-components/theme/components/Icon';
import { Props } from './ServerFailPage.types';
import './ServerFailPage.scss';

export const ServerFailPage = ({ isErrorTimeout, onReload, className }: Props): ReactElement => {
  const t = useTranslate();

  return (
    <div className={classNames('ServerFailPage', className)}>
      <div className='ServerFailPage-Icon'>
        <Icon name={ICON_NAME.alertWarning} size={ICON_SIZE.large} />
      </div>

      <h1 className='ServerFailPage-Header'>{t('Sorry, content could not be loaded.')}</h1>
      <h2 className='ServerFailPage-Subheader'>
        <span>{t('Error')}: </span>
        {isErrorTimeout ? t('Connection has timed out') : t('Service temporary unavailable')}
      </h2>

      {isErrorTimeout ? (
        <>
          <div className='ServerFailPage-TextLine'>
            {t('Try refreshing the page:')}
          </div>
          <Button onClick={onReload} className='ServerFailPage-Button'>
            {t('Refresh page')}
          </Button>
        </>
      ) : (
        <>
          <div className='ServerFailPage-TextLine'>
            {t('Our technical support has been notified about this issue.')}
          </div>
          <div className='ServerFailPage-TextLine'>
            {t('Please check again in a few moments.')}
          </div>
        </>
      )}
    </div>
  );
};
