import { lazy } from 'react';
import { AccessCallbackDataType } from '@client/routes/components/Router/routes';
import { getLocalisedPaths } from '@client/routes/utils/paths';
import { PERMISSION } from 'b2b-common/core/context/Context.types';
import { DEFAULT_ROUTE } from '../constants';
import { RMAS_ROUTES_NAMES, RmasRouteType } from './types';

export const rmas: RmasRouteType = {
  [RMAS_ROUTES_NAMES.myAccountRmasSearch]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/rmas/search'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/rmas/search" */
      '@client/myAccount/rma/components/SearchPage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }: AccessCallbackDataType): boolean => isLoggedIn
      && permissionsValidator([PERMISSION.accessMyAccountRma]),
  },
  [RMAS_ROUTES_NAMES.rmasSearch]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('rmas/search', true),
    component: lazy(() => import(
      /* webpackChunkName: "rmas/search" */
      '@client/myAccount/rma/components/StandaloneSearchPage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }:
      AccessCallbackDataType): boolean => !isLoggedIn
      || (isLoggedIn && !permissionsValidator([PERMISSION.accessMyAccountRma])),
  },
  [RMAS_ROUTES_NAMES.myAccountRmas]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/rmas/:page?'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/rmas" */
      '@client/myAccount/rma/components/ListPage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }: AccessCallbackDataType): boolean => isLoggedIn
      && permissionsValidator([PERMISSION.accessMyAccountRma]),
  },
  [RMAS_ROUTES_NAMES.myAccountCreateRma]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/rma/create/:type/:invoiceId/:sku?'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/rma/create" */
      '@client/myAccount/rma/components/CreatePage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }: AccessCallbackDataType): boolean => isLoggedIn
      && permissionsValidator([PERMISSION.accessMyAccountRma]),
  },
  [RMAS_ROUTES_NAMES.createRma]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('rma/create/:type/:debitorId/:invoiceId/:sku?'),
    component: lazy(() => import(
      /* webpackChunkName: "rma/create" */
      '@client/myAccount/rma/components/StandaloneCreatePage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }: AccessCallbackDataType): boolean => !isLoggedIn
      || (isLoggedIn && !permissionsValidator([PERMISSION.accessMyAccountRma])),
  },
  [RMAS_ROUTES_NAMES.myAccountRma]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/rma/:rmaId'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/rma" */
      '@client/myAccount/rma/components/ViewPage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }: AccessCallbackDataType): boolean => isLoggedIn
      && permissionsValidator([PERMISSION.accessMyAccountRma]),
  },
  [RMAS_ROUTES_NAMES.myAccountRmaConfirmation]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/rma/:rmaId/confirmation'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/rma/confirmation" */
      '@client/myAccount/rma/components/ConfirmationPage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }: AccessCallbackDataType): boolean => isLoggedIn
      && permissionsValidator([PERMISSION.accessMyAccountRma]),
  },
  [RMAS_ROUTES_NAMES.rmaConfirmation]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('rma/confirmation/:debitorId/:rmaId'),
    component: lazy(() => import(
      /* webpackChunkName: "rma/confirmation" */
      '@client/myAccount/rma/components/StandaloneConfirmationPage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }: AccessCallbackDataType): boolean => !isLoggedIn
      || (isLoggedIn && !permissionsValidator([PERMISSION.accessMyAccountRma])),
  },
  [RMAS_ROUTES_NAMES.myAccountUpdateRma]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('my-account/rma/:rmaId/update'),
    component: lazy(() => import(
      /* webpackChunkName: "my-account/rma/update" */
      '@client/myAccount/rma/components/UpdatePage'
    )),
    allow: ({ isLoggedIn, permissionsValidator }: AccessCallbackDataType): boolean => isLoggedIn
      && permissionsValidator([PERMISSION.accessMyAccountRma]),
  },
};
