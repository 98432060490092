import { analyzeEvent } from '@client/analytics/redux/tealium/actions';
import { TEALIUM_PROD_LIST } from '@client/analytics/types/types';
import { ProductListAnalyticsProductPropertyBuilder } from '@client/analytics/utils/builders/ProductListAnalyticsProductPropertyBuilder';
import { selectSearch } from '@client/catalog/redux/search/redux';
import { getBrandFilterFromSearchParams } from '@client/catalog/utils/filters';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { selectProductsBySkus, selectProductTileBySku } from '@client/product/redux/products/tile/redux';
import { call, CallEffect, put, PutEffect, select, SelectEffect } from 'redux-saga/effects';
import { CatalogSearchType } from 'b2b-common/core/catalog/Catalog.types';
import { ProductTileType } from 'b2b-common/core/product/api/ProductTile.types';
import { AnalyzeProductsInteraction } from '../types';
import { getInteractionProduct } from '../utils';
import { analyzeProductAddToCartFromListInteraction, analyzeProductClickInteraction } from './interactions';

const getBrandInteractionEventSource = (): TEALIUM_PROD_LIST => {
  const brand = getBrandFilterFromSearchParams();

  return `${TEALIUM_PROD_LIST.brand}${brand || '/'}` as TEALIUM_PROD_LIST;
};

export function* analyzeBrandProductListView():
  Generator<
    PutEffect | SelectEffect,
    void,
    & RemoteRecord<CatalogSearchType>
    & Record<string, RemoteRecord<ProductTileType>>
    > {
  const catalogRecord = yield select(selectSearch);
  const productTiles = yield select(selectProductsBySkus, catalogRecord.data.products.map(({ sku }) => sku));
  const catalog = catalogRecord.data;
  const catalogSite = catalog?.site;
  const skuList = catalog?.products;
  const pageSize = catalogSite?.listView.allowedProductsPerPage.find(page => page.current)?.numberOfProducts || 48;
  const page = catalogSite?.page || 1;
  const builder = new ProductListAnalyticsProductPropertyBuilder();
  builder
    .setProductList(productTiles)
    .setSkuList(skuList)
    .setPage(page)
    .setPageSize(pageSize)
    .setPageType(getBrandInteractionEventSource());

  yield put(analyzeEvent(
    builder.build(),
  ));
}

function* analyzeBrandProductInteraction(
  interaction: AnalyzeProductsInteraction,
  sku: string,
  listPosition: number,
  quantity?: number,
): Generator<SelectEffect | CallEffect, void, RemoteRecord<ProductTileType>> {
  const productTileRecord = yield select(selectProductTileBySku, sku);
  const productTile = productTileRecord.data;

  if (!productTile) {
    return;
  }

  yield call(
    interaction,
    getBrandInteractionEventSource(),
    {
      products: [{ ...getInteractionProduct(productTile), listPosition, quantity }],
    },
  );
}

export function* analyzeBrandProductClick(sku: string, listPosition: number): Generator<CallEffect | void> {
  yield call(
    analyzeBrandProductInteraction,
    analyzeProductClickInteraction,
    sku,
    listPosition,
  );
}

export function* analyzeBrandProductAddToCart(sku: string, listPosition: number): Generator<CallEffect, void> {
  yield call(
    analyzeBrandProductInteraction,
    analyzeProductAddToCartFromListInteraction,
    sku,
    listPosition,
    1,
  );
}
