import { retryRequestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Rmas } from '@client/myAccount/rma/api/Rmas';
import {
  fetchRmas,
  fetchRmasFulfilled,
  fetchRmasRejected,
  searchRmas,
  searchRmasFulfilled,
  searchRmasRejected,
} from '@client/myAccount/rma/redux/rmas/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

function* fetchRmasSaga(action: ReturnType<typeof fetchRmas>): Generator<CallEffect | PutEffect, any, AxiosResponse> {
  const { pageSize, startItem } = action.payload;

  try {
    const response = yield call(retryRequestApiSaga, Rmas.fetch(pageSize, startItem));
    yield put(fetchRmasFulfilled(response.data));
  } catch (error) {
    yield put(fetchRmasRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* searchRmasSaga(action: ReturnType<typeof searchRmas>): Generator<CallEffect | PutEffect, any, AxiosResponse> {
  const query = action.payload;

  try {
    const response = yield call(retryRequestApiSaga, Rmas.search(query));
    yield put(searchRmasFulfilled(response.data));
  } catch (error) {
    yield put(searchRmasRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: { query: action.payload } }));
  }
}

export function* rmasSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(fetchRmas.type, fetchRmasSaga),
    takeLatest(searchRmas.type, searchRmasSaga),
  ]);
}
