import { ReactElement } from 'react';
import { SCREENSHOT_TOOL_DATA_ATTR_MPT_ID } from '@client/common/screenshotTool';
import { createKey } from '@client/common/utils/key/key';
import { LinkTile as LinkTileComponent } from '@lib/components/CMS/LinkTile';
import classNames from 'classnames';
import { useLinkHandler } from '../useLinkHandler';
import { Props } from '../';

export const LinkTile = ({ className, componentData }: Props): ReactElement | null => {
  const handleClick = useLinkHandler();
  const linkTileData = componentData.global__link_tile && componentData.global__link_tile.componentData;

  if (!Array.isArray(linkTileData)) {
    return null;
  }

  return (
    <div className={classNames(className)}>
      {componentData.group_label && (
        <h2 className='cds-cms-SectionTitle'>{componentData.group_label}</h2>
      )}
      <div className='cds-cms-GridRow'>
        <div className='cds-cms-ModuleContainer'>
          <div className='cds-cms-GridRow'>
            {linkTileData.map(
              (tile: Record<string, any>, index: number) => {
                const additionalProps = { ...tile.mpt_id && { [SCREENSHOT_TOOL_DATA_ATTR_MPT_ID]: tile.mpt_id } };

                return (
                  <LinkTileComponent
                    {...additionalProps}
                    key={createKey('linktile', tile.url, index)}
                    url={tile.url}
                    label={tile.label}
                    imageUrl={tile.image ? tile.image.url : null}
                    onClick={handleClick}
                    size={tile.size}
                  />
                );
              },
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
