import { useRemoteRecordsAnalytics } from '@client/analytics/hooks';
import { analyzePageView } from '@client/analytics/redux';
import { TEALIUM_INTERACTION_ID, TEALIUM_PAGE_TYPE } from '@client/analytics/types/types';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { StatusCodes } from 'http-status-codes';

interface Props {
  pageType: TEALIUM_PAGE_TYPE;
  remoteRecord?: RemoteRecord<any>;
  data?: Record<string, any>;
  failedPageType?: TEALIUM_PAGE_TYPE;
  isTriggerBlocked?: boolean;
}

export const usePageViewAnalytics = (props: Props): void => {
  const {
    pageType,
    remoteRecord,
    data = {},
    failedPageType,
    isTriggerBlocked = false,
  } = props;
  const hasRecordFailed = remoteRecord?.hasFailed || false;

  useRemoteRecordsAnalytics(
    [remoteRecord].filter(v => !!v),
    () => analyzePageView(
      {
        interaction: data?.interaction || TEALIUM_INTERACTION_ID.generic,
        ...data,
        page: {
          ...data?.page,
          type: hasRecordFailed
            ? failedPageType || pageType
            : pageType,
        },
        server: {
          code: hasRecordFailed
            ? remoteRecord.error?.statusCode || StatusCodes.OK
            : StatusCodes.OK,
          ...data?.server,
        },
      },
    ),
    isTriggerBlocked,
  );
};
