import { MouseEvent, ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAddToCartAction } from '@client/cart/hooks';
import { TOAST_TYPE } from '@client/common/components/Toast';
import emitter, { EVENT } from '@client/common/utils/eventEmitter';
import { showToast } from '@client/common/utils/showToast';
import { useCheckPermissions, useLoginAction, useUserData } from '@client/context/hooks';
import { useTranslate } from '@client/i18n/hooks';
import { fetchStampConfigurator } from '@client/product/redux/stampConfigurator/redux';
import { ROUTES } from '@client/routes/components/Router/routes';
import { useOpenLink, useUrlCreator } from '@client/routes/hooks';
import { Alert, ALERT_DISPLAY_TYPE } from '@lib/components/Alert';
import { Modal } from '@lib/components/Modal';
import { ModalButtons } from 'b2b-components/core/components/Modal';
import { PERMISSION } from 'b2b-common/core/context/Context.types';
import { CONFIGURABLE_TYPE } from 'b2b-common/core/product/api/Product.types';
import { AddToCart } from './AddToCart';
import { Props } from './';

export const AddToCartContainer = ({
  sku,
  productName,
  reference,
  isUserCertified,
  onClick,
  onConfiguratorOpen,
  settings,
  type,
  quantityAdding,
  isVisible,
  showUnavailable,
  dataAttributes,
  className,
  shouldHideOnContentMouseOver,
}: Props): ReactElement => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const urlCreator = useUrlCreator();
  const openLink = useOpenLink();
  const { isLoggedIn, isBlocked } = useUserData();
  const addToCart = useAddToCartAction();
  const hasAccessCartPermissions = useCheckPermissions([PERMISSION.accessCart]);
  const isCxml = useCheckPermissions([PERMISSION.cxml]);
  const canEditCxmlCart = useCheckPermissions([PERMISSION.editCxmlCart]);
  const inCxmlInspectMode = isCxml && !canEditCxmlCart;
  const hasCertificationInfo = isLoggedIn
        && !isUserCertified
        && hasAccessCartPermissions
        && !isBlocked;
  const redirectToLogin = useLoginAction();
  const [quantityAdded, setQuantityAdded] = useState(null);
  const { isDisabledByCoreAssortment, configurableType } = settings;

  const handleCloseModal = (event?: MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    setQuantityAdded(null);
  };

  const handleOpenConfigurator = (event: MouseEvent<HTMLButtonElement>, onClose: () => void) => {
    event.stopPropagation();

    if (window.location !== window.parent.location) {
      //TODO: this is workaround for the nesting of multiple of iframes on Ariba
      emitter.on(EVENT.openTrivetConfigurator, (url) => {
        emitter.off(EVENT.openTrivetConfigurator);
        window.open(url, '_self');
      });

      dispatch(fetchStampConfigurator({
        sku,
        partAuxiliaryId: null,
        quantity: quantityAdded,
        skipHookUrlTarget: true,
      }));
      return;
    }

    const configuratorUrl = `${urlCreator(ROUTES.productConfigurator, { sku })}?quantity=${quantityAdded}`;

    onClose();
    onConfiguratorOpen?.();
    openLink(event, configuratorUrl);
  };

  const handleAddToCart = (quantity: number): void => {
    if (!isLoggedIn) {
      return redirectToLogin();
    }

    if (isDisabledByCoreAssortment) {
      showToast(TOAST_TYPE.error, t('This item cannot be purchased due to restrictions in assortment on your account.'), productName);
      return;
    }

    if (isBlocked) {
      showToast(TOAST_TYPE.error, t('You are not allowed to purchase.'), productName);
      return;
    }

    if (inCxmlInspectMode) {
      showToast(TOAST_TYPE.error, t('Cannot modify basket while in inspection mode session.'), productName);
      return;
    }

    if (!hasAccessCartPermissions) {
      showToast(TOAST_TYPE.error, t('Your account role does not allow purchasing.'), productName);
      return;
    }

    if (Object.values(CONFIGURABLE_TYPE).includes(configurableType)) {
      setQuantityAdded(quantity);
      return;
    }

    onClick?.(sku, quantity);
    addToCart({ sku, quantity, productName, reference });
  };

  useEffect(() => () => emitter.off(EVENT.openTrivetConfigurator), []);

  return (
    <>
      <Modal
        title={t('Customizable product')}
        content={(onClose) => (
          <>
            <Alert displayType={ALERT_DISPLAY_TYPE.text}>
              {t('This products must be individually configured before adding to cart.')}
            </Alert>
            <ModalButtons
              cancel={{
                label: t('Cancel'),
                onClick: handleCloseModal,
              }}
              primary={{
                label: t('Open configurator'),
                onClick: (event) => handleOpenConfigurator(event, onClose),
              }}
            />
          </>
        )}
        isOpen={quantityAdded != null}
        onCloseModal={handleCloseModal}
      />

      <AddToCart
        settings={settings}
        type={type}
        onAddToCart={handleAddToCart}
        hasAccessCartPermissions={hasAccessCartPermissions}
        hasCertificationInfo={hasCertificationInfo}
        quantityAdding={quantityAdding}
        isBlocked={isBlocked}
        isLoggedIn={isLoggedIn}
        isVisible={isVisible}
        showUnavailable={showUnavailable}
        text={{
          addToCart: t('Add item to cart'),
          notAvailable: t('This item is no longer available for purchase'),
          notPermitted: t('Your account role does not allow purchasing.'),
          blockedUser: t('You are not allowed to purchase.'),
          isSoldOut: t('Product is sold out.'),
          notBuyable: t('Product not available for purchase.'),
          maximumQuantityReached: (maxQuantity: number) => t('This is a sell-out item. Available quantity for purchase is {maxQuantity}.', { maxQuantity }),
          maximumOrderQuantityReached: (maxQuantity: number) => t('Maximum quantity allowed per order is {maxQuantity}.', { maxQuantity }),
          notInCoreAssortment: t('This item cannot be purchased due to restrictions in assortment on your account.'),
        }}
        dataAttributes={dataAttributes}
        className={className}
        shouldHideOnContentMouseOver={shouldHideOnContentMouseOver}
      />
    </>
  );
};
