import { ReactElement } from 'react';
import { Toast, TOAST_TYPE } from '@client/common/components/Toast';
import { useTranslate } from '@client/i18n/hooks';
import { PRODUCT_MESSAGES } from '@client/product/utils/product';
import { Props } from './';

export const ProductToast = (props: Props): ReactElement => {
  const t = useTranslate();
  const { messageId } = props;

  switch (messageId) {
    case PRODUCT_MESSAGES.pdfExportError:
      return (
        <Toast
          type={TOAST_TYPE.error}
          header={null}
          content={t('Could not export PDF file. Please try again later.')}
        />
      );

    case PRODUCT_MESSAGES.wishlistsSearchError:
      return (
        <Toast
          type={TOAST_TYPE.error}
          header={null}
          content={t('Could not search wishlists. Please try again later.')}
        />
      );

    default:
      return (
        <Toast
          type={TOAST_TYPE.error}
          header={null}
          content={t('An error occurred. Please try again')}
        />
      );
  }
};
