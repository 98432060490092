import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Configurators } from '@client/product/api/Configurators';
import {
  fetchStampConfigurator,
  fetchStampConfiguratorFulfilled,
  fetchStampConfiguratorRejected,
} from '@client/product/redux/stampConfigurator/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import emitter, { EVENT } from 'b2b-apps/core/common/utils/eventEmitter';
import { StampConfiguratorType } from 'b2b-common/core/product/api/ProductsCompare.types';

function* fetchStampConfiguratorSaga(
  action: ReturnType<typeof fetchStampConfigurator>,
): Generator<CallEffect | PutEffect, any, AxiosResponse<StampConfiguratorType>> {
  const { sku, partAuxiliaryId, quantity, skipHookUrlTarget } = action.payload;

  try {
    const response = yield call(
      requestApiSaga,
      Configurators.getStampConfigurator(sku, partAuxiliaryId, quantity, skipHookUrlTarget),
    );
    yield put(fetchStampConfiguratorFulfilled({ data: response.data }));
    emitter.dispatch(EVENT.openTrivetConfigurator, response.data.url);
  } catch (error) {
    yield put(fetchStampConfiguratorRejected({ error: parseError(error) }));
    yield put(reportError({ message: action.type, error }));
  }
}

export function* stampConfiguratorSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(fetchStampConfigurator.type, fetchStampConfiguratorSaga),
  ]);
}
