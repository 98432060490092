import { ANALYTICS_BOOLEAN } from '@client/analytics/types/types';
import { CheckoutOrderAnalyticsBuilder } from '@client/analytics/utils/builders/CheckoutOrderAnalyticsBuilder';
import { CheckoutBaseAnalyticsBuilder, OrderType } from './CheckoutBaseAnalyticsBuilder';

export class CheckoutConfirmationPageAnalyticsBuilder extends CheckoutBaseAnalyticsBuilder {
  protected order: OrderType;

  public setOrder(order: OrderType): CheckoutBaseAnalyticsBuilder {
    this.order = order;
    return this;
  }

  public build(): any {
    const checkoutBuilder = new CheckoutOrderAnalyticsBuilder();
    checkoutBuilder.setOrder(this.order);

    return {
      ...super.build(),
      ...{ order: checkoutBuilder.build() },
      ...{
        virtualPageView: ANALYTICS_BOOLEAN.true,
      },
    };
  }
}
