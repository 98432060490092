import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NewsroomAssetsType, NewsroomNewsType } from 'b2b-common/core/content/Newsroom.types';

const initialState = {
  assets: RemoteRecordData.getDefault(),
  news: RemoteRecordData.getDefault(),
};

const NAME = 'newsroom';

export const slice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState,
  reducers: {
    fetchNewsroomAssets: (state, action: PayloadAction<{ page: number | undefined }>) => {
      state.assets = RemoteRecordData.init();
      return state;
    },
    fetchNewsroomAssetsFulfilled: (state, action: PayloadAction<{ data: NewsroomAssetsType }>) => {
      state.assets = RemoteRecordData.fulfill(
        action.payload.data,
      );
      return state;
    },
    fetchNewsroomAssetsRejected: (state, action: PayloadAction<{ error: ErrorType }>) => {
      state.assets = RemoteRecordData.reject(
        action.payload.error,
      );
      return state;
    },
    fetchNewsroomNews: (state, action: PayloadAction<{ page: number | undefined }>) => {
      state.news = RemoteRecordData.init();
      return state;
    },
    fetchNewsroomNewsFulfilled: (state, action: PayloadAction<{ data: NewsroomNewsType }>) => {
      state.news = RemoteRecordData.fulfill(
        action.payload.data,
      );
      return state;
    },
    fetchNewsroomNewsRejected: (state, action: PayloadAction<{ error: ErrorType }>) => {
      state.news = RemoteRecordData.reject(
        action.payload.error,
      );
      return state;
    },
    fetchSingleNewsAssets: (state, action: PayloadAction<{ entriesIds: string[] }>) => state,
  },
});

export const {
  fetchNewsroomAssets,
  fetchNewsroomAssetsRejected,
  fetchNewsroomAssetsFulfilled,
  fetchNewsroomNews,
  fetchNewsroomNewsFulfilled,
  fetchNewsroomNewsRejected,
  fetchSingleNewsAssets,
} = slice.actions;

const getState = (state: AppState) => state.content.newsroom;

export const selectNewsroomAssets = createSelector(
  getState,
  (state) => new RemoteRecord<NewsroomAssetsType>(state.assets),
);

export const selectNewsroomNews = createSelector(
  getState,
  (state) => new RemoteRecord<NewsroomNewsType>(state.news),
);
