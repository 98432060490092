import { EntryMapType } from 'b2b-common/core/content/Content.types';
import { LANGUAGE_CODE } from 'b2b-common/core/i18n/consts';

export const getUrlsForLanguage = (
  entriesMap: EntryMapType[],
  language: LANGUAGE_CODE,
): string[] => (
  entriesMap.reduce(
    (urls: string[], entry: EntryMapType) => {
      const url = entry.url[language];
      if (url) {
        urls.push(url);
      }
      return urls;
    },
    [],
  )
);
