import { castToArray } from '@client/common/utils/array/array';
import { FormikErrors, FormikTouched } from 'formik';

export enum FORM_MODE {
  create = 'create',
  update = 'update',
}

export const getErrorMessage = <V extends Record<string, any> = Record<string, any>>(
  fieldError: FormikErrors<V>[] | FormikErrors<V> | string | string[] | undefined,
  fieldTouched?: boolean | FormikTouched<V>[] | FormikTouched<V>,
): string => fieldError && fieldTouched ? `${fieldError}` : '';

export const isOptionSelected = (
  actual: any[],
  expected: string | number,
): boolean => castToArray(actual).some((val) => val === expected);

export const isAnyOfOptionsSelected = (
  actual: any[],
  expected: (string | number)[],
): boolean => castToArray(actual).some((val) => expected.includes(val));
