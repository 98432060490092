export const hasPermissions = (permissionsRequired: string[], permissionsGiven: string[]): boolean => {
  if (!Array.isArray(permissionsRequired) || !permissionsRequired.length) {
    return true;
  }

  if (!Array.isArray(permissionsGiven)) {
    permissionsGiven = [];
  }

  for (const permission of permissionsRequired) {
    if (!permissionsGiven.includes(permission)) {
      return false;
    }
  }

  return true;
};
