import { useDispatch } from 'react-redux';
import { useActiveLanguage } from '@client/i18n/hooks';
import { changeLanguage } from '@client/i18n/redux/redux';
import { isLanguageAvailable } from '@client/i18n/utils/language';
import { LANGUAGE_CODE } from 'b2b-common/core/i18n/consts';

export type UseChangeLanguageActionReturnType = (language: LANGUAGE_CODE) => void;

export const useChangeLanguageAction = (): UseChangeLanguageActionReturnType => {
  const dispatch = useDispatch();
  const activeLanguage = useActiveLanguage();

  return (language: LANGUAGE_CODE): void => {
    if (activeLanguage === language || !isLanguageAvailable(language)) {
      return;
    }

    dispatch(changeLanguage({ from: activeLanguage, to: language }));
  };
};
