import { getCookie, setCookie } from '@client/common/utils/storage/cookies';
import { COMPARE_PRODUCTS_COOKIE_NAME } from '@client/product/redux/productsCompare/types';
import {
  changePageSize,
  changeViewType,
  selectCatalogSettings,
  setIsComparisonBarClosed,
  updateProductsCompareSettings,
} from '@client/settings/redux/redux';
import { AxiosResponse } from 'axios';
import {
  all,
  AllEffect,
  ForkEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { SettingsProductsCompareType } from './types';

export const SETTINGS_COOKIE_NAME = 'settings';

export const getCookieSetting = (key: string, defaultValue: any = null): any => {
  const settings = getCookie(SETTINGS_COOKIE_NAME);

  if (settings && settings[key]) {
    return settings[key] || defaultValue;
  }

  return defaultValue;
};

function* updateSettingsCookieSaga(): Generator<SelectEffect, void, Record<string, any>> {
  const settings = yield select(selectCatalogSettings);

  setCookie(SETTINGS_COOKIE_NAME, settings);
}

function* setIsComparisonBarClosedSaga(action: ReturnType<typeof setIsComparisonBarClosed>): Generator<
  PutEffect,
  void,
  AxiosResponse<SettingsProductsCompareType>
> {
  const cookie = getCookie(COMPARE_PRODUCTS_COOKIE_NAME) as SettingsProductsCompareType;
  const { isClosed } = action.payload;
  const settings = { ...(cookie || {}), isClosed };

  setCookie(COMPARE_PRODUCTS_COOKIE_NAME, settings);
  yield put(updateProductsCompareSettings(settings));
}

export function* settingsSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(changePageSize.type, updateSettingsCookieSaga),
    takeLatest(changeViewType.type, updateSettingsCookieSaga),
    takeEvery(setIsComparisonBarClosed.type, setIsComparisonBarClosedSaga),
  ]);
}
