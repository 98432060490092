import { ReactElement } from 'react';
import { SUGGESTION_TYPE } from '@client/app/components/App/Header/Autosuggest';
import { useTranslate } from '@client/i18n/hooks';
import classNames from 'classnames';
import { FacetType } from 'b2b-common/core/catalog/Suggestions.types';
import { Props } from './Categories.types';
import './Categories.scss';

export const Categories = ({
  activeItemIndex,
  facets,
  term,
  onCategoryClick,
  getCategoryUrl,
  onMouseEnter,
}: Props): ReactElement => {
  const t = useTranslate();

  return (
    <ul className='Autosuggest-List Autosuggest-List--Category'>
      <li className='Autosuggest-ListItem--Header' key='term'>
        {term}
      </li>
      {Array.isArray(facets.facet) && facets.facet.map((facet: FacetType, index: number) => {
        const url = getCategoryUrl(facet.value, facet.term);
        const linkClassName = classNames(
          'Autosuggest-ListLink',
          'Autosuggest-ListLink--Category',
          { 'Autosuggest-ListLink--Active': index === activeItemIndex },
        );
        return (
          <li key={facet.name} onMouseEnter={onMouseEnter(SUGGESTION_TYPE.category, index)}>
            <a href={url} onClick={onCategoryClick(facet)} className={linkClassName}>
              <span className='Autosuggest-ListLabel'>{t('in')}</span>{' '}
              {facet.name}
            </a>
          </li>
        );
      })}
    </ul>
  );
};
