import { TEALIUM_INTERACTION_ID, TEALIUM_PROD_ACTION, TEALIUM_PROD_LIST } from '@client/analytics/types/types';
import { call, CallEffect } from 'redux-saga/effects';
import { analyzeProductsInteraction } from '../interactions';
import { InteractionData } from '../types';

export function* analyzeProductClickInteraction(
  source: TEALIUM_PROD_LIST,
  interactionData: InteractionData,
): Generator<CallEffect, void> {
  yield call(
    analyzeProductsInteraction,
    TEALIUM_INTERACTION_ID.productListClick,
    TEALIUM_PROD_ACTION.productListClick,
    source,
    interactionData,
  );
}

export function* analyzeProductAddToCartFromListInteraction(
  source: TEALIUM_PROD_LIST,
  interactionData: InteractionData,
): Generator<CallEffect, void> {
  yield call(
    analyzeProductsInteraction,
    TEALIUM_INTERACTION_ID.addToCartFromList,
    TEALIUM_PROD_ACTION.addToCartFromList,
    source,
    interactionData,
  );
}
