import { productsSaga as fullSaga } from '@client/product/redux/products/full/sagas';
import { productTilesSaga as tileSaga } from '@client/product/redux/products/tile/sagas';
import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';

export function* productsSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    fork(fullSaga),
    fork(tileSaga),
  ]);
}
