import { ReactElement } from 'react';
import { createKey } from '@client/common/utils/key/key';
import classNames from 'classnames';
import { FormMapper } from '../FormMapper';
import { Props } from '../';

export const Form = ({ className, componentData }: Props): ReactElement | null => (
  <div className={classNames('cds-cms-GridRow', className)}>
    <div className="cds-cms-ModuleContainer">
      <FormMapper
        key={createKey('form', componentData.form_id)}
        formId={componentData.form_id}
      />
    </div>
  </div>
);
