import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { stringify } from '@client/common/utils/queryParams';
import { AxiosResponse } from 'axios';
import { ContentCollectionType } from 'b2b-common/core/content/Content.types';

export class Collection {
  public static fetch(
    collectionId: string,
    filters: Record<string, any>,
  ): RequestConfigType {
    const queryString = stringify({
      filter: filters,
    });

    return RequestConfig.get(
      `/content/collection/${collectionId}?${queryString}`,
      {
        withLocalization: true,
      },
    );
  }

  public static mapResponseToState<T extends ContentCollectionType[]>(response: AxiosResponse<T>): T {
    return response.data;
  }
}
