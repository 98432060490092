import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { AxiosResponse } from 'axios';
import { OciCartResponseType } from 'b2b-common/core/punchout/oci/oci.types';

export class Oci {
  public static fetchCart(): RequestConfigType {
    return RequestConfig.get(
      '/cart/oci',
      {
        withLocalization: false,
      },
    );
  }

  public static mapCartResponse(response: AxiosResponse): OciCartResponseType {
    return response.data;
  }
}
