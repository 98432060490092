import { Analytics } from '@client/analytics/helpers/Analytics';
import { getAnalyticsCommonData } from '@client/analytics/redux';
import {
  analyzeEmmitEventToTealium,
  analyzeEmmitViewToTealium,
  analyzeEvent,
  analyzeView,
} from '@client/analytics/redux/tealium/actions';
import { reportError } from '@client/common/redux/errors/actions';
import { merge } from '@client/common/utils/object/merge';
import cookie from 'js-cookie';
import {
  all,
  AllEffect,
  call,
  CallEffect,
  delay,
  ForkEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeEvery,
} from 'redux-saga/effects';
import { DISABLED_TRACKING_COOKIE_NAME } from 'b2b-server/core/utils/constants';

function* analyzeEventSaga(action: ReturnType<typeof analyzeEvent>): Generator<
  CallEffect | PutEffect, void
> {
  yield delay(Analytics.delayTealiumEvent);
  yield put(analyzeEmmitEventToTealium(action.payload));
}

function* analyzeEmmitEventToTealiumSaga(action: ReturnType<typeof analyzeEmmitEventToTealium>): Generator<
  CallEffect | PutEffect,
  void
> {
  try {
    yield call(Analytics.event.bind(Analytics), { ...action.payload });
  } catch (error: any) {
    yield put(reportError({ message: error?.message, error }));
  }
}

function* analyzeViewSaga(action: ReturnType<typeof analyzeView>): Generator<
  CallEffect | PutEffect | SelectEffect,
  void
> {
  yield delay(Analytics.delayTealiumPageView);
  const commonData = yield select(getAnalyticsCommonData);
  const mergedData = merge(commonData, action.payload);
  yield put(analyzeEmmitViewToTealium(mergedData));
}

function* analyzeEmmitViewToTealiumSaga(action: ReturnType<typeof analyzeEmmitViewToTealium>): Generator<
  CallEffect | PutEffect,
  void
> {
  try {
    yield call(Analytics.pageView.bind(Analytics), { ...action.payload });
  } catch (error: any) {
    yield put(reportError({ message: error?.message, error }));
  }
}

export function* tealiumSagas(): Generator<AllEffect<ForkEffect>, void> {
  const isTrackingDisabled = !!cookie.get(DISABLED_TRACKING_COOKIE_NAME);

  if (isTrackingDisabled) {
    return;
  }

  yield all([
    takeEvery(analyzeEvent.type, analyzeEventSaga),
    takeEvery(analyzeEmmitEventToTealium.type, analyzeEmmitEventToTealiumSaga),
    takeEvery(analyzeView.type, analyzeViewSaga),
    takeEvery(analyzeEmmitViewToTealium.type, analyzeEmmitViewToTealiumSaga),
  ]);
}
