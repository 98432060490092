import { ReactElement } from 'react';
import { createKey } from '@client/common/utils/key/key';
import { Iframe as IframeComponent } from '@lib/components/CMS/Iframe';
import classNames from 'classnames';
import { Props } from '../';

export const Iframe = ({ className, componentData: data }: Props): ReactElement | null => (
  <div className={classNames('cds-cms-GridRow', className)}>
    <div className="cds-cms-IframeContainer">
      <IframeComponent
        height={data.height ? `${data.height}px` : undefined}
        key={createKey('iframe', data.iframe_url)}
        url={data.iframe_url}
      />
    </div>
  </div>
);
