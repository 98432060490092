import { generatePath } from 'react-router-dom';
import { useActiveLanguage } from '@client/i18n/hooks';
import { DEFAULT_ROUTE_PARAMS, RouteType } from '@client/routes/components/Router/routes';
import { LANGUAGE_CODE } from 'b2b-common/core/i18n/consts';

export type UseUrlCreatorReturnType = (
  route: RouteType,
  params?: Record<string, any>,
  language?: LANGUAGE_CODE,
) => string;

export const useUrlCreator = (): UseUrlCreatorReturnType => {
  const activeLanguage = useActiveLanguage();

  return (
    route: RouteType,
    params: Record<string, any> = {},
    language: LANGUAGE_CODE = activeLanguage,
  ) => generatePath(
    route.paths[language],
    {
      ...DEFAULT_ROUTE_PARAMS,
      ...params,
    },
  );
};
