import { ReactElement } from 'react';
import { useTranslate } from '@client/i18n/hooks';
import { Alert, ALERT_DISPLAY_TYPE, ALERT_STYLE_TYPE } from 'b2b-components/theme/components/Alert';

export const ServerForbiddenMessage = (): ReactElement => {
  const t = useTranslate();

  return (
    <Alert displayType={ALERT_DISPLAY_TYPE.text} styleType={ALERT_STYLE_TYPE.warning}>
      {t('This content cannot be displayed due to restrictions on your account')}
    </Alert>
  );
};
