import { ReactElement } from 'react';
import { useDeviceType } from '@client/common/hooks';
import { useTranslate } from '@client/i18n/hooks';
import { BUTTON_ICON_SIZE } from '@lib/components/ButtonIcon';
import { CopyableText as Text } from 'b2b-components/theme/components/CopyableText';
import { Props } from './';

export const CopyableText = ({
  children,
  buttonSize = BUTTON_ICON_SIZE.xsmall,
  isTriggerCopyOnTextClick = true,
  ...props
}: Props): ReactElement => {
  const t = useTranslate();

  const text = {
    tooltipText: t('Copy to clipboard'),
    tooltipCopySuccessText: t('Copied'),
    tooltipCopyFailText: t('Copy failed'),
  };

  const deviceType = useDeviceType();

  return (
    <Text
      {...props}
      buttonSize={buttonSize}
      isTriggerCopyOnTextClick={isTriggerCopyOnTextClick}
      text={text}
      deviceType={deviceType}
    >
      {children}
    </Text>
  );
};
