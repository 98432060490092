import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SuggestionsType } from 'b2b-common/core/catalog/Suggestions.types';

const initialData: SuggestionsType = {
  facets: {
    facet: [],
  },
  products: [],
  suggestions: [],
  term: '',
};

const initialState = Object.assign(
  RemoteRecordData.getDefault(),
  {
    data: initialData,
  },
);

const NAME = 'suggestions';

export const suggestionsSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState,
  reducers: {
    fetchSuggestions: (state, action: PayloadAction<string>) => (
      Object.assign(RemoteRecordData.init(), {
        data: state.data,
      })
    ),
    fetchSuggestionsFulfilled: (state, action: PayloadAction<SuggestionsType>) => (
      RemoteRecordData.fulfill(action.payload)
    ),
    fetchSuggestionsRejected: (state, action: PayloadAction<ErrorType>) => (
      RemoteRecordData.reject(action.payload)
    ),
    clearSuggestions: (state) => (
      initialState
    ),
  },
});

export const {
  fetchSuggestions,
  fetchSuggestionsFulfilled,
  fetchSuggestionsRejected,
  clearSuggestions,
} = suggestionsSlice.actions;

const selectState = (state: AppState) => state.catalog.suggestions;

export const selectSuggestions = createSelector(
  selectState,
  state => new RemoteRecord<SuggestionsType>(state),
);

