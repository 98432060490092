import { ReactElement } from 'react';
import { useDeviceType } from '@client/common/hooks';
import { Tooltip as TooltipComponent } from 'b2b-components/theme/components/Tooltip';
import { DEVICE_TYPE } from 'b2b-components/utils/device';
import { ComponentType, Props } from './';

const Tooltip: ComponentType = ({
  children,
  className,
  content,
  contentClassName,
  triggerClassName,
  wrapperClassName,
  isApplied = true,
  shouldHideOnContentMouseOver,
}: Props): ReactElement => {
  const deviceType = useDeviceType();

  if (!isApplied) {
    return children;
  }

  return (
    <TooltipComponent
      isHoverable={deviceType === DEVICE_TYPE.desktop}
      content={content}
      className={className}
      contentClassName={contentClassName}
      triggerClassName={triggerClassName}
      wrapperClassName={wrapperClassName}
      shouldHideOnContentMouseOver={shouldHideOnContentMouseOver}
    >
      {children}
    </TooltipComponent>
  );
};

export { Tooltip };
