import { Cart } from '@client/cart/api/Cart';
import {
  fetchCartStatus,
  fetchCartStatusFulfilled,
  fetchCartStatusRejected,
} from '@client/cart/redux/cartStatus/redux';
import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, delay, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { CartStatusType } from 'b2b-common/core/cart/api/CartTypes';

const REFRESH_CART_STATUS_DELAY = 60 * 1000;

function* fetchCartStatusSaga(): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<CartStatusType>
  > {
  try {
    const response = yield call(requestApiSaga, Cart.fetchStatus());

    yield put(fetchCartStatusFulfilled(response.data));
  } catch (error) {
    yield put(fetchCartStatusRejected(parseError(error)));
    yield put(reportError({ message: 'fetchCartStatusSaga error', error }));
  }
}

function* refreshCartStatusSaga(): Generator<
  CallEffect | PutEffect,
  void,
  { isTimeout: boolean, isLogout: boolean }
  > {
  yield delay(REFRESH_CART_STATUS_DELAY);
  yield put(fetchCartStatus());
}

export function* cartStatusSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(fetchCartStatus.type, fetchCartStatusSaga),
    takeLatest(fetchCartStatusFulfilled.type, refreshCartStatusSaga),
  ]);
}
