

import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CostCenterOrderVerificationType,
  ExtendedCostCenterOrderType,
} from 'b2b-common/core/account/api/costCenters/CostCenterOrder.types';
import { AddItemsToCartType } from 'b2b-common/core/cart/api/CartTypes';

const NAME = 'orderRequest';

export const orderRequestSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {
    order: {},
    verifications: {},
    approvals: {},
    rejections: {},
    sendToSales: {},
    addToCart: RemoteRecordData.getDefault(),
  },
  reducers: {
    fetchOrderRequestDetail: (state, action: PayloadAction<string>) => {
      state.order[action.payload] = RemoteRecordData.init();
      return state;
    },
    fetchOrderRequestDetailFulfilled: (state, action: PayloadAction<{
      orderId: string;
      data: ExtendedCostCenterOrderType;
    }>) => {
      state.order[action.payload.orderId] = RemoteRecordData.fulfill(action.payload.data);
      return state;
    },
    fetchOrderRequestDetailRejected: (state, action: PayloadAction<{
      orderId: string;
      error: ErrorType;
    }>) => {
      state.order[action.payload.orderId] = RemoteRecordData.reject(action.payload.error);
      return state;
    },
    fetchOrderRequestVerificationData: (state, action: PayloadAction<string>) => {
      state.verifications[action.payload] = RemoteRecordData.init();
      return state;
    },
    fetchOrderRequestVerificationDataFulfilled: (state, action: PayloadAction<{
      orderId: string;
      items: any;
    }>) => {
      state.verifications[action.payload.orderId] = RemoteRecordData.fulfill(action.payload.items);
      return state;
    },
    fetchOrderRequestVerificationDataRejected: (state, action: PayloadAction<{
      orderId: string;
      error: ErrorType;
    }>) => {
      state.verifications[action.payload.orderId] = RemoteRecordData.reject(action.payload.error);
      return state;
    },
    approveOrderRequest: (state, action: PayloadAction<{
      orderId: string;
      remarks: string;
    }>) => {
      state.approvals[action.payload.orderId] = RemoteRecordData.init();
      return state;
    },
    approveOrderRequestFulfilled: (state, action: PayloadAction<{
      orderId: string;
      requesterEmail: string;
    }>) => {
      state.approvals[action.payload.orderId] = RemoteRecordData.fulfill(
        { requesterEmail: action.payload.requesterEmail },
      );
      return state;
    },
    approveOrderRequestRejected: (state, action: PayloadAction<{
      orderId: string;
      error: ErrorType;
    }>) => {
      state.approvals[action.payload.orderId] = RemoteRecordData.reject(
        action.payload.error,
      );
      return state;
    },
    rejectOrderRequest: (state, action: PayloadAction<{
      orderId: string;
      remarks: string;
    }>) => {
      state.rejections[action.payload.orderId] = RemoteRecordData.init();
      return state;
    },
    rejectOrderRequestFulfilled: (state, action: PayloadAction<{
      orderId: string;
      requesterEmail: string;
    }>) => {
      state.rejections[action.payload.orderId] = RemoteRecordData.fulfill({
        requesterEmail: action.payload.requesterEmail,
      });
      return state;
    },
    rejectOrderRequestRejected: (state, action: PayloadAction<{
      orderId: string;
      error: ErrorType;
    }>) => {
      state.rejections[action.payload.orderId] = RemoteRecordData.reject(
        action.payload.error,
      );
      return state;
    },
    sendOrderRequestToSales: (state, action: PayloadAction<{
      orderId: string;
      remarks: string;
    }>) => {
      state.sendToSales[action.payload.orderId] = RemoteRecordData.init();
      return state;
    },
    sendOrderRequestToSalesFulfilled: (state, action: PayloadAction<{
      orderId: string;
      requesterEmail: string;
    }>) => {
      state.sendToSales[action.payload.orderId] = RemoteRecordData.fulfill({
        requesterEmail: action.payload.requesterEmail,
      });
    },
    sendOrderRequestToSalesRejected: (state, action: PayloadAction<{
      orderId: string;
      error: ErrorType;
    }>) => {
      state.sendToSales[action.payload.orderId] = RemoteRecordData.reject(
        action.payload.error,
      );
      return state;
    },
    addOrderRequestToCart: (state, action: PayloadAction<{
      orderUuid: string,
      copyArticleReferences: boolean,
    }>) => {
      state.addToCart = RemoteRecordData.init();
      return state;
    },
    addOrderRequestToCartFulfilled: (state, action: PayloadAction<AddItemsToCartType>) => {
      state.addToCart = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    addOrderRequestToCartRejected: (state, action: PayloadAction<ErrorType>) => {
      state.addToCart = RemoteRecordData.reject(action.payload);
      return state;
    },
    clearAddOrderRequestToCart: (state) => {
      state.addToCart = RemoteRecordData.getDefault();
      return state;
    },
  },
});

export const {
  fetchOrderRequestDetail,
  fetchOrderRequestDetailFulfilled,
  fetchOrderRequestDetailRejected,
  fetchOrderRequestVerificationData,
  fetchOrderRequestVerificationDataFulfilled,
  fetchOrderRequestVerificationDataRejected,
  approveOrderRequest,
  approveOrderRequestFulfilled,
  approveOrderRequestRejected,
  rejectOrderRequest,
  rejectOrderRequestFulfilled,
  rejectOrderRequestRejected,
  sendOrderRequestToSales,
  sendOrderRequestToSalesFulfilled,
  sendOrderRequestToSalesRejected,
  addOrderRequestToCart,
  addOrderRequestToCartFulfilled,
  addOrderRequestToCartRejected,
  clearAddOrderRequestToCart,
} = orderRequestSlice.actions;

const getState = (state: AppState) => state.myAccount.costCenter.orderRequest;

export const selectOrder = createSelector(
  [getState, (state, uuid) => uuid],
  (state, uuid) => new RemoteRecord<ExtendedCostCenterOrderType>(state.order[uuid]),
);

export const selectOrderVerificationRecord = createSelector(
  [getState, (state, uuid) => uuid],
  (state, uuid) => new RemoteRecord<CostCenterOrderVerificationType>(state.verifications[uuid]),
);

export const selectOrderApprovalRecord = createSelector(
  [getState, (state, uuid) => uuid],
  (state, uuid) => (
    state.approvals[uuid]
      ? new RemoteRecord<{ requesterEmail: string }>(state.approvals[uuid])
      : null
  ),
);

export const selectOrderRejectionRecord = createSelector(
  [getState, (state, uuid) => uuid],
  (state, uuid) => state.rejections[uuid]
    ? new RemoteRecord<{ requesterEmail: string }>(state.rejections[uuid])
    : null,
);

export const selectOrderSendToSalesRecord = createSelector(
  [getState, (state, uuid) => uuid],
  (state, uuid) => state.sendToSales[uuid]
    ? new RemoteRecord<{ requesterEmail: string }>(state.sendToSales[uuid])
    : null,
);

export const selectOrderRequestToCartRecord = createSelector(
  getState,
  state => new RemoteRecord<AddItemsToCartType>(state.addToCart),
);
