import { combineSlices } from '@reduxjs/toolkit';
import { checkoutSlice as coreCheckoutSlice } from './checkout/redux';
import { checkoutDetailsSlice } from './details/redux';
import { orderSlice } from './order/redux';

export const checkoutReducer = combineSlices(
  coreCheckoutSlice,
  checkoutDetailsSlice,
  orderSlice,
);
