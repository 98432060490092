import { generatePath } from 'react-router-dom';
import { selectEntriesMap } from '@client/content/redux/entriesMap/redux';
import { changeLanguage } from '@client/i18n/redux/redux';
import { isLanguageAvailable } from '@client/i18n/utils/language';
import { DEFAULT_ROUTE_PARAMS, ROUTES } from '@client/routes/components/Router/routes';
import { getCurrentRoute } from '@client/routes/redux/selectors';
import { getLocation, RouterLocation } from 'connected-react-router';
import { all, AllEffect, ForkEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects';

function* changeLanguageSaga(
  action: ReturnType<typeof changeLanguage>,
): Generator<SelectEffect,
  void,
  RouterLocation<any> & ReturnType<typeof selectEntriesMap>> {
  const {
    from,
    to,
  } = action.payload;
  const homepagePaths: Record<string, any> = ROUTES.homepage.paths;

  if (from === to || !isLanguageAvailable(to)) {
    return;
  }

  let path = generatePath(homepagePaths[to] || '', DEFAULT_ROUTE_PARAMS);
  const { pathname } = yield select(getLocation);
  const { data: entries } = yield select(selectEntriesMap);
  const currentRoute = getCurrentRoute(entries, pathname, from);

  if (!currentRoute?.paths?.[to]) {
    window.location.href = `/${to}/unlocalized-content?from=${pathname}`;
    return;
  }

  if (currentRoute?.paths?.[to] && currentRoute?.allowTranslation) {
    path = generatePath(currentRoute.paths[to] || '', {
      ...DEFAULT_ROUTE_PARAMS,
      ...currentRoute.params,
    });
  }

  window.location.href = path;
}

export function* i18nSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([takeLatest(changeLanguage.type, changeLanguageSaga)]);
}
