import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CheckoutDataType } from 'b2b-common/core/checkout/Checkout.types';

const NAME = 'order';

export const orderSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: RemoteRecordData.getDefault(),
  reducers: {
    clearPlaceOrder: () => RemoteRecordData.getDefault(),
    placeOrder: (state, action: PayloadAction<string>) => RemoteRecordData.init(action.payload),
    placeOrderFulfilled: (state, action: PayloadAction<{ checkoutId: string; data: CheckoutDataType }>) => (
      RemoteRecordData.fulfill(action.payload.data, action.payload.checkoutId)
    ),
    placeOrderRejected: (state, action: PayloadAction<{ checkoutId: string; error: ErrorType }>) => (
      RemoteRecordData.reject(action.payload.error, action.payload.checkoutId)
    ),
  },
});

export const {
  clearPlaceOrder,
  placeOrder,
  placeOrderFulfilled,
  placeOrderRejected,
} = orderSlice.actions;


const getState = (state: AppState) => state.checkout.order;

export const selectPlaceOrder = createSelector(
  getState,
  state => new RemoteRecord<CheckoutDataType>(state),
);
