import { Cart as CartConnector } from '@client/cart/api/Cart';
import {
  addCartToWishlist,
  AddCartToWishlistAction,
  addCartToWishlistFulfilled,
  addCartToWishlistRejected,
  addItemsToCart,
  addItemsToCartFulfilled,
  addItemsToCartRejected,
  delayedUpdateItemReference,
  fetchCart,
  fetchCartFulfilled,
  fetchCartRejected,
  removeCardProduct,
  removeCart,
  removeCartFulfilled,
  removeVoucher,
  updateItemQuantity,
  updateItemQuantityFulfilled,
  updateItemQuantityRejected,
  updateItemReference,
  updateItemReferenceFulfilled,
  updateItemReferenceRejected,
  updateVoucher,
} from '@client/cart/redux/cart/redux';
import { clearCartStatus, replaceCartStatus } from '@client/cart/redux/cartStatus/redux';
import { getStatusFromCart } from '@client/cart/utils/cart';
import { requestApiSaga } from '@client/common/redux/api/sagas';
import { takeLatestActionPerSku } from '@client/common/redux/effects/takeLatestActionPerSku';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { ROUTES } from '@client/routes/components/Router/routes';
import { pushToRoute } from '@client/routes/redux/utils';
import { AxiosResponse } from 'axios';
import {
  all,
  AllEffect,
  call,
  CallEffect,
  delay,
  ForkEffect,
  put,
  PutEffect,
  race,
  RaceEffect,
  SelectEffect,
  take,
  TakeEffect,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  AddItemsToCartType,
  CartProductType,
  CartType,
  WishlistWithAddedCartType,
} from 'b2b-common/core/cart/api/CartTypes';

const REFERENCE_UPDATE_DELAY = 1000;

function* addCartToWishlistSaga(action: AddCartToWishlistAction): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<WishlistWithAddedCartType>
> {
  const name = action.payload;

  try {
    const response = yield call(requestApiSaga, CartConnector.exportToWishlist(name));

    yield put(addCartToWishlistFulfilled({ name, data: response.data }));
  } catch (error) {
    yield put(addCartToWishlistRejected({ name, error: parseError(error) }));
    yield put(reportError({ message: action.type, error, additionalData: { name } }));
  }
}

function* fetchCartSaga(action: ReturnType<typeof fetchCart>): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<CartType>
> {
  try {
    const response = yield call(requestApiSaga, CartConnector.fetch());
    const cart = response.data;

    yield put(fetchCartFulfilled(cart));
    yield put(replaceCartStatus(getStatusFromCart(cart)));
  } catch (error) {
    yield put(fetchCartRejected(parseError(error)));
    yield put(reportError({ message: action.type, error }));
  }
}

function* removeCartSaga(action: ReturnType<typeof removeCart>): Generator<CallEffect | PutEffect, void> {
  try {
    yield call(requestApiSaga, CartConnector.clear());
    yield put(removeCartFulfilled());
    yield put(clearCartStatus());
    yield put(fetchCart());
  } catch (error) {
    yield put(fetchCartRejected(parseError(error)));
    yield put(reportError({ message: action.type, error }));
  }
}

function* removeVoucherSaga(action: ReturnType<typeof removeVoucher>): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<CartType>
> {
  try {
    const response = yield call(requestApiSaga, CartConnector.clearVoucher());
    const cart = response.data;

    yield put(fetchCartFulfilled(cart));
    yield put(replaceCartStatus(getStatusFromCart(cart)));
  } catch (error) {
    yield put(fetchCartRejected(parseError(error)));
    yield put(reportError({ message: action.type, error }));
  }
}

function* updateItemQuantitySaga(action: ReturnType<typeof updateItemQuantity>): Generator<
  CallEffect | PutEffect | SelectEffect,
  void,
  AxiosResponse<CartType> & CartProductType
> {
  const { quantity, sku, partAuxiliaryId } = action.payload;

  try {
    const response = yield call(
      requestApiSaga,
      CartConnector.updateItemQuantity(sku, quantity, partAuxiliaryId),
    );
    const cart = response.data;

    yield put(updateItemQuantityFulfilled({ sku, quantity }));
    yield put(fetchCartFulfilled(cart));
    yield put(replaceCartStatus(getStatusFromCart(cart)));
  } catch (error) {
    yield put(updateItemQuantityRejected({ sku, quantity }));
    yield put(fetchCartRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* delayedUpdateItemReferenceSaga(action: ReturnType<typeof delayedUpdateItemReference>): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<CartType>
> {
  const { reference, sku } = action.payload;

  try {
    const response = yield call(
      requestApiSaga,
      CartConnector.updateItemReference(sku, reference),
    );
    const cart = response.data;

    yield put(updateItemReferenceFulfilled({ sku }));
    yield put(fetchCartFulfilled(cart));
    yield put(replaceCartStatus(getStatusFromCart(cart)));
  } catch (error) {
    yield put(updateItemReferenceRejected({ sku, error: parseError(error) }));
    yield put(fetchCartRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}


function* handleDelay(action: ReturnType<typeof updateItemReference>): Generator<any | PutEffect<any>> {
  yield delay(REFERENCE_UPDATE_DELAY);
  yield put(delayedUpdateItemReference(action.payload));
}

function* updateVoucherSaga(action: ReturnType<typeof updateVoucher>): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<CartType>
> {
  const { code } = action.payload;

  try {
    const response = yield call(requestApiSaga, CartConnector.updateVoucher(code));
    const cart = response.data;

    yield put(fetchCartFulfilled(cart));
    yield put(replaceCartStatus(getStatusFromCart(cart)));
  } catch (error) {
    yield put(fetchCartRejected(parseError(error)));
    yield put(reportError({ message: action.type, error }));
  }
}

function* addItemsToCartSaga(action: ReturnType<typeof addItemsToCart>): Generator<
  CallEffect | PutEffect,
  any,
  AxiosResponse<AddItemsToCartType>
> {
  const items = action.payload;

  try {
    const addItemsResponse = yield call(requestApiSaga, CartConnector.addItems(items));

    yield put(removeCartFulfilled());
    yield put(addItemsToCartFulfilled(addItemsResponse.data));
  } catch (error) {
    yield put(addItemsToCartRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* removeCardProductSaga(action: ReturnType<typeof removeCardProduct>): Generator<
  PutEffect | RaceEffect<TakeEffect> | Generator,
  any,
  AxiosResponse<AddItemsToCartType>
  & { isFulfilled: ReturnType<typeof fetchCartFulfilled>, isRejected: ReturnType<typeof fetchCartRejected> }
> {
  const { sku, quantity, partAuxiliaryId } = action.payload;

  yield put(updateItemQuantity({ sku, quantity: 0, partAuxiliaryId }));

  const { isFulfilled } = yield race({
    isFulfilled: take(fetchCartFulfilled.type),
    isRejected: take(fetchCartRejected.type),
  });

  if (isFulfilled) {
    yield pushToRoute(
      ROUTES.productConfigurator,
      { sku },
      undefined,
      undefined,
      { quantity },
    );
  }
}

export function* cartSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(addCartToWishlist.type, addCartToWishlistSaga),
    takeLatest(fetchCart.type, fetchCartSaga),
    takeLatest(removeCart.type, removeCartSaga),
    takeLatest(removeVoucher.type, removeVoucherSaga),
    takeLatest(updateItemQuantity.type, updateItemQuantitySaga),
    takeLatestActionPerSku(updateItemReference.type, handleDelay),
    takeEvery(delayedUpdateItemReference.type, delayedUpdateItemReferenceSaga),
    takeLatest(updateVoucher.type, updateVoucherSaga),
    takeLatest(addItemsToCart.type, addItemsToCartSaga),
    takeLatest(removeCardProduct.type, removeCardProductSaga),
  ]);
}
