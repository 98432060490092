import { retryRequestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Users } from '@client/myAccount/users/api/Users';
import { fetchUsers, fetchUsersFulfilled, fetchUsersRejected } from '@client/myAccount/users/redux/users/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { UsersType } from 'b2b-common/core/account/api/Users.types';

function* fetchUsersSaga(action: ReturnType<typeof fetchUsers>): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<UsersType>
> {
  const { pageSize, startItem } = action.payload;

  try {
    const response = yield call(
      retryRequestApiSaga,
      Users.fetch(pageSize, startItem),
    );
    yield put(fetchUsersFulfilled(response.data));
  } catch (error) {
    yield put(fetchUsersRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* usersSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(fetchUsers.type, fetchUsersSaga),
  ]);
}
