import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData, RemoteRecordDataType } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestCreateUserType, RequestUpdateUserType, UserType } from 'b2b-common/core/account/api/User.types';

export const userSlice = createSlice({
  name: 'user',
  initialState: {} as Record<string, RemoteRecordDataType<UserType>>,
  reducers: {
    fetchUser: (state, action: PayloadAction<string>) => {
      state[action.payload] = RemoteRecordData.init();
    },
    fetchUserFulfilled: (state, action: PayloadAction<{ accountId: string; data: UserType }>) => {
      state[action.payload.accountId] = RemoteRecordData.fulfill(action.payload.data);
    },
    fetchUserRejected: (state, action: PayloadAction<{ accountId: string; error: ErrorType }>) => {
      state[action.payload.accountId] = RemoteRecordData.reject(action.payload.error);
    },
  },
});

export const {
  fetchUser,
  fetchUserFulfilled,
  fetchUserRejected,
} = userSlice.actions;

export const submitUser = createAction<{
  data: RequestCreateUserType | RequestUpdateUserType,
  accountId?: string
}>('submitUser');

export const removeUser = createAction<string>('removeUser');

const selectState = (state: AppState) => state.myAccount.users.user;

export const selectUser = createSelector(
  [selectState, (state, accountId) => accountId],
  (state, accountId) => new RemoteRecord<UserType>(state[accountId]),
);
