import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { SimilarProducts } from '@client/product/api/SimilarProducts';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { similarProductsFetch, similarProductsFulfilled, similarProductsRejected } from './redux';

function* fetchSimilarProductSaga(
  action: ReturnType<typeof similarProductsFetch>,
): Generator<CallEffect | PutEffect, void, AxiosResponse> {
  const sku = action.payload;
  try {
    const response = yield call(requestApiSaga, SimilarProducts.fetch(sku));

    yield put(similarProductsFulfilled(response.data));
  } catch (error) {
    yield put(similarProductsRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action }));
  }
}

export function* similarProductsSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(similarProductsFetch.type, fetchSimilarProductSaga),
  ]);
}
