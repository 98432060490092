import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';

export class DeliveredOrders {
  public static fetch(startItem: number): RequestConfigType {
    return RequestConfig.get(`/account/delivered-orders?startItem=${startItem}`);
  }

  public static search(startItem: number, searchQuery: string): RequestConfigType {
    return RequestConfig.get(`/account/delivered-orders?startItem=${startItem}&q=${searchQuery}`);
  }
}
