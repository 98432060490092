import { ReactElement, useId } from 'react';
import { withField } from '@client/common/components/Form/withField';
import { STYLE_TYPE } from 'b2b-components/theme/components/Form/common';
import { TEXT_INPUT_TYPE, TextInput } from 'b2b-components/theme/components/Form/TextInput';
import { Props } from './FormFieldText.types';

export const FormFieldText = withField((props: Props): ReactElement => {
  const {
    isHotJarSuppressed = true,
    innerComponentProps,
    field,
    form,
    ...otherProps
  } = props;
  const textInputId = useId();

  return <TextInput
      textInputId={textInputId}
      {...(isHotJarSuppressed && { dataAttributes: { 'data-hj-suppress': true } })}
      {...innerComponentProps}
      {...field}
      {...otherProps}
    />;
});

export {
  TEXT_INPUT_TYPE as FORM_FIELD_TEXT_TYPE,
  STYLE_TYPE as TEXT_STYLE_TYPE,
};
