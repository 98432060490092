import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ManufacturerType } from 'b2b-common/core/manufacturer';

const NAME = 'manufacturer';

export const manufacturerSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {},
  reducers: {
    fetchManufacturer: (state, action: PayloadAction<string>) => {
      state[action.payload] = RemoteRecordData.init();
      return state;
    },
    fetchManufacturerFulfilled: (state, action: PayloadAction<{ manufacturerId: string, data: ManufacturerType }>) => {
      state[action.payload.manufacturerId] = RemoteRecordData.fulfill(
        action.payload.data,
      );
      return state;
    },
    fetchManufacturerRejected: (state, action: PayloadAction<{ manufacturerId: string, error: ErrorType }>) => {
      state[action.payload.manufacturerId] = RemoteRecordData.reject(
        action.payload.error,
      );
      return state;
    },
  },
});

export const {
  fetchManufacturer,
  fetchManufacturerFulfilled,
  fetchManufacturerRejected,
} = manufacturerSlice.actions;

const getState = (state: AppState) => state.manufacturer.manufacturer;

export const selectManufacturer = createSelector(
  [getState, (state, manufacturerId) => manufacturerId],
  (state, manufacturerId) => new RemoteRecord<ManufacturerType>(state[manufacturerId]),
);
