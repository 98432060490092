export enum EVENT {
  submitUserFulfilled = 'submitUserFulfilled',
  submitUserRejected = 'submitUserRejected',
  removeUserFulfilled = 'removeUserFulfilled',
  removeUserRejected = 'removeUserRejected',
  submitAddressFulfilled = 'submitAddressFulfilled',
  submitAddressRejected = 'submitAddressRejected',
  removeAddressFulfilled = 'removeAddressFulfilled',
  removeAddressRejected = 'removeAddressRejected',
  addToWishlistFulfilled = 'addToWishlistFulfilled',
  addToWishlistRejected = 'addToWishlistRejected',
  submitWishlistFulfilled = 'submitWishlistFulfilled',
  submitWishlistRejected = 'submitWishlistRejected',
  removeWishlistFulfilled = 'removeWishlistFulfilled',
  removeWishlistRejected = 'removeWishlistRejected',
  searchWishlistsFulfilled = 'searchWishlistsFulfilled',
  searchWishlistsRejected = 'searchWishlistsRejected',
  submitAccountDetailsFulfilled = 'submitAccountDetailsFulfilled',
  submitAccountDetailsRejected = 'submitAccountDetailsRejected',
  submitCostCenterBudgetFulfilled = 'submitCostCenterBudgetFulfilled',
  submitCostCenterBudgetRejected = 'submitCostCenterBudgetRejected',
  removeCostCenterBudgetFulfilled = 'removeCostCenterBudgetFulfilled',
  removeCostCenterBudgetRejected = 'removeCostCenterBudgetRejected',
  removeCostCenterUserFulfilled = 'removeCostCenterUserFulfilled',
  removeCostCenterUserRejected = 'removeCostCenterUserRejected',
  submitCostCenterAddressFulfilled = 'submitCostCenterAddressFulfilled',
  submitCostCenterAddressRejected = 'submitCostCenterAddressRejected',
  removeCostCenterAddressFulfilled = 'removeCostCenterAddressFulfilled',
  removeCostCenterAddressRejected = 'removeCostCenterAddressRejected',
  searchCostCentersFulfilled = 'searchCostCentersFulfilled',
  searchCostCentersRejected = 'searchCostCentersRejected',
  addToCartFulfilled = 'addToCartFulfilled',
  sendWishlistToSalesFulfilled = 'sendWishlistToSalesFulfilled',
  sendWishlistToSalesRejected = 'sendWishlistToSalesRejected',
  exportWishlistFulfilled = 'exportWishlistFulfilled',
  exportWishlistRejected = 'exportWishlistRejected',
  printWishlistFulfilled = 'printWishlistFulfilled',
  printWishlistRejected = 'printWishlistRejected',
  removeConfigurableProductRejected = 'removeConfigurableProductRejected',
  openTrivetConfigurator = 'openTrivetConfigurator',
  fetchCartCostCentersFulfilled = 'fetchCartCostCentersFulfilled',
  fetchCartCostCentersRejected = 'fetchCartCostCentersRejected',
  selectCartCostCenterFulfilled = 'selectCartCostCenterFulfilled',
  selectCartCostCenterRejected = 'selectCartCostCenterRejected',
  cloneWishlistFulfilled = 'cloneWishlistFulfilled'
}

type Callback = (data?: any) => void;

type Events = {
  [key in EVENT]?: Callback[];
};

class EventEmitter {
  private events: Events = {};

  public dispatch(event: EVENT, data?: any): void {
    if (!this.events[event]) {
      return;
    }

    this.events[event].forEach((callback: Callback) => callback(data));
  }

  public on(event: EVENT, callback: Callback): void {
    if (!this.events[event]) {
      this.events[event] = [];
    }

    this.events[event].push(callback);
  }

  public off(event: EVENT): void {
    if (!this.events[event]) {
      return;
    }

    delete this.events[event];
  }
}

const emitter = new EventEmitter();
export default emitter;
