import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCrumbs, updateCrumbs } from '@client/breadcrumbs/redux/reducer';
import { CrumbType } from '@lib/components/Breadcrumbs';

export const useBreadcrumbs = (breadcrumbs: CrumbType[]): void => {
  const dispatch = useDispatch();
  const current = useSelector(selectCrumbs);

  const updateBreadcrumbs = (crumbs: CrumbType[]): void => {
    try {
      if (JSON.stringify(current) !== JSON.stringify(crumbs)) {
        dispatch(updateCrumbs(crumbs));
      }
    } catch {
      return;
    }
  };

  useEffect(
    (): void => {
      updateBreadcrumbs(breadcrumbs);
    },
    [breadcrumbs],
  );
};
