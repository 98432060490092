import { FC } from 'react';
import { isPrimitive } from '@client/common/utils/object/object';
import { ModuleType } from '@client/content/components/ModularPage';
import { CMS_MODULE_COMPONENT } from 'b2b-common/core/content/consts';
import {
  Banner,
  Card,
  ContactInfo,
  Form,
  Gallery,
  Iframe,
  LinkTile,
  LogoSlider,
  Navigation,
  ProductBoxList,
  ProductBoxListV2,
  RichText,
  SectionTitle,
  TextMedia,
} from '../components/ModularPage/Modules';

export const MODULAR_PAGE_COMPONENT: Record<CMS_MODULE_COMPONENT, FC<any>> = {
  [CMS_MODULE_COMPONENT.banner]: Banner,
  [CMS_MODULE_COMPONENT.card]: Card,
  [CMS_MODULE_COMPONENT.contact]: ContactInfo,
  [CMS_MODULE_COMPONENT.form]: Form,
  [CMS_MODULE_COMPONENT.gallery]: Gallery,
  [CMS_MODULE_COMPONENT.mptId]: null,
  [CMS_MODULE_COMPONENT.socialMedia]: null,
  [CMS_MODULE_COMPONENT.iframe]: Iframe,
  [CMS_MODULE_COMPONENT.linkTile]: LinkTile,
  [CMS_MODULE_COMPONENT.logoSlider]: LogoSlider,
  [CMS_MODULE_COMPONENT.navigation]: Navigation,
  [CMS_MODULE_COMPONENT.productsGroup]: ProductBoxList,
  [CMS_MODULE_COMPONENT.productsGroupV2]: ProductBoxListV2,
  [CMS_MODULE_COMPONENT.productsList]: ProductBoxList,
  [CMS_MODULE_COMPONENT.textEditor]: RichText,
  [CMS_MODULE_COMPONENT.sectionTitle]: SectionTitle,
  [CMS_MODULE_COMPONENT.textMedia]: TextMedia,
};

export const resolveModules = (data: Record<string, any>): any[] => {
  const matchComponent = (key: string): null | string => {
    let result = null;
    Object.keys(MODULAR_PAGE_COMPONENT).forEach((componentId) => {
      switch (true) {
        case new RegExp(`^${componentId}$`, 'gi').test(key):
        case new RegExp(`^global__${componentId}$`, 'gi').test(key):
        case new RegExp(`^module__${componentId}$`, 'gi').test(key):
          result = componentId;
          break;

        default:
          break;
      }
    });
    return result;
  };

  const resolve = (
    data: Record<string, any>,
  ): any[] | Record<string, any> => {
    Object.entries(data).forEach(([fieldName, fieldValue]) => {
      const componentName = matchComponent(fieldName);

      switch (true) {
        case componentName !== null: {
          const parsedData = isPrimitive(fieldValue) ? fieldValue : resolve(fieldValue);
          const arrayData = Array.isArray(parsedData) ? parsedData : [parsedData];
          data[fieldName] = {
            componentName,
            componentData: arrayData,
          };
          break;
        }
        case Array.isArray(fieldValue): {
          const found: any = [];
          Array.isArray(fieldValue)
          && fieldValue.forEach((value) => found.push(resolve(value)));
          data[fieldName] = found;
          break;
        }
        case typeof fieldValue === 'object':
          data[fieldName] = resolve(fieldValue);
          break;

        default:
          break;
      }
    });
    return data;
  };

  const getComponents = (data: Record<string, any>): ModuleType[] => {
    const components: ModuleType[] = [];
    Object.values(data).forEach((field: any): void => {
      switch (true) {
        case !!field.componentName:
          components.push(field);
          break;

        case Array.isArray(field):
          field.forEach((element: any) => {
            const found = getComponents(element);
            found.forEach((obj) => components.push(obj));
          });
          break;

        case typeof field === 'object': {
          const found = getComponents(field);
          found.forEach((obj) => components.push(obj));
          break;
        }
        default:
          break;
      }
    });

    return components;
  };

  return getComponents(resolve(structuredClone(data)));
};
