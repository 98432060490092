import { AppState } from '@client/common/redux/store';
import { CrumbType } from '@lib/components/Breadcrumbs';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

const NAME = 'breadcrumbs';

export const breadcrumbsSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: { crumbs: [] },
  reducers: {
    updateCrumbs: (state, action: PayloadAction<CrumbType[]>) => {
      const currentCrumbs = state.crumbs;
      const crumbs = action.payload;

      try {
        if (JSON.stringify(currentCrumbs) !== JSON.stringify(crumbs)) {
          state.crumbs = action.payload;
        }
      } catch {
        return state;
      }

      return state;
    },
  },
});

export const { updateCrumbs } = breadcrumbsSlice.actions;

export const selectCrumbs = createSelector(
  [state => state],
  (state: AppState) => state.breadcrumbs.crumbs,
);
