import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData, RemoteRecordDataType } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CloneWishlistType,
  RequestCreateWishlistType,
  RequestUpdateWishlistNameType,
  SendToSalesDataType,
  UpdateItemReference,
  WishlistDetailedType,
  WishlistType,
} from 'b2b-common/core/account/api/wishlists';
import { AddItemsToCartType } from 'b2b-common/core/cart/api/CartTypes';

const initialState = {
  addItemsToCart: RemoteRecordData.getDefault(),
  cloneWishlist: RemoteRecordData.getDefault(),
  removeWishlistItems: RemoteRecordData.getDefault(),
  wishlists: {} as Record<string, RemoteRecordDataType<WishlistDetailedType>>,
  updateItemReference: {} as Record<string, RemoteRecordDataType<any>>,
};

export const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    addWishlistItemsToCart: (state, action: PayloadAction<{ wishlistId: string; items: string[] }>) => {
      state.addItemsToCart = RemoteRecordData.init();
    },
    addWishlistItemsToCartFulfilled: (state, action: PayloadAction<AddItemsToCartType>) => {
      state.addItemsToCart = RemoteRecordData.fulfill(action.payload);
    },
    addWishlistItemsToCartRejected: (state, action: PayloadAction<ErrorType>) => {
      state.addItemsToCart = RemoteRecordData.reject(action.payload);
    },
    clearAddWishlistItemsToCart: (state) => {
      state.addItemsToCart = initialState.addItemsToCart;
    },
    cloneWishlist: (state, payload: PayloadAction<{ wishlistId: string; name: string }>) => {
      state.cloneWishlist = RemoteRecordData.init();
    },
    cloneWishlistFulfilled: (state, action: PayloadAction<CloneWishlistType>) => {
      state.cloneWishlist = RemoteRecordData.fulfill(action.payload);
    },
    cloneWishlistRejected: (state, action: PayloadAction<ErrorType>) => {
      state.cloneWishlist = RemoteRecordData.reject(action.payload);
    },
    clearCloneWishlist: (state) => {
      state.cloneWishlist = initialState.cloneWishlist;
    },
    fetchWishlist: (state, action: PayloadAction<string>) => {
      state.wishlists[action.payload] = Object.assign(
        RemoteRecordData.init(),
        {
          data: state.wishlists[action.payload]
            ? state.wishlists[action.payload]?.data
            : undefined,
        },
      );
    },
    fetchWishlistFulfilled: (state, action: PayloadAction<{ wishlistId: string; data: WishlistType }>) => {
      state.wishlists[action.payload.wishlistId] = RemoteRecordData.fulfill(
        action.payload.data,
      );
    },
    fetchWishlistRejected: (state, action: PayloadAction<{ wishlistId: string; error: ErrorType }>) => {
      state.wishlists[action.payload.wishlistId] = RemoteRecordData.reject(
        action.payload.error,
      );
    },
    removeWishlistItems: (state, action: PayloadAction<{ wishlistId: string; skus: string[] }>) => {
      state.removeWishlistItems = RemoteRecordData.init();
    },
    removeWishlistItemsFulfilled: (state) => {
      state.removeWishlistItems = RemoteRecordData.fulfill({});
    },
    removeWishlistItemsRejected: (state, action: PayloadAction<ErrorType>) => {
      state.removeWishlistItems = RemoteRecordData.reject(action.payload);
    },
    clearAccountWishlist: (state, action) => {
      state = initialState;
    },
    delayedUpdateItemReference: (state, action: PayloadAction<{
      wishlistId:string, sku: string, reference: string
    }>) => {
      state.updateItemReference[action.payload.sku] = RemoteRecordData.init();
      return state;
    },
    updateItemReference: (state, action: PayloadAction<{ wishlistId:string, sku: string, reference: string}>) => {
      state.updateItemReference[action.payload.sku] = RemoteRecordData.getDefault();
      return state;
    },
    updateItemReferenceFulfilled: (state, action: PayloadAction<{sku: string}>) => {
      state.updateItemReference[action.payload.sku] = RemoteRecordData.fulfill(null);
      return state;
    },
    updateItemReferenceRejected: (state, action: PayloadAction<{sku: string, error: ErrorType}>) => {
      state.updateItemReference[action.payload.sku] = RemoteRecordData.reject(action.payload.error);
      return state;
    },
  },
});

export const {
  addWishlistItemsToCart,
  addWishlistItemsToCartFulfilled,
  addWishlistItemsToCartRejected,
  clearAddWishlistItemsToCart,
  cloneWishlist,
  cloneWishlistFulfilled,
  cloneWishlistRejected,
  clearCloneWishlist,
  delayedUpdateItemReference,
  updateItemReference,
  updateItemReferenceFulfilled,
  updateItemReferenceRejected,
  fetchWishlist,
  fetchWishlistFulfilled,
  fetchWishlistRejected,
  removeWishlistItems,
  removeWishlistItemsFulfilled,
  removeWishlistItemsRejected,
} = wishlistSlice.actions;

export const addToWishlist = createAction<{ name: string; wishlistId: string; skus: string[] }>('addToWishlist');
export const createAndAddToWishlist = createAction<{ name: string; skus: string[] }>('createAndAddToWishlistAction');
export const createWishlist = createAction<RequestCreateWishlistType>('createWishlist');
export const removeWishlist = createAction<string>('removeWishlist');
export const updateWishlistName = createAction<{ wishlistId: string, data: RequestUpdateWishlistNameType }>('updateWishlistName');
export const updateWishlistReference = createAction<{ wishlistId: string; reference: string }>('updateWishlistReference');
export const updateWishlistItemQuantity = createAction<{
  wishlistId: string;
  sku: string;
  quantity: number;
}>('updateWishlistItemQuantity');
export const removeWishlistItem = createAction<{
  wishlistId: string;
  sku: string;
}>('removeWishlistItem');
export const sendWishlist = createAction<{ wishlistId: string, data: SendToSalesDataType }>('sendWishlist');
export const printWishlist = createAction<{ content: string, fileName: string }>('printWishlist');
export const exportWishlist = createAction<{
  wishlistId: string;
  fileName: string;
  fileType?: string;
}>('exportWishlist');

const selectState = (state: AppState) => state.myAccount.wishlists.wishlist;

export const selectWishlists = createSelector(
  selectState,
  state => state.wishlists,
);
export const selectWishlist = createSelector(
  [selectWishlists, (state, wishlistId) => wishlistId],
  (state, wishlistId) => new RemoteRecord<WishlistDetailedType>(state[wishlistId]),
);
export const selectAddItemsToCartData = createSelector(
  selectState,
  (state) => new RemoteRecord<AddItemsToCartType>(state.addItemsToCart),
);
export const selectRemoveWishlistItemsData = createSelector(
  selectState,
  (state) => new RemoteRecord(state.removeWishlistItems),
);
export const selectCloneWishlistData = createSelector(
  selectState,
  (state) => new RemoteRecord<CloneWishlistType>(state.cloneWishlist),
);
export const selectUpdateItemReferenceBySku = createSelector(
  [selectState, (state, sku: string) => sku],
  (state, sku) => new RemoteRecord<UpdateItemReference>(state.updateItemReference[sku]),
);
