import { config } from '@client/config';
import axios, { AxiosInstance } from 'axios';
import { LANGUAGE_CODE } from 'b2b-common/core/i18n/consts';

export const getApiClient = (language?: LANGUAGE_CODE): AxiosInstance => {
  const headers = {
    'X-Requested-With': 'XMLHttpRequest',
  };
  if (language) {
    headers['Accept-Language'] = language;
  }

  return axios.create({
    withCredentials: true,
    baseURL: config.url.api,
    headers,
  });
};
