import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Homepage } from '@client/content/api/Homepage';
import { fetchHomepage, fetchHomepageFulfilled, fetchHomepageRejected } from '@client/content/redux/homepage/redux';
import { all, call, put, takeEvery } from 'redux-saga/effects';

function* fetchHomepageSaga(action: ReturnType<typeof fetchHomepage>): Generator<any, any, any> {
  try {
    const response = yield call(requestApiSaga, Homepage.fetch());
    yield put(fetchHomepageFulfilled(Homepage.mapResponseToState(response)));
  } catch (error) {
    yield put(fetchHomepageRejected(parseError(error)));
    yield put(reportError({ message: action.type, error }));
  }
}

export function* homepageSaga(): Generator<any, any, any> {
  yield all([takeEvery(fetchHomepage.type, fetchHomepageSaga)]);
}
