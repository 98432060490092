import { popShouldInitContextSync } from '@client/common/utils/syncContext';
import { syncContext } from '@client/context/redux/redux';
import { all, AllEffect, ForkEffect, takeLatest } from 'redux-saga/effects';

function syncContextSaga(): void {
  const shouldInitContextSync = popShouldInitContextSync();

  if (!shouldInitContextSync) {
    window.location.reload();
  }
}

export function* contextSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(syncContext.type, syncContextSaga),
  ]);
}
