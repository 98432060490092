export enum CHECKOUT_TYPE {
  guest = 'guest',
  login = 'w_login',
  req = 'w_req'
}

export enum CHECKOUT_STEP {
  checkoutSignin = 'checkout_signin',
  checkoutRegister = 'checkout_register',
  checkoutAddress = 'checkout_address',
  checkoutDelivery = 'checkout_delivery',
  checkoutPayment = 'checkout_payment',
  checkoutOverview = 'checkout_overview',
  b2bCheckoutShippingAndPayment = 'checkout_shipping_and_payment',
  b2bCheckoutOverview = 'checkout_overview',
}
