import { AnalyzeProductsInteraction, getInteractionProduct } from '@client/analytics/redux';
import { analyzeEvent } from '@client/analytics/redux/tealium/actions';
import { TEALIUM_PROD_LIST } from '@client/analytics/types/types';
import {
  ProductListAnalyticsProductPropertyBuilder,
} from '@client/analytics/utils/builders/ProductListAnalyticsProductPropertyBuilder';
import { selectSimilarProduct, selectSimilarProducts } from '@client/product/redux/similarProducts/redux';
import { call, CallEffect, put, PutEffect, RaceEffect, select, SelectEffect, TakeEffect } from 'redux-saga/effects';
import { ProductTileType } from 'b2b-common/core/product/api/ProductTile.types';
import { analyzeProductAddToCartFromListInteraction, analyzeProductClickInteraction } from './interactions';

export function* analyzeSimilarProductListView():
  Generator<
    CallEffect | PutEffect | SelectEffect,
    void,
    Record<string, ProductTileType>
    > {
  const selectSimilarProductsRecord = yield select(selectSimilarProducts);

  if (!selectSimilarProductsRecord.data) {
    return;
  }

  const similarProductsData = Object.values(selectSimilarProductsRecord.data);
  const builder = new ProductListAnalyticsProductPropertyBuilder();
  builder
    .setProductList(similarProductsData)
    .setSkuList(similarProductsData.filter((product) => !!product).map((product) => ({ sku: product.sku })))
    .setPage(1)
    .setPageSize(similarProductsData.length)
    .setPageType(TEALIUM_PROD_LIST.recommendations);


  yield put(analyzeEvent(
    builder.build(),
  ));
}

function* analyzeRecommendationInteraction(
  interaction: AnalyzeProductsInteraction,
  sku: string,
  listPosition: number,
  quantity?: number,
): Generator<
  SelectEffect | CallEffect | TakeEffect | PutEffect | RaceEffect<TakeEffect>,
  void,
  ProductTileType
> {
  const productTile = yield select(selectSimilarProduct, sku);

  if (!productTile) {
    return null;
  }

  yield call(
    interaction,
    TEALIUM_PROD_LIST.recommendations,
    { products: [{ ...getInteractionProduct(productTile), listPosition, quantity }] },
  );
}

export function* analyzeRecommendationProductClick(sku: string, listPosition: number): Generator<CallEffect, void> {
  yield call(
    analyzeRecommendationInteraction,
    analyzeProductClickInteraction,
    sku,
    listPosition,
  );
}

export function* analyzeRecommendationProductAddToCart(sku: string, listPosition: number): Generator<CallEffect, void> {
  yield call(
    analyzeRecommendationInteraction,
    analyzeProductAddToCartFromListInteraction,
    sku,
    listPosition,
    1,
  );
}
