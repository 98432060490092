import { retryRequestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { BalanceDetails } from '@client/myAccount/balanceDetails/api';
import {
  fetchBalanceDetails,
  fetchBalanceDetailsFulfilled,
  fetchBalanceDetailsRejected,
} from '@client/myAccount/balanceDetails/redux/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

function* fetchBalanceDetailsSaga(
  action: ReturnType<typeof fetchBalanceDetails>,
): Generator<CallEffect | PutEffect, any, AxiosResponse> {
  try {
    const response = yield call(retryRequestApiSaga, BalanceDetails.fetch());
    yield put(
      fetchBalanceDetailsFulfilled(response.data),
    );
  } catch (error) {
    yield put(fetchBalanceDetailsRejected(parseError(error)));
    yield put(reportError({ message: action.type, error }));
  }
}

export function* balanceDetailsSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all(
    [
      takeLatest(fetchBalanceDetails.type, fetchBalanceDetailsSaga),
    ],
  );
}
