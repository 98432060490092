import { ReactElement, useEffect, useRef, useState } from 'react';
import { debounce } from '@client/common/utils/debounce';
import classNames from 'classnames';
import { useStickyHeader } from 'b2b-components/context/StickyHeaderContext';
import { ComponentType } from './';
import './Sticky.scss';

const Sticky: ComponentType = ({
  activeClassName,
  className,
  children,
  isEnabled = true,
  isFixedToWidowTop = false,
}): ReactElement => {
  const [isStickyActive, setIsStickyActive] = useState(false);
  const stickyRef = useRef<HTMLDivElement | null>(null);
  const { bottom } = useStickyHeader();

  const handleWindowEvent = debounce(() => {
    if (!stickyRef.current) {
      return;
    }

    const refPageOffset = stickyRef.current.getBoundingClientRect().top;
    const stickyOffset = parseInt(getComputedStyle(stickyRef.current).top);

    if (isFixedToWidowTop) {
      setIsStickyActive(window.pageYOffset > 0);
    } else {
      setIsStickyActive(refPageOffset <= stickyOffset);
    }
  });

  useEffect(
    () => {
      window.addEventListener('orientationchange', handleWindowEvent);
      window.addEventListener('scroll', handleWindowEvent);

      return () => {
        window.removeEventListener('orientationchange', handleWindowEvent);
        window.removeEventListener('scroll', handleWindowEvent);
      };
    },
    [],
  );

  const rootClassName = classNames(
    {
      Sticky: isEnabled && isStickyActive,
      [activeClassName]: isStickyActive,
    },
    className,
  );


  if (!isEnabled) {
    return <>{children}</>;
  }

  const additionalTopOffset = isFixedToWidowTop ? 0 : bottom;
  const top = !isFixedToWidowTop ? additionalTopOffset : 0;

  return (
    <div
      className={rootClassName}
      style={{ top }}
      ref={stickyRef}
    >
      {children}
    </div>
  );
};

export { Sticky };
