import { combineSlices } from '@reduxjs/toolkit';
import { accountsSlice } from './accounts';
import { addressSlice } from './address';
import { budgetSlice } from './budget';
import { costCenterSlice as coreCostCenterSlice } from './costCenter';
import { costCentersSlice } from './costCenters';
import { orderRequestSlice } from './orderRequest';
import { ordersSlice } from './orders';
import { usersSlice } from './user';

export const costCenterReducer = combineSlices(
  coreCostCenterSlice,
  accountsSlice,
  addressSlice,
  costCentersSlice,
  budgetSlice,
  usersSlice,
  orderRequestSlice,
  ordersSlice,
);
