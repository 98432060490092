import { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFoundPage } from '@client/common/components/NotFoundPage';
import { Props } from './Router.types';

export const Router = ({ routes }: Props): ReactElement => (
  <Switch>
    {routes}
    <Route component={NotFoundPage} key='not-found' />
  </Switch>
);
