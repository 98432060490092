const polyfills: (any | Promise<any>)[] = [];

const isArrayFlatSupported = (): boolean => Array.prototype.flat !== undefined;
const isReplaceAllSupported = (): boolean => String.prototype.replaceAll !== undefined;
// @ts-ignore
const isObjectHasOwnSupported = (): boolean => Object.prototype.hasOwn !== undefined;

const isKeyboardEventKeySupported = (): boolean => {
  try {
    const event = new KeyboardEvent('keydown');
    return event.key !== undefined;
  } catch (e) {
    return false;
  }
};

if (!isArrayFlatSupported()) {
  polyfills.push(
    import(
      'core-js/features/array/flat'
      /* webpackChunkName: "core-js-features-array-flat" */
    ),
  );
}

if (!isObjectHasOwnSupported()) {
  polyfills.push(
    import(
      'core-js/features/object/has-own'
      /* webpackChunkName: "core-js-features-object-has-own" */
    ),
  );
}

if (!isReplaceAllSupported()) {
  polyfills.push(
    import(
      'core-js/features/string/replace-all'
      /* webpackChunkName: "core-js-features-replace-all" */
    ),
  );
}

if (!isKeyboardEventKeySupported()) {
  polyfills.push(
    import(
      'shim-keyboard-event-key'
      /* webpackChunkName: "shim-keyboard-event-key" */
    ),
  );
}

export { polyfills };
