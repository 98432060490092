import { Suggestions } from '@client/catalog/api/Suggestions';
import {
  fetchSuggestions,
  fetchSuggestionsFulfilled,
  fetchSuggestionsRejected,
} from '@client/catalog/redux/suggestions/redux';
import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';

const SUGGESTIONS_DELAY = 200;

function* fetchSuggestionsSaga(
  action: ReturnType<typeof fetchSuggestions>,
): Generator<any, any, any> {
  const query = action.payload;

  try {
    yield delay(SUGGESTIONS_DELAY);
    const response = yield call(requestApiSaga, Suggestions.fetch(query));
    const suggestions = Suggestions.mapResponseToState(response);
    yield put(fetchSuggestionsFulfilled(suggestions));
  } catch (error) {
    yield put(fetchSuggestionsRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: { error: action.payload } }));
  }
}

export function* suggestionsSaga(): Generator<any, any, any> {
  yield all([
    takeLatest(fetchSuggestions.type, fetchSuggestionsSaga),
  ]);
}
