import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BarcodeSearchType } from 'b2b-common/core/catalog/Catalog.types';

const NAME = 'barcodeSearch';

export const barcodeSearchSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {},
  reducers: {
    fetchBarcodeSearch: (state, action: PayloadAction<string>) => {
      state[action.payload] = RemoteRecordData.init();
      return state;
    },
    fetchBarcodeSearchFulfilled: (state, action: PayloadAction<{ barcode: string, data: BarcodeSearchType }>) => {
      state[action.payload.barcode] = RemoteRecordData.fulfill(action.payload.data);
      return state;
    },
    fetchBarcodeSearchRejected: (state, action: PayloadAction<{ barcode: string, error: ErrorType }>) => {
      state[action.payload.barcode] = RemoteRecordData.reject(action.payload.error);
      return state;
    },
    clearBarcodeSearch: (state) => {
      state = {};
      return state;
    },
  },
});

export const {
  fetchBarcodeSearch,
  fetchBarcodeSearchFulfilled,
  fetchBarcodeSearchRejected,
  clearBarcodeSearch,
} = barcodeSearchSlice.actions;

const getState = (state: AppState, barcode: string) => state.catalog.barcodeSearch[barcode];

export const selectBarcodeSearch = createSelector(
  getState,
  (state) => new RemoteRecord<BarcodeSearchType>(state),
);
