import { ReactElement, useEffect } from 'react';
import {
  FormFieldSearchableSelect,
  SEARCHABLE_SELECT_STYLE_TYPE,
} from '@client/common/components/Form/FormFieldSearchableSelect';
import { getErrorMessage } from '@client/common/utils/form/form';
import { useTranslate } from '@client/i18n/hooks';
import { useFormikContext } from 'formik';
import { ModalButtons } from 'b2b-components/core/components/Modal';
import { BUTTON_TYPE } from 'b2b-components/theme/components/Button';
import { ICON_NAME, ICON_SIZE } from 'b2b-components/theme/components/Icon';
import { useDeviceType } from 'b2b-apps/core/common/hooks';
import { isMobile } from 'b2b-apps/core/common/utils/env';
import emitter, { EVENT } from 'b2b-apps/core/common/utils/eventEmitter';
import { FIELD_NAME, FormValuesType } from '../Form.types';
import { Props } from './index';
import './Body.scss';

// up to 4 elements visible on mobile devices
const MOBILE_DROPDOWN_MAX_HEIGHT = 140;
// up to 4 elements visible on desktop devices
const DESKTOP_DROPDOWN_MAX_HEIGHT = 260;

export const Body = ({
  onChange,
  onModalClose,
  options,
  onLoadOptions,
  onCreate,
}: Props): ReactElement => {
  const {
    errors,
    setFormikState,
    handleSubmit,
    isSubmitting,
    touched,
    setSubmitting,
  } = useFormikContext<FormValuesType>();
  const t = useTranslate();
  const deviceType = useDeviceType();

  useEffect(() => {
    emitter.on(EVENT.addToWishlistRejected, () => {
      setSubmitting(false);
    });

    emitter.on(EVENT.searchWishlistsRejected, () => {
      setFormikState((state) => ({
        ...state,
        errors: {
          ...state.errors,
          [FIELD_NAME.selectedWishlist]: t('Could not search wishlists. Please try again later.'),
        },
        touched: { ...state.touched, [FIELD_NAME.selectedWishlist]: true },
      }));
    });

    return () => {
      emitter.off(EVENT.addToWishlistRejected);
      emitter.off(EVENT.searchWishlistsRejected);
    };
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <FormFieldSearchableSelect
        name={FIELD_NAME.selectedWishlist}
        onChange={onChange}
        innerComponentProps={{
          error: getErrorMessage<FormValuesType>(
            errors[FIELD_NAME.selectedWishlist],
            touched[FIELD_NAME.selectedWishlist],
          ),
          isCreatable: true,
          options,
          styleType: SEARCHABLE_SELECT_STYLE_TYPE.outlined,
          text: {
            creating: t('Creating'),
            label: t('Select list'),
            noOptions: t('Enter phrase to create a new wishlist'),
            placeholder: t('Select...'),
            loading: t('Loading...'),
          },
          onLoadOptions,
          isAsynchronous: true,
          ...(isMobile(deviceType)
            ? { maxMenuHeight: MOBILE_DROPDOWN_MAX_HEIGHT }
            : { maxMenuHeight: DESKTOP_DROPDOWN_MAX_HEIGHT }),
          formatCreateLabel: (value: string) => <span
            className='WishlistSelect-CreateLabel'>Create &quot;{value}&quot;</span>,
          onCreateOption: (value: string) => onCreate(value),
        }}
      />

      <ModalButtons
        primary={{
          label: isSubmitting ? t('Submitting...') : t('Add item to wishlist'),
          type: BUTTON_TYPE.submit,
          isLoading: isSubmitting,
        }}
        secondary={{
          icon: ICON_NAME.plus,
          iconSize: ICON_SIZE.small,
          label: t('Create new wishlist'),
          onClick: () => onCreate(),
        }}
        cancel={{
          label: t('Cancel'),
          onClick: onModalClose,
        }}
      />
    </form>
  );
};
