import { ChangeEvent, KeyboardEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  BarcodeAnalyticsProvider,
  useBarcodeAnalytics,
} from '@client/analytics/context/barcode/BarcodeAnalyticsContext';
import { ProductsAnalyticsProvider } from '@client/analytics/context/products/ProductsAnalyticsContext';
import { useSearchAnalytics } from '@client/analytics/context/search/SearchAnalyticsContext';
import { EVENT_SOURCE, TEALIUM_PROD_ACTION } from '@client/analytics/types/types';
import { CodeScannerModal } from '@client/app/components/App/Header/SearchField/CodeScannerModal';
import { useFetchSuggestionsAction, useSuggestionsData } from '@client/catalog/hooks';
import { clearBarcodeSearch } from '@client/catalog/redux/barcodeSearch/redux';
import { FILTERS } from '@client/catalog/utils/facets';
import { useDeviceType } from '@client/common/hooks';
import { isMobile } from '@client/common/utils/env';
import { config } from '@client/config';
import { useTranslate } from '@client/i18n/hooks';
import { ROUTES } from '@client/routes/components/Router/routes';
import { useRouterPush, useUrlCreator } from '@client/routes/hooks';
import { getSearchUrlParams } from '@client/routes/utils/url';
import { SearchField as SearchFieldComponent } from 'b2b-components/theme/components/Header/SearchField';
import { Props } from './SearchField.types';

const MIN_SUGGESTIONS_LENGTH = 2;

export const SearchField = ({
  searchRef,
  onOpenAutosuggest,
  onCloseAutosuggest,
  onInputKeyDown,
  className,
  closeButtonDataAttributes,
  submitButtonDataAttributes,
}: Props): ReactElement => {
  const t = useTranslate();
  const { pathname, search } = useLocation();
  const query = new URLSearchParams(search).get(FILTERS.query) || '';
  const [searchQuery, setSearchQuery] = useState(query);
  const { analyzeBarcodeScan } = useBarcodeAnalytics();
  const previousQueryRef = useRef<string>();
  const push = useRouterPush();
  const urlCreator = useUrlCreator();
  const dispatch = useDispatch();
  const deviceType = useDeviceType();
  const [isCodeScannerOpen, setIsCodeScannerOpen] = useState(false);
  const fetchSuggestions = useFetchSuggestionsAction();
  const suggestions = useSuggestionsData();
  const { analyzeSearchEvent } = useSearchAnalytics();

  useEffect(
    (): void => {
      if (query !== previousQueryRef.current) {
        setSearchQuery(query);
        previousQueryRef.current = query;
      }
    },
    [pathname, query],
  );

  const handleSubmit = (): void => {
    if (searchQuery.length > 0) {
      push(
        `${urlCreator(ROUTES.search)}${getSearchUrlParams(
          searchQuery.trim(),
        )}`,
      );
      analyzeSearchEvent();
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newQuery = event.currentTarget.value;
    setSearchQuery(newQuery);

    if (newQuery && newQuery.length >= MIN_SUGGESTIONS_LENGTH) {
      onOpenAutosuggest();
      fetchSuggestions(newQuery.trim());
    } else {
      onCloseAutosuggest();
    }
  };

  const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      onCloseAutosuggest();
    }

    onInputKeyDown(event);
  };

  const handleClearButtonClick = (): void => {
    setSearchQuery('');
    onCloseAutosuggest();
  };

  const handleScanCodeButtonClick = (): void => {
    setIsCodeScannerOpen(true);
    analyzeBarcodeScan({ barcodeAction: TEALIUM_PROD_ACTION.open });
  };

  const handleCodeScannerModalClose = (): void => {
    setIsCodeScannerOpen(false);
    dispatch(clearBarcodeSearch());
  };

  return (
    <>
      <SearchFieldComponent
        ref={searchRef}
        value={searchQuery}
        onInputChange={handleInputChange}
        onInputKeyDown={handleInputKeyDown}
        onScanCodeButtonClick={config.scandit.enabled ? handleScanCodeButtonClick : undefined}
        onSubmit={handleSubmit}
        isLoading={suggestions.wasStarted && suggestions.isLoading}
        deviceType={deviceType}
        placeholder={isMobile(deviceType)
          ? t('Search')
          : t('Search by name or item number')
        }
        dataAttributes={{ 'data-testid': 'headerSearchField' }}
        onClearButtonClick={handleClearButtonClick}
        className={className}
        submitButtonDataAttributes={submitButtonDataAttributes}
        closeButtonDataAttributes={closeButtonDataAttributes}
      />

      <BarcodeAnalyticsProvider>
        <ProductsAnalyticsProvider eventSource={EVENT_SOURCE.barcode}>
          <CodeScannerModal
            isOpen={isCodeScannerOpen}
            onClose={handleCodeScannerModalClose}
          />
        </ProductsAnalyticsProvider>
      </BarcodeAnalyticsProvider>
    </>
  );
};
