import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { AppVersionModal } from '@client/app/components/App/AppVersionModal';
import { LINK, META } from '@client/app/components/App/consts';
import { CookieBanner } from '@client/app/components/App/CookieBanner';
import { FeatureOnboarding } from '@client/app/components/App/FeatureOnboarding';
import { Footer } from '@client/app/components/App/Footer';
import { GlobalAlert } from '@client/app/components/App/GlobalAlert';
import { Header } from '@client/app/components/App/Header/Header';
import { ContentInfobars } from '@client/app/components/App/Infobars/ContentInfobars';
import { SessionInfobars } from '@client/app/components/App/Infobars/SessionInfobars';
import { ProductsComparisonBar } from '@client/app/components/App/ProductsComparisonBar';
import { Breadcrumbs } from '@client/breadcrumbs/component';
import { BackToTop } from '@client/common/components/BackToTop';
import { Sticky } from '@client/common/components/Sticky';
import { Router } from '@client/routes/components/Router';
import { PrimaryLayout } from '@lib/components/PrimaryLayout';
import classNames from 'classnames';
import { useStickyHeader } from 'b2b-components/context/StickyHeaderContext';
import { HydratedContent } from 'b2b-components/core/components/HydratedContent';
import { useActiveLanguage } from 'b2b-apps/core/i18n/hooks/useActiveLanguage';
import { useTranslate } from 'b2b-apps/core/i18n/hooks/useTranslate';
import { Props } from './App.types';
import { ToastWrapper } from './ToastWrapper';

export const App = ({
  autosuggestRefCallback,
  isAutosuggestOpened,
  onCloseAutosuggest,
  onOpenAutosuggest,
  isProductPageRoute = false,
}: Props): ReactElement => {
  const activeLanguage = useActiveLanguage();
  const { isDisabled, ref } = useStickyHeader();
  const t = useTranslate();

  return (
    <div className='App' onClick={onCloseAutosuggest}>
      <Helmet>
        <html lang={activeLanguage} />
        <title>{t('Shop Homepage\'s title')}</title>
        <meta name='description' content={t('Shop meta description', {}, 'Used as default page description')} />
        {LINK.map((link: any) => (
          <link key={link.href} {...link} />
        ))}
        {META.map((meta: any) => (
          <meta key={meta.content} {...meta} />
        ))}
      </Helmet>

      <Sticky
        activeClassName='App-HeaderSticky'
        isEnabled={!isDisabled}
        isFixedToWidowTop
      >
        <header className='App-Header' ref={ref}>
          <Header
            autosuggestRefCallback={autosuggestRefCallback}
            isAutosuggestOpened={isAutosuggestOpened}
            onCloseAutosuggest={onCloseAutosuggest}
            onOpenAutosuggest={onOpenAutosuggest}
          />
        </header>
      </Sticky>

      <main className={classNames('Main', { 'Main--ProductPage': isProductPageRoute })}>
        <FeatureOnboarding />

        <SessionInfobars />

        <ContentInfobars />

        <Breadcrumbs />

        <GlobalAlert />

        {isProductPageRoute
          ? <Router />
          : <PrimaryLayout><Router /></PrimaryLayout>
        }
      </main>

      <Footer />

      <BackToTop />

      <ToastWrapper />

      <HydratedContent>
        <AppVersionModal />
        <CookieBanner />
        <ProductsComparisonBar />
      </HydratedContent>
    </div>
  );
};
