import { FACET_FILTERS } from '@client/catalog/utils/facets';
import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { ensureArray } from '@client/common/utils/array/array';
import { stringify } from '@client/common/utils/queryParams';
import { config } from '@client/config';
import { AxiosResponse } from 'axios';
import { CatalogSearchType, FilterElement } from 'b2b-common/core/catalog/Catalog.types';

export class Search {
  public static fetch(query: string): RequestConfigType {
    const querySettings = stringify({
      listView: 'listshop',
      searchEarlyFilter: config.features.searchEarlyFilter,
      format: 'json',
    });
    return RequestConfig.get(
      `/catalog/search?${query}&${querySettings}`,
    );
  }

  public static mapResponseToInkManufacturersState(
    response: AxiosResponse<CatalogSearchType>,
  ): FilterElement[] {
    const manufacturersFilter = response
      ?.data
      ?.filters
      ?.find((facet) => facet.field === FACET_FILTERS.inkFinderManufacturer);

    return ensureArray(manufacturersFilter?.elements);
  }

  public static mapResponseToInkModelsState(response: AxiosResponse<CatalogSearchType>): FilterElement[] {
    const modelsFilter = response
      ?.data
      ?.filters
      ?.find((facet) => facet.field === FACET_FILTERS.inkFinderModel);

    return ensureArray(modelsFilter?.elements);
  }

  public static mapResponseToState(response: AxiosResponse<CatalogSearchType>): CatalogSearchType {
    return response.data;
  }
}
