import { ReactElement, useId } from 'react';
import { Provider } from 'react-redux';
import { matchPath } from 'react-router-dom';
import { App } from '@client/app/components/App';
import { DisabledCookiesPage } from '@client/app/components/DisabledCookiesPage';
import { ErrorBoundary } from '@client/app/components/ErrorBoundary';
import { AppState, getStore } from '@client/common/redux/store';
import { isServer } from '@client/common/utils/env';
import { logger } from '@client/common/utils/logger/logger';
import { getAreCookiesEnabled } from '@client/common/utils/userCookie/userCookie';
import { config } from '@client/config';
import { getActiveLanguage } from '@client/i18n/redux/redux';
import { ROUTES, RouteType } from '@client/routes/components/Router/routes';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import packageJson from '../../../../../../package.json';
import './common.scss';
import '@client/app/components/Root.scss';

export const Root = (): ReactElement => {
  let initialState = {} as AppState;
  const id = useId();

  try {
    initialState = JSON.parse(decodeURIComponent(window.__INITIAL_STATE__));
  } catch (error) {
    logger.error('Could not load initial state');
  }

  const history = createBrowserHistory({});
  const store = getStore(history, initialState);
  const activeLanguage = getActiveLanguage(initialState);

  // Init Sentry
  if (process.env.NODE_ENV !== 'development') {
    const sentryKey = config.sentry.publicKey;
    const sentryAppId = config.sentry.appId;
    const sentrySampleRate = config.sentry.sampleRate || 1;

    Sentry.init({
      dsn: `https://${sentryKey}@sentry.io/${sentryAppId}`,
      release: `v${packageJson.version}`,
      environment: config.env,
      initialScope: {
        tags: {
          shop: config.shopName,
        },
      },
      tracesSampleRate: sentrySampleRate,
      ignoreErrors: [
        'https://reactjs.org/docs/error-decoder.html?invariant=422', // There was an error while hydrating this Suspense boundary. Switched to client rendering.
        'https://reactjs.org/docs/error-decoder.html?invariant=423', // There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root...
        'https://reactjs.org/docs/error-decoder.html?invariant=425', // Text content does not match server-rendered HTML...
        'ResizeObserver loop completed with undelivered notifications',
        /Loading CSS chunk \d+ failed/,
        "TypeError: Cannot read properties of null (reading 'postMessage')",
        "Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node",
        'UnhandledRejection Non-Error promise rejection captured with value: Object Not Found Matching Id:3, MethodName:update, ParamCount:4',
      ],
      integrations: [
        new Integrations.BrowserTracing({
          // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(
            history,
            Object.values(ROUTES).reduce(
              (result: { path?: string, exact: boolean }[], route: RouteType) => {
                if (route.paths[activeLanguage]) {
                  result.push({
                    path: route.paths[activeLanguage],
                    exact: route.exact || false,
                  });
                }

                return result;
              },
              [],
            ),
            matchPath,
          ),
        }),
      ],
    });
  }

  if (!isServer && !window.requestId) {
    window.requestId = id;
  }

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          {getAreCookiesEnabled()
            ? <App />
            : <DisabledCookiesPage />
          }
        </ConnectedRouter>
      </Provider>
    </ErrorBoundary>
  );
};
