import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { ContactInformationType } from 'b2b-common/core/account/api/Details.types';

export class Details {
  public static fetch(): RequestConfigType {
    return RequestConfig.get('/account/account-details',
      { withLocalization: false });
  }

  public static updateContactInformation(data: ContactInformationType): RequestConfigType {
    return RequestConfig.put('/account/account-details',
      data,
      { withLocalization: false });
  }
}
