import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { stringify } from '@client/common/utils/queryParams';
import { ChangeCostCenterBudgetingDataType } from '@client/myAccount/costCenters/components/Budgets/ListPage';
import { FormValuesType } from '@client/myAccount/costCenters/components/FormPage';
import { COST_CENTERS_LIST_DEFAULT_PAGE_SIZE } from '@client/myAccount/costCenters/consts';

export class CostCenters {
  public static list(
    startItem = 0,
    pageSize: number = COST_CENTERS_LIST_DEFAULT_PAGE_SIZE,
  ): RequestConfigType {
    return RequestConfig.get(
      `/account/cost-centers?${stringify({ pageSize, startItem })}`,
      {
        withLocalization: false,
      },
    );
  }

  public static search(
    startItem = 0,
    pageSize: number = COST_CENTERS_LIST_DEFAULT_PAGE_SIZE,
    query?: string,
  ): RequestConfigType {
    return RequestConfig.get(
      `/account/cost-centers?${stringify({ pageSize, startItem, query })}`,
      {
        withLocalization: false,
      },
    );
  }

  public static simpleSearch(settings: { query?: string, includeInactive?: boolean } = {}): RequestConfigType {
    return RequestConfig.get(
      `/account/cost-centers/search?${stringify(settings, { skipNulls: true })}`,
      {
        withLocalization: false,
      },
    );
  }

  public static get(costCenterId: string): RequestConfigType {
    return RequestConfig.get(
      `/account/cost-centers/${costCenterId}`,
      {
        withLocalization: false,
      },
    );
  }

  public static create(data: FormValuesType | ChangeCostCenterBudgetingDataType): RequestConfigType {
    return RequestConfig.post(
      '/account/cost-centers',
      data,
    );
  }

  public static update(
    costCenterId: string,
    data: FormValuesType | ChangeCostCenterBudgetingDataType,
  ): RequestConfigType {
    return RequestConfig.put(
      `/account/cost-centers/${costCenterId}`,
      data,
    );
  }

  public static delete(
    costCenterId: string,
  ): RequestConfigType {
    return RequestConfig.delete(
      `/account/cost-centers/${costCenterId}`,
    );
  }
}
