import { MouseEvent } from 'react';
import { useRouterPush } from '@client/routes/hooks';
import { openLink } from '@client/routes/utils/url';

export const useLinkHandler = (): (url: string, e?: MouseEvent<any>) => void => {
  const push = useRouterPush();

  return (url, e) => {
    e?.preventDefault();
    openLink(url, push, e);
  };
};
