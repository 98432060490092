import { lazy } from 'react';
import { getLocalisedPaths } from '@client/routes/utils/paths';
import { PERMISSION } from 'b2b-common/core/context/Context.types';
import { AUTHENTICATED_UNBLOCKED_ROUTE, DEFAULT_ROUTE } from './constants';
import { PUNCHOUT_ROUTES_NAMES, PunchoutRouteType } from './types';

export const punchout: PunchoutRouteType = {
  [PUNCHOUT_ROUTES_NAMES.punchoutLoginUnsuccessful]: {
    ...DEFAULT_ROUTE,
    exact: true,
    paths: getLocalisedPaths('punchout'),
    component: lazy(() => import(
      /* webpackChunkName: "punchoutLoginUnsuccessfulPage" */
      '@client/punchout/common/components/AuthFailPage'
    )),
  },
  [PUNCHOUT_ROUTES_NAMES.punchoutOrderConfirmation]: {
    ...AUTHENTICATED_UNBLOCKED_ROUTE,
    exact: true,
    paths: getLocalisedPaths('punchout/confirmation/:punchoutType'),
    component: lazy(() => import(
      /* webpackChunkName: "punchout/confirmation" */
      '@client/punchout/common/components/ConfirmationPage'
    )),
    allow: ({
      isLoggedIn,
      isBlocked,
      permissionsValidator,
    }) => isLoggedIn
      && !isBlocked
      && (permissionsValidator([PERMISSION.oci])
        || permissionsValidator([PERMISSION.cxml])),
  },
  [PUNCHOUT_ROUTES_NAMES.ociTestForm]: {
    ...DEFAULT_ROUTE,
    paths: getLocalisedPaths('test/oci-form'),
    component: lazy(() => import(
      /* webpackChunkName: "test/oci-form" */
      '@client/punchout/testPages/components/OciTestFormPage'
    )),
  },
  [PUNCHOUT_ROUTES_NAMES.ociTestResult]: {
    ...DEFAULT_ROUTE,
    paths: getLocalisedPaths('test/oci-result'),
    component: lazy(() => import(
      /* webpackChunkName: "test/oci-result" */
      '@client/punchout/testPages/components/OciTestResultPage'
    )),
  },
  [PUNCHOUT_ROUTES_NAMES.cxmlTestForm]: {
    ...DEFAULT_ROUTE,
    paths: getLocalisedPaths('test/cxml-form'),
    component: lazy(() => import(
      /* webpackChunkName: "test/cxml-form" */
      '@client/punchout/testPages/components/CXMLTestFormPage'
    )),
  },
  [PUNCHOUT_ROUTES_NAMES.cxmlTestResult]: {
    ...DEFAULT_ROUTE,
    paths: getLocalisedPaths('test/cxml-result'),
    component: lazy(() => import(
      /* webpackChunkName: "test/cxml-result" */
      '@client/punchout/testPages/components/CXMLTestResultPage'
    )),
  },
};
