export enum FIELD_NAME {
  newWishlistName = 'newWishlistName',
}

export type FormValuesType = {
  [FIELD_NAME.newWishlistName]: string,
}

export interface Props {
  initValue?: string;
  onSubmit: (data: FormValuesType) => void;
  onBack: () => void;
}
