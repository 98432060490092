import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '@client/common/redux/store';
import {
  approveOrderRequest,
  rejectOrderRequest,
  selectOrderApprovalRecord,
  selectOrderRejectionRecord,
  selectOrderSendToSalesRecord,
  sendOrderRequestToSales,
} from '@client/myAccount/costCenters/redux/orderRequest';

type UseOrderRequestActions = {
  approvedRecord: ReturnType<typeof selectOrderApprovalRecord>,
  approve: (remarks?: string) => void,
  rejectedRecord: ReturnType<typeof selectOrderRejectionRecord>,
  reject: (remarks?: string) => void,
  sendToSalesRecord: ReturnType<typeof selectOrderSendToSalesRecord>,
  sendToSales: (remarks?: string) => void,
};

export const useOrderRequestActions = (orderReviewUuid: string): UseOrderRequestActions => {
  const dispatch = useDispatch();

  const approvedRecord = useSelector(
    (state: AppState) => selectOrderApprovalRecord(state, orderReviewUuid),
  );
  const approve = (remarks?: string) => {
    dispatch(approveOrderRequest({ orderId: orderReviewUuid, remarks }));
  };

  const rejectedRecord = useSelector(
    (state: AppState) => selectOrderRejectionRecord(state, orderReviewUuid),
  );

  const reject = (remarks?: string) => {
    dispatch(rejectOrderRequest({ orderId: orderReviewUuid, remarks }));
  };

  const sendToSalesRecord = useSelector(
    (state: AppState) => selectOrderSendToSalesRecord(state, orderReviewUuid),
  );

  const sendToSales = (remarks?: string) => {
    dispatch(sendOrderRequestToSales({ orderId: orderReviewUuid, remarks }));
  };

  return {
    approvedRecord,
    approve,
    rejectedRecord,
    reject,
    sendToSalesRecord,
    sendToSales,
  };
};
