import { ReactElement, ReactNode, useEffect } from 'react';
import { useProductsAnalytics } from '@client/analytics/context/products/ProductsAnalyticsContext';
import { MY_ACCOUNT_MESSAGE, showMyAccountToast } from '@client/myAccount/common/utils/messages';
import emitter, { EVENT } from 'b2b-apps/core/common/utils/eventEmitter';
import { useCreateAndAddToWishlistAction, useCreateWishlistAction } from 'b2b-apps/core/myAccount/wishlists/hooks';
import { ROUTES } from 'b2b-apps/core/routes/components/Router';
import { useUrlCreator } from 'b2b-apps/core/routes/hooks';
import { Form } from './Form';
import { FormValuesType } from './Form/Form.types';

interface Props {
  skus: string[];
  onModalClose: () => void;
  onBack: () => void;
  initValue?: string;
}

export const CreateWishlistContent = ({
  skus,
  onModalClose,
  initValue,
  onBack,
}: Props): ReactElement => {
  const urlCreator = useUrlCreator();
  const createAndAddToWishlistAction = useCreateAndAddToWishlistAction();
  const createWishlistAction = useCreateWishlistAction();

  const { analyzeProductAddToWishlist } = useProductsAnalytics();

  const handleAddToWishlistComplete = (): void => {
    if (skus.length === 1) {
      analyzeProductAddToWishlist({ sku: skus[0] });
    }
  };

  const handleSubmit = ({ newWishlistName }: FormValuesType): void => {
    if (newWishlistName && skus?.length) {
      createAndAddToWishlistAction({
        name: newWishlistName,
        skus,
      });
      handleAddToWishlistComplete();
      return;
    }

    if (newWishlistName) {
      createWishlistAction({ name: newWishlistName });
      return;
    }
  };

  const toastContent = (wishlistId: string, wishlistName: string): ReactNode => (
    <a href={urlCreator(ROUTES.myAccountWishlist, { wishlistId })}>
      {wishlistName}
    </a>
  );

  useEffect(() => {
    emitter.on(EVENT.addToWishlistFulfilled, ({ wishlistId, wishlistName }) => {
      showMyAccountToast(MY_ACCOUNT_MESSAGE.successCreateAndAddToWishlist, toastContent(wishlistId, wishlistName));
      onModalClose();
    });

    return () => {
      emitter.off(EVENT.addToWishlistFulfilled);
    };
  }, []);

  return (
    <Form
      initValue={initValue}
      onSubmit={handleSubmit}
      onBack={onBack}
    />
  );
};
