import { retryRequestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Addresses } from '@client/myAccount/addresses/api/Addresses';
import {
  fetchAddresses,
  fetchAddressesFulfilled,
  fetchAddressesRejected,
} from '@client/myAccount/addresses/redux/addresses/redux';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { AddressType } from 'b2b-common/core/account/api/Address.types';

function* fetchAddressesSaga(
  action: ReturnType<typeof fetchAddresses>,
): Generator<CallEffect | PutEffect, any, AxiosResponse<AddressType>> {
  const { pageSize, startItem } = action.payload;

  try {
    const response = yield call(
      retryRequestApiSaga,
      Addresses.fetch({
        pageSize,
        startItem,
      }),
    );
    yield put(fetchAddressesFulfilled(response.data));
  } catch (error) {
    yield put(fetchAddressesRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* addressesSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all(
    [
      takeLatest(fetchAddresses.type, fetchAddressesSaga),
    ],
  );
}
