import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSkuListSearch, selectSkusFromQuery } from '@client/catalog/redux/skuList/redux';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { AppState } from '@client/common/redux/store';
import { ProductGroupType, SOURCE_TYPE } from '@client/content/common';
import md5 from 'md5';

export const useProductsFromQuery = (product: ProductGroupType): RemoteRecord<string[]> => {
  const {
    source_type: sourceType,
    source_of_products: query,
    random_order: randomOrder,
  } = product;
  const parsedQuery = query?.replace('?', '');
  const dispatch = useDispatch();
  const queryId = md5(parsedQuery.toLowerCase());
  const content = useSelector((state: AppState) => selectSkusFromQuery(state, queryId));

  useEffect((): void => {
    if (sourceType === SOURCE_TYPE.query) {
      dispatch(fetchSkuListSearch({ query: parsedQuery, queryId, randomOrder }));
    }
  }, [parsedQuery, sourceType]);

  return content;
};
