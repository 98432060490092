import { MouseEvent, ReactElement } from 'react';
import BrandLogo from '@client/common/components/Logo/assets/logo.svg';
import './AppCrash.scss';

export const AppCrash = (): ReactElement => {
  const onClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    window.location.href = '/';
  };

  return (
    <div className='AppCrash'>
      <div className="AppCrash-TopBar" />
      <div className="AppCrash-Content">
        <a className="AppCrash-LogoLink" href="/">
          <img className="AppCrash-Logo" src={BrandLogo} alt="Logo" />
        </a>
        <svg
          className="AppCrash-Image"
          height="90"
          viewBox="0 0 132 90"
          width="132"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <path
              d="m3 76.91h76.5a1.5 1.5 0 0 1 1.5 1.5v.09a1.5 1.5 0 0 1 -1.5 1.5h-78a1.5 1.5 0 0 1 -1.5-1.5v-66a1.5 1.5 0 0 1 3 0zm116.5-65.91a1.5 1.5 0 0 1 1.5 1.5v25a1.5 1.5 0 0 1 -3 0v-25a1.5 1.5 0 0 1 1.5-1.5z"
              fill="#333"
            />
            <circle
              cx="107.5"
              cy="65.5"
              r="21.5"
              stroke="#b70006"
              strokeWidth="6"
            />
            <path
              d="m121 51.5-28.5 28.5"
              stroke="#b70006"
              strokeLinecap="square"
              strokeWidth="6"
            />
            <path
              d="m1.5 10.5h118v-6.5a2.5 2.5 0 0 0 -2.5-2.5h-113a2.5 2.5 0 0 0 -2.5 2.5z"
              stroke="#333"
              strokeWidth="3"
            />
            <g fill="#333">
              <circle cx="8" cy="6" r="2" />
              <circle cx="14" cy="6" r="2" />
              <circle cx="20" cy="6" r="2" />
              <rect height="3" rx="1.5" width="57" x="8" y="20" />
              <rect height="3" rx="1.5" width="40" x="8" y="26" />
              <rect height="3" rx="1.5" width="47" x="8" y="32" />
              <rect height="3" rx="1.5" width="24" x="8" y="38" />
              <rect height="3" rx="1.5" width="36" x="8" y="44" />
              <rect height="3" rx="1.5" width="46" x="8" y="50" />
            </g>
          </g>
        </svg>
        <h1>It looks like we’re having issues with our service.</h1>
        <p className="AppCrash-ErrorTitle">
          Error: <strong>Application has crashed.</strong>
        </p>
        <p>
          Our technical team has been notified about the problem and is looking
          at the issue.
        </p>
        <p>Please try reloading the page to see if problem persists:</p>

        <button onClick={onClickHandler}>Reload page</button>
      </div>
    </div>
  );
};
