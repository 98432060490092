import { requestApiSaga, retryRequestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Details } from '@client/myAccount/accountDetails/api/Details';
import {
  fetchDetails,
  fetchDetailsFulfilled,
  fetchDetailsRejected,
  updateContactInformation,
} from '@client/myAccount/accountDetails/redux/details/redux';
import { MY_ACCOUNT_MESSAGE, showMyAccountToast } from '@client/myAccount/common/utils/messages';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import emitter, { EVENT } from 'b2b-apps/core/common/utils/eventEmitter';
import { DetailsType } from './types';

function* fetchDetailsSaga(
  action: ReturnType<typeof fetchDetails>,
): Generator<CallEffect | PutEffect, any, AxiosResponse<DetailsType>> {
  try {
    const response = yield call(retryRequestApiSaga, Details.fetch());
    yield put(fetchDetailsFulfilled(response.data));
  } catch (error) {
    yield put(fetchDetailsRejected(parseError(error)));
    yield put(reportError({ message: action.type, error }));
  }
}

function* updateContactInformationSaga(
  action: ReturnType<typeof updateContactInformation>,
): Generator<CallEffect | PutEffect, any, AxiosResponse> {
  const contactInformation = action.payload;

  try {
    yield call(
      requestApiSaga,
      Details.updateContactInformation(contactInformation),
    );

    emitter.dispatch(EVENT.submitAccountDetailsFulfilled);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.successEditContactInformation);
  } catch (error) {
    yield put(reportError({ message: action.type, error }));

    emitter.dispatch(EVENT.submitAccountDetailsRejected);
    showMyAccountToast(MY_ACCOUNT_MESSAGE.errorEditContactInformation);
  }
}

export function* detailsSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(fetchDetails.type, fetchDetailsSaga),
    takeLatest(updateContactInformation.type, updateContactInformationSaga),
  ]);
}
