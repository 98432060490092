import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { INFOBAR_DISPLAY_TYPE, INFOBAR_STYLE_TYPE, Infobars } from '@client/app/components/App/Infobars/Infobars';
import { InfobarType } from '@client/app/components/App/Infobars/Infobars/Infobar';
import { useTranslate } from '@client/i18n/hooks';
import { useRouterReplace } from '@client/routes/hooks';
import { LocationStateType, SESSION_INFOBAR_TYPE } from './SessionInfobars.types';

export const SessionInfobars = (): ReactElement | null => {
  const t = useTranslate();
  const { state, pathname, search } = useLocation<LocationStateType>();
  const replace = useRouterReplace();
  const registeredAccountEmail = state?.[SESSION_INFOBAR_TYPE.registeredAccount]?.email;

  const handleRegisterInfobarDismiss = () => {
    replace(`${pathname}${search}`);
  };

  const getRegisterInfobar = (): InfobarType | null => {
    if (registeredAccountEmail) {
      return {
        message: t(
          'Your account <b>{email}</b> has been successfully created',
          {
            email: registeredAccountEmail,
          },
        ),
        styleType: INFOBAR_STYLE_TYPE.success,
        displayType: INFOBAR_DISPLAY_TYPE.box,
        id: registeredAccountEmail,
        onDismiss: handleRegisterInfobarDismiss,
      };
    }

    return null;
  };

  const registerInfobar = getRegisterInfobar();
  const infobars = registerInfobar ? [registerInfobar] : [];

  if (!infobars.length) {
    return null;
  }

  return (
    <Infobars infobars={infobars} />
  );
};
