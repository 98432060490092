import {
  analyzeProductAddToCartFromListInteraction,
  analyzeProductClickInteraction,
} from '@client/analytics/redux/products/lists/interactions';
import { TEALIUM_PROD_LIST } from '@client/analytics/types/types';
import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { selectProductsCompareData } from '@client/product/redux/productsCompare/redux';
import { call, CallEffect, select, SelectEffect } from 'redux-saga/effects';
import { ComparedProduct } from 'b2b-common/core/product/api/ProductsCompare.types';
import { AnalyzeProductsInteraction } from '../types';
import { getInteractionProductFromComparison } from '../utils';

function* analyzeComparisonProductInteraction(
  interaction: AnalyzeProductsInteraction,
  sku: string,
  listPosition: number,
  quantity?: number,
): Generator<SelectEffect | CallEffect, void, RemoteRecord<ComparedProduct[]>> {
  const comparisonRecord = yield select(selectProductsCompareData);
  const comparison = comparisonRecord.data;

  if (!comparison) {
    return;
  }

  const comparedProduct = comparison.find(product => sku === product.details.sku);

  if (!comparedProduct) {
    return;
  }

  yield call(
    interaction,
    TEALIUM_PROD_LIST.comparison,
    {
      products: [{ ...getInteractionProductFromComparison(comparedProduct.details), listPosition, quantity }],
    },
  );
}


export function* analyzeComparisonProductClick(sku: string, listPosition: number): Generator<CallEffect | void> {
  yield call(
    analyzeComparisonProductInteraction,
    analyzeProductClickInteraction,
    sku,
    listPosition,
  );
}

export function* analyzeComparisonProductAddToCart(sku: string, listPosition: number): Generator<CallEffect, void> {
  yield call(
    analyzeComparisonProductInteraction,
    analyzeProductAddToCartFromListInteraction,
    sku,
    listPosition,
    1,
  );
}
