import { AppState } from '@client/common/redux/store';
import { createAction, createSlice } from '@reduxjs/toolkit';
import { LANGUAGE_CODE } from 'b2b-common/core/i18n/consts';

const NAME = 'i18n';

export const i18nSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {
    translations: {},
    activeLanguage: LANGUAGE_CODE.de,
  },
  reducers: {},
});

export const changeLanguage = createAction<{ from: LANGUAGE_CODE; to: LANGUAGE_CODE }>('changeLanguage');
export const ACTIONS_TO_SYNC = [changeLanguage.type];

const getState = (state: AppState) => state.i18n;
export const getTranslations = (state: AppState): Record<string, string> => getState(state).translations;
export const getActiveLanguage = (state: AppState): LANGUAGE_CODE => getState(state).activeLanguage;
