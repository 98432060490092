import { fetchCartStatus } from '@client/cart/redux/cartStatus/redux';
import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { ExpressOrder } from '@client/expressOrder/api/ExpressOrder';
import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { AddItemsToCartType } from 'b2b-common/core/cart/api/CartTypes';
import {
  addExpressOrderItemsToCart,
  addExpressOrderItemsToCartFulfilled,
  addExpressOrderItemsToCartRejected,
  addExpressOrderItemsToWishlist,
  addExpressOrderItemsToWishlistFulfilled,
  addExpressOrderItemsToWishlistRejected,
  uploadCsv,
  uploadCsvFulfilled,
  uploadCsvRejected,
  verify,
  verifyFulfilled,
  verifyRejected,
} from './redux';

function* addItemsToCartSaga(action: ReturnType<typeof addExpressOrderItemsToCart>):
  Generator<CallEffect | PutEffect, void, AxiosResponse<AddItemsToCartType>> {
  const data = action.payload;

  try {
    const response = yield call(requestApiSaga, ExpressOrder.addItemsToCart(data));

    yield put(addExpressOrderItemsToCartFulfilled(response.data));
    yield put(fetchCartStatus());
  } catch (error) {
    yield put(addExpressOrderItemsToCartRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* addItemsToWishlistSaga(action: ReturnType<typeof addExpressOrderItemsToWishlist>):
  Generator<CallEffect | PutEffect, void, AxiosResponse> {
  const data = action.payload;

  try {
    const response = yield call(requestApiSaga, ExpressOrder.addItemsToWishlist(data));

    yield put(addExpressOrderItemsToWishlistFulfilled(response.data));
  } catch (error) {
    yield put(addExpressOrderItemsToWishlistRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* uploadCsvSaga(action: ReturnType<typeof uploadCsv>): Generator<CallEffect | PutEffect, void, AxiosResponse> {
  const file = action.payload;

  try {
    const response = yield call(requestApiSaga, ExpressOrder.uploadCsv(file));

    yield put(uploadCsvFulfilled(response.data));
  } catch (error) {
    yield put(uploadCsvRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

function* verifySaga(action: ReturnType<typeof verify>): Generator<CallEffect | PutEffect, void, AxiosResponse> {
  const data = action.payload;

  try {
    const response = yield call(requestApiSaga, ExpressOrder.verify(data));

    yield put(verifyFulfilled(response.data));
  } catch (error) {
    yield put(verifyRejected(parseError(error)));
    yield put(reportError({ message: action.type, error, additionalData: action.payload }));
  }
}

export function* expressOrderSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(addExpressOrderItemsToCart.type, addItemsToCartSaga),
    takeLatest(addExpressOrderItemsToWishlist.type, addItemsToWishlistSaga),
    takeLatest(uploadCsv.type, uploadCsvSaga),
    takeLatest(verify.type, verifySaga),
  ]);
}
