import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Infobar } from 'b2b-common/core/content/Infobars.types';

const NAME = 'infobars';

export const slice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {},
  reducers: {
    fetchInfobars: (state) => {
      state = RemoteRecordData.init();
      return state;
    },
    fetchInfobarsFulfilled: (state, action: PayloadAction<Infobar[]>) => {
      state = RemoteRecordData.fulfill(action.payload);
      return state;
    },
    fetchInfobarsRejected: (state, action: PayloadAction<ErrorType>) => {
      state = RemoteRecordData.reject(action.payload);
      return state;
    },
  },
});

export const {
  fetchInfobars,
  fetchInfobarsFulfilled,
  fetchInfobarsRejected,
} = slice.actions;

const getState = (state: AppState)=> state.content;

export const selectInfobars = createSelector(
  getState,
  state => new RemoteRecord<Infobar[]>(state[NAME]),
);
