import { isPrimitive } from '@client/common/utils/object/object';

export const ensureArray = (input: any): any[] => {
  if (Array.isArray(input)) {
    return input;
  }

  return [];
};

export const castToArray = (input: any): any[] => {
  switch (true) {
    case Array.isArray(input):
      return input;

    case isPrimitive(input):
      return [input];

    default:
      return [];
  }
};

export const chunkArray = (
  [...myArray]: any[],
  chunkSize: number,
): any[] => {
  const results = [];

  while (myArray.length) {
    results.push(myArray.splice(0, chunkSize));
  }

  return results;
};
