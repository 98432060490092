import { ReactElement, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ContentAnalyticsProvider } from '@client/analytics/context/content/ContentAnalyticsContext';
import { useCmsAnalytics } from '@client/analytics/hooks';
import { useScrollRestore } from '@client/app/context/ScrollRestoreContext';
import { RemoteRecordHandler } from '@client/common/components/RemoteRecordHandler';
import { UnloggedUserPage } from '@client/common/components/UnloggedUserPage';
import { useEntry } from '@client/content/hooks';
import { getPathnameWithSearch } from '@client/routes/utils/url';
import { StatusCodes } from 'http-status-codes';
import { ModularPage } from './ModularPage';
import { Props } from './';

export const ModularPageContainer = ({ title, entryId }: Props): ReactElement => {
  const content = useEntry(entryId);
  const scrollContext = useScrollRestore();
  const history = useHistory();
  const location = useLocation();

  useCmsAnalytics(content);

  useEffect(
    (): void => {
      if (content.isLoaded && history.action === 'POP' && !location.hash) {
        const scrollPosition = scrollContext.getScrollPosition(getPathnameWithSearch(location));
        window.scrollTo(0, scrollPosition);
      }
    }, [
      content.isLoaded,
      history.action,
      location,
    ],
  );

  if (content.hasFailed && content.error.statusCode === StatusCodes.UNAUTHORIZED) {
    return <UnloggedUserPage />;
  }

  return (
    <ContentAnalyticsProvider>
      <RemoteRecordHandler
        remoteRecord={content}
        shouldCheckEmptyRecord
      >
        <ModularPage
          content={content}
          title={title}
          entryId={entryId}
        />
      </RemoteRecordHandler>
    </ContentAnalyticsProvider>
  );
};
