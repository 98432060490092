import { isEmpty } from '@client/common/utils/empty/empty';

export enum DATE_TYPE {
  DMY = 'DMY',
  YMD = 'YMD',
}

export enum DATE_CONNECTOR_TYPE {
  default = '.',
  dash = '-',
}

export const getDate = (
  dateString: string,
  dateType: DATE_TYPE = DATE_TYPE.DMY,
  connector: DATE_CONNECTOR_TYPE = DATE_CONNECTOR_TYPE.default,
): string => {
  if (isEmpty(dateString)) {
    return '';
  }

  const date = new Date(dateString.replace(/ /g, 'T'));
  const dateArray = [
    ('0' + date.getDate()).slice(-2),
    ('0' + (date.getMonth() + 1)).slice(-2),
    date.getFullYear().toString().slice(-4),
  ];

  if (dateType === 'YMD') {
    return dateArray.reverse().join(connector);
  }

  return dateArray.join(connector);
};

const getDateParsed = (date: string): Date => {
  const [day, month, year] = date.split('.');

  return new Date(
    parseInt(year || ''),
    parseInt(month || '') - 1,
    parseInt(day || ''),
  );
};

export const getDaysDifference = (firstDate: string, secondDate: string): number => {
  const firstDateParsed = getDateParsed(firstDate);
  const secondDateParsed = getDateParsed(secondDate);
  const differenceInMs = secondDateParsed.getTime() - firstDateParsed.getTime();
  const oneDay = 24 * 60 * 60 * 1000;

  return Math.round(differenceInMs / oneDay);
};

export const toISOString = (date: Date): string => {
  const timezoneOffset = -date.getTimezoneOffset();
  const dif = timezoneOffset >= 0 ? '+' : '-';
  const pad = (num: number) => `${Math.floor(Math.abs(num))}`.padStart(2, '0');

  return `${date.getFullYear()}`
    + `-${pad(date.getMonth() + 1)}`
    + `-${pad(date.getDate())}`
    + `T${pad(date.getHours())}`
    + `:${pad(date.getMinutes())}`
    + `:${pad(date.getSeconds())}`
    + `${dif}${pad(timezoneOffset / 60)}`
    + `:${pad(timezoneOffset % 60)}`;
};

export const toCheckoutDeliveryFormat = (date: string, lang: string): string => {
  const options = {
    day: 'numeric',
    weekday: 'long',
    month: 'long',
  };

  return new Date(date).toLocaleDateString(lang, options as Record<string, any>);
};
