import { createContext, ReactElement, ReactNode, useContext } from 'react';
import { useDispatch } from 'react-redux';
import {
  analyzeSearchEvent,
  analyzeSearchFilterClick,
  analyzeSuggestedCategoryClick,
  analyzeSuggestedSimilarTermClick,
} from '@client/analytics/redux';
import { bindActionCreators } from '@reduxjs/toolkit';

interface SearchAnalyticsContextValue {
  analyzeSearchEvent: typeof analyzeSearchEvent;
  analyzeSuggestedCategoryClick: typeof analyzeSuggestedCategoryClick,
  analyzeSuggestedSimilarTermClick: typeof analyzeSuggestedSimilarTermClick,
  analyzeSearchFilterClick: typeof analyzeSearchFilterClick,
}

const SearchAnalyticsContext = createContext<SearchAnalyticsContextValue | undefined>(undefined);

export const SearchAnalyticsProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const dispatch = useDispatch();

  return (
    <SearchAnalyticsContext.Provider value={{
      analyzeSearchEvent: bindActionCreators(analyzeSearchEvent, dispatch),
      analyzeSuggestedCategoryClick: bindActionCreators(analyzeSuggestedCategoryClick, dispatch),
      analyzeSuggestedSimilarTermClick: bindActionCreators(analyzeSuggestedSimilarTermClick, dispatch),
      analyzeSearchFilterClick: bindActionCreators(analyzeSearchFilterClick, dispatch),
    }}>
      {children}
    </SearchAnalyticsContext.Provider>
  );
};

export const useSearchAnalytics = (): SearchAnalyticsContextValue => {
  const context = useContext(SearchAnalyticsContext);

  if (context === undefined) {
    throw new Error('useSearchAnalytics must be used within a SearchAnalyticsProvider');
  }

  return context;
};
