import { AppState } from '@client/common/redux/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CheckoutDetails } from 'b2b-common/core/checkout/CheckoutDetails.types';

const NAME = 'details';

export const checkoutDetailsSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState: {} as CheckoutDetails,
  reducers: {
    setCheckoutDetails: (state, action: PayloadAction<CheckoutDetails>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { setCheckoutDetails } = checkoutDetailsSlice.actions;

export const getCheckoutDetails = (state: AppState): CheckoutDetails => state.checkout.details;
