import { assortmentReducer } from '@client/assortment/redux/redux';
import { breadcrumbsSlice } from '@client/breadcrumbs/redux/reducer';
import { ACTIONS_TO_SYNC as CART_ACTIONS_TO_SYNC } from '@client/cart/redux/cart/redux';
import { ACTIONS_TO_SYNC as CART_STATUS_ACTIONS_TO_SYNC } from '@client/cart/redux/cartStatus/redux';
import { cartReducer } from '@client/cart/redux/redux';
import { catalogReducer } from '@client/catalog/redux/reducer';
import { checkoutReducer } from '@client/checkout/redux/redux';
import { collectedOrderSlice } from '@client/collectedOrder/redux/reducer';
import { rootSaga } from '@client/common/redux/saga';
import { getCookie } from '@client/common/utils/storage/cookies';
import { contentReducer } from '@client/content/redux/redux';
import { contextSlice, syncContext } from '@client/context/redux/redux';
import { expressOrderSlice } from '@client/expressOrder/redux/redux';
import { ACTIONS_TO_SYNC as I18N_ACTIONS_TO_SYNC, i18nSlice } from '@client/i18n/redux/redux';
import { kanbanSlice } from '@client/kanban/redux/redux';
import { manufacturerReducer } from '@client/manufacturer/redux/redux';
import { myAccountReducer } from '@client/myAccount/common/redux/redux';
import { newsletterSlice } from '@client/newsletter/redux/redux';
import { productReducer } from '@client/product/redux/redux';
import { punchoutSlice } from '@client/punchout/testPages/redux/redux';
import { settingsSlice } from '@client/settings/redux/redux';
import { userStatsSlice } from '@client/user-stats';
import { combineSlices, configureStore, Middleware, Tuple } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { isAppEnvProduction } from '@server/utils/env';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import createSagaMiddleware from 'redux-saga';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';

const STATE_SYNC_MIDDLEWARE_CONFIG = {
  whitelist: [
    syncContext.type,
    ...CART_ACTIONS_TO_SYNC,
    ...CART_STATUS_ACTIONS_TO_SYNC,
    ...I18N_ACTIONS_TO_SYNC,
  ],
};

const DEVTOOLS_COOKIE_NAME = 'enableReduxDevTools';

// Redux Toolkit heavily relies on the type inference, so we shouldn't manually add return type here
// and type of the `store` will be infered from `configureStore`
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getStore = (history: History, preloadedState = {}, withAdditionalMiddlewares = true) => {
  const cookie: any = getCookie(DEVTOOLS_COOKIE_NAME);
  const devTools = (!isAppEnvProduction() || !!cookie?.isEnabed) ? { maxAge: 100, trace: true } : false;
  const sentryReduxEnhancer = Sentry.createReduxEnhancer();
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [
    routerMiddleware(history),
  ];

  if (withAdditionalMiddlewares) {
    middleware.push(sagaMiddleware);
    middleware.push(createStateSyncMiddleware(STATE_SYNC_MIDDLEWARE_CONFIG) as Middleware);
  }

  const store = configureStore({
    reducer: combineSlices(
      {
        assortment: assortmentReducer,
        cart: cartReducer,
        catalog: catalogReducer,
        checkout: checkoutReducer,
        content: contentReducer,
        manufacturer: manufacturerReducer,
        myAccount: myAccountReducer,
        product: productReducer,
        router: connectRouter(history),
      },
      breadcrumbsSlice,
      collectedOrderSlice,
      contextSlice,
      expressOrderSlice,
      i18nSlice,
      kanbanSlice,
      newsletterSlice,
      punchoutSlice,
      settingsSlice,
      userStatsSlice,
    ),
    middleware: () => new Tuple(...middleware),
    enhancers: getDefaultEnhancers => getDefaultEnhancers().concat([sentryReduxEnhancer]),
    preloadedState,
    devTools,
  });

  if (withAdditionalMiddlewares) {
    sagaMiddleware.run(rootSaga);
  }

  initMessageListener(store);

  return store;
};

export type AppStore = ReturnType<typeof getStore>
type AppStoreGetState = AppStore['getState'];
export type AppState = ReturnType<AppStoreGetState>;
