import { ReactElement, useId } from 'react';
import { withField } from '@client/common/components/Form/withField';
import { Select, STYLE_TYPE } from 'b2b-components/theme/components/Form/Select';
import { Props } from './FormFieldSelect.types';

export const FormFieldSelect = withField((props: Props): ReactElement => {
  const { innerComponentProps, field, form, ...otherProps } = props;
  const selectId = useId();

  return (
    <Select selectId={selectId} {...innerComponentProps} {...field} {...otherProps} />
  );
});

export {
  STYLE_TYPE as SELECT_STYLE_TYPE,
};
