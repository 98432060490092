import { requestApiSaga } from '@client/common/redux/api/sagas';
import { reportError } from '@client/common/redux/errors/actions';
import { parseError } from '@client/common/utils/api/error';
import { Backorder } from '@client/myAccount/orders/api';
import {
  backorderSubscribe,
  backorderSubscribeFulfilled,
  backorderSubscribeRejected,
} from '@client/myAccount/orders/redux/backorderSubscribe/redux';
import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';

const SUBSCRIPTION_TYPE = 'BACKORDER_REPORT';

function* subscribeSaga(action: ReturnType<typeof backorderSubscribe>): Generator<CallEffect | PutEffect, void> {
  const { email, isSubscribed } = action.payload;

  try {
    yield call(requestApiSaga, Backorder.subscribe(email, isSubscribed, SUBSCRIPTION_TYPE));
    yield put(backorderSubscribeFulfilled({ email, isSubscribed }));
  } catch (error) {
    yield put(backorderSubscribeRejected(parseError(error)));
    yield put(reportError({ message: action.type, error }));
  }
}

export function* backorderSubscribeSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeEvery(backorderSubscribe.type, subscribeSaga),
  ]);
}
