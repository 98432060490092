import { ReactElement } from 'react';
import classNames from 'classnames';
import { CMS_NEWSROOM_PAGE_ID } from 'b2b-common/core/content/utils/utils';
import { Props } from '../';
import './NewsroomHeader.scss';

const DATE_OPTIONS: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: '2-digit' };

export const NewsroomHeader = ({ className, componentData }: Props): ReactElement | null => {
  const { lang, lead_text: leadText, publish_date: publishDate, title } = componentData;
  const date = new Date(publishDate).toLocaleDateString(lang, DATE_OPTIONS);

  return (
    <div className={classNames(
      'cds-cms-NewsroomHeader', {
        'cds-cms-GridRow': componentData?.contentTypeId === CMS_NEWSROOM_PAGE_ID.singleNews,
      },
      className,
    )}>
      <div className="cds-cms-TextMediaContainer">
        {publishDate && <span className="cds-cms-NewsroomHeader-PublishDate">{date}</span>}
        {title && <h1 className="cds-cms-NewsroomHeader-Title">{title}</h1>}
        {leadText && <h3 className="cds-cms-NewsroomHeader-LeadText">{leadText}</h3>}
      </div>
    </div>
  );
};
