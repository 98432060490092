import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Infobars } from '@client/app/components/App/Infobars/Infobars';
import { InfobarType } from '@client/app/components/App/Infobars/Infobars/Infobar';
import { getCookie, setCookie } from '@client/common/utils/storage/cookies';
import { fetchInfobars, selectInfobars } from '@client/content/redux/infobars/redux';
import { ContentCollectionType } from 'b2b-common/core/content/Content.types';
import { CONTENT_INFOBAR_TYPE } from './ContentInfobars.types';

const COOKIE_GLOBAL_MSG = 'infobar_';

export const ContentInfobars = (): ReactElement => {
  const dispatch = useDispatch();
  const content = useSelector(selectInfobars);
  const { data } = content;

  const onDismiss = (entryId: string) => {
    setCookie(`${COOKIE_GLOBAL_MSG}${entryId}`, { isVisible: false }, 7);
  };

  const visibleInfobars = data?.filter(({ entryId }) => {
    const cookie = getCookie(`${COOKIE_GLOBAL_MSG}${entryId}`) as Record<string, any>;

    return !cookie || cookie.isVisible;
  });

  const getEntriesMappedIntoInfobars = (contentInfobarType: CONTENT_INFOBAR_TYPE): InfobarType[] => visibleInfobars
    ?.filter(({ data }: ContentCollectionType) => data.type === contentInfobarType)
    .map(({ data, entryId }: ContentCollectionType) => ({
      title: data.title,
      styleType: data.type,
      message: data.message,
      onDismiss: () => onDismiss(entryId),
      id: entryId,
    })) || [];

  const sortedInfobars = visibleInfobars?.length === 0
    ? []
    : [
      ...getEntriesMappedIntoInfobars(CONTENT_INFOBAR_TYPE.error),
      ...getEntriesMappedIntoInfobars(CONTENT_INFOBAR_TYPE.warning),
      ...getEntriesMappedIntoInfobars(CONTENT_INFOBAR_TYPE.success),
      ...getEntriesMappedIntoInfobars(CONTENT_INFOBAR_TYPE.info),
    ];

  useEffect(
    () => {
      if (!content.wasStarted) {
        dispatch(fetchInfobars());
      }
    },
    [],
  );

  if (sortedInfobars.length === 0) {
    return null;
  }

  return (
    <Infobars infobars={sortedInfobars} />
  );
};
