import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { AxiosResponse } from 'axios';
import { EntryMapType } from 'b2b-common/core/content/Content.types';

export class EntriesMap {
  public static fetch(): RequestConfigType {
    return RequestConfig.get('/content/entries', { withLocalization: true });
  }

  public static mapResponseToState(response: AxiosResponse<EntryMapType[]>): EntryMapType[] {
    return response.data;
  }
}
