import { RemoteRecord } from '@client/common/redux/RemoteRecord';
import { RemoteRecordData } from '@client/common/redux/RemoteRecordData';
import { AppState } from '@client/common/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductVariantsMatrixType } from './types';

const initialState = {};

const NAME = 'productVariantsMatrix';

export const productVariantsMatrixSlice = createSlice({
  name: NAME,
  reducerPath: NAME,
  initialState,
  reducers: {
    fetchProductVariantsMatrix: (state, action: PayloadAction<{ sku: string }>) => {
      state[action.payload.sku] = RemoteRecordData.init();
      return state;
    },
    fetchProductVariantsMatrixFulfilled: (state, action: PayloadAction<{
      sku: string,
      data: ProductVariantsMatrixType
    }>) => {
      state[action.payload.sku] = RemoteRecordData.fulfill(action.payload.data);
      return state;
    },
    fetchProductVariantsMatrixRejected: (state, action: PayloadAction<{ sku: string, error: ErrorType }>) => {
      state[action.payload.sku] = RemoteRecordData.reject(action.payload.error);
      return state;
    },
  },
});

export const {
  fetchProductVariantsMatrix,
  fetchProductVariantsMatrixFulfilled,
  fetchProductVariantsMatrixRejected,
} = productVariantsMatrixSlice.actions;

const getState = (state: AppState) => state.product.productVariantsMatrix;

export const selectProductVariantsMatrixBySku = createSelector(
  [getState, (state, sku: string) => sku],
  (state, sku) => new RemoteRecord<ProductVariantsMatrixType>(state[sku]),
);
