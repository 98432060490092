export enum ADD_TO_WISHLIST_STYLE_TYPE {
  full = 'full',
  icon = 'icon',
  link = 'link',
}

export interface Props {
  skus: string[];
  styleType: ADD_TO_WISHLIST_STYLE_TYPE;
  className?: string;
}
