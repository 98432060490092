import { getAvailableLanguages } from '@client/i18n/utils/language';
import { LANGUAGE_CODE } from 'b2b-common/core/i18n/consts';

export const getLocalisedPaths = (
  path: string,
  includeFix = false,
): Record<LANGUAGE_CODE | string, string> => {
  let result = getAvailableLanguages().reduce(
    (acc, languageCode: LANGUAGE_CODE): Record<LANGUAGE_CODE | string, string> => ({
      ...acc,
      [languageCode]: `/${languageCode}/${path}`,
    }),
    {},
  );

  if (includeFix) {
    result = {
      ...result,
      fix: `/${path}`,
    };
  }

  return result;
};
