import { backorderSlice } from '@client/myAccount/orders/redux/backorderSubscribe/redux';
import { orderSlice } from '@client/myAccount/orders/redux/order/redux';
import { ordersSlice as coreOrdersSlice } from '@client/myAccount/orders/redux/orders/redux';
import { ordersSearchSlice } from '@client/myAccount/orders/redux/ordersSearch/redux';
import { combineSlices } from '@reduxjs/toolkit';

export const ordersReducer = combineSlices(
  backorderSlice,
  orderSlice,
  coreOrdersSlice,
  ordersSearchSlice,
);
