import { RequestConfig, RequestConfigType } from '@client/common/utils/api/RequestConfig';
import { CartItemType } from 'b2b-common/core/cart/api/CartTypes';
import { CartApi } from './CartApi';

export class Cart extends CartApi {
  public static fetch(): RequestConfigType {
    return RequestConfig.get(this.baseUrl);
  }

  public static fetchStatus(): RequestConfigType {
    return RequestConfig.get(`${this.baseUrl}/status`, {
      withLocalization: false,
    });
  }

  public static fetchWishlistSearch(query: string): RequestConfigType {
    return RequestConfig.get(
      `${this.baseUrl}/wishlist/search/${query}`,
      {
        withLocalization: false,
      },
    );
  }

  public static addItems(items: CartItemType[]): RequestConfigType {
    return RequestConfig.post(`${this.baseUrl}/items`, { items });
  }

  public static addItem({
    sku,
    quantity,
    reference,
  }: { sku: string, quantity: number, reference?: string }): RequestConfigType {
    return RequestConfig.post(
      `${this.baseUrl}/item/${sku}`,
      {
        quantity,
        reference,
      },
    );
  }

  public static updateItemQuantity(sku: string, quantity: number, partAuxiliaryId?: string | null): RequestConfigType {
    return RequestConfig.patch(
      `${this.baseUrl}/item/${sku}/quantity`,
      {
        quantity,
        ...(partAuxiliaryId && { supplierConfig: { partAuxiliaryId } }),
      },
    );
  }

  public static updateItemReference(
    sku: string,
    reference: string | null,
  ): RequestConfigType {
    return RequestConfig.patch(
      `${this.baseUrl}/item/${sku}/reference`,
      {
        reference,
      },
    );
  }

  public static updateVoucher(code: string | null): RequestConfigType {
    return RequestConfig.post(
      `${this.baseUrl}/voucher`,
      {
        voucher: code,
      },
    );
  }

  public static exportToWishlist(name: string): RequestConfigType {
    return RequestConfig.patch(
      `${this.baseUrl}/wishlist`,
      {
        name,
      },
      {
        withLocalization: false,
      },
    );
  }

  public static clear(): RequestConfigType {
    return RequestConfig.delete(this.baseUrl, {
      withLocalization: false,
    });
  }

  public static clearVoucher(): RequestConfigType {
    return RequestConfig.delete(`${this.baseUrl}/voucher`, {
      withLocalization: false,
    });
  }
}
