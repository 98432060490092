import { Analytics } from '@client/analytics/helpers/Analytics';
import {
  analyzeProductAddToCart,
  analyzeProductAddToWishlist,
  analyzeProductClick,
  analyzeProductDetailsPageView,
  analyzeProductsAddToCart,
  analyzeProductsListView,
  analyzeScannedProductAddToCart,
  analyzeScannedProductAddToWishlist,
  analyzeScannedProductClick,
} from '@client/analytics/redux';
import { EVENT_SOURCE } from '@client/analytics/types/types';
import { addToCartFulfilled, addToCartRejected } from '@client/cart/redux/addToCart/redux';
import { addItemsToCartFulfilled, addItemsToCartRejected } from '@client/cart/redux/cart/redux';
import {
  all,
  AllEffect,
  call,
  CallEffect,
  delay,
  ForkEffect,
  race,
  RaceEffect,
  take,
  TakeEffect,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  analyzeSearchAutosuggestProductAddToCart,
  analyzeSearchAutosuggestProductClick,
  analyzeSearchProductAddToCart,
  analyzeSearchProductClick,
} from '../search/interactions';
import {
  analyzeAccessoryProductAddToCart,
  analyzeAccessoryProductClick,
  analyzeBrandProductAddToCart,
  analyzeBrandProductClick,
  analyzeBrandProductListView,
  analyzeCatalogProductListView,
  analyzeCategoryProductAddToCart,
  analyzeCategoryProductClick,
  analyzeCMSProductAddToCart,
  analyzeCMSProductClick,
  analyzeCmsProductsListView,
  analyzeComparisonProductAddToCart,
  analyzeComparisonProductClick,
  analyzeProductAccessoriesListView,
  analyzeRecommendationProductAddToCart,
  analyzeRecommendationProductClick,
  analyzeSimilarProductListView,
  analyzeWishlistProductAddToCart,
  analyzeWishlistProductClick,
  analyzeWishlistProductsAddToCart,
} from './lists';
import { analyzePDPProductAddToCart, analyzeProductDetailsPageViewSaga } from './product';

function* analyzeProductsListSaga(action: ReturnType<typeof analyzeProductsListView>): Generator<CallEffect, void> {
  const { eventSource, skus } = action.payload;
  //TODO Temporary fix to delay EC-PLI events after page view is processed
  yield delay(Analytics.delayTealiumPageView);

  switch (eventSource) {
    case EVENT_SOURCE.category:
      yield call(analyzeCatalogProductListView);
      break;
    case EVENT_SOURCE.brand:
      yield call(analyzeBrandProductListView);
      break;
    case EVENT_SOURCE.accessories:
      yield call(analyzeProductAccessoriesListView, skus[0]);
      break;
    case EVENT_SOURCE.recommendations:
      yield call(analyzeSimilarProductListView);
      break;
    case EVENT_SOURCE.cms:
      yield call(analyzeCmsProductsListView, skus);
      break;
    default:
      break;
  }
}

function* analyzeProductClickSaga(action: ReturnType<typeof analyzeProductClick>): Generator<CallEffect, void> {
  const { eventSource, sku, listPosition, additionalId } = action.payload;

  switch (eventSource) {
    case EVENT_SOURCE.category:
      yield call(analyzeCategoryProductClick, sku, listPosition);
      break;
    case EVENT_SOURCE.search:
      yield call(analyzeSearchProductClick, sku);
      break;
    case EVENT_SOURCE.searchAutosuggest:
      yield call(analyzeSearchAutosuggestProductClick, sku);
      break;
    case EVENT_SOURCE.brand:
      yield call(analyzeBrandProductClick, sku, listPosition);
      break;
    case EVENT_SOURCE.cms:
      yield call(analyzeCMSProductClick, sku, listPosition);
      break;
    case EVENT_SOURCE.recommendations:
      yield call(analyzeRecommendationProductClick, sku, listPosition);
      break;
    case EVENT_SOURCE.accessories:
      yield call(analyzeAccessoryProductClick, sku, additionalId as string, listPosition);
      break;
    case EVENT_SOURCE.wishlist:
      yield call(analyzeWishlistProductClick, sku, additionalId as string, listPosition);
      break;
    case EVENT_SOURCE.comparison:
      yield call(analyzeComparisonProductClick, sku, listPosition);
      break;
    case EVENT_SOURCE.barcode:
      yield call(analyzeScannedProductClick, sku, additionalId as string);
      break;
    // TODO: enable after https://competecjira.atlassian.net/browse/ALL3-5722
    // case EVENT_SOURCE.deliveredOrder:
    //   yield call(analyzeDeliveredOrderProductClick, sku, additionalId as number, listPosition);
    //   break;
    default:
      break;
  }
}

function* analyzeProductAddToCartSaga(action: ReturnType<typeof analyzeProductAddToCart>): Generator<
    CallEffect | RaceEffect<TakeEffect>,
    void,
    { fulfilled: ReturnType<typeof addToCartFulfilled>, rejected: ReturnType<typeof addToCartRejected> }
  > {
  const { eventSource, sku, listPosition, additionalId } = action.payload;

  const { fulfilled, rejected } = yield race({
    fulfilled: take(addToCartFulfilled.type),
    rejected: take(addToCartRejected.type),
  });

  if (rejected) {
    return;
  }

  const { quantity } = fulfilled.payload;

  switch (eventSource) {
    case EVENT_SOURCE.category:
      yield call(analyzeCategoryProductAddToCart, sku, listPosition);
      break;
    case EVENT_SOURCE.search:
      yield call(analyzeSearchProductAddToCart, sku);
      break;
    case EVENT_SOURCE.searchAutosuggest:
      yield call(analyzeSearchAutosuggestProductAddToCart, sku);
      break;
    case EVENT_SOURCE.brand:
      yield call(analyzeBrandProductAddToCart, sku, listPosition);
      break;
    case EVENT_SOURCE.cms:
      yield call(analyzeCMSProductAddToCart, sku, listPosition);
      break;
    case EVENT_SOURCE.recommendations:
      yield call(analyzeRecommendationProductAddToCart, sku, listPosition);
      break;
    case EVENT_SOURCE.accessories:
      yield call(analyzeAccessoryProductAddToCart, sku, additionalId as string, listPosition);
      break;
    case EVENT_SOURCE.pdp:
      yield call(analyzePDPProductAddToCart, sku, quantity);
      break;
    case EVENT_SOURCE.wishlist:
      yield call(analyzeWishlistProductAddToCart, sku, additionalId as string, listPosition, quantity);
      break;
    case EVENT_SOURCE.comparison:
      yield call(analyzeComparisonProductAddToCart, sku, listPosition);
      break;
    case EVENT_SOURCE.barcode:
      yield call(analyzeScannedProductAddToCart, sku);
      break;
    // TODO: enable after https://competecjira.atlassian.net/browse/ALL3-5722
    // case EVENT_SOURCE.deliveredOrder:
    //   yield call(analyzeDeliveredOrderProductAddToCart, sku, additionalId as number, listPosition, quantity);
    //   break;
    default:
      break;
  }
}

function* analyzeProductsAddToCartSaga(action: ReturnType<typeof analyzeProductsAddToCart>): Generator<
    CallEffect | RaceEffect<TakeEffect>,
    void,
    { fulfilled: ReturnType<typeof addItemsToCartFulfilled>, rejected: ReturnType<typeof addItemsToCartRejected> }
  > {
  const { eventSource, products, additionalId } = action.payload;
  const { fulfilled, rejected } = yield race({
    fulfilled: take(addItemsToCartFulfilled.type),
    rejected: take(addItemsToCartRejected.type),
  });

  if (rejected) {
    return;
  }

  const addedProducts = fulfilled.payload.added;

  if (!addedProducts.length) {
    return;
  }

  const mergedProducts = addedProducts?.reduce(
    (allProducts, addedProduct, index) => {
      const matchingProduct = products?.find(product => product.sku === addedProduct.sku) || null;

      return [
        ...allProducts,
        {
          sku: addedProduct.sku,
          quantity: addedProduct.quantity,
          listPosition: matchingProduct?.listPosition || index + 1,
        },
      ];
    },
    [],
  ) || [];

  switch (eventSource) {
    case EVENT_SOURCE.wishlist:
      yield call(analyzeWishlistProductsAddToCart, mergedProducts, additionalId as string);
      break;
    // TODO: enable after https://competecjira.atlassian.net/browse/ALL3-5722
    // case EVENT_SOURCE.deliveredOrder:
    //   yield call(analyzeDeliveredOrderProductsAddToCart, mergedProducts, additionalId as number);
    //   break;
    default:
      break;
  }
}

function* analyzeProductWishlistAddSaga(action: ReturnType<typeof analyzeProductAddToWishlist>): Generator<
  CallEffect | TakeEffect,
  void
> {
  const { eventSource, sku } = action.payload;

  switch (eventSource) {
    case EVENT_SOURCE.barcode:
      yield call(analyzeScannedProductAddToWishlist, sku);
      break;
    default:
      break;
  }
}

export function* productsAnalyticsSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    takeLatest(analyzeProductDetailsPageView.type, analyzeProductDetailsPageViewSaga),
    takeLatest(analyzeProductsListView.type, analyzeProductsListSaga),
    takeEvery(analyzeProductClick.type, analyzeProductClickSaga),
    takeEvery(analyzeProductAddToCart.type, analyzeProductAddToCartSaga),
    takeEvery(analyzeProductsAddToCart.type, analyzeProductsAddToCartSaga),
    takeEvery(analyzeProductAddToWishlist.type, analyzeProductWishlistAddSaga),
  ]);
}
