import { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Pagination } from '@client/common/components/Pagination';
import { getDate } from '@client/common/utils/date/date';
import { parse, stringify } from '@client/common/utils/queryParams';
import { useFetchNewsroomNews, useNewsroomNewsData } from '@client/content/hooks';
import { NEWSROOM_NEWS_PAGE_SIZE } from '@client/content/redux/newsroom/sagas';
import { useActiveLanguage, useTranslate } from '@client/i18n/hooks';
import { useRouterPush } from '@client/routes/hooks';
import { openLink } from '@client/routes/utils/url';
import { News as NewsComponent } from '@lib/components/CMS/News';
import classNames from 'classnames';
import { Props } from '../';
import fallbackImg from './assets/news-fallback.jpg';

export const NewsroomNews = ({ className }: Props): ReactElement => {
  const fetchNews = useFetchNewsroomNews();
  const location = useLocation();
  const push = useRouterPush();
  const { data: newsData } = useNewsroomNewsData();
  const page = +parse(location.search).page;
  const lang = useActiveLanguage();
  const t = useTranslate();

  useEffect(() => {
    fetchNews({ page });
  }, [fetchNews, page]);

  if (!newsData || !newsData.news || !Array.isArray(newsData.news) || !newsData.news.length) {
    return null;
  }

  const handlePaginationPageChange = (nextPage: number): void => {
    const newPageParam = {
      page: nextPage,
    };

    push({ search: stringify(newPageParam) });
  };

  const handleNewsButtonClick = (url: string): void => {
    openLink(url, push);
  };

  const text = {
    readMore: t('Read more »'),
  };

  return (
    <div className={classNames('cds-cms-NewsroomNews', className)}>
      <div className="row">
        {
          newsData.news.map(news => <NewsComponent
            className="col-md-4 col-sm-6 col-xs-12"
            key={news.uid}
            onClick={handleNewsButtonClick}
            publicationDate={getDate(news.publicationDate)}
            text={text}
            thumbnail={news.thumbnail || fallbackImg}
            title={news.title}
            url={`/${lang}${news.url}`}
          />)
        }
      </div>
      {newsData && (
        <Pagination
          total={Math.ceil(newsData.total / NEWSROOM_NEWS_PAGE_SIZE)}
          page={page || 1}
          onPageChange={handlePaginationPageChange}
        />
      )}
    </div>
  );
};
