import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';
import { addToCartSaga } from './addToCart/sagas';
import { cartSaga as internalCartSaga } from './cart/sagas';
import { cartStatusSaga } from './cartStatus/sagas';
import { cartCostCenterSaga } from './costCenter/sagas';
import { cartWishlistSearchSaga } from './wishlistSearch/sagas';

export function* cartSaga(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    fork(addToCartSaga),
    fork(internalCartSaga),
    fork(cartStatusSaga),
    fork(cartWishlistSearchSaga),
    fork(cartCostCenterSaga),
  ]);
}
