import { invoiceSaga } from '@client/myAccount/invoices/redux/invoice/sagas';
import { invoicesSaga } from '@client/myAccount/invoices/redux/invoices/sagas';
import { invoicesSearchSagas } from '@client/myAccount/invoices/redux/invoicesSearch/sagas';
import { sendEmailSaga } from '@client/myAccount/invoices/redux/sendEmail/sagas';
import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';

export function* invoicesSagas(): Generator<AllEffect<ForkEffect>, void> {
  yield all([
    fork(invoicesSaga),
    fork(invoiceSaga),
    fork(invoicesSearchSagas),
    fork(sendEmailSaga),
  ]);
}
